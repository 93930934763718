import { Button, CircularProgress } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import { fieldErrorMessageMap, noop } from "shared";
import { RATE_TYPES } from "modules/shared/constants";
import { Dialog } from "shared/components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import useStyles from "./style";

let isFieldChange = false;
const defaultState = {
  customer: null,
  location: null,
  route: null,
  defaultBillInfo: {
    bill_rate_type: RATE_TYPES[0],
    bill_quantity: "0",
    bill_rate: "0",
    pay_rate_type: RATE_TYPES[0],
    pay_quantity: "0",
    pay_rate: "0",
    vehicle_type: null,
  },
  errors: {
    customer: " ",
    location: " ",
  },
};

const BranchInlineEdit = ({
  open = false,
  entry = {},
  isLoading = false,
  customerList = [],
  type = "customer",
  shouldUpdateBillInfo = false,
  onClose = noop,
  handleSubmit = noop,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    if (Object.keys(entry).length) {
      setState((prevState) => ({
        ...prevState,
        customer: entry.customerBranch.customer,
        location: entry.customerBranch,
        route: entry.route ? { name: entry.route } : defaultState.route,
      }));
    }
    return () => {
      isFieldChange = false;
    };
  }, [entry]);

  const validate = (field, value) => {
    let isValid = true;
    const fieldValidatorMap = {
      customer: (value) => !!value,
      location: (value) => !!value,
    };

    if (fieldValidatorMap[field]) {
      isValid = fieldValidatorMap[field](value);
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        if (!validate(key, state[key])) {
          isValid = false;
        }
      });
    }
    return isValid;
  };

  const handleFieldChange = (field, value) => {
    isFieldChange = true;
    let errorMessage = validate(field, value)
      ? " "
      : fieldErrorMessageMap[field] || "Required";
    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  const handleClose = () => {
    setState(defaultState);
    onClose();
  };

  const branchList = useMemo(
    () =>
      (
        customerList.find((customer) => customer.id === state.customer?.id)
          ?.customer_branches || []
      )
        .filter((branch) => branch.is_active)
        .sort((a, b) => {
          const valueA = (a.location || "").toLowerCase();
          const valueB = (b.location || "").toLowerCase();
          return valueA > valueB ? 1 : valueB > valueA ? -1 : 0;
        }),
    [state.customer]
  );
  const routeList = useMemo(() => {
    return (
      branchList.find((location) => location.id === state.location?.id)
        ?.routes || []
    );
  }, [state.location]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.inlineEditPaper }}
    >
      <Dialog.Title hasClose>{`Edit ${type}`}</Dialog.Title>
      <Dialog.Content>
        {type === "customer" && (
          <Autocomplete
            disableClearable
            size="small"
            value={state.customer}
            options={customerList}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Customer"
                variant="outlined"
                error={!!state.errors.customer.trim()}
                helperText={state.errors.customer}
              />
            )}
            onChange={(evt, value) => {
              handleFieldChange("customer", value);
              handleFieldChange("location", null);
              handleFieldChange("route", null);
            }}
          />
        )}
        <Autocomplete
          disableClearable
          disabled={!isFieldChange && type === "customer"}
          size="small"
          value={state.location}
          options={branchList}
          getOptionLabel={(option) =>
            `${option.location} (${option.state?.name || ""} ,${
              option?.pdx_company?.name || ""
            })`
          }
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label="Location"
              variant="outlined"
              error={!!state.errors.location.trim()}
              helperText={state.errors.location}
            />
          )}
          onChange={(evt, location) => {
            handleFieldChange("location", location);
            handleFieldChange("route", null);
            setState((prevState) => ({
              ...prevState,
              defaultBillInfo: {
                ...defaultState.defaultBillInfo,
              },
            }));
          }}
        />
        <Autocomplete
          disabled={!isFieldChange}
          size="small"
          value={state.route}
          options={routeList}
          getOptionLabel={(option) => option.name || ""}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Route"
              variant="outlined"
              helperText=" "
              onBlur={(evt) => {
                const { value } = evt?.target;
                handleFieldChange("route", { name: value });
              }}
            />
          )}
          onChange={(evt, route) => {
            handleFieldChange("route", route);
            setState((prevState) => ({
              ...prevState,
              defaultBillInfo: {
                ...prevState.defaultBillInfo,
                vehicle_type:
                  route?.vehicle_type ||
                  defaultState.defaultBillInfo.vehicle_type,
                bill_rate_type:
                  route?.default_bill_rate_type ||
                  defaultState.defaultBillInfo.bill_rate_type,
                bill_quantity:
                  route?.default_bill_quantity ||
                  defaultState.defaultBillInfo.bill_quantity,
                bill_rate:
                  route?.default_bill_rate ||
                  defaultState.defaultBillInfo.bill_rate,
                pay_rate_type:
                  route?.default_pay_rate_type ||
                  defaultState.defaultBillInfo.pay_rate_type,
                pay_quantity:
                  route?.default_pay_quantity ||
                  defaultState.defaultBillInfo.pay_quantity,
                pay_rate:
                  route?.default_pay_rate ||
                  defaultState.defaultBillInfo.pay_rate,
              },
            }));
          }}
        />
      </Dialog.Content>
      <Dialog.Actions>
        <div className="d-flex f-align-center f-justify-end p-4">
          <Button className="mr-2" onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button
            className="ml-2"
            variant="contained"
            color="primary"
            disabled={!isFieldChange || isLoading || !validate()}
            onClick={() => {
              let payload = {
                customer_branch_id: state.location?.id,
                route: state.route?.name || null,
              };
              if (shouldUpdateBillInfo) {
                payload = {
                  ...payload,
                  ...state.defaultBillInfo,
                };
              }
              handleSubmit({ payload }, entry?.id);
            }}
          >
            {isLoading && (
              <CircularProgress size={24} className="p-absolute progress-btn" />
            )}
            Save
          </Button>
        </div>
      </Dialog.Actions>
    </Dialog>
  );
};

export default BranchInlineEdit;
