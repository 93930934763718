import makeStyles from "@material-ui/core/styles/makeStyles";
import grey from "@material-ui/core/colors/grey";
import yellow from "@material-ui/core/colors/yellow";

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  datepickerWrapper: {
    width: 160,
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  paperSize: {
    width: 316,
  },
  actionsWrapper: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  tunePaper: {
    minWidth: 156,
  },
  highlightUpdate: {
    background: `${yellow[50]} !important`,
  },
  highlightApproved: {
    background: grey[100],
  },
  tableHeaderCell: {
    minWidth: 144,
  },
  kamInput: {
    minWidth: 200,
    maxWidth: 250,
  },
  paper: {
    minWidth: 150,
    maxHeight: 200,
  },
  highlightDisable: {
    background: theme.palette.grey[200],
  },
  dropdownPaper: {
    minWidth: 156,
  },
  progressBtn: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  fuelSurchargeScheduleDropdown: {
    width: 224,
  },
  fixedHeader: {
    width: "100%",
    top: "64px",
    height: "75px",
    zIndex: 1000,
    position: "sticky",
    background: "#fafafa",
  },
  paperHeight: {
    minHeight: 400,
  },
  editFuelSurcharge: {
    width: "72px",
  },
}));

export default useStyles;
