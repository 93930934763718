import { http, responseFormatter } from "utils";

const post = (data) => {
  return responseFormatter(
    http.post("/user", data, {
      setAuthHeader: true,
    })
  );
};

const put = (data) => {
  return responseFormatter(
    http.put("/user", data, {
      setAuthHeader: true,
    })
  );
};

const UserService = {
  post,
  put,
};

export default UserService;
