import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 400,
    minHeight: 200,
    "& .MuiDialogContent-root": {
      overflow: "hidden",
    },
  },
}));

export default useStyles;
