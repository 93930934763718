import React from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getUTCDateString } from "utils";
import { noop } from "shared";
import { modeOfPaymentList } from "modules/shared/constants";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    margin: "auto",
    width: "100%",
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
  },
  label: {
    fontWeight: "bold",
  },
  value: {
    textAlign: "right",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
}));

const CustomerSummary = ({ data, getInvoice = noop, isInvoiceLoading }) => {
  const classes = useStyles();

  const leftColumnData = [
    { label: "Customer Name", value: data?.customer_name || "-" },
    {
      label: "PDX Company",
      value: data?.customer_branch?.pdxCompany?.value || "-",
    },
    { label: "Location", value: data?.customer_branch?.location || "-" },
    { label: "State", value: data?.customer_branch?.state || "-" },
    {
      label: "Created By",
      value: `${data?.createdBy?.first_name || ""} - ${
        data?.createdBy?.last_name || ""
      }`,
    },
  ];

  const getModeOfPaymentLabel = (value) => {
    const payment = modeOfPaymentList.find((item) => item.value === value);
    return payment ? payment.label : "-";
  };

  const rightColumnData = [
    { label: "Invoice No.", value: data?.invoice_number || "-" },
    { label: "Date of Payment", value: getUTCDateString(data?.date) || "-" },
    {
      label: "Mode of Payment",
      value: getModeOfPaymentLabel(data?.mode),
    },
    { label: "Amount", value: `$${data?.amount || "0.00"}` },
    {
      label: "Reference Number",
      value: `${data?.reference_number || "-"}`,
    },
  ];

  return (
    <div className={classes.paper}>
      <Typography variant="h6" className={classes.title} color="primary">
        Summary:
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          {leftColumnData.map(({ label, value }) => (
            <div className={classes.row} key={label}>
              <Typography className={classes.label}>{label}:</Typography>
              <Typography className={classes.value}>{value}</Typography>
            </div>
          ))}
        </Grid>
        <Grid item xs={12} md={6}>
          {rightColumnData.map(({ label, value }) => (
            <div className={classes.row} key={label}>
              <Typography className={classes.label}>{label}:</Typography>
              <div className="d-flex f-align-center">
                {label === "Invoice No." && isInvoiceLoading && (
                  <CircularProgress size={14} style={{ marginRight: 8 }} />
                )}
                <Typography
                  className={classes.value}
                  style={{
                    color: label === "Invoice No." && "#1e88e5",
                    cursor: label === "Invoice No." && "pointer",
                    textDecoration: label === "Invoice No." && "underline",
                  }}
                  onClick={() =>
                    label === "Invoice No." && getInvoice(data, true)
                  }
                >
                  {value}
                </Typography>
              </div>
            </div>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomerSummary;
