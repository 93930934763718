import { http, responseFormatter } from "utils";

const get = (query) => {
  return responseFormatter(
    http.get(`/invoice/invoice-history/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getAllInvoice = (query) => {
  return responseFormatter(
    http.get(`/invoice/get-invoices/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const post = (data) => {
  return responseFormatter(
    http.post("/invoice/create-invoice", data, {
      setAuthHeader: true,
    })
  );
};
const generateMultipleInvoices = (data) => {
  return responseFormatter(
    http.post("/invoice/generate-multiple-invoices", data, {
      setAuthHeader: true,
    })
  );
};
const recordPayment = (data) => {
  return responseFormatter(
    http.post("/invoice/add-invoice-payments", data, {
      setAuthHeader: true,
    })
  );
};

const getRecordPayment = (query) => {
  return responseFormatter(
    http.get(`/invoice/get-invoices-payments/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getInvoiceEmailHistory = (query) => {
  return responseFormatter(
    http.get(`/invoice/email-history${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const sendEmail = (data) => {
  return responseFormatter(
    http.post("/invoice/send-invoice-by-email", data, {
      setAuthHeader: true,
    })
  );
};

const update = (data) => {
  return responseFormatter(
    http.put("/invoice/update-invoice", data, {
      setAuthHeader: true,
    })
  );
};

const finalizeInvoice = (data) => {
  return responseFormatter(
    http.patch("/invoice/finalize-invoice", data, {
      setAuthHeader: true,
    })
  );
};

const markInvoiceSent = (data) => {
  return responseFormatter(
    http.patch("/invoice/mark-as-sent", data, {
      setAuthHeader: true,
    })
  );
};

const getCustomerLocation = (query) => {
  return responseFormatter(
    http.get(`/customer/get-locations${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const postLineItem = (data) => {
  return responseFormatter(
    http.post("/invoice/create-line-items", data, {
      setAuthHeader: true,
    })
  );
};
const updateLineItem = (data) => {
  return responseFormatter(
    http.put("/invoice/update-line-item", data, {
      setAuthHeader: true,
    })
  );
};

const deleteLineItem = (query) => {
  return responseFormatter(
    http.delete(`/invoice/remove-line-item${query}`, {
      setAuthHeader: true,
    })
  );
};

const approveInvoice = (data) => {
  return responseFormatter(
    http.patch("/invoice/approve-invoice", data, {
      setAuthHeader: true,
    })
  );
};

const rejectInvoice = (data) => {
  return responseFormatter(
    http.patch("/invoice/reject-invoice", data, {
      setAuthHeader: true,
    })
  );
};
const deleteInvoices = (query) => {
  return responseFormatter(
    http.delete(`/invoices${query}`, {
      setAuthHeader: true,
    })
  );
};

const getInvoicesPayments = (query) => {
  return responseFormatter(
    http.get(`/invoice/get-invoices-payments${query || ""}`, {
      setAuthHeader: true,
    })
  );
};
const getAllInvoiceDetails = (query) => {
  return responseFormatter(
    http.get(`/invoice/all-invoice-details${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const addEmailInBranch = (data) => {
  return responseFormatter(
    http.put("/customer-branch", data, {
      setAuthHeader: true,
    })
  );
};

const Service = {
  get,
  post,
  update,
  recordPayment,
  sendEmail,
  markInvoiceSent,
  getAllInvoice,
  getCustomerLocation,
  postLineItem,
  updateLineItem,
  deleteLineItem,
  approveInvoice,
  rejectInvoice,
  finalizeInvoice,
  getRecordPayment,
  deleteInvoices,
  getInvoicesPayments,
  generateMultipleInvoices,
  getAllInvoiceDetails,
  addEmailInBranch,
  getInvoiceEmailHistory,
};

export default Service;
