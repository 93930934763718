import { Switch, Route } from "react-router-dom";
import View from "./view";
import Create from "./create";
import DisplayCalenderModule from "./display-calender";
import AuditHistory from "./audit-history";
import { getTokenData } from "utils";
import { ROLE } from "modules/shared/constants";

const DailyScheduleModule = ({ match }) => {
  const currentUser = getTokenData() || {};
  const isRoleKAM = (currentUser?.role || "").toLowerCase() === ROLE.KAM;
  return (
    <Switch>
      <Route exact path={match.path} component={Create} />
      {!isRoleKAM && (
        <Route
          exact
          path={`${match.path}/audit-history`}
          component={AuditHistory}
        />
      )}
      <Route
        exact
        path={`${match.path}/day/:schedule_date/:end_date?`}
        component={View}
      />
      <Route
        exact
        path={`${match.path}/day/:schedule_date/:end_date?/kam/:kam_id`}
        component={View}
      />
      <Route exact path={`${match.path}/create`} component={Create} />
      <Route
        exact
        path={`${match.path}/create/kam/:kam_id`}
        component={Create}
      />
      <Route
        exact
        path={`${match.path}/create/day/:schedule_date/:end_date?`}
        component={Create}
      />
      <Route
        exact
        path={`${match.path}/create/day/:schedule_date/:end_date?/kam/:kam_id`}
        component={Create}
      />
      <Route
        exact
        path={`${match.path}/create/day/:schedule_date/:end_date?/kam/`}
        component={Create}
      />

      <Route exact path={match.path} component={DisplayCalenderModule} />
      <Route
        render={(props) => (
          <>
            <div className="m-10 pt-10 d-flex f-justify-center f-align-center flex-1">
              <div>
                <h2>Uh Oh! Something is not right...</h2>
                <h4>The page you are trying to access does not exist.</h4>
                <h4>
                  Maybe you want to go to{" "}
                  <span
                    className="color-primary c-pointer"
                    onClick={() => {
                      props.history.push("/");
                    }}
                  >
                    Home
                  </span>{" "}
                  page?
                </h4>
              </div>
            </div>
          </>
        )}
      />
    </Switch>
  );
};

export default DailyScheduleModule;
