import { Redirect, Switch, Route } from "react-router";
import SignInForm from "./form";
import ForgotPasswordModule from "./forgot-password";
import ResetPasswordMoudle from "./reset-password";

const SignInModule = ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.path} component={SignInForm} />
      <Route
        exact
        path={`${match.path}/change-password/:accessToken`}
        component={ResetPasswordMoudle}
      />
      <Route
        exact
        path={`${match.path}/reset-password/:token`}
        component={ResetPasswordMoudle}
      />
      <Route
        exact
        path={`${match.path}/forgot-password`}
        component={ForgotPasswordModule}
      />

      <Redirect to="/" />
    </Switch>
  );
};

export default SignInModule;
