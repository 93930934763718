import { useState, useEffect, useContext } from "react";
import clsx from "clsx";
import Paper from "@material-ui/core/Paper";
import { toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import SharedService from "../service";
import { ROLE } from "modules/shared/constants";

import Service from "./service";
import {
  getDateString,
  getTokenData,
  getUTCDateString,
  queryStringBuilderNew,
} from "utils";
import { AppContext } from "shared/contexts";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import { ActionDialog } from "shared/components";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { startOfMonth, endOfMonth, endOfWeek } from "date-fns";
import ForceSubmitDialog from "../forceSubmit";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import useStyles from "./style";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { isTransferScheduleEnable } from "shared";
import HeightIcon from "@material-ui/icons/Height";
import { SvgIcon } from "@material-ui/core";

const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
  startOfMonth,
  endOfMonth,
  endOfWeek,
});

const defaultState = {
  scheduleDate: new Date(),
  previousDate: new Date(),
  isLoading: false,
  isRevertingDate: false,
  currentSelectedMonth: null,
  isFetchingList: {
    users: false,
    submissionHistory: false,
    pendingItems: false,
  },
  submissionHistory: {
    completeSubmitted: [],
    bsApproved: [],
    notCreated: [],
    notSubmitted: [],
    partialSubmitted: [],
    forcedlySubmitted: [],
    transferSubmitted: [],
    underReview: [],
    events: [],
  },
  overridePayload: null,
  openForcedSubmitRevertDialog: false,
  previousDateConfirmation: false,
  selectedKAM: null,
  userRole: null,
  viewScheduleConfirmation: false,
  previousScheduleActiveDate: null,
  openForceSubmitDialog: false,
  weekend: [],
  calenderView: "month",
  isTransferScheduleDetails: false,
  transferScheduleDetails: [],
};

const ViewCalender = ({ history, selectedkamId }) => {
  const classes = useStyles();
  const userData = getTokenData();
  const [state, setState] = useState(defaultState);

  const kamId = selectedkamId;
  const isRoleADMIN = (userData?.role || "").toLowerCase() === ROLE.ADMIN;
  const isRoleKAM = (userData?.role || "").toLowerCase() === ROLE.KAM;

  const { appData, setHeaderElements, updateContextData } =
    useContext(AppContext);

  const fetchTransferScheduleDetails = async (fromDate, toDate, kamId) => {
    if (!isTransferScheduleEnable) return;
    setState((prevState) => ({
      ...prevState,
      isTransferScheduleDetails: true,
    }));

    if (!(fromDate && toDate && kamId)) return;

    let queryString = queryStringBuilderNew();

    if (kamId) {
      queryString += `?filter[where][and][0][or][0][kam_id]=${kamId}&filter[where][and][0][or][1][transfer_to]=${kamId}`;
    }
    if (fromDate) {
      queryString += `&filter[where][schedule_date][gte]=${fromDate}`;
    }
    if (toDate) {
      queryString += `&filter[where][schedule_date][lte]=${toDate}`;
    }

    const { data, error } = await Service.getTransferRecords(queryString);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isTransferScheduleDetails: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    setState((prevState) => ({
      ...prevState,
      transferScheduleDetails:
        data.transferRecords || defaultState.transferScheduleDetails,
      isTransferScheduleDetails: false,
    }));
  };

  const isTransferScheduled = (details, kamId, targetKamId, event) => {
    return (
      details.length > 0 &&
      details.some((item) => {
        const formattedEndDate = getUTCDateString(item.schedule_date);
        const formattedEventEnd = getDateString(event.end);
        return (
          (targetKamId === "org"
            ? item.KAM?.id === kamId
            : item.transfer_to === kamId) &&
          formattedEndDate === formattedEventEnd
        );
      })
    );
  };

  const CustomArrowIcon = ({ additionalStyles, ...props }) => {
    return (
      <SvgIcon {...props} style={additionalStyles}>
        <path
          d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
          className={classes.arrowStroke}
        />
        <path
          d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
          className={classes.arrowFill}
        />
      </SvgIcon>
    );
  };

  const CustomArrowHeightIcon = ({ additionalStyles, ...props }) => {
    const classes = useStyles();
    return (
      <SvgIcon {...props} style={additionalStyles}>
        <path
          d="M13 6.99h3L12 3 8 6.99h3v10.02H8L12 21l4-3.99h-3z"
          className={classes.arrowStroke}
        />
        <path
          d="M13 6.99h3L12 3 8 6.99h3v10.02H8L12 21l4-3.99h-3z"
          className={classes.arrowFill}
        />
      </SvgIcon>
    );
  };

  const renderEventWrapper = ({ event, children }) => {
    const isScheduledTransferred = isTransferScheduled(
      state.transferScheduleDetails,
      kamId,
      "org",
      event
    );
    const isTransferredScheduledReceived = isTransferScheduled(
      state.transferScheduleDetails,
      kamId,
      "transferred",
      event
    );

    return (
      <div style={{ position: "relative", height: "100%" }}>
        {isScheduledTransferred && !isTransferredScheduledReceived && (
          <div className={classes.iconWrapper}>
            <CustomArrowIcon additionalStyles={{ fontSize: "2.8rem" }} />
          </div>
        )}
        {isTransferredScheduledReceived && !isScheduledTransferred && (
          <div className={classes.iconWrapper}>
            <CustomArrowIcon
              additionalStyles={{ fontSize: "2.8rem", rotate: "180deg" }}
            />
          </div>
        )}
        {/* {isTransferredScheduledReceived && isScheduledTransferred && (
          <div className={classes.iconWrapper}>
            <CustomArrowHeightIcon
              additionalStyles={{ fontSize: "2.8rem", rotate: "90deg" }}
            />
          </div>
        )} */}
        {children}
      </div>
    );
  };

  const fetchKamSetting = async () => {
    const { data, error } = await Service.getKamSetting(kamId);

    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      weekend: data.settings.week_off_days,
    }));
  };

  const fetchSubmissionHistoryWeek = async (kamId, startDate) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        submissionHistory: true,
      },
    }));

    const { data, error } = await Service.getSubmissionHistoryWeek(
      kamId,
      startDate
    );

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          submissionHistory: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      submissionHistory: {
        ...prevState.submissionHistory,
        completeSubmitted: (data?.completeSubmitted || []).map((date) => ({
          date: getDateString(date),
          title: "Submitted",
          type: "submitted",
        })),
        bsApproved: (data?.bsApproved || []).map((date) => ({
          date: getDateString(date),
          title: "Approved",
          type: "bsApproved",
        })),
        notCreated: (data?.notCreated || []).map((date) => ({
          date: getDateString(date),
          title: "Not Created",
          type: "notCreated",
        })),
        notSubmitted: (data?.notSubmitted || []).map((date) => ({
          date: getDateString(date),
          title: "Not Submitted",
          type: "notSubmitted",
        })),
        partialSubmitted: (data?.partialSubmitted || []).map((date) => ({
          date: getDateString(date),
          title: "Partial Submitted",
          type: "partialSubmitted",
        })),
        forcedlySubmitted: (data?.forcedlySubmitted || []).map((data) => ({
          date: getDateString(data.date),
          title: `Forced Submit-${data.reason}`,
          type: "forcedSubmit",
        })),
        transferSubmitted: (data?.transferred || []).map((date) => ({
          date: getDateString(date),
          title: "Schedule Transferred",
          type: "scheduleTransferred",
        })),
        underReview: (data?.underReview || []).map((date) => ({
          date: getDateString(date),
          title: "Under Review",
          type: "underReview",
        })),
      },
      isFetchingList: {
        ...prevState.isFetchingList,
        submissionHistory: false,
      },
    }));
  };

  const fetchList = async (listType = "customers", ...params) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    switch (listType) {
      case "customers":
        serviceMethod = "getCustomerList";
        responseKey = "customers";
        break;
      case "IC":
        serviceMethod = "getContractorList";
        responseKey = "contractors";
        break;
      case "users":
        serviceMethod = "getUsersList";
        responseKey = "users";
        break;
      case "pendingItems":
        serviceMethod = "getPendingItems";
        responseKey = "pendingItems";
        break;
      default:
        serviceMethod = "getCustomerList";
        responseKey = "customers";
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];
    if (listType === "customers") {
      listData.map((item = {}) => {
        (item.customer_branches || []).map((branch = {}) => {
          (branch.routes || []).sort((a, b) =>
            Intl.Collator().compare(a.name, b.name)
          );
        });
      });
    }
    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
  };

  const fetchSubmissionHistory = async (kamId, month, year) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        submissionHistory: true,
      },
    }));

    const { data, error } = await Service.getSubmissionHistory(
      kamId,
      month,
      year
    );

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          submissionHistory: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      submissionHistory: {
        ...prevState.submissionHistory,
        completeSubmitted: (data?.completeSubmitted || []).map((date) => ({
          date: getDateString(date),
          title: "Submitted",
          type: "submitted",
        })),
        bsApproved: (data?.bsApproved || []).map((date) => ({
          date: getDateString(date),
          title: "Approved",
          type: "bsApproved",
        })),
        notCreated: (data?.notCreated || []).map((date) => ({
          date: getDateString(date),
          title: "Not Created",
          type: "notCreated",
        })),
        notSubmitted: (data?.notSubmitted || []).map((date) => ({
          date: getDateString(date),
          title: "Not Submitted",
          type: "notSubmitted",
        })),
        partialSubmitted: (data?.partialSubmitted || []).map((date) => ({
          date: getDateString(date),
          title: "Partial Submitted",
          type: "partialSubmitted",
        })),
        forcedlySubmitted: (data?.forcedlySubmitted || []).map((data) => ({
          date: getDateString(data.date),
          title: `Forced Submit-${data.reason}`,
          type: "forcedSubmit",
        })),
        transferSubmitted: (data?.transferred || []).map((date) => ({
          date: getDateString(date),
          title: "Schedule Transferred",
          type: "scheduleTransferred",
        })),
        underReview: (data?.underReview || []).map((date) => ({
          date: getDateString(date),
          title: "Under Review",
          type: "underReview",
        })),
      },
      isFetchingList: {
        ...prevState.isFetchingList,
        submissionHistory: false,
      },
    }));
  };

  const data = [
    ...(state.submissionHistory?.forcedlySubmitted || []),
    ...(state.submissionHistory?.notCreated || []),
    ...(state.submissionHistory?.completeSubmitted || []),
    ...(state.submissionHistory?.partialSubmitted || []),
    ...(state.submissionHistory?.notSubmitted || []),
    ...(state.submissionHistory?.transferSubmitted || []),
    ...(state.submissionHistory?.underReview || []),
    ...(state.submissionHistory?.bsApproved || []),
  ].map((item) => {
    const isWeekoff = (state.weekend || []).includes(
      new Date(item.date).getDay().toString()
    );
    let title = item.title;
    if (isWeekoff) {
      title = item.type === "notCreated" ? "No Schedule" : item.title;
    }
    return {
      ...item,
      title,
    };
  });

  useEffect(() => {
    !!appData.pendingItems.length &&
      setHeaderElements([
        <div className="d-flex f-align-center m-2">
          <Chip
            className={"bg-danger color-white"}
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                openForceSubmitDialog: true,
              }));
            }}
            label={
              appData.isTabletView ? (
                <Tooltip title="PENDING SCHEDULE DATE(S)" placement="top-start">
                  <Typography variant="body2" className="text-bold">
                    ({appData.pendingItems.length})
                  </Typography>
                </Tooltip>
              ) : (
                <Typography variant="body2" className="text-bold">
                  PENDING SCHEDULE DATE(S) ({appData.pendingItems.length})
                </Typography>
              )
            }
            variant="outlined"
          />
        </div>,
      ]);

    return () => setHeaderElements([]);
  }, [appData.pendingItems]);

  useEffect(() => {
    return () => updateContextData("pendingItems", []);
  }, []);

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      openForceSubmitDialog: false,
    }));
  };

  const handleRevertForcedSubmit = async (date = "") => {
    setState((prevState) => ({
      ...prevState,
      isRevertingDate: true,
    }));

    const payload = {
      forcedSubmitDate: date,
    };

    const { error } = await Service.revertForcedSubmit(payload);

    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    // fetchSubmissionHistory(
    //   selectedkamId,
    //   state.scheduleDate.getMonth() + 1,
    //   state.scheduleDate.getFullYear()
    // );
    fetchList("pendingItems", selectedkamId);
    setState((prevState) => ({
      ...prevState,
      openRevertForcedSubmitDialog: false,
    }));
  };

  useEffect(() => {
    const today = new Date();
    if (kamId) {
      fetchSubmissionHistory(
        kamId,
        state.previousScheduleActiveDate
          ? state.previousScheduleActiveDate.getMonth() + 1
          : state.currentSelectedMonth
          ? state.currentSelectedMonth.getMonth() + 1
          : today.getMonth() + 1,
        state.previousScheduleActiveDate
          ? state.previousScheduleActiveDate.getFullYear()
          : state.currentSelectedMonth
          ? state.currentSelectedMonth.getFullYear()
          : today.getFullYear()
      );
      fetchKamSetting();
      fetchTransferScheduleDetails(
        getDateString(startOfMonth(today)),
        getDateString(endOfMonth(today)),
        selectedkamId
      );
    }
  }, [appData.pendingItems]);

  const calculateMonth = (date = "") => {
    const selectedDate = new Date(date);
    setState((prevState) => ({
      ...prevState,
      currentSelectedMonth: selectedDate,
    }));
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const isScheduledTransferred = isTransferScheduled(
      state.transferScheduleDetails,
      kamId,
      "org",
      event
    );

    const isTransferredScheduledReceived = isTransferScheduled(
      state.transferScheduleDetails,
      kamId,
      "transferred",
      event
    );

    const isWeeklyOff = state.weekend?.includes(start.getDay().toString());

    const isFutureDate = start > new Date();

    let dayClassName =
      event.type === "notCreated" && !isFutureDate
        ? clsx(
            isRoleKAM
              ? isScheduledTransferred === false &&
                  isTransferredScheduledReceived === false &&
                  "c-default no-events"
              : isScheduledTransferred === false &&
                  (isTransferredScheduledReceived === true ||
                    isTransferredScheduledReceived === false) &&
                  "c-default no-events",
            classes.notCreatedDay,
            {
              [classes.weekend]: isWeeklyOff,
            }
          )
        : event.type === "partialSubmitted"
        ? clsx(
            isScheduledTransferred && !isTransferredScheduledReceived
              ? classes.transfer
              : classes.partialSubmittedDay,
            {
              [classes.weekend]: isWeeklyOff,
            }
          )
        : isTransferredScheduledReceived &&
          state.transferScheduleDetails.some(
            (item) =>
              item.status === "PENDING" &&
              getUTCDateString(item.schedule_date) ===
                getDateString(event.end) &&
              !!item.is_accepted
          ) &&
          (event.type === "submitted" ||
            event.type === "bsApproved" ||
            event.type === "underReview")
        ? clsx(classes.partialSubmittedDay, {
            [classes.weekend]: isWeeklyOff,
          })
        : event.type === "submitted"
        ? clsx(
            isScheduledTransferred && !isTransferredScheduledReceived
              ? classes.transfer
              : classes.completeSubmittedDay,
            {
              [classes.weekend]: isWeeklyOff,
            }
          )
        : event.type === "notSubmitted"
        ? clsx(
            isScheduledTransferred && !isTransferredScheduledReceived
              ? classes.transfer
              : classes.notSubmittedDay,
            {
              [classes.weekend]: isWeeklyOff,
            }
          )
        : event.type === "forcedSubmit"
        ? clsx(
            isScheduledTransferred && !isTransferredScheduledReceived
              ? classes.transfer
              : classes.forcedSubmitDay,
            {
              [classes.weekend]: isWeeklyOff,
            }
          )
        : event.type === "scheduleTransferred"
        ? clsx(classes.transfer, {
            [classes.weekend]: isWeeklyOff,
          })
        : event.type === "underReview"
        ? clsx(
            isScheduledTransferred && !isTransferredScheduledReceived
              ? classes.transfer
              : classes.underReview,
            {
              [classes.weekend]: isWeeklyOff,
            }
          )
        : event.type === "bsApproved"
        ? clsx(
            isScheduledTransferred && !isTransferredScheduledReceived
              ? classes.transfer
              : classes.bsApproved,
            {
              [classes.weekend]: isWeeklyOff,
            }
          )
        : clsx(
            isRoleKAM
              ? isScheduledTransferred === false &&
                  isTransferredScheduledReceived === false &&
                  "c-default no-events"
              : isScheduledTransferred === false &&
                  (isTransferredScheduledReceived === true ||
                    isTransferredScheduledReceived === false) &&
                  "c-default no-events",
            classes.backgroundWhite,
            {
              [classes.weekoff]: isWeeklyOff,
            }
          );

    if (isSelected) {
      if (event.type === "forcedSubmit") {
        if (!isRoleADMIN) {
          toast.error("Schedule not exist for forced submitted date.");
        } else {
          setState((prevState) => ({
            ...prevState,
            openRevertForcedSubmitDialog: isRoleADMIN && true,
            forcedSubmitDate: getDateString(start).split("/").join("-"),
          }));
        }
      } else {
        if (
          (event.type === "notCreated" &&
            isScheduledTransferred === false &&
            isTransferredScheduledReceived === false) ||
          (event.type === "noSchedule" &&
            isScheduledTransferred === false &&
            isTransferredScheduledReceived === false)
        ) {
          return false;
        } else {
          let scheduleDate = getDateString(start).split("/").join("-");
          history.push({
            pathname: `/daily-schedule/day/${scheduleDate}/kam/${selectedkamId}`,
            viewState:
              isScheduledTransferred === false &&
              isTransferredScheduledReceived === true &&
              (event.type === "notCreated" || event.type === "noSchedule")
                ? {
                    isTransferredScheduledReceived:
                      isTransferredScheduledReceived,
                    isScheduledTransferred,
                  }
                : undefined,
            state:
              isScheduledTransferred === false &&
              isTransferredScheduledReceived === true &&
              (event.type === "notCreated" || event.type === "noSchedule")
                ? {
                    rowDate: scheduleDate,
                    tabIndex: 2,
                  }
                : undefined,
          });
        }
      }
    }

    let style = {
      borderRadius: "2px",
      //   opacity: 0.8,
      color:
        isFutureDate && event.type === "notCreated" && !isWeeklyOff
          ? "transparent"
          : "black",
      border: "12px",
      height: "48px",
      display: "block",
      marginTop: "1px",
      fontSize: "17px",
    };

    return {
      className: state.isFetchingList.submissionHistory
        ? "loading"
        : dayClassName,
      style: style,
    };
  };

  // const handleForceSubmit = () => {
  //   fetchSubmissionHistory(
  //     selectedkamId,
  //     state.currentSelectedMonth.getMonth() + 1,
  //     state.currentSelectedMonth.getFullYear()
  //   );
  // }

  return (
    <>
      <div>
        <Paper
          style={{
            height: state.calenderView === "month" ? "380pt" : "126pt",
            width: appData.isTabletView ? "680px" : "1100px",
            marginTop: appData.isTabletView && "10px",
          }}
          className={clsx(classes.calenderWrapper, "ml-5 mr-5")}
        >
          <Calendar
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            selectable
            resizable
            tooltipAccessor={(e) => e.tooltip}
            showMultiDayTimes
            onView={async (calenderView) => {
              let submissionHistoryPromise;
              let transferSchedulePromise;

              if (calenderView === "week") {
                submissionHistoryPromise = fetchSubmissionHistoryWeek(
                  selectedkamId,
                  getDateString(startOfWeek(state.scheduleDate))
                );
                transferSchedulePromise = fetchTransferScheduleDetails(
                  getDateString(startOfWeek(state.scheduleDate)),
                  getDateString(endOfWeek(state.scheduleDate)),
                  selectedkamId
                );
              }

              if (calenderView === "month") {
                submissionHistoryPromise = fetchSubmissionHistory(
                  selectedkamId,
                  state.scheduleDate.getMonth() + 1,
                  state.scheduleDate.getFullYear()
                );
                transferSchedulePromise = fetchTransferScheduleDetails(
                  getDateString(startOfMonth(state.scheduleDate)),
                  getDateString(endOfMonth(state.scheduleDate)),
                  selectedkamId
                );
              }

              await Promise.all([
                submissionHistoryPromise,
                transferSchedulePromise,
              ]);

              setState((prevState) => ({
                ...prevState,
                calenderView,
              }));
            }}
            date={state.scheduleDate}
            views={["month", "week"]}
            messages={
              state.calenderView === "month"
                ? {
                    next: "Next Month",
                    previous: "Previous Month",
                    today: "Current Month",
                  }
                : {
                    next: "Next Week",
                    previous: "Previous Week",
                    today: "Current Week",
                  }
            }
            events={data.map((entry) => {
              let tooltip = state.isFetchingList.submissionHistory
                ? ""
                : entry.title;
              let title = state.isFetchingList.submissionHistory
                ? ""
                : entry.title;

              const isOrg = isTransferScheduled(
                state.transferScheduleDetails,
                kamId,
                "org",
                { end: entry.date }
              );
              const isTransferred = isTransferScheduled(
                state.transferScheduleDetails,
                kamId,
                "transferred",
                { end: entry.date }
              );

              if (isOrg) {
                title = "Transferred";
                tooltip = "Schedule Transferred";
              }

              if (
                isTransferred &&
                state.transferScheduleDetails.some(
                  (item) =>
                    item.status === "PENDING" &&
                    getUTCDateString(item.schedule_date) ===
                      getDateString(entry.date) &&
                    !!item.is_accepted
                ) &&
                (entry.type === "submitted" ||
                  entry.type === "bsApproved" ||
                  entry.type === "underReview")
              ) {
                title = "Partial Submitted";
                tooltip = "Partial Submitted";
              }
              // if (isOrg && isTransferred) {
              //   title += " / Transferred...";
              //   tooltip += " / Initiated & Received Transferred Schedule";
              // } else if (isOrg) {
              //   title += " / Transferred";
              //   tooltip += " / Schedule Transferred";
              // } else if (isTransferred) {
              //   title += " / Schedule Rec...";
              //   tooltip += " / Received Transferred Schedule";
              // }

              if (title.length > 35) {
                title = title.slice(0, 35) + "...";
              }

              return {
                tooltip: tooltip,
                title: title,
                allDay: true,
                start: new Date(entry.date),
                end: new Date(entry.date),
                type: entry.type,
              };
            })}
            onNavigate={async (date, view) => {
              let submissionHistoryPromise;
              let transferSchedulePromise;

              if (view === "week") {
                submissionHistoryPromise = fetchSubmissionHistoryWeek(
                  selectedkamId,
                  getDateString(startOfWeek(date))
                );
                transferSchedulePromise = fetchTransferScheduleDetails(
                  getDateString(startOfWeek(date)),
                  getDateString(endOfWeek(date)),
                  selectedkamId
                );
              }

              if (view === "month") {
                calculateMonth(date);
                submissionHistoryPromise = fetchSubmissionHistory(
                  selectedkamId,
                  date.getMonth() + 1,
                  date.getFullYear()
                );
                transferSchedulePromise = fetchTransferScheduleDetails(
                  getDateString(startOfMonth(date)),
                  getDateString(endOfMonth(date)),
                  selectedkamId
                );
              }

              await Promise.all([
                submissionHistoryPromise,
                transferSchedulePromise,
              ]);

              fetchKamSetting();

              setState((prevState) => ({
                ...prevState,
                scheduleDate: date,
              }));
            }}
            eventPropGetter={(event, start, end, isSelected) =>
              eventStyleGetter(
                event,
                start,
                end,
                isSelected,
                renderEventWrapper
              )
            }
            components={{
              month: {
                header: (day) => {
                  return (
                    <Typography variant="body1" className="text-bold p-2">
                      {day.label}
                    </Typography>
                  );
                },
              },
              week: {
                header: (day) => {
                  return (
                    <Typography
                      variant="body1"
                      className="text-bold p-2 c-default"
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                      }}
                    >
                      {day.label}
                    </Typography>
                  );
                },
              },
              eventWrapper: renderEventWrapper,
            }}
          />
        </Paper>
        <div
          className={clsx("d-flex p-absolute mt-9 ml-3 f-wrap", {
            "mt-8 mb-2": appData.isTabletView,
            [classes.legend]: !appData.isTabletView,
          })}
        >
          {[
            { label: "Submitted", className: "completeSubmittedDay" },
            {
              label: "Partial Submitted",
              className: "partialSubmittedDay",
            },
            { label: "Not Submitted", className: "notSubmittedDay" },
            { label: "Not Created", className: "notCreatedDay" },
            { label: "Forced Submit", className: "forcedSubmitDay" },
            { label: "No Schedule", className: "weekoff" },
            { label: "Under Review", className: "underReview" },
            { label: "Approved", className: "bsApproved" },
            ...(isTransferScheduleEnable
              ? [
                  {
                    label: "Schedule Transferred",
                    icon: (
                      <CustomArrowIcon
                        additionalStyles={{ fontSize: "1.6rem" }}
                        className="mr-2"
                      />
                    ),
                  },
                  {
                    label: "Received Transferred Schedule",
                    icon: (
                      <CustomArrowIcon
                        additionalStyles={{
                          fontSize: "1.6rem",
                          rotate: "180deg",
                        }}
                        className="mr-2"
                      />
                    ),
                  },
                  {
                    /* {
                    label: "Initiated & Received Transferred Schedule",
                    icon: (
                      <CustomArrowHeightIcon
                        additionalStyles={{
                          fontSize: "1.6rem",
                          rotate: "90deg",
                        }}
                        className="mr-2"
                      />
                    ),
                  }, */
                  },
                ]
              : []),
          ].map((item) => (
            <div
              className={clsx("d-flex f-align-center ml-2", {
                "ml-6 mt-3": appData.isTabletView,
              })}
            >
              {item.className && (
                <div
                  className={clsx(
                    "mr-2",
                    classes.legendHint,
                    classes[item.className]
                  )}
                ></div>
              )}
              {item.icon}
              <Typography variant="body2">{item.label}</Typography>
            </div>
          ))}
        </div>
      </div>
      {state.openForceSubmitDialog && (
        <ForceSubmitDialog
          open={state.openForceSubmitDialog}
          pendingItems={appData.pendingItems.map((date, index) => ({
            date,
            id: index,
          }))}
          onClose={handleClose}
          kamId={kamId}
          // forceSubmitReasonList={state.forceSubmitReasonList}
          // onForceSubmit={handleForceSubmit}
        />
      )}
      {state.openRevertForcedSubmitDialog && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.openRevertForcedSubmitDialog}
          contentText={
            <div>
              <Typography variant="body1">
                Are you certain that you wish to undo the force submit action
                for this date({state.forcedSubmitDate})?
              </Typography>
            </div>
          }
          onConfirm={() => handleRevertForcedSubmit(state.forcedSubmitDate)}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              openRevertForcedSubmitDialog: false,
            }))
          }
          isConfirmDisabled={state.isLoading}
          positiveActionLabel={<>OK</>}
          negativeActionLabel={null}
        />
      )}
    </>
  );
};

export default ViewCalender;
