import { createRef, useState, useEffect } from "react";
import clsx from "clsx";
import DatePicker from "react-datepicker";
import MaskedInput from "react-text-mask";
import TextField from "@material-ui/core/TextField";
import { getDateString, getFormattedDate, getUTCDateString } from "utils";
import Tooltip from "@material-ui/core/Tooltip";
import DateRangeIcon from "@material-ui/icons/DateRange";
import useStyles from "./style";

export default function Datepicker(props) {
  const classes = useStyles();
  const selectedDate = props.selected ? new Date(props.selected) : null;
  const [datepickerState, setDatepickerState] = useState({
    selected: selectedDate,
    isInViewMode: !!selectedDate,
    ref: createRef(),
    isInSuccess: false,
    focused: false,
    error: "",
  });

  const showSuccessState = () => {
    setDatepickerState((prevState) => {
      return {
        ...prevState,
        isInSuccess: true,
      };
    });

    // setTimeout(() => {
    //   setDatepickerState(prevState => {
    //     return {
    //       ...prevState,
    //       isInSucces: false
    //     };
    //   });
    // }, 5000);
  };

  const handleDateChange = (selectedDate) => {
    setDatepickerState((prevState) => {
      return {
        ...prevState,
        selected: selectedDate,
        isInViewMode: !!selectedDate,
        error: "",
      };
    });

    if (props.onChange) {
      props.onChange(selectedDate, showSuccessState);
    }

    if (!selectedDate || props.mask) {
      datepickerState.ref.current.setOpen(false);
    }
  };

  const openCalendar = (() => {
    let timeout = null;
    return () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (datepickerState.ref.current) {
          datepickerState.ref.current.setOpen(true);
          return;
        } else {
          openCalendar();
        }
      }, 100);
    };
  })();

  // const handleRawChange = (date) => {
  //   let isValid = true;
  //   if (date) {
  //     const regExp = /^(\d\d?)\/(\d\d?)\/(\d{4})$/;
  //     let matches = date.match(regExp);
  //     isValid = matches;
  //     let maxDate = [0, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  //     if (matches) {
  //       const month = parseInt(matches[1]);
  //       const date = parseInt(matches[2]);
  //       const year = parseInt(matches[3]);

  //       isValid = month <= 12 && month > 0;
  //       isValid &= date <= maxDate[month] && date > 0;

  //       const leapYear = year % 400 == 0 || (year % 4 == 0 && year % 100 != 0);
  //       isValid &= month != 2 || leapYear || date <= 28;
  //     }
  //   }

  //   const maskInput = props.maskPlaceholder || "_";
  //   const maskInputPlaceholder = `${maskInput}${maskInput}/${maskInput}${maskInput}/${maskInput}${maskInput}${maskInput}${maskInput}`;
  //   setDatepickerState((prevState) => {
  //     return {
  //       ...prevState,
  //       isError: maskInputPlaceholder === date ? false : !isValid,
  //     };
  //   });

  //   return isValid;
  // };

  const handleRawChange = ({ target }) => {
    const { value: date } = target;
    const isValid = !isNaN(Date.parse(date));
    const isEmpty = !(date || "")
      .replaceAll("/", "")
      .split(props.maskPlaceholder || "_")
      .some((item) => item);
    setDatepickerState((prevState) => ({
      ...prevState,
      error: isEmpty || !date ? "" : isValid ? "" : "Invalid date",
    }));
  };

  useEffect(() => {
    let newState = {
      selected: null,
      isInViewMode: false,
      ref: datepickerState.ref,
      isInSuccess: datepickerState.isInSuccess,
    };

    if (props.selected) {
      newState = {
        ...newState,
        selected: new Date(props.selected),
        isInViewMode: true,
      };
    }

    setDatepickerState(newState);
    //eslint-disable-next-line
  }, [props.selected]);

  let isDatepickerVisible = true;
  if (props.hasViewMode) {
    isDatepickerVisible = !datepickerState.isInViewMode;
  }

  if (props.onReady) {
    props.onReady({
      setDate: (date) => {
        setDatepickerState((prevState) => ({
          ...prevState,
          selected: date ? new Date(date) : null,
          isInViewMode: !!date,
        }));
      },
    });
  }

  const fullWidth =
    props.fullWidth !== undefined && props.fullWidth !== null
      ? props.fullWidth
      : true;

  return (
    <div className="d-flex">
      <div
        className={clsx(props.classes?.wrapper, {
          "w-100": fullWidth,
        })}
      >
        {isDatepickerVisible && (
          <DatePicker
            preventClear
            mask
            {...props}
            // calendarClassName={clsx(classes.calendar, props.classes?.calendar)}
            // dayClassName={day => {
            //   console.log('DAY', day);
            //   return clsx(classes.day, props.classes?.day);
            // }}
            classes={{
              input: {
                root: "grid-datepicker",
              },
            }}
            popperClassName={clsx(classes.popper, props.classes?.popper)}
            wrapperClassName={clsx(props.classes?.datepickerWrapper, "w-100")}
            customInput={
              props.mask ? (
                <MaskedInput
                  {...props}
                  showMask
                  mask={
                    props.maskArray || [
                      /\d/,
                      /\d/,
                      "/",
                      /\d/,
                      /\d/,
                      "/",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]
                  }
                  placeholderChar={props.maskPlaceholder || "_"}
                  keepCharPositions={true}
                  render={(maskRef, props) => (
                    <TextField
                      {...props}
                      value={
                        props.defaultValue ||
                        [
                          props.maskPlaceholder || "_",
                          props.maskPlaceholder || "_",
                          "/",
                          props.maskPlaceholder || "_",
                          props.maskPlaceholder || "_",
                          "/",
                          props.maskPlaceholder || "_",
                          props.maskPlaceholder || "_",
                          props.maskPlaceholder || "_",
                          props.maskPlaceholder || "_",
                        ].join("")
                      }
                      InputProps={{
                        endAdornment: <DateRangeIcon />,
                      }}
                      variant={props.inputVariant || "outlined"}
                      classes={props.classes?.input}
                      error={
                        (props.required && !datepickerState.selected) ||
                        !!datepickerState.error
                      }
                      helperText={
                        (props.required &&
                          !datepickerState.selected &&
                          "Required") ||
                        props.defaultHelperText ||
                        datepickerState.error
                      }
                      fullWidth={fullWidth}
                      label={props.label}
                      placeholder={props.placeholder || props.label}
                      size={props.size || "medium"}
                      inputRef={(node) => maskRef(node)}
                      onKeyDown={(event) =>
                        parseInt(event.keyCode === 13) && event.preventDefault()
                      }
                    />
                  )}
                />
              ) : (
                <TextField
                  variant={props.inputVariant || "outlined"}
                  classes={props.classes?.input}
                  error={props.required && !datepickerState.selected}
                  helperText={
                    (props.required &&
                      !datepickerState.selected &&
                      "Required") ||
                    props.defaultHelperText ||
                    ""
                  }
                  InputProps={{
                    endAdornment: <DateRangeIcon />,
                  }}
                  fullWidth={fullWidth}
                  type="text"
                  label={props.label}
                  placeholder={props.placeholder || props.label}
                  size={props.size || "medium"}
                  value={
                    datepickerState.selected
                      ? getDateString(datepickerState.selected)
                      : ""
                  }
                  onChange={(event) => {}}
                />
              )
            }
            ref={datepickerState.ref}
            onFocus={() => {
              setDatepickerState((prevState) => {
                return {
                  ...prevState,
                  isInSuccess: false,
                  focused: true,
                };
              });
            }}
            onClickOutside={() => {
              setDatepickerState((prevState) => {
                return {
                  ...prevState,
                  isInViewMode: !!prevState.selected,
                  focused: false,
                  error: "",
                };
              });
            }}
            onBlur={() => {
              setDatepickerState((prevState) => {
                return {
                  ...prevState,
                  isInViewMode: !!prevState.selected,
                };
              });
            }}
            selected={datepickerState.selected}
            onChangeRaw={(event) => {
              props.mask ? handleRawChange(event) : event.preventDefault();
            }}
            onKeyDown={(event) => {
              if (props.mask) {
                return false;
              }
              if (
                parseInt(event.keyCode) === 8 ||
                parseInt(event.keyCode) === 46
              ) {
                if (datepickerState.selected && !props.preventClear) {
                  handleDateChange(null);
                }
              }

              if (parseInt(event.keyCode) !== 9) {
                event.preventDefault();
              }
            }}
            //onSelect={handleDateChange}
            onChange={handleDateChange}
            popperPlacement={props.placement || "bottom"}
            popperModifiers={{
              flip: {
                behavior: ["bottom"],
              },
              preventOverflow: {
                enabled: true,
                escapeWithReference: false,
                boundariesElement: "viewport",
              },
              hide: {
                enabled: false,
              },
            }}
          />
        )}
        {!isDatepickerVisible && datepickerState.selected && (
          <p
            className={clsx(props.viewClassName, "p-0 m-0")}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setDatepickerState((prevState) => {
                return {
                  ...prevState,
                  isInViewMode: false,
                };
              });
              openCalendar();
            }}
          >
            {getDateString(datepickerState.selected)}
            {/* {getFormattedDate(datepickerState.selected)} */}
          </p>
        )}
        <span className="display-error" />
      </div>
      {props.tooltip && (
        <Tooltip
          className="ml-2"
          title={props.tooltip}
          placement={props.tooltipPlacement || "top"}
        >
          {/* <img src={InfoLightIcon} alt='info icon' className='info-icon' /> */}
        </Tooltip>
      )}
    </div>
  );
}
