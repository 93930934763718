import { Switch, Route } from "react-router-dom";
import View from "./view";
import History from "./history";
import ExcludedHistory from "./excludedHistory";

const DCNComponent = ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.path} component={View} />
      <Route exact path={`${match.path}/history`} component={History} />
      <Route
        exact
        path={`${match.path}/excluded-records`}
        component={ExcludedHistory}
      />
    </Switch>
  );
};

export default DCNComponent;
