import { http, responseFormatter } from "utils";

const get = () => {
  return responseFormatter(
    http.get("/message/get-messages", {
      setAuthHeader: true,
    })
  );
};

const createMessage = (data) => {
  return responseFormatter(
    http.post("/message/create-message", data, {
      setAuthHeader: true,
    })
  );
};

const markMessageRead = (data) => {
  return responseFormatter(
    http.patch(`/message/mark-as-read/`, data, {
      setAuthHeader: true,
    })
  );
};

const createRoom = (data) => {
  return responseFormatter(
    http.post("/message/chat-rooms", data, {
      setAuthHeader: true,
    })
  );
};

const getRoom = () => {
  return responseFormatter(
    http.get("/message/chat-rooms", {
      setAuthHeader: true,
    })
  );
};

const updateGroupName = (data) => {
  return responseFormatter(
    http.put("/message/chat-rooms", data, {
      setAuthHeader: true,
    })
  );
};

const adduser = (data) => {
  return responseFormatter(
    http.post("/message/chat-room-users", data, {
      setAuthHeader: true,
    })
  );
};

const removeUserFromGroup = (query = "") => {
  return responseFormatter(
    http.delete(`/message/remove-user${query}`, {
      setAuthHeader: true,
    })
  );
};

const leaveGroup = (query = "") => {
  return responseFormatter(
    http.delete(`/message/left-chat-rooms${query}`, {
      setAuthHeader: true,
    })
  );
};

const MessengerService = {
  get,
  adduser,
  updateGroupName,
  getRoom,
  createRoom,
  markMessageRead,
  createMessage,
  removeUserFromGroup,
  leaveGroup,
};

export default MessengerService;
