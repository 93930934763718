import { Fragment } from "react";
import {
  BarChart,
  Bar,
  LabelList,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import useStyles from "./style";
import NoRecords from "assets/images/norecord.svg";

const BarChartReport = ({
  totalCustomerArray = [],
  display = "",
  billing = true,
  settlement = false,
  showBarChartLegends = false,
  barChartSelectedValue = null,
  appData,
}) => {
  const classes = useStyles();
  const data = [];
  for (const customer in totalCustomerArray) {
    const {
      customerName,
      Total: { total_bill, total_pay },
      branches,
    } = totalCustomerArray[customer];
    data.push({
      name: customerName,
      totalSettlement: total_pay,
      totalBill: total_bill,
      total: parseFloat(total_pay) + parseFloat(total_bill),
      branches,
    });
  }

  const filteredCustomerData = data.filter(
    (item) => item.name !== "Gross Profit" && item.name !== "TOTAL SALES"
  );

  const filteredData = filteredCustomerData.filter((data) => {
    if (barChartSelectedValue?.length === 0 || barChartSelectedValue === null) {
      return true;
    }
    return barChartSelectedValue.some((value) => value.name === data.name);
  });

  const amounts =
    billing && settlement
      ? filteredData
          .map((data) => parseFloat(data.totalSettlement || 0))
          .concat(filteredData.map((data) => parseFloat(data.totalBill || 0)))
      : billing
      ? filteredData.map((data) => parseFloat(data.totalBill || 0))
      : filteredData.map((data) => parseFloat(data.totalSettlement || 0));
  const maxAmount = Math.max(...amounts);

  return (
    <>
      {filteredData.length > 0 ? (
        <div className={classes.barChartHeadrer}>
          <div style={{ width: filteredData.length * 150, minWidth: "100%" }}>
            <ResponsiveContainer height={appData.isTabletView ? 610 : 470}>
              <BarChart
                style={{ backgroundColor: "#FFFFFF" }}
                width={filteredData.length * 150}
                height={470}
                data={filteredData}
                margin={{ top: 20, bottom: 80, left: 50 }}
                barGap={0}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  interval={0}
                  tick={({ payload: { value }, x, y, textAnchor, index }) => (
                    <g transform={`translate(${x},${y})`}>
                      <foreignObject x={-60} width={125} height={90}>
                        <div className={classes.xAxisLabel}>{value}</div>
                      </foreignObject>
                    </g>
                  )}
                  label={{
                    value:
                      display === "profit and loss"
                        ? "Customers ⟶"
                        : display === "KAM"
                        ? "KAM ⟶"
                        : display === "pdxCompany"
                        ? "PDX Company ⟶"
                        : "IC ⟶",
                    position: "insideBottom",
                    offset: -88,
                    color: "#333",
                    fontWeight: "bold",
                  }}
                />
                <YAxis
                  label={{
                    value: `${
                      billing && settlement
                        ? "Billing/Settlement Amount ($)"
                        : billing
                        ? "Billing Amount ($)"
                        : "Settlement Amount ($)"
                    } ⟶`,
                    angle: -90,
                    position: "insideLeft",
                    dy: 80,
                    offset: -40,
                    color: "#333",
                    fontWeight: "bold",
                  }}
                  domain={[0, maxAmount]}
                  yAxisId={0}
                  tickCount={30}
                />
                <Tooltip formatter={(value) => `$${value}`} />
                {billing && settlement ? (
                  <Fragment>
                    <Bar
                      dataKey="totalBill"
                      fill="#DD7A59"
                      name="Billing"
                      width={120}
                      barSize={50}
                    >
                      <LabelList
                        dataKey="totalBill"
                        position="top"
                        formatter={(value) => `$${value}`}
                        fontSize={9}
                      />
                    </Bar>
                    <Bar
                      dataKey="totalSettlement"
                      fill="#9278e2"
                      name="Settlement"
                      width={120}
                      barSize={50}
                    >
                      <LabelList
                        dataKey="totalSettlement"
                        position="top"
                        formatter={(value) => `$${value}`}
                        fontSize={9}
                      />
                    </Bar>
                  </Fragment>
                ) : billing ? (
                  <Bar
                    dataKey="totalBill"
                    fill="#DD7A59"
                    name="Billing"
                    width={120}
                    barSize={50}
                  >
                    <LabelList
                      dataKey="totalBill"
                      position="top"
                      formatter={(value) => `$${value}`}
                      fontSize={9}
                    />
                  </Bar>
                ) : (
                  <Bar
                    dataKey="totalSettlement"
                    fill="#9278e2"
                    name="Settlement"
                    width={120}
                    barSize={50}
                  >
                    <LabelList
                      dataKey="totalSettlement"
                      position="top"
                      formatter={(value) => `$${value}`}
                      fontSize={9}
                    />
                  </Bar>
                )}
                {/* {showBarChartLegends && ( */}
                <Legend
                  align="center"
                  verticalAlign="bottom"
                  wrapperStyle={{
                    position: "relative",
                    paddingTop: "12px",
                    paddingLeft: "60px",
                    bottom: "40px",
                  }}
                />
                {/* )} */}
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <div className="d-flex f-justify-center" style={{ height: "26rem" }}>
          <img className={classes.noRecordsImg} src={NoRecords} />
        </div>
      )}
    </>
  );
};

export default BarChartReport;
