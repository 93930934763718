import ReactDOM from "react-dom";
import React, { useEffect, useState, useRef } from "react";
import { InfoWindow } from "google-maps-react";

const InfoWindowEx = (props) => {
  const infoWindowRef = useRef(null);
  const [contentElement, setContentElement] = useState(null);

  useEffect(() => {
    if (props.children) {
      setContentElement(document.createElement(`div`));
    }
  }, [props.children]);

  useEffect(() => {
    if (contentElement) {
      ReactDOM.render(React.Children.only(props.children), contentElement);
      infoWindowRef.current.infowindow.setContent(contentElement);
    }
  }, [contentElement, props.children]);

  return <InfoWindow ref={infoWindowRef} {...props} />;
};

export default InfoWindowEx;
