import { http, responseFormatter } from "utils";

const getSubmissionHistory = (kamId, month, year) => {
  const timeZoneOffset = new Date().getTimezoneOffset();
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let query = `?kamId=${kamId}&month=${month}&year=${year}&timeZoneString=${timeZoneString}`;
  if (timeZoneOffset >= 0) {
    query += `&offSet=${timeZoneOffset}`;
  }

  return responseFormatter(
    http.get(`kam-submission-history${query}`, {
      setAuthHeader: true,
    })
  );
};

const revertForcedSubmit = (data) => {
  return responseFormatter(
    http.patch("/revert-forced-submit", data, {
      setAuthHeader: true,
    })
  );
};

const getSubmissionHistoryWeek = (kamId, date) => {
  const timeZoneOffset = new Date().getTimezoneOffset();
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let query = `?kamId=${kamId}&date=${date}&timeZoneString=${timeZoneString}`;
  if (timeZoneOffset >= 0) {
    query += `&offSet=${timeZoneOffset}`;
  }

  return responseFormatter(
    http.get(`/weekly-submission-history${query}`, {
      setAuthHeader: true,
    })
  );
};

const getKamSetting = (kamId) => {
  return responseFormatter(
    http.get(`user/${kamId}/settings`, {
      setAuthHeader: true,
    })
  );
};

const getPendingItems = (kamId) => {
  const timeZoneOffset = new Date().getTimezoneOffset();
  let query = `?kamId=${kamId}`;
  if (timeZoneOffset >= 0) {
    query += `&offSet=${timeZoneOffset}`;
  }

  return responseFormatter(
    http.get(`/pending-items${query}`, {
      setAuthHeader: true,
    })
  );
};

const getTransferRecords = (query) => {
  return responseFormatter(
    http.get(`/get-all-transferred-schedule/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const ScheduleCalenderService = {
  getSubmissionHistory,
  getTransferRecords,
  getSubmissionHistoryWeek,
  getKamSetting,
  revertForcedSubmit,
  getPendingItems,
};

export default ScheduleCalenderService;
