import { getAppConfig } from './';

let alreadyRedirected = false;
const sessionExpireMsg = 'Session expired. Redirecting to login screen...';
const redirectToLogin = () => {
	if (alreadyRedirected) return;
	alreadyRedirected = true;
	const { appUrl } = getAppConfig();
	window.location.replace(appUrl);
};

const responseFormatter = async (
	api,
	defaultMsg = 'Something went wrong, please contact your system administrator.'
) => {
	try {
		const { data: response, status, ...request } = await api;

		if (status === 202 && response.error) {
			throw response;
		}

		if (
			request.request.responseType === 'blob' ||
			request.headers['content-type'] === 'application/zip'
		) {
			return {
				data: response,
				message: response.message,
				error: null,
			};
		}

		return {
			data: response && response.data,
			message: response.message,
			status,
			error: null,
		};
	} catch (err) {
		let accessDeniedError = '';
		if (!err.response) {
			console.log(
				'IS NETWORK ERROR',
				err.message && err.message.toLowerCase().indexOf('network error') === -1
			);
			return {
				data: null,
				status: null,
				error:
					err.message &&
					err.message.toLowerCase().indexOf('network error') === -1
						? err.message || err.error || defaultMsg
						: [{ message: '' }],
			};
		}
		if (
			err &&
			err.response &&
			err.response.status === 401 &&
			err.response?.data?.error?.toLowerCase() !== 'under maintenance'
		) {
			accessDeniedError = sessionExpireMsg;
			redirectToLogin();
		}

		return {
			data: null,
			status: err?.response?.status ? err.response.status : null,
			error:
				(err.response && err.response.data.error) ||
				accessDeniedError ||
				defaultMsg,
		};
	}
};

export default responseFormatter;
