import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseLine from "@material-ui/core/CssBaseline";
import { ToastContainer } from "react-toastify";
import { AppContextConsumer, AppContextProvider } from "shared/contexts";
import { THEME_VARIANT } from "shared/constants";
import { initializeHttp, setAppConfig, setPermissionMatrix } from "utils";
import { ROLE_PERMISSIONS } from "./modules/shared/constants";
import AppModule from "./modules";
import config from "./config";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

// Initialize all necessary parts.
setAppConfig({
  ...config,
  appUrl: window.location.origin,
});
initializeHttp(config);
setPermissionMatrix(ROLE_PERMISSIONS);

const RenderModule = ({ appData, updateContextData }) => {
  const isTabletView = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  if (appData.isTabletView !== isTabletView) {
    updateContextData("isTabletView", isTabletView);
  }

  // Disable logs for all env except development
  if (process.env.NODE_ENV !== "development") {
    console.log = () => {};
  }

  return (
    <>
      <CssBaseLine />
      <AppModule />
    </>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <AppContextProvider>
      <ToastContainer
        position="top-right"
        autoClose={15000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        pauseOnHover
      />
      <AppContextConsumer>
        {({ theme, themeVariant, updateContextData, appData }) => {
          return (
            <ThemeProvider
              theme={
                themeVariant === THEME_VARIANT.LIGHT
                  ? { ...theme.light }
                  : { ...theme.dark }
              }
            >
              <RenderModule
                appData={appData}
                updateContextData={updateContextData}
              />
            </ThemeProvider>
          );
        }}
      </AppContextConsumer>
    </AppContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
