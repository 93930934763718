import { createContext, useState } from "react";
import {
  APP_NAME,
  DefaultTheme,
  noop,
  THEME_VARIANT,
  PAGE_KEYS,
} from "../constants";
import { getPageConfig } from "utils";

const subscriptions = {};
export const AppContext = createContext({
  appName: APP_NAME,
  headerElements: [],
  theme: DefaultTheme,
  publish: noop,
  subscribe: noop,
  unsubscribe: noop,
});

export const AppContextConsumer = AppContext.Consumer;

export const AppContextProvider = ({ children }) => {
  const subscribe = (topic, callback) => {
    if (!(typeof callback === "function" && typeof topic === "string")) {
      throw new Error(
        "Subscription topic and callback need to be of type string and function respectively."
      );
    }

    let subscriptionId = null;
    subscriptionId = Math.random();
    if (!subscriptions[topic]) {
      subscriptions[topic] = {};
    }
    subscriptions[topic][`subscriber${subscriptionId}`] = callback;

    return subscriptionId;
  };

  const unsubscribe = (topic, subscriptionId) => {
    delete subscriptions[topic][`subscriber${subscriptionId}`];
  };

  const publish = (topic, data) => {
    if (subscriptions[topic]) {
      Object.values(subscriptions[topic]).forEach((subscriber) => {
        subscriber(data);
      });
    }
  };

  const updateContextData = (key, data) => {
    if (key == "isTabletView") {
      setState((prevState) => ({
        ...prevState,
        appData: {
          ...prevState.appData,
          isTabletView: false,
        },
      }));
    }
    if (Object.prototype.hasOwnProperty.call(state.appData, key)) {
      setState((prevState) => ({
        ...prevState,
        appData: {
          ...prevState.appData,
          [key]: data,
        },
      }));
    } else {
      console.error(`${key} does not exist in app context.`);
    }
  };

  const setHeaderElements = (headerElements) => {
    setState((prevState) => ({
      ...prevState,
      headerElements,
    }));
  };

  // const defaultTheme = (getPageConfig(PAGE_KEYS.GLOBAL) || {}).theme || 'light';

  const [state, setState] = useState({
    appName: APP_NAME,
    appData: {
      customers: [],
      IC: [],
      icList: [],
      users: [],
      pendingItems: [],
      transferRecords: [],
      isTabletView: false,
      notificationCount: 0,
      alertBar: null,
      fetchMessages: false,
    },
    headerElements: [],
    theme: DefaultTheme,
    themeVariant:
      (getPageConfig(PAGE_KEYS.GLOBAL) || {}).theme || THEME_VARIANT.LIGHT,
    publish,
    subscribe,
    unsubscribe,
    updateContextData,
    setHeaderElements,
  });

  return <AppContext.Provider value={state}>{children}</AppContext.Provider>;
};
