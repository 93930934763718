import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  datepickerWrapper: {
    width: 160,
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },

    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  tabs: {
    background: "#eeeeee",
    maxWidth: "24rem",
    marginTop: "1.5rem",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
  slider: {
    width: 220,
  },
  highlightWeekOff: {
    background: "#f1958e",
  },
  highlightForceSubmit: {
    background: "#2196f3",
  },
  totalDS: {
    backgroundColor: "#4b4747",
    color: "#ffffff",
    marginRight: "5px",
    marginTop: "5px",
  },
  totalSchedule: {
    backgroundColor: "#00B4D8",
    color: "#ffffff",
    marginRight: "5px",
    marginTop: "5px",
  },
  directSchedule: {
    backgroundColor: "#4F7942",
    color: "#ffffff",
    marginRight: "5px",
    marginTop: "5px",
  },
  totalOnTime: {
    backgroundColor: "#72c658",
    color: "#ffffff",
    marginRight: "5px",
    marginTop: "5px",
  },
  scheduleUnderwent: {
    backgroundColor: "#800080",
    color: "#ffffff",
    marginRight: "5px",
    marginTop: "5px",
  },
  delayedSubmission: {
    backgroundColor: "red",
    color: "#ffffff",
    marginRight: "5px",
    marginTop: "5px",
  },
  notSubmitted: {
    backgroundColor: "rgb(221 200 21)",
    color: "#ffffff",
    marginRight: "5px",
    marginTop: "5px",
  },
  totalSubmittedSchedule: {
    backgroundColor: "#4b4747",
    color: "#ffffff",
    marginRight: "5px",
    marginTop: "5px",
  },
  canBeSubmitOnTime: {
    backgroundColor: "#FB6F92",
    color: "#ffffff",
    marginRight: "5px",
    marginTop: "5px",
  },
  weekOff: {
    width: 16,
    height: 16,
    background: "#90E0EF",
  },
  forceSubmit: {
    width: 16,
    height: 16,
    background: "#FFB3C1",
  },
  paperSpacing: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important ",
    borderTopLeftRadius: "0px",
  },
  gridPaperSpacing: {
    marginTop: "20px !important",
    paddingTop: "0px !important",
    paddingBottom: "0px !important ",
  },
  paper: (props) => ({
    "& .MuiDialogTitle-root": {
      paddingBottom: "0px",
      color: "#004700",
    },
    minWidth: props.isTabletView ? "98%" : "80%",
  }),
  chip: {
    margin: theme.spacing(0.5),
  },
  kamDropdown: {
    width: 300,
  },
  fixedHeader: {
    width: "100%",
    top: "64px",
    height: "70px",
    zIndex: 1000,
    position: "sticky",
    background: "#fafafa",
  },
  divider: { height: "2px", margin: "0px 20px", color: "gray" },
  barChartHeight: {
    borderRadius: 1,
    borderTopLeftRadius: 0,
  },
  paperHeight: {
    height: "26rem",
    display: "flex",
    alignContent: "space-around",
    justifyContent: "center",
    flexWrap: "wrap",
  },
}));

export default useStyles;
