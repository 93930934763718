import makeStyles from "@material-ui/core/styles/makeStyles";
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";

const useStyles = makeStyles((theme) => ({
  paper: (props) => ({
    maxWidth: props.isTabletView ? "98%" : "89%",
    minWidth: props.isTabletView ? "98%" : "89%",

    maxHeight: props.isTabletView ? "95%" : "90%",
  }),

  datepickerWrapper: {
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  autocompletePaper: {
    [theme.breakpoints.down("sm")]: {
      maxHeight: 200,
    },
  },
  field: (props) => ({
    width: props.isTabletView ? 264 : 200,
    marginRight: 24,
    marginBottom: 5,
  }),

  fieldRate: (props) => ({
    width: props.isTabletView ? 264 : 200,
    marginBottom: 5,
  }),
  textArea: {
    width: 350,
    marginRight: 24,
  },
  aaMgrNotes: {
    width: 350,
    marginRight: 24,
    marginLeft: 16,
  },
  paperWidthSm: {
    maxWidth: "98%",
  },
  warningIcon: {
    color: "#d7d718",
    zIndex: 1,
    position: "relative",
    marginTop: "2px",
  },
  warningIconHeight: {
    height: "24px",
    position: "relative",
  },
  exclamationMark: {
    width: "3px",
    height: "9px",
    background: "black",
    position: "absolute",
    top: "11px",
    left: "11px",
  },
  aaMgrNotesTableHeaderCell: { minWidth: "255px" },
  tabContainer: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  existingButton: {
    // marginTop: -14,
    marginLeft: 12,
  },
  customSpace: {
    whiteSpace: "pre",
  },
  addLookupEmailpaperSize: {
    width: 530,
  },
  buttonStyle: {
    borderRadius: "1rem",
    background: "rgb(0 71 0)",
    marginTop: "10px",
    border: "0px",
    height: "30px",
    minWidth: "28px",
    width: "28px",
    marginRight: "4px",
  },
  AaMgrbuttonStyle: {
    borderRadius: "1rem",
    background: "rgb(0 71 0)",
    border: "0px",
    height: "30px",
    minWidth: "28px",
    width: "28px",
    marginLeft: "8px",
  },
  inputGreen: {
    "& .MuiInput-underline:after": {
      borderBottomColor: green[500],
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: green[500],
      },
    },
  },
  inputOrange: {
    "& .MuiInput-underline:after": {
      borderBottomColor: orange,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: orange,
      },
    },
  },
}));

export default useStyles;
