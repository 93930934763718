import { http, responseFormatter } from "utils";

const getLookupTypes = (query) => {
  return responseFormatter(
    http.get(`/lookup-types`, {
      setAuthHeader: true,
    })
  );
};

const editLookup = (payload) => {
  return responseFormatter(
    http.put(`/lookup`, payload, {
      setAuthHeader: true,
    })
  );
};

const createLookup = (payload) => {
  return responseFormatter(
    http.post(`/lookup`, payload, {
      setAuthHeader: true,
    })
  );
};

const getAllLookupData = (querystring) => {
  return responseFormatter(
    http.get(`/all-lookup/${querystring}`, {
      setAuthHeader: true,
    })
  );
};

const downloadLookupData = (querystring) => {
  return responseFormatter(
    http.get(`/download/lookup/${querystring}`, {
      setAuthHeader: true,
    })
  );
};

const LookupService = {
  getLookupTypes,
  editLookup,
  createLookup,
  getAllLookupData,
  downloadLookupData,
};

export default LookupService;
