import makeStyles from "@material-ui/core/styles/makeStyles";
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";
import yellow from "@material-ui/core/colors/yellow";
import blue from "@material-ui/core/colors/blue";
import pink from "@material-ui/core/colors/pink";
import grey from "@material-ui/core/colors/grey";
import purple from "@material-ui/core/colors/purple";

const useStyles = makeStyles((theme) => ({
  calenderSize: {
    width: "100%",
  },
  datepickerWrapper: {
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },

  calenderWrapper: {
    "& .rbc-time-content,& .rbc-label,& .rbc-time-gutter.rbc-time-column,& .rbc-day-slot.rbc-time-column":
      {
        display: "none",
      },
    "& .rbc-toolbar-label": {
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        maxWidth:
          navigator.userAgent.indexOf("Firefox") !== -1 ? "198px" : "auto",
      },
    },
    "& .rbc-event-content": {
      whiteSpace: "normal",
      fontSize: "13.4px",
      textWrap: "balance !important",
    },
    "& .rbc-month-row": {
      overflow: "auto",
    },
  },

  kam: {
    width: 250,
  },
  pageLoader: {
    position: "fixed",
    top: "50%",
    left: "50%",
  },
  confirmationPaper: {
    minWidth: 300,
  },
  pendingItemsDropdown: {
    maxHeight: 200,
    minWidth: 120,
  },
  calendarDay: {
    borderRadius: 4,
    color: `${theme.palette.common.black} !important`,
  },
  notCreatedDay: {
    background: red[500],

    "&$weekend": {
      backgroundImage: `linear-gradient(to bottom right, ${grey[200]}, ${red[500]} )`,
    },
  },
  notSubmittedDay: {
    background: yellow[500],
    "&$weekend": {
      backgroundImage: `linear-gradient(to bottom right,${grey[200]}, ${yellow[500]} )`,
    },
  },
  partialSubmittedDay: {
    background: orange[600],
    "&$weekend": {
      backgroundImage: `linear-gradient(to bottom right,${grey[200]}, ${orange[600]} )`,
    },
  },
  completeSubmittedDay: {
    background: green[500],
    "&$weekend": {
      backgroundImage: `linear-gradient(to bottom right,${grey[200]}, ${green[500]} )`,
    },
  },
  underReview: {
    background: purple[300],
    "&$weekend": {
      backgroundImage: `linear-gradient(to bottom right,${grey[200]}, ${purple[300]} )`,
    },
  },
  bsApproved: {
    background: blue[300],
    "&$weekend": {
      backgroundImage: `linear-gradient(to bottom right,${grey[200]}, ${blue[300]} )`,
    },
  },
  forcedSubmitDay: {
    background: `${blue[500]} !important`,
    "&$weekend": {
      backgroundImage: `linear-gradient(to bottom right,${grey[200]}, ${blue[500]} )`,
    },
  },
  dateLegend: {
    marginTop: 110,
    left: 8,
  },
  legendHint: {
    width: 16,
    height: 16,
    border: `1px solid ${theme.palette.divider}`,
  },
  backgroundWhite: {
    background: "white",
  },
  weekoff: {
    backgroundImage: `linear-gradient(to bottom right,white, ${grey[400]} )`,
  },
  colorNone: {
    color: "transparent !important",
  },
  transfer: {
    background: pink[200],
    "&$weekend": {
      backgroundImage: `linear-gradient(to bottom right,${grey[200]}, ${pink[200]} )`,
    },
  },
  legend: { width: "70rem" },
  weekend: {
    //border: "1px solid black",
    //backgroundImage: "linear-gradient(to bottom right, red, yellow)",
  },
  bgColorNone: {
    background: "none",
  },
  dialog: {
    height: 500,
    width: 700,
  },

  ColoredDateCellWrapper: {
    backgroundColor: "black",
  },
  iconWrapper: {
    position: "absolute",
    top: 2,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  heightIcon: {
    fontSize: "2.7rem",
    rotate: "90deg",
    stroke: "black",
    strokeWidth: "0.9px",
  },
  legendHeightIcon: {
    fontSize: "1.7rem",
    rotate: "90deg",
  },
  arrowStroke: {
    fill: "none",
    stroke: "black",
    strokeWidth: 1,
    strokeLinejoin: "round",
  },
  arrowFill: {
    fill: "pink",
    stroke: "none",
  },
}));

export default useStyles;
