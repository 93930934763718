import makeStyles from '@material-ui/core/styles/makeStyles';
const useStyles = makeStyles(theme => ({
	paper: {
		width: 480,
	},
	progressBtn: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}));

export default useStyles;
