import { useState } from "react";
import { toast } from "react-toastify";
import Service from "../service";
import { getFileExtension } from "utils";
import { Dialog } from "shared/components";
import { noop } from "shared/constants";
import {
  MAX_ALLOWED_FILE_SIZE,
  SUPPORTED_FILE_FORMATS,
} from "../../shared/constants";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import useStyles from "./style";

const defaulState = {
  file: null,
  isLoading: false,
};

const BulkUpload = ({
  open = false,
  exchangeType = null,
  onClose = noop,
  onSheetError = noop,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaulState);

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, ...defaulState }));
    onClose();
  };

  const handleFileChange = (evt) => {
    const file = evt.target.files[0];
    const { name, size } = file;
    const fileExtension = getFileExtension(name);

    if (size > MAX_ALLOWED_FILE_SIZE.COMMON * 1048576) {
      return toast.error(
        `Size of "${file.name}" is more than ${MAX_ALLOWED_FILE_SIZE.COMMON} mb.`
      );
    } else if (
      !SUPPORTED_FILE_FORMATS.COMMON.includes(fileExtension.toLowerCase())
    ) {
      return toast.error(
        `Only ${SUPPORTED_FILE_FORMATS.COMMON.join(
          ", "
        )} formats are supported.`
      );
    }
    setState((prevState) => ({ ...prevState, file }));
  };

  const handleBulkUploads = async (file, type) => {
    const formData = new FormData();
    formData.append("media", file);
    formData.append("type", type);

    setState((prevState) => ({ ...prevState, isLoading: true }));
    const { error } = await Service.bulkUploads(formData);
    if (error) {
      setState((prevState) => ({ ...prevState, isLoading: false, file: null }));
      if (Array.isArray(error)) {
        let errorKams = {};
        let errorMessage = error.reduce((acc, val) => {
          (val?.errors || []).map((error) => {
            const kamNames = error.kamNameArray || [];
            if (Object.keys(acc).some((key) => key === error.message)) {
              (acc[error.message] || []).push(val.row);
              (errorKams[error.message] || []).push(kamNames);
            } else {
              acc = { ...acc, [error.message]: [val.row] };
              errorKams = { ...errorKams, [error.message]: [kamNames] };
            }
          });
          return acc;
        }, []);
        errorMessage = Object.keys(errorMessage).reduce((acc, key) => {
          const kamNames = (errorKams[key] || []).flat().join(", ");
          return {
            ...acc,
            [`${key}${kamNames}`]: errorMessage[key],
          };
        }, {});

        return onSheetError(errorMessage);
      } else {
        return toast.error(error.message);
      }
    } else {
      const label = (exchangeType?.label || "")
        .toLocaleLowerCase()
        .split("")
        .map((letter, index) => (index === 0 ? letter.toUpperCase() : letter))
        .join("");
      toast.success(`${label} uploaded successfully.`);
    }
    handleClose();
  };

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.paper,
      }}
    >
      <Dialog.Title hasClose onClose={handleClose}>
        Upload Data | {exchangeType?.label}
      </Dialog.Title>
      <Dialog.Content>
        <input
          id="file"
          type="file"
          className="d-none"
          accept={`.${SUPPORTED_FILE_FORMATS.COMMON.join(",.")}`}
          onChange={handleFileChange}
          onClick={(evt) => (evt.currentTarget.value = "")}
        />
        <Button
          variant="outlined"
          component="label"
          color="primary"
          htmlFor="file"
        >
          Choose File
        </Button>
        {state.file && (
          <div className="d-flex f-align-center mt-4">
            <Tooltip title={state.file?.name} placement="top-start">
              <Typography variant="caption" className="mw-75" noWrap>
                {state.file?.name}
              </Typography>
            </Tooltip>
            {state.isLoading && <LinearProgress className="ml-2 w-25" />}
          </div>
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <div className="p-4">
          <Button
            variant="outlined"
            onClick={handleClose}
            className="ml-2 mr-2"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="ml-2 mr-2"
            disabled={state.isLoading || !state.file}
            onClick={() => handleBulkUploads(state.file, exchangeType?.value)}
          >
            Upload
          </Button>
        </div>
      </Dialog.Actions>
    </Dialog>
  );
};

export default BulkUpload;
