import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Skeleton from "@material-ui/lab/Skeleton";

const GridLoader = ({
  open = false,
  pageSize = 5,
  columns: columnsLength = 0,
}) => {
  const columns = new Array(columnsLength).fill("Loading");
  const rows = new Array(pageSize).fill("Rows Loading");
  return (
    <List>
      <ListItem>
        <ListItemText>
          <Skeleton variant="rect" width="100%" height={60} />
        </ListItemText>
      </ListItem>
      <Collapse in={open} unmountOnExit>
        <TableContainer>
          <Table>
            <TableBody>
              {rows.map((row, rowIndex) => {
                return (
                  <TableRow key={`loader-row-${rowIndex}`}>
                    {columns.map((column, columnIndex) => {
                      return (
                        <TableCell
                          className="no-border"
                          key={`loader-row-${rowIndex}-col-${columnIndex}`}
                        >
                          <div
                            className="loading"
                            style={{
                              width:
                                rowIndex % 2 === 0
                                  ? "100px"
                                  : columnIndex % 2 === 0
                                  ? "50px"
                                  : "auto",
                            }}
                          >
                            <span className="v-hidden">{column}</span>
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </List>
  );
};

export default GridLoader;
