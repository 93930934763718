import { useCallback, useState, useEffect, useContext } from "react";
import Typography from "@material-ui/core/Typography";
// import { useLocation } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import SharedService from "modules/shared/service";
import { getAppConfig } from "utils";
import ExpandMoreIcon from "@material-ui/icons/ExpandLess";
import { noop, VALIDATIONS } from "shared/constants";
import { AppContext } from "shared/contexts";
import Accordion from "@material-ui/core/Accordion";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Badge from "@material-ui/core/Badge";
import clsx from "clsx";
import { ProfileAvatar } from "shared/components";
import { toast } from "react-toastify";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { CssTextField, useStyles } from "./style";
import { Dialog } from "shared/components";
import { queryStringBuilderNew } from "utils";
import TextField from "@material-ui/core/TextField";
import { Avatar, Button } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { validator } from "utils";
import MessengerService from "../service";
import { getTokenData } from "utils";
import Tooltip from "@material-ui/core/Tooltip";
import ChatIcon from "@material-ui/icons/Chat";
import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ChatWindow from "../chat-window";
import GroupChat from "../group-chat";
// import {
//   CompassCalibrationOutlined,
//   HelpOutline,
//   YouTube,
// } from "@material-ui/icons";
// import { element } from "prop-types";

let gridHelper = null,
  timeout = null;
// let chatsArray = [];
let openChatWindows = [];
let unreadMessageUsers = [];
let readMessageUsers = [];
let unreadGroupMessageUsers = [];
let readGroupMessageUsers = [];
let loggedInusers = [];
let isFetchAfterReadMsgInProgress = false;
let isFetchAfterReadGroupMsgInProgress = false;
let userLists = [];
let addedGroupUserList = [];
let groupList = [];
let matchingEntry = null;
let isUserSearched = false;

const defaultState = {
  entries: [],
  groupEntries: [],
  addedGroupUser: [],
  totalEntries: 0,
  search: "",
  isFetching: false,
  openChat: false,
  msgExistsForDate: false,
  openGroupChat: false,
  addUsers: false,
  totalMessagesCount: 0,
  openGroupMessages: false,
  searchValue: "",
  typingValue: "",
  showRecentChat: true,
  showChat: false,
  selected: "recentChats",
  roomName: "",
  errors: {
    roomName: " ",
  },
  finalArray: [],
  userName: null,
  addUserIngroup: [],
  addUserListIngroup: [],
  chatRoomUsers: {},
  onlineUsers: [],
  message: "",
  messages: [],
  fullName: {},
  currentSelected: 0,
  originalGroupChatArray: [],
  showMessages: false,
  unreadUserData: {},
  unreadGroupUserData: {},
  isFetchingList: {
    userOnline: false,
  },
  directChatArray: [],
  addedUserListArray: [],
  groupChatArray: [],
};

let socket;
// let isRegistered = false;

const Messenger = () => {
  const [state, setState] = useState({
    ...defaultState,
  });
  // const location = useLocation();
  // const isDashboard = location?.pathname === "/home";
  const classes = useStyles();
  // const scrollRef = useRef();
  const { appData } = useContext(AppContext);
  const {
    id,
    username = "",
    profile_image_path,
    first_name = "",
    last_name = "",
  } = getTokenData() || {};

  const socketUrl = getAppConfig()?.socketUrl;

  const userData = {
    id,
    username,
    profile_image_path,
    fullName: `${first_name || ""} ${last_name || ""}`,
  };

  const getSearchArray = (search) => {
    const users = ["first_name", "last_name", "username"];
    return users?.map((list) => ({
      field: list,
      value: search,
    }));
  };

  const getFormattedDate = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    const dateInstance = new Date(date);
    const day = dateInstance.getDate();
    const monthIndex = dateInstance.getMonth();
    const year = dateInstance.getFullYear();
    return `${day} ${monthNames[monthIndex]} ${year}`;
  };

  const groupByDate = (data) => {
    return data?.reduce((groups, dates) => {
      const date = getFormattedDate(dates?.created_at);
      if (!groups.hasOwnProperty(date)) {
        groups[date] = [];
      }
      groups[date].push(dates);
      return groups;
    }, {});
  };

  const getFieldValidatorMap = (state, field) => {
    const fieldValidatorMap = {
      roomName: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.MIN_LENGTH, value: 3 },
        { type: VALIDATIONS.MAX_LENGTH, value: 30 },
      ],
    };
    return fieldValidatorMap;
  };

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = getFieldValidatorMap(state, field);

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const fetchMessages = useCallback(async () => {
    // setState((prevState) => ({
    //   ...prevState,
    //   isFetchingList: {
    //     ...prevState.isFetchingList,
    //     userOnline: true,
    //   },
    // }));
    const { data, error } = await MessengerService.get();
    isFetchAfterReadMsgInProgress = false;
    isFetchAfterReadGroupMsgInProgress = false;
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          userOnline: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      unreadUserData: data.unreadUserData.users || defaultState.unreadUserData,
      unreadGroupUserData:
        data.unreadUserData.rooms || defaultState.unreadGroupUserData,
      totalMessagesCount:
        data?.totalUnreadCount || defaultState.totalMessagesCount,
      onlineUsers: data?.rows || defaultState.onlineUsers,
      // isFetchingList: {
      //   ...prevState.isFetchingList,
      //   userOnline: false,
      // },
    }));
  }, []);

  const fetchEntries = useCallback(async (search, hasLoader = true) => {
    const searchArray = getSearchArray(search);

    setState((prevState) => ({
      ...prevState,
      isFetching: hasLoader,
    }));
    const queryString = queryStringBuilderNew(
      0,
      0,
      searchArray,
      [],
      null,
      search && search.length > 0
    );
    const { data, error } = await SharedService.getUsersList(queryString);
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetching: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }
    if (isUserSearched === false) {
      matchingEntry = data?.users
        ?.filter((user) => !!user.is_active)
        ?.find((entry) => entry?.id === id);
    }
    state.directChatArray =
      data?.users.filter(
        (user) => user?.id !== userData?.id && !!user.is_active
      ) || defaultState.entries;
    state.addedUserListArray =
      data?.users.filter((user) => !!user.is_active) ||
      defaultState.addedUserListArray;

    userLists.push(state.directChatArray);
    addedGroupUserList.push(state.addedUserListArray);

    let tempArr = [...state.groupChatArray];
    tempArr.push(...state.directChatArray);
    setState((prevState) => ({
      ...prevState,
      isFetching: false,
      entries: tempArr,
      addUserIngroup: userLists,
      addUserListIngroup: addedGroupUserList,
      addedGroupUser:
        data?.users.filter((user) => !!user.is_active) ||
        defaultState.addedGroupUser,
      totalEntries: data?.count,
    }));

    return data;
  }, []);

  // const handleReadMessages = async (id = "", isRoomMsg = false) => {
  //   // let entry = state.entries[id];
  //   const { data, error } = await MessengerService.markMessageRead({
  //     userId: id,
  //     isRoomMessage: isRoomMsg,
  //   });
  //   if (error) {
  //     return toast.error(
  //       Array.isArray(error) ? error[0].message : error.message
  //     );
  //   }
  //   fetchMessages();
  // };

  const seenUnseen = (id, isRoomMsg = false) => {
    const markAsRead = {
      action: "markAsRead",
      userId: id,
      recipientId: userData?.id,
      isRoomMessage: isRoomMsg,
    };
    socket.send(JSON.stringify(markAsRead));
    isFetchAfterReadMsgInProgress = true;
    isFetchAfterReadGroupMsgInProgress = true;
    fetchMessages();
  };

  const openChatIds = openChatWindows.map((chat) => chat.id);

  const uniqueUsers = state.onlineUsers.reduce((acc, message) => {
    if (
      !acc?.find(
        (entry) =>
          entry.id == message?.sender_id &&
          entry.is_group_chat === message.is_room_msg
      )
    ) {
      const user = {
        id: message?.sender_id,
        is_group_chat: message?.is_room_msg,
      };
      acc.push(user);
    }
    if (
      !acc?.find(
        (entry) =>
          entry.id == message?.recipient_id &&
          entry.is_group_chat === message.is_room_msg
      )
    ) {
      const user = {
        id: message?.recipient_id,
        is_group_chat: message?.is_room_msg,
      };
      acc.push(user);
    }
    return acc;
  }, []);

  const filteredUsers = state.entries.filter((user) =>
    uniqueUsers?.find(
      (entry) =>
        entry.id == user?.id &&
        (entry.is_group_chat == user?.is_room ||
          (entry.is_group_chat === false && user.is_room == undefined))
    )
  );

  if (state.unreadUserData) {
    let temp = Object.entries(state.unreadUserData);
    let temp1 = temp.map((item) => ({
      id: item[0],
      count: item[1],
    }));

    let tempArray = [];

    for (let i = 0; i < filteredUsers.length; i++) {
      let entry = filteredUsers[i];
      let unreadCount = 0;
      if (entry.is_room === undefined || entry.is_room === false) {
        for (let j = 0; j < temp1.length; j++) {
          let item = temp1[j];
          if (item?.id == entry?.id) {
            if (openChatIds.find((userId) => userId == item?.id)) {
              unreadCount = item?.count;
              if (unreadCount > 0 && !isFetchAfterReadMsgInProgress) {
                setTimeout(() => {
                  seenUnseen(item?.id, false);
                }, 5000);
              }
            } else {
              unreadCount = item?.count;
            }
          }
        }

        tempArray.push({
          ...entry,
          count: unreadCount,
        });
      }
    }

    unreadMessageUsers = tempArray?.filter((entry) => entry.count > 0);
    readMessageUsers = tempArray?.filter((entry) => entry.count < 1);
  }

  if (state.unreadGroupUserData) {
    let temp = Object.entries(state.unreadGroupUserData);
    let temp1 = temp.map((item) => ({
      id: item[0],
      count: item[1],
    }));

    let tempArray = [];

    for (let i = 0; i < filteredUsers.length; i++) {
      let entry = filteredUsers[i];
      if (entry.is_room === undefined || entry.is_room === false) continue;
      let unreadCount = 0;
      for (let j = 0; j < temp1.length; j++) {
        let item = temp1[j];
        if (item?.id == entry?.id) {
          if (openChatIds.find((userId) => userId == item?.id)) {
            unreadCount = item?.count;
            if (unreadCount > 0 && !isFetchAfterReadGroupMsgInProgress) {
              setTimeout(() => {
                seenUnseen(item?.id, true);
              }, 5000);
            }
          } else {
            unreadCount = item?.count;
          }
        }
      }

      tempArray.push({
        ...entry,
        count: unreadCount,
      });
    }
    unreadGroupMessageUsers = tempArray?.filter((entry) => entry.count > 0);
    readGroupMessageUsers = tempArray?.filter((entry) => entry.count < 1);
  }

  const handleSendMsg = async (message = "", entry = {}) => {
    sendSocketMessage({
      action: "onMessage",
      message,
      name: entry?.first_name,
      isRoomMessage: false,
      recipientId: entry?.id,
      senderId: userData?.id,
    });

    setState((prevState) => ({
      ...prevState,
      messages: message || defaultState.messages,
    }));
  };

  const fetchChatRoom = useCallback(async (search) => {
    const { data, error } = await MessengerService.getRoom();
    if (error) {
      toast.error(Array.isArray(error) ? error[0].message : error.message);
    }

    data?.rows?.forEach((row) => {
      row.is_room = true;
    });

    // let filteredData =
    //   data?.rows.filter((row) => row?.name?.includes(search)) ||
    //   defaultState.entries;
    state.groupChatArray = data?.rows || defaultState.groupChatArray;
    groupList.push(state.groupChatArray);
    state.originalGroupChatArray =
      data?.rows || defaultState.originalGroupChatArray;
    let tempArr = [...state.groupChatArray];
    tempArr.push(...state.directChatArray);
    setState((prevState) => ({
      ...prevState,
      entries: tempArr,
      groupEntries: groupList,
      chatRoomUsers: data?.roomMembersData || defaultState.chatRoomUsers,
    }));
  }, []);

  const handleSearch = useCallback((search) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        search,
      }));
      gridHelper && gridHelper.resetSelection();
      if (search.length === 0) {
        state.groupChatArray = state.originalGroupChatArray;
      } else {
        isUserSearched = true;
        state.groupChatArray = state.originalGroupChatArray.filter(
          (row) => row?.name?.toLowerCase().indexOf(search.toLowerCase()) !== -1
        );
      }
    }, 800);
  }, []);

  const recentChatSortedArray = [
    ...unreadGroupMessageUsers,
    ...unreadMessageUsers,
    ...readGroupMessageUsers,
    ...readMessageUsers,
  ].sort((a, b) => {
    const messageA = state.onlineUsers.find(
      (message) =>
        (message?.sender_id == a?.id || message?.recipient_id == a?.id) &&
        (message?.is_room_msg == a?.is_room ||
          (message?.is_room_msg === false && a?.is_room == undefined))
    );
    const messageB = state.onlineUsers.find(
      (message) =>
        (message?.sender_id == b?.id || message?.recipient_id == b?.id) &&
        (message?.is_room_msg == b?.is_room ||
          (message?.is_room_msg === false && b?.is_room == undefined))
    );

    return Intl.Collator().compare(messageB.created_at, messageA.created_at);
  });

  const handleOpenChat = (event, id = "", isDirectChat = true) => {
    // const arrayToPushIn = isDirectChat ? openChatWindows : openGroupChatWindows;
    for (let i = 0; i < openChatWindows.length; i++) {
      if (
        openChatWindows[i].id === id &&
        (!isDirectChat
          ? openChatWindows[i].is_room === true
          : openChatWindows[i].is_room === undefined ||
            openChatWindows[i].is_room === false)
      ) {
        //no-op
        return;
      }
    }

    let itemToPush = state.entries.find(
      (entry) =>
        entry?.id == id &&
        (!isDirectChat
          ? entry.is_room === true
          : entry.is_room === undefined || entry.is_room === false)
    );

    itemToPush.type = isDirectChat ? "chat" : "group";
    // if (isDirectChat) {
    //   handleReadMessages(id, false);
    // } else {
    //   handleReadMessages(id, true);
    // }
    if (isDirectChat) {
      seenUnseen(id, false);
    } else {
      seenUnseen(id, true);
    }

    if (appData.isTabletView) {
      for (let i = 0; i < openChatWindows.length; i++) {
        handleClose(openChatWindows[i], isDirectChat);
      }
      openChatWindows = [];
    }

    if (openChatWindows.length >= 3) {
      handleClose(openChatWindows[0], isDirectChat);
    }

    itemToPush.position = openChatWindows.length;
    openChatWindows.push(itemToPush);

    if (isDirectChat === true) {
      setState((prevState) => ({
        ...prevState,
        openChat: true,
        currentSelected: state.entries[id],
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        openGroupMessages: true,
        currentSelected: state.entries[id],
      }));
    }
  };

  const handleCloseMessaging = () => {
    setState((prevState) => ({
      ...prevState,
      openChat: false,
      showMessages: false,
      openGroupMessages: false,
      search: "",
    }));
    fetchChatRoom();
    searchValue();
  };

  const handleSocketConnect = () => {
    socket = new WebSocket(`${socketUrl}`);
    socket.onopen = (event) => {
      console.log("connection is on");
      stayAlive();
      registerUser();
    };
    socket.onmessage = (event) => {
      fetchMessages();
      const groupMsgData = JSON.parse(event?.data);
      if (groupMsgData?.isRoomMessage === true) {
        const roomIdExists = state.originalGroupChatArray.find(
          (entry) =>
            entry?.id == groupMsgData?.userId ||
            entry?.id == groupMsgData?.roomId
        );
        if (!roomIdExists) {
          fetchChatRoom();
          searchValue();
          setState((prevState) => ({
            ...prevState,
            search: "",
          }));
        }
      }
      handleOnlineUsers(event);
    };
    socket.onclose = (event) => {
      console.log("closed connection");
      disconnectSocket();
    };
    socket.onerror = (event) => {
      setTimeout(() => {
        handleSocketConnect();
      }, 1000);
    };
  };

  const stayAlive = useCallback((search) => {
    if (socket.readyState === WebSocket.OPEN) {
      setTimeout(() => {
        if (socket.readyState === WebSocket.OPEN) {
          let whoAmIPayload = { action: "stayAlive" };
          socket.send(JSON.stringify(whoAmIPayload));
          gridHelper && gridHelper.resetSelection();
        }
        stayAlive();
      }, 200000);
    } else if (socket.readyState === WebSocket.CONNECTING) {
      setTimeout(() => {
        stayAlive();
      }, 100);
    } else {
      handleSocketConnect();
      setTimeout(() => {
        stayAlive();
      }, 100);
    }
  }, []);

  const sendSocketMessage = (data = {}) => {
    // if(!isRegistered){
    //   registerUser()
    // }
    // isRegistered = true
    socket.send(JSON.stringify(data));
    fetchMessages();
  };

  const handleOnlineUsers = (event) => {
    const onlineUserData = JSON.parse(event?.data);

    if (onlineUserData?.code === "PDX-OU-001") {
      loggedInusers = onlineUserData?.onlineUsers;
    }

    if (onlineUserData?.statusCode === 1001) {
      let index = loggedInusers?.indexOf(onlineUserData?.userId);
      loggedInusers?.splice(index, 1);
    }

    if (onlineUserData?.statusCode === 1002) {
      loggedInusers?.push(onlineUserData?.userId);
    }
  };

  const registerUser = () => {
    const whoAmIPayload = {
      action: "whoAmI",
      name: userData.fullName,
      userId: userData?.id,
    };
    socket.send(JSON.stringify(whoAmIPayload));
  };

  const disconnectSocket = () => {
    console.log("hii i am closed");
    socket.close();
  };

  useEffect(() => {
    handleSocketConnect();

    return () => {
      disconnectSocket();
    };
  }, []);

  const handleShowMessaging = () => {
    setState((prevState) => ({
      ...prevState,
      showMessages: true,
    }));
  };

  const searchValue = () => {
    setState((prevState) => ({
      ...prevState,
      searchValue: state.typingValue,
      typingValue: "",
    }));
  };

  const handleClose = (entrySelected = "", isDirectChat = true) => {
    let indexTobeDeleted = openChatWindows?.findIndex(
      (entry) => entry?.id === entrySelected?.id
    );

    if (indexTobeDeleted > -1) {
      let positionToBeRemoved = 0;
      positionToBeRemoved = openChatWindows[indexTobeDeleted].position;
      openChatWindows.splice(indexTobeDeleted, 1);

      for (let i = 0; i < openChatWindows.length; i++) {
        openChatWindows[i].position = i;
      }
    }
    setState((prevState) => ({
      ...prevState,
      openChat: false,
      openGroupMessages: false,
    }));
  };

  const handleSendGroupMsg = async (message = "", entry = {}) => {
    sendSocketMessage({
      action: "onMessage",
      message,
      isRoomMessage: true,
      name: entry?.name,
      recipientId: entry?.id,
      senderId: userData?.id,
    });

    setState((prevState) => ({
      ...prevState,
      messages: message || defaultState.messages,
    }));
  };

  useEffect(() => {
    fetchChatRoom();
  }, [fetchChatRoom]);

  useEffect(() => {
    fetchMessages();
    fetchEntries(state.search);
  }, [fetchEntries, state.search, fetchMessages]);

  const addUserInRoom = async (user) => {
    const groups = state.entries.filter((entry) => entry.is_room === true);

    const roomId = groups.find((entry) => entry.id === state.currentSelected);

    const { error } = await MessengerService.adduser({
      roomId: roomId?.id,
      userIds: user.map((element) => element?.id),
    });
    if (error) {
      toast.error(Array.isArray(error) ? error[0].message : error.message);
    } else {
      toast.success("Participants added successfully.");
    }
    setState((prevState) => ({
      ...prevState,
      search: "",
    }));
    fetchChatRoom();
    searchValue();
  };

  const removeUserFromGroup = async (user, id) => {
    const query = `?user_id=${user}&room_id=${id}`;
    const { error } = await MessengerService.removeUserFromGroup(query);
    if (error) {
      toast.error(Array.isArray(error) ? error[0].message : error.message);
    } else {
      toast.success("Participant removed successfully.");
    }
    setState((prevState) => ({
      ...prevState,
      search: "",
    }));
    fetchChatRoom();
    searchValue();
  };

  const leaveGroup = async (user, id) => {
    // const roomId = id;
    const query = `?room_id=${id}`;

    const { error } = await MessengerService.leaveGroup(query);
    if (error) {
      toast.error(Array.isArray(error) ? error[0].message : error.message);
    } else {
      toast.success("Group left successfully.");
    }
    setState((prevState) => ({
      ...prevState,
      search: "",
    }));
    fetchChatRoom();
    searchValue();
  };

  const createRoom = async (groupName) => {
    const { error } = await MessengerService.createRoom({
      name: groupName,
    });
    if (error) {
      toast.error(Array.isArray(error) ? error[0].message : error.message);
    } else {
      toast.success("Group created successfully.");
    }
    setState((prevState) => ({
      ...prevState,
      search: "",
    }));
    fetchChatRoom();
    searchValue();
  };

  const changeRoomName = async (groupName, id) => {
    const roomId = state.groupEntries[state.groupEntries?.length - 1].find(
      (entry) => entry?.id == id
    );
    const { error } = await MessengerService.updateGroupName({
      id: roomId?.id,
      name: groupName,
    });
    if (error) {
      toast.error(Array.isArray(error) ? error[0].message : error.message);
    } else {
      toast.success("Group name is changed successfully.");
    }
    setState((prevState) => ({
      ...prevState,
      search: "",
    }));
    fetchChatRoom();
    searchValue();
  };

  const handleCreateGroupChat = () => {
    setState((prevState) => ({
      ...prevState,
      openGroupChat: true,
    }));
  };

  const handleCloseGroupChat = () => {
    setState((prevState) => ({
      ...prevState,
      openGroupChat: false,
      roomName: "",
      errors: {
        ...prevState.errors,
        roomName: "",
      },
    }));
  };

  const setRoomName = (event) => {
    let errorMessage =
      validate(event.currentTarget.name, event.target.value) || " ";

    setState((prevState) => ({
      ...prevState,
      roomName: event.target.value,
      errors: {
        ...prevState.errors,
        roomName: errorMessage,
      },
    }));
  };

  const setUserName = (evt, value) => {
    setState((prevState) => ({
      ...prevState,
      userName: value,
    }));
  };

  const createRoomName = (event) => {
    event.preventDefault();
    if (state.roomName.length > 0) {
      const string = state.roomName?.trimStart();
      const finalString = string?.trimEnd();
      createRoom(finalString);
      setState((prevState) => ({
        ...prevState,
        roomName: "",
        openGroupChat: false,
      }));
    }
  };

  const addUserName = (event) => {
    event.preventDefault();
    addUserInRoom(state.userName);
    setState((prevState) => ({
      ...prevState,
      userName: null,
      addUsers: false,
    }));
    fetchChatRoom();
  };

  const handleCloseAddUsers = () => {
    setState((prevState) => ({
      ...prevState,
      addUsers: false,
      userName: null,
    }));
  };

  const handleAddUsers = (event) => {
    setState((prevState) => ({
      ...prevState,
      addUsers: true,
      currentSelected: +event.currentTarget.dataset?.id,
    }));
  };

  const handleShowRecentChat = () => {
    setState((prevState) => ({
      ...prevState,
      showRecentChat: true,
      showChat: false,
      selected: "recentChats",
    }));
  };

  const handleShowChat = () => {
    setState((prevState) => ({
      ...prevState,
      showRecentChat: false,
      showChat: true,
      selected: "chats",
    }));
  };

  // const sortedFilteredUsers = filteredUsers.sort((a, b) => {
  //   const timeA = new Date(
  //     state.onlineUsers.find(
  //       (message) =>
  //         message?.sender_id == a?.id || message?.recipient_id == a?.id
  //     ).created_at
  //   );
  //   const timeB = new Date(
  //     state.onlineUsers.find(
  //       (message) =>
  //         message?.sender_id == b?.id || message?.recipient_id == b?.id
  //     ).created_at
  //   );
  //   return timeB - timeA;
  // });

  // const sortedConversations = [...state.entries].sort((a, b) => {
  //   return new Date(b.updated_at) - new Date(a.updated_at);
  // });

  return (
    <>
      <div>
        {!state.showMessages && (
          <div>
            <Tooltip
              title="Messages"
              placement="top-end"
              className={classes.showMessagesTooltip}
            >
              <Badge
                badgeContent={state.totalMessagesCount}
                color="error"
                classes={{
                  anchorOriginTopRightRectangle: classes.anchorTopRight,
                }}
                className={classes.showBadges}
              >
                <IconButton onClick={handleShowMessaging}>
                  <ChatIcon className={classes.showMessages} />
                </IconButton>
              </Badge>
            </Tooltip>
          </div>
        )}
        {state.showMessages && (
          <>
            <div>
              <Accordion
                className={classes.accordionSelectUser}
                defaultExpanded={true}
              >
                <AccordionSummary
                  classes={{
                    content: classes.content,
                    expanded: classes.expanded,
                  }}
                  className={classes.userListHeader}
                  expandIcon={
                    <IconButton
                      onClick={handleCloseMessaging}
                      className={classes.closeMessaging}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className={classes.style}>
                    {matchingEntry?.profile_image_path === null ||
                    matchingEntry?.profile_image_path === undefined ? (
                      <ProfileAvatar
                        className={classes.ProfileAvatar}
                        userData={userData}
                      />
                    ) : (
                      <Avatar
                        className={classes.ProfileAvatar}
                        alt="avatar"
                        src={matchingEntry?.profile_image_path}
                      />
                    )}
                    <Typography
                      varient="h5"
                      style={{
                        margin: "0px",
                      }}
                    >
                      Messaging
                    </Typography>
                    <Badge
                      badgeContent={state.totalMessagesCount}
                      color="error"
                      className={classes.showHeaderBadges}
                    />
                  </div>
                </AccordionSummary>
                <List style={{ paddingBottom: "0px" }} subheader={<li />}>
                  {
                    <Grid item xs={3} style={{ maxWidth: "100%" }}>
                      <Divider />
                      <Grid
                        item
                        xs={12}
                        style={{ padding: "10px 10px 0px 10px" }}
                      >
                        <div>
                          <div className="d-flex f-justify-between f-align-center">
                            <div className="d-flex"></div>
                            <div className="d-flex f-align-center">
                              <CssTextField
                                type="text"
                                variant="outlined"
                                className={clsx(
                                  "mr-5 ml-2",
                                  classes.CssTextField
                                )}
                                value={state.typingValue}
                                size="small"
                                placeholder="Search contacts"
                                InputProps={{ endAdornment: <SearchIcon /> }}
                                onChange={(evt) => {
                                  setState((prevState) => ({
                                    ...prevState,
                                    typingValue: evt.target.value || "",
                                  }));
                                  handleSearch((evt.target.value || "").trim());
                                }}
                              />
                            </div>
                          </div>
                          <IconButton
                            onClick={handleCreateGroupChat}
                            className={classes.groupChat}
                          >
                            <GroupAddIcon />
                            <p className={classes.groupChatTitle}>
                              Create New Group
                            </p>
                          </IconButton>
                          <div
                            className={"d-flex f-align-center f-justify-around"}
                          >
                            <IconButton
                              onClick={handleShowRecentChat}
                              className={
                                state.selected === "recentChats"
                                  ? classes.selected
                                  : classes.recentChats
                              }
                            >
                              <Typography varient="h5">Recent Chats</Typography>
                            </IconButton>
                            <IconButton
                              onClick={handleShowChat}
                              className={
                                state.selected === "chats"
                                  ? classes.selected
                                  : classes.recentChats
                              }
                            >
                              <Typography varient="h5">Contacts</Typography>
                            </IconButton>
                          </div>
                        </div>
                      </Grid>

                      {state.showRecentChat && (
                        <List className={classes.userList}>
                          {
                            // (state.totalMessagesCount > 0
                            //   ? recentChatSortedArray
                            //   : sortedFilteredUsers
                            // )
                            !recentChatSortedArray.length ? (
                              <Typography
                                variant="body2"
                                className={classes.noUserFound}
                              >
                                No results found.
                              </Typography>
                            ) : (
                              recentChatSortedArray.map((element, index) =>
                                element.is_room === true ? (
                                  <div className="d-flex f-justify-between f-align-center">
                                    <ListItem
                                      style={{
                                        paddingLeft: "8px",
                                      }}
                                      button={true}
                                      data-index={index}
                                      // key={index}
                                      onClick={(evt) =>
                                        handleOpenChat(evt, element.id, false)
                                      }
                                      activeClassName={classes.activeListItem}
                                      key={element.id}
                                    >
                                      <ListItemIcon
                                        className={classes.ListItemIcon}
                                      >
                                        <ProfileAvatar
                                          className={classes.ProfileAvatar}
                                          userData={
                                            (state.fullName = {
                                              profile_image_path,
                                              fullName: `${element.name || ""}`,
                                            })
                                          }
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        style={{ wordBreak: "break-word" }}
                                        primary={element.name}
                                      />
                                      <Badge
                                        badgeContent={
                                          element.count > 0
                                            ? element.count
                                            : null
                                        }
                                        color="error"
                                        className="mr-4"
                                      ></Badge>
                                    </ListItem>
                                    <Tooltip
                                      title="Add Users"
                                      placement="top-start"
                                    >
                                      <IconButton
                                        data-id={element.id}
                                        onClick={handleAddUsers}
                                        className={classes.roomChat}
                                        key={element.id}
                                      >
                                        <PersonAddIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                ) : (
                                  <ListItem
                                    style={{
                                      paddingLeft: "8px",
                                    }}
                                    button={true}
                                    data-index={index}
                                    // key={index}
                                    onClick={(evt) =>
                                      handleOpenChat(evt, element.id, true)
                                    }
                                    activeClassName={classes.activeListItem}
                                    key={element.id}
                                  >
                                    <ListItemIcon
                                      className={classes.ListItemIcon}
                                    >
                                      {element?.profile_image_path === null ||
                                      element?.profile_image_path ===
                                        undefined ? (
                                        <ProfileAvatar
                                          className={classes.ProfileAvatar}
                                          userData={
                                            (state.fullName = {
                                              profile_image_path,
                                              fullName: `${
                                                element.first_name || ""
                                              } ${element.last_name || ""}`,
                                            })
                                          }
                                        />
                                      ) : (
                                        <Avatar
                                          className={classes.ProfileAvatar}
                                          alt="avatar"
                                          src={element?.profile_image_path}
                                        />
                                      )}
                                    </ListItemIcon>
                                    <ListItemText
                                      className={classes.listItem}
                                      primary={
                                        element.name ||
                                        `${element.first_name || ""} ${
                                          element.last_name || ""
                                        }-(${element.username || ""})-(${
                                          element.role || ""
                                        })`
                                      }
                                    />
                                    <Badge
                                      badgeContent={
                                        element.count > 0 ? element.count : null
                                      }
                                      color="error"
                                      className="mr-4"
                                    ></Badge>
                                    {loggedInusers?.includes(element?.id) ? (
                                      <FiberManualRecordIcon
                                        size="small"
                                        className={classes.onlineUser}
                                      />
                                    ) : null}
                                  </ListItem>
                                )
                              )
                            )
                          }
                        </List>
                      )}
                      {state.showChat && (
                        <List className={classes.userList}>
                          {state.entries.map(
                            (groupName, index) =>
                              groupName.is_room === true && (
                                <div className="d-flex f-justify-between f-align-center">
                                  <ListItem
                                    style={{
                                      paddingLeft: "8px",
                                    }}
                                    button={true}
                                    data-index={index}
                                    // key={index}
                                    onClick={(evt) =>
                                      handleOpenChat(evt, groupName.id, false)
                                    }
                                    activeClassName={classes.activeListItem}
                                    key={groupName.id}
                                  >
                                    <ListItemIcon
                                      className={classes.ListItemIcon}
                                    >
                                      <ProfileAvatar
                                        className={classes.ProfileAvatar}
                                        userData={
                                          (state.fullName = {
                                            profile_image_path,
                                            fullName: `${groupName.name || ""}`,
                                          })
                                        }
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      style={{ wordBreak: "break-word" }}
                                      primary={groupName.name}
                                    />
                                  </ListItem>
                                  <Tooltip
                                    title="Add Users"
                                    placement="top-start"
                                  >
                                    <IconButton
                                      data-id={groupName.id}
                                      onClick={handleAddUsers}
                                      className={classes.roomChat}
                                      key={groupName.id}
                                    >
                                      <PersonAddIcon />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              )
                          )}
                          {!state.entries.length ? (
                            <Typography
                              variant="body2"
                              className={classes.noUserFound}
                            >
                              No results found.
                            </Typography>
                          ) : (
                            state.entries.map(
                              (element, index) =>
                                element.is_room === undefined &&
                                element.is_room !== true && (
                                  <ListItem
                                    style={{
                                      paddingLeft: "8px",
                                    }}
                                    button={true}
                                    data-index={index}
                                    // key={index}
                                    onClick={(evt) =>
                                      handleOpenChat(evt, element.id, true)
                                    }
                                    activeClassName={classes.activeListItem}
                                    key={element.id}
                                  >
                                    <ListItemIcon
                                      className={classes.ListItemIcon}
                                    >
                                      {" "}
                                      {element?.profile_image_path === null ||
                                      element?.profile_image_path ===
                                        undefined ? (
                                        <ProfileAvatar
                                          className={classes.ProfileAvatar}
                                          userData={
                                            (state.fullName = {
                                              profile_image_path,
                                              fullName: `${
                                                element.first_name || ""
                                              } ${element.last_name || ""}`,
                                            })
                                          }
                                        />
                                      ) : (
                                        <Avatar
                                          className={classes.ProfileAvatar}
                                          alt="avatar"
                                          src={element?.profile_image_path}
                                        />
                                      )}
                                    </ListItemIcon>
                                    <ListItemText
                                      className={classes.listItem}
                                      primary={
                                        element.name ||
                                        `${element.first_name || ""} ${
                                          element.last_name || ""
                                        }-(${element.username || ""})-(${
                                          element.role || ""
                                        })`
                                      }
                                    />
                                    {loggedInusers?.includes(element?.id) ? (
                                      <FiberManualRecordIcon
                                        size="small"
                                        className={classes.onlineUser}
                                      />
                                    ) : null}
                                  </ListItem>
                                )
                            )
                          )}
                        </List>
                      )}
                    </Grid>
                  }
                </List>
              </Accordion>
            </div>
            <div>
              {state.showMessages &&
                openChatWindows.length > 0 &&
                openChatWindows
                  .filter(
                    (entry) =>
                      entry.is_room === undefined && entry.is_room !== true
                  )
                  .map((entry) => (
                    <ChatWindow
                      handleSendMsg={handleSendMsg}
                      onlineUsers={state.onlineUsers}
                      entry={entry}
                      id={entry.position}
                      userData={userData}
                      entries={state.entries}
                      currentSelected={state.currentSelected}
                      handleClose={handleClose}
                      loggedInusers={loggedInusers}
                      groupByDate={groupByDate}
                      totalMessagesCount={state.totalMessagesCount}
                      readMessageUsers={readMessageUsers}
                      unreadMessageUsers={unreadMessageUsers}
                    />
                  ))}
            </div>
            <div>
              {state.showMessages &&
                openChatWindows.length > 0 &&
                openChatWindows
                  .filter((entry) => entry.is_room === true)
                  .map((entry) => (
                    <GroupChat
                      handleSendGroupMsg={handleSendGroupMsg}
                      onlineUsers={state.onlineUsers}
                      entry={entry}
                      id={entry.position}
                      chatRoomUsers={state.chatRoomUsers}
                      openGroupMessages={state.openGroupMessages}
                      removeUserFromGroup={removeUserFromGroup}
                      leaveGroup={leaveGroup}
                      entries={state.entries}
                      userData={userData}
                      handleClose={handleClose}
                      currentGroupSelected={state.currentSelected}
                      groupByDate={groupByDate}
                      totalMessagesCount={state.totalMessagesCount}
                      readGroupMessageUsers={readGroupMessageUsers}
                      unreadGroupMessageUsers={unreadGroupMessageUsers}
                      changeRoomName={changeRoomName}
                      addedGroupUser={state.addedGroupUser}
                      addedUserIngroup={state.addUserListIngroup[0].filter(
                        (entry) =>
                          entry.is_room === undefined && entry.is_room !== true
                      )}
                      validate={validate}
                    />
                  ))}
            </div>
          </>
        )}
      </div>
      <div>
        {state.openGroupChat && (
          <Dialog
            open
            classes={{
              paper: classes.paper,
            }}
            onClose={handleCloseGroupChat}
          >
            <Dialog.Title hasClose>CREATE NEW GROUP</Dialog.Title>
            <Dialog.Content>
              <>
                <TextField
                  fullWidth
                  required
                  label="Group Name"
                  variant="outlined"
                  size="small"
                  name="roomName"
                  onChange={setRoomName}
                  value={state.roomName}
                  error={!!state.errors.roomName.trim()}
                  helperText={state.errors.roomName}
                ></TextField>
              </>
            </Dialog.Content>
            <Dialog.Actions>
              <div className="p-4">
                <Button
                  variant="outlined"
                  onClick={handleCloseGroupChat}
                  className="ml-2 mr-2"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="ml-2 mr-2"
                  disabled={!state.roomName.trim() || validate()}
                  onClick={createRoomName}
                >
                  Create
                </Button>
              </div>
            </Dialog.Actions>
          </Dialog>
        )}
      </div>
      <div>
        {state.addUsers && (
          <Dialog
            open
            classes={{
              paper: classes.paperr,
            }}
            onClose={handleCloseAddUsers}
          >
            <Dialog.Title hasClose>Add Participants</Dialog.Title>
            <Dialog.Content>
              <>
                <Autocomplete
                  disableClearable
                  required
                  className="mb-1 mt-4"
                  size="small"
                  options={state.addUserIngroup[0].filter(
                    (entry) =>
                      entry.is_room === undefined && entry.is_room !== true
                  )}
                  multiple
                  // value={state.userName}
                  getOptionLabel={(option) =>
                    option.name ||
                    `${option.first_name || ""} ${option.last_name || ""}-(${
                      option.username || ""
                    })-(${option.role || ""})`
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Add Participants"
                      variant="outlined"
                    />
                  )}
                  onChange={setUserName}
                />
              </>
            </Dialog.Content>
            <Dialog.Actions>
              <div className="p-4">
                <Button
                  variant="outlined"
                  onClick={handleCloseAddUsers}
                  className="ml-2 mr-2"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="ml-2 mr-2"
                  onClick={addUserName}
                  disabled={!state.userName}
                >
                  Add
                </Button>
              </div>
            </Dialog.Actions>
          </Dialog>
        )}
      </div>
    </>
  );
};

export default Messenger;
