import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  Fragment,
  useRef,
} from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import { noop, PAGE_KEYS } from "shared/constants";
import { InlineFieldForm } from "modules/shared/components";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import HistoryIcon from "@material-ui/icons/History";
import Switch from "@material-ui/core/Switch";
import { BULLET_POINTS_DCN, dcnFields } from "modules/shared/constants";
import SharedService from "../../shared/service";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import Service from "../service";
import { useLocation } from "react-router-dom";
import FlagIcon from "@material-ui/icons/Flag";
import { AppContext } from "shared/contexts";
import {
  ActionDialog,
  Grid as DataGrid,
  Datepicker,
  Dropdown,
} from "shared/components";
import {
  getDateString,
  getPageConfig,
  getTokenData,
  setPageConfig,
  getUTCDateString,
  validator,
  hasFeatureAccess,
  updateLayout,
  updatePageConfig,
  getFormattedTime,
} from "utils";
import Alert from "@material-ui/lab/Alert";
import { VALIDATIONS } from "shared/constants";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { ROLE, PERMISSION } from "modules/shared/constants";
import Skeleton from "@material-ui/lab/Skeleton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TuneIcon from "@material-ui/icons/Tune";
import useStyles from "./style";
import Chip from "@material-ui/core/Chip";
import AddIcon from "@material-ui/icons/Add";
import PostAddIcon from "@material-ui/icons/PostAdd";
import { writeXLSFile } from "utils";
import WarningIcon from "@material-ui/icons/Warning";
import { FormGroup, Tab, Tabs } from "@material-ui/core";
import ExcludedRecord from "./excludedRecord";

let gridHelper = null,
  timeout = null;
let entries = null;
let isValueChanged = false;
let isConfirmDisabled = false;
let reason = "";

let newEntry = [];
let newExcludedEntry = [];

const TAB_LIST = [
  { id: 1, label: "Included Records" },
  { id: 2, label: "Excluded Records" },
];

const defaultState = {
  entries: [],
  tabIndex: 1,
  originalData: [],
  completeEntries: [],
  totalEntries: 0,
  isFetching: false,
  isAddButtonEnabled: false,
  isExcludingRecordsPermanent: false,
  isIncluding: false,
  isIncludingRecords: false,
  isTempExcluding: false,
  isPermExcluding: false,
  isFetchingList: {
    customers: false,
    users: false,
    settlementCompany: false,
    pdxCompany: false,
    IC: false,
    dcnUsers: false,
  },
  isFiltering: false,
  isLoading: false,
  isLookupLoading: false,
  pageFilters: [],
  dcnUsersList: [],
  selectedRows: [],
  excludeIds: [],
  notes: "",
  permExclude: "",
  tempExclude: "",
  email: null,
  newEmail: [],
  errors: {
    notes: " ",
    permExclude: " ",
    tempExclude: " ",
    email: " ",
  },
  aaUser: null,
  customer: null,
  kam: null,
  IC: null,
  settlementCompany: null,
  endDate: null,
  showConfirmationPopup: false,
  defaultBullletPoints: BULLET_POINTS_DCN,
  showAlertBar: false,
  showAlertBarMessage: false,
  showApproveRecordsDialog: false,
  showApproveRecordsMessage: false,
  showDCNHistoryDialog: false,
  showAddLookupDialog: false,
  showDCNHistoryDialogMessage: false,
  showAutoICDialog: false,
  showAutoICMessage: false,
  autoICIDs: [],
  startDate: null,
  payDate: null,
  filters: {
    customer: [],
    IC: [],
    aaUser: [],
    endDate: [],
    startDate: [],
    payDate: [],
    scheduleDate: [],
    kam: [],
    is_verified: [],
    daily_schedule_id: [],
    settlementCompany: [],
  },
  vehicleTypeList: [],
  locationList: [],
  customerlocationList: [],
  stateList: [],
  excludeOrder: null,
  excludeOrderBy: null,
  pdxCompanyList: [],
  isUnderReview: false,
  isDataFetched: false,
  approved: true,
  unapproved: true,
  pageSize: 100,
  testId: "",
  pageNumber: 1,
  order: null,
  isApproveLoading: false,
  shouldApproveEnable: false,
  shouldUnapproveEnable: false,
  orderBy: null,
  search: "",
  searchColumns: [],
  settlementCompanyList: [],
  isNew: false,
  rowBeingEdited: null,
  allRowsBeingEdited: null,
  deletingEntryId: null,
  isApproved: false,
  totalExcludedRecordEntries: 0,
  excludedRecords: [],
  excludeRecords: {},
  premExcludeRecords: {},
  isPermanentlyExcluded: false,
  isExcludingRecords: false,
  dynamicColumns: {
    select_all: { label: "Select All", value: "select_all", isSelected: true },
    ICID: { label: "ICID", value: "ICID", isSelected: true },
    DeptNum: {
      label: "Dept Num",
      value: "DeptNum",
      isSelected: true,
    },
    LastName: { label: "Last Name", value: "LastName", isSelected: true },
    FirstName: { label: "First Name", value: "FirstName", isSelected: true },
    SSN: {
      label: "SSN",
      value: "SSN",
      isSelected: true,
    },
    EIN: {
      label: "EIN",
      value: "EIN",
      isSelected: true,
    },
    CoName: {
      label: "IC Company Name",
      value: "CoName",
      isSelected: true,
    },
    Settlement: {
      label: "Settlement",
      value: "Settlement",
      isSelected: true,
    },
    FuelReimbursement: {
      label: "Fuel Reimbursement",
      value: "FuelReimbursement",
      isSelected: true,
    },
    TollReimbursement: {
      label: "Toll Reimbursement",
      value: "TollReimbursement",
      isSelected: true,
    },
    MileageReimbursement: {
      label: "Mileage Reimbursement",
      value: "MileageReimbursement",
      isSelected: true,
    },
    Reimbursement: {
      label: "Reimbursement",
      value: "Reimbursement",
      isSelected: true,
    },
    MiscDeduction: {
      label: "Misc Deduction",
      value: "MiscDeduction",
      isSelected: true,
    },
    Misc2Deduction: {
      label: "Misc2 Deduction",
      value: "Misc2Deduction",
      isSelected: true,
    },
    FuelAdvanceDeduction: {
      label: "Fuel Advance Deduction",
      value: "FuelAdvanceDeduction",
      isSelected: true,
    },
    FuelAdvance2: {
      label: "Fuel Advance2",
      value: "FuelAdvance2",
      isSelected: true,
    },
    CashAdvanceDeduction: {
      label: "Cash Advance Deduction",
      value: "CashAdvanceDeduction",
      isSelected: true,
    },
    CashAdvance2Deduction: {
      label: "Cash Advance2 Deduction",
      value: "CashAdvance2Deduction",
      isSelected: true,
    },
    ClaimDeduction: {
      label: "Claim Deduction",
      value: "ClaimDeduction",
      isSelected: true,
    },
    Claim2Deduction: {
      label: "Claim2 Deduction",
      value: "Claim2Deduction",
      isSelected: true,
    },
    Claim3Deduction: {
      label: "Claim3 Deduction",
      value: "Claim3Deduction",
      isSelected: true,
    },
    Rental1Deduction: {
      label: "Rental1 Deduction",
      value: "Rental1Deduction",
      isSelected: true,
    },
    Rental2Deduction: {
      label: "Rental2 Deduction",
      value: "Rental2Deduction",
      isSelected: true,
    },
    Rental3Deduction: {
      label: "Rental3 Deduction",
      value: "Rental3Deduction",
      isSelected: true,
    },
    Rental4Deduction: {
      label: "Rental4 Deduction",
      value: "Rental4Deduction",
      isSelected: true,
    },
    EOR: {
      label: "EOR",
      value: "EOR",
      isSelected: true,
    },
  },
  dateFilterType: "single",
  formAlertFields: {},
  fieldAlert: {
    show: false,
    id: null,
    isLoading: false,
    subHeading: "",
    configs: [],
    updateValidations: noop,
    onChange: noop,
  },
  isSendingToDCN: false,
  clickedRowId: null,
};

const DCN = ({ match, history }) => {
  const filterPageConfig = getPageConfig(PAGE_KEYS.DCN_FILTERS) || {};

  let isGoButtonIsClicked =
    (filterPageConfig && filterPageConfig?.isGoButtonIsClicked) || false;
  const classes = useStyles();
  const location = useLocation();
  const data = location.state || {};
  const { appData, updateContextData } = useContext(AppContext);
  const currentUser = getTokenData() || {};
  const isRoleAA = (currentUser?.role || "").toLowerCase() === ROLE.AA;
  const isRoleView = (currentUser?.role || "").toLowerCase() === ROLE.MAPS_VIEW;
  const selectedRef = useRef(null);

  const appDataList = useMemo(() => {
    const users = appData.users.reduce(
      (acc, user) => {
        const role = (user.role || "").toLowerCase();
        acc[role] && acc[role].push(user);
        return acc;
      },
      {
        kam: [],
        aa: [],
        mgr: [],
        admin: [],
      }
    );

    const IC = appData.IC.filter((ic) => ic.is_active);
    const customers = appData.customers.filter(
      (customer) => customer.is_active
    );

    const getOptionList = (users = []) => {
      return users.map((list) => ({
        value: list,
        label:
          list?.name ||
          `${list.first_name || ""} ${list.last_name || ""}-- ${
            list?.email || ""
          }`,
      }));
    };

    const ICOptions = IC.map((list) => ({
      value: list,
      label: `${list?.icid || ""}/${list?.first_name || ""} ${
        list?.last_name || ""
      }`,
    }));

    return {
      kamUsers: users.kam,
      officeUsers: users.aa,
      managerUsers: users.mgr,
      adminUsers: users.admin,
      IC,
      customers,
      kamUsersOptions: getOptionList(users.kam),
      officeUsersOptions: getOptionList(
        users.aa.filter((user) => user.is_active === true)
      ),
      managerUsersOptions: getOptionList(
        users.mgr.filter((user) => user.is_active === true)
      ),
      adminUsersOptions: getOptionList(
        users.admin.filter((user) => user.is_active === true)
      ),
      ICOptions,
    };
  }, [appData]);

  useEffect(() => {
    if (
      appDataList.kamUsers?.length &&
      appDataList.IC?.length &&
      appDataList.customers?.length &&
      appDataList.officeUsers?.length &&
      data?.kam_id
    ) {
      const kam = appDataList.kamUsers?.find((kam) => kam?.id == data?.kam_id);
      const customer = appDataList.customers?.find(
        (customer) => customer.id == data?.customer_id
      );
      const IC = appDataList.IC?.find(
        (ic) => ic.id == data?.individual_contractor_id
      );
      const aaUser = appDataList.officeUsers?.filter(
        (aa) => aa.id == data?.aa_id
      );
      setState((prevState) => ({
        ...prevState,
        kam,
        customer,
        IC,
        aaUser,
      }));
      window.history.replaceState(null, document.title);
      history.replace({ ...history.location, state: null });
    }
  }, [
    appDataList.kamUsers,
    appDataList.customers,
    appDataList.IC,
    appDataList.officeUsers,
  ]);

  const [state, setState] = useState({
    ...defaultState,
    pageSize: filterPageConfig?.pageSize || defaultState.pageSize,
    dynamicColumns:
      filterPageConfig?.dynamicColumns || defaultState.dynamicColumns,
    clickedRowId:
      (filterPageConfig && filterPageConfig?.clickedRowId) ||
      defaultState.clickedRowId,
    settlementCompany: isGoButtonIsClicked
      ? (filterPageConfig && filterPageConfig?.settlementCompanyList?.value) ||
        defaultState.settlementCompany
      : defaultState.settlementCompany,
    aaUser: isRoleAA ? currentUser : null,
    testId: data?.daily_schedule_id || defaultState.testId,
    startDate: isGoButtonIsClicked
      ? (filterPageConfig && filterPageConfig?.startDateFilter) ||
        (() => {
          const startDate = new Date();
          startDate.setDate(startDate.getDate() - 6);
          return getDateString(startDate);
        })()
      : (() => {
          const startDate = new Date();
          startDate.setDate(startDate.getDate() - 6);
          return getDateString(startDate);
        })(),
    endDate: isGoButtonIsClicked
      ? (filterPageConfig && filterPageConfig?.endDateFilter) ||
        getDateString(new Date())
      : getDateString(new Date()),
    payDate: getDateString(new Date()),
    filters: {
      ...defaultState.filters,
      settlementCompany:
        (filterPageConfig &&
          filterPageConfig?.settlementCompanyList?.settlementCompany) ||
        defaultState.settlementCompany,
      aaUser: isRoleAA
        ? [{ field: "aa_id", type: "=", value: currentUser?.id }]
        : [],
      startDate: [
        {
          field: "schedule_date",
          type: "=",
          value: data.schedule_date
            ? getUTCDateString(data.schedule_date)
            : isGoButtonIsClicked
            ? (filterPageConfig && filterPageConfig?.startDateFilter) ||
              getDateString(new Date())
            : getDateString(new Date()),
        },
      ],
      endDate: isGoButtonIsClicked
        ? (filterPageConfig && filterPageConfig?.endDateFilter) ||
          getDateString(new Date())
        : getDateString(new Date()),
      kam: data.kam_id
        ? [
            {
              field: "kam_id",
              type: "=",
              value: data.kam_id,
            },
          ]
        : [],
      daily_schedule_id: data.daily_schedule_id
        ? [
            {
              field: "daily_schedule_id",
              type: "=",
              value: data.daily_schedule_id,
            },
          ]
        : [],
    },
  });

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (selectedRef.current && !!state.clickedRowId) {
        selectedRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        observer.disconnect();
      }
    });

    if (document.body) {
      observer.observe(document.body, { childList: true, subtree: true });
    }

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      setPageConfig(PAGE_KEYS.DCN_FILTERS, {
        clickedRowId: null,
      });
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleSubmit = async () => {
    const payload_data = JSON.parse(localStorage.getItem("filters-dcn"));

    const updatedEntries = state.entries?.map((entry) => {
      const { startDate, endDate, settlementCompany, ...rest } = entry;
      return rest;
    });

    const finalData = {
      api_key: state.initialData.api_key,
      upload_date: state.initialData.upload_date,
      upload_id: state.initialData.upload_id,
      clientdata: {
        Client: state.initialData.clientdata.Client,
        icdata: updatedEntries,
      },
    };

    const emailArray = state?.newEmail.map(({ email }) => email);
    const uniqueEmails = [...new Set(emailArray)];
    const emailString = uniqueEmails?.join(",");

    const idsExcluded = state.excludeIds.length
      ? state.excludeIds.join(",")
      : "";

    const payload = {
      updatedData: JSON.stringify(finalData),
      id: state.data.id,
      payDate: state.payDate,
      dcnEmails: emailString,
      excludeIds: idsExcluded,
      fromDate: payload_data.start_date,
      toDate: payload_data.end_date,
      settlement_co_id: payload_data.settlementCompany,
    };

    setState((prevState) => ({
      ...prevState,
      isSendingToDCN: true,
    }));
    const { error } = await Service.sendToDCN(payload);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isSendingToDCN: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }

    toast.success("Data successfully sent to DCN.");

    const dcnPageConfig = getPageConfig(PAGE_KEYS.DCN) || {};
    const newPageConfig = { ...dcnPageConfig };
    if (newPageConfig.modifiedEntries) {
      newPageConfig.modifiedEntries = newPageConfig.modifiedEntries.filter(
        (modifiedEntry) => {
          return !state.entries.some(
            (entry) =>
              (entry.ICID === modifiedEntry.ICID &&
                modifiedEntry.settlementCompany === entry.settlementCompany &&
                modifiedEntry.startDate === entry.startDate &&
                modifiedEntry.endDate === entry.endDate) ||
              (entry.ICID === modifiedEntry.ICID &&
                modifiedEntry.settlementCompany ===
                  state.settlementCompany?.value &&
                modifiedEntry.startDate === state.startDate &&
                modifiedEntry.endDate === state.endDate)
          );
        }
      );
    }
    setPageConfig(PAGE_KEYS.DCN, newPageConfig);

    setState((prevState) => ({
      ...prevState,
      isSendingToDCN: false,
      showConfirmationPopup: false,
      isDataFetched: false,
      settlementCompany: null,
      email: null,
      excludeIds: defaultState.excludeIds,
      showAlertBar: false,
      endDate: getDateString(new Date()),
      newEmail: [],
      entries: defaultState.entries,
      startDate: (() => {
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 6);
        return getDateString(startDate);
      })(),
    }));
    setPageConfig(PAGE_KEYS.DCN_FILTERS, {
      isGoButtonIsClicked: false,
      startDateFilter: null,
      endDateFilter: null,
      settlementCompanyList: null,
    });
    //upload api integration
  };

  const fetchDCNUsersList = async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      isFetchingList: {
        ...prevState.isFetchingList,
        dcnUsers: true,
      },
    }));

    const { data, error } = await SharedService.getDCNUsersList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isFetchingList: {
          ...prevState.isFetchingList,
          dcnUsers: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      showAddLookupDialog: false,
      email: "",
      dcnUsersList:
        data?.rows.filter((dcnUsers) => dcnUsers.is_active) ||
        defaultState.dcnUsersList,
      isFetchingList: {
        ...prevState.isFetchingList,
        dcnUsers: false,
      },
    }));
  };

  const getFieldValidatorMap = (state, field) => {
    const fieldValidatorMap = {
      notes: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
      permExclude: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
      tempExclude: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
      email: [{ type: VALIDATIONS.EMAIL, value: true }],
    };
    return fieldValidatorMap;
  };

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = getFieldValidatorMap(state, field);

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const handleFieldChange = (evt) => {
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    let errorMessage = "";

    if (field === "email") {
      if (
        state.dcnUsersList.some((obj) => obj.value === value) ||
        state.newEmail.some((obj) => obj.email === value)
      ) {
        errorMessage = "This email has already been entered.";
      } else {
        errorMessage = validate(field, value);
      }
      isConfirmDisabled = !!errorMessage;
      setState((prevState) => ({
        ...prevState,
        isAddButtonEnabled: value?.length > 0 ? true : false,
        [field]: value,
        errors: {
          ...prevState.errors,
          [field]: errorMessage,
        },
        isConfirmDisabled,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [field]: value,
        errors: {
          ...prevState.errors,
          [field]: validate(field, value) || " ",
        },
      }));
    }
  };

  const handleShowAddLookupDialog = () => {
    setState((prevState) => ({
      ...prevState,
      showAddLookupDialog: true,
    }));
  };

  const handleAddEmail = () => {
    if (state.email?.trim()) {
      setState((prevState) => ({
        ...prevState,
        newEmail: [
          ...prevState.newEmail,
          { id: Date.now(), email: state?.email?.trim() },
        ],
        email: "",
        isAddButtonEnabled: false,
        showAddLookupDialog: false,
      }));
    }
  };

  const handleDeleteEmail = (id) => {
    setState((prevState) => {
      const filteredEmails = prevState.newEmail.filter(
        (email) => email.id !== id
      );
      const emailExists = filteredEmails.some(
        (obj) => obj.email === prevState.email
      );
      const isAddButtonEnabled = emailExists;
      isConfirmDisabled = isAddButtonEnabled;
      return {
        ...prevState,
        newEmail: filteredEmails,
        errors: {
          email: emailExists ? prevState.errors.email : "",
        },
        isAddButtonEnabled: isAddButtonEnabled,
      };
    });
  };

  const fetchLocalResources = async () => {
    const response = await Service.getLocalResources();
    setState((prevState) => ({
      ...prevState,
      stateList: (response?.data?.states || defaultState.stateList).sort(
        (a, b) => {
          const valueA = (a.name || "").toLowerCase();
          const valueB = (b.name || "").toLowerCase();
          return valueA > valueB ? 1 : valueB > valueA ? -1 : 0;
        }
      ),
      locationList: response?.data?.locations || defaultState.locationList,
    }));
  };

  const fetchCustomerLocation = async () => {
    const { data, error } = await Service.getCustomerLocation();

    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      customerlocationList:
        data?.locations || defaultState.customerlocationList,
    }));
  };

  const fetchCompanyList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        settlementCompany: true,
      },
    }));

    const { data, error } = await SharedService.getSettlementCompanyList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          settlementCompany: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      settlementCompanyList:
        data?.rows.filter((settlementCompany) => settlementCompany.is_active) ||
        defaultState.settlementCompanyList,
      isFetchingList: {
        ...prevState.isFetchingList,
        settlementCompany: false,
      },
    }));
  };

  const fetchPDXCompanyList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: true,
      },
    }));

    const { data, error } = await SharedService.getPDXCompanyList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          pdxCompany: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      pdxCompanyList:
        data?.rows.filter((pdxCompany) => pdxCompany.is_active) ||
        defaultState.pdxCompanyList,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: false,
      },
    }));
  };

  const fetchVehicleTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        vehicleType: true,
      },
    }));
    const { data, error } = await SharedService.getVehicleTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          vehicleType: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      vehicleTypeList:
        data?.rows.filter((vehicle) => vehicle.is_active) ||
        defaultState.vehicleTypeList,
      isFetchingList: {
        ...prevState.isFetchingList,
        vehicleType: false,
      },
    }));
  };

  const addLookupValue = async () => {
    setState((prevState) => ({ ...prevState, isLookupLoading: true }));

    const payload = {
      type: "DCN Email Recipients",
      value: state.email,
      is_active: true,
      description: "hii",
    };

    const { error } = await Service.addEmailInLookup(payload);

    if (error) {
      handleAddEmail();
      setState((prevState) => ({ ...prevState, isLookupLoading: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success(`Value added in Lookup(DCN Email Recipients) successfully.`);
    setState((prevState) => ({
      ...prevState,
      isLookupLoading: false,
      showAddLookupDialog: false,
      isAddButtonEnabled: false,
    }));
  };

  const fetchEntries = async (
    startDate,
    endDate,
    filters,
    hasLoader = true
  ) => {
    let queryString = `?fromDate=${startDate}&toDate=${endDate}&settlement_co_id=${filters.settlementCompany[0].value}`;

    setState((prevState) => ({
      ...prevState,
      isFetching: hasLoader,
      showAlertBar: false,
      showAutoICDialog: false,
      showApproveRecordsDialog: false,
      showDCNHistoryDialog: false,
    }));

    const { data, error } = await Service.getDCN(queryString);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetching: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }

    if (data.code === "PDX-DCN-002") {
      return setState((prevState) => ({
        ...prevState,
        isDataFetched: false,
        showApproveRecordsDialog: true,
        showApproveRecordsMessage: data.message || "",
      }));
    }

    if (data.code === "PDX-DCN-004") {
      return setState((prevState) => ({
        ...prevState,
        isDataFetched: false,
        showAutoICDialog: true,
        showAutoICMessage: data.message || "",
        autoICIDs: data.icids || defaultState.autoIcids,
      }));
    }

    if (data.code === "PDX-DCN-003") {
      const parsedData = JSON.parse(data.icReportJson);
      const originaParsedData = JSON.parse(JSON.stringify(parsedData));
      return setState((prevState) => ({
        ...prevState,
        showAlertBar: true,
        showAlertBarMessage: data.message || "",
        data,
        isFetching: false,
        initialData: parsedData,
        originalData:
          originaParsedData.clientdata?.icdata || defaultState.originalData,
        icdata: parsedData.clientdata?.icdata || defaultState.entries,
        entries: (parsedData.clientdata?.icdata || defaultState.entries).map(
          (item) => {
            item.startDate = state.startDate;
            item.endDate = state.endDate;
            item.settlementCompany = state.settlementCompany?.value;
            const persitedModifiedentries =
              getPageConfig(PAGE_KEYS.DCN)?.modifiedEntries || [];
            const persistedEntry = persitedModifiedentries?.find(
              (entry) =>
                entry.ICID === item.ICID &&
                entry.settlementCompany === state.settlementCompany?.value &&
                entry.startDate === state.startDate &&
                entry.endDate === state.endDate
            );
            return persistedEntry ? persistedEntry : item;
          }
        ),
        totalEntries:
          parsedData?.clientdata?.icdata.length || defaultState.totalEntries,
      }));
    }
    if (data.code === "PDX-DCN-005") {
      const parsedData = JSON.parse(data.icReportJson);
      const originaParsedData = JSON.parse(JSON.stringify(parsedData));
      return setState((prevState) => ({
        ...prevState,
        showAlertBar: true,
        showAlertBarMessage: data.message || "",
        data,
        isFetching: false,
        initialData: parsedData,
        originalData:
          originaParsedData.clientdata?.icdata || defaultState.originalData,
        icdata: parsedData.clientdata?.icdata || defaultState.entries,
        entries: (parsedData.clientdata?.icdata || defaultState.entries).map(
          (item) => {
            item.startDate = state.startDate;
            item.endDate = state.endDate;
            item.settlementCompany = state.settlementCompany?.value;
            const persitedModifiedentries =
              getPageConfig(PAGE_KEYS.DCN)?.modifiedEntries || [];
            const persistedEntry = persitedModifiedentries?.find(
              (entry) =>
                entry.ICID === item.ICID &&
                entry.settlementCompany === state.settlementCompany?.value &&
                entry.startDate === state.startDate &&
                entry.endDate === state.endDate
            );
            return persistedEntry ? persistedEntry : item;
          }
        ),
        totalEntries:
          parsedData?.clientdata?.icdata.length || defaultState.totalEntries,
      }));
    }

    if (data.code === "PDX-DCN-001") {
      return setState((prevState) => ({
        ...prevState,
        showDCNHistoryDialog: true,
        showDCNHistoryDialogMessage: data.message || "",
        isFetching: false,
        entries: defaultState.entries,
        totalEntries: defaultState.totalEntries,
      }));
    }

    if (!data.icReportJson) {
      return setState((prevState) => ({
        ...prevState,
        isFetching: false,
        entries: defaultState.entries,
        totalEntries: defaultState.totalEntries,
      }));
    }

    const parsedData = JSON.parse(data.icReportJson);
    const originaParsedData = JSON.parse(JSON.stringify(parsedData));

    setState((prevState) => ({
      ...prevState,
      data,
      isFetching: false,
      initialData: parsedData,
      originalData:
        originaParsedData.clientdata?.icdata || defaultState.originalData,
      icdata: parsedData.clientdata?.icdata || defaultState.entries,
      entries: (parsedData.clientdata?.icdata || defaultState.entries).map(
        (item) => {
          item.startDate = state.startDate;
          item.endDate = state.endDate;
          item.settlementCompany = state.settlementCompany?.value;
          const persitedModifiedentries =
            getPageConfig(PAGE_KEYS.DCN)?.modifiedEntries || [];
          const persistedEntry = persitedModifiedentries?.find(
            (entry) =>
              entry.ICID === item.ICID &&
              entry.settlementCompany === state.settlementCompany?.value &&
              entry.startDate === state.startDate &&
              entry.endDate === state.endDate
          );
          return persistedEntry ? persistedEntry : item;
        }
      ),
      totalEntries:
        parsedData?.clientdata?.icdata.length || defaultState.totalEntries,
    }));

    return data;
  };

  const fetchList = async (listType = "customers", ...params) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    switch (listType) {
      case "customers":
        serviceMethod = "getCustomerList";
        responseKey = "customers";
        break;
      case "IC":
        serviceMethod = "getContractorList";
        responseKey = "contractors";
        break;
      case "users":
        serviceMethod = "getUsersList";
        responseKey = "users";
        break;
      default:
        serviceMethod = "getCustomerList";
        responseKey = "customers";
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];
    if (listType === "customers") {
      listData.map((item = {}) => {
        (item.customer_branches || []).map((branch = {}) => {
          (branch.routes || []).sort((a, b) =>
            Intl.Collator().compare(a.name, b.name)
          );
        });
      });
    }
    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
  };

  const fetchExcludedRecords = async (
    startDate,
    endDate,
    filters,
    hasLoader = true
  ) => {
    let queryString = `?fromDate=${startDate}&toDate=${endDate}&settlement_co_id=${filters.settlementCompany[0].value}`;

    setState((prevState) => ({
      ...prevState,
      isFetching: hasLoader,
      showAlertBar: false,
      showAutoICDialog: false,
    }));

    const { data, error } = await Service.getSettlementExcludedRecords(
      queryString
    );

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetching: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }

    const parsedTemporaryExcludedData = data?.tempExcluded
      ? JSON.parse(data.tempExcluded)
      : null;
    const parsedPermanentExcludedData = data?.permExcluded
      ? JSON.parse(data.permExcluded)
      : null;

    const tempExcludedData =
      parsedTemporaryExcludedData?.clientdata?.icdata || [];
    const permExcludedData =
      parsedPermanentExcludedData?.clientdata?.icdata || [];

    const mergeExcludeData = [...tempExcludedData, ...permExcludedData];

    setState((prevState) => ({
      ...prevState,
      isFetching: false,
      totalExcludedRecordEntries:
        tempExcludedData.length + permExcludedData.length ||
        defaultState.totalExcludedRecordEntries,
      excludedRecords: mergeExcludeData || defaultState.excludedRecords,
    }));

    return data;
  };

  const excludeRecordsSettlements = async (
    rowData,
    reason,
    isPermanentlyExcluded
  ) => {
    setState((prevState) => ({
      ...prevState,
      isTempExcluding: true,
    }));
    const payload = {
      fromDate: state.startDate,
      toDate: state.endDate,
      contractor_id: rowData.contractorId,
      reason: reason,
      is_permanently_excluded: isPermanentlyExcluded,
    };

    const { error } = await Service.excludeRecordsFromSettlements(payload);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isTempExcluding: false,
        isExcludingRecords: false,
        tempExclude: defaultState.tempExclude,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success(`Dcn excluded ics data created successfully.`);
    setState((prevState) => ({
      ...prevState,
      isTempExcluding: false,
      isExcludingRecords: false,
      tempExclude: defaultState.tempExclude,
    }));
    fetchEntries(state.startDate, state.endDate, state.filters);
    fetchExcludedRecords(state.startDate, state.endDate, state.filters);
  };

  const permanentExcludeRecordsSettlements = async (
    rowData,
    reason,
    isPermanentlyExcluded
  ) => {
    setState((prevState) => ({
      ...prevState,
      isPermExcluding: true,
    }));
    const payload = {
      fromDate: state.startDate,
      toDate: state.endDate,
      contractor_id: rowData.contractorId,
      reason: reason,
      is_permanently_excluded: isPermanentlyExcluded,
    };

    const { error } = await Service.permanentExcludeRecordsFromSettlements(
      payload
    );

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isPermExcluding: false,
        isExcludingRecordsPermanent: false,
        permExclude: defaultState.permExclude,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success(`Dcn excluded ics data created successfully.`);
    setState((prevState) => ({
      ...prevState,
      isPermExcluding: false,
      isExcludingRecordsPermanent: false,
      permExclude: defaultState.permExclude,
    }));
    fetchExcludedRecords(state.startDate, state.endDate, state.filters);
  };

  const includeRecordsSettlements = async (rowData) => {
    setState((prevState) => ({
      ...prevState,
      isIncluding: true,
    }));
    let queryString = `?fromDate=${state.startDate}&toDate=${state.endDate}&contractor_id=${rowData.contractorId}`;

    const { error } = await Service.includeRecordsToSettlements(queryString);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isIncluding: false,
        isIncludingRecords: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success(`Record included successfully.`);
    setState((prevState) => ({
      ...prevState,
      isIncluding: false,
      isIncludingRecords: false,
    }));
    fetchEntries(state.startDate, state.endDate, state.filters);
    fetchExcludedRecords(state.startDate, state.endDate, state.filters);
  };

  useEffect(() => {
    setTimeout(updateLayout, 1000);
  }, [state.entries, state.excludedRecords, state.tabIndex]);

  useEffect(() => {
    fetchLocalResources();
    fetchVehicleTypeList();
    fetchPDXCompanyList();
    fetchCompanyList();
    fetchCustomerLocation();
  }, []);

  useEffect(() => {
    fetchList("customers");
    fetchList("IC");
    fetchList("users");
  }, [match.params.path]);

  useEffect(() => {
    if (isGoButtonIsClicked) {
      fetchEntries(state.startDate, state.endDate, state.filters);
      fetchExcludedRecords(state.startDate, state.endDate, state.filters);
      setState((prevState) => ({
        ...prevState,
        isDataFetched: true,
      }));
    }
  }, [isGoButtonIsClicked]);

  const handlePageNumberChange = useCallback((pageNumber) => {
    setState((prevState) => ({
      ...prevState,
      pageNumber,
      selectedRows: defaultState.selectedRows,
    }));
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setPageConfig(PAGE_KEYS.DCN_FILTERS, {
      pageSize,
    });
    setState((prevState) => ({
      ...prevState,
      pageSize,
      pageNumber: defaultState.pageNumber,
      selectedRows: defaultState.selectedRows,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleSelectionChange = useCallback((selectedRows) => {
    setState((prevState) => ({
      ...prevState,
      selectedRows,
    }));
  }, []);

  const handleSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      order: order,
      orderBy: fieldObj.field || fieldObj.fieldName,
      selectedRows: defaultState.selectedRows,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleExcludeSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      excludeOrder: order,
      excludeOrderBy: fieldObj.field || fieldObj.fieldName,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleEdit = (updatedEntries = [], ICID) => {
    let persitedModifiedentries =
      getPageConfig(PAGE_KEYS.DCN)?.modifiedEntries || [];
    const updatedEntry =
      updatedEntries.find((item) => item.ICID === ICID) || {};
    const orginalEntry = {
      ...(state.originalData.find((item) => item.ICID === updatedEntry.ICID) ||
        {}),
      startDate: state.startDate,
      endDate: state.endDate,
      settlementCompany: state.settlementCompany?.value,
    };
    const updatedModifiedEntry = {
      ...updatedEntry,
      startDate: state.startDate,
      endDate: state.endDate,
      settlementCompany: state.settlementCompany?.value,
    };

    const field = "_notes";
    const filteredPaydata = {};
    for (const key in updatedModifiedEntry.paydata[0]) {
      if (!key.includes(field)) {
        filteredPaydata[key] = updatedModifiedEntry.paydata[0][key];
      }
    }
    const updatedModifiedEntryWithoutNotes = {
      ...updatedModifiedEntry,
      paydata: [filteredPaydata],
    };

    if (
      persitedModifiedentries.some(
        (item) =>
          item.ICID === updatedModifiedEntry.ICID &&
          item.settlementCompany === updatedModifiedEntry.settlementCompany &&
          item.startDate === updatedModifiedEntry.startDate &&
          item.endDate === updatedModifiedEntry.endDate
      )
    ) {
      if (
        JSON.stringify(orginalEntry) ===
        JSON.stringify(updatedModifiedEntryWithoutNotes)
      ) {
        persitedModifiedentries = persitedModifiedentries.filter(
          (item) =>
            item.ICID !== updatedModifiedEntry.ICID ||
            (item.ICID !== updatedModifiedEntry.ICID &&
              item.settlementCompany !==
                updatedModifiedEntry.settlementCompany &&
              item.startDate !== updatedModifiedEntry.startDate &&
              item.endDate !== updatedModifiedEntry.endDate)
        );
        isValueChanged = false;
      } else {
        persitedModifiedentries = persitedModifiedentries.map((item) =>
          item.ICID === updatedModifiedEntry.ICID &&
          item.settlementCompany === updatedModifiedEntry.settlementCompany &&
          item.startDate === updatedModifiedEntry.startDate &&
          item.endDate === updatedModifiedEntry.endDate
            ? updatedModifiedEntry
            : item
        );
        isValueChanged = true;
      }
    } else {
      persitedModifiedentries = [
        ...persitedModifiedentries,
        updatedModifiedEntry,
      ];
    }
    setPageConfig(PAGE_KEYS.DCN, { modifiedEntries: persitedModifiedentries });
  };

  const handleTabChange = useCallback((event, tabIndex) => {
    setState((prevState) => ({
      ...prevState,
      tabIndex,
      selectedRows: defaultState.selectedRows,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const permanentExcludeRecords = (isExcluded, row) => {
    let reasons = "";
    reasons = (row.tempExcludedData || row.permExcludedData)[0]?.reason?.split(
      "||"
    );
    setState((prevState) => ({
      ...prevState,
      isExcludingRecordsPermanent: isExcluded,
      premExcludeRecords: row,
      permExclude: reasons[0],
    }));
  };

  const includeExcludedRecords = (isInclude) => {
    setState((prevState) => ({
      ...prevState,
      isIncludingRecords: isInclude,
    }));
  };

  const revertAllChangedValue = () => {
    const dcnPageConfig = getPageConfig(PAGE_KEYS.DCN) || {};
    const newPageConfig = { ...dcnPageConfig };
    if (newPageConfig.modifiedEntries) {
      newPageConfig.modifiedEntries = newPageConfig.modifiedEntries.filter(
        (modifiedEntry) => {
          return !state.entries.some(
            (entry) =>
              entry.ICID === modifiedEntry.ICID &&
              modifiedEntry.settlementCompany ===
                state.settlementCompany?.value &&
              modifiedEntry.startDate === state.startDate &&
              modifiedEntry.endDate === state.endDate
          );
        }
      );
    }
    setPageConfig(PAGE_KEYS.DCN, newPageConfig);

    setState((prevState) => ({
      ...prevState,
      allRowsBeingEdited: false,
    }));
    isValueChanged = false;
  };

  const moreActions = (row) => {
    if (row.status === "temp") {
      return false;
    } else {
      const rowData = row.paydata[0] || [];
      const originalData = state.originalData.find(
        (item) => item?.ICID === row?.ICID
      )?.paydata[0];
      let isRowEdited = false;
      for (const [key, value] of Object.entries(rowData)) {
        if (value !== originalData[key]) {
          isRowEdited = true;
          isValueChanged = true;
        }
      }
      return (
        <div className="d-flex f-align-center">
          {!isRoleView && (
            <Tooltip title="Reset changed values" placement="top-start">
              <RotateLeftIcon
                className={
                  isRowEdited
                    ? clsx("ml-2 mr-2 c-pointer", classes.VisibilityIconColor)
                    : clsx("ml-2 mr-2", classes.disabledIconColor)
                }
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    rowBeingEdited: isRowEdited ? row : null,
                  }))
                }
              />
            </Tooltip>
          )}
        </div>
      );
    }
  };

  const revertChangedValue = () => {
    const dcnPageConfig = getPageConfig(PAGE_KEYS.DCN) || {};
    const newPageConfig = { ...dcnPageConfig };
    if (newPageConfig.modifiedEntries) {
      newPageConfig.modifiedEntries = newPageConfig.modifiedEntries.filter(
        (modifiedEntry) => {
          const isSameEntry =
            modifiedEntry.ICID === state.rowBeingEdited?.ICID &&
            modifiedEntry.settlementCompany ===
              state.settlementCompany?.value &&
            modifiedEntry.startDate === state.startDate &&
            modifiedEntry.endDate === state.endDate;
          return !isSameEntry;
        }
      );
    }
    setPageConfig(PAGE_KEYS.DCN, newPageConfig);

    setState((prevState) => ({
      ...prevState,
      rowBeingEdited: false,
    }));
  };

  const dcnPageConfig = getPageConfig(PAGE_KEYS.DCN) || {};
  const newPageConfig = { ...dcnPageConfig };
  if (newPageConfig.modifiedEntries) {
    isValueChanged = newPageConfig.modifiedEntries.some((modifiedEntry) => {
      return state.entries.some(
        (entry) =>
          entry.ICID === modifiedEntry.ICID &&
          modifiedEntry.settlementCompany === entry.settlementCompany &&
          modifiedEntry.startDate === entry.startDate &&
          modifiedEntry.endDate === entry.endDate
      );
    });
  }

  const handleDownload = async (data, newRow) => {
    const updatedEntries = data?.map((entry) => {
      const { startDate, endDate, settlementCompany, ...rest } = entry;
      return rest;
    });

    const finalData = [...updatedEntries, ...newRow].map((entry) => {
      let excludedBy = "-";
      let excludedAt = "-";
      let excludeReason = "-";
      if (
        (!!entry.tempExcludedData && entry.tempExcludedData !== "-") ||
        (!!entry.permExcludedData && entry.permExcludedData !== "-")
      ) {
        let reasons = (entry.tempExcludedData ||
          entry.permExcludedData)[0]?.reason?.split("||");
        excludeReason = reasons[0];

        excludedAt =
          `${getDateString(
            (entry.tempExcludedData || entry.permExcludedData)[0]?.excluded_at
          )} ${getFormattedTime(
            new Date(
              (entry.tempExcludedData || entry.permExcludedData)[0]?.excluded_at
            )
          )}` || "";

        const excluded_by = (entry.tempExcludedData ||
          entry.permExcludedData)[0]?.excluded_by;
        const user = appData.users.find((item) => item.id === excluded_by);
        excludedBy = `${user?.first_name} ${user?.last_name}`;
      }

      const additionalColumns =
        state.tabIndex === 2
          ? {
              "": entry.status === "temp" ? "Total" : "",
              "Permanent Exclude":
                entry.status === "temp"
                  ? "-"
                  : entry.isPermExcludedStatus
                  ? "Yes"
                  : "No",
              "Excluded By": excludedBy,
              "Excluded At": excludedAt,
              "Excludeld Reason": excludeReason,
            }
          : { "": entry.status === "temp" ? "Total" : "" };

      return {
        ...additionalColumns,
        ICID: entry.ICID,
        "Dept Num": entry.DeptNum,
        "Last Name": entry.LastName || "-",
        "First Name": entry.FirstName,
        SSN: entry.SSN || "-",
        EIN: entry.EIN || "-",
        "IC Company Name": entry.CoName || "-",
        Settlement: Number(entry.paydata[0]?.Settlement).toFixed(2),
        "Fuel Reimbursement": Number(
          entry.paydata[0]?.FuelReimbursement
        ).toFixed(2),
        "Toll Reimbursement": Number(
          entry.paydata[0]?.TollReimbursement
        ).toFixed(2),
        "Mileage Reimbursement": Number(
          entry.paydata[0]?.MileageReimbursement
        ).toFixed(2),
        Reimbursement: Number(entry.paydata[0]?.Reimbursement).toFixed(2),
        "Misc Deduction": Number(entry.paydata[0]?.MiscDeduction).toFixed(2),
        "Misc2 Deduction": Number(entry.paydata[0]?.Misc2Deduction).toFixed(2),
        "Fuel Advance Deduction": Number(
          entry.paydata[0]?.FuelAdvanceDeduction
        ).toFixed(2),
        "Fuel Advance2": Number(entry.paydata[0]?.FuelAdvance2).toFixed(2),
        "Cash Advance Deduction": Number(
          entry.paydata[0]?.CashAdvanceDeduction
        ).toFixed(2),
        "Cash Advance2 Deduction": Number(
          entry.paydata[0]?.CashAdvance2Deduction
        ).toFixed(2),
        "Claim Deduction": Number(entry.paydata[0]?.ClaimDeduction).toFixed(2),
        "Claim2 Deduction": Number(entry.paydata[0]?.Claim2Deduction).toFixed(
          2
        ),
        "Claim3 Deduction": Number(entry.paydata[0]?.Claim3Deduction).toFixed(
          2
        ),
        "Rental1 Deduction": Number(entry.paydata[0]?.Rental1Deduction).toFixed(
          2
        ),
        "Rental2 Deduction": Number(entry.paydata[0]?.Rental2Deduction).toFixed(
          2
        ),
        "Rental3 Deduction": Number(entry.paydata[0]?.Rental3Deduction).toFixed(
          2
        ),
        "Rental4 Deduction": Number(entry.paydata[0]?.Rental4Deduction).toFixed(
          2
        ),
        EOR: Number(entry.paydata[0]?.EOR).toFixed(2),
        "Is Flagged": entry.status !== "temp" ? entry.isFlagged : "-",
        "Is Caution Flagged":
          entry.status !== "temp" ? entry.isCautiousFlagged : "-",
        "Flagged Reason": entry.flaggedReason || "-",
        Hold:
          entry.status !== "temp"
            ? entry.paydata[0]?.hold == 0
              ? false
              : true
            : "-",
      };
    });
    writeXLSFile(
      state.tabIndex === 1 ? "Settlements Data" : "Excluded Settlement Data",
      "sheet",
      finalData
    );
  };

  const columnConfig = [
    {
      id: "hold",
      headerClassName: classes.holdPaddingLeft,
      label: "Hold",
      field: "hold",
      render: (row) => {
        if (row.status === "temp") {
          if (!isRoleView) {
            return false;
          } else {
            return (
              <Tooltip title={"Total"} placement="top-start">
                <Typography className="text-bold" noWrap variant="body1">
                  {"Total"}
                </Typography>
              </Tooltip>
            );
          }
        }

        const originalEntry = state.originalData.find(
          (item) => item.ICID === row.ICID
        );
        const holdValue = row?.paydata[0]?.hold === "1";

        return hasFeatureAccess(PERMISSION.MANAGE_DCN) ? (
          <div className="d-flex f-align-center f-justify-start">
            <Tooltip
              title={`Mark ${holdValue ? "Don't Hold" : "Hold"}`}
              placement="top-start"
            >
              <Switch
                size="small"
                checked={holdValue}
                onChange={() => {
                  setState((prevState) => {
                    const updatedEntries = prevState?.entries?.map((entry) => {
                      if (entry.ICID === row.ICID) {
                        entry.paydata[0].hold = holdValue ? "0" : "1";
                      }
                      return entry;
                    });

                    const isFieldModified =
                      originalEntry?.paydata[0]?.hold !== holdValue ? "0" : "1";

                    if (isFieldModified) {
                      handleEdit(updatedEntries, row.ICID);
                    }

                    return {
                      ...prevState,
                      entries: updatedEntries,
                    };
                  });
                }}
                color="primary"
              />
            </Tooltip>
          </div>
        ) : (
          <div className="d-flex f-align-center f-justify-start">
            <Tooltip
              title={row.paydata[0].hold === "0" ? "Don't Hold" : "Hold"}
              placement="top-start"
            >
              <Switch
                size="small"
                checked={row.paydata[0].hold === "1"}
                color="primary"
                style={{ cursor: "default" }}
              />
            </Tooltip>
          </div>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.ICID?.isSelected,
      headerClassName: classes.icidFlagTitle,
      id: "ICID",
      label: "ICID",
      field: "ICID",
      canSort: true,
      render: (row) => {
        const flagLabel =
          (row?.isFlagged === "True" && row?.isCautiousFlagged === "True") ||
          row?.isFlagged === "True" ? (
            <FlagIcon style={{ color: "red" }} />
          ) : row?.isCautiousFlagged === "True" ? (
            <div className={classes.warningIconHeight}>
              <WarningIcon className={classes.warningIcon} />
              <div className={classes.exclamationMark}></div>
            </div>
          ) : (
            ""
          );

        const flagTitles = `Flagged Reason: ${row.flaggedReason}`;

        return (
          <Fragment>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Tooltip title={flagTitles} placement="top-start">
                <Typography noWrap variant="body2">
                  {flagLabel}
                </Typography>
              </Tooltip>
              <>
                <div
                  className={
                    row?.isFlagged === "True" ||
                    row?.isCautiousFlagged === "True"
                      ? ""
                      : "ml-5"
                  }
                >
                  <Tooltip title={row.ICID ?? ""} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {row.ICID || "-"}
                    </Typography>
                  </Tooltip>
                </div>
              </>
            </div>
          </Fragment>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.DeptNum?.isSelected,
      id: "DeptNum",
      label: "Dept Num",
      field: "DeptNum",
      render: (row) => {
        return (
          <Tooltip title={row.DeptNum ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.DeptNum}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      isHidden: !state.dynamicColumns?.LastName?.isSelected,
      id: "LastName",
      label: "Last Name",
      field: "LastName",
      canSort: true,
      render: (row) => {
        return (
          <Tooltip title={row.LastName ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.LastName || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.FirstName?.isSelected,
      id: "FirstName",
      label: "First Name",
      field: "FirstName",
      canSort: true,
      render: (row) => {
        return (
          <Tooltip title={row.FirstName ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.FirstName || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.SSN?.isSelected,
      id: "SSN",
      label: "SSN",
      field: "SSN",
      render: (row) => {
        return (
          <Tooltip title={row.SSN ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.SSN || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.EIN?.isSelected,
      id: "EIN",
      label: "EIN",
      field: "EIN",
      render: (row) => {
        return (
          <Tooltip title={row.EIN ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.EIN || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.CoName?.isSelected,
      id: "CoName",
      label: "IC Company Name",
      field: "CoName",
      canSort: true,
      render: (row) => {
        return (
          <Tooltip title={row.CoName ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.CoName || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      isHidden: !state.dynamicColumns?.Settlement?.isSelected,
      id: "paydata.0.Settlement",
      label: "Settlement",
      field: "paydata.0.Settlement",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.Settlement).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          let validations = [
            { type: VALIDATIONS.MIN, value: 0 },
            { type: VALIDATIONS.LIMIT, value: 10.2 },
          ];
          const originalEntry = state.originalData.find(
            (item) => item.ICID === row.ICID
          );
          const isFieldModified =
            originalEntry?.paydata[0]?.Settlement !=
            row?.paydata[0]?.Settlement;

          const notes_tooltip =
            state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.Settlement_notes || "";

          const tooltip = isFieldModified
            ? !!notes_tooltip
              ? `Original Value : ${originalEntry.paydata[0]?.Settlement}, Changed Value : ${row.paydata[0]?.Settlement}, Notes : ${notes_tooltip}`
              : `Original Value : ${originalEntry.paydata[0]?.Settlement}, Changed Value : ${row.paydata[0]?.Settlement}`
            : row.paydata[0]?.Settlement;

          if (
            originalEntry.paydata[0]?.Settlement == row.paydata[0]?.Settlement
          ) {
            delete state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.["Settlement_notes"];
          }
          return hasFeatureAccess(PERMISSION.MANAGE_DCN) ? (
            <InlineFieldForm
              textFieldTooltip={`${tooltip}`}
              labelClassName={isFieldModified ? "color-error" : ""}
              startAdornment="$"
              name="Settlement"
              initialValue={Number(row.paydata[0].Settlement).toFixed(2)}
              inputType="number"
              validations={validations}
              onUpdate={(updatedData) => {
                setState((prevState) => {
                  const updatedEntries = prevState.entries.map((entry) => {
                    if (entry.ICID === row.ICID) {
                      entry.paydata[0].Settlement = Number(
                        updatedData.value
                      ).toFixed(2);
                    }
                    return entry;
                  });
                  if (isFieldModified) {
                    handleEdit(updatedEntries, row.ICID);
                  }
                  return {
                    ...prevState,
                    entries: updatedEntries,
                    isEditingInline:
                      Number(updatedData.value).toFixed(2) ==
                      originalEntry.paydata[0]?.Settlement
                        ? false
                        : true,
                    editingNotesName: "Settlement",
                    editingNotesLabel: "Settlement",
                    editingICID: row.ICID,
                  };
                });
              }}
            />
          ) : (
            <Tooltip placement="top-start" title={tooltip} variant="body2">
              <Typography>{row.paydata[0]?.Settlement}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.FuelReimbursement?.isSelected,
      id: "paydata.0.FuelReimbursement",
      label: "Fuel Reimbursement",
      field: "paydata.0.FuelReimbursement",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.FuelReimbursement).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          let validations = [
            { type: VALIDATIONS.MIN, value: 0 },
            { type: VALIDATIONS.LIMIT, value: 10.2 },
          ];
          const originalEntry =
            state.originalData.find((item) => item.ICID === row.ICID) || {};
          const isFieldModified =
            originalEntry?.paydata[0]?.FuelReimbursement !=
            row?.paydata[0]?.FuelReimbursement;
          const notes_tooltip =
            state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.FuelReimbursement_notes || "";

          const tooltip = isFieldModified
            ? !!notes_tooltip
              ? `Original Value : ${originalEntry.paydata[0]?.FuelReimbursement}, Changed Value : ${row.paydata[0]?.FuelReimbursement}, Notes : ${notes_tooltip}`
              : `Original Value : ${originalEntry.paydata[0]?.FuelReimbursement}, Changed Value : ${row.paydata[0]?.FuelReimbursement} `
            : row.paydata[0]?.FuelReimbursement;
          if (
            originalEntry.paydata[0]?.FuelReimbursement ==
            row.paydata[0]?.FuelReimbursement
          ) {
            delete state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.["FuelReimbursement_notes"];
          }
          return hasFeatureAccess(PERMISSION.MANAGE_DCN) ? (
            <InlineFieldForm
              textFieldTooltip={`${tooltip}`}
              labelClassName={isFieldModified ? "color-error" : ""}
              startAdornment="$"
              name="FuelReimbursement"
              initialValue={Number(row.paydata[0].FuelReimbursement).toFixed(2)}
              inputType="number"
              validations={validations}
              onUpdate={(updatedData) => {
                setState((prevState) => {
                  const updatedEntries = prevState.entries.map((entry) => {
                    if (entry.ICID === row.ICID) {
                      entry.paydata[0].FuelReimbursement = Number(
                        updatedData.value
                      ).toFixed(2);
                    }
                    return entry;
                  });
                  if (isFieldModified) {
                    handleEdit(updatedEntries, row.ICID);
                  }
                  return {
                    ...prevState,
                    entries: updatedEntries,
                    isEditingInline:
                      Number(updatedData.value).toFixed(2) ==
                      originalEntry.paydata[0]?.FuelReimbursement
                        ? false
                        : true,
                    editingNotesName: "FuelReimbursement",
                    editingNotesLabel: "Fuel Reimbursement",
                    editingICID: row.ICID,
                  };
                });
                // handleInlineEdit(updatedData, row.id);
              }}
            />
          ) : (
            <Tooltip placement="top-start" title={tooltip} variant="body2">
              <Typography>{row.paydata[0]?.FuelReimbursement}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.TollReimbursement?.isSelected,
      id: "paydata.0.TollReimbursement",
      label: "Toll Reimbursement",
      field: "paydata.0.TollReimbursement",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.TollReimbursement).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          let validations = [
            { type: VALIDATIONS.MIN, value: 0 },
            { type: VALIDATIONS.LIMIT, value: 10.2 },
          ];
          const originalEntry =
            state.originalData.find((item) => item.ICID === row.ICID) || {};
          const isFieldModified =
            originalEntry?.paydata[0]?.TollReimbursement !=
            row?.paydata[0]?.TollReimbursement;

          const notes_tooltip =
            state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.TollReimbursement_notes || "";

          const tooltip = isFieldModified
            ? !!notes_tooltip
              ? `Original Value : ${originalEntry.paydata[0]?.TollReimbursement}, Changed Value : ${row.paydata[0]?.TollReimbursement}, Notes : ${notes_tooltip}`
              : `Original Value : ${originalEntry.paydata[0]?.TollReimbursement}, Changed Value : ${row.paydata[0]?.TollReimbursement}`
            : row.paydata[0]?.TollReimbursement;
          if (
            originalEntry.paydata[0]?.TollReimbursement ==
            row.paydata[0]?.TollReimbursement
          ) {
            delete state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.["TollReimbursement_notes"];
          }
          return hasFeatureAccess(PERMISSION.MANAGE_DCN) ? (
            <InlineFieldForm
              textFieldTooltip={`${tooltip}`}
              labelClassName={isFieldModified ? "color-error" : ""}
              startAdornment="$"
              name="TollReimbursement"
              initialValue={Number(row.paydata[0].TollReimbursement).toFixed(2)}
              inputType="number"
              validations={validations}
              onUpdate={(updatedData) => {
                setState((prevState) => {
                  const updatedEntries = prevState.entries.map((entry) => {
                    if (entry.ICID === row.ICID) {
                      entry.paydata[0].TollReimbursement = Number(
                        updatedData.value
                      ).toFixed(2);
                    }
                    return entry;
                  });
                  if (isFieldModified) {
                    handleEdit(updatedEntries, row.ICID);
                  }
                  return {
                    ...prevState,
                    entries: updatedEntries,
                    isEditingInline:
                      Number(updatedData.value).toFixed(2) ==
                      originalEntry.paydata[0]?.TollReimbursement
                        ? false
                        : true,
                    editingNotesName: "TollReimbursement",
                    editingNotesLabel: "Toll Reimbursement",
                    editingICID: row.ICID,
                  };
                });
                // handleInlineEdit(updatedData, row.id);
              }}
            />
          ) : (
            <Tooltip placement="top-start" title={tooltip} variant="body2">
              <Typography>{row.paydata[0]?.TollReimbursement}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.MileageReimbursement?.isSelected,
      id: "paydata.0.MileageReimbursement",
      label: "Mileage Reimbursement",
      field: "paydata.0.MileageReimbursement",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.MileageReimbursement).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          let validations = [
            { type: VALIDATIONS.MIN, value: 0 },
            { type: VALIDATIONS.LIMIT, value: 10.2 },
          ];
          const originalEntry =
            state.originalData.find((item) => item.ICID === row.ICID) || {};
          const isFieldModified =
            originalEntry?.paydata[0]?.MileageReimbursement !=
            row?.paydata[0]?.MileageReimbursement;

          const notes_tooltip =
            state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.MileageReimbursement_notes || "";

          const tooltip = isFieldModified
            ? !!notes_tooltip
              ? `Original Value : ${originalEntry.paydata[0]?.MileageReimbursement}, Changed Value : ${row.paydata[0]?.MileageReimbursement}, Notes : ${notes_tooltip}`
              : `Original Value : ${originalEntry.paydata[0]?.MileageReimbursement}, Changed Value : ${row.paydata[0]?.MileageReimbursement}`
            : row.paydata[0]?.MileageReimbursement;
          if (
            originalEntry.paydata[0]?.MileageReimbursement ==
            row.paydata[0]?.MileageReimbursement
          ) {
            delete state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.["MileageReimbursement_notes"];
          }
          return hasFeatureAccess(PERMISSION.MANAGE_DCN) ? (
            <InlineFieldForm
              textFieldTooltip={`${tooltip}`}
              labelClassName={isFieldModified ? "color-error" : ""}
              startAdornment="$"
              name="MileageReimbursement"
              initialValue={Number(
                row.paydata[0]?.MileageReimbursement
              ).toFixed(2)}
              inputType="number"
              validations={validations}
              onUpdate={(updatedData) => {
                setState((prevState) => {
                  const updatedEntries = prevState.entries.map((entry) => {
                    if (entry.ICID === row.ICID) {
                      entry.paydata[0].MileageReimbursement = Number(
                        updatedData.value
                      ).toFixed(2);
                    }
                    return entry;
                  });
                  if (isFieldModified) {
                    handleEdit(updatedEntries, row.ICID);
                  }
                  return {
                    ...prevState,
                    entries: updatedEntries,
                    isEditingInline:
                      Number(updatedData.value).toFixed(2) ==
                      originalEntry.paydata[0]?.MileageReimbursement
                        ? false
                        : true,
                    editingNotesName: "MileageReimbursement",
                    editingNotesLabel: "Mileage Reimbursement",
                    editingICID: row.ICID,
                  };
                });
                // handleInlineEdit(updatedData, row.id);
              }}
            />
          ) : (
            <Tooltip placement="top-start" title={tooltip} variant="body2">
              <Typography>{row.paydata[0]?.MileageReimbursement}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.Reimbursement?.isSelected,
      id: "paydata.0.Reimbursement",
      label: "Reimbursement",
      field: "paydata.0.Reimbursement",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.Reimbursement).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          let validations = [
            { type: VALIDATIONS.MIN, value: 0 },
            { type: VALIDATIONS.LIMIT, value: 10.2 },
          ];
          const originalEntry =
            state.originalData.find((item) => item.ICID === row.ICID) || {};
          const isFieldModified =
            originalEntry?.paydata[0]?.Reimbursement !=
            row?.paydata[0]?.Reimbursement;

          const notes_tooltip =
            state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.Reimbursement_notes || "";

          const tooltip = isFieldModified
            ? !!notes_tooltip
              ? `Original Value : ${originalEntry.paydata[0]?.Reimbursement}, Changed Value : ${row.paydata[0]?.Reimbursement}, Notes : ${notes_tooltip}`
              : `Original Value : ${originalEntry.paydata[0]?.Reimbursement}, Changed Value : ${row.paydata[0]?.Reimbursement}`
            : row.paydata[0]?.Reimbursement;
          if (
            originalEntry.paydata[0]?.Reimbursement ==
            row.paydata[0]?.Reimbursement
          ) {
            delete state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.["Reimbursement_notes"];
          }
          return hasFeatureAccess(PERMISSION.MANAGE_DCN) ? (
            <InlineFieldForm
              textFieldTooltip={`${tooltip}`}
              labelClassName={isFieldModified ? "color-error" : ""}
              startAdornment="$"
              name="Reimbursement"
              initialValue={Number(row.paydata[0].Reimbursement).toFixed(2)}
              inputType="number"
              validations={validations}
              onUpdate={(updatedData) => {
                setState((prevState) => {
                  const updatedEntries = prevState.entries.map((entry) => {
                    if (entry.ICID === row.ICID) {
                      entry.paydata[0].Reimbursement = Number(
                        updatedData.value
                      ).toFixed(2);
                    }
                    return entry;
                  });
                  if (isFieldModified) {
                    handleEdit(updatedEntries, row.ICID);
                  }
                  return {
                    ...prevState,
                    entries: updatedEntries,
                    isEditingInline:
                      Number(updatedData.value).toFixed(2) ==
                      originalEntry.paydata[0]?.Reimbursement
                        ? false
                        : true,
                    editingNotesName: "Reimbursement",
                    editingNotesLabel: "Reimbursement",
                    editingICID: row.ICID,
                  };
                });
                // handleInlineEdit(updatedData, row.id);
              }}
            />
          ) : (
            <Tooltip placement="top-start" title={tooltip} variant="body2">
              <Typography>{row.paydata[0]?.Reimbursement}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.MiscDeduction?.isSelected,
      id: "paydata.0.MiscDeduction",
      label: "Misc Deduction",
      field: "paydata.0.MiscDeduction",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.MiscDeduction).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          let validations = [
            { type: VALIDATIONS.MIN, value: 0 },
            { type: VALIDATIONS.LIMIT, value: 10.2 },
          ];
          const originalEntry =
            state.originalData.find((item) => item.ICID === row.ICID) || {};
          const isFieldModified =
            originalEntry?.paydata[0]?.MiscDeduction !=
            row?.paydata[0]?.MiscDeduction;

          const notes_tooltip =
            state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.MiscDeduction_notes || "";

          const tooltip = isFieldModified
            ? !!notes_tooltip
              ? `Original Value : ${originalEntry.paydata[0]?.MiscDeduction}, Changed Value : ${row.paydata[0]?.MiscDeduction}, Notes : ${notes_tooltip}`
              : `Original Value : ${originalEntry.paydata[0]?.MiscDeduction}, Changed Value : ${row.paydata[0]?.MiscDeduction} `
            : row.paydata[0]?.MiscDeduction;
          if (
            originalEntry.paydata[0]?.MiscDeduction_notes ==
            row.paydata[0]?.MiscDeduction_notes
          ) {
            delete state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.["MiscDeduction_notes"];
          }
          return hasFeatureAccess(PERMISSION.MANAGE_DCN) ? (
            <InlineFieldForm
              textFieldTooltip={`${tooltip}`}
              labelClassName={isFieldModified ? "color-error" : ""}
              startAdornment="$"
              name="MiscDeduction"
              initialValue={Number(row.paydata[0].MiscDeduction).toFixed(2)}
              inputType="number"
              validations={validations}
              onUpdate={(updatedData) => {
                setState((prevState) => {
                  const updatedEntries = prevState.entries.map((entry) => {
                    if (entry.ICID === row.ICID) {
                      entry.paydata[0].MiscDeduction = Number(
                        updatedData.value
                      ).toFixed(2);
                    }
                    return entry;
                  });
                  if (isFieldModified) {
                    handleEdit(updatedEntries, row.ICID);
                  }
                  return {
                    ...prevState,
                    entries: updatedEntries,
                    isEditingInline:
                      Number(updatedData.value).toFixed(2) ==
                      originalEntry.paydata[0]?.MiscDeduction
                        ? false
                        : true,
                    editingNotesName: "MiscDeduction",
                    editingNotesLabel: "Misc Deduction",
                    editingICID: row.ICID,
                  };
                });
                // handleInlineEdit(updatedData, row.id);
              }}
            />
          ) : (
            <Tooltip placement="top-start" title={tooltip} variant="body2">
              <Typography>{row.paydata[0]?.MiscDeduction}</Typography>
            </Tooltip>
          );
        }
      },
    },

    {
      isHidden: !state.dynamicColumns?.Misc2Deduction?.isSelected,
      id: "paydata.0.Misc2Deduction",
      label: "Misc2 Deduction",
      field: "paydata.0.Misc2Deduction",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.Misc2Deduction).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          let validations = [
            { type: VALIDATIONS.MIN, value: 0 },
            { type: VALIDATIONS.LIMIT, value: 10.2 },
          ];
          const originalEntry =
            state.originalData.find((item) => item.ICID === row.ICID) || {};
          const isFieldModified =
            originalEntry?.paydata[0]?.Misc2Deduction !=
            row?.paydata[0]?.Misc2Deduction;

          const notes_tooltip =
            state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.Misc2Deduction_notes || "";

          const tooltip = isFieldModified
            ? !!notes_tooltip
              ? `Original Value : ${originalEntry.paydata[0]?.Misc2Deduction}, Changed Value : ${row.paydata[0]?.Misc2Deduction}, Notes : ${notes_tooltip}`
              : `Original Value : ${originalEntry.paydata[0]?.Misc2Deduction}, Changed Value : ${row.paydata[0]?.Misc2Deduction}`
            : row.paydata[0]?.Misc2Deduction;
          if (
            originalEntry.paydata[0]?.Misc2Deduction_notes ==
            row.paydata[0]?.Misc2Deduction_notes
          ) {
            delete state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.["Misc2Deduction_notes"];
          }
          return hasFeatureAccess(PERMISSION.MANAGE_DCN) ? (
            <InlineFieldForm
              textFieldTooltip={`${tooltip}`}
              labelClassName={isFieldModified ? "color-error" : ""}
              startAdornment="$"
              name="Misc2Deduction"
              initialValue={Number(row.paydata[0].Misc2Deduction).toFixed(2)}
              inputType="number"
              validations={validations}
              onUpdate={(updatedData) => {
                setState((prevState) => {
                  const updatedEntries = prevState.entries.map((entry) => {
                    if (entry.ICID === row.ICID) {
                      entry.paydata[0].Misc2Deduction = Number(
                        updatedData.value
                      ).toFixed(2);
                    }
                    return entry;
                  });
                  if (isFieldModified) {
                    handleEdit(updatedEntries, row.ICID);
                  }
                  return {
                    ...prevState,
                    entries: updatedEntries,
                    isEditingInline:
                      Number(updatedData.value).toFixed(2) ==
                      originalEntry.paydata[0]?.Misc2Deduction
                        ? false
                        : true,
                    editingNotesName: "Misc2Deduction",
                    editingNotesLabel: "Misc2 Deduction",
                    editingICID: row.ICID,
                  };
                });
                // handleInlineEdit(updatedData, row.id);
              }}
            />
          ) : (
            <Tooltip placement="top-start" title={tooltip} variant="body2">
              <Typography>{row.paydata[0]?.Misc2Deduction}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.FuelAdvanceDeduction?.isSelected,
      id: "paydata.0.FuelAdvanceDeduction",
      label: "Fuel Advance Deduction",
      field: "paydata.0.FuelAdvanceDeduction",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.FuelAdvanceDeduction).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          let validations = [
            { type: VALIDATIONS.MIN, value: 0 },
            { type: VALIDATIONS.LIMIT, value: 10.2 },
          ];
          const originalEntry =
            state.originalData.find((item) => item.ICID === row.ICID) || {};
          const isFieldModified =
            originalEntry?.paydata[0]?.FuelAdvanceDeduction !=
            row?.paydata[0]?.FuelAdvanceDeduction;

          const notes_tooltip =
            state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.FuelAdvanceDeduction_notes || "";

          const tooltip = isFieldModified
            ? !!notes_tooltip
              ? `Original Value : ${originalEntry.paydata[0]?.FuelAdvanceDeduction}, Changed Value : ${row.paydata[0]?.FuelAdvanceDeduction}, Notes : ${notes_tooltip}`
              : `Original Value : ${originalEntry.paydata[0]?.FuelAdvanceDeduction}, Changed Value : ${row.paydata[0]?.FuelAdvanceDeduction} `
            : row.paydata[0]?.FuelAdvanceDeduction;
          if (
            originalEntry.paydata[0]?.FuelAdvanceDeduction ==
            row.paydata[0]?.FuelAdvanceDeduction
          ) {
            delete state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.["FuelAdvanceDeduction_notes"];
          }
          return hasFeatureAccess(PERMISSION.MANAGE_DCN) ? (
            <InlineFieldForm
              textFieldTooltip={`${tooltip}`}
              labelClassName={isFieldModified ? "color-error" : ""}
              startAdornment="$"
              name="FuelAdvanceDeduction"
              initialValue={Number(row.paydata[0].FuelAdvanceDeduction).toFixed(
                2
              )}
              inputType="number"
              validations={validations}
              onUpdate={(updatedData) => {
                setState((prevState) => {
                  const updatedEntries = prevState.entries.map((entry) => {
                    if (entry.ICID === row.ICID) {
                      entry.paydata[0].FuelAdvanceDeduction = Number(
                        updatedData.value
                      ).toFixed(2);
                    }
                    return entry;
                  });
                  if (isFieldModified) {
                    handleEdit(updatedEntries, row.ICID);
                  }
                  return {
                    ...prevState,
                    entries: updatedEntries,
                    isEditingInline:
                      Number(updatedData.value).toFixed(2) ==
                      originalEntry.paydata[0]?.FuelAdvanceDeduction
                        ? false
                        : true,
                    editingNotesName: "FuelAdvanceDeduction",
                    editingNotesLabel: "Fuel Advance Deduction",
                    editingICID: row.ICID,
                  };
                });
                // handleInlineEdit(updatedData, row.id);
              }}
            />
          ) : (
            <Tooltip placement="top-start" title={tooltip} variant="body2">
              <Typography>{row.paydata[0]?.FuelAdvanceDeduction}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.FuelAdvance2?.isSelected,
      id: "paydata.0.FuelAdvance2",
      label: "Fuel Advance2",
      field: "paydata.0.FuelAdvance2",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.FuelAdvance2).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          let validations = [
            { type: VALIDATIONS.MIN, value: 0 },
            { type: VALIDATIONS.LIMIT, value: 10.2 },
          ];
          const originalEntry =
            state.originalData.find((item) => item.ICID === row.ICID) || {};
          const isFieldModified =
            originalEntry?.paydata[0]?.FuelAdvance2 !=
            row?.paydata[0]?.FuelAdvance2;

          const notes_tooltip =
            state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.FuelAdvance2_notes || "";

          const tooltip = isFieldModified
            ? !!notes_tooltip
              ? `Original Value : ${originalEntry.paydata[0]?.FuelAdvance2}, Changed Value : ${row.paydata[0]?.FuelAdvance2}, Notes : ${notes_tooltip}`
              : `Original Value : ${originalEntry.paydata[0]?.FuelAdvance2}, Changed Value : ${row.paydata[0]?.FuelAdvance2} `
            : row.paydata[0]?.FuelAdvance2;
          if (
            originalEntry.paydata[0]?.FuelAdvance2 ==
            row.paydata[0]?.FuelAdvance2
          ) {
            delete state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.["FuelAdvance2_notes"];
          }
          return hasFeatureAccess(PERMISSION.MANAGE_DCN) ? (
            <InlineFieldForm
              textFieldTooltip={`${tooltip}`}
              labelClassName={isFieldModified ? "color-error" : ""}
              name="FuelAdvance2"
              startAdornment="$"
              initialValue={Number(row.paydata[0].FuelAdvance2).toFixed(2)}
              inputType="number"
              validations={validations}
              onUpdate={(updatedData) => {
                setState((prevState) => {
                  const updatedEntries = prevState.entries.map((entry) => {
                    if (entry.ICID === row.ICID) {
                      entry.paydata[0].FuelAdvance2 = Number(
                        updatedData.value
                      ).toFixed(2);
                    }
                    return entry;
                  });
                  if (isFieldModified) {
                    handleEdit(updatedEntries, row.ICID);
                  }
                  return {
                    ...prevState,
                    entries: updatedEntries,
                    isEditingInline:
                      Number(updatedData.value).toFixed(2) ==
                      originalEntry.paydata[0]?.FuelAdvance2
                        ? false
                        : true,
                    editingNotesName: "FuelAdvance2",
                    editingNotesLabel: "Fuel Advance2",
                    editingICID: row.ICID,
                  };
                });
                // handleInlineEdit(updatedData, row.id);
              }}
            />
          ) : (
            <Tooltip placement="top-start" title={tooltip} variant="body2">
              <Typography>{row.paydata[0]?.FuelAdvance2}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.CashAdvanceDeduction?.isSelected,
      id: "paydata.0.CashAdvanceDeduction",
      label: "Cash Advance Deduction",
      field: "paydata.0.CashAdvanceDeduction",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.CashAdvanceDeduction).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          let validations = [
            { type: VALIDATIONS.MIN, value: 0 },
            { type: VALIDATIONS.LIMIT, value: 10.2 },
          ];
          const originalEntry =
            state.originalData.find((item) => item.ICID === row.ICID) || {};
          const isFieldModified =
            originalEntry?.paydata[0]?.CashAdvanceDeduction !=
            row?.paydata[0]?.CashAdvanceDeduction;

          const notes_tooltip =
            state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.CashAdvanceDeduction_notes || "";

          const tooltip = isFieldModified
            ? !!notes_tooltip
              ? `Original Value : ${originalEntry.paydata[0]?.CashAdvanceDeduction}, Changed Value : ${row.paydata[0]?.CashAdvanceDeduction}, Notes : ${notes_tooltip}`
              : `Original Value : ${originalEntry.paydata[0]?.CashAdvanceDeduction}, Changed Value : ${row.paydata[0]?.CashAdvanceDeduction} `
            : row.paydata[0]?.CashAdvanceDeduction;
          if (
            originalEntry.paydata[0]?.CashAdvanceDeduction ==
            row.paydata[0]?.CashAdvanceDeduction
          ) {
            delete state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.["CashAdvanceDeduction_notes"];
          }
          return hasFeatureAccess(PERMISSION.MANAGE_DCN) ? (
            <InlineFieldForm
              textFieldTooltip={`${tooltip}`}
              labelClassName={isFieldModified ? "color-error" : ""}
              startAdornment="$"
              name="CashAdvanceDeduction"
              initialValue={Number(row.paydata[0].CashAdvanceDeduction).toFixed(
                2
              )}
              inputType="number"
              validations={validations}
              onUpdate={(updatedData) => {
                setState((prevState) => {
                  const updatedEntries = prevState.entries.map((entry) => {
                    if (entry.ICID === row.ICID) {
                      entry.paydata[0].CashAdvanceDeduction = Number(
                        updatedData.value
                      ).toFixed(2);
                    }
                    return entry;
                  });
                  if (isFieldModified) {
                    handleEdit(updatedEntries, row.ICID);
                  }
                  return {
                    ...prevState,
                    entries: updatedEntries,
                    isEditingInline:
                      Number(updatedData.value).toFixed(2) ==
                      originalEntry.paydata[0]?.CashAdvanceDeduction
                        ? false
                        : true,
                    editingNotesName: "CashAdvanceDeduction",
                    editingNotesLabel: "Cash Advance Deduction",
                    editingICID: row.ICID,
                  };
                });
                // handleInlineEdit(updatedData, row.id);
              }}
            />
          ) : (
            <Tooltip placement="top-start" title={tooltip} variant="body2">
              <Typography>{row.paydata[0]?.CashAdvanceDeduction}</Typography>
            </Tooltip>
          );
        }
      },
    },

    {
      isHidden: !state.dynamicColumns?.CashAdvance2Deduction?.isSelected,
      id: "paydata.0.CashAdvance2Deduction",
      label: "Cash Advance2 Deduction",
      field: "paydata.0.CashAdvance2Deduction",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.CashAdvance2Deduction).toFixed(
            2
          );
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          let validations = [
            { type: VALIDATIONS.MIN, value: 0 },
            { type: VALIDATIONS.LIMIT, value: 10.2 },
          ];
          const originalEntry =
            state.originalData.find((item) => item.ICID === row.ICID) || {};
          const isFieldModified =
            originalEntry?.paydata[0]?.CashAdvance2Deduction !=
            row?.paydata[0]?.CashAdvance2Deduction;

          const notes_tooltip =
            state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.CashAdvance2Deduction_notes || "";

          const tooltip = isFieldModified
            ? !!notes_tooltip
              ? `Original Value : ${originalEntry.paydata[0]?.CashAdvance2Deduction}, Changed Value : ${row.paydata[0]?.CashAdvance2Deduction}, Notes : ${notes_tooltip}`
              : `Original Value : ${originalEntry.paydata[0]?.CashAdvance2Deduction}, Changed Value : ${row.paydata[0]?.CashAdvance2Deduction} `
            : row.paydata[0]?.CashAdvance2Deduction;
          if (
            originalEntry.paydata[0]?.CashAdvance2Deduction ==
            row.paydata[0]?.CashAdvance2Deduction
          ) {
            delete state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.["CashAdvance2Deduction_notes"];
          }
          return hasFeatureAccess(PERMISSION.MANAGE_DCN) ? (
            <InlineFieldForm
              textFieldTooltip={`${tooltip}`}
              labelClassName={isFieldModified ? "color-error" : ""}
              startAdornment="$"
              name="CashAdvance2Deduction"
              initialValue={Number(
                row.paydata[0].CashAdvance2Deduction
              ).toFixed(2)}
              inputType="number"
              validations={validations}
              onUpdate={(updatedData) => {
                setState((prevState) => {
                  const updatedEntries = prevState.entries.map((entry) => {
                    if (entry.ICID === row.ICID) {
                      entry.paydata[0].CashAdvance2Deduction = Number(
                        updatedData.value
                      ).toFixed(2);
                    }
                    return entry;
                  });
                  if (isFieldModified) {
                    handleEdit(updatedEntries, row.ICID);
                  }
                  return {
                    ...prevState,
                    entries: updatedEntries,
                    isEditingInline:
                      Number(updatedData.value).toFixed(2) ==
                      originalEntry.paydata[0]?.CashAdvance2Deduction
                        ? false
                        : true,
                    editingNotesName: "CashAdvance2Deduction",
                    editingNotesLabel: "Cash Advance2 Deduction",
                    editingICID: row.ICID,
                  };
                });
                // handleInlineEdit(updatedData, row.id);
              }}
            />
          ) : (
            <Tooltip placement="top-start" title={tooltip} variant="body2">
              <Typography>{row.paydata[0]?.CashAdvance2Deduction}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.ClaimDeduction?.isSelected,
      id: "paydata.0.ClaimDeduction",
      label: "Claim Deduction",
      field: "paydata.0.ClaimDeduction",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.ClaimDeduction).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          let validations = [
            { type: VALIDATIONS.MIN, value: 0 },
            { type: VALIDATIONS.LIMIT, value: 10.2 },
          ];
          const originalEntry =
            state.originalData.find((item) => item.ICID === row.ICID) || {};
          const isFieldModified =
            originalEntry?.paydata[0]?.ClaimDeduction !=
            row?.paydata[0]?.ClaimDeduction;

          const notes_tooltip =
            state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.ClaimDeduction_notes || "";

          const tooltip = isFieldModified
            ? !!notes_tooltip
              ? `Original Value : ${originalEntry.paydata[0]?.ClaimDeduction}, Changed Value : ${row.paydata[0]?.ClaimDeduction}, Notes : ${notes_tooltip}`
              : `Original Value : ${originalEntry.paydata[0]?.ClaimDeduction}, Changed Value : ${row.paydata[0]?.ClaimDeduction}`
            : row.paydata[0]?.ClaimDeduction;
          if (
            originalEntry.paydata[0]?.ClaimDeduction ==
            row.paydata[0]?.ClaimDeduction
          ) {
            delete state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.["ClaimDeduction_notes"];
          }
          return hasFeatureAccess(PERMISSION.MANAGE_DCN) ? (
            <InlineFieldForm
              textFieldTooltip={`${tooltip}`}
              labelClassName={isFieldModified ? "color-error" : ""}
              startAdornment="$"
              name="ClaimDeduction"
              initialValue={Number(row.paydata[0].ClaimDeduction).toFixed(2)}
              inputType="number"
              validations={validations}
              onUpdate={(updatedData) => {
                setState((prevState) => {
                  const updatedEntries = prevState.entries.map((entry) => {
                    if (entry.ICID === row.ICID) {
                      entry.paydata[0].ClaimDeduction = Number(
                        updatedData.value
                      ).toFixed(2);
                    }
                    return entry;
                  });
                  if (isFieldModified) {
                    handleEdit(updatedEntries, row.ICID);
                  }
                  return {
                    ...prevState,
                    entries: updatedEntries,
                    isEditingInline:
                      Number(updatedData.value).toFixed(2) ==
                      originalEntry.paydata[0]?.ClaimDeduction
                        ? false
                        : true,
                    editingNotesName: "ClaimDeduction",
                    editingNotesLabel: "Claim Deduction",
                    editingICID: row.ICID,
                  };
                });
                // handleInlineEdit(updatedData, row.id);
              }}
            />
          ) : (
            <Tooltip placement="top-start" title={tooltip} variant="body2">
              <Typography>{row.paydata[0]?.ClaimDeduction}</Typography>
            </Tooltip>
          );
        }
      },
    },

    {
      isHidden: !state.dynamicColumns?.Claim2Deduction?.isSelected,
      id: "paydata.0.Claim2Deduction",
      label: "Claim2 Deduction",
      field: "paydata.0.Claim2Deduction",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.Claim2Deduction).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          let validations = [
            { type: VALIDATIONS.MIN, value: 0 },
            { type: VALIDATIONS.LIMIT, value: 10.2 },
          ];
          const originalEntry =
            state.originalData.find((item) => item.ICID === row.ICID) || {};
          const isFieldModified =
            originalEntry?.paydata[0]?.Claim2Deduction !=
            row?.paydata[0]?.Claim2Deduction;

          const notes_tooltip =
            state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.Claim2Deduction_notes || "";

          const tooltip = isFieldModified
            ? !!notes_tooltip
              ? `Original Value : ${originalEntry.paydata[0]?.Claim2Deduction}, Changed Value : ${row.paydata[0]?.Claim2Deduction}, Notes : ${notes_tooltip}`
              : `Original Value : ${originalEntry.paydata[0]?.Claim2Deduction}, Changed Value : ${row.paydata[0]?.Claim2Deduction} `
            : row.paydata[0]?.Claim2Deduction;
          if (
            originalEntry.paydata[0]?.Claim2Deduction ==
            row.paydata[0]?.Claim2Deduction
          ) {
            delete state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.["Claim2Deduction_notes"];
          }
          return hasFeatureAccess(PERMISSION.MANAGE_DCN) ? (
            <InlineFieldForm
              textFieldTooltip={`${tooltip}`}
              labelClassName={isFieldModified ? "color-error" : ""}
              startAdornment="$"
              name="Claim2Deduction"
              initialValue={Number(row.paydata[0].Claim2Deduction).toFixed(2)}
              inputType="number"
              validations={validations}
              onUpdate={(updatedData) => {
                setState((prevState) => {
                  const updatedEntries = prevState.entries.map((entry) => {
                    if (entry.ICID === row.ICID) {
                      entry.paydata[0].Claim2Deduction = Number(
                        updatedData.value
                      ).toFixed(2);
                    }
                    return entry;
                  });
                  if (isFieldModified) {
                    handleEdit(updatedEntries, row.ICID);
                  }
                  return {
                    ...prevState,
                    entries: updatedEntries,
                    isEditingInline:
                      Number(updatedData.value).toFixed(2) ==
                      originalEntry.paydata[0]?.Claim2Deduction
                        ? false
                        : true,
                    editingNotesName: "Claim2Deduction",
                    editingNotesLabel: "Claim2 Deduction",
                    editingICID: row.ICID,
                  };
                });
                // handleInlineEdit(updatedData, row.id);
              }}
            />
          ) : (
            <Tooltip placement="top-start" title={tooltip} variant="body2">
              <Typography>{row.paydata[0]?.Claim2Deduction}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.Claim3Deduction?.isSelected,
      id: "paydata.0.Claim3Deduction",
      label: "Claim3 Deduction",
      field: "paydata.0.Claim3Deduction",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.Claim3Deduction).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          let validations = [
            { type: VALIDATIONS.MIN, value: 0 },
            { type: VALIDATIONS.LIMIT, value: 10.2 },
          ];
          const originalEntry =
            state.originalData.find((item) => item.ICID === row.ICID) || {};
          const isFieldModified =
            originalEntry?.paydata[0]?.Claim3Deduction !=
            row?.paydata[0]?.Claim3Deduction;

          const notes_tooltip =
            state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.Claim3Deduction_notes || "";

          const tooltip = isFieldModified
            ? !!notes_tooltip
              ? `Original Value : ${originalEntry.paydata[0]?.Claim3Deduction}, Changed Value : ${row.paydata[0]?.Claim3Deduction}, Notes : ${notes_tooltip}`
              : `Original Value : ${originalEntry.paydata[0]?.Claim3Deduction}, Changed Value : ${row.paydata[0]?.Claim3Deduction}`
            : row.paydata[0]?.Claim3Deduction;
          if (
            originalEntry.paydata[0]?.Claim3Deduction ==
            row.paydata[0]?.Claim3Deduction
          ) {
            delete state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.["Claim3Deduction_notes"];
          }
          return hasFeatureAccess(PERMISSION.MANAGE_DCN) ? (
            <InlineFieldForm
              textFieldTooltip={`${tooltip}`}
              labelClassName={isFieldModified ? "color-error" : ""}
              startAdornment="$"
              name="Claim3Deduction"
              initialValue={Number(row.paydata[0].Claim3Deduction).toFixed(2)}
              inputType="number"
              validations={validations}
              onUpdate={(updatedData) => {
                setState((prevState) => {
                  const updatedEntries = prevState.entries.map((entry) => {
                    if (entry.ICID === row.ICID) {
                      entry.paydata[0].Claim3Deduction = Number(
                        updatedData.value
                      ).toFixed(2);
                    }
                    return entry;
                  });
                  if (isFieldModified) {
                    handleEdit(updatedEntries, row.ICID);
                  }
                  return {
                    ...prevState,
                    entries: updatedEntries,
                    isEditingInline:
                      Number(updatedData.value).toFixed(2) ==
                      originalEntry.paydata[0]?.Claim3Deduction
                        ? false
                        : true,
                    editingNotesName: "Claim3Deduction",
                    editingNotesLabel: "Claim3 Deduction",
                    editingICID: row.ICID,
                  };
                });
                // handleInlineEdit(updatedData, row.id);
              }}
            />
          ) : (
            <Tooltip placement="top-start" title={tooltip} variant="body2">
              <Typography>{row.paydata[0]?.Claim3Deduction}</Typography>
            </Tooltip>
          );
        }
      },
    },

    {
      isHidden: !state.dynamicColumns?.Rental1Deduction?.isSelected,
      id: "paydata.0.Rental1Deduction",
      label: "Rental1 Deduction",
      field: "paydata.0.Rental1Deduction",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.Rental1Deduction).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          let validations = [
            { type: VALIDATIONS.MIN, value: 0 },
            { type: VALIDATIONS.LIMIT, value: 10.2 },
          ];
          const originalEntry =
            state.originalData.find((item) => item.ICID === row.ICID) || {};
          const isFieldModified =
            originalEntry?.paydata[0]?.Rental1Deduction !=
            row?.paydata[0]?.Rental1Deduction;

          const notes_tooltip =
            state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.Rental1Deduction_notes || "";

          const tooltip = isFieldModified
            ? !!notes_tooltip
              ? `Original Value : ${originalEntry.paydata[0]?.Rental1Deduction}, Changed Value : ${row.paydata[0]?.Rental1Deduction}, Notes : ${notes_tooltip}`
              : `Original Value : ${originalEntry.paydata[0]?.Rental1Deduction}, Changed Value : ${row.paydata[0]?.Rental1Deduction} `
            : row.paydata[0]?.Rental1Deduction;
          if (
            originalEntry.paydata[0]?.Rental1Deduction ==
            row.paydata[0]?.Rental1Deduction
          ) {
            delete state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.["Rental1Deduction_notes"];
          }
          return hasFeatureAccess(PERMISSION.MANAGE_DCN) ? (
            <InlineFieldForm
              textFieldTooltip={`${tooltip}`}
              labelClassName={isFieldModified ? "color-error" : ""}
              startAdornment="$"
              name="Rental1Deduction"
              initialValue={Number(row.paydata[0].Rental1Deduction).toFixed(2)}
              inputType="number"
              validations={validations}
              onUpdate={(updatedData) => {
                setState((prevState) => {
                  const updatedEntries = prevState.entries.map((entry) => {
                    if (entry.ICID === row.ICID) {
                      entry.paydata[0].Rental1Deduction = Number(
                        updatedData.value
                      ).toFixed(2);
                    }
                    return entry;
                  });
                  if (isFieldModified) {
                    handleEdit(updatedEntries, row.ICID);
                  }
                  return {
                    ...prevState,
                    entries: updatedEntries,
                    isEditingInline:
                      Number(updatedData.value).toFixed(2) ==
                      originalEntry.paydata[0]?.Rental1Deduction
                        ? false
                        : true,
                    editingNotesName: "Rental1Deduction",
                    editingNotesLabel: "Rental1 Deduction",
                    editingICID: row.ICID,
                  };
                });
                // handleInlineEdit(updatedData, row.id);
              }}
            />
          ) : (
            <Tooltip placement="top-start" title={tooltip} variant="body2">
              <Typography>{row.paydata[0]?.Rental1Deduction}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.Rental2Deduction?.isSelected,
      id: "paydata.0.Rental2Deduction",
      label: "Rental2 Deduction",
      field: "paydata.0.Rental2Deduction",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.Rental2Deduction).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          let validations = [
            { type: VALIDATIONS.MIN, value: 0 },
            { type: VALIDATIONS.LIMIT, value: 10.2 },
          ];
          const originalEntry =
            state.originalData.find((item) => item.ICID === row.ICID) || {};
          const isFieldModified =
            originalEntry?.paydata[0]?.Rental2Deduction !=
            row?.paydata[0]?.Rental2Deduction;

          const notes_tooltip =
            state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.Rental2Deduction_notes || "";

          const tooltip = isFieldModified
            ? !!notes_tooltip
              ? `Original Value : ${originalEntry.paydata[0]?.Rental2Deduction}, Changed Value : ${row.paydata[0]?.Rental2Deduction}, Notes : ${notes_tooltip}`
              : `Original Value : ${originalEntry.paydata[0]?.Rental2Deduction}, Changed Value : ${row.paydata[0]?.Rental2Deduction}`
            : row.paydata[0]?.Rental2Deduction;
          if (
            originalEntry.paydata[0]?.Rental2Deduction ==
            row.paydata[0]?.Rental2Deduction
          ) {
            delete state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.["Rental2Deduction_notes"];
          }
          return hasFeatureAccess(PERMISSION.MANAGE_DCN) ? (
            <InlineFieldForm
              textFieldTooltip={`${tooltip}`}
              labelClassName={isFieldModified ? "color-error" : ""}
              startAdornment="$"
              name="Rental2Deduction"
              initialValue={Number(row.paydata[0].Rental2Deduction).toFixed(2)}
              inputType="number"
              validations={validations}
              onUpdate={(updatedData) => {
                setState((prevState) => {
                  const updatedEntries = prevState.entries.map((entry) => {
                    if (entry.ICID === row.ICID) {
                      entry.paydata[0].Rental2Deduction = Number(
                        updatedData.value
                      ).toFixed(2);
                    }
                    return entry;
                  });
                  if (isFieldModified) {
                    handleEdit(updatedEntries, row.ICID);
                  }
                  return {
                    ...prevState,
                    entries: updatedEntries,
                    isEditingInline:
                      Number(updatedData.value).toFixed(2) ==
                      originalEntry.paydata[0]?.Rental2Deduction
                        ? false
                        : true,
                    editingNotesName: "Rental2Deduction",
                    editingNotesLabel: "Rental2 Deduction",
                    editingICID: row.ICID,
                  };
                });
                // handleInlineEdit(updatedData, row.id);
              }}
            />
          ) : (
            <Tooltip placement="top-start" title={tooltip} variant="body2">
              <Typography>{row.paydata[0]?.Rental2Deduction}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.Rental3Deduction?.isSelected,
      id: "paydata.0.Rental3Deduction",
      label: "Rental3 Deduction",
      field: "paydata.0.Rental3Deduction",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.Rental3Deduction).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          let validations = [
            { type: VALIDATIONS.MIN, value: 0 },
            { type: VALIDATIONS.LIMIT, value: 10.2 },
          ];
          const originalEntry =
            state.originalData.find((item) => item.ICID === row.ICID) || {};
          const isFieldModified =
            originalEntry?.paydata[0]?.Rental3Deduction !=
            row?.paydata[0]?.Rental3Deduction;

          const notes_tooltip =
            state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.Rental3Deduction_notes || "";

          const tooltip = isFieldModified
            ? !!notes_tooltip
              ? `Original Value : ${originalEntry.paydata[0]?.Rental3Deduction}, Changed Value : ${row.paydata[0]?.Rental3Deduction}, Notes : ${notes_tooltip}`
              : `Original Value : ${originalEntry.paydata[0]?.Rental3Deduction}, Changed Value : ${row.paydata[0]?.Rental3Deduction}`
            : row.paydata[0]?.Rental3Deduction;
          if (
            originalEntry.paydata[0]?.Rental3Deduction ==
            row.paydata[0]?.Rental3Deduction
          ) {
            delete state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.["Rental3Deduction_notes"];
          }
          return hasFeatureAccess(PERMISSION.MANAGE_DCN) ? (
            <InlineFieldForm
              textFieldTooltip={`${tooltip}`}
              labelClassName={isFieldModified ? "color-error" : ""}
              startAdornment="$"
              name="Rental3Deduction"
              initialValue={Number(row.paydata[0].Rental3Deduction).toFixed(2)}
              inputType="number"
              validations={validations}
              onUpdate={(updatedData) => {
                setState((prevState) => {
                  const updatedEntries = prevState.entries.map((entry) => {
                    if (entry.ICID === row.ICID) {
                      entry.paydata[0].Rental3Deduction = Number(
                        updatedData.value
                      ).toFixed(2);
                    }
                    return entry;
                  });
                  if (isFieldModified) {
                    handleEdit(updatedEntries, row.ICID);
                  }
                  return {
                    ...prevState,
                    entries: updatedEntries,
                    isEditingInline:
                      Number(updatedData.value).toFixed(2) ==
                      originalEntry.paydata[0]?.Rental3Deduction
                        ? false
                        : true,
                    editingNotesName: "Rental3Deduction",
                    editingNotesLabel: "Rental3 Deduction",
                    editingICID: row.ICID,
                  };
                });
                // handleInlineEdit(updatedData, row.id);
              }}
            />
          ) : (
            <Tooltip placement="top-start" title={tooltip} variant="body2">
              <Typography>{row.paydata[0]?.Rental3Deduction}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.Rental4Deduction?.isSelected,
      id: "paydata.0.Rental4Deduction",
      label: "Rental4 Deduction",
      field: "paydata.0.Rental4Deduction",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.Rental4Deduction).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          let validations = [
            { type: VALIDATIONS.MIN, value: 0 },
            { type: VALIDATIONS.LIMIT, value: 10.2 },
          ];
          const originalEntry =
            state.originalData.find((item) => item.ICID === row.ICID) || {};
          const isFieldModified =
            originalEntry?.paydata[0]?.Rental4Deduction !=
            row?.paydata[0]?.Rental4Deduction;

          const notes_tooltip =
            state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.Rental4Deduction_notes || "";

          const tooltip = isFieldModified
            ? !!notes_tooltip
              ? `Original Value : ${originalEntry.paydata[0]?.Rental4Deduction}, Changed Value : ${row.paydata[0]?.Rental4Deduction}, Notes : ${notes_tooltip}`
              : `Original Value : ${originalEntry.paydata[0]?.Rental4Deduction}, Changed Value : ${row.paydata[0]?.Rental4Deduction}`
            : row.paydata[0]?.Rental4Deduction;
          if (
            originalEntry.paydata[0]?.Rental4Deduction ==
            row.paydata[0]?.Rental4Deduction
          ) {
            delete state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.["Rental4Deduction_notes"];
          }
          return hasFeatureAccess(PERMISSION.MANAGE_DCN) ? (
            <InlineFieldForm
              textFieldTooltip={`${tooltip}`}
              labelClassName={isFieldModified ? "color-error" : ""}
              startAdornment="$"
              name="Rental4Deduction"
              initialValue={Number(row.paydata[0].Rental4Deduction).toFixed(2)}
              inputType="number"
              validations={validations}
              onUpdate={(updatedData) => {
                setState((prevState) => {
                  const updatedEntries = prevState.entries.map((entry) => {
                    if (entry.ICID === row.ICID) {
                      entry.paydata[0].Rental4Deduction = Number(
                        updatedData.value
                      ).toFixed(2);
                    }
                    return entry;
                  });
                  if (isFieldModified) {
                    handleEdit(updatedEntries, row.ICID);
                  }
                  return {
                    ...prevState,
                    entries: updatedEntries,
                    isEditingInline:
                      Number(updatedData.value).toFixed(2) ==
                      originalEntry.paydata[0]?.Rental4Deduction
                        ? false
                        : true,
                    editingNotesName: "Rental4Deduction",
                    editingNotesLabel: "Rental4 Deduction",
                    editingICID: row.ICID,
                  };
                });
                // handleInlineEdit(updatedData, row.id);
              }}
            />
          ) : (
            <Tooltip placement="top-start" title={tooltip} variant="body2">
              <Typography>{row.paydata[0]?.Rental4Deduction}</Typography>
            </Tooltip>
          );
        }
      },
    },
    {
      isHidden: !state.dynamicColumns?.EOR?.isSelected,
      id: "paydata.0.EOR",
      label: "EOR",
      field: "paydata.0.EOR",
      canSort: true,
      render: (row, rowIndex) => {
        if (row.status === "temp") {
          const value = Number(row.paydata[0]?.EOR).toFixed(2);
          return (
            <Tooltip placement="top-start" title={`${value}`} variant="body2">
              <Typography className="text-bold">${value}</Typography>
            </Tooltip>
          );
        } else {
          let validations = [
            { type: VALIDATIONS.MIN, value: 0 },
            { type: VALIDATIONS.LIMIT, value: 10.2 },
          ];
          const originalEntry =
            state.originalData.find((item) => item.ICID === row.ICID) || {};
          const isFieldModified =
            originalEntry?.paydata[0]?.EOR != row?.paydata[0]?.EOR;

          const notes_tooltip =
            state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.EOR_notes || "";

          const tooltip = isFieldModified
            ? !!notes_tooltip
              ? `Original Value : ${originalEntry.paydata[0]?.EOR}, Changed Value : ${row.paydata[0]?.EOR}, Notes : ${notes_tooltip}`
              : `Original Value : ${originalEntry.paydata[0]?.EOR}, Changed Value : ${row.paydata[0]?.EOR}`
            : row.paydata[0]?.EOR;
          if (originalEntry.paydata[0]?.EOR == row.paydata[0]?.EOR) {
            delete state.entries?.filter((entry) => entry.ICID === row.ICID)[0]
              ?.paydata[0]?.["EOR_notes"];
          }
          return hasFeatureAccess(PERMISSION.MANAGE_DCN) ? (
            <InlineFieldForm
              textFieldTooltip={`${tooltip}`}
              labelClassName={isFieldModified ? "color-error" : ""}
              name="EOR"
              initialValue={Number(row.paydata[0].EOR).toFixed(2)}
              inputType="number"
              validations={validations}
              onUpdate={(updatedData) => {
                setState((prevState) => {
                  const updatedEntries = prevState.entries.map((entry) => {
                    if (entry.ICID === row.ICID) {
                      entry.paydata[0].EOR = Number(updatedData.value).toFixed(
                        2
                      );
                    }
                    return entry;
                  });
                  if (isFieldModified) {
                    handleEdit(updatedEntries, row.ICID);
                  }
                  return {
                    ...prevState,
                    entries: updatedEntries,
                    isEditingInline:
                      Number(updatedData.value).toFixed(2) ==
                      originalEntry.paydata[0]?.EOR
                        ? false
                        : true,
                    editingNotesName: "EOR",
                    editingNotesLabel: "EOR",
                    editingICID: row.ICID,
                  };
                });
              }}
            />
          ) : (
            <Tooltip placement="top-start" title={tooltip} variant="body2">
              <Typography>{row.paydata[0]?.EOR}</Typography>
            </Tooltip>
          );
        }
      },
    },
  ];

  if (!isRoleView) {
    columnConfig.splice(0, 0, {
      id: "col-edit",
      noResize: true,
      render: (row) => moreActions(row),
    });
  }

  if (!isRoleView) {
    columnConfig.splice(1, 0, {
      id: "Exclude Records",
      noResize: true,
      tooltip: "Exclude Records",
      label: "Exclude Records",
      render: (row) => {
        if (row.status === "temp") {
          return (
            <Typography className="text-bold" variant="body1">
              Total
            </Typography>
          );
        }
        return (
          <Tooltip title="Exclude Record" placement="top-start">
            <Switch
              size="small"
              checked={!row.contractorId}
              onChange={() => {
                setState((prevState) => ({
                  ...prevState,
                  excludeRecords: row,
                  isExcludingRecords: true,
                }));
              }}
              color="primary"
            />
          </Tooltip>
        );
      },
    });
  }

  const modifiedEntries = state.entries?.map((item) => {
    const persitedModifiedentries =
      getPageConfig(PAGE_KEYS.DCN)?.modifiedEntries || [];

    const persistedEntry = persitedModifiedentries.find(
      (entry) =>
        entry.ICID === item.ICID &&
        entry.settlementCompany === item.settlementCompany &&
        entry.startDate === item.startDate &&
        entry.endDate === item.endDate
    );

    return persistedEntry ? persistedEntry : item;
  });

  if (!!state.entries.length) {
    const updatedEntries = state.entries?.map((entry) => {
      const { startDate, endDate, settlementCompany, ...rest } = entry;
      return rest;
    });
    const totals = updatedEntries?.reduce(
      (accumulator, entry) => {
        const paydata = entry.paydata[0];
        const fields = dcnFields;

        fields?.forEach((field) => {
          accumulator[field] += Number(paydata[field]);
        });

        return accumulator;
      },
      {
        Settlement: 0,
        FuelReimbursement: 0,
        TollReimbursement: 0,
        MileageReimbursement: 0,
        Reimbursement: 0,
        MiscDeduction: 0,
        Misc2Deduction: 0,
        FuelAdvanceDeduction: 0,
        FuelAdvance2: 0,
        CashAdvanceDeduction: 0,
        CashAdvance2Deduction: 0,
        ClaimDeduction: 0,
        Claim2Deduction: 0,
        Claim3Deduction: 0,
        Rental1Deduction: 0,
        Rental2Deduction: 0,
        Rental3Deduction: 0,
        Rental4Deduction: 0,
        EOR: 0,
      }
    );

    newEntry = [
      {
        CoName: "-",
        DeptNum: "-",
        EIN: "-",
        FirstName: "-",
        ICID: "-",
        LastName: "-",
        SSN: "-",
        flaggedReason: "-",
        id: "-",
        isFlagged: "false",
        settlementCompany: "-",
        status: "temp",
        paydata: [totals],
      },
    ];
  } else {
    newEntry = [];
  }

  const getNestedPropertyValue = (obj, path) => {
    if (!path) {
      return "";
    }

    const properties = path.split(".");
    let value = obj;

    for (let property of properties) {
      value = value?.[property];

      // If the current value is undefined or null, break out of the loop
      if (value === undefined || value === null) {
        break;
      }
    }
    return value;
  };

  const alphanumericCompare = (a, b) => {
    const chunkify = (value) =>
      value
        .toString()
        .replace(/([0-9]+)/g, (_, number) =>
          `00000000000000000000${number}`.slice(-20)
        );

    const chunkedA = chunkify(a);
    const chunkedB = chunkify(b);

    return chunkedA.localeCompare(chunkedB, undefined, { sensitivity: "base" });
  };

  //get sorted and paginated data
  const getData = (orderBy = state.orderBy, order = state.order) => {
    const sortObj = { field: orderBy, order };

    let preparedData =
      [...modifiedEntries, ...newEntry] &&
      [...modifiedEntries, ...newEntry].slice();
    if (!preparedData || preparedData.length === 0) {
      return [];
    }
    preparedData = preparedData.map((row) => ({
      ...row,
      id: row.id,
    }));

    if (sortObj) {
      const sortedModifiedEntries = modifiedEntries.sort((rowA, rowB) => {
        const valueA = getNestedPropertyValue(rowA, sortObj.field);
        const valueB = getNestedPropertyValue(rowB, sortObj.field);

        if (valueA === undefined || valueA === null) {
          return valueB === undefined || valueB === null ? 0 : 1;
        }

        if (valueB === undefined || valueB === null) {
          return -1;
        }

        return sortObj.order === "DESC"
          ? alphanumericCompare(valueB, valueA)
          : alphanumericCompare(valueA, valueB);
      });

      preparedData = [...sortedModifiedEntries, ...newEntry];
    }

    return { preparedData };
  };

  const getExcludedData = (
    orderBy = state.excludeOrderBy,
    order = state.excludeOrder
  ) => {
    const sortObj = { field: orderBy, order };

    let preparedData =
      [...state.excludedRecords, ...newExcludedEntry] &&
      [...state.excludedRecords, ...newExcludedEntry].slice();

    if (!preparedData || preparedData.length === 0) {
      return [];
    }
    preparedData = preparedData.map((row) => ({
      ...row,
      id: row.ICID,
    }));

    if (sortObj) {
      const sortedModifiedEntries = state.excludedRecords.sort((rowA, rowB) => {
        const valueA = getNestedPropertyValue(rowA, sortObj.field);
        const valueB = getNestedPropertyValue(rowB, sortObj.field);

        if (valueA === undefined || valueA === null) {
          return valueB === undefined || valueB === null ? 0 : 1;
        }

        if (valueB === undefined || valueB === null) {
          return -1;
        }

        return sortObj.order === "DESC"
          ? alphanumericCompare(valueB, valueA)
          : alphanumericCompare(valueA, valueB);
      });
      preparedData = [...sortedModifiedEntries, ...newExcludedEntry];
    }

    return { preparedData };
  };

  if (state.totalExcludedRecordEntries > 0) {
    const updatedEntries = state.excludedRecords?.map((entry) => {
      const { startDate, endDate, settlementCompany, ...rest } = entry;
      return rest;
    });

    const totals = updatedEntries?.reduce(
      (accumulator, entry) => {
        const paydata = entry.paydata[0];
        const fields = dcnFields;

        fields?.forEach((field) => {
          accumulator[field] += Number(paydata[field]);
        });

        return accumulator;
      },
      {
        Settlement: 0,
        FuelReimbursement: 0,
        TollReimbursement: 0,
        MileageReimbursement: 0,
        Reimbursement: 0,
        MiscDeduction: 0,
        Misc2Deduction: 0,
        FuelAdvanceDeduction: 0,
        FuelAdvance2: 0,
        CashAdvanceDeduction: 0,
        CashAdvance2Deduction: 0,
        ClaimDeduction: 0,
        Claim2Deduction: 0,
        Claim3Deduction: 0,
        Rental1Deduction: 0,
        Rental2Deduction: 0,
        Rental3Deduction: 0,
        Rental4Deduction: 0,
        EOR: 0,
      }
    );

    newExcludedEntry = [
      {
        CoName: "-",
        DeptNum: "-",
        EIN: "-",
        FirstName: "-",
        ICID: "-",
        LastName: "-",
        SSN: "-",
        flaggedReason: "-",
        id: "-",
        isFlagged: "false",
        settlementCompany: "-",
        status: "temp",
        paydata: [totals],
        tempExcludedData: "-",
      },
    ];
  } else {
    newExcludedEntry = [];
  }

  return (
    <div id="numbers-page-wrapper">
      <Grid container className="mr-5">
        <Grid item xs={12} className={classes.fixedHeader}>
          <div className="mb-2">
            <Typography variant="h4" color="primary" className="ml-2">
              Settlements
              <Button
                startIcon={<ArrowBackIcon />}
                style={{ float: "right" }}
                className="mt-1 mr-2"
                variant="contained"
                color="primary"
                onClick={() => history.push("/home")}
              >
                Back To Home
              </Button>
            </Typography>
            <Typography variant="body2" style={{ marginLeft: "10px" }}>
              {!isRoleView
                ? "Please Review/Change data before sending it to DCN."
                : "View data, being send to DCN."}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} className="ml-1">
          <div className={"d-flex f-align-center f-wrap f-justify-between"}>
            <div className={"d-flex f-align-center mb-2"}>
              <Datepicker
                mask
                preventClear
                label="Start date"
                selected={new Date(state.startDate)}
                maxDate={new Date(state.endDate)}
                classes={{
                  input: {
                    root: clsx("mr-4 mt-4", classes.datepickerWrapper),
                  },
                }}
                onChange={(startDate) => {
                  setState((prevState) => ({
                    ...prevState,
                    startDate: getDateString(startDate),
                  }));
                }}
              />
              <Datepicker
                mask
                preventClear
                label="End date"
                selected={new Date(state.endDate)}
                minDate={new Date(state.startDate)}
                maxDate={new Date()}
                classes={{
                  input: {
                    root: clsx("mr-4 mt-4", classes.datepickerWrapper),
                  },
                }}
                onChange={(endDate) => {
                  setState((prevState) => ({
                    ...prevState,
                    endDate: getDateString(endDate),
                  }));
                }}
              />
              <Autocomplete
                size="small"
                className={clsx(classes.settlementCompanyDropdown, {
                  "mt-7": appData.isTabletView,
                })}
                options={state.settlementCompanyList.filter((company) =>
                  company.value.includes("DCN")
                )}
                value={state.settlementCompany}
                getOptionLabel={(option) =>
                  `${option.value}(${option.description})`
                }
                renderInput={(params) =>
                  state.isFetchingList.users ? (
                    <Skeleton variant="rect" width={200} height={38} />
                  ) : (
                    <TextField
                      {...params}
                      label="Settlement Company"
                      variant="outlined"
                    />
                  )
                }
                onChange={(evt, value) => {
                  setState((prevState) => ({
                    ...prevState,
                    settlementCompany: value,
                    filters: {
                      ...prevState.filters,
                      settlementCompany: [
                        {
                          field: "settlement_co_id",
                          type: "=",
                          value: value?.id,
                        },
                      ],
                    },
                  }));
                }}
              />
              <Button
                variant="contained"
                color="primary"
                className={clsx("ml-4 mr-2 mt-3", {
                  "mt-4": appData.isTabletView,
                })}
                disabled={!state.settlementCompany}
                onClick={() => {
                  setPageConfig(PAGE_KEYS.DCN_FILTERS, {
                    isGoButtonIsClicked: true,
                    startDateFilter: state.startDate,
                    endDateFilter: state.endDate,
                    settlementCompanyList: {
                      value: state.settlementCompany,
                      settlementCompany: state.filters.settlementCompany,
                    },
                  });
                  const data = JSON.stringify({
                    start_date: state.startDate,
                    end_date: state.endDate,
                    settlementCompany:
                      state.filters.settlementCompany[0]?.value,
                  });
                  localStorage.setItem("filters-dcn", data || {});

                  fetchEntries(state.startDate, state.endDate, state.filters);
                  fetchExcludedRecords(
                    state.startDate,
                    state.endDate,
                    state.filters
                  );
                  setState((prevState) => ({
                    ...prevState,
                    isDataFetched: true,
                    excludeIds: [],
                  }));
                }}
              >
                Go
              </Button>
            </div>
            <div
              className={clsx("d-flex", {
                "mt-4 f-justify-start f-wrap": appData.isTabletView,
              })}
            ></div>

            <div>
              {!appData.isTabletView && (
                <Button
                  variant="contained"
                  color="primary"
                  className="ml-2 mr-2 mt-2"
                  startIcon={<HistoryIcon />}
                  onClick={() => history.push("settlements/excluded-records")}
                >
                  View Excluded Records
                  {state.isLoading && (
                    <CircularProgress
                      size={24}
                      className={classes.progressBtn}
                    />
                  )}
                </Button>
              )}
              {!appData.isTabletView && (
                <Button
                  variant="contained"
                  color="primary"
                  className="ml-2 mr-2 mt-2"
                  startIcon={<HistoryIcon />}
                  onClick={() => history.push("settlements/history")}
                >
                  View History
                  {state.isLoading && (
                    <CircularProgress
                      size={24}
                      className={classes.progressBtn}
                    />
                  )}
                </Button>
              )}
            </div>
          </div>
          <div>
            {appData.isTabletView && (
              <div className={"d-flex f-align-center f-justify-end"}>
                <Button
                  variant="contained"
                  color="primary"
                  className="ml-2 mr-2 mt-2"
                  startIcon={<HistoryIcon />}
                  onClick={() => history.push("settlements/excluded-records")}
                >
                  View Excluded Records
                  {state.isLoading && (
                    <CircularProgress
                      size={24}
                      className={classes.progressBtn}
                    />
                  )}
                </Button>
              </div>
            )}
            {appData.isTabletView && (
              <div className={"d-flex f-align-center f-justify-end"}>
                <Button
                  variant="contained"
                  color="primary"
                  className="ml-2 mr-2 mt-2"
                  startIcon={<HistoryIcon />}
                  onClick={() => history.push("settlements/history")}
                >
                  View History
                  {state.isLoading && (
                    <CircularProgress
                      size={24}
                      className={classes.progressBtn}
                    />
                  )}
                </Button>
              </div>
            )}
          </div>

          {state.showAlertBar && (
            <div className="mt-2">
              <Alert icon={false} severity="warning">
                <li>{state.showAlertBarMessage}</li>
              </Alert>
            </div>
          )}
        </Grid>

        <>
          <Grid item xs={12}>
            {!state.isDataFetched ? (
              <>
                {!state.showAutoICDialog && (
                  <Paper
                    elevation={2}
                    className={clsx(
                      "d-flex f-align-center f-justify-center mt-6 p-2 pl-4 pr-4 p-relative",
                      classes.paperHeight
                    )}
                  >
                    <div className="d-flex flex-column f-justify-center f-align-center">
                      {!state.showApproveRecordsDialog &&
                      !state.showAutoICDialog ? (
                        <Typography variant="h6">
                          Please select the Date range, Settlement company and
                          click on Go button to view the records.
                        </Typography>
                      ) : (
                        <Typography variant="h6">
                          {state.showApproveRecordsMessage}
                        </Typography>
                      )}
                    </div>
                  </Paper>
                )}
                {state.showAutoICDialog && (
                  <Paper
                    elevation={2}
                    className={clsx(
                      "d-flex f-align-top f-justify-start mt-4",
                      classes.paperHeight
                    )}
                  >
                    {state.showAutoICDialog && (
                      <div className="d-flex flex-column f-justify-start f-align-top mt-4 ml-2">
                        <Typography className="ml-6" variant="body1">
                          Following ICID(s) should be numeric:
                        </Typography>

                        <ul>
                          <b>
                            {state.autoICIDs?.map((icid) => {
                              return <li>{icid}</li>;
                            })}
                          </b>
                        </ul>
                        <Typography className="ml-6" variant="body1">
                          {" "}
                          To proceed further on the DCN process, please update
                          the given ICID(s) with actual ICID(s) through
                          independent contractor screen.
                        </Typography>
                      </div>
                    )}
                  </Paper>
                )}
              </>
            ) : (
              <>
                <Tabs
                  value={state.tabIndex}
                  onChange={handleTabChange}
                  indicatorColor={"#eeeeee"}
                  textColor={"primary"}
                  className={classes.tabs}
                >
                  {TAB_LIST.map((tab) => (
                    <Tab
                      value={tab.id}
                      key={tab.id}
                      name={tab.id}
                      className={classes.tab}
                      style={{
                        borderTop:
                          tab.id === state.tabIndex
                            ? "2px solid #80808045"
                            : "none",
                        borderLeft:
                          tab.id === state.tabIndex
                            ? "2px solid #80808045"
                            : "none",
                        borderRight:
                          tab.id === state.tabIndex
                            ? "2px solid #80808045"
                            : "none",
                        background:
                          tab.id === state.tabIndex ? "white" : "#ececec",
                      }}
                      label={
                        <Typography
                          variant="subtitle2"
                          color={
                            tab.id === state.tabIndex
                              ? "primary.dark"
                              : "grey.300"
                          }
                        >
                          {`${tab.label} (${
                            tab.label === "Excluded Records"
                              ? state.totalExcludedRecordEntries
                              : state.totalEntries
                          })`}
                        </Typography>
                      }
                    />
                  ))}
                </Tabs>
                {state.tabIndex === 1 && (
                  <Paper
                    elevation={2}
                    className={clsx("p-4 mt-10", classes.paperSpacing)}
                  >
                    <div
                      className={clsx(
                        "d-flex f-align-center f-justify-between p-2 pl-4 pr-4",
                        classes.actionsWrapper
                      )}
                    >
                      {" "}
                      {!isRoleView && (
                        <Button
                          startIcon={<RotateLeftIcon />}
                          style={{
                            borderColor: isValueChanged ? "red" : "#cdcdcd",
                            color: isValueChanged ? "red" : "#cdcdcd",
                          }}
                          className="ml-2 mr-2 mt-2"
                          variant="outlined"
                          disabled={!isValueChanged}
                          onClick={() =>
                            setState((prevState) => ({
                              ...prevState,
                              allRowsBeingEdited: true,
                            }))
                          }
                        >
                          Reset All
                        </Button>
                      )}
                      <div></div>
                      <div className="d-flex f-justify-center">
                        <Button
                          variant="contained"
                          color="primary"
                          className="ml-2 mr-2 mt-2"
                          startIcon={<CloudDownloadIcon />}
                          disabled={!state.entries.length}
                          onClick={() =>
                            handleDownload(state.entries, newEntry)
                          }
                        >
                          Download
                          {state.isLoading && (
                            <CircularProgress
                              size={24}
                              className={classes.progressBtn}
                            />
                          )}
                        </Button>
                        {!isRoleView && (
                          <Button
                            variant="contained"
                            color="primary"
                            className="ml-2 mr-2 mt-2"
                            startIcon={<CloudUploadIcon />}
                            disabled={!state.entries.length}
                            onClick={() => {
                              fetchDCNUsersList();
                              setState((prevState) => ({
                                ...prevState,
                                showConfirmationPopup: true,
                              }));
                            }}
                          >
                            Send to DCN
                            {state.isLoading && (
                              <CircularProgress
                                size={24}
                                className={classes.progressBtn}
                              />
                            )}
                          </Button>
                        )}
                        <Dropdown
                          classes={{
                            paper: classes.tunePaper,
                          }}
                          disablePortal={false}
                          remainOpen
                          isMultiSelect
                          placement="bottom-end"
                          options={Object.keys(state.dynamicColumns).map(
                            (key) => state.dynamicColumns[key]
                          )}
                          customToggle={({ anchorRef, onClick }) => (
                            <Tooltip
                              title="Show/Hide column(s)"
                              placement="top-start"
                            >
                              <IconButton
                                color="primary"
                                ref={anchorRef}
                                onClick={onClick}
                              >
                                <TuneIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          onChange={(options) => {
                            const isSelectAll = options.some(
                              (item) => item.value === "select_all"
                            );
                            const isCurrentSelectAll =
                              state.dynamicColumns?.select_all?.isSelected;

                            const isClickedOnSelectAll =
                              isSelectAll !== isCurrentSelectAll;

                            const isAllOptionSelected =
                              Object.keys(state.dynamicColumns).length - 1 ===
                              options.filter(
                                (item) => item.value !== "select_all"
                              ).length;
                            const updatedDynamicColumns = Object.keys(
                              state.dynamicColumns
                            ).reduce((acc, key) => {
                              const isSelected = isClickedOnSelectAll
                                ? isSelectAll
                                : key === "select_all"
                                ? isAllOptionSelected
                                : !!options.some((item) => item.value === key);
                              return {
                                ...acc,
                                [key]: {
                                  ...state.dynamicColumns[key],
                                  isSelected,
                                },
                              };
                            }, {});
                            setPageConfig(PAGE_KEYS.DCN_FILTERS, {
                              dynamicColumns: updatedDynamicColumns,
                            });
                            setState((prevState) => ({
                              ...prevState,
                              dynamicColumns: {
                                ...prevState.dynamicColumns,
                                ...updatedDynamicColumns,
                              },
                            }));
                          }}
                        />
                        <Tooltip placement="top-end" title="Reset All Filters">
                          <IconButton
                            color="primary"
                            onClick={() => {
                              updatePageConfig("dcn-filters");
                              window.location.reload();
                            }}
                          >
                            <RotateLeftIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <DataGrid
                      columns={columnConfig}
                      rows={getData()?.preparedData?.map((entry) => ({
                        ...entry,
                        className: clsx({
                          [classes.total]: entry.status === "temp",
                        }),
                      }))}
                      actionBarConfig={null}
                      isLoading={state.isFetching}
                      hasSelection={false}
                      hasPagination={false}
                      onReady={(instance) => (gridHelper = instance)}
                      totalRows={state.totalEntries}
                      pageSize={state.pageSize}
                      pageNumber={state.pageNumber}
                      order={state.order}
                      orderBy={state.orderBy}
                      onPageNumberChange={handlePageNumberChange}
                      onPageSizeChange={handlePageSizeChange}
                      onSelectionChange={handleSelectionChange}
                      boxborder={state.clickedRowId}
                      selectedRef={selectedRef}
                      isDCNScreen={true}
                      onSortChange={handleSortChange}
                      rowEvents={[
                        {
                          type: "onDoubleClick",
                          handler: (row) => {
                            setState((prevState) => ({
                              ...prevState,
                              clickedRowId: row.ICID,
                            }));
                            setPageConfig(PAGE_KEYS.DCN_FILTERS, {
                              clickedRowId: row.ICID,
                            });
                          },
                        },
                        {
                          type: "onClick",
                          handler: (row) => {
                            setState((prevState) => ({
                              ...prevState,
                              clickedRowId: row.ICID,
                            }));
                            setPageConfig(PAGE_KEYS.DCN_FILTERS, {
                              clickedRowId: row.ICID,
                            });
                          },
                        },
                      ]}
                    />
                  </Paper>
                )}
                {state.tabIndex === 2 && (
                  <Paper
                    elevation={2}
                    className={clsx("p-4 mt-10", classes.paperSpacing)}
                  >
                    <ExcludedRecord
                      includeRecordsSettlements={includeRecordsSettlements}
                      getExcludedData={getExcludedData}
                      handleExcludeSortChange={handleExcludeSortChange}
                      isFetching={state.isFetching}
                      updatePageConfig={updatePageConfig}
                      excludedRecords={state.excludedRecords}
                      newExcludedEntry={newExcludedEntry}
                      filterPageConfig={filterPageConfig}
                      setPageConfig={setPageConfig}
                      handleDownload={handleDownload}
                      PAGE_KEYS={PAGE_KEYS}
                      excludeOrder={state.excludeOrder}
                      excludeOrderBy={state.excludeOrderBy}
                      includeExcludedRecords={includeExcludedRecords}
                      users={appData.users}
                      permanentExcludeRecords={permanentExcludeRecords}
                      totalExcludedRecordEntries={
                        state.totalExcludedRecordEntries
                      }
                    />
                  </Paper>
                )}
              </>
            )}
          </Grid>

          {state.showConfirmationPopup && (
            <ActionDialog
              classes={{
                confirm:
                  state.isSendingToDCN ||
                  state.isAddButtonEnabled ||
                  isConfirmDisabled
                    ? "black"
                    : "bg-primary",
                paper: classes.senToDcnPaperSize,
              }}
              open={!!state.showConfirmationPopup}
              contentText={
                <div style={{ overflowY: "hidden" }}>
                  <div
                    className={clsx(
                      "d-flex f-align-center mt-4",
                      classes.payDateGap
                    )}
                  >
                    <Typography
                      variant="body2"
                      className={clsx("text-bold", classes.payDateSize)}
                    >
                      Select pay date :
                    </Typography>
                    <Datepicker
                      mask
                      preventClear
                      label="Pay date"
                      required
                      selected={new Date(state.payDate)}
                      classes={{
                        input: {
                          root: clsx(
                            "mr-4 mt-3 mb-3",
                            classes.datepickerWrapper
                          ),
                        },
                      }}
                      onChange={(payDate) => {
                        setState((prevState) => ({
                          ...prevState,
                          payDate: getDateString(payDate),
                        }));
                      }}
                    />
                  </div>
                  <Typography variant="body2" className="mt-4 text-bold">
                    DCN Email Recipients:
                  </Typography>
                  {state.isLoading ? (
                    <Skeleton variant="text" width="50%" height="100%" />
                  ) : state.dcnUsersList?.length > 0 ? (
                    <ul className="mt-1 mb-6">
                      <b>
                        {state.dcnUsersList?.map((dcnUsers) => {
                          return <li>{dcnUsers.value}</li>;
                        })}
                      </b>
                    </ul>
                  ) : (
                    <div className={classes.noEmail}>
                      To add an email, you can use the lookup screen.
                    </div>
                  )}
                  <Typography variant="body2" className="text-bold">
                    Want to add more DCN emails(Optional)?
                  </Typography>
                  <div className="d-flex" style={{ gap: "6px" }}>
                    <TextField
                      label="Add DCN Email ID(s)"
                      fullWidth
                      variant="outlined"
                      size="small"
                      name="email"
                      value={state.email}
                      className="mt-3"
                      onChange={handleFieldChange}
                      error={!!state.errors.email?.trim()}
                      helperText={state.errors.email}
                    />
                    <div>
                      <Tooltip
                        title="Add Email Temporarily"
                        placement="top-end"
                      >
                        <Button
                          type="submit"
                          onClick={handleAddEmail}
                          variant="contained"
                          color="primary"
                          disabled={isConfirmDisabled || !state.email}
                          className={classes.buttonStyle}
                        >
                          <AddIcon />
                        </Button>
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip
                        title="Add To Lookup(DCN Email Recipients)"
                        placement="top-end"
                      >
                        <Button
                          type="submit"
                          onClick={handleShowAddLookupDialog}
                          variant="contained"
                          color="primary"
                          disabled={isConfirmDisabled || !state.email}
                          className={classes.buttonStyle}
                        >
                          <PostAddIcon />
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {state.newEmail.map((email) => (
                      <Chip
                        key={email.id}
                        label={email.email}
                        onDelete={() => handleDeleteEmail(email?.id)}
                        className={classes.emailChip}
                      />
                    ))}
                  </div>
                </div>
              }
              onConfirm={() => handleSubmit()}
              onCancel={() => {
                isConfirmDisabled = false;
                setState((prevState) => ({
                  ...prevState,
                  showConfirmationPopup: false,
                  email: null,
                  isAddButtonEnabled: false,
                  newEmail: [],
                  errors: {
                    email: " ",
                  },
                }));
              }}
              isConfirmDisabled={
                state.isSendingToDCN ||
                state.isAddButtonEnabled ||
                isConfirmDisabled
              }
              positiveActionLabel={
                <>
                  SEND TO DCN
                  {state.isSendingToDCN && (
                    <CircularProgress
                      size={24}
                      className="p-absolute progress-btn color-white"
                    />
                  )}
                </>
              }
              negativeActionLabel={<>Cancel</>}
            />
          )}
          {state.isEditingInline && (
            <ActionDialog
              classes={{
                confirm: "bg-primary",
                paper: classes.notesPaperSize,
              }}
              open={!!state.isEditingInline}
              contentText={
                <>
                  <Typography variant="body2" className="mt-2">
                    Want to add note or reason for change(Optional)?
                  </Typography>
                  <br></br>
                  <TextField
                    className="mb-1"
                    fullWidth
                    name={"notes"}
                    label={`${state.editingNotesLabel} Notes`}
                    variant="outlined"
                    size="small"
                    error={!!state.errors.notes?.trim()}
                    helperText={state.errors.notes}
                    onChange={(evt) => handleFieldChange(evt)}
                  />
                </>
              }
              onConfirm={() =>
                setState((prevState) => {
                  let value = state.notes;
                  const updatedEntries = prevState.entries.map((entry) => {
                    let field = `${state.editingNotesName}_notes`;
                    if (entry.ICID === state.editingICID) {
                      entry.paydata[0] = {
                        ...entry?.paydata[0],
                        [field]: value,
                      };
                    }
                    return entry;
                  });
                  handleEdit(updatedEntries, state.editingICID);
                  return {
                    ...prevState,
                    entries: updatedEntries,
                    isEditingInline: false,
                    notes: defaultState.notes,
                  };
                })
              }
              onCancel={() =>
                setState((prevState) => {
                  const updatedEntries = prevState.entries.map((entry) => {
                    let field = `${state.editingNotesName}_notes`;
                    if (entry.ICID === state.editingICID) {
                      const paydata = entry?.paydata[0];
                      if (paydata && paydata[field]) {
                        delete paydata[field];
                      }
                    }
                    return entry;
                  });
                  handleEdit(updatedEntries, state.editingICID);
                  return {
                    ...prevState,
                    entries: updatedEntries,
                    isEditingInline: false,
                    notes: defaultState.notes,
                    errors: {
                      notes: " ",
                    },
                  };
                })
              }
              isConfirmDisabled={validate() || !state.notes?.trim()}
              positiveActionLabel={
                <>
                  OK
                  {state.isSendingToDCN && (
                    <CircularProgress
                      size={24}
                      className="p-absolute progress-btn color-white"
                    />
                  )}
                </>
              }
              negativeActionLabel={false}
            />
          )}
          {state.showDCNHistoryDialog && (
            <ActionDialog
              classes={{
                confirm: "bg-primary",
                paper: classes.paperSize,
              }}
              open={!!state.showDCNHistoryDialog}
              contentText={
                <>
                  <Typography variant="body2" className="mt-2">
                    {state.showDCNHistoryDialogMessage}
                  </Typography>
                </>
              }
              onConfirm={() =>
                setState((prevState) => ({
                  ...prevState,
                  showDCNHistoryDialog: false,
                }))
              }
              onCancel={() =>
                setState((prevState) => ({
                  ...prevState,
                  showDCNHistoryDialog: false,
                }))
              }
              positiveActionLabel={<>OK</>}
              negativeActionLabel={false}
            />
          )}
          {state.showAddLookupDialog && (
            <ActionDialog
              classes={{
                confirm: "bg-primary",
                paper: classes.addLookupEmailpaperSize,
              }}
              open={!!state.showAddLookupDialog}
              contentText={
                <>
                  <Typography variant="body2" className="mt-2 text-bold">
                    {`Would you also like to add this email address `}
                    <span style={{ color: "Black" }}>{state.email}</span>
                    {` in the Lookup(DCN Email Recipients)?`}
                  </Typography>
                </>
              }
              onConfirm={() => {
                addLookupValue(state.showAddLookupDialog);
                setTimeout(() => {
                  fetchDCNUsersList();
                }, 1000);
              }}
              onCancel={() => {
                setState((prevState) => ({
                  ...prevState,
                  showAddLookupDialog: false,
                }));
              }}
              isConfirmDisabled={state.isLookupLoading}
              positiveActionLabel={
                <>
                  ADD TO LOOKUP
                  {state.isLookupLoading && (
                    <CircularProgress
                      size={24}
                      className="p-absolute progress-btn"
                    />
                  )}
                </>
              }
              negativeActionLabel={<>CANCEL</>}
            />
          )}
          {state.allRowsBeingEdited && (
            <ActionDialog
              classes={{
                confirm: "bg-danger",
                paper: classes.paperSize,
              }}
              open={!!state.allRowsBeingEdited}
              contentText={
                <>
                  <Typography variant="body2" className="mt-2">
                    Are you certain you wish to reset the modified values for
                    all rows?
                  </Typography>
                  <br />
                  <div className="d-flex" style={{ gap: "4px" }}>
                    <Typography className="text-bold">Note: </Typography>
                    <Typography variant="body2" style={{ marginTop: "2px" }}>
                      This action cannot be reverted once done.
                    </Typography>
                  </div>
                </>
              }
              onConfirm={() => {
                revertAllChangedValue(state.allRowsBeingEdited);
                fetchEntries(state.startDate, state.endDate, state.filters);
              }}
              onCancel={() =>
                setState((prevState) => ({
                  ...prevState,
                  allRowsBeingEdited: null,
                }))
              }
              isConfirmDisabled={state.isLoading}
              positiveActionLabel={
                <>
                  Reset
                  {state.isLoading && (
                    <CircularProgress
                      size={24}
                      className="p-absolute progress-btn"
                    />
                  )}
                </>
              }
            />
          )}
          {state.rowBeingEdited && (
            <ActionDialog
              classes={{
                confirm: "bg-danger",
                paper: classes.paperSize,
              }}
              open={!!state.rowBeingEdited}
              contentText={
                <>
                  <Typography variant="body2" className="mt-2">
                    Are you certain you wish to reset the modified values for
                    this particular row?
                  </Typography>
                  <br />
                  <div className="d-flex" style={{ gap: "4px" }}>
                    <Typography className="text-bold">Note: </Typography>
                    <Typography variant="body2" style={{ marginTop: "2px" }}>
                      This action cannot be reverted once done.
                    </Typography>
                  </div>
                </>
              }
              onConfirm={() => {
                revertChangedValue(state.rowBeingEdited);
                fetchEntries(state.startDate, state.endDate, state.filters);
              }}
              onCancel={() =>
                setState((prevState) => ({
                  ...prevState,
                  rowBeingEdited: null,
                }))
              }
              isConfirmDisabled={state.isLoading}
              positiveActionLabel={
                <>
                  Reset
                  {state.isLoading && (
                    <CircularProgress
                      size={24}
                      className="p-absolute progress-btn"
                    />
                  )}
                </>
              }
            />
          )}
          {state.isExcludingRecords && (
            <ActionDialog
              classes={{
                confirm: "bg-primary",
                paper: classes.tempExcludePaperSize,
              }}
              open={!!state.isExcludingRecords}
              contentText={
                <>
                  <Typography variant="body2" className="mt-2">
                    Do you want to exclude this record?
                  </Typography>
                  <br></br>

                  <TextField
                    className="mb-1"
                    fullWidth
                    name={"tempExclude"}
                    label={`Exclude Reason`}
                    variant="outlined"
                    size="small"
                    error={!!state.errors.tempExclude?.trim()}
                    helperText={state.errors.tempExclude}
                    onChange={(evt) => handleFieldChange(evt)}
                  />
                </>
              }
              onConfirm={() => {
                excludeRecordsSettlements(
                  state.excludeRecords,
                  state.tempExclude,
                  state.isPermanentlyExcluded
                );
              }}
              onCancel={() => {
                setState((prevState) => {
                  return {
                    ...prevState,

                    isExcludingRecords: false,
                    tempExclude: defaultState.tempExclude,
                    errors: {
                      tempExclude: " ",
                    },
                  };
                });
              }}
              isConfirmDisabled={
                validate() ||
                !state.tempExclude?.trim() ||
                state.isTempExcluding
              }
              positiveActionLabel={
                <>
                  OK
                  {state.isTempExcluding && (
                    <CircularProgress
                      size={24}
                      className="p-absolute progress-btn color-white"
                    />
                  )}
                </>
              }
              negativeActionLabel={false}
            />
          )}
          {state.isExcludingRecordsPermanent && (
            <ActionDialog
              classes={{
                confirm: "bg-primary",
                paper: classes.permExcludePaperSize,
              }}
              open={!!state.isExcludingRecordsPermanent}
              contentText={
                <>
                  <Typography variant="body2" className="mt-2">
                    Do you want to permanent exclude this record?
                  </Typography>
                  <br></br>

                  <TextField
                    className="mb-1"
                    fullWidth
                    name={"permExclude"}
                    value={state.permExclude}
                    label={`Exclude Reason`}
                    variant="outlined"
                    size="small"
                    error={!!state.errors.permExclude?.trim()}
                    helperText={state.errors.permExclude}
                    onChange={(evt) => handleFieldChange(evt)}
                  />
                </>
              }
              onConfirm={() => {
                permanentExcludeRecordsSettlements(
                  state.tabIndex === 1
                    ? state.excludeRecords
                    : state.premExcludeRecords,
                  state.permExclude,
                  true
                );
              }}
              onCancel={() => {
                setState((prevState) => {
                  return {
                    ...prevState,

                    isExcludingRecordsPermanent: false,
                    permExclude: defaultState.permExclude,
                    errors: {
                      permExclude: " ",
                    },
                  };
                });
              }}
              isConfirmDisabled={
                validate() ||
                !state.permExclude?.trim() ||
                state.isPermExcluding
              }
              positiveActionLabel={
                <>
                  OK
                  {state.isPermExcluding && (
                    <CircularProgress
                      size={24}
                      className="p-absolute progress-btn color-white"
                    />
                  )}
                </>
              }
              negativeActionLabel={false}
            />
          )}
          {state.isIncludingRecords && (
            <ActionDialog
              classes={{
                confirm: "bg-primary",
                paper: classes.isIncludingRecords,
              }}
              open={!!state.isIncludingRecords}
              contentText={
                <Typography variant="body2" className="mt-2">
                  Do you want to include this record?
                </Typography>
              }
              onConfirm={() => {
                includeRecordsSettlements(state.isIncludingRecords);
              }}
              onCancel={() => {
                setState((prevState) => {
                  return {
                    ...prevState,
                    isIncludingRecords: false,
                  };
                });
              }}
              isConfirmDisabled={state.isIncluding}
              positiveActionLabel={
                <>
                  OK
                  {state.isIncluding && (
                    <CircularProgress
                      size={24}
                      className="p-absolute progress-btn color-white"
                    />
                  )}
                </>
              }
              negativeActionLabel={false}
            />
          )}
        </>
      </Grid>
    </div>
  );
};

export default DCN;
