import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  popperWrapper: {
    zIndex: theme.zIndex.tooltip,
  },
  paper: {
    maxHeight: 400,
    overflow: "auto",
    minWidth: navigator.userAgent.indexOf("Firefox") !== -1 ? 256 : 244,
  },
  ellipsesWidth: {
    maxWidth: 400,
  },
  searchInput: {
    "& .MuiInput-underline:after": {
      borderBottom: theme.palette.grey[300],
    },
  },
}));
