import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
	paper: {
		width: 600,
		height: 256,
	},
}));

export default useStyles;
