import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import IconButton from "@material-ui/core/IconButton";
import Service from "../service";
import SharedService from "modules/shared/service";
import { AppContext } from "shared/contexts";
import { PAGE_KEYS, isTransferScheduleEnable } from "shared/constants";
import { PERMISSION } from "modules/shared/constants";
import {
  queryStringBuilderNew,
  getPageConfig,
  setPageConfig,
  exportBase64ToFile,
  hasFeatureAccess,
  getTokenData,
  updatePagination,
  updateLayout,
  getDateString,
} from "utils";
import Chip from "@material-ui/core/Chip";
import ForceSubmitDialog from "../../daily-schedule/forceSubmit";
import { ROLE } from "modules/shared/constants";
import Form from "./form";
import ScheduleForm from "./shedule-form";
import RenameScheduleForm from "./rename-schedule-form";
import BulkUpload from "../bulk-upload";
import ErrorModal from "../error-modal";
import { ActionDialog, Grid, FilterComponent } from "shared/components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FilterListIcon from "@material-ui/icons/FilterList";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import Skeleton from "@material-ui/lab/Skeleton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import SearchIcon from "@material-ui/icons/Search";

import useStyles from "./style";

let gridHelper = null,
  timeout = null;
const defaultSortObj = { field: "from_price", order: "ASC" };
const defaultState = {
  isNew: false,
  pageSize: 100,
  pageNumber: 1,
  order: null,
  orderBy: null,
  entries: [],
  totalEntries: 0,
  isFiltering: false,
  pageFilters: [],
  search: "",
  isFetching: false,
  isExchanging: false,
  openUpload: false,
  sheetError: null,
  scheduleList: [],
  schedule: null,
  isFormOpen: false,
  isScheduleFormOpen: false,
  isAddingSchedule: false,
  deletingEntryIds: null,
  selectedRows: [],
  renameScheduleOpen: false,
  isTemplateDownloading: false,
  openForceSubmitDialog: false,
  isFetchingList: {
    fuelSurchargeSchedule: false,
  },
};

const FuelSurchargeSchedule = ({ history, match }) => {
  const { appData, updateContextData, setHeaderElements } =
    useContext(AppContext);
  const pageConfig = getPageConfig(PAGE_KEYS.CUSTOM_FUEL_SURCHARGE);
  const classes = useStyles();
  const [state, setState] = useState({
    ...defaultState,
    pageSize: pageConfig?.pageSize || defaultState.pageSize,
    // pageNumber: pageConfig?.pageNumber || defaultState.pageNumber,
  });
  const currentUser = getTokenData() || {};

  const isRoleKAM = (currentUser?.role || "").toLowerCase() === ROLE.KAM;
  const isRoleADMIN = (currentUser?.role || "").toLowerCase() === ROLE.ADMIN;
  const isRoleAA = (currentUser?.role || "").toLowerCase() === ROLE.AA;
  const kamId = isRoleKAM ? currentUser?.id : state.selectedKAM?.id;
  const hasWritePermission = hasFeatureAccess(PERMISSION.FUEL_SURCHARGE_WRITE);
  const handleSelectionChange = useCallback((selectedRows) => {
    setState((prevState) => ({
      ...prevState,
      selectedRows,
    }));
  }, []);

  const filterConfig = useMemo(
    () => [
      {
        field: "from_price",
        fieldToDisplay: "From Price",
        operatorType: "number",
      },
      {
        field: "to_price",
        fieldToDisplay: "To Price",
        operatorType: "number",
      },
    ],
    []
  );

  const handleSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      order: order,
      orderBy: fieldObj.field || fieldObj.fieldName,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setPageConfig(PAGE_KEYS.CUSTOM_FUEL_SURCHARGE, {
      pageSize,
    });
    setState((prevState) => ({
      ...prevState,
      pageSize,
      pageNumber: defaultState.pageNumber,
    }));
  }, []);

  const handlePageNumberChange = useCallback((pageNumber) => {
    // setPageConfig(PAGE_KEYS.CUSTOM_FUEL_SURCHARGE, {
    //   pageNumber,
    // });
    setState((prevState) => ({
      ...prevState,
      pageNumber,
    }));
  }, []);

  const handleSearch = useCallback((search) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        search,
        selectedRows: defaultState.selectedRows,
        pageNumber: defaultState.pageNumber,
      }));
      gridHelper && gridHelper.resetSelection();
    }, 1000);
  }, []);

  const handleDelete = async (entryIds) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const { error } = await Service.deleteFuelSurchargeSchedule(entryIds);

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      deletingEntryIds: null,
      selectedRows: defaultState.selectedRows,
    }));
    if (gridHelper) {
      gridHelper.resetSelection();
    }

    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      toast.success("Deleted successfully.");
      fetchFuelSurchargeSchedule();
      fetchEntries(
        state.search,
        state.pageSize,
        state.pageNumber,
        state.order,
        state.orderBy,
        state.schedule,
        state.pageFilters
      );
    }
  };

  const fetchEntries = useCallback(
    async (
      search,
      pageSize,
      pageNumber,
      order,
      orderBy,
      schedule,
      pageFilters = [],
      hasLoader = true
    ) => {
      setState((prevState) => ({
        ...prevState,
        isFetching: hasLoader,
      }));

      let filters = [
        ...pageFilters.map((filter) => ({
          ...filter,
          value: filter?.value?.value || filter?.value,
        })),
      ];
      if (!!search) {
        filters.push({
          field: "surcharge_percent",
          type: "=",
          value: search,
          notIncludeInBuilder: true,
        });
      }

      if (!!schedule) {
        filters.push({
          field: "name",
          type: "=",
          value: schedule,
          notIncludeInBuilder: true,
        });
      }

      const sortObj =
        orderBy && order ? { field: orderBy, order: order } : defaultSortObj;
      let queryString = queryStringBuilderNew(
        pageSize,
        pageSize * (pageNumber - 1),
        [],

        filters,

        sortObj,
        search && search.length > 0
      );
      const { data, error } = await Service.getFuelSurchargeSchedule(
        queryString
      );
      if (error) {
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0].message : error.message
        );
      }

      setState((prevState) => ({
        ...prevState,
        isFetching: false,
        entries: data.fuelSurchargeSchedules || defaultState.entries,
        totalEntries: data.count,
      }));
      return data;
    },
    []
  );

  const fetchFuelSurchargeSchedule = async (schedule = "") => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        fuelSurchargeSchedule: true,
      },
    }));

    const { data, error } = await SharedService.getFuelSurchargeScheduleNames();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          fuelSurchargeSchedule: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    const isScheduleExists = (
      data?.customFSNames || defaultState.scheduleList
    ).includes(schedule);
    setState((prevState) => ({
      ...prevState,
      scheduleList: data?.customFSNames || defaultState.scheduleList,
      schedule: isScheduleExists ? schedule : prevState.schedule,
      isFetchingList: {
        ...prevState.isFetchingList,
        fuelSurchargeSchedule: false,
      },
    }));
    if (!isScheduleExists) {
      history.push("/fuel-price/schedule");
    }
  };

  const handleDownload = async (type, schedule = "", isTemplate = false) => {
    console.log(isTemplate, "istemplate");
    setState((prevState) => ({
      ...prevState,
      isExchanging: !isTemplate ? true : false,
      isTemplateDownloading: isTemplate ? true : false,
    }));

    const queryString = isTemplate
      ? `?filter[isTemplate]=${isTemplate}`
      : `?filter[where][name]=${encodeURIComponent(schedule)}`;

    const { data, error } = await Service.download(type, queryString);
    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      !isTemplate
        ? toast.success(`Fuel surcharge schedule downloaded successfully.`)
        : toast.success(
            `Fuel surcharge schedule template downloaded successfully.`
          );
      !isTemplate
        ? exportBase64ToFile(data, "Fuel Surcharges Schedule")
        : exportBase64ToFile(data, "Fuel Surcharges Schedule Template");
    }
    setState((prevState) => ({
      ...prevState,
      isExchanging: false,
      isTemplateDownloading: false,
    }));
  };

  const handleFormClose = (row, rowIndex, isNewRecord = false) => {
    const entriesClone = state.entries.slice();
    entriesClone[rowIndex] = row;
    setState((prevState) => {
      return {
        ...prevState,
        isFormOpen: false,
        isAddingSchedule: isNewRecord ? false : prevState.isAddingSchedule,
        entries: entriesClone,
      };
    });
    if (isNewRecord) {
      state.isAddingSchedule && fetchFuelSurchargeSchedule(state.schedule);
      fetchEntries(
        state.search,
        state.pageSize,
        state.pageNumber,
        state.order,
        state.orderBy,
        state.schedule,
        state.pageFilters
      );
    }
  };

  const handleFormOpen = (row, rowIndex) => {
    setState((prevState) => ({
      ...prevState,
      selectedRows: defaultState.selectedRows,
      isFormOpen: true,
    }));
    gridHelper && gridHelper.resetSelection();
    const entriesClone = state.entries.slice();
    const isNew = !row;

    const form = {
      renderer: () => (
        <Form
          entry={row}
          isNew={isNew}
          schedule={state.schedule}
          onClose={(event) => handleFormClose(row, rowIndex)}
          onSave={(newRow) => handleFormClose(newRow, rowIndex, true)}
        />
      ),
    };

    isNew ? entriesClone.unshift(form) : (entriesClone[rowIndex] = form);
    setState((prevState) => ({
      ...prevState,
      entries: entriesClone,
    }));
  };

  useEffect(() => {
    if (state.schedule) {
      fetchEntries(
        state.search,
        state.pageSize,
        state.pageNumber,
        state.order,
        state.orderBy,
        state.schedule,
        state.pageFilters
      ).then((data) => {
        updatePagination(
          data?.count,
          { pageNumber: state.pageNumber, pageSize: state.pageSize },
          (pageNumber) => {
            setState((prevState) => ({ ...prevState, pageNumber }));
          }
        );
      });
    }
  }, [
    fetchEntries,
    state.pageSize,
    state.pageNumber,
    state.order,
    state.orderBy,
    state.search,
    state.schedule,
    state.pageFilters,
  ]);

  const handleFilterChange = useCallback((pageFilters = []) => {
    setState((prevState) => ({
      ...prevState,
      pageFilters,
    }));
  }, []);

  const fetchList = async (listType = "pendingItems", ...params) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    switch (listType) {
      case "pendingItems":
        serviceMethod = "getPendingItems";
        responseKey = "pendingItems";
        break;
      case "transferRecords":
        serviceMethod = "getTransferScheduleRequests";
        responseKey = "transferRecords";
        break;
      default:
        serviceMethod = "getPendingItems";
        responseKey = "pendingItems";
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];

    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
  };

  useEffect(() => {
    setTimeout(updateLayout, 2000);
  }, [state.entries]);

  useEffect(() => {
    !!appData.pendingItems.length &&
      isRoleKAM &&
      setHeaderElements([
        <div className="d-flex f-align-center m-2">
          <Chip
            className={"bg-danger color-white"}
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                openForceSubmitDialog: true,
              }));
            }}
            label={
              appData.isTabletView ? (
                <Tooltip title="PENDING SCHEDULE DATE(S)" placement="top-start">
                  <Typography variant="body2" className="text-bold">
                    ({appData.pendingItems.length})
                  </Typography>
                </Tooltip>
              ) : (
                <Typography variant="body2" className="text-bold">
                  PENDING SCHEDULE DATE(S) ({appData.pendingItems.length})
                </Typography>
              )
            }
            variant="outlined"
          />
        </div>,
      ]);

    return () => setHeaderElements([]);
  }, [appData.pendingItems]);

  useEffect(() => {
    if (kamId && isRoleKAM) {
      if (isTransferScheduleEnable) {
        fetchList("transferRecords", "", kamId);
      }
      fetchLeaveTypeList();
      fetchList("pendingItems", kamId);
    }
  }, [state.selectedKAM]);

  useEffect(() => {
    return () => {
      updateContextData("pendingItems", []);
    };
  }, []);

  const fetchLeaveTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        settlementCompanies: true,
      },
    }));

    const { data, error } = await SharedService.getLeaveTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          filters: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetching: false,
      forceSubmitReasonList: data?.rows || [],
    }));
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      openForceSubmitDialog: false,
    }));
  };

  useEffect(() => {
    // let decodedSchedule = match.params.schedule || "";
    // try {
    //   decodedSchedule = decodeURIComponent(decodedSchedule);
    // } catch (error) {
    //   console.error(error);
    // }

    const decodedSchedule = sessionStorage.getItem(match.params.schedule) || "";

    if (!!state.scheduleList.length) {
      setState((prevState) => ({
        ...prevState,
        // schedule: decodeURIComponent(
        //   encodeURIComponent(match.params.schedule || "")
        // ),
        schedule: decodedSchedule,
      }));
    } else {
      fetchFuelSurchargeSchedule(decodedSchedule);
    }
  }, [match.params.schedule]);

  const moreActions = (row, rowIndex) => {
    return (
      <div
        className={clsx("d-flex f-align-center f-justify-end", {
          disabled: state.isFormOpen,
        })}
      >
        <Tooltip title="Edit" placement="top-start">
          <EditOutlinedIcon
            className="ml-2 mr-2 c-pointer"
            onClick={() => handleFormOpen(row, rowIndex)}
          />
        </Tooltip>
        <Tooltip title="Delete" placement="top-start">
          <DeleteForeverOutlinedIcon
            color={state.isFormOpen ? "" : "error"}
            className="ml-2 mr-2 c-pointer"
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                deletingEntryIds: row.id,
              }))
            }
          />
        </Tooltip>
      </div>
    );
  };

  const columnConfig = [
    {
      id: "from_price",
      label: "From Price",
      field: "from_price",
      startAdornment: "$",
      canSort: true,
      hasEllipses: true,
    },
    {
      id: "to_price",
      label: "To Price",
      field: "to_price",
      startAdornment: "$",
      canSort: true,
      hasEllipses: true,
    },
    {
      id: "surcharge_percent",
      label: "Surcharge %",
      field: "surcharge_percent",
      endAdornment: "%",
      canSort: true,
      hasEllipses: true,
    },
  ];

  if (hasWritePermission) {
    columnConfig.unshift({
      headerClassName: classes.editFuelSurcharge,
      id: "col-edit",
      noResize: true,
      render: (row, rowIndex) =>
        hasWritePermission && moreActions(row, rowIndex),
    });
  }
  return (
    <div id="numbers-page-wrapper">
      <div className="mr-5">
        <div className={clsx("mb-2 mr-10", classes.fixedHeader)}>
          <Typography variant="h4" color="primary" className="ml-2">
            Fuel Surcharge Schedule
            <Button
              startIcon={<ArrowBackIcon />}
              style={{ float: "right" }}
              className="ml-2 mr-2"
              variant="contained"
              color="primary"
              onClick={() => history.push("/home")}
            >
              Back To Home
            </Button>
          </Typography>
          {hasWritePermission ? (
            <Typography variant="body2" style={{ marginLeft: "10px" }}>
              Add, Edit, Upload and Download Fuel Surcharge Schedule's
              information.
            </Typography>
          ) : (
            <Typography variant="body2" className="ml-2">
              View and Download Fuel Surcharge Schedule's information.
            </Typography>
          )}
        </div>
        <div
          className={clsx({
            "d-flex f-justify-between f-align-center": !appData.isTabletView,
          })}
        >
          <div className="d-flex f-align-center">
            <Autocomplete
              disabled={state.isFormOpen}
              fullWidth
              size="small"
              className={clsx("w-50 mr-2", {
                "d-none": state.isScheduleFormOpen,
              })}
              value={state.schedule}
              options={state.scheduleList}
              classes={{
                root: classes.fuelSurchargeScheduleDropdown,
              }}
              renderInput={(params) =>
                state.isFetchingList.fuelSurchargeSchedule ? (
                  <Skeleton variant="rect" width="100%" height={40} />
                ) : (
                  <TextField
                    {...params}
                    label={state.schedule ? "Schedule" : "Select Schedule"}
                    variant="outlined"
                  />
                )
              }
              onChange={(evt, schedule) => {
                setState((prevState) => {
                  gridHelper && gridHelper.resetSelection();
                  if (prevState.isAddingSchedule) {
                    setTimeout(
                      () => fetchFuelSurchargeSchedule(prevState.schedule),
                      100
                    );
                  }
                  return {
                    ...prevState,
                    selectedRows: defaultState.selectedRows,
                    search: defaultState.search,
                    isAddingSchedule: defaultState.isAddingSchedule,
                  };
                });
                if (schedule) {
                  const timeStamp = Date.now();
                  sessionStorage.setItem(timeStamp, schedule);
                  history.push(`/fuel-price/schedule/${timeStamp}`);
                } else {
                  history.push(`/fuel-price/schedule`);
                }
              }}
            />
            {(isRoleADMIN || isRoleAA) && state.schedule && (
              <Tooltip title="Rename Schedule" placement="top-start">
                <EditOutlinedIcon
                  className={clsx("ml-2 mr-2 c-pointer", {
                    disabled: state.isFormOpen,
                  })}
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      renameScheduleOpen: true,
                    }))
                  }
                />
              </Tooltip>
            )}
            {hasWritePermission && !state.isScheduleFormOpen && (
              <>
                <Typography variant="h6" className="mr-4 ml-4">
                  OR
                </Typography>
                <Button
                  disabled={state.schedule}
                  color="primary"
                  variant="contained"
                  startIcon={<PlaylistAddIcon />}
                  onClick={() => {
                    setState((prevState) => ({
                      ...prevState,
                      isAddingSchedule: true,
                      isScheduleFormOpen: true,
                    }));
                  }}
                >
                  Add new schedule
                </Button>
              </>
            )}
          </div>
          {state.schedule && (
            <div
              className={clsx("d-flex f-align-center", {
                "f-justify-end mt-4": appData.isTabletView,
              })}
            >
              <Typography variant="body1">Enter to search by:</Typography>
              <TextField
                disabled={
                  state.isFormOpen ||
                  state.isFetching ||
                  state.isFetchingList.fuelSurchargeSchedule
                }
                type="text"
                variant="outlined"
                className="ml-2"
                size="small"
                placeholder="Surcharge%"
                InputProps={{ endAdornment: <SearchIcon /> }}
                onChange={(evt) =>
                  handleSearch((evt.target.value || "").trim())
                }
              />
            </div>
          )}
        </div>
        {hasWritePermission
          ? !state.schedule &&
            !state.isScheduleFormOpen && (
              <Paper
                elevation={2}
                className={clsx(
                  "d-flex f-align-center f-justify-center mt-10 p-2 pl-4 pr-4 p-relative",
                  classes.paperHeight
                )}
              >
                <div className="d-flex flex-column f-justify-center f-align-center">
                  <Typography variant="h6">
                    No schedule selected, Please select schedule or Add new
                    schedule.
                  </Typography>
                </div>
              </Paper>
            )
          : !state.schedule &&
            !state.isScheduleFormOpen && (
              <Paper
                elevation={2}
                className={clsx(
                  "d-flex f-align-center f-justify-center mt-10 p-2 pl-4 pr-4 p-relative",
                  classes.paperHeight
                )}
              >
                <div className="d-flex flex-column f-justify-center f-align-center">
                  <Typography variant="h6">
                    No schedule selected, Please select schedule.
                  </Typography>
                </div>
              </Paper>
            )}
        <Paper
          elevation={2}
          className={clsx("p-4 mt-8 h-100", {
            "d-none": !state.schedule && !state.isScheduleFormOpen,
          })}
        >
          {state.schedule ? (
            <>
              <div
                className={clsx(
                  "d-flex f-align-center f-justify-between pb-4",
                  classes.actionsWrapper
                )}
              >
                <div>
                  {hasWritePermission && (
                    <>
                      {state.isFetching ||
                      state.isFetchingList.fuelSurchargeSchedule ? (
                        <Skeleton variant="rect" width={85} height={36} />
                      ) : (
                        <Button
                          startIcon={<PlaylistAddIcon />}
                          variant="outlined"
                          color="primary"
                          disabled={state.isFormOpen}
                          onClick={() => handleFormOpen(null, -1)}
                        >
                          New
                        </Button>
                      )}
                      {!!state.selectedRows.length && (
                        <Button
                          startIcon={
                            <DeleteForeverOutlinedIcon color="error" />
                          }
                          classes={{
                            root: "border-error ml-4",
                            label: "color-error",
                          }}
                          variant="outlined"
                          onClick={() =>
                            setState((prevState) => ({
                              ...prevState,
                              deletingEntryIds: state.selectedRows.join(","),
                            }))
                          }
                        >
                          Delete
                        </Button>
                      )}
                    </>
                  )}
                </div>
                <div className="d-flex f-align-center">
                  {state.isFetching ||
                  state.isFetchingList.fuelSurchargeSchedule ? (
                    <>
                      <Skeleton
                        variant="rect"
                        width={104}
                        height={30}
                        className="mr-4"
                      />
                      <Skeleton variant="rect" width={104} height={30} />
                    </>
                  ) : (
                    <>
                      {hasWritePermission && (
                        <Button
                          startIcon={<CloudUploadIcon />}
                          disabled={
                            state.isExchanging ||
                            state.isFormOpen ||
                            state.isTemplateDownloading
                          }
                          className="mr-4"
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            setState((prevState) => ({
                              ...prevState,
                              openUpload: true,
                            }))
                          }
                        >
                          Upload
                        </Button>
                      )}
                      <Button
                        startIcon={<CloudDownloadIcon />}
                        className="mr-4"
                        disabled={
                          state.isExchanging ||
                          state.isFormOpen ||
                          state.isAddingSchedule
                        }
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                          handleDownload(
                            "fuelSurchargeSchedules",
                            state.schedule
                          )
                        }
                      >
                        Download
                        {state.isExchanging && (
                          <CircularProgress
                            color="secondary"
                            size={24}
                            className={classes.progressBtn}
                          />
                        )}
                      </Button>
                      {hasWritePermission && (
                        <Button
                          startIcon={<CloudDownloadIcon />}
                          disabled={
                            state.isTemplateDownloading || state.isFormOpen
                          }
                          color="secondary"
                          variant="outlined"
                          onClick={() => {
                            handleDownload("fuelSurchargeSchedules", "", true);
                          }}
                        >
                          Download Template
                          {state.isTemplateDownloading && (
                            <CircularProgress
                              color="secondary"
                              size={24}
                              className={classes.progressBtn}
                            />
                          )}
                        </Button>
                      )}{" "}
                      <Tooltip placement="top-start" title="Filter">
                        <IconButton
                          color="primary"
                          className={clsx("ml-2", {
                            "bg-primary": !!state.pageFilters?.length,
                          })}
                          onClick={() => {
                            setState((prevState) => ({
                              ...prevState,
                              isFiltering: true,
                            }));
                          }}
                        >
                          <FilterListIcon
                            className={clsx({
                              "color-white": !!state.pageFilters?.length,
                            })}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </div>
              </div>
              <Grid
                columns={columnConfig}
                rows={state.entries.map((entry) => ({
                  ...entry,
                  className: clsx({
                    disabled: state.isFormOpen,
                  }),
                }))}
                actionBarConfig={null}
                isLoading={state.isFetching}
                isFormVisible={state.isFormOpen}
                onReady={(instance) => (gridHelper = instance)}
                totalRows={state.totalEntries}
                pageSize={state.pageSize}
                pageNumber={state.pageNumber}
                order={state.order}
                orderBy={state.orderBy}
                search={state.search}
                hasSelection={hasWritePermission}
                noRecordLabel={
                  (!state.search || state.isAddingSchedule) &&
                  !state.pageFilters.length && (
                    <Typography variant="h6" className="align-center">
                      Please add/upload at least one price range to create
                      schedule {appData.isTabletView ? <br /> : ""}'
                      {state.schedule}'.
                    </Typography>
                  )
                }
                onSelectionChange={handleSelectionChange}
                onPageNumberChange={handlePageNumberChange}
                onPageSizeChange={handlePageSizeChange}
                onSortChange={handleSortChange}
                hasPagination={!state.isFormOpen}
                rowEvents={[
                  {
                    type: "onDoubleClick",
                    handler: (row, rowIndex) =>
                      hasWritePermission && handleFormOpen(row, rowIndex),
                  },
                ]}
                classes={{
                  gridActions: "f-justify-end",
                }}
              />
            </>
          ) : (
            <ScheduleForm
              isAddingSchedule={state.isAddingSchedule}
              hasWritePermission={hasWritePermission}
              onNewSchedule={() =>
                setState((prevState) => ({
                  ...prevState,
                  isAddingSchedule: true,
                  isScheduleFormOpen: true,
                }))
              }
              onNext={(name = "") => {
                if (state.scheduleList.includes(name)) {
                  return toast.error(`Schedule "${name}" already exists.`);
                }
                setState((prevState) => ({
                  ...prevState,
                  scheduleList: [name, ...prevState.scheduleList],
                  isScheduleFormOpen: false,
                }));
                if (name) {
                  const timeStamp = Date.now();
                  sessionStorage.setItem(timeStamp, name);
                  history.push(`/fuel-price/schedule/${timeStamp}`);
                }
              }}
              onCancel={() => {
                setState((prevState) => ({
                  ...prevState,
                  isAddingSchedule: false,
                  isScheduleFormOpen: false,
                }));
              }}
            />
          )}
        </Paper>
        {state.openUpload && (
          <BulkUpload
            open={state.openUpload}
            scheduleName={state.schedule}
            uploadKey="fuelSurchargeSchedules"
            onClose={(isUploaded = false) => {
              setState((prevState) => ({
                ...prevState,
                openUpload: false,
              }));
              if (isUploaded) {
                setState((prevState) => ({
                  ...prevState,
                  isAddingSchedule: false,
                }));
                fetchEntries(
                  state.search,
                  state.pageSize,
                  state.pageNumber,
                  state.order,
                  state.orderBy,
                  state.schedule,
                  state.pageFilters
                );
                fetchFuelSurchargeSchedule(state.schedule);
              }
            }}
            onSheetError={(sheetError) =>
              setState((prevState) => ({ ...prevState, sheetError }))
            }
          />
        )}
        {!!state.sheetError && (
          <ErrorModal
            open={!!state.sheetError}
            error={state.sheetError}
            onClose={() =>
              setState((prevState) => ({
                ...prevState,
                sheetError: null,
              }))
            }
          />
        )}
        {state.deletingEntryIds && (
          <ActionDialog
            classes={{
              confirm: "bg-danger",
              paper: classes.paperSize,
            }}
            open={!!state.deletingEntryIds}
            contentText="Are you sure you want to delete?"
            onConfirm={() => handleDelete(state.deletingEntryIds)}
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                deletingEntryIds: null,
              }))
            }
            isConfirmDisabled={state.isLoading}
            positiveActionLabel={
              <>
                Delete
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}
        {state.renameScheduleOpen && (
          <RenameScheduleForm
            id={state.entries[0]?.id}
            schedule={state.schedule}
            open={state.renameScheduleOpen}
            fetchFuelSurchargeSchedule={fetchFuelSurchargeSchedule}
            onClose={(isSubmitted = false, renamedSchedule = "") => {
              setState((prevState) => ({
                ...prevState,
                renameScheduleOpen: false,
              }));
              if (typeof isSubmitted === "boolean" && isSubmitted) {
                setState((prevState) => {
                  if (renamedSchedule) {
                    const timeStamp = Date.now();
                    sessionStorage.setItem(timeStamp, renamedSchedule);
                    history.push(`/fuel-price/schedule/${timeStamp}`);
                  }

                  return {
                    ...prevState,
                    schedule: prevState.isAddingSchedule
                      ? renamedSchedule
                      : prevState.schedule,
                    scheduleList: prevState.isAddingSchedule
                      ? prevState.scheduleList.map((item) =>
                          item === state.schedule ? renamedSchedule : item
                        )
                      : prevState.scheduleList,
                  };
                });
              }
            }}
          />
        )}
        {state.isFiltering && (
          <FilterComponent
            isFiltering={state.isFiltering}
            filterConfig={filterConfig}
            appliedFilters={state.pageFilters}
            onFilterApply={handleFilterChange}
            onClose={() => {
              setState((prevState) => ({
                ...prevState,
                isFiltering: false,
              }));
            }}
          />
        )}
        {state.openForceSubmitDialog && (
          <ForceSubmitDialog
            open={state.openForceSubmitDialog}
            pendingItems={appData.pendingItems.map((date, index) => ({
              date,
              id: index,
            }))}
            onClose={handleClose}
            kamId={kamId}
            forceSubmitReasonList={state.forceSubmitReasonList}
            onForceSubmit={() => fetchList("pendingItems", kamId)}
          />
        )}
      </div>
    </div>
  );
};

export default FuelSurchargeSchedule;
