import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  inlineEditPaper: {
    width: 450,
    minHeight: 300,
  },
}));

export default useStyles;
