import { createMuiTheme, darken } from "@material-ui/core/styles";

export default createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#004700",
    },
    secondary: {
      main: "#470047",
    },
    background: {
      primary: {
        light: "#004700",
        main: darken("#004700", 0.2),
        dark: darken("#004700", 0.4),
      },
      secondary: {
        light: "53ACCF#",
        main: darken("#53ACCF", 0.2),
        dark: darken("#53ACCF", 0.4),
      },
      warning: {
        light: "#ffb74d",
        main: darken("#ffb74d", 0.2),
        dark: darken("#ffb74d", 0.4),
      },
      error: {
        light: "#e57373",
        main: darken("#e57373", 0.2),
        dark: darken("#e57373", 0.4),
      },
    },
  },
  spacing: 4,
});
