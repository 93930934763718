import makeStyles from "@material-ui/core/styles/makeStyles";
import { maxHeight } from "@material-ui/system";

const useStyles = makeStyles((theme) => ({
  fixedHeader: {
    width: "100%",
    top: "64px",
    height: "75px",
    zIndex: 1000,
    position: "sticky",
    background: "#fafafa",
    marginBottom: "6px",
  },
  mapStyles: {
    width: "94%",
    height: "80%",
  },
  field: {
    width: 200,
    marginBottom: 0,
    marginRight: 10,
    marginTop: 10,
  },
  phone: {
    marginLeft: "0px !important",
    width: "48.6% !important",
    marginRight: "8px !important",
  },
  manualField: {
    width: "100%",
    marginRight: "8px",
  },
  GpsFixedIconColors: {
    // height: "37px",
    // padding: "7px",
    // marginTop: "12px",
    // marginLeft: "0px !important",
    // marginRight: "0px !important",
    color: "white",
  },
  mapTablet: {
    width: "94%",
    height: "calc(100vh - 400px)",
    position: "absolute",
  },
  fields: {
    width: 232,
  },
  searchFields: {
    width: 214,
    marginRight: 8,
    marginTop: 8,
  },
  searchField: {
    width: 214,
    marginBottom: 12,
    marginRight: 8,
    marginTop: 10,
  },
  adornmentClass: {
    marginRight: -12,
  },
  boxStyle: {
    px: "42px",
    py: "7px",
    backgroundColor: "grey.50",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  tabsStyle: {
    // marginTop: ".8rem",
    background: "#eeeeee",
    maxWidth: "22.5rem",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    border: "2px solid primary",
  },
  boxPaperStyle: {
    width: 320,
    boxShadow: "none",
  },
  paperStyle: {
    width: "1100px",
    height: "calc(100vh - 188px)",
    boxShadow: "none",
  },
  searchButton: {
    borderRadius: "28px",
    marginRight: "4px",
    height: "29px",
    width: "5px",
    minWidth: "10px",
  },
  searchIcon: { color: "white" },
  saveButton: { height: 38, marginTop: "10px" },
  map: { width: "96%", height: "calc(100vh - 312px)", position: "absolute" },
  StreetAddress: {
    marginLeft: "0px !important",
    width: "100% !important",
    marginRight: "8px !important",
  },
  GpsFixedIconColor: {
    height: "37px",
    padding: "7px",
    marginTop: "12px",
    marginLeft: "0px !important",
    marginRight: "0px !important",
    color: "white",
  },
  city: { marginLeft: "8px !important", width: "100% !important" },
  state: {
    marginLeft: "0px !important",
    width: "100% !important",
    marginRight: "8px !important",
  },
  zip: { marginLeft: "8px !important", width: "100% !important" },
  country: {
    marginLeft: "0px !important",
    width: "100% !important",
    marginRight: "8px !important",
  },
  phoneNumber: {
    marginLeft: "8px !important",
    width: "100% !important",
  },
  addressType: {
    width: "100% !important",
    marginBottom: "10px !important",
  },
  dialog: {
    "& .MuiDialogTitle-root": {
      paddingBottom: "0px",
      paddingTop: "5px",
    },
    "& .MuiDialogContent-root": {
      overflow: "hidden",
    },
    minWidth: "700px",
    maxWidth: "1200px !important",
    height: "calc(100vh - 188px)",
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },

  paperWidthSm: {
    maxWidth: "none",
    height: "calc(100vh - 100px)",
  },
  paperWidth: {
    maxWidth: "none",
    height: "500px",
  },
  contact: {
    marginLeft: "0px !important",
    width: "100% !important",
    marginRight: "4px !important",
  },

  searchBox: {
    width: 264,
    marginBottom: 12,
    marginRight: 4,
    position: "absolute",
    top: "10px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "300px",
    height: "40px",
    borderRadius: "5px",
    border: "1px solid gray",
    padding: "5px",
    backgroundColor: "white",
    zIndex: "1300",
  },
}));

export default useStyles;
