import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minWidth: (props) => props.paperminWidth,
    minHeight: (props) => props.paperMinHeight,
  },
}));

export default useStyles;
