import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import { noop, PAGE_KEYS, VALIDATIONS } from "shared/constants";
import Service from "../service";
import { AppContext } from "shared/contexts";
import {
  Grid as DataGrid,
  Dropdown,
  Dialog,
  FilterComponent,
  Datepicker,
  ActionDialog,
} from "shared/components";
import {
  getPageConfig,
  setPageConfig,
  queryStringBuilderNew,
  updatePagination,
  updateLayout,
  getDateString,
  getUTCDateString,
  getFormattedTime,
  updatePageConfig,
  validator,
  getTokenData,
} from "utils";
import Button from "@material-ui/core/Button";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import FilterListIcon from "@material-ui/icons/FilterList";
import Switch from "@material-ui/core/Switch";
import FlagIcon from "@material-ui/icons/Flag";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TuneIcon from "@material-ui/icons/Tune";
import useStyles from "./style";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { SharedService } from "modules/shared";
import { Chip, CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import { useLocation } from "react-router-dom";
import WarningIcon from "@material-ui/icons/Warning";
import GenerateDCNPdf from "../generate-DCN-history-Pdf";
import pdfIcon from "../../../assets/icons/pdfDownloadIcon.svg";
import PostAddIcon from "@material-ui/icons/PostAdd";
import AddIcon from "@material-ui/icons/Add";
import { ROLE } from "modules/shared/constants";

let gridHelper = null;
let isConfirmDisabled = false;

const defaultState = {
  entries: [],
  detailedEntries: [],
  totalEntries: 0,
  isFetching: false,
  dcnDetailedHistory: {},
  viewHistory: false,
  pageFilters: [],
  selectedRows: [],
  showConfirmationPopup: false,
  isDataFetched: false,
  isFiltering: false,
  settlementCompany: null,
  settlementCompanyList: [],
  userList: [],
  users: [],
  setUniqueDcnUsersEmailList: [],
  payDate: null,
  sentDate: null,
  metadata: "",
  isLoading: false,
  filters: {
    settlementCompany: [],
  },
  isAddButtonEnabled: false,
  showAddLookupDialog: false,
  isLookupLoading: false,
  dcnUsersList: [],
  email: "",
  newEmail: [],
  isRevertingData: false,
  errors: {
    email: " ",
  },
  isFetchingList: {
    settlementCompany: false,
    userNameList: false,
    dcnUsers: false,
  },
  pageSize: 100,
  testId: "",
  pageNumber: 1,
  order: null,
  orderBy: null,
  detailedOrder: null,
  detailedOrderBy: null,
  search: "",
  dynamicColumns: {
    select_all: { label: "Select All", value: "select_all", isSelected: true },
    SettlementCompany: {
      label: "Settlement Company",
      value: "SettlementCompany",
      isSelected: true,
    },
    UploadDate: {
      label: "Sent Date & Time",
      value: "UploadDate",
      isSelected: true,
    },
    SentBy: {
      label: "Sent By",
      value: "SentBy",
      isSelected: true,
    },
    PayDate: {
      label: "Pay Date",
      value: "PayDate",
      isSelected: true,
    },
    Emails: {
      label: "DCN Emails",
      value: "Emails",
      isSelected: true,
    },
    UploadId: {
      label: "Upload Id",
      value: "UploadId",
      isSelected: true,
    },
    StartDate: {
      label: "Start Date",
      value: "StartDate",
      isSelected: true,
    },
    EndDate: {
      label: "End Date",
      value: "EndDate",
      isSelected: true,
    },
    IsReverted: {
      label: "Is Reverted",
      value: "IsReverted",
      isSelected: true,
    },
    RevertedBy: {
      label: "Reverted By",
      value: "RevertedBy",
      isSelected: true,
    },
  },
  detailedDynamicColumns: {
    select_all: { label: "Select All", value: "select_all", isSelected: true },
    ICID: { label: "ICID", value: "ICID", isSelected: true },
    DeptNum: {
      label: "Dept Num",
      value: "DeptNum",
      isSelected: true,
    },
    LastName: { label: "Last Name", value: "LastName", isSelected: true },
    FirstName: { label: "First Name", value: "FirstName", isSelected: true },
    SSN: {
      label: "SSN",
      value: "SSN",
      isSelected: true,
    },
    EIN: {
      label: "EIN",
      value: "EIN",
      isSelected: true,
    },
    CoName: {
      label: "IC Company Name",
      value: "CoName",
      isSelected: true,
    },
    Settlement: {
      label: "Settlement",
      value: "Settlement",
      isSelected: true,
    },
    FuelReimbursement: {
      label: "Fuel Reimbursement",
      value: "FuelReimbursement",
      isSelected: true,
    },
    TollReimbursement: {
      label: "Toll Reimbursement",
      value: "TollReimbursement",
      isSelected: true,
    },
    MileageReimbursement: {
      label: "Mileage Reimbursement",
      value: "MileageReimbursement",
      isSelected: true,
    },
    Reimbursement: {
      label: "Reimbursement",
      value: "Reimbursement",
      isSelected: true,
    },
    MiscDeduction: {
      label: "Misc Deduction",
      value: "MiscDeduction",
      isSelected: true,
    },
    Misc2Deduction: {
      label: "Misc2 Deduction",
      value: "Misc2Deduction",
      isSelected: true,
    },
    FuelAdvanceDeduction: {
      label: "Fuel Advance Deduction",
      value: "FuelAdvanceDeduction",
      isSelected: true,
    },
    FuelAdvance2: {
      label: "Fuel Advance2",
      value: "FuelAdvance2",
      isSelected: true,
    },
    CashAdvanceDeduction: {
      label: "Cash Advance Deduction",
      value: "CashAdvanceDeduction",
      isSelected: true,
    },
    CashAdvance2Deduction: {
      label: "Cash Advance2 Deduction",
      value: "CashAdvance2Deduction",
      isSelected: true,
    },
    ClaimDeduction: {
      label: "Claim Deduction",
      value: "ClaimDeduction",
      isSelected: true,
    },
    Claim2Deduction: {
      label: "Claim2 Deduction",
      value: "Claim2Deduction",
      isSelected: true,
    },
    Claim3Deduction: {
      label: "Claim3 Deduction",
      value: "Claim3Deduction",
      isSelected: true,
    },
    Rental1Deduction: {
      label: "Rental1 Deduction",
      value: "Rental1Deduction",
      isSelected: true,
    },
    Rental2Deduction: {
      label: "Rental2 Deduction",
      value: "Rental2Deduction",
      isSelected: true,
    },
    Rental3Deduction: {
      label: "Rental3 Deduction",
      value: "Rental3Deduction",
      isSelected: true,
    },
    Rental4Deduction: {
      label: "Rental4 Deduction",
      value: "Rental4Deduction",
      isSelected: true,
    },
    EOR: {
      label: "EOR",
      value: "EOR",
      isSelected: true,
    },
  },
  dateFilterType: "single",
  formAlertFields: {},
  fieldAlert: {
    show: false,
    id: null,
    isLoading: false,
    subHeading: "",
    configs: [],
    updateValidations: noop,
    onChange: noop,
  },
};

const DcnHistory = ({ match, history }) => {
  const pageConfig = getPageConfig(
    PAGE_KEYS.DCN_HISTORY || PAGE_KEYS.DCN_DETALIED_HISTORY
  );
  const userData = getTokenData();
  const isAdminRole = (userData.role || "").toLowerCase() === ROLE.ADMIN;
  const classes = useStyles();
  const { appData } = useContext(AppContext);
  const location = useLocation();
  const data = location.state || {};
  const [state, setState] = useState({
    ...defaultState,
    pageSize: pageConfig?.pageSize || defaultState.pageSize,
    pageNumber: pageConfig?.pageNumber || defaultState.pageNumber,
    pageFilters:
      (pageConfig && pageConfig?.filters) || defaultState.pageFilters,
    payDate:
      pageConfig?.payDateFilter === undefined ||
      pageConfig?.payDateFilter === null
        ? getDateString(data.pay_date) || defaultState.payDate
        : (pageConfig && pageConfig?.payDateFilter) || defaultState.payDate,
    sentDate:
      pageConfig?.sentDateFilter === undefined ||
      pageConfig?.sentDateFilter === null
        ? getDateString(data.sent_at) || defaultState.sentDate
        : (pageConfig && pageConfig?.sentDateFilter) || defaultState.sentDate,
    dynamicColumns: pageConfig?.dynamicColumns || defaultState.dynamicColumns,
    detailedDynamicColumns:
      pageConfig?.detailedDynamicColumns || defaultState.detailedDynamicColumns,
    settlementCompany:
      (pageConfig && pageConfig?.settlementCompanyFilter?.value) ||
      defaultState.settlementCompany,
    filters: {
      ...defaultState.filters,
      settlementCompany:
        (pageConfig &&
          pageConfig?.settlementCompanyFilter?.settlementCompany) ||
        [],
    },
  });

  const handleFilterChange = useCallback((pageFilters = []) => {
    setState((prevState) => ({
      ...prevState,
      pageFilters,
    }));
    setPageConfig(PAGE_KEYS.DCN_HISTORY, {
      filters: pageFilters,
    });
  }, []);

  const fetchSettlementCompanyList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        settlementCompany: true,
      },
    }));

    const { data, error } = await SharedService.getSettlementCompanyList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          settlementCompany: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      settlementCompanyList:
        data?.rows.filter((settlementCompany) => settlementCompany.is_active) ||
        defaultState.settlementCompanyList,
      isFetchingList: {
        ...prevState.isFetchingList,
        settlementCompany: false,
      },
    }));
  };

  const handleSubmit = async () => {
    setState((prevState) => ({
      ...prevState,
      isRevertingData: true,
    }));
    const emailArray = state?.newEmail.map(({ email }) => email);
    const uniqueEmails = [...new Set(emailArray)];
    const emailString = uniqueEmails?.join(",");

    const payload = {
      historyId: state.viewHistory?.id,
      dcnEmails: emailString,
    };
    const { error } = await Service.revertDCN(payload);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isRevertingData: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }

    toast.success("Data reverted successfully.");

    setState((prevState) => ({
      ...prevState,
      isRevertingData: false,
      showConfirmationPopup: false,
      email: defaultState.email,
      newEmail: defaultState.newEmail,
      viewHistory: false,
    }));

    fetchEntries(
      state.payDate,
      state.sentDate,
      state.search,
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    );
  };

  const getFieldValidatorMap = (state, field) => {
    const fieldValidatorMap = {
      email: [{ type: VALIDATIONS.EMAIL, value: true }],
    };
    return fieldValidatorMap;
  };

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = getFieldValidatorMap(state, field);

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const handleFieldChange = (evt) => {
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    let errorMessage = "";

    if (field === "email") {
      if (
        state.setUniqueDcnUsersEmailList.includes(value) ||
        state.newEmail.some((obj) => obj.email === value)
      ) {
        errorMessage = "This email has already been entered.";
      } else {
        errorMessage = validate(field, value);
      }
      isConfirmDisabled = !!errorMessage;
      setState((prevState) => ({
        ...prevState,
        isAddButtonEnabled: value?.length > 0 ? true : false,
        [field]: value,
        errors: {
          ...prevState.errors,
          [field]: errorMessage,
        },
        isConfirmDisabled,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [field]: value,
        errors: {
          ...prevState.errors,
          [field]: validate(field, value) || " ",
        },
      }));
    }
  };

  const handleShowAddLookupDialog = () => {
    setState((prevState) => ({
      ...prevState,
      showAddLookupDialog: true,
    }));
  };

  const handleAddEmail = () => {
    if (state.email?.trim()) {
      setState((prevState) => ({
        ...prevState,
        newEmail: [
          ...prevState.newEmail,
          { id: Date.now(), email: state?.email?.trim() },
        ],
        email: defaultState.email,
        isAddButtonEnabled: false,
        showAddLookupDialog: false,
      }));
    }
  };

  const handleDeleteEmail = (id) => {
    setState((prevState) => {
      const filteredEmails = prevState.newEmail.filter(
        (email) => email.id !== id
      );
      const emailExists = filteredEmails.some(
        (obj) => obj.email === prevState.email
      );
      const isAddButtonEnabled = emailExists;
      isConfirmDisabled = isAddButtonEnabled;
      return {
        ...prevState,
        newEmail: filteredEmails,
        errors: {
          email: emailExists ? prevState.errors.email : " ",
        },
        isAddButtonEnabled: isAddButtonEnabled,
      };
    });
  };

  const addLookupValue = async () => {
    setState((prevState) => ({ ...prevState, isLookupLoading: true }));

    const payload = {
      type: "DCN Email Recipients",
      value: state.email,
      is_active: true,
      description: "description",
    };

    const { error } = await Service.addEmailInLookup(payload);

    if (error) {
      handleAddEmail();
      setState((prevState) => ({ ...prevState, isLookupLoading: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success(`Value added in Lookup(DCN Email Recipients) successfully.`);
    setState((prevState) => ({
      ...prevState,
      isLookupLoading: false,
      showAddLookupDialog: false,
      isAddButtonEnabled: false,
    }));
  };

  const fetchDCNUsersList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        dcnUsers: true,
      },
    }));

    const { data, error } = await SharedService.getDCNUsersList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          dcnUsers: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      showAddLookupDialog: false,
      email: defaultState.email,
      dcnUsersList:
        data?.rows.filter((dcnUsers) => dcnUsers.is_active) ||
        defaultState.dcnUsersList,
      isFetchingList: {
        ...prevState.isFetchingList,
        dcnUsers: false,
      },
    }));
  };

  const fetchUserList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        userNameList: true,
      },
    }));

    const { data, error } = await SharedService.getUsersList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          userNameList: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      users: data?.users || defaultState.users,
      userList:
        data?.users.filter(
          (user) =>
            user.is_active && (user.role === "ADMIN" || user.role === "AA")
        ) || defaultState.userList,
      isFetchingList: {
        ...prevState.isFetchingList,
        userNameList: false,
      },
    }));
  };

  const fetchEntries = useCallback(
    async (
      payDate,
      sentDate,
      search,
      filters,
      pageSize,
      pageNumber,
      order,
      orderBy,
      pageFilters = [],
      hasLoader = true
    ) => {
      setState((prevState) => ({
        ...prevState,
        isFetching: hasLoader,
      }));

      let filtersArr = [
        ...pageFilters.map((filter) => ({
          ...filter,
          value: filter?.value?.value || filter?.value,
        })),
      ];
      payDate &&
        filtersArr.push({
          field: "pay_date",
          type: "=",
          value: payDate,
        });
      if (
        filters.settlementCompany.length &&
        filters.settlementCompany[0].value
      ) {
        filtersArr = [...filtersArr, ...filters.settlementCompany];
      }
      let queryString = queryStringBuilderNew(
        pageSize,
        pageSize * (pageNumber - 1),
        [],
        filtersArr,
        { field: orderBy, order },
        search && search.length > 0
      );

      if (sentDate?.length > 0) {
        queryString += `&filter[where][sent_at][lte]=${sentDate} 23:59:59.999999`;
      }
      if (sentDate?.length > 0) {
        queryString += `&filter[where][sent_at][gte]=${sentDate} 00:00:00`;
      }

      const { data, error } = await Service.getDcnHistory(queryString);
      if (error) {
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0].message : error.message
        );
      }

      setState((prevState) => ({
        ...prevState,
        data,
        isFetching: false,
        entries: data.result || defaultState.entries,
        totalEntries: data.count,
      }));

      return data;
    },
    []
  );

  const fetchDetailedEntries = useCallback(
    async (recordId, hasLoader = true) => {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
        isFetching: hasLoader,
      }));

      const { data, error } = await Service.getHistoryDetailsByID(recordId);
      if (error) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          isFetching: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0].message : error.message
        );
      }
      const parsedOriginalData = JSON.parse(data.original_data);
      const parsedPostData = JSON.parse(data.post_data);

      const originalICData = parsedOriginalData?.clientdata?.icdata || [];
      const postICData = parsedPostData?.clientdata?.icdata || [];

      const uniqueICIDs = postICData?.map((entry) => entry.ICID);
      const uniqueEntries = originalICData?.filter(
        (entry) => uniqueICIDs.indexOf(entry.ICID) > -1
      );

      const mergedICData = uniqueEntries.map((originalItem, index) => {
        const postItem = postICData[index];

        if (!postItem) {
          return { originalData: originalItem };
        }

        const filteredPostItem = { ...postItem };
        delete filteredPostItem?.startDate;
        delete filteredPostItem?.endDate;
        delete filteredPostItem?.settlementCompany;

        const isDifferent =
          JSON.stringify(originalItem) != JSON.stringify(filteredPostItem);
        if (isDifferent) {
          return {
            originalData: originalItem,
            postData: postItem,
            isDifferent: true,
          };
        }

        return { originalData: originalItem };
      });

      setState((prevState) => ({
        ...prevState,
        data,
        isFetching: false,
        isLoading: false,
        metadata: data?.metadata || defaultState.metadata,
        dcnDetailedHistory: data || defaultState.dcnDetailedHistory,
        detailedEntries: mergedICData || defaultState.detailedEntries,
      }));

      return data;
    },
    []
  );

  const filterConfig = useMemo(
    () => [
      {
        field: "upload_id",
        fieldToDisplay: "Upload Id",
        operatorType: "iLike",
      },
      {
        field: "sent_by_name",
        fieldToDisplay: "Sent By",
        operatorType: "regexp",
        options: state.userList.map((row) => ({
          label: `${row.first_name} ${row.last_name} (${row.username})` || "-",
          value: `${row.first_name} ${row.last_name}`,
        })),
      },
    ],
    [state.userList]
  );

  useEffect(() => {
    fetchSettlementCompanyList();
    fetchUserList();
  }, []);

  useEffect(() => {
    fetchEntries(
      state.payDate,
      state.sentDate,
      state.search,
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    ).then((data) => {
      updatePagination(
        data?.count,
        { pageNumber: state.pageNumber, pageSize: state.pageSize },
        (pageNumber) => {
          setState((prevState) => ({ ...prevState, pageNumber }));
        }
      );
    });
  }, [
    fetchEntries,
    state.payDate,
    state.sentDate,
    state.filters,
    state.pageSize,
    state.pageNumber,
    state.order,
    state.orderBy,
    state.search,
    state.pageFilters,
  ]);

  useEffect(() => {
    setTimeout(updateLayout, 1000);
  }, [state.entries, state.detailedEntries]);

  const handlePageNumberChange = useCallback((pageNumber) => {
    setPageConfig(PAGE_KEYS.DCN_HISTORY, {
      pageNumber,
    });
    setState((prevState) => ({
      ...prevState,
      pageNumber,
      selectedRows: defaultState.selectedRows,
    }));
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setPageConfig(PAGE_KEYS.DCN_HISTORY || PAGE_KEYS.DCN_DETALIED_HISTORY, {
      pageSize,
    });
    setState((prevState) => ({
      ...prevState,
      pageSize,
      pageNumber: defaultState.pageNumber,
      selectedRows: defaultState.selectedRows,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleSelectionChange = useCallback((selectedRows) => {
    setState((prevState) => ({
      ...prevState,
      selectedRows,
    }));
  }, []);

  const handleSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      order: order,
      orderBy: fieldObj.field || fieldObj.fieldName,
      selectedRows: defaultState.selectedRows,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleDetailedSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      detailedOrder: order,
      detailedOrderBy: fieldObj.field || fieldObj.fieldName,
    }));
  }, []);

  const moreActions = (row) => {
    return (
      <div className="d-flex f-align-center">
        <Tooltip title="View history in details" placement="top-start">
          <VisibilityIcon
            className={clsx("ml-2 mr-2 c-pointer", classes.VisibilityIconColor)}
            onClick={() => {
              fetchDetailedEntries(row.id);
              setState((prevState) => ({ ...prevState, viewHistory: row }));
            }}
          />
        </Tooltip>
      </div>
    );
  };

  const columnConfig = [
    {
      label: "View details",
      id: "col-edit",
      noResize: true,
      render: (row) => moreActions(row),
    },
    {
      isHidden: !state.dynamicColumns?.SettlementCompany?.isSelected,
      headerClassName: classes.dcnHistoryCloumnTitle,
      id: "settlement_co_name",
      label: "Settlement Company",
      field: "settlement_co_name",
      canSort: true,
      hasEllipses: true,
      render: (row) => {
        return (
          <Tooltip
            title={
              `${row.settlementCompany?.value}, ${row.settlementCompany?.description}` ??
              ""
            }
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.settlementCompany?.value || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.UploadDate?.isSelected,
      headerClassName: classes.dcnHistoryCloumnTitle,
      id: "sent_at",
      label: "Sent Date & Time",
      field: "sent_at",
      hasEllipses: true,
      canSort: true,
      render: (row) => {
        const uploadDate =
          `${getUTCDateString(row.sent_at)} ${getFormattedTime(
            new Date(row.sent_at)
          )}` || "";
        return (
          <Tooltip title={uploadDate ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {uploadDate}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.SentBy?.isSelected,
      headerClassName: classes.dcnHistoryCloumnTitle,
      id: "sent_by_name",
      label: "Sent By",
      field: "sent_by_name",
      canSort: true,
      hasEllipses: true,
      render: (row) => {
        return (
          <Tooltip
            title={
              `${row.sentBy.first_name} ${row.sentBy.last_name} (${row.sentBy.username})` ??
              ""
            }
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {`${row.sentBy.first_name} ${row.sentBy.last_name} (${row.sentBy.username})` ||
                "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.PayDate?.isSelected,
      id: "pay_date",
      label: "Pay Date",
      field: "pay_date",
      canSort: true,
      render: (row) => {
        return (
          <Tooltip
            title={getUTCDateString(row.pay_date) ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {getUTCDateString(row.pay_date) || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.Emails?.isSelected,
      headerClassName: classes.dcnHistoryCloumnTitle,
      id: "emails",
      label: "DCN Emails",
      field: "emails",
      hasEllipses: true,
      // canSort: true,
      render: (row) => {
        const dcnEmails = row.metadata?.dcnEmails;
        return (
          <Tooltip
            title={
              dcnEmails
                ?.map(
                  (item, index) =>
                    `${item}${index !== dcnEmails?.length - 1 ? ",\n" : ""}`
                )
                ?.join("") ?? ""
            }
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {dcnEmails?.length > 0
                ? `${dcnEmails?.[0]?.split("@")[0]}@${dcnEmails?.[0]
                    ?.split("@")[1]
                    ?.slice(0, 3)}...`
                : "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.UploadId?.isSelected,
      headerClassName: classes.dcnHistoryCloumnTitle,
      id: "upload_id",
      label: "Upload Id",
      field: "upload_id",
      // canSort: true,
      hasEllipses: true,
      render: (row) => {
        return (
          <Tooltip title={row.upload_id ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.upload_id || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.StartDate?.isSelected,
      id: "fromDate",
      label: "Start Date",
      field: "fromDate",
      // canSort: true,
      render: (row) => {
        return (
          <Tooltip title={row.metadata?.fromDate ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.metadata?.fromDate || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.EndDate?.isSelected,
      id: "toDate",
      label: "End Date",
      field: "toDate",
      render: (row) => {
        return (
          <Tooltip title={row.metadata?.toDate ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.metadata?.toDate || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.IsReverted?.isSelected,
      id: "is_reverted",
      label: "Is Reverted",
      field: "is_reverted",
      render: (row) => {
        const isReverted = !!row.is_reverted ? "Yes" : "No";
        return (
          <Tooltip title={isReverted} placement="top-start">
            <Typography variant="body2" noWrap>
              {isReverted}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.RevertedBy?.isSelected,
      id: "reverted_by",
      label: "Reverted By",
      field: "reverted_by",
      render: (row) => {
        const revertedBy = state.users.find(
          (item) => item?.id === row?.reverted_by
        );
        let user = "";

        if (revertedBy) {
          user = `${revertedBy?.first_name} ${revertedBy?.last_name || ""} (${
            revertedBy?.username
          })`;
        }

        return (
          <Tooltip title={user ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {user || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  const detailedColumnConfig = [
    // {
    //   isHidden: state.detailedEntries?.every(
    //     (entry) => !entry.hasOwnProperty("postData")
    //   ),
    //   canSort: true,
    //   render: (row) => {
    //     return (
    //       <>
    //         <Tooltip title={"Original"} placement="top-start">
    //           <Typography variant="body2" noWrap>
    //             {"Original"}
    //           </Typography>
    //         </Tooltip>
    //         <Tooltip title={"Updated"} placement="top-start">
    //           <Typography
    //             variant="body2"
    //             noWrap
    //             className={classes.updatedData}
    //           >
    //             {"Updated"}
    //           </Typography>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
    {
      headerClassName: classes.holdPaddingLeft,
      id: "hold",
      label: "Hold",
      field: "hold",
      render: (row) => {
        const isDifferent = row.isDifferent;
        if (!isDifferent) {
          return (
            <Tooltip
              title={
                row.postData?.paydata[0].hold === "0" ? "Don't Hold" : "Hold"
              }
              placement="top-start"
            >
              <Switch
                size="small"
                checked={row.postData?.paydata[0].hold === "1"}
                color="primary"
                style={{ cursor: "default" }}
              />
            </Tooltip>
          );
        }
        return (
          <>
            <Tooltip
              title={
                row.postData?.paydata[0].hold === "0" ? "Don't Hold" : "Hold"
              }
              placement="top-start"
            >
              <Switch
                size="small"
                checked={row.postData?.paydata[0].hold === "1"}
                color="primary"
                style={{ cursor: "default" }}
              />
            </Tooltip>
            <Typography variant="body2" noWrap className={classes.upDatedRow}>
              {""}
            </Typography>
          </>
        );
      },
    },
    {
      isHidden: !state.detailedDynamicColumns?.ICID?.isSelected,
      headerClassName: classes.icidFlagTitle,
      id: "originalData.ICID",
      label: "ICID",
      field: "originalData.ICID",
      canSort: true,
      render: (row) => {
        const originalData = row.originalData;
        const postData = row.postData;
        const isDifferent = row.isDifferent;
        const flagLabel =
          (originalData?.isFlagged === "True" &&
            originalData?.isCautiousFlagged === "True") ||
          originalData?.isFlagged === "True" ? (
            <FlagIcon style={{ color: "red" }} />
          ) : originalData?.isCautiousFlagged === "True" ? (
            <div className={classes.warningIconHeight}>
              <WarningIcon className={classes.warningIcon} />
              <div className={classes.exclamationMark}></div>
            </div>
          ) : (
            ""
          );

        const flagTitles = `Flagged Reason: ${originalData.flaggedReason}`;

        if (!isDifferent) {
          return (
            <Fragment>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Tooltip title={flagTitles} placement="top-start">
                  <Typography noWrap variant="body2">
                    {flagLabel}
                  </Typography>
                </Tooltip>
                <div
                  className={
                    originalData?.isFlagged === "True" ||
                    originalData?.isCautiousFlagged === "True"
                      ? ""
                      : "ml-5"
                  }
                >
                  <Tooltip
                    title={originalData.ICID ?? ""}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {originalData.ICID || "-"}
                    </Typography>
                  </Tooltip>
                </div>
              </div>
            </Fragment>
          );
        }
        return (
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <div>
              <Tooltip title={flagTitles} placement="top-start">
                <Typography noWrap variant="body2">
                  {flagLabel}
                </Typography>
              </Tooltip>
              <Typography variant="body2" noWrap className={classes.upDatedRow}>
                {""}
              </Typography>
            </div>
            <div
              className={
                originalData.isFlagged === "True" ||
                originalData?.isCautiousFlagged === "True"
                  ? ""
                  : "ml-5"
              }
            >
              <Tooltip title={originalData.ICID ?? ""} placement="top-start">
                <Typography variant="body2" noWrap>
                  {originalData.ICID || "-"}
                </Typography>
              </Tooltip>
              <Typography variant="body2" noWrap className={classes.upDatedRow}>
                {""}
              </Typography>
            </div>
          </div>
        );
      },
    },
    {
      isHidden: !state.detailedDynamicColumns?.DeptNum?.isSelected,
      id: "originalData.DeptNum",
      label: "Dept Num",
      field: "originalData.DeptNum",
      render: (row) => {
        const originalData = row.originalData;
        const postData = row.postData;
        const isDifferent = row.isDifferent;

        if (!isDifferent) {
          return (
            <Tooltip title={originalData.DeptNum ?? ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {originalData.DeptNum}
              </Typography>
            </Tooltip>
          );
        }
        return (
          <>
            <Tooltip title={originalData.DeptNum ?? ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {originalData.DeptNum}
              </Typography>
            </Tooltip>
            <Typography variant="body2" noWrap className={classes.upDatedRow}>
              {""}
            </Typography>
          </>
        );
      },
    },

    {
      isHidden: !state.detailedDynamicColumns?.LastName?.isSelected,
      id: "originalData.LastName",
      label: "Last Name",
      field: "originalData.LastName",
      canSort: true,
      render: (row) => {
        const originalData = row.originalData;
        const postData = row.postData;
        const isDifferent = row.isDifferent;

        if (!isDifferent) {
          return (
            <Tooltip title={originalData.LastName ?? ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {originalData.LastName || "-"}
              </Typography>
            </Tooltip>
          );
        }
        return (
          <>
            <Tooltip title={originalData.LastName ?? ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {originalData.LastName || "-"}
              </Typography>
            </Tooltip>
            <Typography variant="body2" noWrap className={classes.upDatedRow}>
              {""}
            </Typography>
          </>
        );
      },
    },
    {
      isHidden: !state.detailedDynamicColumns?.FirstName?.isSelected,
      id: "originalData.FirstName",
      label: "First Name",
      field: "originalData.FirstName",
      canSort: true,
      render: (row) => {
        const originalData = row.originalData;
        const postData = row.postData;
        const isDifferent = row.isDifferent;

        if (!isDifferent) {
          return (
            <Tooltip title={originalData.FirstName ?? ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {originalData.FirstName || "-"}
              </Typography>
            </Tooltip>
          );
        }
        return (
          <>
            <Tooltip title={originalData.FirstName ?? ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {originalData.FirstName || "-"}
              </Typography>
            </Tooltip>
            <Typography variant="body2" noWrap className={classes.upDatedRow}>
              {""}
            </Typography>
          </>
        );
      },
    },
    {
      isHidden: !state.detailedDynamicColumns?.SSN?.isSelected,
      id: "SSN",
      label: "SSN",
      field: "SSN",
      render: (row) => {
        const originalData = row.originalData;
        const postData = row.postData;
        const isDifferent = row.isDifferent;

        if (!isDifferent) {
          return (
            <Tooltip title={originalData.SSN ?? ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {originalData.SSN || "-"}
              </Typography>
            </Tooltip>
          );
        }
        return (
          <>
            <Tooltip title={originalData.SSN ?? ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {originalData.SSN || "-"}
              </Typography>
            </Tooltip>
            <Typography variant="body2" noWrap className={classes.upDatedRow}>
              {""}
            </Typography>
          </>
        );
      },
    },
    {
      isHidden: !state.detailedDynamicColumns?.EIN?.isSelected,
      id: "EIN",
      label: "EIN",
      field: "EIN",
      render: (row) => {
        const originalData = row.originalData;
        const postData = row.postData;
        const isDifferent = row.isDifferent;

        if (!isDifferent) {
          return (
            <Tooltip title={originalData.EIN ?? ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {originalData.EIN || "-"}
              </Typography>
            </Tooltip>
          );
        }
        return (
          <>
            <Tooltip title={originalData.EIN ?? ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {originalData.EIN || "-"}
              </Typography>
            </Tooltip>
            <Typography
              variant="body2"
              noWrap
              style={{ paddingTop: "40px" }}
              className={classes.upDatedRow}
            >
              {""}
            </Typography>
          </>
        );
      },
    },
    {
      isHidden: !state.detailedDynamicColumns?.CoName?.isSelected,
      id: "originalData.CoName",
      label: "IC Company Name",
      field: "originalData.CoName",
      canSort: true,
      render: (row) => {
        const originalData = row.originalData;
        const postData = row.postData;
        const isDifferent = row.isDifferent;

        if (!isDifferent) {
          return (
            <Tooltip title={originalData.CoName ?? ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {originalData.CoName || "-"}
              </Typography>
            </Tooltip>
          );
        }
        return (
          <>
            <Tooltip title={originalData.CoName ?? ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {originalData.CoName || "-"}
              </Typography>
            </Tooltip>
            <Typography variant="body2" noWrap className={classes.upDatedRow}>
              {""}
            </Typography>
          </>
        );
      },
    },
    {
      isHidden: !state.detailedDynamicColumns?.Settlement?.isSelected,
      id: "originalData.paydata.0.Settlement",
      label: "Settlement",
      field: "originalData.paydata.0.Settlement",
      canSort: true,
      render: (row, rowIndex) => {
        const originalData = row.originalData;
        const postData = row.postData;
        const isDifferent = row.isDifferent;
        const tiltle =
          postData?.paydata[0]?.Settlement_notes !== undefined
            ? `$${postData?.paydata[0]?.Settlement}, Notes : ${postData?.paydata[0]?.Settlement_notes}`
            : `$${postData?.paydata[0]?.Settlement}`;

        const dcnOriginalData = (
          <Tooltip
            title={`$${originalData.paydata[0].Settlement}` ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {`$${originalData.paydata[0].Settlement}`}
            </Typography>
          </Tooltip>
        );

        if (!isDifferent) {
          return dcnOriginalData;
        }
        if (
          isDifferent &&
          originalData.paydata[0].Settlement != postData.paydata[0].Settlement
        ) {
          return (
            <>
              {dcnOriginalData}
              <Tooltip title={tiltle ?? ""} placement="top-start">
                <Typography
                  variant="body2"
                  noWrap
                  className={clsx(classes.updatedData, {
                    "color-error":
                      originalData.paydata[0].Settlement !=
                      postData.paydata[0].Settlement,
                  })}
                >
                  {`$${postData.paydata[0].Settlement}`}
                </Typography>
              </Tooltip>
            </>
          );
        }
        return (
          <>
            {" "}
            {dcnOriginalData}
            <Typography variant="body2" noWrap className={classes.upDatedRow}>
              {""}
            </Typography>
          </>
        );
      },
    },
    {
      isHidden: !state.detailedDynamicColumns?.FuelReimbursement?.isSelected,
      id: "originalData.paydata.0.FuelReimbursement",
      label: "Fuel Reimbursement",
      field: "originalData.paydata.0.FuelReimbursement",
      canSort: true,
      render: (row, rowIndex) => {
        const originalData = row.originalData;
        const postData = row.postData;
        const isDifferent = row.isDifferent;
        const tiltle =
          postData?.paydata[0]?.FuelReimbursement_notes !== undefined
            ? `$${postData?.paydata[0]?.FuelReimbursement}, Notes : ${postData?.paydata[0]?.FuelReimbursement_notes}`
            : `$${postData?.paydata[0]?.FuelReimbursement}`;

        const dcnOriginalData = (
          <Tooltip
            title={`$${originalData.paydata[0].FuelReimbursement}` ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {`$${originalData.paydata[0].FuelReimbursement}`}
            </Typography>
          </Tooltip>
        );

        if (!isDifferent) {
          return dcnOriginalData;
        }

        if (
          isDifferent &&
          originalData.paydata[0].FuelReimbursement !=
            postData.paydata[0].FuelReimbursement
        ) {
          return (
            <>
              {dcnOriginalData}
              <Tooltip title={tiltle ?? ""} placement="top-start">
                <Typography
                  variant="body2"
                  noWrap
                  className={clsx(classes.updatedData, {
                    "color-error":
                      originalData.paydata[0].FuelReimbursement !=
                      postData.paydata[0].FuelReimbursement,
                  })}
                >
                  {`$${postData.paydata[0].FuelReimbursement}`}
                </Typography>
              </Tooltip>
            </>
          );
        }
        return (
          <>
            {dcnOriginalData}
            <Typography variant="body2" noWrap className={classes.upDatedRow}>
              {""}
            </Typography>
          </>
        );
      },
    },
    {
      isHidden: !state.detailedDynamicColumns?.TollReimbursement?.isSelected,
      id: "originalData.paydata.0.TollReimbursement",
      label: "Toll Reimbursement",
      field: "originalData.paydata.0.TollReimbursement",
      canSort: true,
      render: (row, rowIndex) => {
        const originalData = row.originalData;
        const postData = row.postData;
        const isDifferent = row.isDifferent;
        const tiltle =
          postData?.paydata[0]?.TollReimbursement_notes !== undefined
            ? `$${postData?.paydata[0]?.TollReimbursement}, Notes : ${postData?.paydata[0]?.TollReimbursement_notes}`
            : `$${postData?.paydata[0]?.TollReimbursement}`;

        const dcnOriginalData = (
          <Tooltip
            title={`$${originalData.paydata[0].TollReimbursement}` ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {`$${originalData.paydata[0].TollReimbursement}`}
            </Typography>
          </Tooltip>
        );
        if (!isDifferent) {
          return dcnOriginalData;
        }
        if (
          isDifferent &&
          originalData.paydata[0].TollReimbursement !=
            postData.paydata[0].TollReimbursement
        ) {
          return (
            <>
              {dcnOriginalData}
              <Tooltip title={tiltle ?? ""} placement="top-start">
                <Typography
                  variant="body2"
                  noWrap
                  className={clsx(classes.updatedData, {
                    "color-error":
                      originalData.paydata[0].TollReimbursement !=
                      postData.paydata[0].TollReimbursement,
                  })}
                >
                  {`$${postData.paydata[0].TollReimbursement}`}
                </Typography>
              </Tooltip>
            </>
          );
        }
        return (
          <>
            {dcnOriginalData}
            <Typography variant="body2" noWrap className={classes.upDatedRow}>
              {""}
            </Typography>
          </>
        );
      },
    },
    {
      isHidden: !state.detailedDynamicColumns?.MileageReimbursement?.isSelected,
      id: "originalData.paydata.0.MileageReimbursement",
      label: "Mileage Reimbursement",
      field: "originalData.paydata.0.MileageReimbursement",
      canSort: true,
      render: (row, rowIndex) => {
        const originalData = row.originalData;
        const postData = row.postData;
        const isDifferent = row.isDifferent;
        const tiltle =
          postData?.paydata[0]?.MileageReimbursement_notes !== undefined
            ? `$${postData?.paydata[0]?.MileageReimbursement}, Notes : ${postData?.paydata[0]?.MileageReimbursement_notes}`
            : `$${postData?.paydata[0]?.MileageReimbursement}`;

        const dcnOriginalData = (
          <Tooltip
            title={`$${originalData.paydata[0].MileageReimbursement}` ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {`$${originalData.paydata[0].MileageReimbursement}`}
            </Typography>
          </Tooltip>
        );
        if (!isDifferent) {
          return dcnOriginalData;
        }
        if (
          isDifferent &&
          originalData.paydata[0].MileageReimbursement !=
            postData.paydata[0].MileageReimbursement
        ) {
          return (
            <>
              {dcnOriginalData}
              <Tooltip title={tiltle ?? ""} placement="top-start">
                <Typography
                  variant="body2"
                  noWrap
                  className={clsx(classes.updatedData, {
                    "color-error":
                      originalData.paydata[0].MileageReimbursement !=
                      postData.paydata[0].MileageReimbursement,
                  })}
                >
                  {`$${postData.paydata[0].MileageReimbursement}`}
                </Typography>
              </Tooltip>
            </>
          );
        }
        return (
          <>
            {dcnOriginalData}
            <Typography variant="body2" noWrap className={classes.upDatedRow}>
              {""}
            </Typography>
          </>
        );
      },
    },
    {
      isHidden: !state.detailedDynamicColumns?.Reimbursement?.isSelected,
      id: "originalData.paydata.0.Reimbursement",
      label: "Reimbursement",
      field: "originalData.paydata.0.Reimbursement",
      canSort: true,
      render: (row, rowIndex) => {
        const originalData = row.originalData;
        const postData = row.postData;
        const isDifferent = row.isDifferent;
        const tiltle =
          postData?.paydata[0]?.Reimbursement_notes !== undefined
            ? `$${postData?.paydata[0]?.Reimbursement}, Notes : ${postData?.paydata[0]?.Reimbursement_notes}`
            : `$${postData?.paydata[0]?.Reimbursement}`;
        const dcnOriginalData = (
          <Tooltip
            title={`$${originalData.paydata[0].Reimbursement}` ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {`$${originalData.paydata[0].Reimbursement}`}
            </Typography>
          </Tooltip>
        );
        if (!isDifferent) {
          return dcnOriginalData;
        }
        if (
          isDifferent &&
          originalData.paydata[0].Reimbursement !=
            postData.paydata[0].Reimbursement
        ) {
          return (
            <>
              {dcnOriginalData}
              <Tooltip title={tiltle ?? ""} placement="top-start">
                <Typography
                  variant="body2"
                  noWrap
                  className={clsx(classes.updatedData, {
                    "color-error":
                      originalData.paydata[0].Reimbursement !=
                      postData.paydata[0].Reimbursement,
                  })}
                >
                  {`$${postData.paydata[0].Reimbursement}`}
                </Typography>
              </Tooltip>
            </>
          );
        }
        return (
          <>
            {dcnOriginalData}
            <Typography variant="body2" noWrap className={classes.upDatedRow}>
              {""}
            </Typography>
          </>
        );
      },
    },
    {
      isHidden: !state.detailedDynamicColumns?.MiscDeduction?.isSelected,
      id: "originalData.paydata.0.MiscDeduction",
      label: "Misc Deduction",
      field: "originalData.paydata.0.MiscDeduction",
      canSort: true,
      render: (row, rowIndex) => {
        const originalData = row.originalData;
        const postData = row.postData;
        const isDifferent = row.isDifferent;
        const tiltle =
          postData?.paydata[0]?.MiscDeduction_notes !== undefined
            ? `$${postData?.paydata[0]?.MiscDeduction}, Notes : ${postData?.paydata[0]?.MiscDeduction_notes}`
            : `$${postData?.paydata[0]?.MiscDeduction}`;
        const dcnOriginalData = (
          <Tooltip
            title={`$${originalData.paydata[0].MiscDeduction}` ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {`$${originalData.paydata[0].MiscDeduction}`}
            </Typography>
          </Tooltip>
        );
        if (!isDifferent) {
          return dcnOriginalData;
        }
        if (
          isDifferent &&
          originalData.paydata[0].MiscDeduction !=
            postData.paydata[0].MiscDeduction
        ) {
          return (
            <>
              {dcnOriginalData}
              <Tooltip title={tiltle ?? ""} placement="top-start">
                <Typography
                  variant="body2"
                  noWrap
                  className={clsx(classes.updatedData, {
                    "color-error":
                      originalData.paydata[0].MiscDeduction !=
                      postData.paydata[0].MiscDeduction,
                  })}
                >
                  {`$${postData.paydata[0].MiscDeduction}`}
                </Typography>
              </Tooltip>
            </>
          );
        }
        return (
          <>
            {dcnOriginalData}
            <Typography variant="body2" noWrap className={classes.upDatedRow}>
              {""}
            </Typography>
          </>
        );
      },
    },

    {
      isHidden: !state.detailedDynamicColumns?.Misc2Deduction?.isSelected,
      id: "originalData.paydata.0.Misc2Deduction",
      label: "Misc2 Deduction",
      field: "originalData.paydata.0.Misc2Deduction",
      canSort: true,
      render: (row, rowIndex) => {
        const originalData = row.originalData;
        const postData = row.postData;
        const isDifferent = row.isDifferent;
        const tiltle =
          postData?.paydata[0]?.Misc2Deduction_notes !== undefined
            ? `$${postData?.paydata[0]?.Misc2Deduction}, Notes : ${postData?.paydata[0]?.Misc2Deduction_notes}`
            : `$${postData?.paydata[0]?.Misc2Deduction}`;
        const dcnOriginalData = (
          <Tooltip
            title={`$${originalData.paydata[0].Misc2Deduction}` ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {`$${originalData.paydata[0].Misc2Deduction}`}
            </Typography>
          </Tooltip>
        );
        if (!isDifferent) {
          return dcnOriginalData;
        }
        if (
          isDifferent &&
          originalData.paydata[0].Misc2Deduction !=
            postData.paydata[0].Misc2Deduction
        ) {
          return (
            <>
              {dcnOriginalData}
              <Tooltip title={tiltle ?? ""} placement="top-start">
                <Typography
                  variant="body2"
                  noWrap
                  className={clsx(classes.updatedData, {
                    "color-error":
                      originalData.paydata[0].Misc2Deduction !=
                      postData.paydata[0].Misc2Deduction,
                  })}
                >
                  {`$${postData.paydata[0].Misc2Deduction}`}
                </Typography>
              </Tooltip>
            </>
          );
        }
        return (
          <>
            {dcnOriginalData}
            <Typography variant="body2" noWrap className={classes.upDatedRow}>
              {""}
            </Typography>
          </>
        );
      },
    },
    {
      isHidden: !state.detailedDynamicColumns?.FuelAdvanceDeduction?.isSelected,
      id: "originalData.paydata.0.FuelAdvanceDeduction",
      label: "Fuel Advance Deduction",
      field: "originalData.paydata.0.FuelAdvanceDeduction",
      canSort: true,
      render: (row, rowIndex) => {
        const originalData = row.originalData;
        const postData = row.postData;
        const isDifferent = row.isDifferent;
        const tiltle =
          postData?.paydata[0]?.FuelAdvanceDeduction_notes !== undefined
            ? `$${postData?.paydata[0]?.FuelAdvanceDeduction}, Notes : ${postData?.paydata[0]?.FuelAdvanceDeduction_notes}`
            : `$${postData?.paydata[0]?.FuelAdvanceDeduction}`;
        const dcnOriginalData = (
          <Tooltip
            title={`$${originalData.paydata[0].FuelAdvanceDeduction}` ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {`$${originalData.paydata[0].FuelAdvanceDeduction}`}
            </Typography>
          </Tooltip>
        );
        if (!isDifferent) {
          return dcnOriginalData;
        }
        if (
          isDifferent &&
          originalData.paydata[0].FuelAdvanceDeduction !=
            postData.paydata[0].FuelAdvanceDeduction
        ) {
          return (
            <>
              {dcnOriginalData}
              <Tooltip title={tiltle ?? ""} placement="top-start">
                <Typography
                  variant="body2"
                  noWrap
                  className={clsx(classes.updatedData, {
                    "color-error":
                      originalData.paydata[0].FuelAdvanceDeduction !=
                      postData.paydata[0].FuelAdvanceDeduction,
                  })}
                >
                  {`$${postData.paydata[0].FuelAdvanceDeduction}`}
                </Typography>
              </Tooltip>
            </>
          );
        }
        return (
          <>
            {dcnOriginalData}
            <Typography variant="body2" noWrap className={classes.upDatedRow}>
              {""}
            </Typography>
          </>
        );
      },
    },
    {
      isHidden: !state.detailedDynamicColumns?.FuelAdvance2?.isSelected,
      id: "originalData.paydata.0.FuelAdvance2",
      label: "Fuel Advance2",
      field: "originalData.paydata.0.FuelAdvance2",
      canSort: true,
      render: (row, rowIndex) => {
        const originalData = row.originalData;
        const postData = row.postData;
        const isDifferent = row.isDifferent;
        const tiltle =
          postData?.paydata[0]?.FuelAdvance2_notes !== undefined
            ? `$${postData?.paydata[0]?.FuelAdvance2}, Notes : ${postData?.paydata[0]?.FuelAdvance2_notes}`
            : `$${postData?.paydata[0]?.FuelAdvance2}`;
        const dcnOriginalData = (
          <Tooltip
            title={`$${originalData.paydata[0].FuelAdvance2}` ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {`$${originalData.paydata[0].FuelAdvance2}`}
            </Typography>
          </Tooltip>
        );
        if (!isDifferent) {
          return dcnOriginalData;
        }
        if (
          isDifferent &&
          originalData.paydata[0].FuelAdvance2 !=
            postData.paydata[0].FuelAdvance2
        ) {
          return (
            <>
              {dcnOriginalData}
              <Tooltip title={tiltle ?? ""} placement="top-start">
                <Typography
                  variant="body2"
                  noWrap
                  className={clsx(classes.updatedData, {
                    "color-error":
                      originalData.paydata[0].FuelAdvance2 !=
                      postData.paydata[0].FuelAdvance2,
                  })}
                >
                  {`$${postData.paydata[0].FuelAdvance2}`}
                </Typography>
              </Tooltip>
            </>
          );
        }
        return (
          <>
            {dcnOriginalData}
            <Typography variant="body2" noWrap className={classes.upDatedRow}>
              {""}
            </Typography>
          </>
        );
      },
    },
    {
      isHidden: !state.detailedDynamicColumns?.CashAdvanceDeduction?.isSelected,
      id: "originalData.paydata.0.CashAdvanceDeduction",
      label: "Cash Advance Deduction",
      field: "originalData.paydata.0.CashAdvanceDeduction",
      canSort: true,
      render: (row, rowIndex) => {
        const originalData = row.originalData;
        const postData = row.postData;
        const isDifferent = row.isDifferent;
        const tiltle =
          postData?.paydata[0]?.CashAdvanceDeduction_notes !== undefined
            ? `$${postData?.paydata[0]?.CashAdvanceDeduction}, Notes : ${postData?.paydata[0]?.CashAdvanceDeduction_notes}`
            : `$${postData?.paydata[0]?.CashAdvanceDeduction}`;
        const dcnOriginalData = (
          <Tooltip
            title={`$${originalData.paydata[0].CashAdvanceDeduction}` ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {`$${originalData.paydata[0].CashAdvanceDeduction}`}
            </Typography>
          </Tooltip>
        );
        if (!isDifferent) {
          return dcnOriginalData;
        }
        if (
          isDifferent &&
          originalData.paydata[0].CashAdvanceDeduction !=
            postData.paydata[0].CashAdvanceDeduction
        ) {
          return (
            <>
              {dcnOriginalData}
              <Tooltip title={tiltle ?? ""} placement="top-start">
                <Typography
                  variant="body2"
                  noWrap
                  className={clsx(classes.updatedData, {
                    "color-error":
                      originalData.paydata[0].CashAdvanceDeduction !=
                      postData.paydata[0].CashAdvanceDeduction,
                  })}
                >
                  {`$${postData.paydata[0].CashAdvanceDeduction}`}
                </Typography>
              </Tooltip>
            </>
          );
        }
        return (
          <>
            {dcnOriginalData}
            <Typography variant="body2" noWrap className={classes.upDatedRow}>
              {""}
            </Typography>
          </>
        );
      },
    },

    {
      isHidden:
        !state.detailedDynamicColumns?.CashAdvance2Deduction?.isSelected,
      id: "originalData.paydata.0.CashAdvance2Deduction",
      label: "Cash Advance2 Deduction",
      field: "originalData.paydata.0.CashAdvance2Deduction",
      canSort: true,
      render: (row, rowIndex) => {
        const originalData = row.originalData;
        const postData = row.postData;
        const isDifferent = row.isDifferent;
        const tiltle =
          postData?.paydata[0]?.CashAdvance2Deduction_notes !== undefined
            ? `$${postData?.paydata[0]?.CashAdvance2Deduction}, Notes : ${postData?.paydata[0]?.CashAdvance2Deduction_notes}`
            : `$${postData?.paydata[0]?.CashAdvance2Deduction}`;
        const dcnOriginalData = (
          <Tooltip
            title={`$${originalData.paydata[0].CashAdvance2Deduction}` ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {`$${originalData.paydata[0].CashAdvance2Deduction}`}
            </Typography>
          </Tooltip>
        );
        if (!isDifferent) {
          return dcnOriginalData;
        }
        if (
          isDifferent &&
          originalData.paydata[0].CashAdvance2Deduction !=
            postData.paydata[0].CashAdvance2Deduction
        ) {
          return (
            <>
              {dcnOriginalData}
              <Tooltip title={tiltle ?? ""} placement="top-start">
                <Typography
                  variant="body2"
                  noWrap
                  className={clsx(classes.updatedData, {
                    "color-error":
                      originalData.paydata[0].CashAdvance2Deduction !=
                      postData.paydata[0].CashAdvance2Deduction,
                  })}
                >
                  {`$${postData.paydata[0].CashAdvance2Deduction}`}
                </Typography>
              </Tooltip>
            </>
          );
        }
        return (
          <>
            {dcnOriginalData}
            <Typography variant="body2" noWrap className={classes.upDatedRow}>
              {""}
            </Typography>
          </>
        );
      },
    },
    {
      isHidden: !state.detailedDynamicColumns?.ClaimDeduction?.isSelected,
      id: "originalData.paydata.0.ClaimDeduction",
      label: "Claim Deduction",
      field: "originalData.paydata.0.ClaimDeduction",
      canSort: true,
      render: (row, rowIndex) => {
        const originalData = row.originalData;
        const postData = row.postData;
        const isDifferent = row.isDifferent;
        const tiltle =
          postData?.paydata[0]?.ClaimDeduction_notes !== undefined
            ? `$${postData?.paydata[0]?.ClaimDeduction}, Notes : ${postData?.paydata[0]?.ClaimDeduction_notes}`
            : `$${postData?.paydata[0]?.ClaimDeduction}`;
        const dcnOriginalData = (
          <Tooltip
            title={`$${originalData.paydata[0].ClaimDeduction}` ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {`$${originalData.paydata[0].ClaimDeduction}`}
            </Typography>
          </Tooltip>
        );
        if (!isDifferent) {
          return dcnOriginalData;
        }
        if (
          isDifferent &&
          originalData.paydata[0].ClaimDeduction !=
            postData.paydata[0].ClaimDeduction
        ) {
          return (
            <>
              {dcnOriginalData}
              <Tooltip title={tiltle ?? ""} placement="top-start">
                <Typography
                  variant="body2"
                  noWrap
                  className={clsx(classes.updatedData, {
                    "color-error":
                      originalData.paydata[0].ClaimDeduction !=
                      postData.paydata[0].ClaimDeduction,
                  })}
                >
                  {`$${postData.paydata[0].ClaimDeduction}`}
                </Typography>
              </Tooltip>
            </>
          );
        }
        return (
          <>
            {dcnOriginalData}
            <Typography variant="body2" noWrap className={classes.upDatedRow}>
              {""}
            </Typography>
          </>
        );
      },
    },

    {
      isHidden: !state.detailedDynamicColumns?.Claim2Deduction?.isSelected,
      id: "originalData.paydata.0.Claim2Deduction",
      label: "Claim2 Deduction",
      field: "originalData.paydata.0.Claim2Deduction",
      canSort: true,
      render: (row, rowIndex) => {
        const originalData = row.originalData;
        const postData = row.postData;
        const isDifferent = row.isDifferent;
        const tiltle =
          postData?.paydata[0]?.Claim2Deduction_notes !== undefined
            ? `$${postData?.paydata[0]?.Claim2Deduction}, Notes : ${postData?.paydata[0]?.Claim2Deduction_notes}`
            : `$${postData?.paydata[0]?.Claim2Deduction}`;
        const dcnOriginalData = (
          <Tooltip
            title={`$${originalData.paydata[0].Claim2Deduction}` ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {`$${originalData.paydata[0].Claim2Deduction}`}
            </Typography>
          </Tooltip>
        );
        if (!isDifferent) {
          return dcnOriginalData;
        }
        if (
          isDifferent &&
          originalData.paydata[0].Claim2Deduction !=
            postData.paydata[0].Claim2Deduction
        ) {
          return (
            <>
              {dcnOriginalData}
              <Tooltip title={tiltle ?? ""} placement="top-start">
                <Typography
                  variant="body2"
                  noWrap
                  className={clsx(classes.updatedData, {
                    "color-error":
                      originalData.paydata[0].Claim2Deduction !=
                      postData.paydata[0].Claim2Deduction,
                  })}
                >
                  {`$${postData.paydata[0].Claim2Deduction}`}
                </Typography>
              </Tooltip>
            </>
          );
        }
        return (
          <>
            {dcnOriginalData}
            <Typography variant="body2" noWrap className={classes.upDatedRow}>
              {""}
            </Typography>
          </>
        );
      },
    },
    {
      isHidden: !state.detailedDynamicColumns?.Claim3Deduction?.isSelected,
      id: "originalData.paydata.0.Claim3Deduction",
      label: "Claim3 Deduction",
      field: "originalData.paydata.0.Claim3Deduction",
      canSort: true,
      render: (row, rowIndex) => {
        const originalData = row.originalData;
        const postData = row.postData;
        const isDifferent = row.isDifferent;
        const tiltle =
          postData?.paydata[0]?.Claim3Deduction_notes !== undefined
            ? `$${postData?.paydata[0]?.Claim3Deduction}, Notes : ${postData?.paydata[0]?.Claim3Deduction_notes}`
            : `$${postData?.paydata[0]?.Claim3Deduction}`;
        const dcnOriginalData = (
          <Tooltip
            title={`$${originalData.paydata[0].Claim3Deduction}` ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {`$${originalData.paydata[0].Claim3Deduction}`}
            </Typography>
          </Tooltip>
        );
        if (!isDifferent) {
          return dcnOriginalData;
        }
        if (
          isDifferent &&
          originalData.paydata[0].Claim3Deduction !=
            postData.paydata[0].Claim3Deduction
        ) {
          return (
            <>
              {dcnOriginalData}
              <Tooltip title={tiltle ?? ""} placement="top-start">
                <Typography
                  variant="body2"
                  noWrap
                  className={clsx(classes.updatedData, {
                    "color-error":
                      originalData.paydata[0].Claim3Deduction !=
                      postData.paydata[0].Claim3Deduction,
                  })}
                >
                  {`$${postData.paydata[0].Claim3Deduction}`}
                </Typography>
              </Tooltip>
            </>
          );
        }
        return (
          <>
            {dcnOriginalData}
            <Typography variant="body2" noWrap className={classes.upDatedRow}>
              {""}
            </Typography>
          </>
        );
      },
    },

    {
      isHidden: !state.detailedDynamicColumns?.Rental1Deduction?.isSelected,
      id: "originalData.paydata.0.Rental1Deduction",
      label: "Rental1 Deduction",
      field: "originalData.paydata.0.Rental1Deduction",
      canSort: true,
      render: (row, rowIndex) => {
        const originalData = row.originalData;
        const postData = row.postData;
        const isDifferent = row.isDifferent;
        const tiltle =
          postData?.paydata[0]?.Rental1Deduction_notes !== undefined
            ? `$${postData?.paydata[0]?.Rental1Deduction}, Notes : ${postData?.paydata[0]?.Rental1Deduction_notes}`
            : `$${postData?.paydata[0]?.Rental1Deduction}`;
        const dcnOriginalData = (
          <Tooltip
            title={`$${originalData.paydata[0].Rental1Deduction}` ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {`$${originalData.paydata[0].Rental1Deduction}`}
            </Typography>
          </Tooltip>
        );
        if (!isDifferent) {
          return dcnOriginalData;
        }
        if (
          isDifferent &&
          originalData.paydata[0].Rental1Deduction !=
            postData.paydata[0].Rental1Deduction
        ) {
          return (
            <>
              {dcnOriginalData}
              <Tooltip title={tiltle ?? ""} placement="top-start">
                <Typography
                  variant="body2"
                  noWrap
                  className={clsx(classes.updatedData, {
                    "color-error":
                      originalData.paydata[0].Rental1Deduction !=
                      postData.paydata[0].Rental1Deduction,
                  })}
                >
                  {`$${postData.paydata[0].Rental1Deduction}`}
                </Typography>
              </Tooltip>
            </>
          );
        }
        return (
          <>
            {dcnOriginalData}
            <Typography variant="body2" noWrap className={classes.upDatedRow}>
              {""}
            </Typography>
          </>
        );
      },
    },
    {
      isHidden: !state.detailedDynamicColumns?.Rental2Deduction?.isSelected,
      id: "originalData.paydata.0.Rental2Deduction",
      label: "Rental2 Deduction",
      field: "originalData.paydata.0.Rental2Deduction",
      canSort: true,
      render: (row, rowIndex) => {
        const originalData = row.originalData;
        const postData = row.postData;
        const isDifferent = row.isDifferent;
        const tiltle =
          postData?.paydata[0]?.Rental2Deduction_notes !== undefined
            ? `$${postData?.paydata[0]?.Rental2Deduction}, Notes : ${postData?.paydata[0]?.Rental2Deduction_notes}`
            : `$${postData?.paydata[0]?.Rental2Deduction}`;
        const dcnOriginalData = (
          <Tooltip
            title={`$${originalData.paydata[0].Rental2Deduction}` ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {`$${originalData.paydata[0].Rental2Deduction}`}
            </Typography>
          </Tooltip>
        );
        if (!isDifferent) {
          return dcnOriginalData;
        }
        if (
          isDifferent &&
          originalData.paydata[0].Rental2Deduction !=
            postData.paydata[0].Rental2Deduction
        ) {
          return (
            <>
              {dcnOriginalData}
              <Tooltip title={tiltle ?? ""} placement="top-start">
                <Typography
                  variant="body2"
                  noWrap
                  className={clsx(classes.updatedData, {
                    "color-error":
                      originalData.paydata[0].Rental2Deduction !=
                      postData.paydata[0].Rental2Deduction,
                  })}
                >
                  {`$${postData.paydata[0].Rental2Deduction}`}
                </Typography>
              </Tooltip>
            </>
          );
        }
        return (
          <>
            {dcnOriginalData}
            <Typography variant="body2" noWrap className={classes.upDatedRow}>
              {""}
            </Typography>
          </>
        );
      },
    },
    {
      isHidden: !state.detailedDynamicColumns?.Rental3Deduction?.isSelected,
      id: "originalData.paydata.0.Rental3Deduction",
      label: "Rental3 Deduction",
      field: "originalData.paydata.0.Rental3Deduction",
      canSort: true,
      render: (row, rowIndex) => {
        const originalData = row.originalData;
        const postData = row.postData;
        const isDifferent = row.isDifferent;
        const tiltle =
          postData?.paydata[0]?.Rental3Deduction_notes !== undefined
            ? `$${postData?.paydata[0]?.Rental3Deduction}, Notes : ${postData?.paydata[0]?.Rental3Deduction_notes}`
            : `$${postData?.paydata[0]?.Rental3Deduction}`;
        const dcnOriginalData = (
          <Tooltip
            title={`$${originalData.paydata[0].Rental3Deduction}` ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {`$${originalData.paydata[0].Rental3Deduction}`}
            </Typography>
          </Tooltip>
        );
        if (!isDifferent) {
          return dcnOriginalData;
        }
        if (
          isDifferent &&
          originalData.paydata[0].Rental3Deduction !=
            postData.paydata[0].Rental3Deduction
        ) {
          return (
            <>
              {dcnOriginalData}
              <Tooltip title={tiltle ?? ""} placement="top-start">
                <Typography
                  variant="body2"
                  noWrap
                  className={clsx(classes.updatedData, {
                    "color-error":
                      originalData.paydata[0].Rental3Deduction !=
                      postData.paydata[0].Rental3Deduction,
                  })}
                >
                  {`$${postData.paydata[0].Rental3Deduction}`}
                </Typography>
              </Tooltip>
            </>
          );
        }
        return (
          <>
            {dcnOriginalData}
            <Typography variant="body2" noWrap className={classes.upDatedRow}>
              {""}
            </Typography>
          </>
        );
      },
    },
    {
      isHidden: !state.detailedDynamicColumns?.Rental4Deduction?.isSelected,
      id: "originalData.paydata.0.Rental4Deduction",
      label: "Rental4 Deduction",
      field: "originalData.paydata.0.Rental4Deduction",
      canSort: true,
      render: (row) => {
        const originalData = row.originalData;
        const postData = row.postData;
        const isDifferent = row.isDifferent;
        const tiltle =
          postData?.paydata[0]?.Rental4Deduction_notes !== undefined
            ? `$${postData?.paydata[0]?.Rental4Deduction}, Notes : ${postData?.paydata[0]?.Rental4Deduction_notes}`
            : `$${postData?.paydata[0]?.Rental4Deduction}`;
        const dcnOriginalData = (
          <Tooltip
            title={`$${originalData.paydata[0].Rental4Deduction}` ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {`$${originalData.paydata[0].Rental4Deduction}`}
            </Typography>
          </Tooltip>
        );
        if (!isDifferent) {
          return dcnOriginalData;
        }
        if (
          isDifferent &&
          originalData.paydata[0].Rental4Deduction !=
            postData.paydata[0].Rental4Deduction
        ) {
          return (
            <>
              {dcnOriginalData}
              <Tooltip title={tiltle ?? ""} placement="top-start">
                <Typography
                  variant="body2"
                  noWrap
                  className={clsx(classes.updatedData, {
                    "color-error":
                      originalData.paydata[0].Rental4Deduction !=
                      postData.paydata[0].Rental4Deduction,
                  })}
                >
                  {`$${postData.paydata[0].Rental4Deduction}`}
                </Typography>
              </Tooltip>
            </>
          );
        }
        return (
          <>
            {dcnOriginalData}
            <Typography variant="body2" noWrap className={classes.upDatedRow}>
              {""}
            </Typography>
          </>
        );
      },
    },
    {
      isHidden: !state.detailedDynamicColumns?.EOR?.isSelected,
      id: "originalData.paydata.0.EOR",
      label: "EOR",
      field: "originalData.paydata.0.EOR",
      canSort: true,
      render: (row) => {
        const originalData = row.originalData;
        const postData = row.postData;
        const isDifferent = row.isDifferent;
        const tiltle =
          postData?.paydata[0]?.EOR_notes !== undefined
            ? `${postData?.paydata[0]?.EOR}, Notes : ${postData?.paydata[0]?.EOR_notes}`
            : `${postData?.paydata[0]?.EOR}`;
        const dcnOriginalData = (
          <Tooltip
            title={`${originalData.paydata[0].EOR}` ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {`${originalData.paydata[0].EOR}`}
            </Typography>
          </Tooltip>
        );
        if (!isDifferent) {
          return dcnOriginalData;
        }
        if (
          isDifferent &&
          originalData.paydata[0].EOR != postData.paydata[0].EOR
        ) {
          return (
            <>
              {dcnOriginalData}
              <Tooltip title={tiltle ?? ""} placement="top-start">
                <Typography
                  variant="body2"
                  noWrap
                  className={clsx(classes.updatedData, {
                    "color-error":
                      originalData.paydata[0].EOR != postData.paydata[0].EOR,
                  })}
                >
                  {`${postData.paydata[0].EOR}`}
                </Typography>
              </Tooltip>
            </>
          );
        }
        return (
          <>
            {dcnOriginalData}
            <Typography variant="body2" noWrap className={classes.upDatedRow}>
              {""}
            </Typography>
          </>
        );
      },
    },
  ];

  const handleCloseViewHistory = () => {
    setState((prevState) => ({
      ...prevState,
      viewHistory: false,
    }));
  };

  const renderDropdown = (columnState, columns, pageKey) => (
    <div className="d-flex f-justify-center">
      <Dropdown
        classes={{
          paper: classes.tunePaper,
        }}
        disablePortal={false}
        remainOpen
        isMultiSelect
        placement="bottom-end"
        options={Object.keys(columns).map((key) => columns[key])}
        customToggle={({ anchorRef, onClick }) => (
          <Tooltip title="Show/Hide column(s)" placement="top-start">
            <IconButton color="primary" ref={anchorRef} onClick={onClick}>
              <TuneIcon />
            </IconButton>
          </Tooltip>
        )}
        onChange={(options) => {
          const isSelectAll = options.some(
            (item) => item.value === "select_all"
          );
          const isCurrentSelectAll = columns?.select_all?.isSelected;
          const isClickedOnSelectAll = isSelectAll !== isCurrentSelectAll;
          const isAllOptionSelected =
            Object.keys(columns).length - 1 ===
            options.filter((item) => item.value !== "select_all").length;
          const updatedDynamicColumns = Object.keys(columns).reduce(
            (acc, key) => {
              const isSelected = isClickedOnSelectAll
                ? isSelectAll
                : key === "select_all"
                ? isAllOptionSelected
                : !!options.some((item) => item.value === key);
              return {
                ...acc,
                [key]: {
                  ...columns[key],
                  isSelected,
                },
              };
            },
            {}
          );
          setPageConfig(pageKey, {
            [columnState]: updatedDynamicColumns,
          });
          setState((prevState) => ({
            ...prevState,
            [columnState]: {
              ...prevState[columnState],
              ...updatedDynamicColumns,
            },
          }));
        }}
      />
    </div>
  );

  const getNestedPropertyValue = (obj, path) => {
    if (!path) {
      return "";
    }

    const properties = path.split(".");
    let value = obj;

    for (let property of properties) {
      value = value?.[property];

      // If the current value is undefined or null, break out of the loop
      if (value === undefined || value === null) {
        break;
      }
    }
    return value;
  };

  const alphanumericCompare = (a, b) => {
    const chunkify = (value) =>
      value
        .toString()
        .replace(/([0-9]+)/g, (_, number) =>
          `00000000000000000000${number}`.slice(-20)
        );

    const chunkedA = chunkify(a);
    const chunkedB = chunkify(b);

    return chunkedA.localeCompare(chunkedB, undefined, { sensitivity: "base" });
  };

  //get sorted and paginated data
  const getData = (
    // filters = state.filters,
    // pageSize = state.pageSize,
    // pageNumber = state.pageNumber,
    orderBy = state.detailedOrderBy,
    order = state.detailedOrder
  ) => {
    const sortObj = { field: orderBy, order };

    let preparedData = state.detailedEntries && state.detailedEntries.slice();
    if (!preparedData || preparedData.length === 0) {
      return [];
    }
    preparedData = preparedData.map((row) => ({
      ...row,
      id: row.id,
      // className: clsx({ [classes.total]: row.status === "temp" }),
    }));

    if (sortObj) {
      preparedData.sort((rowA, rowB) => {
        const valueA = getNestedPropertyValue(rowA, sortObj.field);
        const valueB = getNestedPropertyValue(rowB, sortObj.field);

        if (valueA === undefined || valueA === null) {
          return valueB === undefined || valueB === null ? 0 : 1;
        }

        if (valueB === undefined || valueB === null) {
          return -1;
        }

        return sortObj.order === "DESC"
          ? alphanumericCompare(valueB, valueA)
          : alphanumericCompare(valueA, valueB);
      });
    }
    const totalEntries = preparedData.length;

    return { preparedData, totalEntries };
  };

  useEffect(() => {
    const calculateUniqueEmailList = () => {
      const dcnUsersEmailList = [];

      if (!!state.dcnUsersList) {
        state.dcnUsersList.forEach((user) => {
          dcnUsersEmailList.push(user?.value);
        });
      }

      if (!!state.viewHistory?.metadata?.dcnEmails) {
        state.viewHistory.metadata.dcnEmails.forEach((email) => {
          dcnUsersEmailList.push(email);
        });
      }

      const uniqueEmailList = Array.from(new Set(dcnUsersEmailList));
      setState((prevState) => ({
        ...prevState,
        setUniqueDcnUsersEmailList:
          uniqueEmailList || defaultState.setUniqueDcnUsersEmailList,
      }));
    };

    calculateUniqueEmailList();
  }, [state.dcnUsersList, state.viewHistory]);

  return (
    <>
      <div id="numbers-page-wrapper">
        <Grid container className="mr-5">
          <Grid item xs={12} className={classes.fixedHeader}>
            <div className="mb-2">
              <Typography variant="h4" color="primary" className="ml-2">
                DCN History
                <Button
                  startIcon={<ArrowBackIcon />}
                  style={{ float: "right" }}
                  className="mt-1 mr-2"
                  variant="contained"
                  color="primary"
                  onClick={() => history.push("/home")}
                >
                  Back To Home
                </Button>
                <Button
                  startIcon={<ArrowBackIcon />}
                  style={{ float: "right" }}
                  className="mt-1 mr-2"
                  variant="contained"
                  color="primary"
                  onClick={() => history.push("/settlements")}
                >
                  Back To Settlements
                </Button>
              </Typography>
              <Typography variant="body2" style={{ marginLeft: "10px" }}>
                View DCN History.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} className="ml-1">
            <div className={"d-flex f-align-center f-wrap f-justify-between"}>
              <div className={"d-flex f-align-center mb-2"}>
                <Autocomplete
                  size="small"
                  className={clsx(classes.settlementCompanyDropdown, {
                    "mt-3": appData.isTabletView,
                  })}
                  options={state.settlementCompanyList.filter(
                    (company) =>
                      company.value === "DCN" ||
                      company.value === "DCN - South" ||
                      company.value === "DCN - West"
                  )}
                  value={state.settlementCompany}
                  getOptionLabel={(option) =>
                    `${option.value}(${option.description})`
                  }
                  renderInput={(params) =>
                    state.isFetchingList.users ? (
                      <Skeleton variant="rect" width="100%" height="100%" />
                    ) : (
                      <TextField
                        {...params}
                        label="Settlement Company"
                        variant="outlined"
                      />
                    )
                  }
                  onChange={(evt, value) => {
                    setState((prevState) => ({
                      ...prevState,
                      settlementCompany: value,
                      filters: {
                        ...prevState.filters,
                        settlementCompany: [
                          {
                            field: "settlement_co_id",
                            type: "=",
                            value: value?.id,
                          },
                        ],
                      },
                    }));
                    setPageConfig(PAGE_KEYS.DCN_HISTORY, {
                      settlementCompanyFilter: {
                        value,
                        settlementCompany: [
                          {
                            field: "settlement_co_id",
                            type: "=",
                            value: value?.id,
                          },
                        ],
                      },
                    });
                  }}
                />
                <div style={{ position: "relative", marginLeft: "16px" }}>
                  <Datepicker
                    mask
                    label="Pay date"
                    selected={state.payDate}
                    placement={"bottom-start"}
                    classes={{
                      datepickerWrapper: classes.scheduleDateWrapper,
                      input: {
                        root: classes.payDateWrapper,
                      },
                    }}
                    isClearable
                    onCalendarClose={() => {}}
                    onCalendarOpen={() => {}}
                    onMonthChange={() => {}}
                    onChange={(payDate) => {
                      setState((prevState) => ({
                        ...prevState,
                        payDate: getDateString(payDate),
                      }));
                      setPageConfig(PAGE_KEYS.DCN_HISTORY, {
                        payDateFilter: getDateString(payDate),
                      });
                    }}
                  />
                </div>
                <div style={{ position: "relative", marginLeft: "16px" }}>
                  <Datepicker
                    mask
                    label="Sent date"
                    selected={state.sentDate}
                    placement={"bottom-start"}
                    classes={{
                      datepickerWrapper: classes.scheduleDateWrapper,
                      input: {
                        root: classes.payDateWrapper,
                      },
                    }}
                    isClearable
                    onCalendarClose={() => {}}
                    onCalendarOpen={() => {}}
                    onMonthChange={() => {}}
                    onChange={(sentDate) => {
                      setState((prevState) => ({
                        ...prevState,
                        sentDate: getDateString(sentDate),
                      }));
                      setPageConfig(PAGE_KEYS.DCN_HISTORY, {
                        sentDateFilter: getDateString(sentDate),
                      });
                    }}
                  />
                </div>
              </div>
              <div
                className={clsx("d-flex", {
                  "mt-4 f-justify-start f-wrap": appData.isTabletView,
                })}
              ></div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Paper
              elevation={2}
              className={clsx("p-4 mt-10", classes.paperSpacing)}
            >
              <div
                className={clsx(
                  "d-flex f-align-center f-justify-end p-2 pl-4 pr-4",
                  classes.actionsWrapper
                )}
              >
                <div className="d-flex f-justify-center">
                  {renderDropdown(
                    "dynamicColumns",
                    state.dynamicColumns,
                    PAGE_KEYS.DCN_HISTORY
                  )}
                </div>
                <Tooltip placement="top-start" title="Filter">
                  <IconButton
                    color="primary"
                    className={clsx({
                      "bg-primary": !!state.pageFilters?.length,
                    })}
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        isFiltering: true,
                      }));
                    }}
                  >
                    <FilterListIcon
                      className={clsx({
                        "color-white": !!state.pageFilters?.length,
                      })}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip placement="top-end" title="Reset All Filters">
                  <IconButton
                    // disabled={pageConfig === undefined || pageConfig === null}
                    color="primary"
                    onClick={() => {
                      updatePageConfig("dcn-history");
                      window.location.reload();
                    }}
                  >
                    <RotateLeftIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <DataGrid
                columns={columnConfig}
                rows={state.entries?.map((entry) => ({
                  ...entry,
                  className: clsx({
                    [classes.isReverted]: !!entry.is_reverted,
                  }),
                }))}
                actionBarConfig={null}
                isLoading={state.isFetching}
                hasSelection={false}
                onReady={(instance) => (gridHelper = instance)}
                totalRows={state.totalEntries}
                pageSize={state.pageSize}
                pageNumber={state.pageNumber}
                order={state.order}
                orderBy={state.orderBy}
                onPageNumberChange={handlePageNumberChange}
                onPageSizeChange={handlePageSizeChange}
                onSelectionChange={handleSelectionChange}
                onSortChange={handleSortChange}
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
      {state.viewHistory && (
        <Dialog
          open
          classes={{
            paper: classes.paper,
          }}
          onClose={handleCloseViewHistory}
        >
          <Dialog.Title hasClose>
            <div className="mb-2">
              <Typography variant="h4" color="primary" className="ml-2">
                DCN Detailed History
              </Typography>
              <Typography variant="body2" style={{ marginLeft: "10px" }}>
                View DCN Detailed History.
              </Typography>
            </div>
          </Dialog.Title>
          <Dialog.Content>
            <Paper
              elevation={2}
              className={clsx("p-4 mt-10", classes.paperSpacing)}
            >
              <div
                className={clsx(
                  "d-flex f-align-center f-justify-between p-2 pl-4 pr-4",
                  classes.actionsWrapper
                )}
              >
                {state.isLoading ? (
                  <Skeleton variant="text" width="40%" height="100%" />
                ) : (
                  <Typography variant="h6" className="text-bold">
                    {`Start Date : ${
                      state.metadata?.fromDate || "-"
                    } || End Date : ${
                      state.metadata?.toDate || "-"
                    } || Settlement Company : ${
                      state.dcnDetailedHistory?.settlementCompany?.value || "-"
                    }`}
                  </Typography>
                )}
                <div></div>
                <div className="d-flex f-justify-center">
                  {isAdminRole && (
                    <Button
                      variant="contained"
                      color="primary"
                      className="ml-2 mr-2 mt-2"
                      startIcon={<RotateLeftIcon />}
                      disabled={
                        state.isFetching ||
                        !state.detailedEntries.length ||
                        !!state.viewHistory.is_reverted
                      }
                      onClick={() => {
                        fetchDCNUsersList();
                        setState((prevState) => ({
                          ...prevState,
                          showConfirmationPopup: true,
                        }));
                      }}
                    >
                      Revert
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    className="ml-2 mr-2 mt-2"
                    startIcon={
                      <img
                        src={pdfIcon}
                        alt="pdf icon"
                        height={25}
                        width={25}
                      />
                    }
                    disabled={state.isFetching || !state.detailedEntries.length}
                    onClick={() => {
                      const pdf = GenerateDCNPdf({
                        dcnDetails: state.detailedEntries,
                        dcnDetailedHistory: state.dcnDetailedHistory,
                        metadata: state.metadata,
                      });
                      pdf.save("DCN-detailed-history.pdf");
                    }}
                  >
                    Download
                  </Button>
                  {renderDropdown(
                    "detailedDynamicColumns",
                    state.detailedDynamicColumns,
                    PAGE_KEYS.DCN_DETALIED_HISTORY
                  )}
                </div>
              </div>
              <DataGrid
                columns={detailedColumnConfig}
                rows={getData()?.preparedData}
                actionBarConfig={null}
                isLoading={state.isFetching}
                hasSelection={false}
                hasPagination={false}
                onReady={(instance) => (gridHelper = instance)}
                order={state.detailedOrder}
                orderBy={state.detailedOrderBy}
                onSortChange={handleDetailedSortChange}
                totalRows={getData()?.totalEntries}
              />
            </Paper>
          </Dialog.Content>
          <Dialog.Actions>
            <div className="p-4">
              <Button
                type="submit"
                variant="contained"
                onClick={handleCloseViewHistory}
                className="ml-2 mr-2"
                color="primary"
              >
                OK
              </Button>
            </div>
          </Dialog.Actions>
        </Dialog>
      )}
      {state.showAddLookupDialog && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.addLookupEmailpaperSize,
          }}
          open={!!state.showAddLookupDialog}
          contentText={
            <>
              <Typography variant="body2" className="mt-2 text-bold">
                {`Would you also like to add this email address `}
                <span style={{ color: "Black" }}>{state.email}</span>
                {` in the Lookup(DCN Email Recipients)?`}
              </Typography>
            </>
          }
          onConfirm={() => {
            addLookupValue(state.showAddLookupDialog);
            setTimeout(() => {
              fetchDCNUsersList();
            }, 1000);
          }}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              showAddLookupDialog: false,
            }));
          }}
          isConfirmDisabled={state.isLookupLoading}
          positiveActionLabel={
            <>
              ADD TO LOOKUP
              {state.isLookupLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          negativeActionLabel="CANCEL"
        />
      )}
      {state.showConfirmationPopup && (
        <ActionDialog
          classes={{
            confirm:
              state.isRevertingData ||
              state.isAddButtonEnabled ||
              isConfirmDisabled
                ? "black"
                : "bg-primary",
            paper: classes.senToDcnPaperSize,
          }}
          open={!!state.showConfirmationPopup}
          contentText={
            <div style={{ overflowY: "hidden" }}>
              <div
                className={clsx(
                  "d-flex f-align-center mt-4",
                  classes.payDateGap
                )}
              >
                <Typography
                  variant="body2"
                  className={clsx("text-bold", classes.payDateSize)}
                >
                  Pay Date:
                </Typography>
                <Datepicker
                  mask
                  preventClear
                  label="Pay date"
                  selected={getUTCDateString(state.viewHistory.pay_date)}
                  classes={{
                    input: {
                      root: clsx(
                        "mr-4 mt-3 mb-3 no-events",
                        classes.datepickerWrapper
                      ),
                    },
                  }}
                />
              </div>
              <Typography variant="body2" className="mt-4 text-bold">
                DCN Email Recipients:
              </Typography>
              {state.isFetchingList.dcnUsers ? (
                <Skeleton variant="text" width="50%" height="100%" />
              ) : state.setUniqueDcnUsersEmailList &&
                state.setUniqueDcnUsersEmailList.length > 0 ? (
                <ul className="mt-1 mb-6">
                  {state.setUniqueDcnUsersEmailList.map((email, index) => (
                    <li key={index}>
                      <b>{email}</b>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className={classes.noEmail}>
                  To add an email, you can use the lookup screen.
                </div>
              )}
              <Typography variant="body2" className="text-bold">
                Want to add more DCN emails(Optional)?
              </Typography>
              <div className="d-flex" style={{ gap: "6px" }}>
                <TextField
                  label="Add DCN Email ID(s)"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="email"
                  value={state.email}
                  className="mt-3"
                  onChange={handleFieldChange}
                  error={!!state.errors.email?.trim()}
                  helperText={state.errors.email}
                />
                <div>
                  <Tooltip title="Add Email Temporarily" placement="top-end">
                    <Button
                      type="submit"
                      onClick={handleAddEmail}
                      variant="contained"
                      color="primary"
                      disabled={isConfirmDisabled || !state.email}
                      className={classes.buttonStyle}
                    >
                      <AddIcon />
                    </Button>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip
                    title="Add To Lookup(DCN Email Recipients)"
                    placement="top-end"
                  >
                    <Button
                      type="submit"
                      onClick={handleShowAddLookupDialog}
                      variant="contained"
                      color="primary"
                      disabled={isConfirmDisabled || !state.email}
                      className={classes.buttonStyle}
                    >
                      <PostAddIcon />
                    </Button>
                  </Tooltip>
                </div>
              </div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {state.newEmail.map((email) => (
                  <Chip
                    key={email.id}
                    label={email.email}
                    onDelete={() => handleDeleteEmail(email?.id)}
                    className={classes.emailChip}
                  />
                ))}
              </div>
            </div>
          }
          onConfirm={() => handleSubmit()}
          onCancel={() => {
            isConfirmDisabled = false;
            setState((prevState) => ({
              ...prevState,
              showConfirmationPopup: false,
              email: defaultState.email,
              isAddButtonEnabled: false,
              newEmail: defaultState.newEmail,
              errors: {
                email: " ",
              },
            }));
          }}
          isConfirmDisabled={
            state.isRevertingData ||
            state.isFetchingList.dcnUsers ||
            state.isAddButtonEnabled ||
            isConfirmDisabled
          }
          positiveActionLabel={
            <>
              Revert
              {state.isRevertingData && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn color-white"
                />
              )}
            </>
          }
          negativeActionLabel="Cancel"
        />
      )}
      {state.isFiltering && (
        <FilterComponent
          isFiltering={state.isFiltering}
          filterConfig={filterConfig}
          appliedFilters={state.pageFilters}
          onFilterApply={handleFilterChange}
          onClose={() => {
            setState((prevState) => ({
              ...prevState,
              isFiltering: false,
            }));
          }}
        />
      )}
    </>
  );
};

export default DcnHistory;
