import { useCallback, useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import useStyles from "./style";
import NoRecords from "assets/images/norecord.svg";

const defaultState = {
  lineColors: [],
};

const LineChartReport = ({
  totalCustomerArray = [],
  monthYearArray = [],
  billing = true,
  settlement = false,
  showLegends = false,
  selectedOption = "amount",
  selectedValue = null,
  filterType = {},
  appData,
}) => {
  const [state, setState] = useState(defaultState);
  const classes = useStyles();

  const chartData = [];

  totalCustomerArray.forEach((data) => {
    const chartItem = {
      date: [],
      bill: [],
      pay: [],
      customer: data.customerName,
    };

    Object.entries(data).forEach(([key, value]) => {
      if (
        key === "customerName" ||
        key === "Customer" ||
        key === "Total" ||
        key === "Total%" ||
        key === "ic_name" ||
        key === "KAM" ||
        key === "PDX Company" ||
        (selectedOption === "amount" && key.endsWith("%")) ||
        (selectedOption === "percent" && !key.endsWith("%"))
      ) {
        return;
      }

      chartItem.date.push(key);
      chartItem.bill.push(
        selectedOption === "amount"
          ? value.monthly_bill_amount
          : value.monthly_bill_percent
      );
      chartItem.pay.push(
        selectedOption === "amount"
          ? value.monthly_pay_amount
          : value.monthly_pay_percent
      );
    });

    monthYearArray.forEach((date) => {
      if (!chartItem.date.includes(date)) {
        chartItem.date.push(date);
        chartItem.bill.push("0");
        chartItem.pay.push("0");
      }
    });

    chartData.push(chartItem);
  });

  const newChartData = chartData.filter(
    (item) =>
      item.customer !== "Gross Profit" && item.customer !== "TOTAL SALES"
  );

  const filteredCustomerData = newChartData.filter((data) => {
    if (selectedValue?.length === 0 || selectedValue === null) {
      return true;
    }
    return selectedValue.some((value) => value.name === data.customer);
  });

  const uniqueDates = [...new Set(filteredCustomerData.flatMap((d) => d.date))];
  const filteredData = uniqueDates
    .filter((item) => item !== "Start" && item !== "End")
    .sort((a, b) => {
      const aIsPercentage = a.endsWith("%");
      const bIsPercentage = b.endsWith("%");
      if (aIsPercentage !== bIsPercentage) {
        return aIsPercentage ? 1 : -1;
      }
      if (aIsPercentage && bIsPercentage) {
        a = a.replace("%", "");
        b = b.replace("%", "");
      }
      const aDate = isNaN(a) ? new Date(`01-${a}`) : parseInt(a);
      const bDate = isNaN(b) ? new Date(`01-${b}`) : parseInt(b);
      if (aDate === bDate) {
        return 0;
      } else if (aDate < bDate) {
        return -1;
      } else {
        return 1;
      }
    });

  const processedData = filteredData.map((date) => {
    const customers = filteredCustomerData.filter((item) =>
      item.date.includes(date)
    );
    const dateData = { date };
    customers.forEach((customer) => {
      const index = customer.date.indexOf(date);
      dateData[`${customer.customer} - Billing`] = customer.bill[index];
      dateData[`${customer.customer} - Settlement`] = customer.pay[index];
    });
    return dateData;
  });

  const amounts =
    billing && settlement
      ? filteredCustomerData
          .flatMap((data) => [data.pay, data.bill])
          .flatMap((array) => array.map((value) => parseFloat(value || 0)))
      : billing
      ? filteredCustomerData.flatMap((data) =>
          data.bill.map((value) => parseFloat(value || 0))
        )
      : filteredCustomerData.flatMap((data) =>
          data.pay.map((value) => parseFloat(value || 0))
        );
  const maxAmount = Math.max(...amounts);

  const generateLineColors = useCallback(() => {
    const colors = [];
    for (let i = 0; i < filteredCustomerData.length; i++) {
      colors.push(
        `#${Math.floor(Math.random() * 0x1000000)
          .toString(16)
          .padStart(6, "0")}`
      );
    }
    setState((prevState) => ({
      ...prevState,
      lineColors: colors,
    }));
  }, [filteredCustomerData.length]);

  useEffect(() => {
    generateLineColors();
  }, [generateLineColors]);

  // const getRandomColor = () => {
  //   return `#${Math.floor(Math.random() * 0x1000000)
  //     .toString(16)
  //     .padStart(6, "0")}`;
  // };

  return (
    <>
      {processedData.length > 0 ? (
        <div
          style={{
            ...(appData.isTabletView
              ? { width: "100%", overflow: "auto", height: "610px" }
              : { undefined }),
          }}
        >
          <div
            style={{
              ...(appData.isTabletView
                ? { width: processedData.length * 150, minWidth: "100%" }
                : { undefined }),
            }}
          >
            <ResponsiveContainer height={appData.isTabletView ? 560 : 470}>
              <LineChart
                style={{ backgroundColor: "#FFFFFF" }}
                data={processedData}
                margin={{ top: 20, right: 30, left: 50, bottom: 4 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  label={{
                    value: `${
                      filterType.value === "monthly"
                        ? "Months"
                        : filterType.value === "quarterly"
                        ? "Quarters"
                        : "Years"
                    } ⟶`,
                    position: "insideBottom",
                    offset: -20,
                    color: "#333",
                    fontWeight: "bold",
                  }}
                />
                <YAxis
                  label={{
                    value: `${
                      selectedOption === "amount"
                        ? billing && settlement
                          ? "Billing/Settlement Amount ($)"
                          : billing
                          ? "Billing Amount ($)"
                          : "Settlement Amount ($)"
                        : billing && settlement
                        ? "Billing/Settlement Percent (%)"
                        : billing
                        ? "Billing Percent (%)"
                        : "Settlement Percent (%)"
                    } ⟶`,
                    angle: -90,
                    position: "insideLeft",
                    dy: 80,
                    offset: -40,
                    color: "#333",
                    fontWeight: "bold",
                  }}
                  domain={[0, maxAmount]}
                  yAxisId={0}
                  tickCount={30}
                />
                <Tooltip
                  contentStyle={{
                    maxWidth: "500px",
                    minWidth: "300px",
                    maxHeight: "400px",
                    overflow: "auto",
                    margin: 0,
                  }}
                  formatter={(value) =>
                    selectedOption === "amount" ? `$${value}` : `${value}%`
                  }
                  wrapperStyle={{
                    position: "absolute",
                    zIndex: 1,
                    pointerEvents: "auto",
                  }}
                  allowEscapeViewBox={true}
                  position="fixed"
                />
                {billing && settlement
                  ? filteredCustomerData.map((item, i) => (
                      <>
                        <Line
                          key={`${item.customer} - Billing`}
                          type="monotone"
                          dataKey={`${item.customer} - Billing`}
                          stroke={state.lineColors[i]}
                          strokeWidth={2}
                        />
                        <Line
                          key={`${item.customer} - Settlement`}
                          type="monotone"
                          dataKey={`${item.customer} - Settlement`}
                          stroke={state.lineColors[i]}
                          strokeWidth={2}
                        />
                      </>
                    ))
                  : billing
                  ? filteredCustomerData.map((item, i) => (
                      <Line
                        key={`${item.customer} - Billing`}
                        type="monotone"
                        dataKey={`${item.customer} - Billing`}
                        stroke={state.lineColors[i]}
                        strokeWidth={2}
                      />
                    ))
                  : filteredCustomerData.map((item, i) => (
                      <Line
                        key={`${item.customer} - Settlement`}
                        type="monotone"
                        dataKey={`${item.customer} - Settlement`}
                        stroke={state.lineColors[i]}
                        strokeWidth={2}
                      />
                    ))}
                <Legend
                  align="center"
                  verticalAlign="bottom"
                  wrapperStyle={{
                    ...(appData.isTabletView
                      ? {
                          // paddingLeft: "60px",
                          position: "revert",
                          height: "10px",
                          paddingTop: "20px",
                          width: "100%",
                        }
                      : {
                          width: "100%",
                          // paddingLeft: "60px",
                          height: "10px",
                          position: "revert",
                          paddingTop: "20px",
                        }),
                  }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <div className="d-flex f-justify-center" style={{ height: "26rem" }}>
          <img className={classes.noRecordsImg} src={NoRecords} />
        </div>
      )}
    </>
  );
};

export default LineChartReport;
