import { cloneElement } from "react";
import clsx from "clsx";
import MaterialDialog from "@material-ui/core/Dialog";
import MaterialDialogTitle from "@material-ui/core/DialogTitle";
import MaterialDialogContent from "@material-ui/core/DialogContent";
import MaterialDialogActions from "@material-ui/core/DialogActions";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "./style";

const DragComponent = (props) => {
  return (
    <Draggable
      bounds="parent"
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
};

const DialogActions = (props) => {
  return <MaterialDialogActions>{props.children}</MaterialDialogActions>;
};

const DialogTitle = (props) => {
  const classes = useStyles();
  const handleClose = () => {
    props.onCloseInternal();

    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <>
      <MaterialDialogTitle
        style={{ cursor: "move" }}
        id="draggable-dialog-title"
        classes={{
          root: clsx(props.className, classes.title),
        }}
      >
        {props.children}
      </MaterialDialogTitle>
      {props.hasClose && (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className={clsx("p-absolute", classes.closeIcon)}
        >
          {props.claseIcon ? (
            <img alt="Close icon" src={props.closeIcon} />
          ) : (
            <CloseIcon />
          )}
        </IconButton>
      )}
    </>
  );
};

const DialogContent = (props) => {
  return (
    <MaterialDialogContent className={props.className}>
      {props.children}
    </MaterialDialogContent>
  );
};

const Dialog = (props) => {
  let Children = [];

  props.children.forEach((child, childIndex) => {
    if (child.type === DialogTitle) {
      Children.push(
        cloneElement(child, {
          key: `dialog-child-${childIndex}`,
          onCloseInternal: props.onClose || (() => {}),
        })
      );
    } else {
      Children.push(cloneElement(child, { key: `dialog-child-${childIndex}` }));
    }
  });

  return (
    <MaterialDialog
      {...props}
      onClose={props.onClose}
      open={props.open}
      className={props.className}
      classes={props.classes}
      PaperComponent={DragComponent}
    >
      {Children}
    </MaterialDialog>
  );
};

Dialog.Title = DialogTitle;
Dialog.Content = DialogContent;
Dialog.Actions = DialogActions;

export default Dialog;
