import { useEffect, useState } from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import { Dialog } from "shared/components";
import { preventInputKeyCodes, validator } from "utils";
import Service from "../service";
import { noop, VALIDATIONS } from "shared/constants";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import useStyles from "./style";

let isFieldChange = false;
const NUMBER_TYPE_FIELDS = ["dieselPrice", "regularPrice"];
const defaultState = {
  id: null,
  regularPrice: null,
  dieselPrice: null,
  isLoading: false,
  errors: {
    regularPrice: " ",
    dieselPrice: " ",
  },
};

const Form = ({
  open = false,
  entry = null,
  dateRange = {},
  onClose = noop,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    if (entry) {
      setState((prevState) => ({
        ...prevState,
        id: entry.id || defaultState.id,
        regularPrice: entry.regular_price || defaultState.regularPrice,
        dieselPrice: entry.diesel_price || defaultState.dieselPrice,
      }));
    }
  }, [entry]);

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = {
      regularPrice: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.LIMIT, value: 10 },
      ],
      dieselPrice: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.LIMIT, value: 10 },
      ],
    };

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string",
          validation.customMessage
        )
      );

      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        console.log(message, "Message", key);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const handleFieldChange = (evt) => {
    isFieldChange = true;
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    const type = evt.currentTarget?.type || evt.target?.type;
    let errorMessage = validate(field, value) || " ";

    if (type === "number" && value.indexOf(".") !== -1) {
      let stringValue = value.toString().split(".");
      let length = stringValue[1]?.length > 2;
      if (length) {
        value = +value;
        value = Math.round((value + Number.EPSILON) * 100) / 100;
        value = value + "";
      }
    }
    if (type === "number" && value?.length > 1 && value.indexOf(".") === -1) {
      value = value?.replace(/^0+/, "");
    }

    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  const handleSubmit = async () => {
    Object.keys(state).map((key) => {
      if (NUMBER_TYPE_FIELDS.includes(key)) {
        state[key] = Number.isInteger(+state[key])
          ? (+state[key]).toFixed(0)
          : (+state[key]).toFixed(2);
      }
    });
    const { regularPrice, dieselPrice } = state;

    setState((prevState) => ({ ...prevState, isLoading: true }));

    const { error } = await Service.put({
      ...dateRange,
      id: entry?.id,
      state: entry.state,
      abbreviation: entry.abbreviation,
      regular_price: regularPrice,
      diesel_price: dieselPrice,
    });

    if (error) {
      setState((prevState) => ({ ...prevState, isLoading: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success("Fuel price updated successfully.");
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
    }));
    handleClose(true);
  };

  const handleClose = (isSubmitted = false) => {
    isFieldChange = false;
    onClose(isSubmitted);
    setState(defaultState);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <Dialog.Title
        hasClose
      >{`${entry.state} (${entry.abbreviation})`}</Dialog.Title>
      <Dialog.Content>
        <TextField
          fullWidth
          required
          type="number"
          label="Regular Price"
          variant="outlined"
          name="regularPrice"
          size="small"
          onWheel={(event) => {
            event.target.blur();
          }}
          onKeyDown={preventInputKeyCodes}
          value={state.regularPrice}
          className="mb-1 mt-2"
          onChange={handleFieldChange}
          error={!!state.errors.regularPrice.trim()}
          helperText={state.errors.regularPrice}
          InputProps={{
            startAdornment: <InputAdornment>$</InputAdornment>,
          }}
        />
        <TextField
          fullWidth
          required
          type="number"
          label="Diesel Price"
          variant="outlined"
          name="dieselPrice"
          size="small"
          onKeyDown={preventInputKeyCodes}
          onWheel={(event) => {
            event.target.blur();
          }}
          value={state.dieselPrice}
          className="mb-1 mt-2"
          onChange={handleFieldChange}
          error={!!state.errors.dieselPrice.trim()}
          helperText={state.errors.dieselPrice}
          InputProps={{
            startAdornment: <InputAdornment>$</InputAdornment>,
          }}
        />
      </Dialog.Content>
      <Dialog.Actions>
        <div className="p-4">
          <Button
            variant="outlined"
            onClick={handleClose}
            className="ml-2 mr-2"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="ml-2 mr-2"
            disabled={state.isLoading || !isFieldChange || validate()}
            onClick={handleSubmit}
          >
            Update
            {state.isLoading && (
              <CircularProgress size={24} className="p-absolute progress-btn" />
            )}
          </Button>
        </div>
      </Dialog.Actions>
    </Dialog>
  );
};

export default Form;
