import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  datepickerWrapper: {
    width: 160,
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  payDateWrapper: {
    width: 190,
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  scheduleDateWrapper: {
    "& .react-datepicker__close-icon": {
      top: 2,
      right: 36,
    },
    "& .react-datepicker__close-icon::after": {
      background: "transparent",
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: 28,
    },
  },
  paperHeight: {
    minHeight: 400,
  },
  autocompleteSearchInput: {
    minWidth: 100,
    "& .MuiInput-underline:before": {
      borderBottom: 0,
    },
    "&:hover": {
      "& .MuiInput-underline:before": {
        borderBottom: 0,
      },
    },
  },
  autocompletePaper: {
    minWidth: 150,
    maxHeight: 200,
    fontSize: 14,
  },
  dcnHistoryCloumnTitle: {
    minWidth: "40px",
  },
  holdPaddingLeft: {
    paddingLeft: "21px !important",
  },
  progressBtn: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  autocompleteInput: {
    fontSize: 14,
  },
  settlementCompanyDropdown: {
    //marginTop: "14px",
    minWidth: 198,
    maxWidth: 198,
    [theme.breakpoints.down("sm")]: {
      minWidth: 244,
      maxWidth: 244,
      marginLeft: 0,
      marginBottom: 12,
      marginRight: 10,
    },
  },
  paperSpacing: {
    marginTop: "14px !important",
    paddingTop: "0px !important",
    paddingBottom: "0px !important ",
  },
  viewHitoryPaperSpacing: {
    marginTop: "0px !important",
    paddingTop: "0px !important",
    paddingBottom: "0px !important ",
  },
  tunePaper: {
    minWidth: 232,
  },
  VisibilityIconColor: { color: "#004700" },
  fixedHeader: {
    width: "100%",
    top: "64px",
    height: "75px",
    zIndex: 1000,
    position: "sticky",
    background: "#fafafa",
    marginBottom: "6px",
  },
  kamInput: {
    minWidth: 198,
    [theme.breakpoints.down("sm")]: {
      minWidth: 244,
    },
  },
  tableHeaderCell: {
    minWidth: 144,
  },
  icidFlagTitle: {
    minWidth: "180px",
    paddingLeft: "28px !important",
  },
  customerHeaderCell: {
    minWidth: 162,
  },
  kamInputRoot: {
    overflow: "auto !important",
    maxWidth: 684,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 244,
    },
  },
  paper: (props) => ({
    "& .MuiDialogTitle-root": {
      paddingBottom: "0px",
    },
    maxWidth: "90%",
    minWidth: "90%",
    maxHeight: props.isTabletView ? "95%" : "90%",
  }),
  updatedData: {
    marginTop: "20px",
  },
  upDatedRow: { paddingTop: "40px" },
}));

export default useStyles;
