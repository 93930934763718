import { useState, useRef, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import clsx from "clsx";

import { ProfileAvatar, Settings } from "shared/components";
import { getTokenData, removeAuthData } from "utils";
import { ProfileModal } from "modules/shared/components";
import PersonIcon from "@material-ui/icons/Person";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Typography from "@material-ui/core/Typography";
import SettingsIcon from "@material-ui/icons/Settings";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import useStyles from "./style";
import { Avatar } from "@material-ui/core";
import { SharedService } from "modules/shared";
import { toast } from "react-toastify/dist";

const defaultState = {
  isOpen: false,
  openProfileModal: false,
  entries: [],
  isLoading: false,
};

const ProfileMenu = ({ history, listItems = [], profileEdit = null }) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);
  const arrowRef = useRef(null);
  const anchorRef = useRef(null);
  const { id, role, email } = getTokenData() || {};

  const fetchEntries = useCallback(async () => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    const { data, error } = await SharedService.getUsersList();
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }
    setState((prevState) => ({
      ...prevState,
      entries: data?.users || defaultState.entries,
      isLoading: false,
    }));
  }, []);

  const matchingEntry = state.entries?.find((entry) => entry?.id === id);

  const userData = {
    id: id || "",
    profile_image_path: matchingEntry
      ? matchingEntry?.profile_image_path
      : null,
    fullName: `${matchingEntry?.first_name || ""} ${
      matchingEntry?.last_name || ""
    }`,
    first_name: `${matchingEntry?.first_name || ""}`,
    last_name: `${matchingEntry?.last_name || ""}`,
    username: `${matchingEntry?.username || ""}`,
    role: `${role || ""}`,
    email: `${email || ""}`,
    phone: `${matchingEntry?.phone || ""}`,
    location: matchingEntry?.location || "",
    manager: matchingEntry?.manager || null,
  };

  const handleSignOut = () => {
    removeAuthData();
    history.push("/sign-in");
  };

  const handleViewProfile = () => {
    setState((prevState) => ({
      ...prevState,
      openProfileModal: true,
    }));
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      openProfileModal: false,
    }));
  };

  const isRoleKAM = role === "KAM";

  useEffect(() => {
    fetchEntries();
  }, []);

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      onClickAway={() => {
        setState((prevState) => ({ ...prevState, isOpen: false }));
      }}
    >
      <div>
        <div className="d-flex f-align-center">
          <div className={classes.arrowRight}></div>
          <div
            className={clsx(
              "d-flex f-align-center f-justify-between p-3 c-pointer",
              classes.caretWrapper
            )}
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                isOpen: !prevState.isOpen,
              }));
            }}
          >
            {state.isLoading ? (
              <CircularProgress size={16} style={{ margin: "12px" }} />
            ) : userData?.profile_image_path === null ||
              userData?.profile_image_path === undefined ? (
              <ProfileAvatar userData={userData} />
            ) : (
              <Avatar src={userData?.profile_image_path} />
            )}
            <Typography variant="body2" className="pl-2 pr-2" noWrap>
              {userData.fullName}
            </Typography>
            <ArrowDropDownIcon innerRef={anchorRef} fontSize="large" />
          </div>
        </div>
        <div className="popper-root">
          <Popper
            open={state.isOpen}
            anchorEl={anchorRef.current}
            disablePortal
            className="popper-w-arrow"
            placement="bottom-end"
            keepMounted
            modifiers={{
              hide: {
                enabled: false,
              },
              arrow: {
                enabled: true,
                element: arrowRef.current,
              },
              flip: {
                enabled: false,
              },
              preventOverflow: {
                enabled: false,
                boundariesElement: "scrollParent",
              },
            }}
          >
            <span className="popper-arrow" ref={arrowRef} />
            <Paper elevation={4} className={classes.paper}>
              <div
                className={clsx("d-flex p-3", classes.profileDetailsWrapper)}
              >
                {state.isLoading ? (
                  <CircularProgress
                    size={18}
                    style={{
                      marginTop: "14px",
                      marginRight: "10px",
                      marginLeft: "12px",
                    }}
                  />
                ) : userData?.profile_image_path === null ||
                  userData?.profile_image_path === undefined ? (
                  <ProfileAvatar userData={userData} />
                ) : (
                  <Avatar src={userData?.profile_image_path} />
                )}
                <div className="pl-5">
                  <Typography variant="body1">{`${userData.fullName}`}</Typography>
                  <Typography variant="body1">{`(${userData.username})`}</Typography>
                  <Typography variant="caption">
                    Role: <span className="color-primary">{role}</span>
                  </Typography>
                </div>
              </div>
              <MenuList>
                {[
                  profileEdit && (
                    <MenuItem onClick={() => history.push(profileEdit.url)}>
                      <ListItemIcon className={classes.listIcon}>
                        {profileEdit.icon ? (
                          <profileEdit.icon />
                        ) : (
                          <EditOutlinedIcon />
                        )}
                      </ListItemIcon>
                      <ListItemText primary={profileEdit.label} />
                    </MenuItem>
                  ),
                  ...listItems,
                  <MenuItem onClick={handleViewProfile}>
                    <ListItemIcon className={classes.listIcon}>
                      <PersonIcon />
                    </ListItemIcon>

                    <ListItemText primary="View/Edit profile" />
                  </MenuItem>,

                  <MenuItem onClick={handleSignOut}>
                    <ListItemIcon className={classes.listIcon}>
                      <ExitToAppOutlinedIcon />
                    </ListItemIcon>

                    <ListItemText primary="Sign out" />
                  </MenuItem>,
                ].map((item, index) => (
                  <div key={index}>{item}</div>
                ))}
              </MenuList>
            </Paper>
          </Popper>

          {state.openProfileModal && (
            <ProfileModal
              open={state.openProfileModal}
              userInfo={userData}
              isEditable={true}
              onClose={handleClose}
              fetchEntries={fetchEntries}
              isLoading={state.isLoading}
            />
          )}
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default withRouter(ProfileMenu);
