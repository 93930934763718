import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import useTheme from '@material-ui/core/styles/useTheme';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { noop } from 'shared/constants';
import { Dropdown } from '../';
import { renderLeftActions, renderRightActions } from './helpers';
import { useToolbarStyles } from './style';

const FilterToolbar = ({
	renderContent = noop,
	filters = [],
	isFiltering = false,
	disabled = false,
	onClose = noop,
	onSearch = noop,
	numSelected = 0,
	search = '',
	actions = { left: [], right: [] },
}) => {
	const classes = useToolbarStyles();
	const theme = useTheme();
	// debugger;
	return (
		<Toolbar
			className={clsx(classes.root, { [classes.highlight]: isFiltering })}
			tabIndex={-1}
		>
			<div className='d-flex f-align-center f-justify-between flex-1'>
				{renderLeftActions(actions.left, theme, numSelected, 'filter')}
				<TextField
					variant='standard'
					defaultValue={search}
					placeholder='Search..'
					onChange={onSearch}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<SearchIcon />
							</InputAdornment>
						),
					}}
					disabled={disabled}
				/>
				<div>
					{filters.map((element, elementIndex) => {
						if (element.render) {
							return element.render();
						}

						return (
							<Dropdown
								key={`filter-dropdown-${elementIndex}`}
								{...element}
								classes={{
									...element.classes,
									wrapper: `ml-6 ${element.classes?.wrapper}`,
								}}
								placement={element.placement || 'bottom-start'}
								disabled={
									element.disabled !== undefined && element.disabled !== null
										? element.disabled
										: disabled
								}
							/>
						);
					})}
					{renderRightActions(actions.right, theme, numSelected, 'filter')}
					<CloseIcon className='ml-5 c-pointer' onClick={onClose} />
				</div>
			</div>
		</Toolbar>
	);
};

export default FilterToolbar;
