import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import { NavLink } from "react-router-dom/";
import Link from "@material-ui/core/Link";
import ViewInvoice from "../../../assets/icons/viewInvoice.svg";
import ApproveInvoice from "../../../assets/icons/approveInvoice.svg";
import paymentHistory from "../../../assets/icons/paymentHistory.svg";
import GenerateInvoice from "../../../assets/icons/generateInvoice.svg";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import useStyles from "./style";
import GenerateInvoiceDialog from "../generateInvoice";

const defaultState = {
  open: false,
  isLoading: false,
};

const InvoiceHomePage = ({ history }) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);

  const handleOpen = () => {
    setState((prevState) => ({
      ...prevState,
      open: true,
    }));
  };

  const handleClose = (close) => {
    setState((prevState) => ({
      ...prevState,
      open: close,
    }));
  };

  return (
    <div>
      <div>
        <Typography variant="h4" color="primary" className=" ml-2">
          Invoices & Payments
          <Button
            style={{ float: "right" }}
            className="mt-1 mr-2"
            variant="contained"
            color="primary"
            onClick={() => history.push("/home")}
            startIcon={<ArrowBackIcon />}
          >
            Back To Home
          </Button>
        </Typography>
        <Typography variant="body2" style={{ marginLeft: "10px" }}>
          Select to view, edit and generate Invoices & Payments
        </Typography>
      </div>

      <Grid
        container
        spacing={2}
        alignItems="center"
        justify="center"
        className={classes.wrapper}
      >
        <Grid item sm={3} md={4} className={classes.griddddd}>
          <div
            className="d-flex f-align-center flex-column"
            style={{ paddingTop: "98px" }}
          >
            <Button
              variant="outlined"
              size="large"
              className={classes.iconButton}
              onClick={handleOpen}
            >
              <img
                src={GenerateInvoice}
                alt="generateInvoice"
                className={classes.icon}
              />
            </Button>
            <Typography
              variant={"body1"}
              className={clsx("mt-2 text-bold", classes.dahsboardItemTitle)}
            >
              Generate Invoice
            </Typography>
          </div>
        </Grid>
        <Grid item sm={3} md={4} className={classes.griddddd}>
          <div
            className="d-flex f-align-center flex-column"
            style={{ paddingTop: "98px" }}
          >
            <Link to="/invoice/view_invoice" component={NavLink}>
              <Button
                variant="outlined"
                size="large"
                className={classes.iconButton}
                onClick={() => history.push("/invoice/view_invoice")}
              >
                <img
                  src={ViewInvoice}
                  alt="viewInvoice"
                  className={classes.icon}
                />
              </Button>
            </Link>
            <Typography
              variant={"body1"}
              className={clsx("mt-2 text-bold", classes.dahsboardItemTitle)}
            >
              View Invoices
            </Typography>
          </div>
        </Grid>
        <Grid item sm={3} md={4} className={classes.griddddd}>
          <div
            className="d-flex f-align-center flex-column"
            style={{ paddingTop: "98px" }}
          >
            <Link to="/invoice/record_payment" component={NavLink}>
              <Button
                variant="outlined"
                size="large"
                className={classes.iconButton}
                onClick={() => history.push("/invoice/record_payment")}
              >
                <img
                  src={paymentHistory}
                  alt="approveInvoice"
                  className={clsx("ml-2", classes.icon)}
                />
              </Button>
            </Link>
            <Typography
              variant={"body1"}
              className={clsx("mt-2 text-bold", classes.dahsboardItemTitle)}
            >
              Payments
            </Typography>
          </div>
        </Grid>
        <Grid item sm={3} md={4} className={classes.griddddd}>
          <div
            className="d-flex f-align-center flex-column"
            style={{ paddingTop: "98px" }}
          >
            <Link to="/invoice/approve_invoice" component={NavLink}>
              <Button
                variant="outlined"
                size="large"
                className={classes.iconButton}
                onClick={() => history.push("/invoice/approve_invoice")}
              >
                <img
                  src={ApproveInvoice}
                  alt="ApproveInvoice"
                  className={clsx("mr-2", classes.icon)}
                />
              </Button>
            </Link>
            <Typography
              variant={"body1"}
              className={clsx("mt-2 text-bold", classes.dahsboardItemTitle)}
            >
              Approve/Reject Invoices
            </Typography>
          </div>
        </Grid>
      </Grid>

      <GenerateInvoiceDialog
        open={state.open}
        handleClose={handleClose}
        history={history}
      />
    </div>
  );
};

export default InvoiceHomePage;
