import { useEffect, useState } from "react";
import useStyles from "./style";
import { Datepicker, Dialog, VALIDATIONS, noop } from "shared";
import { getDateString, preventInputKeyCodes, validator } from "utils";
import clsx from "clsx";
import { Autocomplete } from "@material-ui/lab";
import {
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { toast } from "react-toastify";
import Service from "../service";
import { modeOfPaymentList } from "modules/shared/constants";

const defaultState = {
  customer: null,
  location: null,
  pdxCompany: null,
  modeOfPayment: null,
  invoiceNumber: null,
  isRecordPayment: false,
  referenceNumber: "",
  errors: {
    referenceNumber: " ",
    amount: " ",
  },
  balanceAmount: 0,
  amount: 0,
  paymentDate: getDateString(new Date()),
};

const RecordPayment = ({
  customerList,
  entry = {},
  open = false,
  handleClose = noop,
  handleFetch = noop,
  getInvoice = noop,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    if (!!entry && Object.keys(entry).length > 0) {
      setState((prevState) => ({
        ...prevState,
        pdxCompany:
          customerList
            .map((item) =>
              item.customer_branches.find(
                (branch) =>
                  branch.id === entry.invoiceDetails?.customer_branch_id
              )
            )
            .find((branch) => branch !== undefined) || defaultState.pdxCompany,
        customer:
          customerList.find((item) =>
            item.customer_branches.some(
              (branch) => branch.id === entry.invoiceDetails?.customer_branch_id
            )
          ) || defaultState.customer,
        location:
          customerList
            .map((item) =>
              item.customer_branches.find(
                (branch) =>
                  branch.id === entry.invoiceDetails?.customer_branch_id
              )
            )
            .find((branch) => branch !== undefined) || defaultState.location,
        invoiceNumber:
          entry.invoiceDetails.serial_no || defaultState.invoiceNumber,
        balanceAmount:
          entry.invoiceDetails.balance_amt || defaultState.balanceAmount,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry, open]);

  const handleSubmit = async (payload) => {
    setState((prevState) => ({ ...prevState, isRecordPayment: true }));

    const { error } = await Service.recordPayment(payload.data);

    if (error) {
      setState((prevState) => ({ ...prevState, isRecordPayment: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success("Payment recorded successfully.");
    setState((prevState) => ({
      ...prevState,
      isRecordPayment: false,
    }));
    handleFetch("invoiceLoading");
    getInvoice({ id: payload.data?.invoice_id }, true);
    onClose();
  };

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = {
      referenceNumber: [{ type: VALIDATIONS.MAX_LENGTH, value: 100 }],
      amount: [{ type: VALIDATIONS.MAX_LENGTH, value: 10.2 }],
    };

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const handleFieldChange = (evt) => {
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    let errorMessage = validate(field, value) || " ";

    if (field === "amount" && value.indexOf(".") !== -1) {
      let s = value.toString().split(".");
      let length = s[1]?.length > 2;
      if (length) {
        value = +value;
        value = Math.round((+value + Number.EPSILON) * 100) / 100;
        value = value.toFixed(2);
      }
    }
    if (field === "amount" && value?.length > 1 && value.indexOf(".") === -1) {
      value = value?.replace(/^0+/, "");
    }

    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  const onClose = () => {
    handleClose(false);
    setState(defaultState);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
        <Dialog.Title hasClose>Record Payment</Dialog.Title>
        <Dialog.Content>
          <div className="d-flex f-align-center">
            <TextField
              className={clsx("mr-4 mt-4")}
              disabled
              fullWidth
              label="PDX Company"
              variant="outlined"
              size="small"
              value={state.pdxCompany?.pdxCompany?.value || "-"}
              helperText=" "
            />
            <TextField
              className={clsx("mr-4 mt-4")}
              disabled
              fullWidth
              label="Customer"
              variant="outlined"
              size="small"
              value={state.customer?.name || "-"}
              helperText=" "
            />
          </div>
          <div className="d-flex f-align-center">
            <TextField
              className={clsx("mr-4 mt-4")}
              disabled
              fullWidth
              label="Location"
              variant="outlined"
              size="small"
              value={state.location?.location || "-"}
              helperText=" "
            />
            <Datepicker
              mask
              preventClear
              label="Payment date"
              required
              selected={state.paymentDate}
              maxDate={new Date()}
              classes={{
                input: {
                  root: clsx("mr-4 mt-4", classes.datepickerWrapper),
                },
              }}
              onChange={(paymentDate) => {
                setState((prevState) => ({
                  ...prevState,
                  paymentDate: getDateString(paymentDate),
                }));
              }}
            />
          </div>
          <div className="d-flex f-align-center">
            <TextField
              className={clsx("mr-4 mt-4")}
              disabled
              fullWidth
              label="Invoice No."
              variant="outlined"
              size="small"
              value={state.invoiceNumber || "-"}
              helperText=" "
            />
            <Autocomplete
              disableClearable
              fullWidth
              className="mr-4 mt-4"
              size="small"
              required
              value={state.modeOfPayment}
              options={modeOfPaymentList}
              getOptionLabel={(option) => option.label || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Mode"
                  variant="outlined"
                  helperText=" "
                />
              )}
              onChange={(evt, value) => {
                setState((prevState) => ({
                  ...prevState,
                  modeOfPayment: value,
                }));
              }}
            />
          </div>
          <div className="d-flex f-align-center">
            <TextField
              className={clsx("mr-4 mt-4")}
              disabled
              fullWidth
              label="Balance Amount"
              InputProps={{
                startAdornment: <InputAdornment>$</InputAdornment>,
              }}
              variant="outlined"
              size="small"
              value={state.balanceAmount}
              helperText=" "
            />
            <TextField
              className={clsx("mr-4 mt-4")}
              required
              fullWidth
              label="Amount"
              type="number"
              variant="outlined"
              size="small"
              name="amount"
              InputProps={{
                startAdornment: <InputAdornment>$</InputAdornment>,
              }}
              value={state.amount}
              onKeyDown={preventInputKeyCodes}
              error={!!state.errors.amount?.trim()}
              helperText={state.errors.amount}
              onChange={handleFieldChange}
            />
          </div>
          <TextField
            className={clsx("mr-4 mt-4")}
            fullWidth
            style={{ width: "47%" }}
            label="Reference No."
            name="referenceNumber"
            variant="outlined"
            size="small"
            value={state.referenceNumber}
            error={!!state.errors.referenceNumber?.trim()}
            helperText={state.errors.referenceNumber}
            onChange={handleFieldChange}
          />
        </Dialog.Content>
        <Dialog.Actions>
          <div className="p-4">
            <Button variant="outlined" onClick={onClose} className="ml-2 mr-2">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="ml-2 mr-2"
              disabled={
                state.isRecordPayment ||
                validate() ||
                !state.amount ||
                state.amount == 0 ||
                !state.modeOfPayment
              }
              onClick={() =>
                handleSubmit({
                  data: {
                    date: state.paymentDate,
                    invoice_id: entry.invoiceDetails?.id,
                    amount: state.amount,
                    reference_number: state.referenceNumber || null,
                    mode: state.modeOfPayment.value,
                  },
                })
              }
            >
              Create
              {state.isRecordPayment && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </Button>
          </div>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default RecordPayment;
