import makeStyle from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyle((theme) => ({
  paper: {
    minWidth: 150,
    maxHeight: 200,
  },
  textField: {
    minWidth: 150,
  },
}));

export default useStyles;
