import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom/";
import Drawer from "@material-ui/core/Drawer";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";
import ListItemText from "@material-ui/core/ListItemText";
import { AppContext } from "shared/contexts";
import OrgLogo from "shared/assets/images/org-logo.png";
import newOrgLogo from "shared/assets/images/pdx-logo.png";

import useStyles from "./style";

const SidebarHeaderLayout = ({
  children = <></>,
  headerElements = <></>,
  isDrawerOpen = false,
  sidebarElements = [],
  alertBar = <></>,
}) => {
  const location = useLocation();
  const { appData } = useContext(AppContext);
  const { isTabletView = false } = appData;
  const isDashboard = location?.pathname === "/home";
  const [open, setOpen] = useState(isDrawerOpen);
  const [alertBarHeight, setAlertBarHeight] = useState(0);
  const classes = useStyles({ isOpen: open, isDashboard });
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    isDashboard && handleDrawerClose();
  }, [isDashboard]);

  const alertBarRef = useCallback(
    (node) => {
      if (node !== null) {
        setAlertBarHeight(node.getBoundingClientRect().height || 0);
      }
    },
    [alertBar]
  );

  const DrawerContent = () => {
    return (
      <>
        <List>
          {sidebarElements.map((element) => (
            <Tooltip
              title={element.tooltip || element.label}
              placement="right"
              disableHoverListener={open}
              disableTouchListener={open}
            >
              <ListItem
                component={NavLink}
                activeClassName={classes.activeListItem}
                to={element.to}
                key={element.label}
                className={clsx("ml-1 pt-1 pb-1 pl-2", classes.listItem, {
                  "c-default": element.canNavigateTo
                    ? !element.canNavigateTo()
                    : false,
                })}
              >
                <ListItemIcon>{element.icon}</ListItemIcon>
                <ListItemText primary={element.label} />
              </ListItem>
            </Tooltip>
          ))}
        </List>
        <div className={classes.poweredBy}>
          <p className={classes.fadeOutElement}>Powered by</p>
          <img alt="Organization logo" src={newOrgLogo} />
        </div>
      </>
    );
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open && !isTabletView,
        })}
      >
        <div ref={alertBarRef}>{alertBar}</div>
        {isDashboard && (
          <div
            className={clsx(
              "d-flex f-align-center f-justify-between flex-column pb-2",
              classes.poweredByHeader
            )}
          >
            <p>Powered by</p>
            <img
              alt="Organization logo"
              src={newOrgLogo}
              className={classes.pdxLogo}
            />
          </div>
        )}
        <Toolbar>
          {!isDashboard && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open && !isTabletView,
              })}
            >
              <MenuIcon />
            </IconButton>
          )}
          {headerElements}
        </Toolbar>
      </AppBar>
      {!isDashboard && !isTabletView && (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div
            className={classes.toolbar}
            style={{
              marginTop: alertBarHeight / 2,
              marginBottom: alertBarHeight / 2,
            }}
          >
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <DrawerContent />
        </Drawer>
      )}
      {isTabletView && !isDashboard && (
        <SwipeableDrawer
          open={open}
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}
          anchor="left"
        >
          <div
            style={{
              marginTop: alertBarHeight / 2,
              marginBottom: alertBarHeight / 2,
            }}
          >
            <div className="d-flex f-align-center f-justify-end mt-3">
              <IconButton onClick={handleDrawerClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <DrawerContent />
          </div>
        </SwipeableDrawer>
      )}
      <main className={classes.content} style={{ marginTop: alertBarHeight }}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

SidebarHeaderLayout.propTypes = {
  children: PropTypes.element,
  headerElements: PropTypes.element,
  sidebarElements: PropTypes.array,
};

export default SidebarHeaderLayout;
