import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
  paper: {
    width: 484,
    minHeight: 236,
  },
  checkboxLabel: {
    width: 55,
  },
}));

export default useStyles;
