import makeStyles from "@material-ui/core/styles/makeStyles";
import red from "@material-ui/core/colors/red";
import { fontSize, fontWeight } from "@material-ui/system";

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  highlightDisable: {
    background: theme.palette.grey[200],
  },
  inactiveICID: {
    background: red[400],
  },
  tableHeaderCell: {
    minWidth: 144,
  },
  notification: {
    border: `2px solid ${theme.palette.grey[500]}`,
  },
  warningIcon: {
    color: "#d7d718",
    zIndex: 1,
    position: "relative",
    marginTop: "2px",
  },
  warningIconHeight: {
    height: "24px",
    position: "relative",
  },
  exclamationMark: {
    width: "3px",
    height: "9px",
    background: "black",
    position: "absolute",
    top: "11px",
    left: "11px",
  },
  paperSize: {
    width: 316,
  },
  paperSpacing: {
    marginTop: "14px !important",
    paddingTop: "0px !important",
  },
  customSpace: {
    whiteSpace: "pre",
  },
  kamInputRoot: {
    // maxHeight: 190,
    overflow: "auto !important",
    maxWidth: 684,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 244,
    },
  },
  kamInput: {
    minWidth: 198,
    [theme.breakpoints.down("sm")]: {
      minWidth: 244,
    },
  },
  autocompleteSearchInput: {
    minWidth: 100,
    "& .MuiInput-underline:before": {
      borderBottom: 0,
    },
    "&:hover": {
      "& .MuiInput-underline:before": {
        borderBottom: 0,
      },
    },
  },
  tuneMargin: {
    marginLeft: 870,
  },
  autocompletePaper: {
    minWidth: 150,
    maxHeight: 200,
    fontSize: 14,
  },
  searchBox: { width: "312px" },
  autocompleteInput: {
    fontSize: 14,
  },
  icidTitle: {
    minWidth: "450px",
  },
  tableHeaderwidth: {
    minWidth: 144,
    maxWidth: 144,
  },
  wrapTableTitle: {
    minWidth: 125,
    maxWidth: 125,
  },
  pdxCompanyHeader: {
    minWidth: 200,
    maxWidth: 200,
  },
  tableHeaderNotesWidth: {
    minWidth: 200,
    maxWidth: 200,
  },
  icidFlagTitle: {
    minWidth: 320,
    maxWidth: 320,
    paddingLeft: "48px",
  },
  bottomNavigation: {
    backgroundColor: "white",
    position: "fixed",
    bottom: 0,
    right: 0,
    width: "100%",
  },
  tunePaper: {
    minWidth: 200,
  },
  label: {
    fontSize: "x-large",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
  },
  arrow: {
    color: "blue",
    marginLeft: theme.spacing(1),
  },
  dropdownItem: {
    padding: theme.spacing(1, 2),
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  fixedHeader: {
    width: "100%",
    top: "64px",
    marginBottom: "12px",
    height: "64px",
    zIndex: 1000,
    position: "sticky",
    background: "#fafafa",
  },
}));

export default useStyles;
