import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  Fragment,
} from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import {
  Datepicker,
  Dropdown,
  Grid,
  FilterComponent,
  ActionDialog,
  Dialog,
} from "shared/components";
import { INVOICE_TYPES } from "modules/shared/constants";
import { PAGE_KEYS, isTransferScheduleEnable } from "shared/constants";
import {
  exportBase64ToFile,
  getDateString,
  getPageConfig,
  setPageConfig,
  getTokenData,
  queryStringBuilder,
  updateLayout,
  updatePageConfig,
  getUTCDateString,
} from "utils";
import format from "date-fns/format";
import { AppContext } from "shared/contexts";
import FilterListIcon from "@material-ui/icons/FilterList";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { ROLE } from "modules/shared/constants";
import SharedService from "modules/shared/service";
import Service from "../service";
import FlagIcon from "@material-ui/icons/Flag";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ForceSubmitDialog from "../../daily-schedule/forceSubmit";
import FormLabel from "@material-ui/core/FormLabel";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Skeleton from "@material-ui/lab/Skeleton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TuneIcon from "@material-ui/icons/Tune";
import useStyles from "./style";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ReviewNotesIcon from "../../../assets/icons/review-notes-icon.svg";
import WarningIcon from "@material-ui/icons/Warning";

let gridHelper = null;
const REPORT_TYPE = [
  {
    label: "Billing",
    value: "billing",
    counts: {
      "Bill Amount": "bill_amount",
      "Bill Fuel Amount": "bill_fuel_amount",
      "Bill Extra Miles Amount": "bill_extra_mile_amount",
      "Bill Toll Amount": "bill_toll_amount",
      "Total Bill Amount": "total_bill_amount",
    },
    dynamicColumns: [
      "Select All",
      "KAM",
      "Office",
      "Location",
      "Route",
      "State",
      "PDX Company",
      "Vehicle",
      "Bill Rate Type",
      "Bill Rate",
      "Bill Quantity",
      "Bill Amount",
      "Bill Fuel Surcharge%",
      "Bill Fuel Amount",
      "Bill Toll Amount",
      "Bill Extra Miles",
      "Bill Rate Per Extra Mile",
      "Bill Extra Miles Amount",
    ].reduce((acc, column) => {
      acc[column] = {
        label: column,
        value: column,
        isSelected: true,
      };
      return acc;
    }, {}),
  },
  {
    label: "Settlement",
    value: "settlement",
    counts: {
      "Pay Amount": "pay_amount",
      "Pay Fuel Amount": "pay_fuel_amount",
      "Pay Extra Miles Amount": "pay_extra_mile_amount",
      Reimbursement: "reimbursement",
      Deduction: "deduction",
      "Pay Toll Amount": "pay_toll_amount",
      "Total Pay Amount": "total_pay_amount",
    },
    dynamicColumns: [
      "Select All",
      "Customer Name",
      "Location",
      "KAM",
      "Office",
      "Route",
      "State",
      "PDX Company",
      "Vehicle",
      "ICID/IC Name",
      "Pay Rate Type",
      "Pay Rate",
      "Pay Quantity",
      "Pay Amount",
      "Pay Fuel Surcharge%",
      "Pay Fuel Amount",
      "Pay Toll Amount",
      "Pay Extra Miles",
      "Pay Rate Per Extra Mile",
      "Pay Extra Miles Amount",
      "Reimbursement",
      "Deduction",
      "Deduction Reason",
    ].reduce((acc, column) => {
      acc[column] = {
        label: column,
        value: column,
        isSelected: true,
      };
      return acc;
    }, {}),
    dynamicColumnsKam: [
      "Select All",
      "Customer Name",
      "Location",
      "Office",
      "Route",
      "State",
      "PDX Company",
      "Vehicle",
      "ICID/IC Name",
      "Pay Rate Type",
      "Pay Rate",
      "Pay Quantity",
      "Pay Amount",
      "Pay Fuel Surcharge%",
      "Pay Fuel Amount",
      "Pay Toll Amount",
      "Pay Extra Miles",
      "Pay Rate Per Extra Mile",
      "Pay Extra Miles Amount",
      "Reimbursement",
      "Deduction",
      "Deduction Reason",
    ].reduce((acc, column) => {
      acc[column] = {
        label: column,
        value: column,
        isSelected: true,
      };
      return acc;
    }, {}),
  },
  {
    label: "Billing & Settlements",
    value: "billingandsettlement",
    counts: {
      "Bill Amount": "bill_amount",
      "Bill Fuel Amount": "bill_fuel_amount",
      "Bill Extra Miles Amount": "bill_extra_mile_amount",
      "Pay Amount": "pay_amount",
      "Pay Fuel Amount": "pay_fuel_amount",
      "Pay Extra Miles Amount": "pay_extra_mile_amount",
      Reimbursement: "reimbursement",
      Deduction: "deduction",
      "Pay Toll Amount": "pay_toll_amount",
      "Bill Toll Amount": "bill_toll_amount",
      "Total Bill Amount": "total_bill_amount",
      "Total Pay Amount": "total_pay_amount",
    },
    dynamicColumns: [
      "Select All",
      "KAM",
      "Office",
      "Location",
      "Route",
      "State",
      "PDX Company",
      "Vehicle",
      "Bill Rate Type",
      "Bill Rate",
      "Bill Quantity",
      "Bill Amount",
      "Bill Fuel Surcharge%",
      "Bill Fuel Amount",
      "Bill Toll Amount",
      "Bill Extra Miles",
      "Bill Rate Per Extra Mile",
      "Bill Extra Miles Amount",
      "ICID/IC Name",
      "Pay Rate Type",
      "Pay Rate",
      "Pay Quantity",
      "Pay Amount",
      "Pay Fuel Surcharge%",
      "Pay Fuel Amount",
      "Pay Toll Amount",
      "Pay Extra Miles",
      "Pay Rate Per Extra Mile",
      "Pay Extra Miles Amount",
      "Reimbursement",
      "Deduction",
      "Deduction Reason",
    ].reduce((acc, column) => {
      acc[column] = {
        label: column,
        value: column,
        isSelected: true,
      };
      return acc;
    }, {}),
  },
  {
    label: "Billing(With IC Info)",
    value: "billingwithic",
    counts: {
      "Bill Amount": "bill_amount",
      "Bill Fuel Amount": "bill_fuel_amount",
      "Bill Extra Miles Amount": "bill_extra_mile_amount",
      "Bill Toll Amount": "bill_toll_amount",
      "Total Bill Amount": "total_bill_amount",
    },
    dynamicColumns: [
      "Select All",
      "KAM",
      "Office",
      "Location",
      "Route",
      "State",
      "PDX Company",
      "Vehicle",
      "ICID/IC Name",
      "Bill Rate Type",
      "Bill Rate",
      "Bill Quantity",
      "Bill Amount",
      "Bill Fuel Surcharge%",
      "Bill Fuel Amount",
      "Bill Toll Amount",
      "Bill Extra Miles",
      "Bill Rate Per Extra Mile",
      "Bill Extra Miles Amount",
    ].reduce((acc, column) => {
      acc[column] = {
        label: column,
        value: column,
        isSelected: true,
      };
      return acc;
    }, {}),
  },
  {
    label: "Billing Invoice",
    value: "billingInvoice",
    counts: {
      "Bill Amount": "bill_amount",
      "Bill Fuel Amount": "bill_fuel_amount",
      "Bill Extra Miles Amount": "bill_extra_mile_amount",
      "Bill Toll Amount": "bill_toll_amount",
      "Total Bill Amount": "total_bill_amount",
    },
    dynamicColumns: [
      "Select All",
      "Location",
      "IC Name",
      "Route",
      "Bill Rate",
      "Bill Quantity",
      "Bill Amount",
      "Bill Fuel Surcharge%",
      "Bill Fuel Amount",
      "Bill Extra Miles",
      "Bill Rate Per Extra Mile",
      "Bill Extra Miles Amount",
      "Bill Toll Amount",
    ].reduce((acc, column) => {
      acc[column] = {
        label: column,
        value: column,
        isSelected: true,
      };
      return acc;
    }, {}),
  },
];
const defaultState = {
  entries: [],
  isFetching: false,
  isFiltering: false,
  viewNotes: false,
  pageFilters: [],
  isFetchingList: {
    customers: false,
    settlementCompanies: false,
    filters: false,
    IC: false,
  },
  reportType: REPORT_TYPE[0],
  startDate: null,
  endDate: null,
  showGrid: false,
  openForceSubmitDialog: false,
  totalCounts: {},
  customer: null,
  billingCustomer: null,
  billingLocation: null,
  pdxCompanyList: [],
  filters: {
    reportType: null,
    location: [],
    route: [],
    vehicle_type: [],
    settlementCompany: [],
    pdxCompany: [],
    IC: [],
    customer: [],
    kamId: [],
    aaId: [],
  },
  settlementCompanyList: [],
  filterData: {
    aa: [],
    kam: [],
    location: [],
    route: [],
    vehicle: [],
  },
  dynamicColumnsKam: REPORT_TYPE.reduce((acc, reportType) => {
    acc[reportType.value] = reportType.dynamicColumnsKam;
    return acc;
  }, {}),
  dynamicColumns: REPORT_TYPE.reduce((acc, reportType) => {
    acc[reportType.value] = reportType.dynamicColumns;
    return acc;
  }, {}),
};

const ViewReport = ({ match, history }) => {
  const pageConfig = getPageConfig(PAGE_KEYS.REPORT);
  let isGoButtonIsClicked =
    (pageConfig && pageConfig?.isGoButtonIsClicked) || false;
  const classes = useStyles();
  const userData = getTokenData();
  const { appData, setHeaderElements, updateContextData } =
    useContext(AppContext);
  const currentUser = getTokenData() || {};
  const isRoleKAM = (currentUser?.role || "").toLowerCase() === ROLE.KAM;
  const [state, setState] = useState({
    ...defaultState,
    billingLocation: isGoButtonIsClicked
      ? (pageConfig && pageConfig?.billingLocationFilter) ||
        defaultState.billingLocation
      : defaultState.billingLocation,
    billingCustomer: isGoButtonIsClicked
      ? (pageConfig && pageConfig?.billingCustomerFilter) ||
        defaultState.billingCustomer
      : defaultState.billingCustomer,
    filters: {
      ...defaultState.filters,
      customer: isGoButtonIsClicked
        ? (pageConfig && pageConfig?.customerFilter) ||
          defaultState.filters.customer
        : defaultState.filters.customer,
      IC: isGoButtonIsClicked
        ? (pageConfig && pageConfig?.ICFilter) || defaultState.filters.IC
        : defaultState.filters.IC,
      settlementCompany: isGoButtonIsClicked
        ? (pageConfig && pageConfig?.settlementCompanyFilter) ||
          defaultState.filters.settlementCompany
        : defaultState.filters.settlementCompany,
      location: isGoButtonIsClicked
        ? (pageConfig && pageConfig?.locationFilter) ||
          defaultState.filters.location
        : defaultState.filters.location,
      vehicle_type: isGoButtonIsClicked
        ? (pageConfig && pageConfig?.vehicleFilter) ||
          defaultState.filters.vehicle_type
        : defaultState.filters.vehicle_type,
      aaId: isGoButtonIsClicked
        ? (pageConfig && pageConfig?.aaIdFilter) || defaultState.filters.aaId
        : defaultState.filters.aaId,
      pdxCompany: isGoButtonIsClicked
        ? (pageConfig && pageConfig?.pdxCompanyFilter) ||
          defaultState.filters.pdxCompany
        : defaultState.filters.pdxCompany,
      kamId: isGoButtonIsClicked
        ? (pageConfig && pageConfig?.kamIdFilter) || defaultState.filters.kamId
        : defaultState.filters.kamId,
      route: isGoButtonIsClicked
        ? (pageConfig && pageConfig?.routeFilter) || defaultState.filters.route
        : defaultState.filters.route,
    },
    startDate: isGoButtonIsClicked
      ? (pageConfig && pageConfig?.startDateFilter) ||
        (() => {
          const startDate = new Date();
          startDate.setDate(startDate.getDate() - 6);
          return getDateString(startDate);
        })()
      : (() => {
          const startDate = new Date();
          startDate.setDate(startDate.getDate() - 6);
          return getDateString(startDate);
        })(),
    endDate: isGoButtonIsClicked
      ? (pageConfig && pageConfig?.endDateFilter) || getDateString(new Date())
      : getDateString(new Date()),
    dynamicColumns: pageConfig?.dynamicColumns || defaultState.dynamicColumns,
    // reportType: !isRoleKAM ? REPORT_TYPE[0] : REPORT_TYPE[1],
    reportType: !isRoleKAM
      ? isGoButtonIsClicked
        ? (pageConfig && pageConfig?.reportType) || REPORT_TYPE[0]
        : REPORT_TYPE[0]
      : isGoButtonIsClicked
      ? (pageConfig && pageConfig?.reportType) || REPORT_TYPE[1]
      : REPORT_TYPE[1],
  });
  const kamId = isRoleKAM ? userData?.id : state.selectedKAM?.id;

  const handleFilterChange = useCallback((pageFilters = []) => {
    setState((prevState) => ({
      ...prevState,
      pageFilters,
    }));
  }, []);

  const fetchList = async (listType, ...params) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    switch (listType) {
      case "customers":
        serviceMethod = "getCustomerList";
        responseKey = "customers";
        break;
      case "IC":
        serviceMethod = "getContractorList";
        responseKey = "contractors";
        break;
      case "users":
        serviceMethod = "getUsersList";
        responseKey = "users";
        break;
      case "pendingItems":
        serviceMethod = "getPendingItems";
        responseKey = "pendingItems";
        break;
      case "transferRecords":
        serviceMethod = "getTransferScheduleRequests";
        responseKey = "transferRecords";
        break;
      case "icList":
        serviceMethod = "getIcList";
        responseKey = "contractors";
        break;
      default:
        serviceMethod = "getCustomerList";
        responseKey = "customers";
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];
    if (listType === "customers") {
      listData.map((item = {}) => {
        (item.customer_branches || []).map((branch = {}) => {
          (branch.routes || []).sort((rowA, rowB) => {
            const valueA = (rowA?.name || "").toLowerCase();
            const valueB = (rowB?.name || "").toLowerCase();
            return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
          });
        });
      });
    }
    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
  };

  const fetchPDXCompanyList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompanys: true,
      },
    }));

    const { data, error } = await SharedService.getPDXCompanyList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          pdxCompanys: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      pdxCompanyList:
        data?.rows.filter((pdxCompany) => pdxCompany.is_active) ||
        defaultState.pdxCompanyList,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompanys: false,
      },
    }));
  };

  const filterConfigSettlement = useMemo(
    () => [
      {
        field: "pay_rate_type",
        fieldToDisplay: "Pay Rate Type",
        operatorType: "string",
        options: [
          { label: "Daily", value: "daily" },
          { label: "Hourly", value: "hourly" },
          { label: "Per Miles", value: "per_miles" },
        ],
      },
      {
        field: "pay_rate",
        fieldToDisplay: "Pay Rate",
        operatorType: "string",
      },
      {
        field: "pay_quantity",
        fieldToDisplay: "Pay Quantity",
        operatorType: "string",
      },
      {
        field: "pay_amount",
        fieldToDisplay: "Pay Amount",
        operatorType: "string",
      },
      {
        field: "pay_fuel_surcharge",
        fieldToDisplay: "Pay Fuel Surcharge%",
        operatorType: "string",
      },
      {
        field: "pay_fuel_amount",
        fieldToDisplay: "Pay Fuel Amount",
        operatorType: "string",
      },
      {
        field: "pay_extra_miles",
        fieldToDisplay: "Pay Extra Miles",
        operatorType: "string",
      },
      {
        field: "pay_rate_per_extra_mile",
        fieldToDisplay: "Pay Rate Per Extra Mile",
        operatorType: "string",
      },
      {
        field: "pay_extra_miles_amount",
        fieldToDisplay: "Pay Extra Miles Amount",
        operatorType: "string",
      },
      {
        field: "reimbursement",
        fieldToDisplay: "Reimbursement",
        operatorType: "string",
      },
      {
        field: "deduction_reason",
        fieldToDisplay: "Deduction Reason",
        operatorType: "string",
      },
      {
        field: "toll_amount",
        fieldToDisplay: "Toll Amount",
        operatorType: "string",
      },
    ],
    []
  );

  const filterConfigBilling = useMemo(
    () => [
      {
        field: "bill_rate_type",
        fieldToDisplay: "Billing Rate Type",
        operatorType: "string",
        options: [
          { label: "Daily", value: "daily" },
          { label: "Hourly", value: "hourly" },
          { label: "Per Miles", value: "per_miles" },
        ],
      },
      {
        field: "bill_rate",
        fieldToDisplay: "Bill Rate",
        operatorType: "string",
      },
      {
        field: "bill_quantity",
        fieldToDisplay: "Bill Quantity",
        operatorType: "string",
      },
      {
        field: "bill_amount",
        fieldToDisplay: "Bill Amount",
        operatorType: "string",
      },
      {
        field: "bill_fuel_surcharge",
        fieldToDisplay: "Bill Fuel Surcharge%",
        operatorType: "string",
      },
      {
        field: "bill_fuel_amount",
        fieldToDisplay: "Bill Fuel Amount",
        operatorType: "string",
      },
      {
        field: "bill_extra_miles",
        fieldToDisplay: "Bill Extra Miles",
        operatorType: "string",
      },
      {
        field: "bill_rate_per_extra_mile",
        fieldToDisplay: "Bill Rate Per Extra Mile",
        operatorType: "string",
      },
      {
        field: "bill_extra_miles_amount",
        fieldToDisplay: "Bill Extra Miles Amount",
        operatorType: "string",
      },
      {
        field: "toll_amount",
        fieldToDisplay: "Toll Amount",
        operatorType: "string",
      },
    ],
    []
  );

  const filterConfigBillingSettlement = useMemo(
    () => [
      {
        field: "bill_rate_type",
        fieldToDisplay: "Billing Rate Type",
        operatorType: "string",
        options: [
          { label: "Daily", value: "daily" },
          { label: "Hourly", value: "hourly" },
          { label: "Per Miles", value: "per_miles" },
        ],
      },
      {
        field: "bill_rate",
        fieldToDisplay: "Bill Rate",
        operatorType: "string",
      },
      {
        field: "bill_quantity",
        fieldToDisplay: "Bill Quantity",
        operatorType: "string",
      },
      {
        field: "bill_amount",
        fieldToDisplay: "Bill Amount",
        operatorType: "string",
      },
      {
        field: "bill_fuel_surcharge",
        fieldToDisplay: "Bill Fuel Surcharge%",
        operatorType: "string",
      },
      {
        field: "bill_fuel_amount",
        fieldToDisplay: "Bill Fuel Amount",
        operatorType: "string",
      },
      {
        field: "bill_extra_miles",
        fieldToDisplay: "Bill Extra Miles",
        operatorType: "string",
      },
      {
        field: "bill_rate_per_extra_mile",
        fieldToDisplay: "Bill Rate Per Extra Mile",
        operatorType: "string",
      },
      {
        field: "bill_extra_miles_amount",
        fieldToDisplay: "Bill Extra Miles Amount",
        operatorType: "string",
      },
      {
        field: "pay_rate_type",
        fieldToDisplay: "Pay Rate Type",
        operatorType: "string",
        options: [
          { label: "Daily", value: "daily" },
          { label: "Hourly", value: "hourly" },
          { label: "Per Miles", value: "per_miles" },
        ],
      },
      {
        field: "pay_rate",
        fieldToDisplay: "Pay Rate",
        operatorType: "string",
      },
      {
        field: "pay_quantity",
        fieldToDisplay: "Pay Quantity",
        operatorType: "string",
      },
      {
        field: "pay_amount",
        fieldToDisplay: "Pay Amount",
        operatorType: "string",
      },
      {
        field: "pay_fuel_surcharge",
        fieldToDisplay: "Pay Fuel Surcharge%",
        operatorType: "string",
      },
      {
        field: "pay_fuel_amount",
        fieldToDisplay: "Pay Fuel Amount",
        operatorType: "string",
      },
      {
        field: "pay_extra_miles",
        fieldToDisplay: "Pay Extra Miles",
        operatorType: "string",
      },
      {
        field: "pay_rate_per_extra_mile",
        fieldToDisplay: "Pay Rate Per Extra Mile",
        operatorType: "string",
      },
      {
        field: "pay_extra_miles_amount",
        fieldToDisplay: "Pay Extra Miles Amount",
        operatorType: "string",
      },
      {
        field: "reimbursement",
        fieldToDisplay: "Reimbursement",
        operatorType: "string",
      },
      {
        field: "deduction_reason",
        fieldToDisplay: "Deduction Reason",
        operatorType: "string",
      },
      {
        field: "toll_amount",
        fieldToDisplay: "Toll Amount",
        operatorType: "string",
      },
    ],
    []
  );
  const fetchVehicleTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        settlementCompanies: true,
      },
    }));

    const { data, error } = await SharedService.getVehicleTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          filters: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetching: false,
      filterData: {
        ...prevState.filterData,
        vehicle:
          data?.rows.filter((vehicle) => vehicle.is_active) ||
          defaultState.filterData.vehicle,
      },
      isFetchingList: {
        ...prevState.isFetchingList,
        filters: false,
      },
    }));
  };

  const fetchCompanyList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        settlementCompanies: true,
      },
    }));

    const { data, error } = await SharedService.getSettlementCompanyList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          settlementCompanies: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      settlementCompanyList:
        data?.rows.filter((settlementCompany) => settlementCompany.is_active) ||
        defaultState.settlementCompanyList,
      isFetchingList: {
        ...prevState.isFetchingList,
        settlementCompanies: false,
      },
    }));
  };

  const filterQueryBuilder = (
    startDate,
    endDate,
    reportType,
    filters,
    pageFilters = []
  ) => {
    const customer_id = filters?.customer?.map((customer) => customer.id);
    const location = filters?.location || "";
    const vehicle_type = filters?.vehicle_type?.map((vehicle) => vehicle.id);
    const kamId = filters?.kamId?.map((kam) => kam.id);
    const aaId = filters?.aaId?.map((aa) => aa.id);
    const icid = filters?.IC?.map((ic) => ic.icid);
    const settlementCompany = filters?.settlementCompany?.map(
      (item) => item.id
    );
    const pdxCompany = filters?.pdxCompany?.map((item) => item.id);

    let filterKeysMap = {};

    if (pageFilters?.length > 0) {
      pageFilters.map((item) => {
        Object.assign(filterKeysMap, {
          item: {
            key: item.field,
            value: item.value,
            type: item.type,
          },
        });
      });
    }
    if (customer_id?.length > 0) {
      Object.assign(filterKeysMap, {
        customer: {
          key: "customer_id",
          value: customer_id,
        },
      });
    }
    if (location?.length > 0) {
      Object.assign(filterKeysMap, {
        location: {
          key: "location",
          value: location,
        },
      });
    }
    if (settlementCompany?.length > 0) {
      Object.assign(filterKeysMap, {
        settlementCompany: {
          key: "settlement_co_id",
          value: settlementCompany,
        },
      });
    }
    if (pdxCompany?.length > 0) {
      Object.assign(filterKeysMap, {
        settlementCompany: {
          key: "pdx_company_id",
          value: pdxCompany,
        },
      });
    }
    if (filters?.route) {
      Object.assign(filterKeysMap, {
        route: {
          key: "route",
          value: filters?.route?.name || filters?.route,
        },
      });
    }
    if (icid?.length > 0) {
      Object.assign(filterKeysMap, {
        IC: {
          key: "icid",
          value: icid,
        },
      });
    }
    if (vehicle_type?.length > 0) {
      Object.assign(filterKeysMap, {
        vehicle_type: {
          key: "vehicle_type",
          value: vehicle_type,
        },
      });
    }
    if (kamId?.length > 0) {
      Object.assign(filterKeysMap, {
        kamId: {
          key: "kam_id",
          value: kamId,
        },
      });
    }
    if (aaId?.length > 0) {
      Object.assign(filterKeysMap, {
        aaId: {
          key: "aa_id",
          value: aaId,
        },
      });
    }

    let queryString = `?type=${reportType?.value}&from=${startDate}&to=${endDate}`;
    Object.keys(filterKeysMap).map((item) => {
      return (queryString += `&${
        filterKeysMap[item]["key"]
      }=${encodeURIComponent(filterKeysMap[item]["value"])}`);
    });

    return queryString;
  };

  const fetchEntries = useCallback(
    async (
      startDate,
      endDate,
      reportType = {},
      filters = {},
      pageFilters = []
    ) => {
      let queryString = filterQueryBuilder(
        startDate,
        endDate,
        reportType,
        filters,
        pageFilters
      );

      setState((prevState) => ({
        ...prevState,
        isFetching: true,
        showGrid: true,
      }));

      const { data, error } = await Service.get(queryString);
      if (error) {
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0].message : error.message
        );
      }

      const sortedEntries = (
        data[reportType?.value] || defaultState.entries
      ).sort((rowA, rowB) => {
        const rowAcustomerName = (rowA?.customer_name || "").toLowerCase();
        const rowAlocation = (rowA?.location || "").toLowerCase();
        const rowAroute = (rowA?.route || "").toLowerCase();
        const rowBcustomerName = (rowB?.customer_name || "").toLowerCase();
        const rowBlocation = (rowB?.location || "").toLowerCase();
        const rowBroute = (rowB?.route || "").toLowerCase();
        const valueA = `${rowAcustomerName}${rowAlocation}${rowAroute}`;
        const valueB = `${rowBcustomerName}${rowBlocation}${rowBroute}`;
        return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
      });

      setState((prevState) => ({
        ...prevState,
        isFetching: false,
        entries: sortedEntries,
        totalCounts: data?.total || defaultState.totalCounts,
      }));
    },
    []
  );

  const fetchFilterData = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        filters: true,
      },
    }));
    const { data, error } = await Service.getFilterData();
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetching: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetching: false,

      filterData: {
        ...prevState.filterData,
        location: data.locations || defaultState.filterData.location,
        route: data.routes || defaultState.filterData.routes,
      },
      isFetchingList: {
        ...prevState.isFetchingList,
        filters: false,
      },
    }));
  };

  const fetchLeaveTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        settlementCompanies: true,
      },
    }));

    const { data, error } = await SharedService.getLeaveTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          filters: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetching: false,
      forceSubmitReasonList: data?.rows || [],
    }));
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      openForceSubmitDialog: false,
    }));
  };

  const handleExport = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetching: true,
    }));
    const fileName = `${state.reportType?.label}_report`;
    let query = filterQueryBuilder(
      state.startDate,
      state.endDate,
      state.reportType,
      state.reportType.value === REPORT_TYPE[4].value
        ? {
            customer: [state.billingCustomer],
            location: [state.billingLocation],
          }
        : state.filters
    );
    query += "&excel=true";

    const { data, error } = await Service.get(query);

    if (error) {
      toast.error(Array.isArray(error) ? error[0].message : error);
    } else {
      exportBase64ToFile(data, fileName);
    }
    setState((prevState) => ({
      ...prevState,
      isFetching: false,
    }));
  };

  useEffect(() => {
    if (isGoButtonIsClicked) {
      fetchEntries(
        state.startDate,
        state.endDate,
        state.reportType,
        state.reportType.value === REPORT_TYPE[4].value
          ? {
              customer: [state.billingCustomer],
              location: [state.billingLocation],
            }
          : state.filters,
        state.pageFilters
      );
    }
  }, [isGoButtonIsClicked]);

  const moreActions = (row) => {
    const allReviewNotesEmpty = row.notes?.every((note) => !note.review_notes);
    const allKamNotesEmpty = row.notes?.every((note) => !note.kam_notes);
    const allAAMGRNotesEmpty = row.notes?.every((note) => !note.change_notes);

    const handleViewNotes = () => {
      setState((prevState) => ({ ...prevState, viewNotes: row }));
    };

    const iconProps = {
      className: clsx("ml-2 mr-2 c-pointer", classes.VisibilityIconColor),
      onClick: handleViewNotes,
    };

    return (
      <div className="d-flex f-align-center">
        <Tooltip title="View Notes" placement="top-start">
          {allKamNotesEmpty && allReviewNotesEmpty && allAAMGRNotesEmpty ? (
            <VisibilityIcon {...iconProps} />
          ) : (
            <img
              src={ReviewNotesIcon}
              alt="reviewNotesIcon"
              className="mr-2 c-pointer"
              onClick={handleViewNotes}
              style={{ height: "24px", width: "24px", marginLeft: "6px" }}
            />
          )}
        </Tooltip>
      </div>
    );
  };

  const notesColumnConfig = [
    {
      id: "date",
      label: "Schedule Date",
      fieldName: "date",
      render: (row) => {
        const date = getUTCDateString(row.date);
        return (
          <Tooltip title={date ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {date || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      headerClassName: classes.reviewNotesHeaderCell,
      id: "review_notes",
      label: "Review Notes",
      fieldName: "review_notes",
      render: (row) => {
        const splitNotes = row.review_notes?.split("||");

        const finalNotes = splitNotes?.map((note) => {
          let str = note;
          if (!str) {
            return "";
          }
          const startIndexOfDate = str?.indexOf("-");
          const lastIndexOfDate = str?.indexOf(": ");
          const formattedDate = new Date(
              str?.substring(startIndexOfDate + 1, lastIndexOfDate) + " GMT"
            )?.toLocaleString(),
            str1 =
              str.substring(0, startIndexOfDate) +
              "-" +
              formattedDate +
              str.substring(lastIndexOfDate);
          return str1;
        });
        const title =
          finalNotes !== undefined ? (
            <div
              dangerouslySetInnerHTML={{
                __html: finalNotes.join("<br>"),
              }}
            />
          ) : null;

        return !row.review_notes ? (
          <Typography variant="body2" noWrap>
            {"-"}
          </Typography>
        ) : (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {finalNotes || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "kam_notes",
      label: "KAM Notes",
      fieldName: "kam_notes",
      render: (row) => {
        const title = row?.kam_notes;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "aa_mgr_notes",
      label: "AA/MGR Notes",
      fieldName: "aa_mgr_notes",
      render: (row) => {
        const title = row?.change_notes;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "status",
      label: "Status",
      fieldName: "status",
      render: (row) => {
        return (
          <Tooltip title={row.status ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.status || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  const columnConfig = {
    [REPORT_TYPE[0].value]: [
      {
        label: "View Notes",
        id: "col-edit",
        noResize: true,
        render: (row) => moreActions(row),
      },
      {
        id: "customer_name",
        label: "Customer Name",
        fieldName: "customer_name",
        render: (row) => {
          const title = `
            Invoice: ${
              INVOICE_TYPES.find(
                (invoice) =>
                  invoice.id === row.invoice_type ||
                  invoice.label === row.invoice_type
              )?.label || "-"
            }
          `;
          return (
            <Tooltip title={title} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.customer_name}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[0].value]["KAM"].isSelected ||
          isRoleKAM,
        id: "kam_name",
        label: "KAM",
        field: "kam_name",
        hasEllipses: true,
        render: (row) => {
          const title = row.kam_username
            ? `${row.kam_name}-(${row.kam_username})`
            : "";
          return (
            <Tooltip title={title} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.kam_name}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[0].value]["Office"].isSelected ||
          isRoleKAM,
        id: "aa_name",
        label: "Office",
        field: "aa_name",
        hasEllipses: true,
        render: (row) => {
          const title = row.aa_username
            ? `${row.aa_name}-(${row.aa_username})`
            : "";
          return (
            <Tooltip title={title} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.aa_name}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[0].value]["Location"].isSelected,
        id: "location",
        label: "Location",
        field: "location",
        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[0].value]["Route"].isSelected,
        id: "route",
        label: "Route",
        field: "route",
        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[0].value]["State"].isSelected,
        id: "state",
        label: "State",
        field: "state",
        render: (row, rowIndex) => {
          return (
            <Tooltip title={row.state ?? ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.state ?? "-"}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[0].value]["PDX Company"].isSelected,
        id: "pdx_company_name",
        label: "PDX Company",
        field: "pdx_company_name",
        render: (row, rowIndex) => {
          return (
            <Tooltip title={row.pdx_company_name ?? ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.pdx_company_name ?? "-"}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[0].value]["Vehicle"].isSelected,
        id: "vehicle_type",
        label: "Vehicle",
        field: "vehicle_type",
        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[0].value]["Bill Rate Type"]
            .isSelected,
        headerClassName: classes.tableHeaderCell,
        id: "bill_rate_type",
        label: "Bill Rate Type",
        field: "bill_rate_type",
        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[0].value]["Bill Rate"].isSelected,
        id: "bill_rate",
        label: "Bill Rate",
        field: "bill_rate",
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_rate ? `$${row.bill_rate || ""}` : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[0].value]["Bill Quantity"]
            .isSelected,
        id: "bill_quantity",
        label: "Bill Quantity",
        field: "bill_quantity",
        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[0].value]["Bill Amount"].isSelected,
        id: "bill_amount",
        label: "Bill Amount",
        field: "bill_amount",
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_amount ? `$${row.bill_amount || ""}` : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[0].value]["Bill Fuel Surcharge%"]
            .isSelected,
        id: "bill_fuel_surcharge",
        label: "Bill Fuel Surcharge%",
        field: "bill_fuel_surcharge",
        headerClassName: classes.tableHeaderCell,
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_fuel_surcharge
            ? `${row.bill_fuel_surcharge || ""}%`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[0].value]["Bill Fuel Amount"]
            .isSelected,
        id: "bill_fuel_amount",
        label: "Bill Fuel Amount",
        field: "bill_fuel_amount",
        headerClassName: classes.tableHeaderCell,
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_fuel_amount
            ? `$${row.bill_fuel_amount || ""}`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[0].value]["Bill Toll Amount"]
            .isSelected,
        id: "bill_toll_amount",
        label: "Bill Toll Amount",
        field: "bill_toll_amount",
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_toll_amount
            ? `$${row.bill_toll_amount || ""}`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[0].value]["Bill Extra Miles"]
            .isSelected,
        id: "bill_extra_miles",
        label: "Bill Extra Miles",
        field: "bill_extra_miles",
        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[0].value][
            "Bill Rate Per Extra Mile"
          ].isSelected,
        headerClassName: classes.tableHeaderCell,
        id: "bill_rate_per_extra_mile",
        label: "Bill Rate Per Extra Mile",
        field: "bill_rate_per_extra_mile",
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_rate_per_extra_mile
            ? `$${row.bill_rate_per_extra_mile || ""}`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[0].value]["Bill Extra Miles Amount"]
            .isSelected,
        headerClassName: classes.tableHeaderCell,
        id: "bill_extra_mile_amount",
        label: "Bill Extra Miles Amount",
        field: "bill_extra_mile_amount",
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_extra_mile_amount
            ? `$${row.bill_extra_mile_amount || ""}`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
    ],
    [REPORT_TYPE[1].value]: [
      {
        label: "View Notes",
        id: "col-edit",
        noResize: true,
        render: (row) => moreActions(row),
      },
      {
        id: "settlement_company_name",
        label: "Settlement Company",
        field: "settlement_company_name",
        hasEllipses: true,
      },
      {
        isHidden: !isRoleKAM
          ? !state.dynamicColumns[REPORT_TYPE[1].value]["Customer Name"]
              .isSelected
          : !state.dynamicColumnsKam[REPORT_TYPE[1].value]["Customer Name"]
              .isSelected,
        id: "customer_name",
        label: "Customer Name",
        fieldName: "customer_name",
        render: (row) => {
          const title = `
            Invoice: ${
              INVOICE_TYPES.find(
                (invoice) =>
                  invoice.id === row.invoice_type ||
                  invoice.label === row.invoice_type
              )?.label || "-"
            }
          `;
          return (
            <Tooltip title={title} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.customer_name}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden: !isRoleKAM
          ? !state.dynamicColumns[REPORT_TYPE[1].value]["Location"].isSelected
          : !state.dynamicColumnsKam[REPORT_TYPE[1].value]["Location"]
              .isSelected,
        id: "location",
        label: "Location",
        field: "location",
        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[1].value]["KAM"].isSelected ||
          isRoleKAM,
        id: "kam_name",
        label: "KAM",
        field: "kam_name",
        hasEllipses: true,
        render: (row) => {
          const title = row.kam_username
            ? `${row.kam_name}-(${row.kam_username})`
            : "";
          return (
            <Tooltip title={title} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.kam_name}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden: !isRoleKAM
          ? !state.dynamicColumns[REPORT_TYPE[1].value]["Office"].isSelected
          : !state.dynamicColumnsKam[REPORT_TYPE[1].value]["Office"].isSelected,
        id: "aa_name",
        label: "Office",
        field: "aa_name",
        hasEllipses: true,
        render: (row) => {
          const title = row.aa_username
            ? `${row.aa_name}-(${row.aa_username})`
            : "";
          return (
            <Tooltip title={title} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.aa_name}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden: !isRoleKAM
          ? !state.dynamicColumns[REPORT_TYPE[1].value]["Route"].isSelected
          : !state.dynamicColumnsKam[REPORT_TYPE[1].value]["Route"].isSelected,
        id: "route",
        label: "Route",
        field: "route",
        hasEllipses: true,
      },
      {
        isHidden: !isRoleKAM
          ? !state.dynamicColumns[REPORT_TYPE[1].value]["State"].isSelected
          : !state.dynamicColumnsKam[REPORT_TYPE[1].value]["State"].isSelected,
        id: "state",
        label: "State",
        field: "state",
        render: (row, rowIndex) => {
          return (
            <Tooltip title={row.state ?? ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.state ?? "-"}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden: !isRoleKAM
          ? !state.dynamicColumns[REPORT_TYPE[1].value]["PDX Company"]
              .isSelected
          : !state.dynamicColumnsKam[REPORT_TYPE[1].value]["PDX Company"]
              .isSelected,
        id: "pdx_company_name",
        label: "PDX Company",
        field: "pdx_company_name",
        render: (row, rowIndex) => {
          return (
            <Tooltip title={row.pdx_company_name ?? ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.pdx_company_name ?? "-"}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden: !isRoleKAM
          ? !state.dynamicColumns[REPORT_TYPE[1].value]["Vehicle"].isSelected
          : !state.dynamicColumnsKam[REPORT_TYPE[1].value]["Vehicle"]
              .isSelected,
        id: "vehicle_type",
        label: "Vehicle",
        field: "vehicle_type",
        hasEllipses: true,
      },
      {
        isHidden: !isRoleKAM
          ? !state.dynamicColumns[REPORT_TYPE[1].value]["ICID/IC Name"]
              .isSelected
          : !state.dynamicColumnsKam[REPORT_TYPE[1].value]["ICID/IC Name"]
              .isSelected,
        id: "ic_name",
        label: "ICID/IC Name",
        headerClassName: classes.icidFlagTitle,
        fieldName: "ic_name",
        render: (row) => {
          const title = `
            IC Settlement Company Name: ${
              row.ic_settlement_company_name || "-"
            }, 
          
            ICID: ${row.icid || "-"},
            IC Name: ${row.ic_name || ""},
            Contractor Type:${row.contractorType || ""}
          `;
          const flagLabel =
            (!!row?.is_flagged && !!row?.is_cautious_flagged) ||
            !!row?.is_flagged ? (
              <FlagIcon style={{ color: "red" }} />
            ) : row?.is_cautious_flagged ? (
              <div className={classes.warningIconHeight}>
                <WarningIcon className={classes.warningIcon} />
                <div className={classes.exclamationMark}></div>
              </div>
            ) : (
              ""
            );
          const flagTitles = `Flagged Reason: ${
            row.flagReason || row.ctnFlagRsn
          }, Flagged By: ${
            row.flaggedBy || row.ctnFlaggedBy || ""
          }, USERNAME: ${
            row.flaggedByUsername || row.ctnFlaggedByUsername || ""
          }, ROLE: ${
            row.flaggedByRole || row.ctnFlaggedByRole || ""
          }, Flagged At: ${getDateString(row.flaggedAt || row.ctnFlaggedAt)}`;

          return (
            <Fragment>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <>
                  <Tooltip title={flagTitles} placement="top-start">
                    <Typography noWrap variant="body2">
                      {flagLabel}
                    </Typography>
                  </Tooltip>
                </>
                <>
                  <Tooltip title={title} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {`${row.icid || ""}/${row.ic_name || ""}`}
                    </Typography>
                  </Tooltip>
                </>
              </div>
            </Fragment>
          );
        },
      },
      {
        isHidden: !isRoleKAM
          ? !state.dynamicColumns[REPORT_TYPE[1].value]["Pay Rate Type"]
              .isSelected
          : !state.dynamicColumnsKam[REPORT_TYPE[1].value]["Pay Rate Type"]
              .isSelected,
        headerClassName: classes.tableHeaderCell,
        id: "pay_rate_type",
        label: "Pay Rate Type",
        field: "pay_rate_type",
        hasEllipses: true,
      },
      {
        isHidden: !isRoleKAM
          ? !state.dynamicColumns[REPORT_TYPE[1].value]["Pay Rate"].isSelected
          : !state.dynamicColumnsKam[REPORT_TYPE[1].value]["Pay Rate"]
              .isSelected,
        id: "pay_rate",
        label: "Pay Rate",
        field: "pay_rate",
        hasEllipses: true,
        render: (row) => {
          const label = row.pay_rate ? `$${row.pay_rate || ""}` : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden: !isRoleKAM
          ? !state.dynamicColumns[REPORT_TYPE[1].value]["Pay Quantity"]
              .isSelected
          : !state.dynamicColumnsKam[REPORT_TYPE[1].value]["Pay Quantity"]
              .isSelected,
        id: "pay_quantity",
        label: "Pay Quantity",
        field: "pay_quantity",
        hasEllipses: true,
      },
      {
        isHidden: !isRoleKAM
          ? !state.dynamicColumns[REPORT_TYPE[1].value]["Pay Amount"].isSelected
          : !state.dynamicColumnsKam[REPORT_TYPE[1].value]["Pay Amount"]
              .isSelected,
        id: "pay_amount",
        label: "Pay Amount",
        field: "pay_amount",
        hasEllipses: true,
        render: (row) => {
          const label = row.pay_amount ? `$${row.pay_amount || ""}` : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden: !isRoleKAM
          ? !state.dynamicColumns[REPORT_TYPE[1].value]["Pay Fuel Surcharge%"]
              .isSelected
          : !state.dynamicColumnsKam[REPORT_TYPE[1].value][
              "Pay Fuel Surcharge%"
            ].isSelected,
        id: "pay_fuel_surcharge",
        label: "Pay Fuel Surcharge%",
        field: "pay_fuel_surcharge",
        headerClassName: classes.tableHeaderCell,
        hasEllipses: true,
        render: (row) => {
          const label = row.pay_fuel_surcharge
            ? `${row.pay_fuel_surcharge || ""}%`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden: !isRoleKAM
          ? !state.dynamicColumns[REPORT_TYPE[1].value]["Pay Fuel Amount"]
              .isSelected
          : !state.dynamicColumnsKam[REPORT_TYPE[1].value]["Pay Fuel Amount"]
              .isSelected,
        id: "pay_fuel_amount",
        label: "Pay Fuel Amount",
        field: "pay_fuel_amount",
        headerClassName: classes.tableHeaderCell,
        hasEllipses: true,
        render: (row) => {
          const label = row.pay_fuel_amount
            ? `$${row.pay_fuel_amount || ""}`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden: !isRoleKAM
          ? !state.dynamicColumns[REPORT_TYPE[1].value]["Pay Toll Amount"]
              .isSelected
          : !state.dynamicColumnsKam[REPORT_TYPE[1].value]["Pay Toll Amount"]
              .isSelected,
        id: "pay_toll_amount",
        label: "Pay Toll Amount",
        field: "pay_toll_amount",
        hasEllipses: true,
        render: (row) => {
          const label = row.pay_toll_amount
            ? `$${row.pay_toll_amount || ""}`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },

      {
        isHidden: !isRoleKAM
          ? !state.dynamicColumns[REPORT_TYPE[1].value]["Pay Extra Miles"]
              .isSelected
          : !state.dynamicColumnsKam[REPORT_TYPE[1].value]["Pay Extra Miles"]
              .isSelected,
        id: "pay_extra_miles",
        label: "Pay Extra Miles",
        field: "pay_extra_miles",
        hasEllipses: true,
      },
      {
        isHidden: !isRoleKAM
          ? !state.dynamicColumns[REPORT_TYPE[1].value][
              "Pay Rate Per Extra Mile"
            ].isSelected
          : !state.dynamicColumnsKam[REPORT_TYPE[1].value][
              "Pay Rate Per Extra Mile"
            ].isSelected,
        headerClassName: classes.tableHeaderCell,
        id: "pay_rate_per_extra_mile",
        label: "Pay Rate Per Extra Mile",
        field: "pay_rate_per_extra_mile",
        hasEllipses: true,
        render: (row) => {
          const label = row.pay_rate_per_extra_mile
            ? `$${row.pay_rate_per_extra_mile || ""}`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden: !isRoleKAM
          ? !state.dynamicColumns[REPORT_TYPE[1].value][
              "Pay Extra Miles Amount"
            ].isSelected
          : !state.dynamicColumnsKam[REPORT_TYPE[1].value][
              "Pay Extra Miles Amount"
            ].isSelected,
        headerClassName: classes.tableHeaderCell,
        id: "pay_extra_mile_amount",
        label: "Pay Extra Miles Amount",
        field: "pay_extra_mile_amount",
        hasEllipses: true,
        render: (row) => {
          const label = row.pay_extra_mile_amount
            ? `$${row.pay_extra_mile_amount || ""}`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden: !isRoleKAM
          ? !state.dynamicColumns[REPORT_TYPE[1].value]["Reimbursement"]
              .isSelected
          : !state.dynamicColumnsKam[REPORT_TYPE[1].value]["Reimbursement"]
              .isSelected,
        id: "reimbursement",
        label: "Reimbursement",
        field: "reimbursement",
        hasEllipses: true,
        render: (row) => {
          const label = row.reimbursement ? `$${row.reimbursement || ""}` : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden: !isRoleKAM
          ? !state.dynamicColumns[REPORT_TYPE[1].value]["Deduction"].isSelected
          : !state.dynamicColumnsKam[REPORT_TYPE[1].value]["Deduction"]
              .isSelected,
        id: "deduction",
        label: "Deduction",
        field: "deduction",
        hasEllipses: true,
        render: (row) => {
          const label = row.deduction ? `$${row.deduction || ""}` : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden: !isRoleKAM
          ? !state.dynamicColumns[REPORT_TYPE[1].value]["Deduction Reason"]
              .isSelected
          : !state.dynamicColumnsKam[REPORT_TYPE[1].value]["Deduction Reason"]
              .isSelected,
        id: "deduction_reason",
        label: "Deduction Reason",
        field: "deduction_reason",
        hasEllipses: true,
      },
    ],
    [REPORT_TYPE[2].value]: [
      {
        label: "View Notes",
        id: "col-edit",
        noResize: true,
        render: (row) => moreActions(row),
      },
      {
        id: "customer_name",
        label: "Customer Name",
        fieldName: "customer_name",
        render: (row) => {
          const title = `
            Invoice: ${
              INVOICE_TYPES.find(
                (invoice) =>
                  invoice.id === row.invoice_type ||
                  invoice.label === row.invoice_type
              )?.label || "-"
            }
          `;
          return (
            <Tooltip title={title} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.customer_name}
              </Typography>
            </Tooltip>
          );
        },
        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["KAM"].isSelected ||
          isRoleKAM,
        id: "kam_name",
        label: "KAM",
        field: "kam_name",
        hasEllipses: true,
        render: (row) => {
          const title = row.kam_username
            ? `${row.kam_name}-(${row.kam_username})`
            : "";
          return (
            <Tooltip title={title} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.kam_name}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["Office"].isSelected ||
          isRoleKAM,
        id: "aa_name",
        label: "Office",
        field: "aa_name",
        hasEllipses: true,
        render: (row) => {
          const title = row.aa_username
            ? `${row.aa_name}-(${row.aa_username})`
            : "";
          return (
            <Tooltip title={title} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.aa_name}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["Location"].isSelected,
        id: "location",
        label: "Location",
        field: "location",
        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["Route"].isSelected,
        id: "route",
        label: "Route",
        field: "route",
        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["State"].isSelected,
        id: "state",
        label: "State",
        field: "state",
        render: (row, rowIndex) => {
          return (
            <Tooltip title={row.state ?? ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.state ?? "-"}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["PDX Company"].isSelected,
        id: "pdx_company_name",
        label: "PDX Company",
        field: "pdx_company_name",
        render: (row, rowIndex) => {
          return (
            <Tooltip title={row.pdx_company_name ?? ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.pdx_company_name ?? "-"}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["Vehicle"].isSelected,
        id: "vehicle_type",
        label: "Vehicle",
        field: "vehicle_type",
        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["Bill Rate Type"]
            .isSelected,
        headerClassName: classes.tableHeaderCell,
        id: "bill_rate_type",
        label: "Bill Rate Type",
        field: "bill_rate_type",
        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["Bill Rate"].isSelected,
        id: "bill_rate",
        label: "Bill Rate",
        field: "bill_rate",
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_rate ? `$${row.bill_rate || ""}` : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["Bill Quantity"]
            .isSelected,
        id: "bill_quantity",
        label: "Bill Quantity",
        field: "bill_quantity",
        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["Bill Amount"].isSelected,
        id: "bill_amount",
        label: "Bill Amount",
        field: "bill_amount",
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_amount ? `$${row.bill_amount || ""}` : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["Bill Fuel Surcharge%"]
            .isSelected,
        id: "bill_fuel_surcharge",
        label: "Bill Fuel Surcharge%",
        field: "bill_fuel_surcharge",
        headerClassName: classes.tableHeaderCell,
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_fuel_surcharge
            ? `${row.bill_fuel_surcharge || ""}%`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["Bill Fuel Amount"]
            .isSelected,
        id: "bill_fuel_amount",
        label: "Bill Fuel Amount",
        field: "bill_fuel_amount",
        headerClassName: classes.tableHeaderCell,
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_fuel_amount
            ? `$${row.bill_fuel_amount || ""}`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["Bill Toll Amount"]
            .isSelected,
        id: "bill_toll_amount",
        label: "Bill Toll Amount",
        field: "bill_toll_amount",
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_toll_amount
            ? `$${row.bill_toll_amount || ""}`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["Bill Extra Miles"]
            .isSelected,
        id: "bill_extra_miles",
        label: "Bill Extra Miles",
        field: "bill_extra_miles",
        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value][
            "Bill Rate Per Extra Mile"
          ].isSelected,
        headerClassName: classes.tableHeaderCell,
        id: "bill_rate_per_extra_mile",
        label: "Bill Rate Per Extra Mile",
        field: "bill_rate_per_extra_mile",
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_rate_per_extra_mile
            ? `$${row.bill_rate_per_extra_mile || ""}`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["Bill Extra Miles Amount"]
            .isSelected,
        headerClassName: classes.tableHeaderCell,
        id: "bill_extra_mile_amount",
        label: "Bill Extra Miles Amount",
        field: "bill_extra_mile_amount",
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_extra_mile_amount
            ? `$${row.bill_extra_mile_amount || ""}`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["ICID/IC Name"]
            .isSelected,
        id: "ic_name",
        label: "ICID/IC Name",
        headerClassName: classes.icidFlagTitle,
        fieldName: "ic_name",
        render: (row) => {
          const title = `
            IC Settlement Company Name: ${
              row.ic_settlement_company_name || "-"
            }, 
           
            ICID: ${row.icid || "-"},
            IC Name: ${row.ic_name || ""},
            Contractor Type:${row.contractorType || ""}
          `;
          const flagLabel =
            (!!row?.is_flagged && !!row?.is_cautious_flagged) ||
            !!row?.is_flagged ? (
              <FlagIcon style={{ color: "red" }} />
            ) : row?.is_cautious_flagged ? (
              <div className={classes.warningIconHeight}>
                <WarningIcon className={classes.warningIcon} />
                <div className={classes.exclamationMark}></div>
              </div>
            ) : (
              ""
            );
          const flagTitles = `Flagged Reason: ${
            row.flagReason || row.ctnFlagRsn
          }, Flagged By: ${
            row.flaggedBy || row.ctnFlaggedBy || ""
          }, USERNAME: ${
            row.flaggedByUsername || row.ctnFlaggedByUsername || ""
          }, ROLE: ${
            row.flaggedByRole || row.ctnFlaggedByRole || ""
          }, Flagged At: ${getDateString(row.flaggedAt || row.ctnFlaggedAt)}`;

          return (
            <Fragment>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <>
                  <Tooltip title={flagTitles} placement="top-start">
                    <Typography noWrap variant="body2">
                      {flagLabel}
                    </Typography>
                  </Tooltip>
                </>
                <>
                  <Tooltip title={title} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {`${row.icid || ""}/${row.ic_name || ""}`}
                    </Typography>
                  </Tooltip>
                </>
              </div>
            </Fragment>
          );
        },
      },
      {
        id: "settlement_company_name",
        label: "Settlement Company",
        field: "settlement_company_name",
        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["Pay Rate Type"]
            .isSelected,
        headerClassName: classes.tableHeaderCell,
        id: "pay_rate_type",
        label: "Pay Rate Type",
        field: "pay_rate_type",

        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["Pay Rate"].isSelected,
        id: "pay_rate",
        label: "Pay Rate",
        field: "pay_rate",
        hasEllipses: true,
        render: (row) => {
          const label = row.pay_rate ? `$${row.pay_rate || ""}` : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["Pay Quantity"]
            .isSelected,
        id: "pay_quantity",
        label: "Pay Quantity",
        field: "pay_quantity",
        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["Pay Amount"].isSelected,
        id: "pay_amount",
        label: "Pay Amount",
        field: "pay_amount",
        hasEllipses: true,
        render: (row) => {
          const label = row.pay_amount ? `$${row.pay_amount || ""}` : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["Pay Fuel Surcharge%"]
            .isSelected,
        id: "pay_fuel_surcharge",
        label: "Pay Fuel Surcharge%",
        field: "pay_fuel_surcharge",
        headerClassName: classes.tableHeaderCell,
        hasEllipses: true,
        render: (row) => {
          const label = row.pay_fuel_surcharge
            ? `${row.pay_fuel_surcharge || ""}%`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["Pay Fuel Amount"]
            .isSelected,
        id: "pay_fuel_amount",
        label: "Pay Fuel Amount",
        field: "pay_fuel_amount",
        headerClassName: classes.tableHeaderCell,
        hasEllipses: true,
        render: (row) => {
          const label = row.pay_fuel_amount
            ? `$${row.pay_fuel_amount || ""}`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["Pay Toll Amount"]
            .isSelected,
        id: "pay_toll_amount",
        label: "Pay Toll Amount",
        field: "pay_toll_amount",
        hasEllipses: true,
        render: (row) => {
          const label = row.pay_toll_amount
            ? `$${row.pay_toll_amount || ""}`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["Pay Extra Miles"]
            .isSelected,
        id: "pay_extra_miles",
        label: "Pay Extra Miles",
        field: "pay_extra_miles",
        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["Pay Rate Per Extra Mile"]
            .isSelected,
        headerClassName: classes.tableHeaderCell,
        id: "pay_rate_per_extra_mile",
        label: "Pay Rate Per Extra Mile",
        field: "pay_rate_per_extra_mile",
        hasEllipses: true,
        render: (row) => {
          const label = row.pay_rate_per_extra_mile
            ? `$${row.pay_rate_per_extra_mile || ""}`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["Pay Extra Miles Amount"]
            .isSelected,
        headerClassName: classes.tableHeaderCell,
        id: "pay_extra_mile_amount",
        label: "Pay Extra Miles Amount",
        field: "pay_extra_mile_amount",
        hasEllipses: true,
        render: (row) => {
          const label = row.pay_extra_mile_amount
            ? `$${row.pay_extra_mile_amount || ""}`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["Reimbursement"]
            .isSelected,
        id: "reimbursement",
        label: "Reimbursement",
        field: "reimbursement",
        hasEllipses: true,
        render: (row) => {
          const label = row.reimbursement ? `$${row.reimbursement || ""}` : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["Deduction"].isSelected,
        id: "deduction",
        label: "Deduction",
        field: "deduction",
        hasEllipses: true,
        render: (row) => {
          const label = row.deduction ? `$${row.deduction || ""}` : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[2].value]["Deduction Reason"]
            .isSelected,
        id: "deduction_reason",
        label: "Deduction Reason",
        field: "deduction_reason",
        hasEllipses: true,
      },
    ],
    [REPORT_TYPE[3].value]: [
      {
        label: "View Notes",
        id: "col-edit",
        noResize: true,
        render: (row) => moreActions(row),
      },
      {
        id: "customer_name",
        label: "Customer Name",
        fieldName: "customer_name",
        render: (row) => {
          const title = `
            Invoice: ${
              INVOICE_TYPES.find(
                (invoice) =>
                  invoice.id === row.invoice_type ||
                  invoice.label === row.invoice_type
              )?.label || "-"
            }
          `;
          return (
            <Tooltip title={title} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.customer_name}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[3].value]["KAM"].isSelected ||
          isRoleKAM,
        id: "kam_name",
        label: "KAM",
        field: "kam_name",
        hasEllipses: true,
        render: (row) => {
          const title = row.kam_username
            ? `${row.kam_name}-(${row.kam_username})`
            : "";
          return (
            <Tooltip title={title} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.kam_name}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[3].value]["Office"].isSelected ||
          isRoleKAM,
        id: "aa_name",
        label: "Office",
        field: "aa_name",
        hasEllipses: true,
        render: (row) => {
          const title = row.aa_username
            ? `${row.aa_name}-(${row.aa_username})`
            : "";
          return (
            <Tooltip title={title} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.aa_name}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[3].value]["Location"].isSelected,
        id: "location",
        label: "Location",
        field: "location",
        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[3].value]["Route"].isSelected,
        id: "route",
        label: "Route",
        field: "route",
        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[3].value]["State"].isSelected,
        id: "state",
        label: "State",
        field: "state",
        render: (row, rowIndex) => {
          return (
            <Tooltip title={row.state ?? ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.state ?? "-"}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[3].value]["PDX Company"].isSelected,
        id: "pdx_company_name",
        label: "PDX Company",
        field: "pdx_company_name",
        render: (row, rowIndex) => {
          return (
            <Tooltip title={row.pdx_company_name ?? ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.pdx_company_name ?? "-"}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[3].value]["Vehicle"].isSelected,
        id: "vehicle_type",
        label: "Vehicle",
        field: "vehicle_type",
        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[3].value]["ICID/IC Name"]
            .isSelected,
        id: "ic_name",
        label: "ICID/IC Name",
        headerClassName: classes.icidFlagTitle,
        fieldName: "ic_name",
        render: (row) => {
          const title = `
            IC Settlement Company Name: ${
              row.ic_settlement_company_name || "-"
            }, 
          
            ICID: ${row.icid || "-"},
            IC Name: ${row.ic_name || ""},
            Contractor Type:${row.contractorType || ""}
          `;
          const flagLabel =
            (!!row?.is_flagged && !!row?.is_cautious_flagged) ||
            !!row?.is_flagged ? (
              <FlagIcon style={{ color: "red" }} />
            ) : row?.is_cautious_flagged ? (
              <div className={classes.warningIconHeight}>
                <WarningIcon className={classes.warningIcon} />
                <div className={classes.exclamationMark}></div>
              </div>
            ) : (
              ""
            );
          const flagTitles = `Flagged Reason: ${
            row.flagReason || row.ctnFlagRsn
          }, Flagged By: ${
            row.flaggedBy || row.ctnFlaggedBy || ""
          }, USERNAME: ${
            row.flaggedByUsername || row.ctnFlaggedByUsername || ""
          }, ROLE: ${
            row.flaggedByRole || row.ctnFlaggedByRole || ""
          }, Flagged At: ${getDateString(row.flaggedAt || row.ctnFlaggedAt)}`;

          return (
            <Fragment>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <>
                  <Tooltip title={flagTitles} placement="top-start">
                    <Typography noWrap variant="body2">
                      {flagLabel}
                    </Typography>
                  </Tooltip>
                </>
                <>
                  <Tooltip title={title} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {`${row.icid || ""}/${row.ic_name || ""}`}
                    </Typography>
                  </Tooltip>
                </>
              </div>
            </Fragment>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[3].value]["Bill Rate Type"]
            .isSelected,
        headerClassName: classes.tableHeaderCell,
        id: "bill_rate_type",
        label: "Bill Rate Type",
        field: "bill_rate_type",
        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[3].value]["Bill Rate"].isSelected,
        id: "bill_rate",
        label: "Bill Rate",
        field: "bill_rate",
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_rate ? `$${row.bill_rate || ""}` : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[3].value]["Bill Quantity"]
            .isSelected,
        id: "bill_quantity",
        label: "Bill Quantity",
        field: "bill_quantity",
        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[3].value]["Bill Amount"].isSelected,
        id: "bill_amount",
        label: "Bill Amount",
        field: "bill_amount",
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_amount ? `$${row.bill_amount || ""}` : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[3].value]["Bill Fuel Surcharge%"]
            .isSelected,
        id: "bill_fuel_surcharge",
        label: "Bill Fuel Surcharge%",
        field: "bill_fuel_surcharge",
        headerClassName: classes.tableHeaderCell,
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_fuel_surcharge
            ? `${row.bill_fuel_surcharge || ""}%`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[3].value]["Bill Fuel Amount"]
            .isSelected,
        id: "bill_fuel_amount",
        label: "Bill Fuel Amount",
        field: "bill_fuel_amount",
        headerClassName: classes.tableHeaderCell,
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_fuel_amount
            ? `$${row.bill_fuel_amount || ""}`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[3].value]["Bill Toll Amount"]
            .isSelected,
        id: "bill_toll_amount",
        label: "Bill Toll Amount",
        field: "bill_toll_amount",
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_toll_amount
            ? `$${row.bill_toll_amount || ""}`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[3].value]["Bill Extra Miles"]
            .isSelected,
        id: "bill_extra_miles",
        label: "Bill Extra Miles",
        field: "bill_extra_miles",
        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[3].value][
            "Bill Rate Per Extra Mile"
          ].isSelected,
        headerClassName: classes.tableHeaderCell,
        id: "bill_rate_per_extra_mile",
        label: "Bill Rate Per Extra Mile",
        field: "bill_rate_per_extra_mile",
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_rate_per_extra_mile
            ? `$${row.bill_rate_per_extra_mile || ""}`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[3].value]["Bill Extra Miles Amount"]
            .isSelected,
        headerClassName: classes.tableHeaderCell,
        id: "bill_extra_mile_amount",
        label: "Bill Extra Miles Amount",
        field: "bill_extra_mile_amount",
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_extra_mile_amount
            ? `$${row.bill_extra_mile_amount || ""}`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
    ],
    [REPORT_TYPE[4].value]: [
      {
        id: "customer_name",
        label: "Customer Name",
        fieldName: "customer_name",
        render: (row) => {
          const title = `
            Invoice: ${
              INVOICE_TYPES.find(
                (invoice) =>
                  invoice.id === row.invoice_type ||
                  invoice.label === row.invoice_type
              )?.label || "-"
            }
          `;
          return (
            <Tooltip title={title} placement="top-start">
              <Typography variant="body2" noWrap>
                {row.customer_name}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[4].value]["Location"].isSelected,
        id: "location",
        label: "Location",
        field: "location",
        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[4].value]["IC Name"].isSelected,
        id: "ic_name",
        label: "IC Name",
        fieldName: "ic_name",
        render: (row) => {
          return (
            <Tooltip title={row?.ic_name ?? ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {row?.ic_name || "-"}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[4].value]["Route"].isSelected,
        id: "route",
        label: "Route",
        field: "route",
        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[4].value]["Bill Rate"].isSelected,
        id: "bill_rate",
        label: "Bill Rate",
        field: "bill_rate",
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_rate ? `$${row.bill_rate || ""}` : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[4].value]["Bill Quantity"]
            .isSelected,
        id: "bill_quantity",
        label: "Bill Quantity",
        field: "bill_quantity",
        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[4].value]["Bill Amount"].isSelected,
        id: "bill_amount",
        label: "Bill Amount",
        field: "bill_amount",
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_amount ? `$${row.bill_amount || ""}` : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[4].value]["Bill Fuel Surcharge%"]
            .isSelected,
        id: "bill_fuel_surcharge",
        label: "Bill Fuel Surcharge%",
        field: "bill_fuel_surcharge",
        headerClassName: classes.tableHeaderCell,
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_fuel_surcharge
            ? `${row.bill_fuel_surcharge || ""}%`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[4].value]["Bill Fuel Amount"]
            .isSelected,
        id: "bill_fuel_amount",
        label: "Bill Fuel Amount",
        field: "bill_fuel_amount",
        headerClassName: classes.tableHeaderCell,
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_fuel_amount
            ? `$${row.bill_fuel_amount || ""}`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[4].value]["Bill Extra Miles"]
            .isSelected,
        id: "bill_extra_miles",
        label: "Bill Extra Miles",
        field: "bill_extra_miles",
        hasEllipses: true,
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[4].value][
            "Bill Rate Per Extra Mile"
          ].isSelected,
        headerClassName: classes.tableHeaderCell,
        id: "bill_rate_per_extra_mile",
        label: "Bill Rate Per Extra Mile",
        field: "bill_rate_per_extra_mile",
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_rate_per_extra_mile
            ? `$${row.bill_rate_per_extra_mile || ""}`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[4].value]["Bill Extra Miles Amount"]
            .isSelected,
        headerClassName: classes.tableHeaderCell,
        id: "bill_extra_mile_amount",
        label: "Bill Extra Miles Amount",
        field: "bill_extra_mile_amount",
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_extra_mile_amount
            ? `$${row.bill_extra_mile_amount || ""}`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        isHidden:
          !state.dynamicColumns[REPORT_TYPE[4].value]["Bill Toll Amount"]
            .isSelected,
        id: "bill_toll_amount",
        label: "Bill Toll Amount",
        field: "bill_toll_amount",
        hasEllipses: true,
        render: (row) => {
          const label = row.bill_toll_amount
            ? `$${row.bill_toll_amount || ""}`
            : "-";

          return (
            <Tooltip title={label} placement="top-start">
              <Typography variant="body2" noWrap>
                {label}
              </Typography>
            </Tooltip>
          );
        },
      },
    ],
  };

  useEffect(() => {
    setTimeout(updateLayout, 1500);
  }, [state.entries]);

  useEffect(() => {
    !!appData.pendingItems.length &&
      isRoleKAM &&
      setHeaderElements([
        <div className="d-flex f-align-center m-2">
          <Chip
            className={"bg-danger color-white"}
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                openForceSubmitDialog: true,
              }));
            }}
            label={
              appData.isTabletView ? (
                <Tooltip title="PENDING SCHEDULE DATE(S)" placement="top-start">
                  <Typography variant="body2" className="text-bold">
                    ({appData.pendingItems.length})
                  </Typography>
                </Tooltip>
              ) : (
                <Typography variant="body2" className="text-bold">
                  PENDING SCHEDULE DATE(S) ({appData.pendingItems.length})
                </Typography>
              )
            }
            variant="outlined"
          />
        </div>,
      ]);

    return () => setHeaderElements([]);
  }, [appData.pendingItems]);

  useEffect(() => {
    if (kamId && isRoleKAM) {
      if (isTransferScheduleEnable) {
        fetchList("transferRecords", "", kamId);
      }
      fetchList("pendingItems", kamId);
    }
  }, [state.selectedKAM]);

  useEffect(() => {
    return () => updateContextData("pendingItems", []);
  }, []);

  useEffect(() => {
    fetchEntries(
      state.startDate,
      state.endDate,
      state.reportType,
      state.reportType.value === REPORT_TYPE[4].value
        ? {
            customer: [state.billingCustomer],
            location: [state.billingLocation],
          }
        : state.filters,
      state.pageFilters
    );
  }, [state.pageFilters]);

  useEffect(() => {
    fetchLeaveTypeList();
    fetchList("customers");
    fetchList("users");
    fetchCompanyList();
    fetchList("icList");
    fetchPDXCompanyList();
    fetchVehicleTypeList();
    fetchList("IC");
    fetchFilterData();
  }, [match?.params?.path]);

  const routeList = useMemo(() => {
    return state.filters?.customer?.length > 0
      ? state.filters?.location?.length > 0
        ? state.filters?.customer
            ?.filter((customer) => customer.is_active)
            ?.map((item) => item.customer_branches)
            .flat()
            ?.filter((item) => state.filters.location.includes(item.location))
            ?.map((item) => item.routes)
            .flat()
            ?.map((item) => item.name)
        : state.filters?.customer
            ?.filter((customer) => customer.is_active)
            ?.map((item) => item.customer_branches)
            .flat()
            ?.map((item) => item.routes)
            .flat()
            ?.map((route) => route.name)
      : state.filterData?.route;
  }, [state.filterData.route, state.filters.customer, state.filters.location]);

  const billingLocationList = useMemo(() => {
    return !!state.billingCustomer
      ? state.billingCustomer.customer_branches
          .flat()
          .map((item) => item.location)
      : state.filterData.location;
  }, [state.filterData.location, state.billingCustomer]);

  const locationList = useMemo(() => {
    return state.filters?.customer?.length > 0
      ? state.filters?.customer
          ?.filter((customer) => customer.is_active)
          .map((item) => item.customer_branches)
          .flat()
          .map((item) => item.location)
      : state.filterData.location;
  }, [state.filterData.location, state.filters?.customer]);

  const handleCloseViewNotes = () => {
    setState((prevState) => ({
      ...prevState,
      viewNotes: false,
    }));
  };

  return (
    <div id="numbers-page-wrapper">
      <div className="mr-5">
        <div className={clsx("mb-2 mr-10", classes.fixedHeader)}>
          <Typography variant="h4" color="primary" className=" ml-2">
            Reports
            <Button
              style={{ float: "right" }}
              className="mt-1 mr-2"
              variant="contained"
              color="primary"
              onClick={() => history.push("/home")}
              startIcon={<ArrowBackIcon />}
            >
              Back To Home
            </Button>
            <Button
              style={{ float: "right" }}
              className="mt-1 mr-2"
              variant="contained"
              color="primary"
              onClick={() => history.push("/report")}
              startIcon={<ArrowBackIcon />}
            >
              Back To Reports
            </Button>
          </Typography>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            {!isRoleKAM
              ? "View/Download Reports."
              : "View/Download Settlement Reports."}
          </Typography>
        </div>
        <div className="d-flex f-justify-between f-align-center">
          <div className="d-flex ml-1">
            <div>
              <div className="d-flex f-justify-between f-align-center">
                {!isRoleKAM && (
                  <FormControl>
                    <FormLabel className="color-grey mb-1">
                      Please select reports type.
                    </FormLabel>
                    <RadioGroup
                      row
                      value={state.reportType?.value}
                      onChange={(evt) => {
                        const reportType = REPORT_TYPE.find(
                          (type) => type.value === evt.target.value
                        );
                        const isBilling =
                          reportType?.value !== REPORT_TYPE[1].value;
                        const isSettlement =
                          reportType?.value !== REPORT_TYPE[0].value;
                        setState((prevState) => ({
                          ...prevState,
                          reportType,
                          showGrid: false,
                          // filters: {
                          //   ...prevState.filters,

                          //   customer: isBilling
                          //     ? prevState.filters?.customer
                          //     : null,
                          //   location: isBilling
                          //     ? prevState.filters?.location
                          //     : null,
                          //   icid: isSettlement ? prevState.filters?.icid : null,
                          //   settlementCompany: isSettlement
                          //     ? prevState.filters?.settlementCompany
                          //     : null,
                          // },
                        }));
                      }}
                    >
                      {REPORT_TYPE.map(({ label, value }) => (
                        <FormControlLabel
                          disabled={state.isLoading}
                          value={value}
                          control={<Radio color="primary" size="small" />}
                          label={label}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                )}
                {!appData.isTabletView ? null : (
                  <div className="d-flex f-align-center">
                    <Tooltip
                      title={`Export ${state.reportType?.label} Report`}
                      placement="top-start"
                    >
                      <IconButton
                        color="primary"
                        disabled={state.isFetching || !state.entries?.length}
                        onClick={handleExport}
                      >
                        <SystemUpdateAltIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </div>
              {(state.reportType || isRoleKAM) && (
                <div className="d-flex f-align-center f-wrap">
                  <Datepicker
                    mask
                    preventClear
                    label="Start date"
                    selected={new Date(state.startDate)}
                    maxDate={new Date(state.endDate)}
                    classes={{
                      input: {
                        root: clsx("mr-4 mt-4", classes.datepickerWrapper),
                      },
                    }}
                    onChange={(startDate) => {
                      setState((prevState) => ({
                        ...prevState,
                        startDate: getDateString(startDate),
                      }));
                    }}
                  />
                  <Datepicker
                    mask
                    preventClear
                    label="End date"
                    selected={new Date(state.endDate)}
                    minDate={new Date(state.startDate)}
                    maxDate={new Date()}
                    classes={{
                      input: {
                        root: clsx("mr-4 mt-4", classes.datepickerWrapper),
                      },
                    }}
                    onChange={(endDate) => {
                      setState((prevState) => ({
                        ...prevState,
                        endDate: getDateString(endDate),
                      }));
                    }}
                  />
                  {state.reportType.value === REPORT_TYPE[4].value && (
                    <>
                      <Autocomplete
                        className={clsx("mr-4 mt-4", classes.customerDropdown)}
                        size="small"
                        value={state.billingCustomer}
                        classes={{
                          paper: "mb-2",
                        }}
                        options={appData?.customers}
                        disableClearable
                        getOptionLabel={(option) => option.name || ""}
                        renderInput={(params) =>
                          state.isFetchingList.customers ? (
                            <Skeleton
                              variant="rect"
                              width="100%"
                              height="42px"
                            />
                          ) : (
                            <TextField
                              {...params}
                              label="Customer"
                              variant="outlined"
                              required
                            />
                          )
                        }
                        onChange={(evt, value) => {
                          setState((prevState) => ({
                            ...prevState,
                            billingCustomer: value,
                            billingLocation: defaultState.billingLocation,
                          }));
                        }}
                      />
                      <Autocomplete
                        className={clsx("mr-4 mt-4", classes.customerDropdown)}
                        size="small"
                        classes={{
                          paper: "mb-2",
                        }}
                        disableClearable
                        value={state.billingLocation}
                        options={billingLocationList}
                        getOptionLabel={(option) => option || ""}
                        renderInput={(params) =>
                          state.isFetchingList.filters ? (
                            <Skeleton
                              variant="rect"
                              width="100%"
                              height="42px"
                            />
                          ) : (
                            <TextField
                              {...params}
                              label="Location"
                              variant="outlined"
                              required
                            />
                          )
                        }
                        onChange={(evt, billingLocation) => {
                          setState((prevState) => ({
                            ...prevState,
                            billingLocation,
                          }));
                        }}
                      />{" "}
                    </>
                  )}{" "}
                  {state.reportType.value !== REPORT_TYPE[4].value && (
                    <>
                      <Autocomplete
                        className={clsx("mr-4 mt-4", classes.customerDropdown)}
                        size="small"
                        multiple
                        //value={state.customer}
                        defaultValue={state.filters?.customer || []}
                        classes={{
                          paper: "mb-2",
                        }}
                        ChipProps={{
                          component: (a) => {
                            return (
                              <div>
                                <Tooltip
                                  placement={"top-start"}
                                  title={a.children[1]?.props?.children || ""}
                                >
                                  <Chip
                                    size="small"
                                    className={classes.chip}
                                    label={
                                      <Typography variant="body3" noWrap>
                                        {a.children[1]?.props?.children.length >
                                        4
                                          ? a.children[1]?.props?.children.slice(
                                              0,
                                              4
                                            ) + "..."
                                          : a.children[1]?.props?.children}
                                      </Typography>
                                    }
                                    deleteIcon
                                    onDelete={(evt) => {
                                      a.children[2]?.props?.onClick(evt);
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            );
                          },
                        }}
                        options={appData?.customers}
                        getOptionLabel={(option) => option.name || ""}
                        renderInput={(params) =>
                          state.isFetchingList.customers ? (
                            <Skeleton
                              variant="rect"
                              width="100%"
                              height="42px"
                            />
                          ) : (
                            <TextField
                              {...params}
                              label="Customer"
                              variant="outlined"
                            />
                          )
                        }
                        onChange={(evt, value) => {
                          setState((prevState) => ({
                            ...prevState,
                            filters: {
                              ...prevState.filters,
                              customer: value,
                            },
                          }));
                        }}
                      />

                      <Autocomplete
                        className={clsx("mr-4 mt-4", classes.customerDropdown)}
                        size="small"
                        classes={{
                          paper: "mb-2",
                        }}
                        multiple
                        ChipProps={{
                          component: (a) => {
                            return (
                              <div>
                                <Tooltip
                                  placement={"top-start"}
                                  title={a.children[1]?.props?.children || ""}
                                >
                                  <Chip
                                    size="small"
                                    className={classes.chip}
                                    width={15}
                                    label={
                                      <Typography variant="body3" noWrap>
                                        {a.children[1]?.props?.children.length >
                                        4
                                          ? a.children[1]?.props?.children.slice(
                                              0,
                                              4
                                            ) + "..."
                                          : a.children[1]?.props?.children}
                                      </Typography>
                                    }
                                    deleteIcon
                                    onDelete={(evt) => {
                                      a.children[2]?.props?.onClick(evt);
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            );
                          },
                        }}
                        //value={state.filters?.location}
                        defaultValue={state.filters?.location}
                        options={locationList}
                        getOptionLabel={(option) =>
                          option?.location || option || ""
                        }
                        renderInput={(params) =>
                          state.isFetchingList.filters ? (
                            <Skeleton
                              variant="rect"
                              width="100%"
                              height="42px"
                            />
                          ) : (
                            <TextField
                              {...params}
                              label="Location"
                              variant="outlined"
                            />
                          )
                        }
                        onChange={(evt, location) => {
                          setState((prevState) => ({
                            ...prevState,
                            filters: {
                              ...prevState.filters,
                              location,
                            },
                          }));
                        }}
                      />
                    </>
                  )}
                  {state.reportType.value !== REPORT_TYPE[4].value && (
                    <Autocomplete
                      className={clsx("mr-4 mt-4", classes.customerDropdown)}
                      size="small"
                      classes={{
                        paper: "mb-2",
                      }}
                      multiple
                      //value={state.filters?.route}
                      defaultValue={state.filters?.route}
                      ChipProps={{
                        component: (a) => {
                          return (
                            <div>
                              <Tooltip
                                placement={"top-start"}
                                title={a.children[1]?.props?.children || ""}
                              >
                                <Chip
                                  size="small"
                                  className={classes.chip}
                                  width={15}
                                  label={
                                    <Typography variant="body3" noWrap>
                                      {a.children[1]?.props?.children.length > 4
                                        ? a.children[1]?.props?.children.slice(
                                            0,
                                            4
                                          ) + "..."
                                        : a.children[1]?.props?.children}
                                    </Typography>
                                  }
                                  deleteIcon
                                  onDelete={(evt) => {
                                    a.children[2]?.props?.onClick(evt);
                                  }}
                                />
                              </Tooltip>
                            </div>
                          );
                        },
                      }}
                      options={routeList}
                      getOptionLabel={(option) => option?.name || option || ""}
                      renderInput={(params) =>
                        state.isFetchingList.filters ? (
                          <Skeleton variant="rect" width="100%" height="42px" />
                        ) : (
                          <TextField
                            {...params}
                            label="Route"
                            variant="outlined"
                          />
                        )
                      }
                      onChange={(evt, route) => {
                        setState((prevState) => ({
                          ...prevState,
                          filters: {
                            ...prevState.filters,
                            route: route,
                          },
                        }));
                      }}
                    />
                  )}
                  {state.reportType.value !== REPORT_TYPE[4].value && (
                    <Autocomplete
                      className={clsx("mr-4 mt-4", classes.customerDropdown)}
                      size="small"
                      classes={{
                        paper: "mb-2",
                      }}
                      // value={state.filters?.vehicle}
                      defaultValue={state.filters?.vehicle_type}
                      options={state.filterData?.vehicle}
                      multiple
                      ChipProps={{
                        component: (a) => {
                          return (
                            <div>
                              <Tooltip
                                placement={"top-start"}
                                title={a.children[1]?.props?.children || ""}
                              >
                                <Chip
                                  size="small"
                                  className={classes.chip}
                                  width={15}
                                  label={
                                    <Typography variant="body3" noWrap>
                                      {a.children[1]?.props?.children.length > 4
                                        ? a.children[1]?.props?.children.slice(
                                            0,
                                            4
                                          ) + "..."
                                        : a.children[1]?.props?.children}
                                    </Typography>
                                  }
                                  deleteIcon
                                  onDelete={(evt) => {
                                    a.children[2]?.props?.onClick(evt);
                                  }}
                                />
                              </Tooltip>
                            </div>
                          );
                        },
                      }}
                      getOptionLabel={(option) => option.value || ""}
                      renderInput={(params) =>
                        state.isFetchingList.filters ? (
                          <Skeleton variant="rect" width="100%" height="42px" />
                        ) : (
                          <TextField
                            {...params}
                            label="Vehicle"
                            variant="outlined"
                          />
                        )
                      }
                      onChange={(evt, vehicle) => {
                        setState((prevState) => ({
                          ...prevState,
                          filters: {
                            ...prevState.filters,
                            vehicle_type: vehicle,
                          },
                        }));
                      }}
                    />
                  )}
                  {state.reportType.value !== REPORT_TYPE[4].value &&
                    !isRoleKAM && (
                      <Autocomplete
                        className={clsx("mr-4 mt-4", classes.customerDropdown)}
                        classes={{
                          paper: "mb-2",
                        }}
                        size="small"
                        ChipProps={{
                          component: (a) => {
                            return (
                              <div>
                                <Tooltip
                                  placement={"top-start"}
                                  title={a.children[1]?.props?.children || ""}
                                >
                                  <Chip
                                    size="small"
                                    className={classes.chip}
                                    width={15}
                                    label={
                                      <Typography variant="body3" noWrap>
                                        {a.children[1]?.props?.children.length >
                                        4
                                          ? a.children[1]?.props?.children.slice(
                                              0,
                                              4
                                            ) + "..."
                                          : a.children[1]?.props?.children}
                                      </Typography>
                                    }
                                    deleteIcon
                                    onDelete={(evt) => {
                                      a.children[2]?.props?.onClick(evt);
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            );
                          },
                        }}
                        // value={state.filters?.kamId}
                        defaultValue={state.filters?.kamId}
                        options={appData.users.filter(
                          (user) => user.role === "KAM" && user.is_active
                        )}
                        multiple
                        getOptionLabel={(option) =>
                          option.name ||
                          `${option.first_name || ""} ${
                            option.last_name || ""
                          }-${option.email || ""}-(${option.username || ""})`
                        }
                        renderInput={(params) =>
                          state.isFetchingList.filters ? (
                            <Skeleton
                              variant="rect"
                              width="100%"
                              height="42px"
                            />
                          ) : (
                            <TextField
                              {...params}
                              label="KAM"
                              variant="outlined"
                            />
                          )
                        }
                        onChange={(evt, kam) => {
                          setState((prevState) => ({
                            ...prevState,
                            filters: {
                              ...prevState.filters,
                              kamId: kam,
                            },
                          }));
                        }}
                      />
                    )}
                  {state.reportType.value !== REPORT_TYPE[4].value && (
                    <Autocomplete
                      className={clsx("mr-4 mt-4", classes.customerDropdown)}
                      classes={{
                        paper: "mb-2",
                      }}
                      size="small"
                      ChipProps={{
                        component: (a) => {
                          return (
                            <div>
                              <Tooltip
                                placement={"top-start"}
                                title={a.children[1]?.props?.children || ""}
                              >
                                <Chip
                                  size="small"
                                  className={classes.chip}
                                  width={15}
                                  label={
                                    <Typography variant="body3" noWrap>
                                      {a.children[1]?.props?.children.length > 4
                                        ? a.children[1]?.props?.children.slice(
                                            0,
                                            4
                                          ) + "..."
                                        : a.children[1]?.props?.children}
                                    </Typography>
                                  }
                                  deleteIcon
                                  onDelete={(evt) => {
                                    a.children[2]?.props?.onClick(evt);
                                  }}
                                />
                              </Tooltip>
                            </div>
                          );
                        },
                      }}
                      // value={state.filters?.kamId}
                      defaultValue={state.filters?.aaId}
                      options={appData.users.filter(
                        (user) => user.role === "AA" && user.is_active
                      )}
                      multiple
                      getOptionLabel={(option) =>
                        option.name ||
                        `${option.first_name || ""} ${option.last_name || ""}-${
                          option.email || ""
                        }-(${option.username || ""})`
                      }
                      renderInput={(params) =>
                        state.isFetchingList.filters ? (
                          <Skeleton variant="rect" width="100%" height="42px" />
                        ) : (
                          <TextField
                            {...params}
                            label="Office"
                            variant="outlined"
                          />
                        )
                      }
                      onChange={(evt, aa) => {
                        setState((prevState) => ({
                          ...prevState,
                          filters: {
                            ...prevState.filters,
                            aaId: aa,
                          },
                        }));
                      }}
                    />
                  )}
                  {state.reportType.value !== REPORT_TYPE[4].value && (
                    <Autocomplete
                      className={clsx("mr-4 mt-4", classes.customerDropdown)}
                      size="small"
                      multiple
                      classes={{
                        paper: "mb-2",
                      }}
                      ChipProps={{
                        component: (a) => {
                          return (
                            <div>
                              <Tooltip
                                placement={"top-start"}
                                title={a.children[1]?.props?.children || ""}
                              >
                                <Chip
                                  size="small"
                                  className={classes.chip}
                                  width={15}
                                  label={
                                    <Typography variant="body3" noWrap>
                                      {a.children[1]?.props?.children.length > 4
                                        ? a.children[1]?.props?.children.slice(
                                            0,
                                            4
                                          ) + "..."
                                        : a.children[1]?.props?.children}
                                    </Typography>
                                  }
                                  deleteIcon
                                  onDelete={(evt) => {
                                    a.children[2]?.props?.onClick(evt);
                                  }}
                                />
                              </Tooltip>
                            </div>
                          );
                        },
                      }}
                      defaultValue={state.filters?.pdxCompany}
                      options={state.pdxCompanyList}
                      getOptionLabel={(option) => option.value || ""}
                      renderInput={(params) =>
                        state.isFetchingList.IC ? (
                          <Skeleton variant="rect" width="100%" height="42px" />
                        ) : (
                          <TextField
                            {...params}
                            label="PDX Company"
                            variant="outlined"
                          />
                        )
                      }
                      onChange={(evt, pdxCompany) => {
                        setState((prevState) => ({
                          ...prevState,
                          filters: {
                            ...prevState.filters,
                            pdxCompany,
                          },
                        }));
                      }}
                    />
                  )}
                  {((state.reportType.value !== REPORT_TYPE[0].value &&
                    state.reportType.value !== REPORT_TYPE[4].value) ||
                    isRoleKAM) && (
                    <>
                      <Autocomplete
                        className={clsx("mr-4 mt-4", classes.customerDropdown)}
                        size="small"
                        multiple
                        classes={{
                          paper: "mb-2",
                        }}
                        ChipProps={{
                          component: (a) => {
                            return (
                              <div>
                                <Tooltip
                                  placement={"top-start"}
                                  title={a.children[1]?.props?.children || ""}
                                >
                                  <Chip
                                    size="small"
                                    className={classes.chip}
                                    width={15}
                                    label={
                                      <Typography variant="body3" noWrap>
                                        {a.children[1]?.props?.children.length >
                                        4
                                          ? a.children[1]?.props?.children.slice(
                                              0,
                                              4
                                            ) + "..."
                                          : a.children[1]?.props?.children}
                                      </Typography>
                                    }
                                    deleteIcon
                                    onDelete={(evt) => {
                                      a.children[2]?.props?.onClick(evt);
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            );
                          },
                        }}
                        options={appData?.icList
                          ?.sort((a, b) =>
                            a.first_name.localeCompare(b.first_name)
                          )
                          ?.map((item) => ({
                            ...item,
                            label: `${item?.icid || ""} - ${
                              item?.last_name || ""
                            } ${item?.first_name || ""}${
                              state.filterData?.vehicle.some(
                                (ele) => ele?.id === item?.default_vehicle_type
                              )
                                ? `-(${
                                    state.filterData?.vehicle.find(
                                      (ele) =>
                                        ele?.id === item?.default_vehicle_type
                                    )?.value
                                  })`
                                : ""
                            }`,
                          }))}
                        //value={state.filters?.IC}
                        defaultValue={state.filters?.IC}
                        getOptionLabel={(option) => option.label || ""}
                        renderInput={(params) =>
                          state.isFetchingList.IC ? (
                            <Skeleton
                              variant="rect"
                              width="100%"
                              height="42px"
                            />
                          ) : (
                            <TextField
                              {...params}
                              label="ICID/IC Name"
                              variant="outlined"
                            />
                          )
                        }
                        onChange={(evt, IC) => {
                          setState((prevState) => ({
                            ...prevState,
                            filters: {
                              ...prevState.filters,
                              IC: IC,
                            },
                          }));
                        }}
                      />
                    </>
                  )}
                  {((state.reportType.value !== REPORT_TYPE[0].value &&
                    state.reportType.value !== REPORT_TYPE[3].value &&
                    state.reportType.value !== REPORT_TYPE[4].value) ||
                    isRoleKAM) && (
                    <Autocomplete
                      className={clsx("mr-4 mt-4", classes.customerDropdown)}
                      size="small"
                      classes={{
                        paper: "mb-2",
                      }}
                      multiple
                      ChipProps={{
                        component: (a) => {
                          return (
                            <div>
                              <Tooltip
                                placement={"top-start"}
                                title={a.children[1]?.props?.children || ""}
                              >
                                <Chip
                                  size="small"
                                  className={classes.chip}
                                  width={15}
                                  label={
                                    <Typography variant="body3" noWrap>
                                      {a.children[1]?.props?.children.length > 6
                                        ? a.children[1]?.props?.children.slice(
                                            0,
                                            6
                                          ) + "..."
                                        : a.children[1]?.props?.children}
                                    </Typography>
                                  }
                                  deleteIcon
                                  onDelete={(evt) => {
                                    a.children[2]?.props?.onClick(evt);
                                  }}
                                />
                              </Tooltip>
                            </div>
                          );
                        },
                      }}
                      // value={state.filters?.settlementCompany}
                      defaultValue={state.filters?.settlementCompany}
                      options={state.settlementCompanyList}
                      getOptionLabel={(option) => option.value || ""}
                      renderInput={(params) =>
                        state.isFetchingList.settlementCompanies ? (
                          <Skeleton variant="rect" width="100%" height="42px" />
                        ) : (
                          <TextField
                            {...params}
                            label="Settlement Co..."
                            variant="outlined"
                          />
                        )
                      }
                      onChange={(evt, settlementCompany) => {
                        setState((prevState) => ({
                          ...prevState,
                          filters: {
                            ...prevState.filters,
                            settlementCompany: settlementCompany,
                          },
                        }));
                      }}
                    />
                  )}
                  <Button
                    className="mt-4"
                    variant="contained"
                    color="primary"
                    disabled={
                      state.reportType.value === REPORT_TYPE[4].value &&
                      !(
                        state.startDate?.length &&
                        state.endDate?.length &&
                        !!state.billingCustomer &&
                        !!state.billingLocation
                      )
                    }
                    onClick={() => {
                      setPageConfig(PAGE_KEYS.REPORT, {
                        isGoButtonIsClicked: true,
                        reportType: state.reportType,
                        startDateFilter: state.startDate,
                        endDateFilter: state.endDate,
                        customerFilter: state.filters.customer,
                        locationFilter: state.filters.location,
                        routeFilter: state.filters.route,
                        vehicleFilter: state.filters.vehicle_type,
                        kamIdFilter: state.filters.kamId,
                        aaIdFilter: state.filters.aaId,
                        pdxCompanyFilter: state.filters.pdxCompany,
                        settlementCompanyFilter:
                          state.filters.settlementCompany,
                        ICFilter: state.filters.IC,
                        billingLocationFilter: state.billingLocation,
                        billingCustomerFilter: state.billingCustomer,
                      });
                      fetchEntries(
                        state.startDate,
                        state.endDate,
                        state.reportType,
                        state.reportType.value === REPORT_TYPE[4].value
                          ? {
                              customer: [state.billingCustomer],
                              location: [state.billingLocation],
                            }
                          : state.filters,
                        state.pageFilters
                      );
                    }}
                  >
                    Go
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="d-flex"></div>
        </div>

        {!state.showGrid && state.reportType.value === REPORT_TYPE[4].value && (
          <Paper
            elevation={2}
            className={clsx(
              "d-flex f-align-center f-justify-center mt-6 p-2 pl-4 pr-4 p-relative",
              classes.paperHeight
            )}
          >
            <div className="d-flex flex-column f-justify-center f-align-center">
              <Typography variant="h6">
                Please select Customer, Location and click on Go button to view
                the report.
              </Typography>
            </div>
          </Paper>
        )}

        {state.showGrid && (
          <Paper
            elevation={2}
            className={clsx("p-4 mt-10 h-100", classes.paperSpacing)}
          >
            <div
              className={clsx(
                "d-flex f-align-center f-justify-between p-2 pl-4 pr-4",
                classes.actionsWrapper
              )}
            >
              <div className="d-flex f-align-center">
                <div>
                  {Object.keys(state.reportType.counts).map((key) =>
                    state.isFetching ? (
                      <Skeleton
                        className={clsx("ml-2 mr-2", classes.countSkeleton)}
                        variant="rect"
                        animation="wave"
                        height={20}
                        width={100}
                        key={key}
                      />
                    ) : (
                      <>
                        <Chip
                          color="primary"
                          size="small"
                          label={`${key}: $${
                            state.totalCounts[state.reportType.counts[key]] || 0
                          }`}
                          className={clsx("ml-2 mr-2 p-2 mt-2 mb-2 text-bold", {
                            "ml-1 mr-1 p-2 mt-1 mb-1": appData.isTabletView,
                          })}
                        />
                        <>
                          {state.reportType?.value === "billingandsettlement" &&
                            (key === "Bill Extra Miles Amount" ||
                              key === "Deduction") && <br></br>}
                        </>
                      </>
                    )
                  )}
                </div>
              </div>
              <div className="d-flex f-align-center">
                {appData.isTabletView ? null : (
                  <Tooltip
                    title={`Export ${state.reportType?.label} Report`}
                    placement="top-start"
                  >
                    <IconButton
                      color="primary"
                      disabled={state.isFetching || !state.entries?.length}
                      onClick={handleExport}
                    >
                      <SystemUpdateAltIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {!!isRoleKAM && (
                  <Dropdown
                    classes={{
                      paper: classes.tunePaper,
                    }}
                    disablePortal={false}
                    remainOpen
                    isMultiSelect
                    placement="bottom-end"
                    options={Object.keys(
                      state.dynamicColumnsKam[state.reportType.value]
                    ).map(
                      (key) =>
                        state.dynamicColumnsKam[state.reportType.value][key]
                    )}
                    customToggle={({ anchorRef, onClick }) => (
                      <Tooltip
                        title="Show/Hide column(s)"
                        placement="top-start"
                      >
                        <IconButton
                          color="primary"
                          ref={anchorRef}
                          onClick={onClick}
                        >
                          <TuneIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    onChange={(options) => {
                      setState((prevState) => {
                        const isSelectAll = options.some(
                          (item) => item.value === "Select All"
                        );
                        const isCurrentSelectAll =
                          state.dynamicColumnsKam[prevState.reportType.value][
                            "Select All"
                          ].isSelected;

                        const isClickedOnSelectAll =
                          isSelectAll !== isCurrentSelectAll;

                        const isAllOptionSelected =
                          Object.keys(
                            state.dynamicColumnsKam[prevState.reportType.value]
                          ).length -
                            1 ===
                          options.filter((item) => item.value !== "Select All")
                            .length;

                        const updatedDynamicColumns = Object.keys(
                          prevState.dynamicColumnsKam[
                            prevState.reportType.value
                          ]
                        ).reduce((acc, key) => {
                          const isSelected = isClickedOnSelectAll
                            ? isSelectAll
                            : key === "Select All"
                            ? isAllOptionSelected
                            : !!options.some((item) => item.value === key);
                          return {
                            ...acc,
                            [key]: {
                              ...prevState.dynamicColumnsKam[
                                prevState.reportType.value
                              ][key],
                              isSelected,
                            },
                          };
                        }, {});
                        setPageConfig(PAGE_KEYS.REPORT, {
                          dynamicColumnsKam: {
                            ...prevState.dynamicColumns,
                            [prevState.reportType.value]: {
                              ...updatedDynamicColumns,
                            },
                          },
                        });
                        return {
                          ...prevState,
                          dynamicColumnsKam: {
                            ...prevState.dynamicColumnsKam,
                            [prevState.reportType.value]: {
                              ...updatedDynamicColumns,
                            },
                          },
                        };
                      });
                    }}
                  />
                )}
                {!isRoleKAM && (
                  <Dropdown
                    classes={{
                      paper: classes.tunePaper,
                    }}
                    disablePortal={false}
                    remainOpen
                    isMultiSelect
                    placement="bottom-end"
                    options={Object.keys(
                      state.dynamicColumns[state.reportType.value]
                    ).map(
                      (key) => state.dynamicColumns[state.reportType.value][key]
                    )}
                    customToggle={({ anchorRef, onClick }) => (
                      <Tooltip
                        title="Show/Hide column(s)"
                        placement="top-start"
                      >
                        <IconButton
                          color="primary"
                          ref={anchorRef}
                          onClick={onClick}
                        >
                          <TuneIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    onChange={(options) => {
                      setState((prevState) => {
                        const isSelectAll = options.some(
                          (item) => item.value === "Select All"
                        );
                        const isCurrentSelectAll =
                          state.dynamicColumns[prevState.reportType.value][
                            "Select All"
                          ].isSelected;

                        const isClickedOnSelectAll =
                          isSelectAll !== isCurrentSelectAll;

                        const isAllOptionSelected =
                          Object.keys(
                            state.dynamicColumns[prevState.reportType.value]
                          ).length -
                            1 ===
                          options.filter((item) => item.value !== "Select All")
                            .length;

                        const updatedDynamicColumns = Object.keys(
                          prevState.dynamicColumns[prevState.reportType.value]
                        ).reduce((acc, key) => {
                          const isSelected = isClickedOnSelectAll
                            ? isSelectAll
                            : key === "Select All"
                            ? isAllOptionSelected
                            : !!options.some((item) => item.value === key);
                          return {
                            ...acc,
                            [key]: {
                              ...prevState.dynamicColumns[
                                prevState.reportType.value
                              ][key],
                              isSelected,
                            },
                          };
                        }, {});
                        setPageConfig(PAGE_KEYS.REPORT, {
                          dynamicColumns: {
                            ...prevState.dynamicColumns,
                            [prevState.reportType.value]: {
                              ...updatedDynamicColumns,
                            },
                          },
                        });
                        return {
                          ...prevState,
                          dynamicColumns: {
                            ...prevState.dynamicColumns,
                            [prevState.reportType.value]: {
                              ...updatedDynamicColumns,
                            },
                          },
                        };
                      });
                    }}
                  />
                )}
                <Tooltip placement="top-end" title="Reset All Filters">
                  <IconButton
                    // disabled={pageConfig === undefined || pageConfig === null}
                    color="primary"
                    onClick={() => {
                      updatePageConfig("report");
                      window.location.reload();
                    }}
                  >
                    <RotateLeftIcon />
                  </IconButton>
                </Tooltip>
                {/* <Tooltip placement="top-start" title="Filter">
                <IconButton
                  color="primary"
                  className={clsx({
                    "bg-primary": !!state.pageFilters?.length,
                  })}
                  onClick={() => {
                    setState((prevState) => ({
                      ...prevState,
                      isFiltering: true,
                    }));
                  }}
                >
                  <FilterListIcon
                    className={clsx({
                      "color-white": !!state.pageFilters?.length,
                    })}
                  />
                </IconButton>
              </Tooltip> */}
              </div>
            </div>
            <Grid
              columns={
                !isRoleKAM
                  ? columnConfig[state.reportType?.value]
                  : columnConfig[REPORT_TYPE[1].value]
              }
              rows={state.entries.map((entry) => {
                const hasAwaitingOrRejectedStatus = entry.notes?.some(
                  (item) =>
                    item.status === "AWAITING REVIEW" ||
                    item.status === "DS REJECTED"
                );
                const hasAdminRejectedStatus = entry.notes?.some(
                  (item) =>
                    item.status === "ADMIN REJECTED" ||
                    item.status === "MGR REJECTED"
                );

                return {
                  ...entry,
                  className: clsx({
                    [classes.highlightAwaitingReview]:
                      hasAwaitingOrRejectedStatus,
                    [classes.highlightAdminRejected]:
                      !hasAwaitingOrRejectedStatus && hasAdminRejectedStatus,
                  }),
                };
              })}
              actionBarConfig={null}
              hasPagination={false}
              hasSelection={false}
              isLoading={state.isFetching}
              onReady={(instance) => (gridHelper = instance)}
            />
          </Paper>
        )}

        {state.openForceSubmitDialog && (
          <ForceSubmitDialog
            open={state.openForceSubmitDialog}
            pendingItems={appData.pendingItems.map((date, index) => ({
              date,
              id: index,
            }))}
            forceSubmitReasonList={state.forceSubmitReasonList}
            onClose={handleClose}
            kamId={kamId}
            onForceSubmit={() => fetchList("pendingItems", kamId)}
          />
        )}
        {/* {state.isFiltering && (
        <FilterComponent
          isFiltering={state.isFiltering}
          filterConfig={
            state.reportType?.value === "billing"
              ? filterConfigBilling
              : state.reportType?.value === "settlement"
              ? filterConfigSettlement
              : filterConfigBillingSettlement
          }
          appliedFilters={state.pageFilters}
          onFilterApply={handleFilterChange}
          onClose={() => {
            setState((prevState) => ({
              ...prevState,
              isFiltering: false,
            }));
          }}
        />
      )} */}
        {state.viewNotes && (
          <Dialog
            open
            classes={{
              paper: classes.paperSize,
              paperWidthSm: classes.paperWidthSm,
            }}
            onClose={handleCloseViewNotes}
          >
            <Dialog.Title hasClose>
              <div className="mb-2">
                <Typography variant="h4" color="primary" className="ml-2">
                  Review/KAM Notes
                </Typography>
                <Typography variant="body2" style={{ marginLeft: "10px" }}>
                  View Review/KAM Notes.
                </Typography>
              </div>
              <div className="mb-2">
                <Typography variant="body2" color="primary" className="ml-2">
                  {state.isLoading ? (
                    <Skeleton variant="text" width="50%" height="100%" />
                  ) : state.viewNotes !== undefined ? (
                    <div>
                      {`Customer Name : ${
                        state.viewNotes.customer_name || "-"
                      }`}
                      <br />
                      {`KAM : ${state.viewNotes.kam_name || "-"}`}
                      <br />
                      {`Route : ${state.viewNotes.route || "-"}`}
                      <br />
                      {`PDX Company : ${
                        state.viewNotes.pdx_company_name || "-"
                      }`}
                      <br />
                      {`Vehicle : ${state.viewNotes.vehicle_type || "-"}`}
                      {state.viewNotes.settlement_company_name !== undefined ? (
                        <br />
                      ) : null}
                      {state.viewNotes.settlement_company_name !== undefined
                        ? `Settlement company : ${
                            state.viewNotes.settlement_company_name || "-"
                          }`
                        : null}
                      {state.viewNotes.ic_name !== undefined ? <br /> : null}
                      {state.viewNotes.ic_name !== undefined
                        ? `ICID/IC Name : ${state.viewNotes.icid || "-"}/${
                            state.viewNotes.ic_name || "-"
                          }`
                        : null}
                    </div>
                  ) : null}
                </Typography>
              </div>
            </Dialog.Title>
            <Dialog.Content>
              <Paper
                elevation={2}
                className={clsx("p-4 mt-10", classes.paperSpacing)}
              >
                <Grid
                  columns={notesColumnConfig}
                  rows={state.viewNotes.notes?.sort(
                    (a, b) => new Date(a.date) - new Date(b.date)
                  )}
                  actionBarConfig={null}
                  isLoading={state.isFetching}
                  hasSelection={false}
                  hasPagination={false}
                  onReady={(instance) => (gridHelper = instance)}
                />
              </Paper>
            </Dialog.Content>
            <Dialog.Actions>
              <Button
                type="submit"
                variant="contained"
                onClick={handleCloseViewNotes}
                className="ml-2 mr-2"
                color="primary"
              >
                OK
              </Button>
            </Dialog.Actions>
          </Dialog>
        )}
      </div>
    </div>
  );
};

export default ViewReport;
