import makeStyles from "@material-ui/core/styles/makeStyles";
import grey from "@material-ui/core/colors/grey";
import yellow from "@material-ui/core/colors/yellow";

const useStyles = makeStyles((theme) => ({
  gridPaper: {
    maxHeight: "470px",
  },
  actionsWrapper: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  paperSize: {
    width: 316,
  },
  paperSpacing: {
    marginTop: "14px !important",
    paddingTop: "0px !important",
    paddingBottom: "0px !important ",
  },
  datepickerWrapper: {
    width: 160,
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  tunePaper: {
    minWidth: 156,
  },
  underlined: {
    color: "#007bff",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  modalDimensions: (props) => ({
    minWidth: props.isTabletView ? 700 : 950,
  }),
  highlightUpdate: {
    background: `${yellow[50]} !important`,
  },
  highlightApproved: {
    background: grey[100],
  },
  tableHeaderCell: {
    minWidth: 144,
  },
  autocompleteWidth: {
    minWidth: 180,
  },
  kamInput: {
    minWidth: 200,
    maxWidth: 250,
  },
  paper: {
    minWidth: 150,
    maxHeight: 200,
  },
  addressListPaper: {
    minWidth: 700,
    maxWidth: 600,
    minHeight: 120,
    maxHeight: 404,
  },
  highlightDisable: {
    background: theme.palette.grey[200],
  },
  dropdownPaper: {
    minWidth: 156,
  },
  deleteAddress: {
    width: 370,
  },
  noRecordsImg: {
    maxHeight: 187,
  },
  addressGridridPaper: { minHeight: 120, maxHeight: 240, overflow: "hidden" },
  editAddress: { width: "20px", paddingRight: "0px !important" },
  fixedHeader: {
    width: "100%",
    top: "64px",
    height: "64px",
    zIndex: 1000,
    position: "sticky",
    background: "#fafafa",
  },
}));

export default useStyles;
