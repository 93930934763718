import { Dialog } from "shared/components";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuListItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import { AppContext } from "shared/contexts";
import { useLocation, useHistory } from "react-router-dom";
import SettingsIcon from "@material-ui/icons/Settings";
import { useState, useEffect, useContext, useCallback } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import useStyles from "./style";
import { Tooltip, Typography } from "@material-ui/core";
import React from "react";
import Service from "./../../../modules/daily-schedule/service";
import TextField from "@material-ui/core/TextField";
import SharedService from "modules/shared/service";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
// import { ROLE } from "./../../constants/index";
import { isTransferScheduleEnable, noop } from "shared";
import { getTokenData, getUserData, setUserData } from "utils";
import { DialogContent } from "@material-ui/core";
import { ROLE_PERMISSIONS, ROLE } from "modules/shared/constants";
import { Skeleton } from "@material-ui/lab";

const WEEK_DAYS = [
  {
    label: "Mon",
    value: "1",
  },
  {
    label: "Tues",
    value: "2",
  },
  {
    label: "Wed",
    value: "3",
  },
  {
    label: "Thu",
    value: "4",
  },
  {
    label: "Fri",
    value: "5",
  },
  {
    label: "Sat",
    value: "6",
  },
  {
    label: "Sun",
    value: "0",
  },
];

const defaultState = {
  open: false,
  checkbox: null,
  userRole: null,
  pendingItems: [],
  entries: [],
  weeklyOff: [],
  isLoading: false,
};
let isFieldChange = false;
const Settings = ({ onClose = noop, match, kamUserData = null }) => {
  const classes = useStyles();
  const userData = getTokenData();
  const [state, setState] = useState(defaultState);
  const { updateContextData } = useContext(AppContext);

  const fetchEntries = useCallback(async () => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    const { data, error } = await SharedService.getUsersList();
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }
    setState((prevState) => ({
      ...prevState,
      entries: data?.users || defaultState.entries,
      isLoading: false,
    }));
  }, []);

  const isKamUser = state.entries.find((user) => user.id === kamUserData?.id);

  useEffect(() => {
    if (state.open) {
      setState((prevState) => ({
        ...prevState,
        weeklyOff: isKamUser?.weekly_off || defaultState.weeklyOff,
      }));
    }
  }, [state.open, isKamUser]);

  const handleSubmit = async (weeklyOff = []) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const { error } = await SharedService.updateUserSettings({
      weeklyOff: weeklyOff.join(","),
      employee_id: isKamUser?.id,
    });
    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    } else {
      setUserData({ week_off_days: weeklyOff });
      toast.success("Updated Successfully.");

      if ((userData.role || "").toLowerCase() === ROLE.KAM) {
        fetchPendingItems(userData.id);
      }
    }

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      open: false,
    }));
    isFieldChange = false;
  };

  const fetchPendingItems = async (kamId) => {
    const { data, error } = await SharedService.getPendingItems(kamId);
    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    updateContextData("pendingItems", data.pendingItems || []);
  };

  const handleWeeklyOffChange = (evt, item) => {
    const { checked } = evt.target;
    setState((prevState) => {
      const WeeklyOff = checked
        ? [...prevState.weeklyOff, item.value]
        : [...prevState.weeklyOff].filter((day) => day !== item.value);
      return {
        ...prevState,
        weeklyOff: [...new Set(WeeklyOff)],
      };
    });
  };

  const handleClose = () => {
    isFieldChange = false;
    setState(defaultState);
  };

  return (
    <>
      <MenuListItem
        onClick={() => {
          setState((prevState) => ({
            ...prevState,
            open: true,
          }));
          fetchEntries();
        }}
        style={{ padding: "0px" }}
      >
        <ListItemIcon className={classes.listIcon}>
          <Tooltip title="No Schedule Setting" placement="top-start">
            <SettingsIcon style={{ color: "#004700" }} />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="No Schedule Setting" />
      </MenuListItem>
      <Dialog
        open={state.open}
        classes={{
          paper: classes.dialog,
        }}
        onClose={handleClose}
      >
        <Dialog.Title hasClose>No Schedule</Dialog.Title>
        <Dialog.Content>
          <div className="d-flex f-align-center f-wrap">
            {state.isLoading ? (
              <Skeleton variant="text" width="100%" height="40px" />
            ) : (
              WEEK_DAYS.map((item, index) => {
                return (
                  <div className={classes.weekDay}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          name={item.label}
                          value={item.value}
                          checked={state.weeklyOff.includes(item.value)}
                          onClick={(evt) => {
                            isFieldChange = true;
                            handleWeeklyOffChange(evt, item);
                          }}
                        />
                      }
                      label={
                        <Typography variant="body1">{item.label}</Typography>
                      }
                    />
                  </div>
                );
              })
            )}
          </div>
        </Dialog.Content>
        <Dialog.Actions>
          <div className="p-4">
            <Button
              variant="contained"
              color="primary"
              disabled={state.isLoading || !isFieldChange}
              onClick={() => handleSubmit(state.weeklyOff)}
              className="ml-2 mr-2"
            >
              Update
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </Button>
          </div>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};
export default Settings;
