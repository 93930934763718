import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  datepickerWrapper: {
    width: 160,
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  paperSize: {
    width: 316,
  },
  matrixPaperSize: {
    width: 424,
  },
  autocompletePaper: {
    [theme.breakpoints.down("sm")]: {
      maxHeight: 200,
    },
  },
  dividerBackground: { background: "#4f5052" },
  divider: {
    height: "3px",
    width: "247px",
    position: "relative",
    left: "-16px",
  },
  noPriceMatrix: {
    height: "320px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperSpacing: {
    marginTop: "14px !important",
    paddingTop: "4px !important",
    paddingBottom: "0px !important ",
  },
  progressBtn: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  rangeAutocomplete: {
    minWidth: 254,
  },
  fixedHeader: {
    width: "100%",
    top: "64px",
    height: "75px",
    zIndex: 1000,
    position: "sticky",
    background: "#fafafa",
  },
  editFuel: {
    width: "72px",
  },
  paperHeight: {
    minHeight: 320,
  },
  field: {
    width: 264,
    marginRight: 24,
    marginBottom: 5,
  },
}));

export default useStyles;
