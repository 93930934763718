import React, { useEffect } from "react";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ActionDialog } from "shared/components";
import { useCallback, useState } from "react";
import Popper from "@material-ui/core/Popper";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import useStyles from "./style";
import { noop } from "shared";
import { getUTCDateString } from "utils";
import { CollapsibleGrid } from "../../shared/components";
import { Paper } from "@material-ui/core";
import { toast } from "react-toastify";
import Service from "../service";

let isFieldChange = false;

const defaultState = {
  scheduleChecked: [],
  scheduleUnchecked: [],
  selectedScheduleRows: [],
  transfer_notes: [],
  isVerifyLoading: false,
  transferData: [],
  errors: {},
};

const TransferSchedule = ({
  appDataList = {},
  isTransferSchedule = false,
  isLoading = false,
  isScheduleTransferred = false,
  tabIndex = 1,
  entries = [],
  transferRecordStatus = [],
  selectedKAM = null,
  userData,
  handleCancel = noop,
  handleTransferSchedule = noop,
}) => {
  const classes = useStyles();
  const [state, setState] = useState({
    ...defaultState,
  });

  const kamUsers = appDataList.kamUsers
    ?.filter((kam) => !!kam.is_active)
    ?.sort((a, b) => b.first_name - a.first_name);

  useEffect(() => {
    const processEntries = (entries, keyExtractor) => {
      const processedEntries = {};

      entries
        ?.filter((entry) => !!entry[keyExtractor])
        ?.forEach((item) => {
          const matchedKam = kamUsers?.find(
            (kam) => kam.id === item[keyExtractor]
          );
          if (!!matchedKam) {
            const key =
              keyExtractor === "transfer_to"
                ? item.id
                : getUTCDateString(item?.schedule_date);
            processedEntries[key] = {
              ...matchedKam,
              row: { ...item },
            };
          }
        });

      return processedEntries;
    };

    const scheduleUnchecked = processEntries(entries, "transfer_to");

    setState((prevState) => ({
      ...prevState,
      scheduleUnchecked:
        tabIndex === 1 || isScheduleTransferred
          ? scheduleUnchecked || defaultState.scheduleUnchecked
          : defaultState.scheduleUnchecked,
    }));
  }, []);

  const handleFieldChange = (evt, rowId) => {
    isFieldChange = true;
    const value = evt.currentTarget?.value || evt.target?.value;

    setState((prevState) => {
      const rowIndex = prevState.transfer_notes.findIndex(
        (item) => item.ds_id === rowId
      );

      const updatedNotes =
        rowIndex === -1
          ? [...prevState.transfer_notes, { ds_id: rowId, note: value }]
          : prevState.transfer_notes.map((item, index) =>
              index === rowIndex ? { ...item, note: value } : item
            );

      return {
        ...prevState,
        transfer_notes: updatedNotes,
      };
    });
  };

  const kamDropdown = (
    row,
    selectedRows,
    scheduleChecked,
    scheduleUnchecked,
    entry,
    isKAMChecked,
    isKAMUnchecked
  ) => {
    const groupScheduleDate = entry.find(
      (item) => item?.id === row?.id
    )?.schedule_date;

    const groupSelectedRows = selectedRows.filter(
      (id) =>
        entry.find((item) => item?.id === id)?.schedule_date ===
        groupScheduleDate
    );

    const filteredScheduleChecked =
      !!groupSelectedRows &&
      Object.keys(scheduleChecked)
        .filter((key) => {
          const scheduleDate = scheduleChecked[key]?.row?.schedule_date;
          return scheduleDate === groupScheduleDate;
        })
        .reduce((result, key) => {
          result[key] = scheduleChecked[key];
          return result;
        }, {});

    const isChecked =
      !!groupSelectedRows && groupSelectedRows.includes(row?.id);
    const selectedValue = isChecked
      ? scheduleChecked[row?.id]
      : scheduleUnchecked[row?.id];

    const missingIds =
      !!groupSelectedRows &&
      groupSelectedRows.filter((id) => !scheduleChecked[id]);

    if (
      missingIds.length > 0 &&
      Object.keys(filteredScheduleChecked).length > 0
    ) {
      const defaultValue = Object.values(filteredScheduleChecked)[0];

      setState((prevState) => ({
        ...prevState,
        [isKAMChecked]: {
          ...scheduleChecked,
          ...missingIds.reduce((acc, id) => {
            const foundItem = entry.find((item) => item.id === id);

            if (foundItem) {
              acc[id] = {
                ...defaultValue,
                row: foundItem,
              };
            }

            return acc;
          }, {}),
        },
      }));
    }

    return (
      <Autocomplete
        className={clsx("mb-1", classes.autocompleteWidth)}
        disableClearable
        size="small"
        value={selectedValue !== undefined ? selectedValue : null}
        options={kamUsers.filter(
          (kam) =>
            !(kam?.id === userData || kam?.id === selectedKAM?.id) &&
            !entries.some((ele) => ele.KAM?.id === kam?.id)
        )}
        PopperComponent={({ style, ...props }) => (
          <Popper {...props} style={{ ...style, height: 0 }} />
        )}
        getOptionLabel={(option) =>
          option.name ||
          `${option.first_name || ""} ${option.last_name || ""}-${
            option.email || ""
          }${option.username ? `-(${option.username})` : ""}`
        }
        getOptionSelected={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Transfer to KAM"
            variant="outlined"
            required
            error={state.errors[row.id]}
          />
        )}
        onChange={(evt, value) => {
          isFieldChange = true;
          const updatedState = isChecked
            ? {
                [isKAMChecked]: {
                  ...scheduleChecked,
                  ...groupSelectedRows.reduce((acc, checkedRowId) => {
                    acc[checkedRowId] = {
                      ...value,
                      row: entry.find((item) => item.id === checkedRowId),
                    };
                    return acc;
                  }, {}),
                },
              }
            : {
                [isKAMUnchecked]: {
                  ...scheduleUnchecked,
                  [row.id]: {
                    ...value,
                    row: row,
                  },
                },
              };

          setState((prevState) => {
            const newErrors = { ...prevState.errors };
            delete newErrors[row.id];

            if (isChecked) {
              groupSelectedRows.forEach((checkedRowId) => {
                delete newErrors[checkedRowId];
              });
            }

            return {
              ...prevState,
              ...updatedState,
              errors: newErrors,
            };
          });
        }}
      />
    );
  };

  const customerColumnConfig = [
    {
      id: "customer_name",
      label: "Customer Name",
      fieldName: "customer_name",
      render: (row) => {
        return (
          <Typography variant="body2" noWrap>
            {row.customer_branch?.customer?.name}
          </Typography>
        );
      },
    },
    {
      id: "customer_branch.location",
      label: "Location",
      field: "customer_branch.location",
      render: (row) => {
        return (
          <Typography variant="body2" noWrap>
            {row.customer_branch?.location || "-"}
          </Typography>
        );
      },
    },
    {
      headerClassName: classes.tableHeaderCell,
      id: "route",
      label: "Route",
      field: "route",
      render: (row) => {
        return (
          <Tooltip title={undefined ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.route}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "pdx_company_name",
      label: "PDX Company",
      field: "pdx_company_name",
      render: (row, rowIndex) => {
        return (
          <Typography variant="body2" noWrap>
            {row.customer_branch?.pdxCompany?.value ?? "-"}
          </Typography>
        );
      },
    },
    {
      id: "default_KAM",
      label: "Transfer to KAM",
      field: "default_KAM",
      render: (row) =>
        kamDropdown(
          row,
          state.selectedScheduleRows,
          state.scheduleChecked,
          state.scheduleUnchecked,
          entries,
          "scheduleChecked",
          "scheduleUnchecked"
        ),
    },
    {
      id: "transfer_notes",
      label: "Transfer Notes",
      field: "transfer_notes",
      headerClassName: classes.tableHeaderwidth,
      render: (row) => (
        <Tooltip title={undefined ?? ""} placement="top-start">
          <TextField
            className="mb-1"
            fullWidth
            type="text"
            name={`transferReason_${row.id}`}
            label="Transfer Note"
            variant="outlined"
            size="small"
            value={
              state.transfer_notes.find((item) => item.ds_id === row.id)
                ?.note || ""
            }
            onChange={(evt) => handleFieldChange(evt, row.id)}
          />
        </Tooltip>
      ),
    },
  ];

  const handleScheduleSelectionChange = useCallback((selectedScheduleRows) => {
    setState((prevState) => {
      const filteredChecked = Object.keys(prevState.scheduleChecked)
        ?.filter((key) => selectedScheduleRows.includes(+key))
        ?.reduce((obj, key) => {
          obj[key] = prevState.scheduleChecked[key];
          return obj;
        }, {});
      const filteredUnchecked = Object.keys(prevState.scheduleUnchecked)
        ?.filter((key) => !selectedScheduleRows.includes(+key))
        ?.reduce((obj, key) => {
          obj[key] = prevState.scheduleUnchecked[key];
          return obj;
        }, {});
      return {
        ...prevState,
        selectedScheduleRows,
        scheduleChecked: filteredChecked,
        scheduleUnchecked: filteredUnchecked,
      };
    });
  }, []);

  const handleVerifyTransferRequest = async (
    scheduleChecked,
    scheduleUnchecked
  ) => {
    setState((prevState) => ({
      ...prevState,
      isVerifyLoading: true,
    }));

    const modifyScheduleCheckedDates = (scheduleChecked) => {
      return scheduleChecked.map((kam) => ({
        ...kam,
        row: {
          ...kam.row,
          schedule_date: getUTCDateString(kam.row.schedule_date),
        },
      }));
    };

    const modifiedScheduleChecked = modifyScheduleCheckedDates(
      Object.values(scheduleChecked)
    );

    const mergedSchedule = Object.values([
      ...modifiedScheduleChecked,
      ...Object.values(scheduleUnchecked),
    ]).reduce((acc, kam) => {
      const scheduleDate = kam?.row.schedule_date;
      const transferToKamId = kam?.id;

      if (!acc[scheduleDate]) {
        acc[scheduleDate] = {
          schedule_date: scheduleDate,
          Transfer_to_kam_ids: [],
        };
      }

      if (transferToKamId) {
        acc[scheduleDate].Transfer_to_kam_ids.push(transferToKamId);
      }

      return acc;
    }, {});

    const filteredSchedule = Object.values(mergedSchedule)?.filter(
      (item) => item.Transfer_to_kam_ids.length > 0
    );

    const { error } = await Service.verifyTransferRequest(filteredSchedule);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isVerifyLoading: false,
      }));

      if (error[0] !== undefined && error[0].code === "PDX-ITK-001") {
        const newErrors = { ...state.errors };

        error[0].transferData.forEach((item) => {
          item.Transfer_to_kam_ids.forEach((kamId) => {
            const entries = [
              ...modifiedScheduleChecked,
              ...Object.values(scheduleUnchecked),
            ].filter(
              (entry) => entry?.id === kamId || entry?.KAM?.id === kamId
            );

            entries.forEach((entry) => {
              if (entry.row.schedule_date === item.schedule_date) {
                newErrors[entry.row.id] = true;
              }
            });
          });
        });

        setState((prevState) => ({
          ...prevState,
          errors: newErrors,
          transferData: error[0].transferData || defaultState.transferData,
        }));
        return toast.error(
          Array.isArray(error)
            ? "Cannot transfer the record to the selected KAM(s) either he marked his own schedule as forced submit or they have already transferred their records for the same schedule date(s)."
            : error.message
        );
      } else {
        toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      }
    } else {
      handleTransferSchedule(
        state.scheduleChecked,
        state.scheduleUnchecked,
        state.transfer_notes,
        () => {
          setState((prevState) => ({
            ...prevState,
            scheduleChecked: defaultState.scheduleChecked,
            scheduleUnchecked: defaultState.scheduleUnchecked,
            selectedScheduleRows: defaultState.selectedScheduleRows,
            transfer_notes: defaultState.transfer_notes,
            isVerifyLoading: false,
          }));
        }
      );
    }
  };

  return (
    <>
      <ActionDialog
        classes={{
          confirm: isLoading ? "" : "bg-primary",
          paper: classes.modalDimensions,
        }}
        open={isTransferSchedule}
        contentText={
          <div>
            <Typography className="text-bold mb-4">
              Please select the KAM to transfer the schedule.
            </Typography>
            <Paper elevation={2} className={classes.paper}>
              <CollapsibleGrid
                multiScroll
                columns={customerColumnConfig}
                rows={entries
                  ?.sort((a, b) =>
                    a?.schedule_date.localeCompare(b?.schedule_date)
                  )
                  ?.map((entry) => ({
                    ...entry,
                    schedule_date: getUTCDateString(entry.schedule_date),
                    className: clsx({
                      [classes.transferSchedule]:
                        tabIndex === 1 &&
                        !!entry.transfer_by &&
                        !!entry.transfer_to,
                    }),
                  }))}
                selectedScheduleRows={state.selectedScheduleRows}
                uniqueBy="schedule_date"
                actionBarConfig={null}
                isLoading={false}
                hasPagination={false}
                onSelectionChange={handleScheduleSelectionChange}
                hasSelection={true}
                classes={{
                  container: classes.gridPaper,
                  gridActions: "f-justify-end",
                }}
              />
            </Paper>
          </div>
        }
        positiveActionLabel={
          <>
            Transfer
            {(isLoading || state.isVerifyLoading) && (
              <CircularProgress size={24} className="p-absolute progress-btn" />
            )}
          </>
        }
        onConfirm={() => {
          isFieldChange = false;
          if (!!transferRecordStatus && transferRecordStatus.length > 0) {
            return toast.error(
              "There are existing transfer records for the dates you've selected. To start a new transfer, you'll have to reject the current transfer records first."
            );
          }
          handleVerifyTransferRequest(
            state.scheduleChecked,
            state.scheduleUnchecked,
            state.transfer_notes,
            () => {
              setState((prevState) => ({
                ...prevState,
                scheduleChecked: defaultState.scheduleChecked,
                scheduleUnchecked: defaultState.scheduleUnchecked,
                selectedScheduleRows: defaultState.selectedScheduleRows,
                transfer_notes: defaultState.transfer_notes,
              }));
            }
          );
        }}
        onCancel={() => {
          handleCancel();
          isFieldChange = false;
          setState((prevState) => ({
            ...prevState,
            scheduleChecked: defaultState.scheduleChecked,
            scheduleUnchecked: defaultState.scheduleUnchecked,
            selectedScheduleRows: defaultState.selectedScheduleRows,
            transfer_notes: defaultState.transfer_notes,
          }));
        }}
        isConfirmDisabled={
          isLoading ||
          state.isVerifyLoading ||
          !(
            isFieldChange &&
            !!entries.length &&
            entries.length ===
              Object.values({
                ...state.scheduleChecked,
                ...state.scheduleUnchecked,
              }).length
          ) ||
          Object.keys(state.errors).length > 0
        }
      />
    </>
  );
};

export default TransferSchedule;
