import {
  VALIDATIONS,
  LOCATION_REGEX,
  PHONE_REGEX,
  PREVENT_DECIMAL,
  EIN,
  SSN,
  SSN_MASK,
  LIMIT,
} from "shared/constants";
import isEmpty from "validator/es/lib/isEmpty";
import isLength from "validator/es/lib/isLength";
import isFloat from "validator/es/lib/isFloat";
import isEmail from "validator/es/lib/isEmail";

export const validator = (
  validatorType,
  validationValue,
  value,
  type = "string",
  customMessage = ""
) => {
  let message = null;
  value = (value ?? "").toString().trim();
  switch (validatorType) {
    case VALIDATIONS.REQUIRED:
      if (validationValue && isEmpty(value)) {
        message = "Required";
      }
      break;
    case VALIDATIONS.MAX_LENGTH:
      if (value && !isLength(value, { max: parseInt(validationValue) })) {
        message = `Max: ${validationValue} ${
          type === "number" ? "digit(s)" : "char(s)"
        }.`;
      }
      break;
    case VALIDATIONS.MIN_LENGTH:
      if (value && !isLength(value, { min: parseInt(validationValue) })) {
        message = `Min: ${validationValue} ${
          type === "number" ? "digit(s)" : "char(s)"
        }.`;
      }
      break;
    case VALIDATIONS.MAX:
      if (value && !isFloat(value, { max: parseInt(validationValue) })) {
        message = `Max value: ${validationValue}.`;
      }
      break;
    case VALIDATIONS.MIN:
      if (value && !isFloat(value, { min: parseInt(validationValue) })) {
        message = `Min value: ${validationValue}.`;
      }
      break;
    case VALIDATIONS.EMAIL:
      if (value && !isEmail(value)) {
        message = `Invalid email address.`;
      }
      break;
    case VALIDATIONS.PHONE:
      if (value && !PHONE_REGEX.test(value)) {
        message = `Invalid Phone Number.`;
      }
      break;
    case VALIDATIONS.LOCATION:
      if (value && !LOCATION_REGEX.test(value)) {
        message = `Only special character(s) not allowed in location.`;
      }
      break;
    case VALIDATIONS.PREVENT_DECIMAL:
      if (value && PREVENT_DECIMAL.test(value)) {
        message = `Decimal values not allowed.`;
      }
      break;
    case VALIDATIONS.EIN:
      if (value && !EIN.test(value)) {
        message = `Invalid EIN.`;
      }
      break;
    case VALIDATIONS.SSN:
      if (value && !SSN.test(value)) {
        message = `Invalid SSN.`;
      }
      break;
    case VALIDATIONS.SSN_MASK:
      if (value && !SSN_MASK.test(value)) {
        message = `Invalid SSN.`;
      }
      break;
    case VALIDATIONS.LIMIT:
      if (value && !LIMIT.test(value)) {
        message = "Max digit allowed: 10.";
      }
      break;
    case VALIDATIONS.REGEX:
      if (value && !validationValue.test(value)) {
        message = customMessage || `Invalid.`;
      }
      break;
  }
  return {
    isValid: !message,
    message,
  };
};
