import { useRef, useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import InputBase from "@material-ui/core/InputBase";
import SendIcon from "@material-ui/icons/Send";
import clsx from "clsx";
import { ProfileAvatar } from "shared/components";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { useStyles } from "./style";
import ClearIcon from "@material-ui/icons/Clear";
import { Avatar, Button } from "@material-ui/core";
import { getFormattedTime } from "utils";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { noop } from "shared";
import Badge from "@material-ui/core/Badge";

const defaultState = {
  message: "",
};

const ChatWindow = ({
  index = "",
  id = "",
  entry = {},
  userData = {},
  onlineUsers = [],
  totalMessagesCount = 0,
  readMessageUsers = [],
  unreadMessageUsers = [],
  loggedInusers = [],
  currentSelected = {},
  groupByDate = noop,
  handleSendMsg = noop,
  handleClose = noop,
}) => {
  const [state, setState] = useState({
    ...defaultState,
  });

  const classes = useStyles();
  const scrollRef = useRef();

  const msgGroupedByDate = groupByDate(
    onlineUsers
      .filter(
        (user) =>
          // user?.id !== userData?.id &&
          user?.is_room_msg === false &&
          (user?.sender_id === userData?.id ||
            user?.recipient_id === userData?.id)
      )
      .sort((a, b) => Intl.Collator().compare(a.created_at, b.created_at))
  );
  const dateArray = Object.keys(msgGroupedByDate);

  const setMsg = (event) => {
    setState((prevState) => ({
      ...prevState,
      message: event.target.value,
    }));
  };

  const sendChat = (event) => {
    event.preventDefault();
    if (state.message.length > 0) {
      handleSendMsg(state.message, entry);
      setState((prevState) => ({
        ...prevState,
        message: "",
      }));
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const scrollItThere = () => {
    scrollRef.current?.scrollIntoView({
      block: "start",
      behavior: "auto",
    });
  };

  useEffect(() => {
    scrollItThere();
  }, [currentSelected, scrollItThere]);

  return (
    <>
      <div style={{ margin: "0px" }}>
        <Accordion
          className={
            id == 1
              ? classes.accordionChatBoxlenght1
              : id == 2
              ? classes.accordionChatBoxlenght2
              : classes.accordionChatBox0
          }
          defaultExpanded={true}
        >
          <div
            className={clsx(
              "d-flex f-align-center f-justify-between",
              classes.chatBoxHeader
            )}
          >
            <AccordionSummary
              classes={{
                content: classes.content,
                expanded: classes.expanded,
              }}
              style={{
                width: "254px",
                minHeight: "42px",
              }}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className={classes.style}>
                {entry?.profile_image_path === null ||
                entry?.profile_image_path === undefined ? (
                  <ProfileAvatar
                    className={classes.ProfileAvatar}
                    userData={
                      (state.fullName = {
                        profile_image_path: userData?.profile_image_path,
                        fullName: `${entry.first_name || ""} ${
                          entry.last_name || ""
                        }`,
                      })
                    }
                  />
                ) : (
                  <Avatar
                    className={classes.ProfileAvatar}
                    alt="avatar"
                    src={entry?.profile_image_path}
                  />
                )}

                <Typography>
                  {`${entry?.first_name || ""} ${entry?.last_name || ""}`}
                </Typography>
                {loggedInusers?.includes(entry?.id) ? (
                  <FiberManualRecordIcon
                    size="small"
                    className={classes.onlineUser}
                  />
                ) : null}
              </div>
            </AccordionSummary>
            {(totalMessagesCount > 0
              ? [...unreadMessageUsers, ...readMessageUsers]
              : []
            ).map((element) =>
              entry?.id == element?.id ? (
                <Badge
                  badgeContent={element.count > 0 ? element.count : null}
                  color="error"
                  className={classes.showHeaderBadges}
                />
              ) : null
            )}
            <Button
              onClick={() => handleClose(entry, true)}
              className={classes.chatBoxClose}
            >
              <ClearIcon />
            </Button>
          </div>
          <div>
            <Divider />
            <div className={classes.chatMessages}>
              {dateArray.map((date) => {
                return (
                  <div>
                    {msgGroupedByDate[date].every((msg) => {
                      if (
                        entry?.id === msg.sender_id ||
                        entry?.id === msg.recipient_id
                      ) {
                        state.msgExistsForDate = true;
                      }
                      if (state.msgExistsForDate) return false;
                      else return true;
                    })}
                    {state.msgExistsForDate && (
                      <h2 className={classes.groupByDateHeader}>
                        <span className={classes.groupByDate}>{date}</span>
                      </h2>
                    )}
                    {(state.msgExistsForDate = false)}
                    {msgGroupedByDate[date].map((message) => {
                      if (
                        entry?.id === message.sender_id ||
                        entry?.id === message.recipient_id
                      ) {
                        return (
                          <div ref={scrollRef}>
                            <div
                              className={`${classes.messagee} ${
                                message.sender_id === entry?.id
                                  ? classes.recieved
                                  : classes.sended
                              }`}
                            >
                              <div>
                                <p
                                  className={`${
                                    message.sender_id === entry?.id
                                      ? classes.recievedMessage
                                      : classes.sendedMessage
                                  }`}
                                >
                                  {
                                    <>
                                      <div className={classes.moveInNextLine}>
                                        {message.message}
                                      </div>
                                      <div
                                        className="d-flex f-align-center f-justify-end"
                                        style={{ gap: "4px" }}
                                      >
                                        <div className={classes.messageTime}>
                                          {getFormattedTime(
                                            new Date(message.created_at)
                                          )}
                                        </div>
                                        <div className={classes.messageTime}>
                                          {message?.recipient_id !=
                                            userData?.id &&
                                            (!message?.is_read ? (
                                              <DoneAllIcon
                                                className={classes.notSeen}
                                              />
                                            ) : (
                                              <DoneAllIcon
                                                className={classes.seen}
                                              />
                                            ))}
                                        </div>
                                      </div>
                                    </>
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                );
              })}
            </div>
            <Divider />
            <Grid
              item
              xs={12}
              style={{
                width: "100%",
              }}
              className="d-flex f-align-center"
            >
              <InputBase
                className={classes.inputcontainer}
                type="text"
                inputProps={{
                  maxLength: 200,
                  style: { margin: "0px 10px", fontSize: "0.8rem" },
                }}
                fullWidth={true}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && !!e.shiftKey) {
                    // prevent default behavior
                    e.preventDefault();
                    return setState((prevState) => ({
                      ...prevState,
                      message: e.target.value + "\n",
                    }));
                  }

                  if (e.key === "Enter") {
                    e.preventDefault();
                    sendChat(e);
                  }
                }}
                maxRows="4"
                multiline={true}
                placeholder="Write a message..."
                onChange={setMsg}
                value={state.message}
              />

              <Button
                type="submit"
                onClick={(event) => sendChat(event)}
                variant="contained"
                color="primary"
                disabled={!state.message}
                className={classes.buttonStyle}
              >
                <SendIcon className={classes.buttonText} />
              </Button>
            </Grid>
          </div>
        </Accordion>
      </div>
    </>
  );
};

export default ChatWindow;
