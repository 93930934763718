import { noop } from "shared/constants";
import { preventInputKeyCodes, getTokenData } from "utils";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Button, Tooltip, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import useStyles from "../style";
import { ROLE } from "modules/shared/constants";
import clsx from "clsx";
import PostAddIcon from "@material-ui/icons/PostAdd";

const defaultState = {
  viewExistingNotes: false,
  showNotesValue: [],
};

const MiscPanel = ({
  entry = {},
  MGRList = [],
  AAList = [],
  AdminList = [],
  profitPercentage = 0,
  isNew = false,
  isTabletView = false,
  reviewNotesList = [],
  aa_mgrNotesList = [],
  onFieldChange = noop,
  handleShowAddLookupDialog = noop,
  isRoleMGR,
}) => {
  const classes = useStyles({ isTabletView });
  const [state, setState] = useState(defaultState);
  const currentUser = getTokenData() || {};
  const isRoleKAM = (currentUser?.role || "").toLowerCase() === ROLE.KAM;
  const handleFieldChange = (evt) => {
    const { name, value, type } = evt.currentTarget;
    onFieldChange(name, value, "misc", type);
  };

  const showExistingNotesValue = () => {
    const splitNotes = entry.review_notes?.split("||");

    const finalNotes = splitNotes?.map((note) => {
      let str = note;

      const startIndexOfDate = str?.indexOf("-");
      const lastIndexOfDate = str?.indexOf(": ");
      const formattedDate = new Date(
          str?.substring(startIndexOfDate + 1, lastIndexOfDate) + " GMT"
        )?.toLocaleString(),
        str1 =
          str.substring(0, startIndexOfDate) +
          "-" +
          formattedDate +
          str.substring(lastIndexOfDate);
      return str1;
    });

    return setState((prevState) => ({
      ...prevState,
      showNotesValue: finalNotes,
    }));
  };

  useEffect(() => {
    if (!isNew) {
      showExistingNotesValue();
    }
  }, [state.showExistingNotesValue]);

  return (
    <>
      <div className="d-flex f-align-center f-wrap">
        <Autocomplete
          size="small"
          disableClearable
          className={classes.field}
          value={entry.office}
          options={[...AAList]}
          getOptionLabel={(option) =>
            option.name ||
            `${option.first_name || ""} ${option.last_name || ""}-${
              option.email || ""
            }-(${option.username || ""})`
          }
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label="Office"
              variant="outlined"
              error={entry.errors.office.trim()}
              helperText={entry.errors.office}
            />
          )}
          onChange={(evt, value) => {
            onFieldChange("office", value, "misc");
            onFieldChange("manager", value?.manager, "misc");
          }}
        />
        <Autocomplete
          size="small"
          disableClearable
          className={classes.field}
          value={entry.manager}
          options={MGRList}
          // options={[...MGRList, ...AdminList]}
          getOptionLabel={(option) =>
            option.name ||
            `${option.first_name || ""} ${option.last_name || ""}-${
              option.email || ""
            }-(${option.username || ""})`
          }
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label="Manager"
              variant="outlined"
              error={entry.errors.manager.trim()}
              helperText={entry.errors.manager}
            />
          )}
          onChange={(evt, value) => onFieldChange("manager", value, "misc")}
        />
        <TextField
          disabled={true}
          className={classes.field}
          size="small"
          type="number"
          InputProps={{
            classes: {
              root: clsx({
                "color-error": profitPercentage < 0,
              }),
            },
            endAdornment: <InputAdornment>%</InputAdornment>,
          }}
          name="profit_percentage"
          label="Profit Percentage"
          variant="outlined"
          value={profitPercentage}
          helperText=" "
        />
        <Tooltip
          placement="top-start"
          title={entry.kam_notes || ""}
          // classes={{ tooltip: classes.customSpace }}
        >
          <TextField
            multiline
            className={classes.textArea}
            disabled={!isRoleKAM}
            rows={2}
            name="kam_notes"
            label="KAM Note"
            variant="outlined"
            size="small"
            value={entry.kam_notes}
            onChange={handleFieldChange}
            error={entry.errors.kam_notes.trim()}
            helperText={entry.errors.kam_notes}
          />
        </Tooltip>
        {state.showNotesValue === null ||
        state.showNotesValue === undefined ||
        state.showNotesValue.length === 0 ? (
          <TextField
            multiline
            inputProps={{
              readOnly: true,
            }}
            className={classes.textArea}
            rows={2}
            name="new_review_notes"
            label="Review Notes"
            variant="outlined"
            size="small"
            value={
              !!state.showNotesValue
                ? !!state.showNotesValue[1]
                  ? state.showNotesValue[0].length > 60
                    ? state.showNotesValue[0].slice(0, 60) + "..."
                    : state.showNotesValue[0]
                  : state.showNotesValue[0]
                : "-"
            }
            // error={entry.errors.review_notes.trim()}
            helperText={" "}
          />
        ) : (
          <Tooltip
            //classes={{ tooltip: classes.customSpace }}
            placement="top-start"
            title={
              <div
                dangerouslySetInnerHTML={{
                  __html: (state.showNotesValue || [])?.join("<br>"),
                }}
              />
            }
          >
            <TextField
              multiline
              inputProps={{
                readOnly: true,
              }}
              className={classes.textArea}
              rows={2}
              name="new_review_notes"
              label="Review Notes"
              variant="outlined"
              size="small"
              value={
                !!state.showNotesValue
                  ? !!state.showNotesValue[1]
                    ? state.showNotesValue[0].length > 60
                      ? state.showNotesValue[0].slice(0, 60) + "..."
                      : state.showNotesValue[0] + "..."
                    : state.showNotesValue[0]
                  : "-"
              }
              // error={entry.errors.review_notes.trim()}
              helperText={" "}
            />
          </Tooltip>
        )}

        {/* <div>
          <TextField
            multiline
            className={classes.textArea}
            rows={2}
            maxRows={10}
            name="add_review_notes"
            label="Add New Review Note"
            variant="outlined"
            size="small"
            //value={entry.review_notes}
            onChange={handleFieldChange}
            error={entry.errors.review_notes.trim()}
            helperText={entry.errors.review_notes}
          />
        </div> */}
        <div className="d-flex">
          <Autocomplete
            fullWidth
            size="small"
            freeSolo
            options={reviewNotesList}
            getOptionLabel={(option) => option.value}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.textArea}
                multiline
                rows={2}
                name="add_review_notes"
                maxRows={10}
                fullWidth
                error={!!entry.errors.add_review_notes.trim()}
                helperText={entry.errors.add_review_notes}
                variant="outlined"
                label="Add New Review Note"
                onChange={handleFieldChange}
              />
            )}
            onChange={(ev, value) =>
              onFieldChange("add_review_notes", value.value, "misc", "")
            }
          />
          {!isRoleMGR && (
            <Tooltip title="Add To Lookup(Review Notes)" placement="top-end">
              <Button
                type="submit"
                onClick={() => handleShowAddLookupDialog("reviewNotes")}
                variant="contained"
                color="primary"
                disabled={
                  !entry.add_review_notes ||
                  entry.errors?.add_review_notes?.length > 1
                }
                className={classes.buttonStyle}
              >
                <PostAddIcon />
              </Button>
            </Tooltip>
          )}
          <div className="d-flex">
            <Autocomplete
              fullWidth
              size="small"
              defaultValue={entry.change_notes}
              className="ml-4"
              options={aa_mgrNotesList}
              freeSolo
              getOptionLabel={(option) => option.value || option}
              getOptionSelected={(option, value) => option.value === value}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.textArea}
                  multiline
                  rows={2}
                  name="change_notes"
                  maxRows={10}
                  fullWidth
                  error={!!entry.errors.change_notes.trim()}
                  helperText={entry.errors.change_notes}
                  variant="outlined"
                  label="AA/MGR Note"
                  onChange={handleFieldChange}
                />
              )}
              onChange={(ev, value) =>
                onFieldChange("change_notes", value.value, "misc", "")
              }
            />
            {!isRoleMGR && (
              <Tooltip title="Add To Lookup(AA/MGR Notes)" placement="top-end">
                <Button
                  type="submit"
                  onClick={() => handleShowAddLookupDialog("AaMgrNotes")}
                  variant="contained"
                  color="primary"
                  disabled={
                    !entry.change_notes ||
                    entry.errors?.change_notes?.length > 1
                  }
                  className={classes.buttonStyle}
                >
                  <PostAddIcon />
                </Button>
              </Tooltip>
            )}
          </div>
        </div>

        {/* <TextField
          multiline
          className={classes.aaMgrNotes}
          rows={2}
          name="change_notes"
          label="AA/MGR Note"
          variant="outlined"
          size="small"
          value={entry.change_notes}
          onChange={handleFieldChange}
          error={entry.errors.change_notes.trim()}
          helperText={entry.errors.change_notes}
        /> */}
      </div>
    </>
  );
};

export default MiscPanel;
