import makeStyles from "@material-ui/core/styles/makeStyles";
import SigninBackground from "../../../assets/icons/Artwork.svg";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  formWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    backgroundImage: `url(${SigninBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "auto",
    backgroundPosition: "center",
  },
  emptyDiv: {
    [theme.breakpoints.down("sm")]: {
      height: "10%",
    },
  },
  formContainer: {
    [theme.breakpoints.down("sm")]: {
      border: `2px solid ${theme.palette.divider}`,
    },
    minHeight: "250px",
    justifyContent: "space-evenly",
  },
  orgLogo: {
    position: "absolute",
    top: 22,
  },
  progressBtn: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  backToLoginBtn: {
    top: 12,
  },
}));

export default useStyles;
