// TODO: Optimize this
import { createRef, useState } from "react";
import clsx from "clsx";
import { noop } from "shared/constants";
import GridLoader from "./loader";
import useTheme from "@material-ui/core/styles/useTheme";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import TableContainer from "@material-ui/core/TableContainer";
import PaginationActions from "./pagination-actions";
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import NoRecords from "assets/images/norecord.svg";
import NoRecordsDark from "assets/images/no-records-dark.svg";

import useStyles from "./style";
let gridHelpers = {};

const defaultLevelTwoGridState = {
  open: false,
  order: null,
  orderBy: null,
  selectedRows: [],
};

const LevelTwoGrid = ({
  columns = [],
  rows = [],
  childIdentifier = [],
  isLoading = false,
  classes: passedClasses = {},
  rowIndex = null,
  rowEvents = [],
  onReady = noop,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultLevelTwoGridState);
  const columnRefs = [];

  const createSortHandler = (property) => (event) => {
    const isAsc = state.orderBy === property && state.order === "asc";
    setState((prevState) => ({
      ...prevState,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    }));
  };

  onReady({
    toggle: (open = false) =>
      setState((prevState) => ({
        ...prevState,
        open,
      })),
  });

  return (
    <>
      <TableRow
        hover
        className={
          !state.open
            ? clsx(classes.root)
            : clsx(classes.root, classes.customerHeadBorder)
        }
      >
        <TableCell className={classes.tableTwoPadding}>
          {!!rows[childIdentifier[0]] && (
            <IconButton
              aria-label="expand row"
              size="small"
              className="ml-8"
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  open: !prevState.open,
                }))
              }
            >
              {state.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        {columns[0].map((column, columnIndex) => {
          if (column.isHidden) {
            return null;
          }
          let content = (
            <Typography
              className="d-inline-block w-100"
              variant="body2"
              component="span"
              noWrap={column.hasEllipses}
            >
              {rows[column.field] || ""}
            </Typography>
          );
          if (column.render) {
            content = column.render(rows, rowIndex);
          }

          return (
            <TableCell
              className={clsx(
                classes.tableCell,
                classes.tableCellPadding,
                column.className
              )}
              key={`${column.label || "grid-column"}-${columnIndex}${rows.id}`}
            >
              {column.render ? (
                <>{content}</>
              ) : (
                <Tooltip
                  title={rows[column.field] || ""}
                  placement={column.dataTooltipPlacement || "top-start"}
                >
                  {content}
                </Tooltip>
              )}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={Math.max(...columns.map((column) => column.length)) + 1}
          className={
            !state.open
              ? clsx("pl-0 pr-0 pb-0 pt-0")
              : clsx(
                  "pl-0 pr-0 pb-0 pt-0",
                  classes.customerInternalSeparatorBorder
                )
          }
        >
          <Collapse in={state.open} timeout="auto" unmountOnExit>
            <Table>
              <TableHead>
                <TableRow className={clsx(classes.row, passedClasses.header)}>
                  <TableCell></TableCell>
                  {columns[1].map((column, columnIndex) => {
                    columnRefs[columnIndex] = createRef();
                    let content = <span></span>;
                    if (column.renderHeader) {
                      content = column.renderHeader();
                    } else {
                      content = column.canSort ? (
                        <TableSortLabel
                          key={column.id}
                          active={state.orderBy === column.id}
                          direction={
                            state.orderBy === column.id ? state.order : "asc"
                          }
                          onClick={createSortHandler(column.id)}
                        >
                          {column.label}
                          {state.orderBy === column.id ? (
                            <span className={classes.visuallyHidden}>
                              {state.order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      ) : (
                        <Typography
                          style={{ fontWeight: 500 }}
                          variant="body2"
                          noWrap
                        >
                          {column.label}
                        </Typography>
                      );
                    }

                    return (
                      <TableCell
                        key={column.id}
                        align={column.numeric ? "right" : "left"}
                        padding={column.disablePadding ? "none" : "default"}
                        sortDirection={
                          state.orderBy === column.id ? state.order : false
                        }
                        className={
                          !column.label
                            ? clsx(
                                "p-relative",
                                classes.changedValuetableCell,
                                classes.tableHeadCellEmpty,
                                column.headerClassName,
                                classes.tableHeadPadding //OK
                              )
                            : clsx(
                                "p-relative",
                                classes.changedValuetableCell,
                                classes.tableHeadCell,
                                column.headerClassName,
                                classes.tableHeadPadding //OK
                              )
                        }
                        ref={columnRefs[columnIndex]}
                      >
                        <Tooltip
                          title={column.tooltip || column.label}
                          placement={column.tooltipPlacement || "top-start"}
                        >
                          <div className="d-flex f-justify-between">
                            {content}
                            {!column.noResize && (
                              <div
                                className={clsx(
                                  "d-flex f-justify-between f-align-center p-absolute",
                                  classes.resizeIconWrapper
                                )}
                                data-role="resize"
                                onMouseDown={(event) => {
                                  if (
                                    columnRefs[columnIndex] &&
                                    columnRefs[columnIndex].current
                                  ) {
                                    const element =
                                      columnRefs[columnIndex].current;
                                    document.onmouseup = () => {
                                      document.onmousemove = null;
                                    };

                                    document.onmousemove = (() => {
                                      const initialCursorX = event.pageX;
                                      const initialWidth = element.offsetWidth;
                                      return (event) => {
                                        if (initialCursorX) {
                                          const diffX =
                                            event.pageX - initialCursorX;
                                          element.style.minWidth = `${
                                            initialWidth + diffX
                                          }px`;
                                          element.style.maxWidth = `${
                                            initialWidth + diffX
                                          }px`;
                                        }
                                      };
                                    })();
                                  }
                                }}
                                onMouseUp={() => {
                                  console.log("Fired local");
                                  document.onmousemove = null;
                                }}
                              >
                                <div className={classes.resizeIcon} />
                              </div>
                            )}
                          </div>
                        </Tooltip>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {!!rows.changed_columns &&
                  Object.entries(rows[childIdentifier[0]]).map(
                    ([fieldName, fieldValue]) => {
                      const {
                        previous_value: previousValue,
                        new_value: newValue,
                      } = fieldValue;
                      return (
                        <TableRow
                          hover
                          key={fieldName}
                          className={clsx(classes.row, passedClasses.row)}
                        >
                          <TableCell></TableCell>
                          <TableCell
                            className={clsx(
                              classes.tableCellLevelTwo,
                              classes.tablePadding
                            )}
                          >
                            <Tooltip title={fieldName} placement="top-start">
                              <Typography
                                style={{ fontWeight: 400 }}
                                variant="body2"
                                noWrap
                              >
                                {fieldName}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            className={clsx(
                              classes.tableCellLevelTwo,
                              classes.tablePadding
                            )}
                          >
                            <Tooltip
                              title={previousValue}
                              placement="top-start"
                            >
                              <Typography
                                style={{ fontWeight: 400 }}
                                variant="body2"
                                noWrap
                              >
                                {previousValue || ""}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            className={clsx(
                              classes.tableCellLevelTwo,
                              classes.tablePadding
                            )}
                          >
                            <Tooltip title={newValue} placement="top-start">
                              <Typography
                                style={{ fontWeight: 400 }}
                                variant="body2"
                                noWrap
                              >
                                {newValue || ""}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const defaultGridState = {
  open: false,
  order: null,
  orderBy: null,
  selectedRows: [],
};

const CollapsibleGrid = ({
  columns = [],
  rows = [],
  childIdentifier = [],
  isLoading = false,
  classes: passedClasses = {},
  hasPagination = true,
  paginationActions = [],
  totalRows = 0,
  pageNumber = 1,
  pageSize = 5,
  rowEvents = [],
  onPageSizeChange = noop,
  onPageNumberChange = noop,
  onReady = noop,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [state, setState] = useState(defaultGridState);

  const createSortHandler = (property) => (event) => {
    const isAsc = state.orderBy === property && state.order === "asc";
    setState((prevState) => ({
      ...prevState,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    }));
  };

  const descendingComparator = (a, b, orderBy) => {
    const splittedOrderBy = (orderBy || "").split(".");
    [...Array(splittedOrderBy.length)].map((item, index) => {
      a = a && a[splittedOrderBy[index]];
      b = b && b[splittedOrderBy[index]];
    });
    if (b < a) {
      return -1;
    }
    if (b > a) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (rows, comparator) => {
    const stablized = rows.map((el, index) => [el, index]);
    stablized.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stablized.map((el) => el[0]);
  };

  if (!isLoading && rows.length === 0) {
    return (
      <div className="d-flex f-justify-center">
        <img
          className={classes.noRecordsImg}
          alt="No Records"
          src={
            theme && theme.palette.type === "dark" ? NoRecordsDark : NoRecords
          }
        />
      </div>
    );
  }

  const handlePageChange = (event, pageNumber) => {
    event.stopPropagation();
    onPageNumberChange(pageNumber + 1);
  };

  const handlePageSizeChange = (event) => {
    onPageSizeChange(event.target.value);
  };

  onReady({
    toggle: (open = false, key = "", branchId) =>
      gridHelpers[key] && gridHelpers[key].toggle(open, branchId),
    toggleAll: (open = false) =>
      Object.keys(gridHelpers).map((key) => {
        gridHelpers[key] && gridHelpers[key].toggle(open);
      }),
  });

  if (isLoading) {
    return <GridLoader pageSize={pageSize} columns={columns[0].length} />;
  }

  return (
    <>
      <TableContainer
        className={clsx({ [classes.container]: rows.length > 0 })}
      >
        <Table>
          <TableHead>
            <TableRow className={clsx(classes.row, passedClasses.header)}>
              <TableCell></TableCell>
              {columns[0].map((column) => {
                let content = <span></span>;
                if (column.renderHeader) {
                  content = column.renderHeader();
                } else {
                  content = column.canSort ? (
                    <TableSortLabel
                      key={column.id}
                      active={state.orderBy === column.id}
                      direction={
                        state.orderBy === column.id ? state.order : "asc"
                      }
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                      {state.orderBy === column.id ? (
                        <span className={classes.visuallyHidden}>
                          {state.order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    <span>{column.label}</span>
                  );
                }

                return (
                  <TableCell
                    key={column.id}
                    align={column.numeric ? "right" : "left"}
                    padding={column.disablePadding ? "none" : "default"}
                    sortDirection={
                      state.orderBy === column.id ? state.order : false
                    }
                    className={clsx(
                      classes.tableCell,
                      column.headerClassName,
                      classes.tableHeadPadding
                    )}
                  >
                    <Tooltip
                      title={column.tooltip || column.label}
                      placement={column.tooltipPlacement || "top-start"}
                    >
                      {content}
                    </Tooltip>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(rows, getComparator(state.order, state.orderBy)).map(
              (row, rowIndex) => (
                <LevelTwoGrid
                  rows={row}
                  columns={columns}
                  childIdentifier={childIdentifier}
                  osLoading={isLoading}
                  rowIndex={rowIndex}
                  isLoading={isLoading}
                  rowEvents={rowEvents}
                  onReady={(gridHelper) => (gridHelpers[row.name] = gridHelper)}
                />
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {hasPagination && !!rows.length && (
        <div className="d-flex f-align-center f-justify-between">
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={totalRows}
            rowsPerPage={pageSize}
            colSpan={3}
            page={pageNumber - 1}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePageSizeChange}
            ActionsComponent={PaginationActions}
          />
          {paginationActions.map((action) => action)}
        </div>
      )}
    </>
  );
};

export default CollapsibleGrid;
