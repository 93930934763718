import { stringify } from "qs";
const escapeRegexCharacacters = (str) =>
  str
    .replace(/\\/g, "\\\\")
    .replace(/\*/g, "\\*")
    .replace(/\?/g, "\\?")
    .replace(/\(/g, "\\(")
    .replace(/\)/g, "\\)")
    .replace(/\[/g, "\\[")
    .replace(/\]/g, "\\]")
    .replace(/\^/g, "\\^")
    .replace(/\$/g, "\\$")
    .replace(/\|/g, "\\|")
    .replace(/\./g, "\\.")
    .replace(/\+/g, "\\+");

const escapeRegexChar = (str) =>
  str
    .replace(/\\/g, "\\\\")
    .replace(/\*/g, "\\*")
    .replace(/\?/g, "\\?")
    .replace(/\(/g, "\\(")
    .replace(/\)/g, "\\)")
    .replace(/\[/g, "\\[")
    .replace(/\]/g, "\\]")
    .replace(/\^/g, "\\^")
    .replace(/\$/g, "\\$")
    .replace(/\|/g, "\\|")
    .replace(/\./g, "\\.")
    .replace(/\+/g, "\\+")
    .replace(/%/g, "\\{{percent}}");

const getFilter = (filterArr = []) => {
  let mappedData = {
    filter: {
      where: {
        and: [],
        or: [],
      },
    },
  };

  const reducedFilters = filterArr.reduce((reducedValue, current) => {
    const { type: operator, value } = current;
    if (reducedValue[current.field]) {
      if (current.subFields) {
        current.subFields.forEach((subField) =>
          reducedValue[current.field].push({
            operator,
            value,
            field: `$${current.field}.${subField}$`,
          })
        );
      } else {
        reducedValue[current.field].push({ operator, value });
      }
    } else {
      if (current.subFields) {
        reducedValue[current.field] = [];
        current.subFields.forEach((subField) =>
          reducedValue[current.field].push({
            operator,
            value,
            field: `$${current.field}.${subField}$`,
          })
        );
      } else if (Array.isArray(value)) {
        reducedValue[current.field] = [];
        value.forEach((item) => {
          reducedValue[current.field].push({ operator, value: item });
        });
      } else {
        reducedValue[current.field] = [{ operator, value }];
      }
    }
    return reducedValue;
  }, {});
  Object.keys(reducedFilters).forEach((filterField) => {
    let fieldData = { or: [] };
    reducedFilters[filterField].forEach((element) => {
      let mappedFieldData = null;
      if (element.operator === "=") {
        mappedFieldData = { [filterField]: element.value };
      }

      if (element.operator === "in") {
        mappedFieldData = { [filterField]: { in: [...element.value] } };
      }
      if (element.operator === "contains") {
        mappedFieldData = { [filterField]: { contains: [element.value] } };
      }
      if (element.operator === "notContains") {
        mappedFieldData = {
          not: { [filterField]: { contains: [element.value] } },
        };
      }
      if (element.operator === "iRegexp") {
        mappedFieldData = {
          [filterField]: { [element.operator]: element.value },
        };
      }

      if (mappedFieldData === null) {
        mappedFieldData = {
          [element.field ? element.field : filterField]: {
            [element.operator]: element.value,
          },
        };
      }
      if (
        Object.keys(mappedFieldData).some((key) =>
          filterArr.some(
            (item) => item.notIncludeInBuilder && item.field === key
          )
        )
      ) {
        fieldData = {
          ...fieldData,
          ...mappedFieldData,
        };
      } else {
        fieldData.or.push(mappedFieldData);
      }
    });

    if (
      Object.keys(fieldData).some((key) =>
        filterArr.some((item) => item.notIncludeInBuilder && item.field === key)
      )
    ) {
      mappedData.filter.where = {
        ...mappedData.filter.where,
        ...fieldData,
      };
    } else {
      mappedData.filter.where.and.push(fieldData);
    }
  });

  return mappedData;
};

export const queryStringBuilderNew = (
  limit = 0,
  offset = 0,
  searchArr = [],
  filterArr = [],
  sortObj = null,
  hasSearchValue = false
) => {
  // const isInConjunction = hasSearchValue && filterArr.length > 0;
  const isInConjunction = hasSearchValue;
  let queryString = "";

  // Add is operator with null value for neq and notILike
  filterArr
    .reduce((acc, filter) => {
      filterArr.filter(
        (item) =>
          item.field === filter.field &&
          item.type === filter.type &&
          ["neq", "notILike", "notIRegexp"].includes(filter.type) &&
          !item.notIncludeInBuilder
      ).length === 1 && acc.push(filter);
      return acc;
    }, [])
    .map((item) => filterArr.push({ ...item, type: "is", value: "null" }));
  //convert array into string for multiple type filter
  filterArr = filterArr.map((filter) => {
    let value =
      Array.isArray(filter.value) && filter.notIncludeInBuilder
        ? filter.value.join(",")
        : filter.value;
    value =
      typeof value === "string" ? value?.replaceAll("&", "{{amp}}") : value;
    return {
      ...filter,
      value,
    };
  });

  searchArr.forEach((element, index) => {
    if (element.value) {
      element.value =
        typeof element.value === "string"
          ? element.value?.replaceAll("&", "{{amp}}")
          : element.value;
      switch (element.type) {
        case "=":
          queryString += `&filter[where]${
            isInConjunction ? "[and][0]" : ""
          }[or][${index}][${element.field}]=${encodeURIComponent(
            `${element.value}`
          )}`;
          break;
        default:
          queryString += `&filter[where]${
            isInConjunction ? "[and][0]" : ""
          }[or][${index}][${element.field}][iRegexp]=${encodeURIComponent(
            escapeRegexCharacacters(element.value)
          )}`;
          break;
      }
    }
  });

  let filterObj = getFilter(filterArr);
  // merging search and filter array
  if (hasSearchValue) {
    filterObj.filter.where.and.unshift(undefined);
  }
  // const filters = stringify(filterObj, { encode: true });

  const filters = stringify(filterObj, {
    encode: true,
    filter: (key, value) => {
      if (
        (key.includes("iRegexp") || key.includes("notIRegexp")) &&
        typeof value === "string"
      ) {
        return key.includes("route_bill_discount") ||
          key.includes("route_pay_discount")
          ? escapeRegexChar(value)
          : escapeRegexCharacacters(value);
      }
      return value;
    },
  });

  queryString += filters ? `&${filters}` : "";

  if (limit > 0) {
    queryString += `&filter[limit]=${limit}`;
  }

  if (offset > 0) {
    queryString += `&filter[offset]=${offset}`;
  }

  if (sortObj && sortObj.order && sortObj.field) {
    queryString += `&filter[order]=${sortObj.field}%20${sortObj.order}`;
  }

  if (queryString.length > 0) {
    queryString = queryString.replace("&", "?");
  }
  return queryString;
};

const queryStringBuilder = (
  limit = 0,
  offset = 0,
  searchArr = [],
  filterArr = [],
  sortObj = null,
  hasSearchValue = false
) => {
  let queryString = "";
  const isInConjunction = hasSearchValue && filterArr.length > 0;

  searchArr.forEach((element, index) => {
    if (element.value) {
      switch (element.type) {
        case "=":
          queryString += `&filter[where]${
            isInConjunction ? "[and][0]" : ""
          }[or][${index}][${element.field}]=${encodeURIComponent(
            `${element.value}`
          )}`;
          break;
        default:
          queryString += `&filter[where]${
            isInConjunction ? "[and][0]" : ""
          }[or][${index}][${element.field}][iRegexp]=${encodeURIComponent(
            escapeRegexChar(element.value)
          )}`;
          break;
      }
    }
  });

  if (limit > 0) {
    queryString += `&filter[limit]=${limit}`;
  }

  if (offset > 0) {
    queryString += `&filter[offset]=${offset}`;
  }

  filterArr.forEach((element, index) => {
    if (!element) return;
    switch (element.type) {
      case "regexp":
        queryString += `&filter[where][${
          element.field
        }][iRegexp]=${encodeURIComponent(
          escapeRegexCharacacters(element.value)
        )}`;
        break;
      case "include":
        queryString += `&filter[include][${element.field}]=${encodeURIComponent(
          escapeRegexCharacacters(element.value)
        )}`;
        break;
      case "or":
        queryString += `&filter[where]${
          isInConjunction ? "[and][1]" : ""
        }[or][${index}][${element.field}]=${encodeURIComponent(
          `${element.value}`
        )}`;
        break;
      case "in":
        element.value.forEach((value, valueIndex) => {
          queryString += `&filter[where][${
            element.field
          }][in][${valueIndex}]=${encodeURIComponent(`${value}`)}`;
        });
        break;
      case "gte":
        queryString += `&filter[where]${isInConjunction ? "[and][1]" : ""}[${
          element.field
        }][gte]=${encodeURIComponent(`${element.value}`)}`;
        break;
      case "lte":
        queryString +=
          `&filter[where][${element.field}][lte]=` +
          encodeURIComponent(`${element.value}`);
        break;
      case "between":
        element.value.forEach((value, valueIndex) => {
          queryString += `&filter[where][${
            element.field
          }][between][${valueIndex}]=${encodeURIComponent(`${value}`)}`;
        });
        break;
      case "not":
        queryString += `&filter[where]${
          isInConjunction ? "[and][1]" : ""
        }[not][${index}][${element.field}]=${encodeURIComponent(
          `${element.value}`
        )}`;
        break;
      case "iLike":
        queryString += `&filter[where]${isInConjunction ? "[and][1]" : ""}[${
          element.field
        }]=${encodeURIComponent(`${element.value}`)}`;
        break;
      case "notILike":
        queryString += `&filter[where]${isInConjunction ? "[and][1]" : ""}[${
          element.field
        }][neq]=${encodeURIComponent(`${element.value}`)}`;
        break;
      case "=":
      default:
        queryString += `&filter[where][${element.field}]=${encodeURIComponent(
          element.value
        )}`;
        break;
    }
  });

  if (sortObj && sortObj.order && sortObj.field) {
    queryString += `&filter[order]=${sortObj.field}%20${sortObj.order}`;
  }

  if (queryString.length > 0) {
    queryString = queryString.replace("&", "?");
  }
  return queryString;
};

export default queryStringBuilder;
