import { http, responseFormatter } from "utils";

const get = (query) => {
  return responseFormatter(
    http.get(`/report/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getMonthlyReport = (query) => {
  return responseFormatter(
    http.get(`/reports/customers/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};
const getKamEfficiencyReport = (query) => {
  return responseFormatter(
    http.get(`/reports/kamEfficiency${query || ""}`, {
      setAuthHeader: true,
    })
  );
};
const getIcReport = (query) => {
  return responseFormatter(
    http.get(`/reports/contractors/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};
const getKamReport = (query) => {
  return responseFormatter(
    http.get(`/reports/kams/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getOnDemandReport = (query) => {
  return responseFormatter(
    http.get(`/reports/onDemand/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};
const getpdxCompanyReport = (query) => {
  return responseFormatter(
    http.get(`/reports/pdx-company${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getFilterData = () => {
  return responseFormatter(
    http.get("/dropdown-data", {
      setAuthHeader: true,
    })
  );
};
const ReportService = {
  get,
  getFilterData,
  getMonthlyReport,
  getKamEfficiencyReport,
  getIcReport,
  getKamReport,
  getpdxCompanyReport,
  getOnDemandReport,
};

export default ReportService;
