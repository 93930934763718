import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  LineChartHeadrer: { width: "100%", overflow: "auto" },
  xAxisLabel: { wordWrap: "break-word", textAlign: "center" },
  // LineChartHeadrer: {
  //   width: "100%",
  //   overflow: "auto",
  //   "&::-webkit-scrollbar": {
  //     width: "5px",
  //     borderRadius: "10px",
  //   },
  //   "&::-webkit-scrollbar-track": {
  //     background: "rgb(241 241 241)",
  //   },
  //   "&::-webkit-scrollbar-thumb": {
  //     backgroundColor: "#c1c1c1",
  //   },
  // },
}));

export default useStyles;
