import { lighten, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: 30,
  },
  row: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tableHeadPadding: {
    paddingTop: "8px !important",
    paddingBottom: "8px !important",
  },
  tablePadding: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    width: "100%",
  },
  boxborder: {
    boxShadow: "inset 0px 0px 4px 2px #00008B, inset 0px 0px 4px 2px #00008B",
  },
  borderCollapse: {
    borderCollapse: "collapse",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableCell: {
    minWidth: 72,
    maxWidth: 200,
  },
  tableHeadCell: {
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.light, 0.9),
      "& div[data-role='resize']": {
        visibility: "visible",
      },
    },
  },
  noRecordsImg: {
    maxWidth: 600,
    maxHeight: 500,
  },
  resizeIconWrapper: {
    width: 2,
    cursor: "col-resize",
    userSelect: "none",
    visibility: "hidden",
    right: 0,
    top: 0,
    bottom: 0,
  },
  resizeIcon: {
    background: lighten(theme.palette.primary.light, 0.9),
    opacity: 0.6,
    width: 2,
    height: 15,
  },
}));

export default useStyles;
