import * as XLSX from "xlsx";

const getFileExtension = (name) => {
  const lastDot = name.lastIndexOf(".");
  const extension = name.substring(lastDot + 1);
  return extension;
};

export { getFileExtension };

export const writeXLSFile = async (
  fileName = "File",
  sheetName = "Sheet",
  data = []
) => {
  return new Promise((resolve) => {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);
    XLSX.writeFile(workBook, `${fileName}.xlsx`);
    resolve(true);
  });
};
