import { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import { ActionDialog, Datepicker, Dialog, Grid } from "shared/components";
import ErrorModal from "../error-modal";
import { noop, VALIDATIONS } from "shared/constants";
import {
  getDateString,
  getUTCDateString,
  preventInputKeyCodes,
  validator,
  updateLayout,
} from "utils";
import Service from "../service";
import SharedService from "modules/shared/service";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import useStyles from "./style";
import { TextField } from "@material-ui/core";

const defaultState = {
  open: false,
  isFetching: false,
  isSaving: false,
  entries: [],
  totalEntries: 0,
  sheetError: null,
  opneConfirm: false,
  dateRange: {
    from_date: null,
    to_date: null,
  },
};

const GasPrice = ({
  disabled = false,
  stateList = [],
  fuelPriceDateRange = {},
  onSave = noop,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);

  const fetchEntries = useCallback(
    async (hasLoader = true) => {
      setState((prevState) => ({
        ...prevState,
        isFetching: hasLoader,
      }));
      const { data, error } = await SharedService.getAAAPriceList();
      if (error) {
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0].message : error.message
        );
      }

      setState((prevState) => ({
        ...prevState,
        isFetching: false,
        entries: (data || defaultState.entries)
          .sort((a, b) => Intl.Collator().compare(a.State || "", b.State || ""))
          .map((item, index) => ({
            ...item,
            id: index,
            abbreviation:
              (stateList.find(({ name }) => name === item.State) || {})
                .abbreviation || "",
            errors: {
              Regular: "",
              Diesel: "",
            },
            Regular: (
              Math.round(
                (+(item.Regular || "").replaceAll("$", "") + Number.EPSILON) *
                  100
              ) / 100
            ).toFixed(2),
            Diesel: (
              Math.round(
                (+(item.Diesel || "").replaceAll("$", "") + Number.EPSILON) *
                  100
              ) / 100
            ).toFixed(2),
          })),
        totalEntries: data.count,
      }));
      return data;
    },
    [stateList]
  );

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = {
      Regular: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.LIMIT, value: 10 },
      ],
      Diesel: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.LIMIT, value: 10 },
      ],
    };

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string",
          validation.customMessage
        )
      );

      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      state.entries.map((entry) => {
        Object.keys(fieldValidatorMap).forEach((key) => {
          const message = validate(key, entry[key]);
          if (!!message) {
            errorMessage = message;
          }
        });
      });
    }

    return errorMessage;
  };

  const handleInlineEdit = (evt, row) => {
    setState((prevState) => {
      let { name, type, value } = evt.currentTarget || evt.target;
      const errorMessage = validate(name, value) || "";
      if (type === "number" && value.indexOf(".") !== -1) {
        if (value.toString().split(".")[1]?.length > 2) {
          value = (Math.round((+value + Number.EPSILON) * 100) / 100).toFixed(
            2
          );
        }
      }
      if (type === "number" && value?.length > 1 && value.indexOf(".") === -1) {
        value = value?.replace(/^0+/, "");
      }
      const entries = prevState.entries.map((entry) => ({
        ...entry,
        [name]: entry.id === row.id ? value : entry[name],
        errors: {
          ...entry.errors,
          [name]: entry.id === row.id ? errorMessage : entry.errors[name],
        },
      }));
      return {
        ...prevState,
        entries,
      };
    });
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const handleSave = async (gasPriceList = [], dateRange = {}) => {
    setState((prevState) => ({
      ...prevState,
      isSaving: true,
    }));
    const payload = {
      ...dateRange,
      fuel_prices: gasPriceList.map((item) => ({
        state: item.State,
        regular_price: item.Regular,
        diesel_price: item.Diesel,
      })),
    };
    const { error } = await Service.saveAAAPrices(payload);
    setState((prevState) => ({
      ...prevState,
      isSaving: false,
      opneConfirm: false,
    }));
    if (error) {
      if (Array.isArray(error)) {
        let errorKams = {};
        let errorMessage = error.reduce((acc, val) => {
          (val?.errors || []).map((error) => {
            const kamNames = error.kamNameArray || [];
            if (Object.keys(acc).some((key) => key === error.message)) {
              (acc[error.message] || []).push(val.row);
              (errorKams[error.message] || []).push(kamNames);
            } else {
              acc = { ...acc, [error.message]: [val.row] };
              errorKams = { ...errorKams, [error.message]: [kamNames] };
            }
          });
          return acc;
        }, []);
        errorMessage = Object.keys(errorMessage).reduce((acc, key) => {
          const kamNames = (errorKams[key] || []).flat().join(", ");
          return {
            ...acc,
            [`${key}${kamNames}`]: errorMessage[key],
          };
        }, {});

        setState((prevState) => ({ ...prevState, sheetError: errorMessage }));
      } else {
        return toast.error(error.message);
      }
    } else {
      toast.success("AAA Gas Prices successfully saved.");
      handleClose();
      onSave(state.dateRange);
    }
  };

  const columnConfig = [
    {
      id: "State",
      label: "State",
      field: "State",
      hasEllipses: true,
      render: (row) => {
        const title = `${row.State} (${row.abbreviation})`;
        return (
          <Tooltip title={title} placement="top-start">
            <Typography variant="body2">{title}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "Regular",
      label: "Regular Price",
      field: "Regular",
      hasEllipses: true,
      render: (row) => (
        <TextField
          size="small"
          variant="outlined"
          label="Regular Price"
          name="Regular"
          type="number"
          value={row.Regular}
          onKeyDown={preventInputKeyCodes}
          onWheel={(event) => event.target.blur()}
          onChange={(evt) => handleInlineEdit(evt, row)}
          error={!!(row.errors.Regular || "").trim()}
          helperText={row.errors.Regular}
          InputProps={{
            startAdornment: <InputAdornment>$</InputAdornment>,
          }}
        />
      ),
    },
    {
      id: "Diesel",
      label: "Diesel Price",
      field: "Diesel",
      hasEllipses: true,
      render: (row) => (
        <TextField
          size="small"
          variant="outlined"
          label="Diesel Price"
          name="Diesel"
          type="number"
          value={row.Diesel}
          onWheel={(event) => event.target.blur()}
          onKeyDown={preventInputKeyCodes}
          onChange={(evt) => handleInlineEdit(evt, row)}
          error={!!(row.errors.Diesel || "").trim()}
          helperText={row.errors.Diesel}
          InputProps={{
            startAdornment: <InputAdornment>$</InputAdornment>,
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    if (state.open) {
      fetchEntries();
      setState((prevState) => ({
        ...prevState,
        dateRange: {
          from_date: getUTCDateString(fuelPriceDateRange.from_date),
          to_date: getUTCDateString(fuelPriceDateRange.to_date),
        },
      }));
    }
  }, [state.open, fuelPriceDateRange]);

  useEffect(() => {
    setTimeout(updateLayout, 2000);
  }, []);

  return (
    <div id="numbers-page-wrapper">
      <>
        <Button
          variant="contained"
          startIcon={<ArrowDownwardIcon />}
          disabled={disabled}
          color="primary"
          className={"mr-2"}
          onClick={() =>
            setState((prevState) => ({ ...prevState, open: true }))
          }
        >
          Import from AAA
        </Button>
        <Dialog
          open={state.open}
          onClose={handleClose}
          classes={{ paper: classes.paper }}
        >
          <Dialog.Title hasClose>
            AAA Gas Prices
            <Typography variant="body2">
              Edit and Save the Gas Prices for the selected date range.
            </Typography>
          </Dialog.Title>
          <Dialog.Content>
            <Box className="d-flex f-align-center mb-8">
              <Datepicker
                placement="bottom-start"
                preventClear
                mask
                maxDate={new Date(state.dateRange.to_date)}
                selected={state.dateRange.from_date}
                label="From date"
                classes={{
                  input: {
                    root: classes.datepickerWrapper,
                  },
                }}
                onChange={(fromDate) => {
                  if (!fromDate) return;
                  setState((prevState) => ({
                    ...prevState,
                    dateRange: {
                      ...prevState.dateRange,
                      from_date: getDateString(fromDate),
                    },
                  }));
                }}
              />
              <Datepicker
                preventClear
                mask
                minDate={new Date(state.dateRange.from_date)}
                selected={state.dateRange.to_date}
                label="To date"
                className="ml-4"
                classes={{
                  input: {
                    root: classes.datepickerWrapper,
                  },
                }}
                onChange={(toDate) => {
                  if (!toDate) return;
                  setState((prevState) => ({
                    ...prevState,
                    dateRange: {
                      ...prevState.dateRange,
                      to_date: getDateString(toDate),
                    },
                  }));
                }}
              />
            </Box>
            <Grid
              actionBarConfig={null}
              columns={columnConfig}
              rows={state.entries}
              totalRows={state.totalEntries}
              isLoading={state.isFetching}
              hasSelection={false}
              hasPagination={false}
              classes={{
                gridActions: "f-justify-end",
                container: classes.gridContainer,
              }}
            />
          </Dialog.Content>
          <Dialog.Actions>
            <Box className="p-4">
              <Button
                variant="outlined"
                className="ml-2 mr-2"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  state.isFetching ||
                  state.isSaving ||
                  !(state.dateRange.from_date && state.dateRange.to_date) ||
                  validate()
                }
                className="ml-2 mr-2"
                onClick={() =>
                  setState((prevState) => ({ ...prevState, opneConfirm: true }))
                }
              >
                Save
                {state.isSaving && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </Button>
            </Box>
          </Dialog.Actions>
        </Dialog>
        {!!state.sheetError && (
          <ErrorModal
            heading="Error while saving"
            description="Following error(s) were encountered while saving data"
            open={!!state.sheetError}
            error={state.sheetError}
            onClose={() =>
              setState((prevState) => ({
                ...prevState,
                sheetError: null,
              }))
            }
          />
        )}
        {state.opneConfirm && (
          <ActionDialog
            classes={{
              paper: classes.confirmDialogPaper,
              confirm: clsx({ "bg-primary": !state.isSaving }),
            }}
            open={state.opneConfirm}
            contentText="Are you sure you want to save the Gas prices for all states?"
            onConfirm={() => handleSave(state.entries, state.dateRange)}
            onCancel={() =>
              setState((prevState) => ({ ...prevState, opneConfirm: false }))
            }
            isConfirmDisabled={state.isSaving}
            positiveActionLabel={
              <>
                Confirm
                {state.isSaving && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}
      </>
    </div>
  );
};

export default GasPrice;
