import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  Fragment,
} from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import { STATUS } from "modules/shared/constants";
import FlagIcon from "@material-ui/icons/Flag";
import { useLocation } from "react-router-dom";
import {
  ActionDialog,
  Grid,
  Dropdown,
  FilterComponent,
  Dialog,
} from "shared/components";
import { PAGE_KEYS, isTransferScheduleEnable } from "shared/constants";
import { ROLE } from "modules/shared/constants";
import FilterListIcon from "@material-ui/icons/FilterList";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import {
  getDateString,
  queryStringBuilderNew,
  getPageConfig,
  setPageConfig,
  getTokenData,
  updatePagination,
  updateLayout,
  updatePageConfig,
  exportBase64ToFile,
} from "utils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { AppContext } from "shared/contexts";
import { ERROR_CODES } from "modules/shared/constants";
import Chip from "@material-ui/core/Chip";
import Service from "../service";
import ForceSubmitDialog from "../../daily-schedule/forceSubmit";
import Form from "../form";
import SharedService from "modules/shared/service";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import SearchIcon from "@material-ui/icons/Search";
import TuneIcon from "@material-ui/icons/Tune";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import useStyles from "./style";
import Maps from "./../Maps";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { Skeleton } from "@material-ui/lab";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { ButtonGroup } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import IcForm from "modules/shared/components/ic-form";

let fetchController;
let newEntryCreated = [];
let gridHelper = null;
let timeout = null;
const defaultState = {
  entries: [],
  totalEntries: 0,
  isDownloadLoading: false,
  uncheckWatchlistFlag: false,
  isFetching: false,
  agreementStatusList: [],
  documentStatusList: [],
  fileRetentionStatusList: [],
  isFetchingList: {
    settlementCompany: false,
    documentStatus: false,
    watchlistStatus: false,
    agreementStatus: false,
    pdxCompany: false,
    customers: false,
    users: false,
  },
  selectedRows: [],
  pageSize: 100,
  pageNumber: 1,
  filters: {
    userStatus: [],
    locations: [],
  },
  selectedLocation: [],
  order: null,
  orderBy: null,
  search: "",
  isNew: false,
  openForceSubmitDialog: false,
  newEntry: [],
  rowBeingEdited: null,
  settlementCompanyList: [],
  contractorTypeList: [],
  vehicleTypeList: [],
  editLocation: [],
  deletingAddressIds: null,
  openAddressDialog: false,
  deleteAddress: false,
  addressRowBeingEdited: null,
  addressAdded: true,
  isAddressListLoading: false,
  allLocation: [],
  icid: "",
  pdxCompanyList: [],
  markActiveInactiveIds: null,
  markFlagUnflagIds: null,
  markCustionFlag: null,
  flagSubmitReasonList: [],
  cautionFlagSubmitReasonList: [],
  flaggedType: null,
  flagSubmitReason: null,
  isReasonOptionOpen: false,
  uncheckRedFlag: false,
  removeRedFlagToNone: false,
  confirmInactive: false,
  selectFlagReason: false,
  flagReason: null,
  kamList: [],
  defaultKamList: [],
  updateFlagReason: false,
  ic_id: null,
  updateFlagReasonValue: null,
  statusUser: null,
  pageFilters: [],
  isFiltering: false,
  stateList: [],
  locationList: [],
  dynamicStatus: {
    all: { label: "All", value: "all", isSelected: true },
    only_active: {
      label: "Only Active",
      value: "is_active",
      isSelected: true,
    },
    only_inActive: {
      label: "Only Inactive",
      value: "is_active",
      isSelected: true,
    },
    only_flagged: {
      label: "Only Flagged",
      value: "is_flagged",
      isSelected: true,
    },
    only_unFlagged: {
      label: "Only Unflagged",
      value: "is_flagged",
      isSelected: true,
    },
    only_cautious_flagged: {
      label: "Only Caution Flagged",
      value: "is_cautious_flagged",
      isSelected: true,
    },
    only_cautious_unFlagged: {
      label: "Only Caution Unflagged",
      value: "is_cautious_flagged",
      isSelected: true,
    },
  },
  dynamicColumns: {
    select_all: { label: "Select All", value: "select_all", isSelected: true },
    flag: { label: "Flag", value: "flag", isSelected: true },
    icid: { label: "ICID", value: "icid", isSelected: true },
    last_name: { label: "Last Name", value: "last_name", isSelected: true },
    first_name: { label: "First Name", value: "first_name", isSelected: true },
    ic_company_name: {
      label: "IC Company Name",
      value: "ic_company_name",
      isSelected: true,
    },
    state: { label: "State", value: "state", isSelected: true },
    city: { label: "City", value: "city", isSelected: true },
    phone: { label: "Phone", value: "phone", isSelected: true },
    email: { label: "Email", value: "email", isSelected: true },
    contractor_type: {
      label: "Contractor Type",
      value: "contractor_type",
      isSelected: true,
    },
    pdx_company: {
      label: "PDX Company",
      value: "pdx_company",
      isSelected: true,
    },
    default_vehicle_type: {
      label: "Default Vehicle Type",
      value: "default_vehicle_type",
      isSelected: true,
    },
    stand_by_rate_type: {
      label: "Stand-by Rate Type",
      value: "stand_by_rate_type",
      isSelected: true,
    },
    stand_by_rate: {
      label: "Stand-by Rate",
      value: "stand_by_rate",
      isSelected: true,
    },
    ein: { label: "EIN", value: "ein", isSelected: true },
    ssn: { label: "SSN", value: "ssn", isSelected: true },
    date_of_birth: {
      label: "Date Of Birth",
      value: "date_of_birth",
      isSelected: true,
    },
    default_customer: {
      label: "Default Customer",
      value: "default_customer",
      isSelected: true,
    },
    default_settlement_company: {
      label: "Default Settlement Company",
      value: "default_settlement_company",
      isSelected: true,
    },
    default_kam: {
      label: "Default KAM",
      value: "default_kam",
      isSelected: true,
    },
    notes: { label: "Notes", value: "notes", isSelected: true },
    flag_Reason: {
      label: "Flagged Reason",
      value: "flag_Reason",
      isSelected: true,
    },
    address: {
      label: "Address",
      value: "address",
      isSelected: true,
    },
  },
  dynamicColumnsKAM: {
    select_all: { label: "Select All", value: "select_all", isSelected: true },

    icid: { label: "ICID", value: "icid", isSelected: true },
    last_name: { label: "Last Name", value: "last_name", isSelected: true },
    first_name: { label: "First Name", value: "first_name", isSelected: true },
    ic_company_name: {
      label: "IC Company Name",
      value: "ic_company_name",
      isSelected: true,
    },
    state: { label: "State", value: "state", isSelected: true },
    city: { label: "City", value: "city", isSelected: true },
    phone: { label: "Phone", value: "phone", isSelected: true },
    email: { label: "Email", value: "email", isSelected: true },
    contractor_type: {
      label: "Contractor Type",
      value: "contractor_type",
      isSelected: true,
    },
    pdx_company: {
      label: "PDX Company",
      value: "pdx_company",
      isSelected: true,
    },
    default_vehicle_type: {
      label: "Default Vehicle Type",
      value: "default_vehicle_type",
      isSelected: true,
    },
    stand_by_rate_type: {
      label: "Stand-by Rate Type",
      value: "stand_by_rate_type",
      isSelected: true,
    },
    stand_by_rate: {
      label: "Stand-by Rate",
      value: "stand_by_rate",
      isSelected: true,
    },
    ein: { label: "EIN", value: "ein", isSelected: true },
    ssn: { label: "SSN", value: "ssn", isSelected: true },
    date_of_birth: {
      label: "Date Of Birth",
      value: "date_of_birth",
      isSelected: true,
    },
    default_customer: {
      label: "Default Customer",
      value: "default_customer",
      isSelected: true,
    },
    default_settlement_company: {
      label: "Default Settlement Company",
      value: "default_settlement_company",
      isSelected: true,
    },
    default_kam: {
      label: "Default KAM",
      value: "default_kam",
      isSelected: true,
    },
    notes: { label: "Notes", value: "notes", isSelected: true },
    flag_Reason: {
      label: "Flagged Reason",
      value: "flag_Reason",
      isSelected: true,
    },
  },
};

const ViewContractor = ({ match, history }) => {
  const pageConfig = getPageConfig(PAGE_KEYS.CONTRACTORS);
  const userData = getTokenData();

  const isRoleKAM = (userData?.role || "").toLowerCase() === ROLE.KAM;
  const isRoleVIEW = (userData?.role || "").toLowerCase() === ROLE.MAPS_VIEW;
  const isRoleMGR = (userData?.role || "").toLowerCase() === ROLE.MGR;
  const classes = useStyles();
  const location = useLocation();
  const data = location.state || {};
  const [state, setState] = useState({
    ...defaultState,
    pageSize: pageConfig?.pageSize || defaultState.pageSize,
    pageNumber: pageConfig?.pageNumber || defaultState.pageNumber,
    selectedLocation:
      (pageConfig && pageConfig?.locationFilter?.value) ||
      defaultState.selectedLocation,
    dynamicColumns: pageConfig?.dynamicColumns || defaultState.dynamicColumns,
    pageFilters:
      (pageConfig && pageConfig?.filters) || defaultState.pageFilters,
    dynamicColumnsKAM:
      pageConfig?.dynamicColumnsKAM || defaultState.dynamicColumnsKAM,
    search: (pageConfig && pageConfig?.search) || defaultState.search,
    dynamicStatus:
      (pageConfig && pageConfig?.dynamicStatus?.value) ||
      defaultState.dynamicStatus,
    // search: !data.icid ? "" : data.icid,
    filters: {
      ...defaultState.filters,
      userStatus: (pageConfig && pageConfig?.dynamicStatus?.userStatus) || [],
      locations: (pageConfig && pageConfig?.locationFilter?.locations) || [],
    },
  });

  const { appData, setHeaderElements, updateContextData } =
    useContext(AppContext);

  const kamId = isRoleKAM ? userData?.id : state.selectedKAM?.id;

  const getSearchArray = (search) => {
    const columns = ["icid", "first_name", "last_name", "ic_company_name"];
    return columns.map((column) => ({
      field: column,
      value: search,
    }));
  };

  const appDataList = useMemo(() => {
    const users = appData.users.reduce(
      (acc, user) => {
        const role = (user.role || "").toLowerCase();
        acc[role] && user?.is_active && acc[role].push(user);
        return acc;
      },
      {
        kam: [],
      }
    );

    const customers = appData.customers.filter(
      (customer) => customer.is_active
    );

    const getOptionList = (users = []) => {
      return users.map((list) => ({
        value: list,
        label:
          list?.name ||
          `${list.first_name || ""} ${list.last_name || ""}- ${
            list.email || ""
          }`,
      }));
    };

    return {
      defaultKamList: users.kam,
      kamUsers: users.kam,
      customers,
      kamUsersOptions: getOptionList(users.kam),
      customersOptions: getOptionList(customers),
    };
  }, [appData]);

  const filterConfig = useMemo(
    () => [
      {
        field: "state",
        fieldToDisplay: "State",
        operatorType: "iLike",
        options: state.stateList.map((state) => ({
          label: state.name,
          value: state.abbreviation,
        })),
      },
      // {
      //   field: "city",
      //   fieldToDisplay: "City",
      //   operatorType: "string",
      //   options: Object.entries(state.locationList)
      //     .map(([state, value]) =>
      //       value.map((item) => ({
      //         label: `${item}-(${state})`,
      //         value: item,
      //       }))
      //     )
      //     .flat()
      //     .sort((a, b) => Intl.Collator().compare(a.label, b.label)),
      // },
      {
        field: "phone",
        fieldToDisplay: "Phone",
        operatorType: "regexp",
      },
      {
        field: "email",
        fieldToDisplay: "Email",
        operatorType: "regexp",
      },
      {
        field: "contractor_type",
        fieldToDisplay: "Contractor Type",
        operatorType: "string",
        options: state.contractorTypeList.map((contractor) => ({
          label: contractor.value,
          value: contractor.value,
        })),
      },
      {
        field: "pdx_company_name",
        fieldToDisplay: "PDX Company",
        operatorType: "string",
        options: state.pdxCompanyList?.map((company) => ({
          label: company.value,
          value: company.value,
        })),
      },
      {
        field: "default_vehicle_type",
        fieldToDisplay: "Default Vehicle Type",
        operatorType: "string",
        options: state.vehicleTypeList?.map((vehicle) => ({
          label: vehicle.value,
          value: vehicle.value,
        })),
      },
      {
        field: "stand_by_rate_type",
        fieldToDisplay: "Stand-by Rate Type",
        operatorType: "string",
        options: [
          { label: "Daily", value: "Daily" },
          { label: "Hourly", value: "Hourly" },
          { label: "Per Miles", value: "Per Miles" },
          { label: "Per Tire", value: "Per Tire" },
          { label: "Per Run", value: "Per Run" },
        ],
      },
      {
        field: "stand_by_rate",
        fieldToDisplay: "Stand-by Rate",
        operatorType: "number",
      },
      {
        field: "ein",
        fieldToDisplay: "EIN",
        operatorType: "regexp",
      },
      {
        field: "ssn",
        fieldToDisplay: "SSN",
        operatorType: "regexp",
      },
      {
        field: "customer_name",
        fieldToDisplay: "Default Customer",
        operatorType: "number",
        options: appDataList.customers.map((customer) => ({
          ...customer,
          label: customer.name,
          value: customer.name,
        })),
      },
      {
        field: "settlement_company_name",
        fieldToDisplay: "Default Settlement Company",
        operatorType: "number",
        options: state.settlementCompanyList.map((company) => ({
          label: company.value,
          value: company.value,
        })),
      },
      {
        field: "kam_name",
        fieldToDisplay: "Default KAM",
        operatorType: "string",
        options: appDataList.kamUsers.map((kam) => ({
          ...kam,
          label: `${kam.first_name} ${kam.last_name}-(${kam.username})  `,
          value: `${kam.first_name} ${kam.last_name}`,
        })),
      },
      {
        field: "notes",
        fieldToDisplay: "Notes",
        operatorType: "regexp",
      },
      {
        field: "flag_reason_value",
        fieldToDisplay: "Flagged Reason",
        operatorType: "string",
        options: state.flagSubmitReasonList.map((flagReasonList) => ({
          ...flagReasonList,
          label: `${flagReasonList.value} `,
          value: flagReasonList.value,
        })),
      },
      {
        field: "flag_reason_value",
        fieldToDisplay: "Caution Flagged Reason",
        operatorType: "string",
        options: state.cautionFlagSubmitReasonList.map((flagReasonList) => ({
          ...flagReasonList,
          label: `${flagReasonList.value} `,
          value: flagReasonList.value,
        })),
      },
    ],
    [
      appDataList.kamUsers,
      state.stateList,
      state.locationList,
      state.settlementCompanyList,
      state.pdxCompanyList,
      state.vehicleTypeList,
      appDataList.customers,
      state.contractorTypeList,
      state.flagSubmitReasonList,
      state.cautionFlagSubmitReasonList,
    ]
  );

  const fetchEntries = useCallback(
    async (
      search,
      filters,
      pageSize,
      pageNumber,
      order,
      orderBy,
      pageFilters = []
    ) => {
      if (fetchController) fetchController.abort(); // Abort any ongoing request
      fetchController = new AbortController();
      const signal = fetchController.signal;

      setState((prevState) => ({
        ...prevState,
        isFetching: true,
      }));
      const searchArray = getSearchArray(search);

      let filtersArr = [
        ...pageFilters.map((filter) => ({
          ...filter,
          value: filter?.value?.value || filter?.value,
        })),
      ];
      if (filters.userStatus.length && filters.userStatus) {
        filtersArr = [...filtersArr, ...filters.userStatus];
      }
      if (filters.locations.length) {
        filtersArr = [...filtersArr, ...filters.locations];
      }

      const queryString = queryStringBuilderNew(
        pageSize,
        pageSize * (pageNumber - 1),
        searchArray,
        filtersArr,
        { field: orderBy, order },
        search && search.length > 0
      );

      try {
        const { data, error } = await Service.get(queryString, { signal });
        if (signal.aborted) {
          return;
        }
        if (error) {
          setState((prevState) => ({
            ...prevState,
            isFetching: false,
          }));
          return toast.error(
            Array.isArray(error) ? error[0].message : error.message
          );
        }
        const timeZoneOffset = new Date().getTimezoneOffset();
        setState((prevState) => {
          const updatedData = (data.contractors || defaultState.entries).map(
            (entry) => {
              let date_of_birth = entry.date_of_birth;
              if (date_of_birth && timeZoneOffset >= 0) {
                const nextDate = new Date(date_of_birth);
                date_of_birth = new Date(
                  nextDate.setDate(nextDate.getDate() + 1)
                );
              }
              return {
                ...entry,
                date_of_birth,
              };
            }
          );

          return {
            ...prevState,
            isFetching: false,
            entries: updatedData,
            totalEntries: data.count || defaultState.totalEntries,
          };
        });
        return data;
      } catch (abortError) {
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
        }));
      }
    },
    []
  );

  const fetchNewEntries = useCallback(async () => {
    setState((prevState) => ({
      ...prevState,
      isFetching: true,
    }));

    const { data, error } = await Service.get();
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetching: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }

    const timeZoneOffset = new Date().getTimezoneOffset();
    setState((prevState) => {
      const updatedData = (data.contractors || defaultState.newEntry).map(
        (entry) => {
          let date_of_birth = entry.date_of_birth;
          if (date_of_birth && timeZoneOffset >= 0) {
            const nextDate = new Date(date_of_birth);
            date_of_birth = new Date(nextDate.setDate(nextDate.getDate() + 1));
          }
          return {
            ...entry,
            date_of_birth,
          };
        }
      );

      updatedData?.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

      const newEntry = updatedData[0];

      if (newEntry) {
        newEntryCreated = newEntry;
      }

      return {
        ...prevState,
        isFetching: false,
        newEntry: updatedData,
      };
    });

    return data;
  }, []);

  const fetchICAgreementStatus = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        agreementStatus: true,
      },
    }));
    const { data, error } = await SharedService.getICAgreementStatus();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          agreementStatus: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      agreementStatusList:
        data?.rows.filter((agreement) => agreement.is_active) ||
        defaultState.agreementStatusList,
      isFetchingList: {
        ...prevState.isFetchingList,
        agreementStatus: false,
      },
    }));
  };

  const fetchICDocumentStatus = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        documentStatus: true,
      },
    }));
    const { data, error } = await SharedService.getICDocumentStatus();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          documentStatus: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      documentStatusList:
        data?.rows.filter((document) => document.is_active) ||
        defaultState.documentStatusList,
      isFetchingList: {
        ...prevState.isFetchingList,
        documentStatus: false,
      },
    }));
  };
  const fetchICWatchlistStatus = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        watchlistStatus: true,
      },
    }));
    const { data, error } = await SharedService.getICWatchlistStatus();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          watchlistStatus: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      fileRetentionStatusList:
        data?.rows.filter((watchlist) => watchlist.is_active) ||
        defaultState.fileRetentionStatusList,
      isFetchingList: {
        ...prevState.isFetchingList,
        watchlistStatus: false,
      },
    }));
  };

  const handleDownload = async (
    search,
    filters,
    order,
    orderBy,
    pageFilters = []
  ) => {
    const searchArray = getSearchArray(search);
    const sortObj = { field: orderBy, order };

    let filtersArr = [
      ...pageFilters.map((filter) => ({
        ...filter,
        value: filter?.value?.value || filter?.value,
      })),
    ];
    if (filters.userStatus.length && filters.userStatus) {
      filtersArr = [...filtersArr, ...filters.userStatus];
    }
    if (filters.locations.length) {
      filtersArr = [...filtersArr, ...filters.locations];
    }

    setState((prevState) => ({
      ...prevState,
      isDownloadLoading: true,
    }));

    let query = queryStringBuilderNew(
      0,
      0,
      searchArray,
      filtersArr,
      sortObj,
      search && search.length > 0
    );

    const { data, error } = await Service.download(query);
    if (error) {
      toast.error(Array.isArray(error) ? error[0].message : error.message);
      setState((prevState) => ({
        ...prevState,
        isDownloadLoading: false,
      }));
    } else {
      toast.success("Independent Contractors records downloaded successfully.");
      exportBase64ToFile(data, "Independent Contractors");
    }

    setState((prevState) => ({
      ...prevState,
      isDownloadLoading: false,
    }));
  };

  const handleFilterChange = useCallback((pageFilters = []) => {
    setState((prevState) => ({
      ...prevState,
      pageFilters: pageFilters.map((filter) => {
        if (filter.field === "phone") {
          filter.value = (filter.value || "").replace(
            /(\d{3})(\d{3})(\d{4})/,
            "$1-$2-$3"
          );
        }
        if (filter.field === "ein") {
          filter.value = (filter.value || "").replace(
            /(\d{2})(\d{7})/,
            "$1-$2"
          );
        }

        return filter;
      }),
    }));
    setPageConfig(PAGE_KEYS.CONTRACTORS, {
      filters: pageFilters,
    });
  }, []);

  const updateFlagReason = async (icid, flagReason, flagType) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const payload = {
      ic_id: icid,
      reason_id: flagReason?.id,
      [flagType === "is_flagged" ? "is_flagged" : "is_cautious_flagged"]: true,
    };

    const { message, error } = await Service.updateFlagReason(payload);

    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    toast.success(message || "Reason updated successfully.");

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      markFlagUnflagIds: null,
      markCustionFlag: null,
      selectFlagReason: false,
      selectedRows: defaultState.selectedRows,
      updateFlagReason: defaultState.updateFlagReason,
      ic_id: defaultState.ic_id,
      updateFlagReasonValue: defaultState.updateFlagReasonValue,
      flagReason: defaultState.flagReason,
    }));
    if (gridHelper) {
      gridHelper.resetSelection();
    }
    fetchEntries(
      state.search,
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    );
  };

  const locationOPtions = Object.entries(state.locationList)
    ?.map(([state, value]) =>
      value.map((item) => ({
        label: `${item}-(${state})`,
        value: item,
      }))
    )
    ?.flat()
    ?.sort((a, b) => Intl.Collator().compare(a.label, b.label));

  const handleMarkFlagUnflag = async (markFlagUnflagIds) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const payload = {
      id: markFlagUnflagIds?.id,
      is_flagged: markFlagUnflagIds?.is_flagged,
      is_cautious_flagged:
        (!markFlagUnflagIds.icDocsStatus ||
          markFlagUnflagIds.icDocsStatus?.some(
            (item) => item.type === "Ic Watchlist" && item.is_archived === true
          )) &&
        markFlagUnflagIds?.isCautiousFlagged === true &&
        markFlagUnflagIds.flagKey === "markFlagUnflagIds"
          ? false
          : markFlagUnflagIds?.is_cautious_flagged,
      flag_reason: state.flagSubmitReason?.id,
    };

    const { message, error } = await Service.markFlagUnflag(payload);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      if (error[0]?.kamUsers) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          kamList: error[0]?.kamUsers.map((kam) => kam),
        }));
      }
      if (error[0]?.code !== ERROR_CODES.OVERRIDE_BASEROSTER_RECORD) {
        return toast.error(
          Array.isArray(error) ? error[0]?.message : error.message
        );
      }
      return;
    }

    toast.success(message || "Status updated successfully.");

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      markFlagUnflagIds: null,
      markCustionFlag: null,
      selectFlagReason: false,
      selectedRows: defaultState.selectedRows,
    }));
    if (gridHelper) {
      gridHelper.resetSelection();
    }
    fetchEntries(
      state.search,
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    );
  };

  const handleMarkActiveInactive = async (
    markActiveInactiveIds,
    updateBaseRoster = false
  ) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    markActiveInactiveIds = {
      ...markActiveInactiveIds,
      status: !markActiveInactiveIds.status,
      updateBaseRoster,
    };

    const { message, error } = await Service.markActiveInactive(
      markActiveInactiveIds
    );

    if (error) {
      // setState((prevState) => ({
      //   ...prevState,
      //   isLoading: false,
      //   confirmInactive:
      //     error[0]?.code === ERROR_CODES.OVERRIDE_BASEROSTER_RECORD,
      // }));
      if (error[0]?.kamUsers) {
        setState((prevState) => ({
          ...prevState,
          kamList: error[0]?.kamUsers.map((kam) => kam),
        }));
      }
      if (error[0]?.code !== ERROR_CODES.OVERRIDE_BASEROSTER_RECORD) {
        return toast.error(
          Array.isArray(error) ? error[0]?.message : error.message
        );
      }
      return;
    }

    toast.success(message || "Status updated successfully.");

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      markActiveInactiveIds: null,
      confirmInactive: false,
      selectedRows: defaultState.selectedRows,
    }));
    if (gridHelper) {
      gridHelper.resetSelection();
    }
    fetchEntries(
      state.search,
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    );
  };

  const fetchLocalResources = async () => {
    const response = await Service.getLocalResources();
    setState((prevState) => ({
      ...prevState,
      stateList: (response?.data?.states || defaultState.stateList).sort(
        (a, b) => {
          const valueA = (a.name || "").toLowerCase();
          const valueB = (b.name || "").toLowerCase();
          return valueA > valueB ? 1 : valueB > valueA ? -1 : 0;
        }
      ),
      locationList: response?.data?.locations || defaultState.locationList,
    }));
  };

  const fetchList = async (listType = "customers", ...params) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    switch (listType) {
      case "customers":
        serviceMethod = "getCustomerList";
        responseKey = "customers";
        break;
      case "users":
        serviceMethod = "getUsersList";
        responseKey = "users";
        break;
      case "pendingItems":
        serviceMethod = "getPendingItems";
        responseKey = "pendingItems";
        break;
      case "transferRecords":
        serviceMethod = "getTransferScheduleRequests";
        responseKey = "transferRecords";
        break;
      default:
        serviceMethod = "getCustomerList";
        responseKey = "customers";
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];
    if (listType === "customers") {
      listData.map((item = {}) => {
        (item.customer_branches || []).map((branch = {}) => {
          (branch.routes || []).sort((rowA, rowB) => {
            const valueA = (rowA?.name || "").toLowerCase();
            const valueB = (rowB?.name || "").toLowerCase();
            return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
          });
        });
      });
    }
    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
  };

  const fetchSettlementCompanyList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        settlementCompany: true,
      },
    }));

    const { data, error } = await SharedService.getSettlementCompanyList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          settlementCompany: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      settlementCompanyList:
        data?.rows.filter((settlementCompany) => settlementCompany.is_active) ||
        defaultState.settlementCompanyList,
      isFetchingList: {
        ...prevState.isFetchingList,
        settlementCompany: false,
      },
    }));
  };

  const fetchFlagTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        settlementCompanies: true,
      },
    }));

    const { data, error } = await SharedService.getFlagTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          filters: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetching: false,
      flagSubmitReasonList: data?.rows || [],
    }));
  };

  const fetchCautionFlagTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetching: true,
    }));

    const { data, error } = await SharedService.getCautionFlagTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetching: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetching: false,
      cautionFlagSubmitReasonList: data?.rows || [],
    }));
  };

  const fetchLeaveTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        settlementCompanies: true,
      },
    }));

    const { data, error } = await SharedService.getLeaveTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          filters: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetching: false,
      forceSubmitReasonList: data?.rows || [],
    }));
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      openForceSubmitDialog: false,
    }));
  };

  const fetchContractorTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        contractorType: true,
      },
    }));
    const { data, error } = await SharedService.getContractorTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          contractorType: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      contractorTypeList:
        data?.rows.filter((contractorType) => contractorType.is_active) ||
        defaultState.vehicleTypeList,
      isFetchingList: {
        ...prevState.isFetchingList,
        vehicleType: false,
      },
    }));
  };

  const getAllLocations = async () => {
    setState((prevState) => ({
      ...prevState,
      isAddressListLoading: true,
    }));
    const { data, error } = await Service.getAllLocations();
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isAddressListLoading: false,
      }));
    }
    setState((prevState) => ({
      ...prevState,
      allLocation: data?.locations?.contractors || defaultState?.allLocation,
      isAddressListLoading: false,
    }));
  };

  const deleteLocation = async () => {
    setState((prevState) => ({
      ...prevState,
      isAddressListLoading: true,
    }));
    const { error } = await Service.deleteLocation(
      "ic",
      state.icid,
      state.deletingAddressIds
    );
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isAddressListLoading: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success(`Address deleted successfully.`);
    setState((prevState) => ({
      ...prevState,
      isAddressListLoading: false,
      deleteAddress: false,
      openAddressDialog: false,
    }));
  };

  const handleAddNewLocation = () => {
    setState((prevState) => ({
      ...prevState,
      openMapsDialog: true,
    }));
  };

  useEffect(() => {
    getAllLocations();
  }, []);

  const handleEditAddressClose = () => {
    setState((prevState) => ({
      ...prevState,
      openAddressDialog: false,
    }));
  };

  const fetchPDXCompanyList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: true,
      },
    }));

    const { data, error } = await SharedService.getPDXCompanyList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          pdxCompany: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      pdxCompanyList:
        data?.rows.filter((pdxCompany) => pdxCompany.is_active) ||
        defaultState.pdxCompanyList,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: false,
      },
    }));
  };

  useEffect(() => {
    setTimeout(updateLayout, 2000);
  }, [state.entries]);

  useEffect(() => {
    !!appData.pendingItems.length &&
      isRoleKAM &&
      setHeaderElements([
        <div className="d-flex f-align-center m-2">
          <Chip
            className={"bg-danger color-white"}
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                openForceSubmitDialog: true,
              }));
            }}
            label={
              appData.isTabletView ? (
                <Tooltip title="PENDING SCHEDULE DATE(S)" placement="top-start">
                  <Typography variant="body2" className="text-bold">
                    ({appData.pendingItems.length})
                  </Typography>
                </Tooltip>
              ) : (
                <Typography variant="body2" className="text-bold">
                  PENDING SCHEDULE DATE(S) ({appData.pendingItems.length})
                </Typography>
              )
            }
            variant="outlined"
          />
        </div>,
      ]);

    return () => setHeaderElements([]);
  }, [appData.pendingItems]);

  useEffect(() => {
    if (kamId && isRoleKAM) {
      if (isTransferScheduleEnable) {
        fetchList("transferRecords", "", kamId);
      }
      fetchList("pendingItems", kamId);
    }
  }, [state.selectedKAM]);

  useEffect(() => {
    return () => {
      updateContextData("pendingItems", []);
    };
  }, []);

  useEffect(() => {
    window.history.replaceState(null, document.title);
    history.replace({ ...history.location, state: null });
    fetchLeaveTypeList();
    fetchFlagTypeList();
    fetchICDocumentStatus();
    fetchICAgreementStatus();
    fetchICWatchlistStatus();
    fetchCautionFlagTypeList();
    fetchContractorTypeList();
    fetchVehicleTypeList();
    fetchSettlementCompanyList();
    fetchPDXCompanyList();
    fetchLocalResources();
  }, []);

  useEffect(() => {
    fetchList("customers");
    fetchList("users");
  }, [match.params.path]);

  useEffect(() => {
    fetchEntries(
      state.search,
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    ).then((data) => {
      updatePagination(
        data?.count,
        { pageNumber: state.pageNumber, pageSize: state.pageSize },
        (pageNumber) => {
          setState((prevState) => ({ ...prevState, pageNumber }));
        }
      );
    });
  }, [
    fetchEntries,
    state.filters,
    state.pageSize,
    state.pageNumber,
    state.order,
    state.orderBy,
    state.search,
    state.pageFilters,
  ]);

  const settlementCompanyOptionList = useMemo(
    () =>
      state.settlementCompanyList.map((company) => ({
        value: company,
        label: company?.name,
      })),
    [state.settlementCompanyList]
  );

  const pdxCompanyOptionList = useMemo(
    () =>
      state.pdxCompanyList.map((company) => ({
        value: company,
        label: company?.name,
      })),
    [state.pdxCompanyList]
  );

  const userInfo = (id) => appData.users?.find((data) => data?.id === id);
  const userFlagInfo = (id) =>
    state.cautionFlagSubmitReasonList?.find((data) => data?.id === id);

  const moreAddressActions = (row) => {
    return (
      <div className="d-flex f-align-center">
        <Tooltip title="Edit" placement="top-start">
          <EditOutlinedIcon
            className={"ml-2 mr-2 c-pointer"}
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                openMapsDialog: row.address_id,
              }))
            }
          />
        </Tooltip>
        <Tooltip title="Delete" placement="top-start">
          <DeleteForeverOutlinedIcon
            color={"error"}
            className={"ml-2 mr-2 c-pointer"}
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                deleteAddress: true,
                deletingAddressIds: row?.address_id,
              }));
            }}
          />
        </Tooltip>
      </div>
    );
  };

  const mapColumnConfig =
    !isRoleKAM && !isRoleMGR
      ? [
          {
            headerClassName: classes.editAddress,
            id: "col-edit",
            noResize: true,
            render: (row) => moreAddressActions(row),
          },
          {
            id: "address",
            label: "Address",
            fieldName: "address",
            render: (row) => {
              return (
                <Tooltip
                  title={
                    `${row?.contractorAddress?.street_address}, ${row?.contractorAddress?.city}, ${row?.contractorAddress?.state}, ${row?.contractorAddress?.zip_code}, ${row?.contractorAddress?.country}` ??
                    ""
                  }
                  placement="top-start"
                >
                  <Typography variant="body2" noWrap>
                    {`${row?.contractorAddress?.street_address}, ${row?.contractorAddress?.city}, ${row?.contractorAddress?.state}, ${row?.contractorAddress?.zip_code}, ${row?.contractorAddress?.country}` ||
                      "-"}
                  </Typography>
                </Tooltip>
              );
            },
          },
          {
            id: "phone",
            label: "Phone",
            field: "phone",
            render: (row) => {
              return (
                <Tooltip
                  title={row?.contractorAddress?.phone ?? ""}
                  placement="top-start"
                >
                  <Typography variant="body2" noWrap>
                    {row?.contractorAddress?.phone || "-"}
                  </Typography>
                </Tooltip>
              );
            },
          },
          {
            id: "primary_contact",
            label: "Primary Contact",
            field: "primary_contact",
            render: (row) => {
              const primaryContact = row?.contractorAddress?.primary_contact;
              const modifiedContact =
                primaryContact && primaryContact.length > 0
                  ? primaryContact.length > 10
                    ? `${primaryContact.slice(0, 10)}...`
                    : primaryContact
                  : "-";

              return (
                <Tooltip
                  title={row?.contractorAddress?.primary_contact ?? ""}
                  placement="top-start"
                >
                  <Typography variant="body2">
                    {modifiedContact}
                    {/* {row?.contractorAddress?.primary_contact || "-"} */}
                  </Typography>
                </Tooltip>
              );
            },
          },
          // {
          //   id: "type",
          //   label: "Type",
          //   field: "type",
          //   render: (row) => {
          //     return (
          //       <Tooltip title={row?.type?.label ?? ""} placement="top-start">
          //         <Typography variant="body2">{row?.type?.label || "-"}</Typography>
          //       </Tooltip>
          //     );
          //   },
          // },
        ]
      : [
          {
            id: "address",
            label: "Address",
            fieldName: "address",
            render: (row) => {
              return (
                <Tooltip
                  title={
                    `${row?.contractorAddress?.street_address}, ${row?.contractorAddress?.city}, ${row?.contractorAddress?.state}, ${row?.contractorAddress?.zip_code}, ${row?.contractorAddress?.country}` ??
                    ""
                  }
                  placement="top-start"
                >
                  <Typography variant="body2" noWrap>
                    {`${row?.contractorAddress?.street_address}, ${row?.contractorAddress?.city}, ${row?.contractorAddress?.state}, ${row?.contractorAddress?.zip_code}, ${row?.contractorAddress?.country}` ||
                      "-"}
                  </Typography>
                </Tooltip>
              );
            },
          },
          {
            id: "phone",
            label: "Phone",
            field: "phone",
            render: (row) => {
              return (
                <Tooltip
                  title={row?.contractorAddress?.phone ?? ""}
                  placement="top-start"
                >
                  <Typography variant="body2" noWrap>
                    {row?.contractorAddress?.phone || "-"}
                  </Typography>
                </Tooltip>
              );
            },
          },
          {
            id: "primary_contact",
            label: "Primary Contact",
            field: "primary_contact",
            render: (row) => {
              const primaryContact = row?.contractorAddress?.primary_contact;
              const modifiedContact =
                primaryContact && primaryContact.length > 0
                  ? primaryContact.length > 10
                    ? `${primaryContact.slice(0, 10)}...`
                    : primaryContact
                  : "-";

              return (
                <Tooltip
                  title={row?.contractorAddress?.primary_contact ?? ""}
                  placement="top-start"
                >
                  <Typography variant="body2">
                    {modifiedContact}
                    {/* {row?.contractorAddress?.primary_contact || "-"} */}
                  </Typography>
                </Tooltip>
              );
            },
          },
        ];

  const handleFlagToggle = (row, flagKey, flagValue) => {
    if (
      !!row.icDocsStatus &&
      row.icDocsStatus?.some(
        (item) => item.type === "Ic Watchlist" && item.is_archived === false
      ) &&
      (row?.is_flagged === null || row?.is_flagged === false) &&
      !!row?.is_cautious_flagged &&
      flagKey !== "markFlagUnflagIds"
    ) {
      return setState((prevState) => ({
        ...prevState,
        uncheckWatchlistFlag: true,
      }));
    }
    if (
      !!row?.is_flagged &&
      !!row.icDocsStatus &&
      !!row.icDocsStatus?.some((item) => {
        return (
          item.name === "Driver's License" &&
          item.lookupStatus.value === "Not Eligible" &&
          !item.is_archived
        );
      })
    ) {
      return setState((prevState) => ({
        ...prevState,
        uncheckRedFlag: true,
      }));
    }
    if (
      !!row.icDocsStatus &&
      row.icDocsStatus?.some(
        (item) => item.type === "Ic Watchlist" && item.is_archived === false
      ) &&
      !!row.is_flagged &&
      flagKey === "markCustionFlag"
    ) {
      return setState((prevState) => ({
        ...prevState,
        removeRedFlagToNone: true,
      }));
    }
    if (
      !!row?.is_flagged &&
      flagKey === "markCustionFlag" &&
      (row?.is_cautious_flagged === null ||
        row?.is_cautious_flagged === false ||
        row?.is_cautious_flagged === true)
    ) {
      return setState((prevState) => ({
        ...prevState,
        removeRedFlagToNone: true,
      }));
    }
    setState((prevState) => ({
      ...prevState,
      [flagKey]: {
        id: row.id,
        [flagValue]: !row[flagValue],
        icDocsStatus: row.icDocsStatus,
        flagKey: flagKey,
        isCautiousFlagged: row.is_cautious_flagged,
      },
    }));

    if (row[flagValue]) {
      handleMarkFlagUnflag({
        id: row.id,
        [flagValue]: !row[flagValue],
      });
    }
  };

  useEffect(() => {
    if (state.entries) {
      const updatedRowList =
        state.isNew && !!state.entries
          ? newEntryCreated
          : !!state.entries &&
            !!state.rowBeingEdited &&
            state.entries.find((item) => item.id === state.rowBeingEdited.id);

      setState((prevState) => ({
        ...prevState,
        rowBeingEdited: updatedRowList,
      }));
    }
  }, [state.entries, state.newEntry]);

  const columnConfig = useMemo(
    () =>
      !isRoleKAM && !isRoleMGR && !isRoleVIEW
        ? [
            {
              id: "col-edit",
              noResize: true,
              render: (row) => (
                <div className="d-flex f-align-center f-justify-end">
                  <Tooltip title="Edit" placement="top-start">
                    <EditOutlinedIcon
                      className="ml-2 mr-2 c-pointer"
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          rowBeingEdited: row,
                        }))
                      }
                    />
                  </Tooltip>
                  <Tooltip
                    title={`Mark ${row.is_active ? "Inactive" : "Active"}`}
                    placement="top-start"
                  >
                    <Switch
                      size="small"
                      checked={row.is_active}
                      onChange={() => {
                        setState((prevState) => ({
                          ...prevState,
                          markActiveInactiveIds: {
                            recordId: row.id,
                            status: row.is_active,
                          },
                        }));
                      }}
                      color="primary"
                    />
                  </Tooltip>
                </div>
              ),
            },
            {
              label: "Flag",
              id: "flag",
              field: "flag",
              headerClassName: classes.flagPaddingLeft,
              isHidden: !state.dynamicColumns?.flag?.isSelected,
              noResize: true,
              render: (row) => {
                const isFlagged = row.is_flagged === true;
                const isCautiousFlagged = row.is_cautious_flagged === true;
                const isNullFlag = row.is_flagged === null;
                const isNullCautiousFlag = row.is_cautious_flagged === null;
                const isDisabled =
                  (isFlagged === false && isCautiousFlagged === false) ||
                  (isNullFlag && isCautiousFlagged === false) ||
                  (isFlagged === false && isNullCautiousFlag) ||
                  (isNullFlag && isNullCautiousFlag);

                const backgroundColor = isDisabled && "#cccbcb";

                const flagKey = isFlagged
                  ? "markFlagUnflagIds"
                  : "markCustionFlag";
                const flagValue = isFlagged
                  ? "is_flagged"
                  : "is_cautious_flagged";

                return (
                  <div className="d-flex f-align-center f-justify-start">
                    <ButtonGroup
                      size="small"
                      aria-label="small outlined button group"
                    >
                      <Tooltip title={"Mark Unflagged"} placement="top-start">
                        <Button
                          onClick={() =>
                            handleFlagToggle(row, flagKey, flagValue)
                          }
                          disabled={isDisabled}
                          style={{
                            backgroundColor,
                            color: "black",
                          }}
                        >
                          None
                        </Button>
                      </Tooltip>
                      <Tooltip title={"Mark Flagged"} placement="top-start">
                        <Button
                          onClick={() =>
                            handleFlagToggle(
                              row,
                              "markFlagUnflagIds",
                              "is_flagged"
                            )
                          }
                          disabled={row.is_flagged === true}
                          style={{
                            backgroundColor:
                              row.is_flagged === true && "#ff67674a",
                          }}
                          className={classes.flaggedButton}
                        >
                          <FlagIcon style={{ color: "red" }} />
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title={"Mark Caution Flag"}
                        placement="top-start"
                      >
                        <Button
                          onClick={() =>
                            handleFlagToggle(
                              row,
                              "markCustionFlag",
                              "is_cautious_flagged"
                            )
                          }
                          disabled={
                            (row.is_flagged === null ||
                              row.is_flagged === false) &&
                            !!row.is_cautious_flagged
                          }
                          style={{
                            backgroundColor:
                              (row.is_flagged === null ||
                                row.is_flagged === false) &&
                              row.is_cautious_flagged === true &&
                              "rgb(255 255 0 / 14%)",
                          }}
                          className={classes.cautionButton}
                        >
                          <div className={classes.warningIconHeight}>
                            <WarningIcon className={classes.warningIcon} />
                            <div className={classes.exclamationMark}></div>
                          </div>
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </div>
                );
              },
            },
            {
              isHidden: !state.dynamicColumns?.icid?.isSelected,
              headerClassName: classes.icidFlagTitle,
              id: "icid",
              label: "ICID",
              field: "icid",
              canSort: true,
              render: (row) => {
                const flagLabel =
                  (!!row.is_flagged && !!row.is_cautious_flagged) ||
                  !!row.is_flagged ? (
                    <FlagIcon style={{ color: "red" }} />
                  ) : row.is_cautious_flagged ? (
                    <div className={classes.warningIconHeights}>
                      <WarningIcon
                        className={classes.warningIcon}
                        style={{ marginTop: "2px" }}
                      />
                      <div className={classes.exclamationMarks}></div>
                    </div>
                  ) : (
                    ""
                  );

                const cautionFlaggedBy = userInfo(row?.ctn_flag_by);
                const cautionFlaggedReason = userFlagInfo(row?.ctn_flag_rsn);

                const flagTitles = `Flagged Reason: ${
                  row.flagReason?.value || cautionFlaggedReason?.value
                }, Flagged By: ${
                  row?.flaggedBy?.first_name ||
                  cautionFlaggedBy?.first_name ||
                  ""
                } ${
                  row?.flaggedBy?.last_name || cautionFlaggedBy?.last_name || ""
                }, Username: ${
                  row?.flaggedBy?.username || cautionFlaggedBy?.username || ""
                }, Role: ${
                  row?.flaggedBy?.role || cautionFlaggedBy?.role || ""
                }, Flagged At: ${getDateString(
                  row?.flagged_at || row?.ctn_flagged_at
                )}`;

                return (
                  <Fragment>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <>
                        {!isRoleKAM && !isRoleVIEW ? (
                          <Tooltip title={flagTitles} placement="top-start">
                            <Typography
                              noWrap
                              variant="body2"
                              className="c-pointer"
                              onClick={() => {
                                if (
                                  !!row.icDocsStatus &&
                                  row.icDocsStatus?.some(
                                    (item) =>
                                      item.type === "Ic Watchlist" &&
                                      item.is_archived === false
                                  ) &&
                                  (row?.is_flagged === null ||
                                    row?.is_flagged === false) &&
                                  !!row?.is_cautious_flagged
                                ) {
                                  return setState((prevState) => ({
                                    ...prevState,
                                    uncheckWatchlistFlag: true,
                                  }));
                                }
                                if (
                                  !!row?.is_flagged &&
                                  !!row.icDocsStatus &&
                                  !!row.icDocsStatus?.some((item) => {
                                    return (
                                      item.name === "Driver's License" &&
                                      item.lookupStatus.value ===
                                        "Not Eligible" &&
                                      !item.is_archived
                                    );
                                  })
                                ) {
                                  return setState((prevState) => ({
                                    ...prevState,
                                    uncheckRedFlag: true,
                                  }));
                                }
                                setState((prevState) => ({
                                  ...prevState,
                                  updateFlagReason: true,
                                  ic_id: row.id,
                                  updateFlagReasonValue:
                                    row.flagReason || cautionFlaggedReason,
                                  flaggedType: row.is_flagged,
                                }));
                              }}
                            >
                              {flagLabel}
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Tooltip title={flagTitles} placement="top-start">
                            <Typography noWrap variant="body2">
                              {flagLabel}
                            </Typography>
                          </Tooltip>
                        )}
                      </>
                      <>
                        <div
                          className={
                            !row.is_flagged && !row.is_cautious_flagged
                              ? "ml-6"
                              : ""
                          }
                        >
                          <Tooltip title={row.icid} placement="top-start">
                            <Typography variant="body2" noWrap>
                              {row.icid}
                            </Typography>
                          </Tooltip>
                        </div>
                      </>
                    </div>
                  </Fragment>
                );
              },
            },

            {
              isHidden: !state.dynamicColumns?.last_name?.isSelected,
              headerClassName: classes.tableHeaderCell,
              id: "last_name",
              label: "Last Name",
              field: "last_name",
              canSort: true,
              hasEllipses: true,
            },
            {
              isHidden: !state.dynamicColumns?.first_name?.isSelected,
              headerClassName: classes.tableHeaderCell,
              id: "first_name",
              label: "First Name",
              field: "first_name",
              canSort: true,
              hasEllipses: true,
            },

            {
              isHidden: !state.dynamicColumns?.ic_company_name?.isSelected,
              headerClassName: classes.tableHeadCell,
              id: "ic_company_name",
              label: "IC Company Name",
              field: "ic_company_name",
              canSort: true,
              hasEllipses: true,
            },
            {
              isHidden: !state.dynamicColumns?.state?.isSelected,
              id: "state",
              label: "State",
              field: "state",
              canSort: true,
              render: (row) => {
                return (
                  <Tooltip title={row.state?.name ?? ""} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {row.state?.name ?? "-"}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              isHidden: !state.dynamicColumns?.city?.isSelected,
              id: "city",
              label: "City",
              field: "city",
              canSort: true,
              hasEllipses: true,
            },

            {
              isHidden: !state.dynamicColumns?.phone?.isSelected,
              id: "phone",
              label: "Phone",
              field: "phone",
              canSort: true,
              hasEllipses: true,
            },
            {
              isHidden: !state.dynamicColumns?.email?.isSelected,
              id: "email",
              label: "Email",
              field: "email",
              canSort: true,
              hasEllipses: true,
            },
            {
              isHidden: !state.dynamicColumns?.contractor_type?.isSelected,
              headerClassName: classes.tableHeaderCell,
              id: "contractor_type",
              label: "Contractor Type",
              field: "contractor_type",
              canSort: true,
              hasEllipses: true,
              render: (row) => {
                return (
                  <Tooltip
                    title={row.contractorType?.value ?? ""}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {row.contractorType?.value ?? "-"}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              isHidden: !state.dynamicColumns?.pdx_company?.isSelected,
              id: "pdx_company_name",
              label: "PDX Company",
              field: "pdx_company_name",
              canSort: true,
              render: (row) => {
                return (
                  <Tooltip
                    title={row.pdxCompany?.value ?? ""}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {row.pdxCompany?.value ?? "-"}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              isHidden: !state.dynamicColumns?.default_vehicle_type?.isSelected,
              headerClassName: classes.tableHeadWidth,
              id: "default_vehicle_type",
              label: "Default Vehicle Type",
              field: "default_vehicle_type",
              canSort: true,
              hasEllipses: true,
              render: (row) => {
                const title = row.defaultVehicleType?.value || "-";
                return (
                  <Tooltip title={title} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {title ?? "-"}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              isHidden: !state.dynamicColumns?.stand_by_rate_type?.isSelected,
              headerClassName: classes.tableHeadWidth,
              id: "stand_by_rate_type",
              label: "Stand-by Rate Type",
              field: "stand_by_rate_type",
              canSort: true,
              hasEllipses: true,
              render: (row) => (
                <Tooltip
                  title={
                    !(row.contractorType?.value === "Stand-by")
                      ? "N/A"
                      : row.stand_by_rate_type
                  }
                  placement="top-start"
                >
                  <Typography variant="body2" noWrap>
                    {!(row.contractorType?.value === "Stand-by")
                      ? "N/A"
                      : row.stand_by_rate_type}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              isHidden: !state.dynamicColumns?.stand_by_rate?.isSelected,
              headerClassName: classes.tableHeaderCell,
              id: "stand_by_rate",
              label: "Stand-by Rate",
              field: "stand_by_rate",
              startAdornment: "$",
              canSort: true,
              hasEllipses: true,
              render: (row) => (
                <Tooltip
                  title={
                    !(row.contractorType?.value === "Stand-by")
                      ? "N/A"
                      : row.stand_by_rate
                  }
                  placement="top-start"
                >
                  <Typography variant="body2" noWrap>
                    {!(row.contractorType?.value === "Stand-by")
                      ? "N/A"
                      : row.stand_by_rate}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              isHidden: !state.dynamicColumns?.ein?.isSelected,
              id: "ein",
              label: "EIN",
              field: "ein",
              canSort: true,
              hasEllipses: true,
            },
            {
              isHidden: !state.dynamicColumns?.ssn?.isSelected,
              id: "ssn",
              label: "SSN",
              field: "ssn",
              canSort: true,
              hasEllipses: true,
            },
            {
              isHidden: !state.dynamicColumns?.date_of_birth?.isSelected,
              headerClassName: classes.dateOfBirthColumn,
              id: "date_of_birth",
              label: "Date Of Birth",
              field: "date_of_birth",
              canSort: true,
              render: (row) => {
                const title = row.date_of_birth
                  ? getDateString(row.date_of_birth)
                  : "";
                return (
                  <Tooltip title={title} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {title ?? "-"}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              isHidden: !state.dynamicColumns?.default_customer?.isSelected,
              headerClassName: classes.tableHeaderWidth,
              id: "customer_name",
              label: "Default Customer",
              field: "customer_name",
              canSort: true,
              render: (row) => {
                return (
                  <Tooltip
                    title={row.customer?.name ?? ""}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {row.customer?.name ?? "-"}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              isHidden:
                !state.dynamicColumns?.default_settlement_company?.isSelected,
              headerClassName: classes.tableHeadCell,
              hasEllipses: true,
              id: "settlement_company_name",
              label: "Default Settlement Company",
              field: "settlement_company_name",
              canSort: true,
              render: (row) => {
                return (
                  <Tooltip
                    title={row.settlementCompany?.value ?? ""}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {row.settlementCompany?.value ?? "-"}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              isHidden: !state.dynamicColumns?.default_kam?.isSelected,
              id: "kam_name",
              label: "Default KAM",
              field: "kam_name",
              canSort: true,
              render: (row) => {
                const kamName = `${row.kamUser?.first_name || ""} ${
                  row.kamUser?.last_name || ""
                }`;
                const title = row.kamUser?.username
                  ? `${kamName}-(${row.kamUser?.username})`
                  : "";
                return (
                  <Tooltip title={title} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {kamName}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              isHidden: !state.dynamicColumns?.notes?.isSelected,
              id: "notes",
              label: "Notes",
              field: "notes",
              canSort: true,
              render: (row) => {
                return (
                  <Tooltip
                    title={row.notes ?? ""}
                    placement="top-start"
                    //classes={{ tooltip: classes.customSpace }}
                  >
                    <Typography
                      variant="body2"
                      noWrap
                      style={{ whiteSpace: "pre" }}
                    >
                      {row.notes ?? "-"}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              isHidden: !state.dynamicColumns?.flag_Reason?.isSelected,
              id: "flag_reason_value",
              label: "Flagged Reason",
              field: "flag_reason_value",
              canSort: true,
              render: (row) => {
                return (
                  <Tooltip
                    title={row.flagReason?.value ?? ""}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {row.flagReason?.value ?? "-"}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              isHidden: !state.dynamicColumns?.address?.isSelected,
              id: "location",
              label: "Address",
              field: "location",
              render: (row) => (
                <div className="c-pointer">
                  {row.addresses === null || row.addresses === "[]" ? (
                    <Tooltip title={"Add IC Address"} placement="top-end">
                      <LocationOnIcon
                        style={{ color: "#9e9e9e" }}
                        onClick={() => {
                          setState((prevState) => ({
                            ...prevState,
                            openMapsDialog: true,
                            icid: row.id,
                            ic_name: row.first_name,
                          }));
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title={"Edit IC Address"} placement="top-end">
                      <LocationOnIcon
                        style={{ color: "#004700" }}
                        onClick={() => {
                          setState((prevState) => ({
                            ...prevState,
                            openAddressDialog: true,
                            icid: row.id,
                            formType: "ICAddress",
                            ic_name: row.first_name,
                            editLocation: state?.allLocation?.filter(
                              (location) => location.id === row.id
                            ),
                          }));
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
              ),
            },
          ]
        : [
            {
              id: "col-edit",
              noResize: true,
              render: (row) => (
                <Tooltip
                  title={`${!row.is_active ? "Inactive" : "Active"}`}
                  placement="top-start"
                  style={{ cursor: "default" }}
                >
                  <Switch
                    size="small"
                    checked={row.is_active}
                    className="none-events"
                    color="primary"
                  />
                </Tooltip>
              ),
            },
            {
              isHidden: !state.dynamicColumnsKAM?.icid?.isSelected,
              headerClassName: classes.icidFlagTitle,
              id: "icid",
              label: "ICID",
              field: "icid",
              canSort: true,
              render: (row) => {
                const flagLabel =
                  (!!row.is_flagged && !!row.is_cautious_flagged) ||
                  !!row.is_flagged ? (
                    <FlagIcon style={{ color: "red" }} />
                  ) : row.is_cautious_flagged ? (
                    <div className={classes.warningIconHeights}>
                      <WarningIcon
                        className={classes.warningIcon}
                        style={{ marginTop: "2px" }}
                      />
                      <div className={classes.exclamationMarks}></div>
                    </div>
                  ) : (
                    ""
                  );

                const cautionFlaggedBy = userInfo(row?.ctn_flag_by);
                const cautionFlaggedReason = userFlagInfo(row?.ctn_flag_rsn);

                const flagTitles = `Flagged Reason: ${
                  row.flagReason?.value || cautionFlaggedReason?.value
                }, Flagged By: ${
                  row?.flaggedBy?.first_name ||
                  cautionFlaggedBy?.first_name ||
                  ""
                } ${
                  row?.flaggedBy?.last_name || cautionFlaggedBy?.last_name || ""
                }, Username: ${
                  row?.flaggedBy?.username || cautionFlaggedBy?.username || ""
                }, Role: ${
                  row?.flaggedBy?.role || cautionFlaggedBy?.role || ""
                }, Flagged At: ${getDateString(
                  row?.flagged_at || row?.ctn_flagged_at
                )}`;

                return (
                  <Fragment>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <>
                        {!isRoleKAM && !isRoleVIEW ? (
                          <Tooltip title={flagTitles} placement="top-start">
                            <Typography
                              noWrap
                              variant="body2"
                              className="c-pointer"
                              onClick={() => {
                                if (
                                  !!row.icDocsStatus &&
                                  row.icDocsStatus?.some(
                                    (item) =>
                                      item.type === "Ic Watchlist" &&
                                      item.is_archived === false
                                  ) &&
                                  (row?.is_flagged === null ||
                                    row?.is_flagged === false) &&
                                  !!row?.is_cautious_flagged
                                ) {
                                  return setState((prevState) => ({
                                    ...prevState,
                                    uncheckWatchlistFlag: true,
                                  }));
                                }
                                if (
                                  !!row?.is_flagged &&
                                  !!row.icDocsStatus &&
                                  !!row.icDocsStatus?.some((item) => {
                                    return (
                                      item.name === "Driver's License" &&
                                      item.lookupStatus.value === "Not Eligible"
                                    );
                                  })
                                ) {
                                  return setState((prevState) => ({
                                    ...prevState,
                                    uncheckRedFlag: true,
                                  }));
                                }
                                setState((prevState) => ({
                                  ...prevState,
                                  updateFlagReason: true,
                                  ic_id: row.id,
                                  updateFlagReasonValue:
                                    row.flagReason || cautionFlaggedReason,
                                  flaggedType: row.is_flagged,
                                }));
                              }}
                            >
                              {flagLabel}
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Tooltip title={flagTitles} placement="top-start">
                            <Typography noWrap variant="body2">
                              {flagLabel}
                            </Typography>
                          </Tooltip>
                        )}
                      </>
                      <>
                        <div
                          className={
                            !row.is_flagged && !row.is_cautious_flagged
                              ? "ml-6"
                              : ""
                          }
                        >
                          <Tooltip title={row.icid} placement="top-start">
                            <Typography variant="body2" noWrap>
                              {row.icid}
                            </Typography>
                          </Tooltip>
                        </div>
                      </>
                    </div>
                  </Fragment>
                );
              },
            },
            {
              isHidden: !state.dynamicColumnsKAM?.last_name?.isSelected,
              headerClassName: classes.tableHeaderCell,
              id: "last_name",
              label: "Last Name",
              field: "last_name",
              canSort: true,
              hasEllipses: true,
            },
            {
              isHidden: !state.dynamicColumnsKAM?.first_name?.isSelected,
              headerClassName: classes.tableHeaderCell,
              id: "first_name",
              label: "First Name",
              field: "first_name",
              canSort: true,
              hasEllipses: true,
            },

            {
              isHidden: !state.dynamicColumnsKAM?.ic_company_name?.isSelected,
              headerClassName: classes.tableHeadCell,
              id: "ic_company_name",
              label: "IC Company Name",
              field: "ic_company_name",
              canSort: true,
              hasEllipses: true,
            },
            {
              isHidden: !state.dynamicColumnsKAM?.state?.isSelected,
              id: "state",
              label: "State",
              field: "state",
              canSort: true,
              render: (row) => {
                return (
                  <Tooltip title={row.state?.name ?? ""} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {row.state?.name ?? "-"}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              isHidden: !state.dynamicColumnsKAM?.city?.isSelected,
              id: "city",
              label: "City",
              field: "city",
              canSort: true,
              hasEllipses: true,
            },

            {
              isHidden: !state.dynamicColumnsKAM?.phone?.isSelected,
              id: "phone",
              label: "Phone",
              field: "phone",
              canSort: true,
              hasEllipses: true,
            },
            {
              isHidden: !state.dynamicColumnsKAM?.email?.isSelected,
              id: "email",
              label: "Email",
              field: "email",
              canSort: true,
              hasEllipses: true,
            },
            {
              isHidden: !state.dynamicColumnsKAM?.contractor_type?.isSelected,
              headerClassName: classes.tableHeaderCell,
              id: "contractor_type",
              label: "Contractor Type",
              field: "contractor_type",
              canSort: true,
              hasEllipses: true,
              render: (row) => {
                return (
                  <Tooltip
                    title={row.contractorType?.value ?? ""}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {row.contractorType?.value ?? "-"}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              isHidden: !state.dynamicColumnsKAM?.pdx_company?.isSelected,
              id: "pdx_company_name",
              label: "PDX Company",
              field: "pdx_company_name",
              canSort: true,
              render: (row) => {
                return (
                  <Tooltip
                    title={row.pdxCompany?.value ?? ""}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {row.pdxCompany?.value ?? "-"}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              isHidden:
                !state.dynamicColumnsKAM?.default_vehicle_type?.isSelected,
              headerClassName: classes.tableHeadWidth,
              id: "default_vehicle_type",
              label: "Default Vehicle Type",
              field: "default_vehicle_type",
              canSort: true,
              hasEllipses: true,
              render: (row) => {
                const title = row.defaultVehicleType?.value || "-";
                return (
                  <Tooltip title={title} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {title ?? "-"}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              isHidden:
                !state.dynamicColumnsKAM?.stand_by_rate_type?.isSelected,
              headerClassName: classes.tableHeadWidth,
              id: "stand_by_rate_type",
              label: "Stand-by Rate Type",
              field: "stand_by_rate_type",
              canSort: true,
              hasEllipses: true,
              render: (row) => (
                <Tooltip
                  title={
                    !(row.contractorType?.value === "Stand-by")
                      ? "N/A"
                      : row.stand_by_rate_type
                  }
                  placement="top-start"
                >
                  <Typography variant="body2" noWrap>
                    {!(row.contractorType?.value === "Stand-by")
                      ? "N/A"
                      : row.stand_by_rate_type}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              isHidden: !state.dynamicColumnsKAM?.stand_by_rate?.isSelected,
              headerClassName: classes.tableHeaderCell,
              id: "stand_by_rate",
              label: "Stand-by Rate",
              field: "stand_by_rate",
              startAdornment: "$",
              canSort: true,
              hasEllipses: true,
              render: (row) => (
                <Tooltip
                  title={
                    !(row.contractorType?.value === "Stand-by")
                      ? "N/A"
                      : row.stand_by_rate
                  }
                  placement="top-start"
                >
                  <Typography variant="body2" noWrap>
                    {!(row.contractorType?.value === "Stand-by")
                      ? "N/A"
                      : row.stand_by_rate}
                  </Typography>
                </Tooltip>
              ),
            },
            {
              isHidden: !state.dynamicColumnsKAM?.ein?.isSelected,
              id: "ein",
              label: "EIN",
              field: "ein",
              canSort: true,
              hasEllipses: true,
            },
            {
              isHidden: !state.dynamicColumnsKAM?.ssn?.isSelected,
              id: "ssn",
              label: "SSN",
              field: "ssn",
              canSort: true,
              hasEllipses: true,
            },
            {
              isHidden: !state.dynamicColumnsKAM?.date_of_birth?.isSelected,
              headerClassName: classes.dateOfBirthColumn,
              id: "date_of_birth",
              label: "Date Of Birth",
              field: "date_of_birth",
              canSort: true,
              render: (row) => {
                const title = row.date_of_birth
                  ? getDateString(row.date_of_birth)
                  : "";
                return (
                  <Tooltip title={title} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {title ?? "-"}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              isHidden: !state.dynamicColumnsKAM?.default_customer?.isSelected,
              headerClassName: classes.tableHeaderWidth,
              id: "customer_name",
              label: "Default Customer",
              field: "customer_name",
              canSort: true,
              render: (row) => {
                return (
                  <Tooltip
                    title={row.customer?.name ?? ""}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {row.customer?.name ?? "-"}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              isHidden:
                !state.dynamicColumnsKAM?.default_settlement_company
                  ?.isSelected,
              headerClassName: classes.tableHeadCell,
              hasEllipses: true,
              id: "settlement_company_name",
              label: "Default Settlement Company",
              field: "settlement_company_name",
              canSort: true,
              render: (row) => {
                return (
                  <Tooltip
                    title={row.settlementCompany?.value ?? ""}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {row.settlementCompany?.value ?? "-"}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              isHidden: !state.dynamicColumnsKAM?.default_kam?.isSelected,
              id: "kam_name",
              label: "Default KAM",
              field: "kam_name",
              canSort: true,
              render: (row) => {
                const kamName = `${row.kamUser?.first_name || ""} ${
                  row.kamUser?.last_name || ""
                }`;
                const title = row.kamUser?.username
                  ? `${kamName}-(${row.kamUser?.username})`
                  : "";
                return (
                  <Tooltip title={title} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {kamName}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              isHidden: !state.dynamicColumnsKAM?.notes?.isSelected,
              id: "notes",
              label: "Notes",
              field: "notes",
              canSort: true,
              hasEllipses: true,
            },
            {
              isHidden: !state.dynamicColumnsKAM?.flag_Reason?.isSelected,
              id: "flag_reason_value",
              label: "Flagged Reason",
              field: "flag_reason_value",
              canSort: true,
              render: (row) => {
                return (
                  <Tooltip
                    title={row.flagReason?.value ?? ""}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {row.flagReason?.value ?? "-"}
                    </Typography>
                  </Tooltip>
                );
              },
            },
            {
              isHidden: !state.dynamicColumns?.address?.isSelected,
              id: "location",
              label: "Address",
              field: "location",
              render: (row) => {
                return row.addresses === null || row.addresses === "[]" ? (
                  <LocationOnIcon style={{ color: "#9e9e9e" }} />
                ) : (
                  <div className="c-pointer">
                    <Tooltip title={"View IC Address"} placement="top-end">
                      <LocationOnIcon
                        style={{ color: "#004700" }}
                        onClick={() => {
                          setState((prevState) => ({
                            ...prevState,
                            openAddressDialog: true,
                            icid: row.id,
                            formType: "ICAddress",
                            ic_name: row.first_name,
                            editLocation: state?.allLocation?.filter(
                              (location) => location.id === row.id
                            ),
                          }));
                        }}
                      />
                    </Tooltip>
                  </div>
                );
              },
            },
          ],

    [state, appDataList, settlementCompanyOptionList, pdxCompanyOptionList]
  );

  const handlePageNumberChange = useCallback((pageNumber) => {
    setPageConfig(PAGE_KEYS.CONTRACTORS, {
      pageNumber,
    });
    setState((prevState) => ({
      ...prevState,
      pageNumber,
      selectedRows: defaultState.selectedRows,
    }));
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setPageConfig(PAGE_KEYS.CONTRACTORS, {
      pageSize,
    });
    setState((prevState) => ({
      ...prevState,
      pageSize,
      pageNumber: defaultState.pageNumber,
      selectedRows: defaultState.selectedRows,
    }));
  }, []);

  const handleSelectionChange = useCallback((selectedRows) => {
    setState((prevState) => ({
      ...prevState,
      selectedRows,
    }));
  }, []);

  const handleSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      order: order,
      orderBy: fieldObj.field || fieldObj.fieldName,
      selectedRows: defaultState.selectedRows,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const fetchVehicleTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        vehicleType: true,
      },
    }));
    const { data, error } = await SharedService.getVehicleTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          vehicleType: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      vehicleTypeList:
        data?.rows.filter((vehicle) => vehicle.is_active) ||
        defaultState.vehicleTypeList,
      isFetchingList: {
        ...prevState.isFetchingList,
        vehicleType: false,
      },
    }));
  };

  const handleSearch = useCallback((search) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        search,
        selectedRows: defaultState.selectedRows,
        pageNumber: defaultState.pageNumber,
      }));
      setPageConfig(PAGE_KEYS.CONTRACTORS, {
        search,
      });
      gridHelper && gridHelper.resetSelection();
    }, 1000);
  }, []);

  const handleCitySearch = useCallback((value) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (value === "") {
        setPageConfig(PAGE_KEYS.CONTRACTORS, {
          locationFilter: null,
        });
        return setState((prevState) => ({
          ...prevState,
          selectedLocation: value,
          filters: {
            ...prevState.filters,
            locations: [],
          },
        }));
      }
      setState((prevState) => ({
        ...prevState,
        selectedLocation: value,
        filters: {
          ...prevState.filters,
          locations: [
            {
              field: "city",
              type: "iRegexp",
              value: value,
            },
          ],
        },
        selectedRows: defaultState.selectedRows,
        pageNumber: defaultState.pageNumber,
      }));
      setPageConfig(PAGE_KEYS.CONTRACTORS, {
        locationFilter: {
          value,
          locations: [
            {
              field: "city",
              type: "iRegexp",
              value: value,
            },
          ],
        },
      });
      gridHelper && gridHelper.resetSelection();
    }, 1000);
  }, []);

  return (
    <div id="numbers-page-wrapper">
      <div className="mr-5">
        <div className={clsx(" mr-10", classes.fixedHeader)}>
          <Typography variant="h4" color="primary" className="ml-2">
            Independent Contractor
            <Button
              style={{ float: "right" }}
              className="mt-1 mr-2"
              variant="contained"
              color="primary"
              startIcon={<ArrowBackIcon />}
              onClick={() => history.push("/home")}
            >
              Back To Home
            </Button>
          </Typography>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            {!isRoleVIEW
              ? " View/Edit Independent Contractor's information."
              : " View Independent Contractor's information."}
          </Typography>
        </div>
        <div className="d-flex f-justify-between f-align-center">
          <Autocomplete
            classes={{
              root: classes.kamInput,
              inputRoot: classes.kamInputRoot,
            }}
            style={{ width: "244px", marginLeft: 12 }}
            size="small"
            fullWidth
            freeSolo
            value={state.selectedLocation}
            disableClearable
            options={
              state.selectedLocation.value === undefined &&
              state.selectedLocation?.length > 0
                ? locationOPtions?.filter((option) =>
                    option.label
                      ?.toLowerCase()
                      ?.includes(state.selectedLocation?.toLowerCase())
                  )
                : locationOPtions
            }
            getOptionLabel={(option) => option.label || option}
            renderInput={(params) =>
              state.isFetchingList.users ? (
                <Skeleton variant="rect" width="100%" height="36px" />
              ) : (
                <TextField
                  {...params}
                  type="text"
                  variant="outlined"
                  className="ml-2"
                  size="small"
                  placeholder="City"
                  onChange={(evt) =>
                    handleCitySearch((evt.target.value || "")?.trim())
                  }
                />
              )
            }
            getOptionSelected={(option, value) => option.label === value.label}
            onChange={(evt, value) => {
              setState((prevState) => ({
                ...prevState,
                selectedLocation: value,
                filters: {
                  ...prevState.filters,
                  locations: [
                    {
                      field: "city",
                      type: "=",
                      value: value.value,
                    },
                  ],
                },
              }));
              setPageConfig(PAGE_KEYS.CONTRACTORS, {
                locationFilter: {
                  value,
                  locations: [
                    {
                      field: "city",
                      type: "=",
                      value: value.value,
                    },
                  ],
                },
              });
              gridHelper && gridHelper.resetSelection();
            }}
          />
          <div className="d-flex f-align-center">
            <Typography variant="body1">Enter to search by:</Typography>
            <TextField
              type="text"
              variant="outlined"
              className="ml-2"
              size="small"
              defaultValue={state.search}
              // defaultValue={!data.icid ? undefined : state.search}
              placeholder="Name, ICID, IC Co. Name"
              InputProps={{ endAdornment: <SearchIcon /> }}
              onChange={(evt) => handleSearch((evt.target.value || "").trim())}
            />
          </div>
        </div>
        <Paper
          elevation={2}
          className={clsx("p-4 mt-10 h-100", classes.paperSpacing)}
        >
          <div
            className={clsx(
              "d-flex f-align-center f-justify-between p-2 pl-4 pr-4",
              classes.actionsWrapper
            )}
          >
            {!isRoleKAM && !isRoleMGR && !isRoleVIEW && (
              <div className="d-flex f-align-center">
                <Button
                  startIcon={<PlaylistAddIcon />}
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    setState((prevState) => ({ ...prevState, isNew: true }))
                  }
                >
                  New
                </Button>
              </div>
            )}

            <div className="d-flex f-align-center"></div>

            <div className="d-flex f-align-center">
              <Button
                variant="contained"
                color="primary"
                className="mr-2"
                startIcon={<CloudDownloadIcon />}
                disabled={state.isDownloadLoading || !state.totalEntries}
                onClick={() => {
                  handleDownload(
                    state.search,
                    state.filters,
                    state.order,
                    state.orderBy,
                    state.pageFilters
                  );
                }}
              >
                Download
                {state.isDownloadLoading && (
                  <CircularProgress size={24} className={classes.progressBtn} />
                )}
              </Button>
              <Dropdown
                classes={{
                  paper: classes.dropdownPaper,
                }}
                disablePortal={false}
                isMultiSelect
                remainOpen
                placement="bottom-end"
                options={Object.keys(state.dynamicStatus).map(
                  (key) => state.dynamicStatus[key]
                )}
                labelEllipses
                hasEllipses
                label={"Status :"}
                onChange={(selectedOptions) => {
                  const isSelectAll = selectedOptions.some(
                    (item) => item.value === "all"
                  );

                  const isCurrentSelectAll =
                    state.dynamicStatus?.all?.isSelected;

                  const isClickedOnSelectAll =
                    isSelectAll !== isCurrentSelectAll;

                  const isAllOptionSelected =
                    Object.keys(state.dynamicStatus).length - 1 ===
                    selectedOptions.filter((item) => item.value !== "all")
                      .length;

                  const updatedDynamicStatus = Object.keys(
                    state.dynamicStatus
                  ).reduce((acc, key) => {
                    const isSelected = isClickedOnSelectAll
                      ? isSelectAll
                      : key === "all"
                      ? isAllOptionSelected
                      : !!selectedOptions.some(
                          (item) =>
                            item.label === state.dynamicStatus[key].label
                        );

                    return {
                      ...acc,
                      [key]: {
                        ...state.dynamicStatus[key],
                        isSelected,
                      },
                    };
                  }, {});
                  let tempFilters = [];

                  if (!updatedDynamicStatus["all"].isSelected) {
                    if (updatedDynamicStatus["only_active"].isSelected) {
                      if (!updatedDynamicStatus["only_inActive"].isSelected) {
                        tempFilters.push({
                          field: "is_active",
                          type: "=",
                          value: true,
                        });
                      }
                    } else {
                      if (updatedDynamicStatus["only_inActive"].isSelected) {
                        tempFilters.push({
                          field: "is_active",
                          type: "neq",
                          value: true,
                        });
                      }
                    }
                    if (updatedDynamicStatus["only_flagged"].isSelected) {
                      if (!updatedDynamicStatus["only_unFlagged"].isSelected) {
                        tempFilters.push({
                          field: "is_flagged",
                          type: "=",
                          value: true,
                        });
                      }
                    } else {
                      if (updatedDynamicStatus["only_unFlagged"].isSelected) {
                        tempFilters.push({
                          field: "is_flagged",
                          type: "neq",
                          value: true,
                        });
                      }
                    }
                    if (
                      updatedDynamicStatus["only_cautious_flagged"].isSelected
                    ) {
                      if (
                        !updatedDynamicStatus["only_cautious_unFlagged"]
                          .isSelected
                      ) {
                        tempFilters.push({
                          field: "is_cautious_flagged",
                          type: "=",
                          value: true,
                        });
                      }
                    } else {
                      if (
                        updatedDynamicStatus["only_cautious_unFlagged"]
                          .isSelected
                      ) {
                        tempFilters.push({
                          field: "is_cautious_flagged",
                          type: "neq",
                          value: true,
                        });
                      }
                    }
                  }
                  setState((prevState) => ({
                    ...prevState,
                    dynamicStatus: {
                      ...prevState.dynamicStatus,
                      ...updatedDynamicStatus,
                    },
                    filters: {
                      ...prevState.filters,
                      userStatus: tempFilters,
                    },
                    pageNumber: defaultState.pageNumber,
                  }));
                  setPageConfig(PAGE_KEYS.CONTRACTORS, {
                    dynamicStatus: {
                      value: { ...updatedDynamicStatus },
                      userStatus: tempFilters,
                    },
                  });
                }}
              />
              {!isRoleKAM && !isRoleMGR && !isRoleVIEW ? (
                <Dropdown
                  classes={{
                    paper: classes.tunePaper,
                  }}
                  disablePortal={false}
                  remainOpen
                  isMultiSelect
                  placement="bottom-end"
                  options={Object.keys(state.dynamicColumns).map(
                    (key) => state.dynamicColumns[key]
                  )}
                  customToggle={({ anchorRef, onClick }) => (
                    <Tooltip title="Show/Hide column(s)" placement="top-start">
                      <IconButton
                        color="primary"
                        ref={anchorRef}
                        onClick={onClick}
                      >
                        <TuneIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  onChange={(options) => {
                    const isSelectAll = options.some(
                      (item) => item.value === "select_all"
                    );
                    const isCurrentSelectAll =
                      state.dynamicColumns?.select_all?.isSelected;

                    const isClickedOnSelectAll =
                      isSelectAll !== isCurrentSelectAll;

                    const isAllOptionSelected =
                      Object.keys(state.dynamicColumns).length - 1 ===
                      options.filter((item) => item.value !== "select_all")
                        .length;
                    const updatedDynamicColumns = Object.keys(
                      state.dynamicColumns
                    ).reduce((acc, key) => {
                      const isSelected = isClickedOnSelectAll
                        ? isSelectAll
                        : key === "select_all"
                        ? isAllOptionSelected
                        : !!options.some((item) => item.value === key);

                      return {
                        ...acc,
                        [key]: {
                          ...state.dynamicColumns[key],
                          isSelected,
                        },
                      };
                    }, {});
                    setPageConfig(PAGE_KEYS.CONTRACTORS, {
                      dynamicColumns: updatedDynamicColumns,
                    });
                    setState((prevState) => ({
                      ...prevState,
                      dynamicColumns: {
                        ...prevState.dynamicColumns,
                        ...updatedDynamicColumns,
                      },
                    }));
                  }}
                />
              ) : (
                <Dropdown
                  classes={{
                    paper: classes.tunePaper,
                  }}
                  disablePortal={false}
                  remainOpen
                  isMultiSelect
                  placement="bottom-end"
                  options={Object.keys(state.dynamicColumnsKAM).map(
                    (key) => state.dynamicColumnsKAM[key]
                  )}
                  customToggle={({ anchorRef, onClick }) => (
                    <Tooltip title="Show/Hide column(s)" placement="top-start">
                      <IconButton
                        color="primary"
                        ref={anchorRef}
                        onClick={onClick}
                      >
                        <TuneIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  onChange={(options) => {
                    const isSelectAll = options.some(
                      (item) => item.value === "select_all"
                    );
                    const isCurrentSelectAll =
                      state.dynamicColumnsKAM?.select_all?.isSelected;

                    const isClickedOnSelectAll =
                      isSelectAll !== isCurrentSelectAll;

                    const isAllOptionSelected =
                      Object.keys(state.dynamicColumnsKAM).length - 1 ===
                      options.filter((item) => item.value !== "select_all")
                        .length;
                    const updatedDynamicColumns = Object.keys(
                      state.dynamicColumnsKAM
                    ).reduce((acc, key) => {
                      const isSelected = isClickedOnSelectAll
                        ? isSelectAll
                        : key === "select_all"
                        ? isAllOptionSelected
                        : !!options.some((item) => item.value === key);

                      return {
                        ...acc,
                        [key]: {
                          ...state.dynamicColumnsKAM[key],
                          isSelected,
                        },
                      };
                    }, {});
                    setPageConfig(PAGE_KEYS.CONTRACTORS, {
                      dynamicColumnsKAM: updatedDynamicColumns,
                    });
                    setState((prevState) => ({
                      ...prevState,
                      dynamicColumnsKAM: {
                        ...prevState.dynamicColumnsKAM,
                        ...updatedDynamicColumns,
                      },
                    }));
                  }}
                />
              )}

              <Tooltip placement="top-start" title="Filter">
                <IconButton
                  color="primary"
                  className={clsx({
                    "bg-primary": !!state.pageFilters?.length,
                  })}
                  disabled={state.isFetching}
                  onClick={() => {
                    setState((prevState) => ({
                      ...prevState,
                      isFiltering: true,
                    }));
                  }}
                >
                  <FilterListIcon
                    className={clsx({
                      "color-white": !!state.pageFilters?.length,
                    })}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip placement="top-end" title="Reset All Filters">
                <IconButton
                  // disabled={pageConfig === undefined || pageConfig === null}
                  color="primary"
                  onClick={() => {
                    updatePageConfig("contractors");
                    window.location.reload();
                  }}
                >
                  <RotateLeftIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <Grid
            columns={
              !isRoleKAM && !isRoleMGR ? columnConfig : columnConfig.slice(1)
            }
            rows={state.entries.map((entry) => ({
              ...entry,
              className: !entry.is_active && classes.highlightDisable,
            }))}
            totalRows={state.totalEntries}
            actionBarConfig={null}
            isLoading={state.isFetching}
            onReady={(instance) => (gridHelper = instance)}
            pageSize={state.pageSize}
            pageNumber={state.pageNumber}
            order={state.order}
            orderBy={state.orderBy}
            onPageNumberChange={handlePageNumberChange}
            onPageSizeChange={handlePageSizeChange}
            onSelectionChange={handleSelectionChange}
            onSortChange={handleSortChange}
            hasSelection={false}
            rowEvents={[
              {
                type: "onDoubleClick",
                handler: (row) => {
                  if (!isRoleVIEW) {
                    setState((prevState) => ({
                      ...prevState,
                      rowBeingEdited: row,
                    }));
                  } else {
                    return false;
                  }
                },
              },
            ]}
            classes={{ container: appData.isTabletView && classes.gridPaper }}
          />
        </Paper>

        {(state.isNew || state.rowBeingEdited) && !isRoleKAM && !isRoleMGR && (
          <Form
            stateList={state.stateList}
            usersList={appData.users}
            locationList={state.locationList}
            isFetching={state.isFetching}
            documentStatusList={state.documentStatusList}
            fileRetentionStatusList={state.fileRetentionStatusList}
            agreementStatusList={state.agreementStatusList}
            isTabletView={appData.isTabletView}
            contractorTypeList={state.contractorTypeList}
            open={state.isNew || !!state.rowBeingEdited}
            entry={state.rowBeingEdited}
            isNew={state.isNew}
            vehicleTypeList={state.vehicleTypeList}
            customers={appDataList.customers}
            kamUsers={appDataList.kamUsers}
            pdxCompanyList={state.pdxCompanyList}
            settlementCompanyList={state.settlementCompanyList}
            onClose={(isSubmitted = false) => {
              setState((prevState) => ({
                ...prevState,
                rowBeingEdited: null,
                isNew: false,
              }));
              if (typeof isSubmitted === "boolean" && isSubmitted) {
                fetchEntries(
                  state.search,
                  state.filters,
                  state.pageSize,
                  state.pageNumber,
                  state.order,
                  state.orderBy,
                  state.pageFilters
                );
              }
            }}
            fetchNewEntry={() => fetchNewEntries()}
            fetchEntries={() => {
              fetchEntries(
                state.search,
                state.filters,
                state.pageSize,
                state.pageNumber,
                state.order,
                state.orderBy,
                state.pageFilters
              );
            }}
          />
        )}
        {state.rowBeingEdited && (isRoleKAM || isRoleMGR) && (
          <IcForm
            open={!!state.rowBeingEdited}
            isTabletView={appData.isTabletView}
            isFetching={state.isFetching}
            entry={state.rowBeingEdited}
            usersList={appData.users}
            onClose={() => {
              setState((prevState) => ({
                ...prevState,
                rowBeingEdited: null,
              }));
            }}
          />
        )}
        {!!state.markActiveInactiveIds && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
            }}
            open={!!state.markActiveInactiveIds}
            contentText={`Are you sure you want to mark the independent contractor ${
              state.markActiveInactiveIds?.status ? "inactive" : "active"
            }?`}
            onConfirm={() =>
              handleMarkActiveInactive(state.markActiveInactiveIds, true)
            }
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                markActiveInactiveIds: null,
              }))
            }
            isConfirmDisabled={state.isLoading}
            positiveActionLabel={
              <>
                Mark{" "}
                {state.markActiveInactiveIds?.status ? "inactive" : "active"}
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}
        {state.isFiltering && (
          <FilterComponent
            isFiltering={state.isFiltering}
            filterConfig={filterConfig}
            appliedFilters={state.pageFilters}
            onFilterApply={handleFilterChange}
            onClose={() => {
              setState((prevState) => ({
                ...prevState,
                isFiltering: false,
              }));
            }}
          />
        )}
        {state.confirmInactive && (
          <ActionDialog
            open={state.confirmInactive}
            contentText={
              <div>
                Base roster exists for this IC for KAM(s){" "}
                {state.kamList.map((item) => item).toString()}
                . <br />
                These base roster(s) will also be mark as Inactive along with
                IC.
                <br />
                <br />
                Do you want to continue?
              </div>
            }
            onConfirm={() =>
              handleMarkActiveInactive(state.markActiveInactiveIds, true)
            }
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                markActiveInactiveIds: null,
                confirmInactive: false,
              }))
            }
            positiveActionLabel="Yes"
            negativeActionLabel="No"
          />
        )}
        {state.openForceSubmitDialog && (
          <ForceSubmitDialog
            open={state.openForceSubmitDialog}
            pendingItems={appData.pendingItems.map((date, index) => ({
              date,
              id: index,
            }))}
            forceSubmitReasonList={state.forceSubmitReasonList}
            onClose={handleClose}
            kamId={kamId}
            onForceSubmit={() => fetchList("pendingItems", kamId)}
          />
        )}
        {!!state.markFlagUnflagIds?.is_flagged && (
          <ActionDialog
            classes={{
              confirm: state.flagSubmitReason && "bg-primary",
            }}
            open={!!state.markFlagUnflagIds}
            contentText={
              <div>
                <Autocomplete
                  disableClearable
                  className={"mt-6"}
                  size="medium"
                  style={{ width: "400px" }}
                  options={state.flagSubmitReasonList.filter(
                    (item) =>
                      item.is_active &&
                      item.type &&
                      item.value !== "Drivers License Not Eligible"
                  )}
                  getOptionLabel={(option) => `${option.value}`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="IC Flag Reason"
                      placeholder="Add IC Flag Reason"
                      variant="outlined"
                    />
                  )}
                  onChange={(evt, value) => {
                    setState((prevState) => ({
                      ...prevState,
                      flagSubmitReason: value,
                    }));
                  }}
                />
              </div>
            }
            onConfirm={() => handleMarkFlagUnflag(state.markFlagUnflagIds)}
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                markFlagUnflagIds: null,
                flagSubmitReason: null,
              }))
            }
            isConfirmDisabled={state.isLoading || !state.flagSubmitReason}
            positiveActionLabel={
              <>
                Confirm
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn color-grey"
                  />
                )}
              </>
            }
          />
        )}
        {!!state.markCustionFlag?.is_cautious_flagged && (
          <ActionDialog
            classes={{
              confirm: state.flagSubmitReason && "bg-primary",
            }}
            open={!!state.markCustionFlag}
            contentText={
              <div>
                <Autocomplete
                  disableClearable
                  className={"mt-6"}
                  size="medium"
                  style={{ width: "400px", height: 70 }}
                  options={state.cautionFlagSubmitReasonList.filter(
                    (item) =>
                      item.is_active &&
                      item.type &&
                      item.value !== "Ic Watchlist Checked"
                  )}
                  getOptionLabel={(option) => `${option.value}`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="IC Caution Flag Reason"
                      placeholder="Add IC Caution Flag Reason"
                      variant="outlined"
                    />
                  )}
                  onChange={(evt, value) => {
                    setState((prevState) => ({
                      ...prevState,
                      flagSubmitReason: value,
                    }));
                  }}
                />
              </div>
            }
            onConfirm={() => handleMarkFlagUnflag(state.markCustionFlag)}
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                markCustionFlag: null,
                flagSubmitReason: null,
              }))
            }
            isConfirmDisabled={state.isLoading || !state.flagSubmitReason}
            positiveActionLabel={
              <>
                Confirm
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn color-grey"
                  />
                )}
              </>
            }
          />
        )}
        {state.openMapsDialog && (
          <Maps
            allLocation={
              state.allLocation.length > 0
                ? state.allLocation
                    .filter((location) => location.id === state.icid)
                    .flatMap((address) => address.addresses)
                    .flatMap((address) => {
                      if (!address) return [];
                      return JSON.parse(address);
                    })
                    .filter(
                      (address) => address.address_id === state.openMapsDialog
                    )
                : []
            }
            isTabletView={appData.isTabletView}
            getAllLocations={getAllLocations}
            icid={state.icid}
            ic_name={state.ic_name}
            onClose={async (isSubmitted = false) => {
              setState((prevState) => ({
                ...prevState,
                openMapsDialog: false,
              }));
              if (typeof isSubmitted === "boolean" && isSubmitted) {
                await (() => {
                  getAllLocations();
                  fetchEntries(
                    state.search,
                    state.filters,
                    state.pageSize,
                    state.pageNumber,
                    state.order,
                    state.orderBy,
                    state.pageFilters
                  );
                })();
              }
            }}
          />
        )}
        {state.uncheckWatchlistFlag && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
            }}
            open={!!state.uncheckWatchlistFlag}
            contentText={
              "First, please uncheck the watchlist of this IC from documentation tab to change the flag type/reason."
            }
            onConfirm={() =>
              setState((prevState) => ({
                ...prevState,
                uncheckWatchlistFlag: false,
              }))
            }
            onClose={() => {
              setState((prevState) => ({
                ...prevState,
                uncheckWatchlistFlag: false,
              }));
            }}
          />
        )}
        {state.uncheckRedFlag && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
            }}
            open={!!state.uncheckRedFlag}
            contentText={
              "First, kindly update the status for the driver's license eligibility of this IC from the documentation tab to modify the flag type/reason."
            }
            onConfirm={() =>
              setState((prevState) => ({
                ...prevState,
                uncheckRedFlag: false,
              }))
            }
            onClose={() => {
              setState((prevState) => ({
                ...prevState,
                uncheckRedFlag: false,
              }));
            }}
          />
        )}
        {state.removeRedFlagToNone && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
            }}
            open={!!state.removeRedFlagToNone}
            contentText={
              "First, kindly mark the IC as 'None' in order to mark the caution flag."
            }
            onConfirm={() =>
              setState((prevState) => ({
                ...prevState,
                removeRedFlagToNone: false,
              }))
            }
            onClose={() => {
              setState((prevState) => ({
                ...prevState,
                removeRedFlagToNone: false,
              }));
            }}
          />
        )}
        {state.openAddressDialog && (
          <Dialog
            open={true}
            onClose={handleEditAddressClose}
            classes={{ paper: classes.addressListPaper }}
          >
            <Dialog.Title hasClose>IC Address List</Dialog.Title>
            <Dialog.Content>
              <>
                {/* <Button
                  className="mb-4"
                  variant="contained"
                  color="primary"
                  onClick={handleAddNewLocation}
                  disabled
                >
                  Add New Address
                </Button> */}
                <Grid
                  columns={mapColumnConfig}
                  rows={state.allLocation
                    ?.filter((location) => location?.id === state.icid)
                    ?.flatMap((location) => {
                      if (!location?.addresses) return [];
                      return JSON.parse(location.addresses);
                    })}
                  actionBarConfig={null}
                  isLoading={state.isAddressListLoading}
                  hasSelection={false}
                  hasPagination={false}
                  onReady={(instance) => (gridHelper = instance)}
                  classes={{
                    container: classes.addressGridridPaper,
                    noRecordsImg: classes.noRecordsImg,
                  }}
                />
              </>
            </Dialog.Content>
            <Dialog.Actions>
              <div className="d-flex f-align-center f-justify-end p-4">
                <Button
                  className="ml-2"
                  variant="contained"
                  color="primary"
                  onClick={handleEditAddressClose}
                >
                  Close
                </Button>
              </div>
            </Dialog.Actions>
          </Dialog>
        )}
        {state.deleteAddress && (
          <ActionDialog
            classes={{
              confirm: "bg-danger",
              paper: classes.deleteAddress,
            }}
            open={!!state.deleteAddress}
            contentText={
              <Typography variant="body2" className="mt-2 text-bold">
                Are you sure you want to delete this address?
              </Typography>
            }
            onConfirm={() => {
              deleteLocation();
              getAllLocations().then(() =>
                fetchEntries(
                  state.search,
                  state.filters,
                  state.pageSize,
                  state.pageNumber,
                  state.order,
                  state.orderBy,
                  state.pageFilters
                )
              );
            }}
            onCancel={() => {
              setState((prevState) => ({
                ...prevState,
                deleteAddress: false,
              }));
            }}
            isConfirmDisabled={state.isAddressListLoading}
            positiveActionLabel={
              <>
                DELETE
                {state.isAddressListLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
            negativeActionLabel={<>CANCEL</>}
          />
        )}
        {!!state.updateFlagReason && (
          <ActionDialog
            classes={{
              confirm: state.updateFlagReason && "bg-primary",
            }}
            open={!!state.updateFlagReason}
            contentText={
              <div>
                <Autocomplete
                  disableClearable
                  className={"mt-6"}
                  size="medium"
                  style={{ width: "400px", height: 82 }}
                  options={
                    !!state.flaggedType
                      ? state.flagSubmitReasonList.filter(
                          (item) =>
                            item.is_active &&
                            item.type &&
                            item.type &&
                            item.value !== "Drivers License Not Eligible"
                        )
                      : state.cautionFlagSubmitReasonList.filter(
                          (item) =>
                            item.is_active &&
                            item.type &&
                            item.value !== "Ic Watchlist Checked"
                        )
                  }
                  value={state.updateFlagReasonValue}
                  getOptionLabel={(option) => `${option.value}`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={
                        !!state.flaggedType
                          ? "Update IC Flag Reason"
                          : "Update IC Caution Flag Reason"
                      }
                      placeholder={
                        !!state.flaggedType
                          ? "Add IC Flag Reason"
                          : "Add IC Caution Flag Reason"
                      }
                      variant="outlined"
                    />
                  )}
                  onChange={(evt, value) => {
                    setState((prevState) => ({
                      ...prevState,
                      updateFlagReasonValue: value,
                    }));
                  }}
                />
              </div>
            }
            onConfirm={() =>
              updateFlagReason(
                state.ic_id,
                state.updateFlagReasonValue,
                !!state.flaggedType ? "is_flagged" : "is_cautious_flagged"
              )
            }
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                updateFlagReasonValue: null,
                ic_id: null,
                updateFlagReason: false,
              }))
            }
            isConfirmDisabled={state.isLoading || !state.updateFlagReasonValue}
            positiveActionLabel={
              <>
                Confirm
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn color-grey"
                  />
                )}
              </>
            }
          />
        )}
      </div>
    </div>
  );
};

export default ViewContractor;
