import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import Service from "../service";
import {
  Grid as DataGrid,
  Dropdown,
  Dialog,
  Datepicker,
  FilterComponent,
  ActionDialog,
} from "shared/components";
import {
  getPageConfig,
  setPageConfig,
  updatePagination,
  updateLayout,
  getUTCDateString,
  updatePageConfig,
  queryStringBuilderNew,
  getFormattedTime,
  getDateString,
  getTokenData,
  exportBase64ToFile,
} from "utils";
import Button from "@material-ui/core/Button";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { PAGE_KEYS, noop } from "shared/constants";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { useLocation } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TuneIcon from "@material-ui/icons/Tune";
import useStyles from "./style";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FilterListIcon from "@material-ui/icons/FilterList";
import CollapsibleGrid from "../grid";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import { AppContext } from "shared";
import { SharedService } from "modules/shared";
import { ROLE } from "modules/shared/constants";

let gridHelper = null;

const defaultState = {
  entries: [],
  userEntries: [],
  detailedEntries: [],
  pageFilters: [],
  isDownloadLoading: false,
  isFiltering: false,
  totalEntries: 0,
  reportExportedPopup: false,
  selectedKAM: [],
  customer: [],
  isFetching: false,
  viewHistory: false,
  selectedRows: [],
  metadata: "",
  isLoading: false,
  pageSize: 100,
  pageNumber: 1,
  order: null,
  orderBy: null,
  search: "",
  dateFilterType: "single",
  isFetchingList: {
    users: false,
    customer: false,
  },
  filters: {
    kam: [],
    customer: [],
    endDate: [],
    startDate: [],
  },
  dynamicColumns: {
    select_all: { label: "Select All", value: "select_all", isSelected: true },
    ScheduleDate: {
      label: "Schedule Date",
      value: "ScheduleDate",
      isSelected: true,
    },
    Kam: {
      label: "Kam",
      value: "Kam",
      isSelected: true,
    },
    customer_name: {
      label: "Customer",
      value: "customer_name",
      isSelected: true,
    },
    route_name: {
      label: "Route",
      value: "route_name",
      isSelected: true,
    },
  },
};

const AuditHistory = ({ match, history }) => {
  const { appData, updateContextData } = useContext(AppContext);
  const userData = getTokenData();
  const isRoleKAM = (userData?.role || "").toLowerCase() === ROLE.KAM;
  const pageConfig = getPageConfig(
    PAGE_KEYS.AUDIT_HISTORY || PAGE_KEYS.DETALIED_AUDIT_HISTORY
  );
  const location = useLocation();
  const data = location.state || {};
  const classes = useStyles();
  const appDataList = useMemo(() => {
    const users = appData.users.reduce(
      (acc, user) => {
        const role = (user.role || "").toLowerCase();
        acc[role] && acc[role].push(user);
        return acc;
      },
      {
        kam: [],
      }
    );
    const customers = appData.customers.filter(
      (customer) => customer.is_active
    );
    return {
      kamUsers: users.kam,
      customers,
    };
  }, [appData]);
  const [state, setState] = useState({
    ...defaultState,
    pageSize: pageConfig?.pageSize || defaultState.pageSize,
    pageNumber: pageConfig?.pageNumber || defaultState.pageNumber,
    dynamicColumns: pageConfig?.dynamicColumns || defaultState.dynamicColumns,
    selectedKAM: isRoleKAM
      ? [userData]
      : history.location.state === undefined
      ? defaultState.selectedKAM
      : [history.location.state?.kamFilter],
    dateFilterType:
      history.location.state === undefined
        ? defaultState.dateFilterType
        : history.location.state?.dateFilterType
        ? "multiple"
        : "single",
    // pageFilters:
    //   (pageConfig && pageConfig?.filters) || defaultState.pageFilters,
    // selectedKAM:
    //   (pageConfig && pageConfig?.kamFilter?.value) || defaultState.selectedKAM,
    // customer:
    //   (pageConfig && pageConfig?.customerFilter?.value) ||
    //   defaultState.customer,dateFilterType
    filters: {
      ...defaultState.filters,
      kam: isRoleKAM
        ? [
            {
              field: "kam_id",
              type: "=",
              value: userData?.id,
            },
          ]
        : history.location.state === undefined
        ? []
        : [
            {
              field: "kam_id",
              type: "=",
              value: history.location.state?.kamFilter?.id,
            },
          ],
      startDate:
        history.location.state === undefined
          ? [
              {
                field: "schedule_date",
                type: "=",
                value: getDateString(new Date()),
              },
            ]
          : [
              {
                field: "schedule_date",
                type: "=",
                value: history.location.state?.startDate,
              },
            ],
      endDate:
        history.location.state?.endDate === undefined
          ? getDateString(new Date())
          : history.location.state?.endDate,
      // kam: (pageConfig && pageConfig?.kamFilter?.kam) || [],
      // customer: (pageConfig && pageConfig?.customerFilter?.customer) || [],
      // startDate: [
      //   {
      //     field: "schedule_date",
      //     type: "=",
      //     value:
      //       (pageConfig && pageConfig?.startDateFilter) ||
      //       getDateString(new Date()),
      //   },
      // ],
      // endDate:
      //   (pageConfig && pageConfig?.endDateFilter) || getDateString(new Date()),
    },
  });

  const fetchEntries = useCallback(
    async (
      search,
      filters,
      pageSize,
      pageNumber,
      order,
      orderBy,
      pageFilters = [],
      hasLoader = true
    ) => {
      let filtersArr = [
        ...pageFilters.map((filter) => ({
          ...filter,
          value: filter?.value?.value || filter?.value,
        })),
      ];
      if (
        state.dateFilterType === "single" &&
        filters.startDate.length &&
        filters.startDate[0].value
      ) {
        filtersArr = [...filtersArr, ...filters.startDate];
      }

      if (filters.kam.length && filters.kam[0].value) {
        filtersArr = [...filtersArr, ...filters.kam];
      }
      if (filters.customer.length && filters.customer[0].value) {
        filtersArr = [...filtersArr, ...filters.customer];
      }
      let queryString = queryStringBuilderNew(
        pageSize,
        pageSize * (pageNumber - 1),
        [],
        filtersArr,
        { field: orderBy, order },
        search && search.length > 0
      );
      if (
        state.dateFilterType === "multiple" &&
        filters.startDate.length &&
        filters.startDate[0].value
      ) {
        queryString += `&filter[where][schedule_date][gte]=${filters.startDate[0].value}`;
      }
      if (
        state.dateFilterType === "multiple" &&
        filters.endDate.length &&
        (filters.endDate[0].value || filters.endDate)
      ) {
        queryString += `&filter[where][schedule_date][lte]=${
          filters.endDate[0].value || filters.endDate
        }`;
      }
      setState((prevState) => ({
        ...prevState,
        isFetching: hasLoader,
      }));
      const { data, error } = await Service.getAuditHistory(queryString);
      if (error) {
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0].message : error.message
        );
      }

      setState((prevState) => ({
        ...prevState,
        data,
        isFetching: false,
        entries: data.rows || defaultState.entries,
        totalEntries: data.count,
      }));

      return data;
    },
    [state.dateFilterType]
  );

  const handleExport = useCallback(
    async (
      search,
      filters,
      pageSize,
      pageNumber,
      order,
      orderBy,
      pageFilters = [],
      hasLoader = true
    ) => {
      let filtersArr = [
        ...pageFilters.map((filter) => ({
          ...filter,
          value: filter?.value?.value || filter?.value,
        })),
      ];
      if (filters.kam.length && filters.kam[0].value) {
        filtersArr = [...filtersArr, ...filters.kam];
      }
      if (filters.customer.length && filters.customer[0].value) {
        filtersArr = [...filtersArr, ...filters.customer];
      }
      let queryString = queryStringBuilderNew(
        pageSize,
        pageSize * (pageNumber - 1),
        [],
        filtersArr,
        { field: orderBy, order },
        search && search.length > 0
      );
      if (
        state.dateFilterType === "single" &&
        filters.startDate.length &&
        filters.startDate[0].value
      ) {
        queryString += `&fromDate=${filters.startDate[0].value}`;
      }
      if (
        state.dateFilterType === "single" &&
        filters.startDate.length &&
        filters.startDate[0].value
      ) {
        queryString += `&toDate=${filters.startDate[0].value}`;
      }
      if (
        state.dateFilterType === "multiple" &&
        filters.startDate.length &&
        filters.startDate[0].value
      ) {
        queryString += `&fromDate=${filters.startDate[0].value}`;
      }
      if (
        state.dateFilterType === "multiple" &&
        filters.endDate.length &&
        (filters.endDate[0].value || filters.endDate)
      ) {
        queryString += `&toDate=${filters.endDate[0].value || filters.endDate}`;
      }
      setState((prevState) => ({
        ...prevState,
        isExporting: hasLoader,
      }));
      const { error } = await Service.auditHistoryDownload(queryString);
      if (error) {
        setState((prevState) => ({
          ...prevState,
          isExporting: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0].message : error.message
        );
      }

      setState((prevState) => ({
        ...prevState,
        isExporting: false,
        reportExportedPopup: true,
      }));
    },
    [state.dateFilterType]
  );

  const fetchUserEntries = useCallback(async () => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    const { data, error } = await SharedService.getUsersList();
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }
    setState((prevState) => ({
      ...prevState,
      userEntries: data?.users || defaultState.userEntries,
      isLoading: false,
    }));
  }, []);

  useEffect(() => {
    fetchUserEntries();
  }, []);

  const fetchDetailedEntries = useCallback(
    async (recordId, hasLoader = true) => {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
        isFetching: hasLoader,
      }));

      const { data, error } = await Service.getHistoryDetailsByID(recordId);
      if (error) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          isFetching: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0].message : error.message
        );
      }
      const parsedData = JSON.parse(data.logs);

      const kamUserMap = new Map();
      for (const stateEntry of state.entries) {
        kamUserMap.set(stateEntry.row_id, {
          kamUser: stateEntry.kamUser,
          routeDetails: stateEntry.routeDetails,
          schedule_date: stateEntry.schedule_date,
        });
      }

      for (const parsedDataEntry of parsedData) {
        const kamID = data.row_id;
        if (kamUserMap.has(kamID)) {
          const { kamUser, routeDetails, schedule_date } =
            kamUserMap.get(kamID);
          parsedDataEntry.kamUser = kamUser;
          parsedDataEntry.routeDetails = routeDetails;
          parsedDataEntry.schedule_date = schedule_date;
        }
      }

      setState((prevState) => ({
        ...prevState,
        data,
        isFetching: false,
        isLoading: false,
        detailedEntries: parsedData || defaultState.detailedEntries,
      }));

      return data;
    },
    [state.entries]
  );

  const handleDownload = async (rowId) => {
    setState((prevState) => ({
      ...prevState,
      isDownloadLoading: true,
    }));

    const { data, error } = await Service.auditHistoryDownload(rowId);
    if (error) {
      toast.error(Array.isArray(error) ? error[0].message : error.message);
      setState((prevState) => ({
        ...prevState,
        isDownloadLoading: false,
      }));
    } else {
      toast.success("Audit history downloaded successfully.");
      exportBase64ToFile(data, "Audit History");
    }

    setState((prevState) => ({
      ...prevState,
      isDownloadLoading: false,
    }));
  };

  const kamOptions = appDataList.kamUsers?.sort(
    (a, b) => b.is_active - a.is_active
  );

  const fetchList = async (listType = "users", callback = noop, ...params) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    switch (listType) {
      case "customers":
        serviceMethod = "getCustomerList";
        responseKey = "customers";
        break;
      case "users":
        serviceMethod = "getUsersList";
        responseKey = "users";
        break;
      case "pendingItems":
        serviceMethod = "getPendingItems";
        responseKey = "pendingItems";
        break;
      default:
        serviceMethod = "getUsersList";
        responseKey = "users";
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];
    if (listType === "customers") {
      listData.map((item = {}) => {
        (item.customer_branches || []).map((branch = {}) => {
          (branch.routes || []).sort((a, b) =>
            Intl.Collator().compare(a.name, b.name)
          );
        });
      });
    }
    updateContextData(listType, data[responseKey] || []);

    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
    callback(data[responseKey]);
  };

  useEffect(() => {
    fetchList("users");
  }, [match.params.path]);

  useEffect(() => {
    fetchList("customers", noop);
  }, []);

  useEffect(() => {
    fetchEntries(
      state.search,
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    ).then((data) => {
      updatePagination(
        data?.count,
        { pageNumber: state.pageNumber, pageSize: state.pageSize },
        (pageNumber) => {
          setState((prevState) => ({ ...prevState, pageNumber }));
        }
      );
    });
  }, [
    fetchEntries,
    state.filters,
    state.pageSize,
    state.pageNumber,
    state.order,
    state.orderBy,
    state.search,
    state.pageFilters,
  ]);

  useEffect(() => {
    setTimeout(updateLayout, 1000);
  }, [state.entries, state.detailedEntries]);

  const handlePageNumberChange = useCallback((pageNumber) => {
    setPageConfig(PAGE_KEYS.AUDIT_HISTORY, {
      pageNumber,
    });
    setState((prevState) => ({
      ...prevState,
      pageNumber,
      selectedRows: defaultState.selectedRows,
    }));
  }, []);

  const handleFilterChange = useCallback((pageFilters = []) => {
    setState((prevState) => ({
      ...prevState,
      pageFilters,
      selectedRows: [],
    }));
    setPageConfig(PAGE_KEYS.AUDIT_HISTORY, {
      filters: pageFilters,
    });
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setPageConfig(PAGE_KEYS.AUDIT_HISTORY || PAGE_KEYS.DETALIED_AUDIT_HISTORY, {
      pageSize,
    });
    setState((prevState) => ({
      ...prevState,
      pageSize,
      pageNumber: defaultState.pageNumber,
      selectedRows: defaultState.selectedRows,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleSelectionChange = useCallback((selectedRows) => {
    setState((prevState) => ({
      ...prevState,
      selectedRows,
    }));
  }, []);

  const handleSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      order: order,
      orderBy: fieldObj.field || fieldObj.fieldName,
      selectedRows: defaultState.selectedRows,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const moreActions = (row) => {
    return (
      <div className="d-flex f-align-center">
        <Tooltip title="View history in details" placement="top-start">
          <VisibilityIcon
            className={clsx("ml-2 mr-2 c-pointer", classes.VisibilityIconColor)}
            onClick={() => {
              fetchDetailedEntries(row.id);
              setState((prevState) => ({ ...prevState, viewHistory: row }));
            }}
          />
        </Tooltip>
      </div>
    );
  };

  const filterConfig = useMemo(
    () => [
      {
        field: "route_name",
        fieldToDisplay: "Route",
        operatorType: "string",
        options: appDataList?.customers
          .map((customer) => customer?.customer_branches)
          .flat()
          .map((branches) => branches?.routes)
          .flat()
          .map((route) => ({ label: route.name, value: route.name }))
          .sort((a, b) => Intl.Collator().compare(a.label, b.label)),
      },
    ],
    [appDataList?.customers]
  );

  const columnConfig = [
    {
      label: "View details",
      id: "col-edit",
      noResize: true,
      render: (row) => moreActions(row),
    },
    {
      isHidden: !state.dynamicColumns?.ScheduleDate?.isSelected,
      headerClassName: classes.dcnHistoryCloumnTitle,
      id: "schedule_date",
      label: "Schedule Date",
      field: "schedule_date",
      canSort: true,
      render: (row) => {
        const scheduleDate = getUTCDateString(row.schedule_date);
        return (
          <Tooltip title={scheduleDate || ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {scheduleDate || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    // {
    //   isHidden: !state.dynamicColumns?.Kam?.isSelected,
    //   headerClassName: classes.dcnHistoryCloumnTitle,
    //   id: "kam_name",
    //   label: "Kam",
    //   field: "kam_name",
    //   hasEllipses: true,
    //   canSort: true,
    //   render: (row) => {
    //     const kamDetails = `${row.kamUser.first_name || ""} ${
    //       row.kamUser.last_name || ""
    //     } - ${row.kamUser.email || ""}-(${row.kamUser.username || ""})`;
    //     return (
    //       <Tooltip title={kamDetails ?? ""} placement="top-start">
    //         <Typography variant="body2" noWrap>
    //           {kamDetails || "-"}
    //         </Typography>
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      isHidden: !state.dynamicColumns?.customer_name?.isSelected,
      headerClassName: classes.dcnHistoryCloumnTitle,
      id: "customer_name",
      label: "Customer",
      field: "customer_name",
      hasEllipses: true,
      canSort: true,
      render: (row) => {
        const customer = `${
          row.routeDetails?.customer_branch.customer?.name ?? ""
        }`;
        return (
          <Tooltip title={customer ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {customer || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.route_name?.isSelected,
      headerClassName: classes.dcnHistoryCloumnTitle,
      id: "route_name",
      label: "Route",
      field: "route_name",
      hasEllipses: true,
      canSort: true,
      render: (row) => {
        const route = `${row.routeDetails?.name ?? ""}`;
        return (
          <Tooltip title={route ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {route || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  if (!isRoleKAM) {
    const kamObject = {
      isHidden: !state.dynamicColumns?.Kam?.isSelected,
      headerClassName: classes.dcnHistoryCloumnTitle,
      id: "kam_name",
      label: "Kam",
      field: "kam_name",
      hasEllipses: true,
      canSort: true,
      render: (row) => {
        const kamDetails = `${row.kamUser.first_name || ""} ${
          row.kamUser.last_name || ""
        } - ${row.kamUser.email || ""}-(${row.kamUser.username || ""})`;
        return (
          <Tooltip title={kamDetails ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {kamDetails || "-"}
            </Typography>
          </Tooltip>
        );
      },
    };
    columnConfig.splice(2, 0, kamObject);
  }

  const detailedColumnConfig = [
    [
      {
        id: "action",
        label: "Action",
        fieldName: "action",
        headerClassName: classes.wrapTableTitle,
        canSort: true,
        render: (row) => (
          <Tooltip title={row.action ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.action || "-"}
            </Typography>
          </Tooltip>
        ),
      },
      {
        id: "performed_at",
        label: "Performed At",
        fieldName: "performed_at",
        headerClassName: classes.wrapTableTitle,
        canSort: true,
        render: (row) => {
          const performedAt = `${
            !!row.performed_at
              ? `${getDateString(row.performed_at)} ${getFormattedTime(
                  new Date(row.performed_at)
                )}`
              : "-"
          }`;
          return (
            <Tooltip title={performedAt || ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {performedAt}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        id: "performed_by",
        label: "Performed By",
        fieldName: "performed_by",
        headerClassName: classes.wrapTableTitle,
        canSort: true,
        render: (row) => {
          const matchingEntry = state.userEntries?.find(
            (entry) => entry?.id == row.performed_by
          );
          const user = `${matchingEntry?.first_name || ""} ${
            matchingEntry?.last_name || ""
          } (${matchingEntry?.username || ""})`;
          return (
            <Tooltip title={user ?? ""} placement="top-start">
              <Typography variant="body2" noWrap>
                {user || "-"}
              </Typography>
            </Tooltip>
          );
        },
      },
    ],
    [
      {
        id: "changed_columns",
        label: "Field Name",
        fieldName: "changed_columns",
        headerClassName: classes.wrapTableTitle,
        render: (row) => {
          const fieldKeys = Object.keys(row.changed_columns);
          return (
            <>
              {fieldKeys.map((key) => (
                <Tooltip key={key} title={key} placement="top-start">
                  <Typography variant="body2" noWrap>
                    {key}
                  </Typography>
                </Tooltip>
              ))}
            </>
          );
        },
      },
      {
        id: "previous_value",
        label: "Previous Value",
        fieldName: "previous_value",
        headerClassName: classes.wrapTableTitle,
        render: (row) => {
          const fieldKeys = Object.keys(row.changed_columns);
          return (
            <>
              {fieldKeys.map((key) => {
                const previousValue =
                  row.changed_columns[key]?.previous_value || "-";
                return (
                  <Tooltip
                    key={key}
                    title={previousValue}
                    placement="top-start"
                  >
                    <Typography variant="body2" noWrap>
                      {previousValue}
                    </Typography>
                  </Tooltip>
                );
              })}
            </>
          );
        },
      },
      {
        id: "new_value",
        label: "Changed Value",
        fieldName: "new_value",
        headerClassName: classes.wrapTableTitle,
        render: (row) => {
          const fieldKeys = Object.keys(row.changed_columns);
          return (
            <>
              {fieldKeys.map((key) => {
                const newValue = row.changed_columns[key]?.new_value || "-";
                return (
                  <Tooltip key={key} title={newValue} placement="top-start">
                    <Typography variant="body2" noWrap>
                      {newValue}
                    </Typography>
                  </Tooltip>
                );
              })}
            </>
          );
        },
      },
    ],
  ];

  const handleCloseViewHistory = () => {
    setState((prevState) => ({
      ...prevState,
      viewHistory: false,
    }));
  };

  const renderDropdown = (columnState, columns, pageKey) => (
    <div className="d-flex f-justify-center">
      <Dropdown
        classes={{
          paper: classes.tunePaper,
        }}
        disablePortal={false}
        remainOpen
        isMultiSelect
        placement="bottom-end"
        options={Object.keys(columns).map((key) => columns[key])}
        customToggle={({ anchorRef, onClick }) => (
          <Tooltip title="Show/Hide column(s)" placement="top-start">
            <IconButton color="primary" ref={anchorRef} onClick={onClick}>
              <TuneIcon />
            </IconButton>
          </Tooltip>
        )}
        onChange={(options) => {
          const isSelectAll = options.some(
            (item) => item.value === "select_all"
          );
          const isCurrentSelectAll = columns?.select_all?.isSelected;
          const isClickedOnSelectAll = isSelectAll !== isCurrentSelectAll;
          const isAllOptionSelected =
            Object.keys(columns).length - 1 ===
            options.filter((item) => item.value !== "select_all").length;
          const updatedDynamicColumns = Object.keys(columns).reduce(
            (acc, key) => {
              const isSelected = isClickedOnSelectAll
                ? isSelectAll
                : key === "select_all"
                ? isAllOptionSelected
                : !!options.some((item) => item.value === key);
              return {
                ...acc,
                [key]: {
                  ...columns[key],
                  isSelected,
                },
              };
            },
            {}
          );
          setPageConfig(pageKey, {
            [columnState]: updatedDynamicColumns,
          });
          setState((prevState) => ({
            ...prevState,
            [columnState]: {
              ...prevState[columnState],
              ...updatedDynamicColumns,
            },
          }));
        }}
      />
    </div>
  );

  const filteredDynamicColumns = isRoleKAM
    ? Object.fromEntries(
        Object.entries(state.dynamicColumns).filter(([key]) => key !== "Kam")
      )
    : state.dynamicColumns;

  return (
    <>
      <div id="numbers-page-wrapper">
        <Grid container className="mr-5">
          <Grid item xs={12} className={classes.fixedHeader}>
            <div className="mb-2">
              <Typography variant="h4" color="primary" className="ml-2">
                Audit History
                <Button
                  startIcon={<ArrowBackIcon />}
                  style={{ float: "right" }}
                  className="mt-1 mr-2"
                  variant="contained"
                  color="primary"
                  onClick={() => history.push("/home")}
                >
                  Back To Home
                </Button>
                <Button
                  startIcon={<ArrowBackIcon />}
                  style={{ float: "right" }}
                  className="mt-1 mr-2"
                  variant="contained"
                  color="primary"
                  onClick={() => history.push("/daily-schedule")}
                >
                  Back To Daily Schedule
                </Button>
              </Typography>
              <Typography variant="body2" style={{ marginLeft: "10px" }}>
                View Audit History.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} className="ml-1">
            <div className="d-flex f-align-center f-wrap pr-8">
              <FormControl>
                <RadioGroup
                  row
                  value={state.dateFilterType}
                  onChange={(evt) => {
                    const { value } = evt.target;
                    const lastWeekDate = new Date();
                    const lastWeekDay = lastWeekDate.getDate() - 6;
                    lastWeekDate.setDate(lastWeekDay);
                    setState((prevState) => ({
                      ...prevState,
                      dateFilterType: value,
                      areAllSelected: false,
                      selectedRows: [],
                      filters: {
                        ...prevState.filters,
                        startDate: [
                          {
                            field: "schedule_date",
                            type: value === "single" ? "=" : "gte",
                            value: getDateString(
                              value === "single" ? new Date() : lastWeekDate
                            ),
                          },
                        ],
                        endDate:
                          value === "single"
                            ? []
                            : [
                                {
                                  field: "schedule_date",
                                  type: "lte",
                                  value: getDateString(new Date()),
                                },
                              ],
                      },
                      pageNumber: defaultState.pageNumber,
                    }));
                    // setPageConfig(PAGE_KEYS.AUDIT_HISTORY, {
                    //   dateFilterType: value,
                    //   startDateFilter: getDateString(
                    //     value === "single" ? new Date() : lastWeekDate
                    //   ),
                    //   endDateFilter: getDateString(new Date()),
                    // });
                    if (gridHelper) {
                      gridHelper.resetSelection();
                      gridHelper.resetAllSelection();
                    }
                  }}
                >
                  <FormControlLabel
                    value="single"
                    control={<Radio size="small" color="primary" />}
                    label="Specific date"
                  />
                  <FormControlLabel
                    value="multiple"
                    control={<Radio size="small" color="primary" />}
                    label="Date range"
                  />
                </RadioGroup>
              </FormControl>
              <div className="d-flex mt-3">
                <Datepicker
                  mask
                  preventClear
                  selected={
                    state.filters.startDate.length &&
                    state.filters.startDate[0]?.value
                  }
                  label={
                    state.dateFilterType === "single" ? "Date" : "Start date"
                  }
                  classes={{
                    input: {
                      root: clsx("mr-4", classes.datepickerWrapper),
                    },
                  }}
                  maxDate={
                    state.filters.endDate[0]?.value
                      ? new Date(state.filters.endDate[0]?.value)
                      : new Date()
                  }
                  onChange={(startDate) => {
                    setState((prevState) => ({
                      ...prevState,
                      areAllSelected: false,
                      selectedRows: [],
                      filters: {
                        ...prevState.filters,
                        startDate: [
                          {
                            field: "schedule_date",
                            type:
                              state.dateFilterType === "single" ? "=" : "gte",
                            value: getDateString(startDate),
                          },
                        ],
                        daily_schedule_id: [],
                      },
                    }));
                    // setPageConfig(PAGE_KEYS.AUDIT_HISTORY, {
                    //   startDateFilter: getDateString(startDate),
                    // });
                  }}
                />
                {state.dateFilterType === "multiple" && (
                  <Datepicker
                    mask
                    preventClear
                    selected={
                      state.filters?.endDate.length &&
                      (state.filters?.endDate[0]?.value ||
                        state.filters?.endDate)
                    }
                    label="End date"
                    classes={{
                      input: {
                        root: clsx("mr-4", classes.datepickerWrapper),
                      },
                    }}
                    maxDate={new Date()}
                    minDate={new Date(state.filters.startDate[0]?.value)}
                    onChange={(endDate) => {
                      setState((prevState) => ({
                        ...prevState,
                        areAllSelected: false,
                        selectedRows: [],
                        filters: {
                          ...prevState.filters,
                          endDate: [
                            {
                              field: "schedule_date",
                              type: "lte",
                              value: getDateString(endDate),
                              daily_schedule_id: [],
                            },
                          ],
                        },
                      }));
                      // setPageConfig(PAGE_KEYS.AUDIT_HISTORY, {
                      //   endDateFilter: getDateString(endDate),
                      // });
                    }}
                  />
                )}
              </div>

              {
                <>
                  <Autocomplete
                    classes={{
                      root: classes.kamInput,
                      inputRoot: classes.kamInputRoot,
                    }}
                    style={{ width: "244px", marginTop: 10 }}
                    size="small"
                    fullWidth
                    defaultValue={data.kam_id ? state.selectedKAM : undefined}
                    value={state.selectedKAM || []}
                    multiple
                    disabled={isRoleKAM}
                    options={kamOptions}
                    getOptionLabel={(option) =>
                      option.name ||
                      `${option.first_name || ""} ${option.last_name || ""} - ${
                        option.email || ""
                      }-(${option.username || ""})`
                    }
                    renderOption={({
                      first_name,
                      last_name,
                      email,
                      is_active,
                      username,
                    }) => (
                      <Typography
                        variant="body1"
                        className={clsx({
                          "color-text-disabled": !is_active,
                        })}
                      >{`${first_name || ""} ${
                        last_name || ""
                      }-${email}-(${username})`}</Typography>
                    )}
                    renderInput={(params) =>
                      state.isFetchingList.users ? (
                        <Skeleton variant="rect" width="100%" height="40px" />
                      ) : (
                        <TextField {...params} label="KAM" variant="outlined" />
                      )
                    }
                    onChange={(evt, value) => {
                      if (value.length === 0) {
                        // setPageConfig(PAGE_KEYS.AUDIT_HISTORY, {
                        //   kamFilter: null,
                        // });
                        return setState((prevState) => ({
                          ...prevState,
                          selectedKAM: value,
                          testId: "",
                          filters: {
                            ...prevState.filters,
                            kam: [{ field: "kam_id", type: "=", value: null }],
                          },
                        }));
                      }
                      const id = value?.map((item) => item.id);

                      setState((prevState) => ({
                        ...prevState,
                        selectedKAM: value,
                        testId: "",
                        filters: {
                          ...prevState.filters,
                          kam: [{ field: "kam_id", type: "=", value: id }],
                        },
                      }));
                      // setPageConfig(PAGE_KEYS.AUDIT_HISTORY, {
                      //   kamFilter: {
                      //     value,
                      //     kam: [{ field: "kam_id", type: "=", value: id }],
                      //   },
                      // });
                    }}
                  />
                  <Autocomplete
                    style={{
                      width: "244px",
                      marginTop: 10,
                      marginLeft: "14px",
                    }}
                    size="small"
                    fullWidth
                    classes={{
                      root: classes.kamInput,
                      inputRoot: classes.kamInputRoot,
                    }}
                    options={appDataList.customers}
                    value={state.customer}
                    multiple
                    getOptionLabel={(option) => `${option.name || ""}`}
                    renderInput={(params) =>
                      state.isFetchingList.customer ? (
                        <Skeleton variant="rect" width="100%" height="100%" />
                      ) : (
                        <TextField
                          {...params}
                          label="Customer"
                          variant="outlined"
                        />
                      )
                    }
                    onChange={(evt, value) => {
                      if (value.length === 0) {
                        // setPageConfig(PAGE_KEYS.AUDIT_HISTORY, {
                        //   customerFilter: null,
                        // });
                        return setState((prevState) => ({
                          ...prevState,
                          customer: value,
                          testId: "",
                          filters: {
                            ...prevState.filters,
                            customer: [],
                          },
                        }));
                      }
                      const customerName = value?.map((item) => item.name);
                      setState((prevState) => ({
                        ...prevState,
                        customer: value,
                        selectedRows: [],
                        filters: {
                          ...prevState.filters,
                          customer: [
                            {
                              field: "customer_name",
                              type: "=",
                              value: customerName,
                            },
                          ],
                        },
                      }));
                      // setPageConfig(PAGE_KEYS.AUDIT_HISTORY, {
                      //   customerFilter: {
                      //     value,
                      //     customer: [
                      //       {
                      //         field: "customer_name",
                      //         type: "=",
                      //         value: customerName,
                      //       },
                      //     ],
                      //   },
                      // });
                      gridHelper && gridHelper.resetSelection();
                    }}
                  />
                </>
              }
            </div>
          </Grid>
          <Grid item xs={12}>
            <Paper
              elevation={2}
              className={clsx("p-4 mt-10", classes.paperSpacing)}
            >
              <div
                className={clsx(
                  "d-flex f-align-center f-justify-end p-2 pl-4 pr-4",
                  classes.actionsWrapper
                )}
              >
                {" "}
                <div className="d-flex f-justify-end pb-2">
                  <Button
                    variant="contained"
                    color="primary"
                    className="ml-2 mr-2 mt-2"
                    startIcon={<CloudDownloadIcon />}
                    disabled={
                      state.isExporting ||
                      !state.totalEntries ||
                      state.isFetching
                    }
                    onClick={() =>
                      handleExport(
                        state.search,
                        state.filters,
                        state.pageSize,
                        state.pageNumber,
                        state.order,
                        state.orderBy,
                        state.pageFilters
                      )
                    }
                  >
                    Export To Email
                    {state.isExporting && (
                      <CircularProgress
                        size={24}
                        className={classes.progressBtn}
                      />
                    )}
                  </Button>
                </div>
                <div className="d-flex f-justify-center">
                  {renderDropdown(
                    "dynamicColumns",
                    filteredDynamicColumns,
                    PAGE_KEYS.AUDIT_HISTORY
                  )}
                </div>
                <Tooltip placement="top-start" title="Filter">
                  <IconButton
                    color="primary"
                    disabled={state.isFetching}
                    className={clsx({
                      "bg-primary": !!state.pageFilters?.length,
                    })}
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        isFiltering: true,
                      }));
                    }}
                  >
                    <FilterListIcon
                      className={clsx({
                        "color-white": !!state.pageFilters?.length,
                      })}
                    />
                  </IconButton>
                </Tooltip>
                {/* <Tooltip placement="top-end" title="Reset All Filters">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      updatePageConfig("audit-history");
                      window.location.reload();
                    }}
                  >
                    <RotateLeftIcon />
                  </IconButton>
                </Tooltip> */}
              </div>
              <DataGrid
                columns={columnConfig}
                rows={state.entries}
                actionBarConfig={null}
                isLoading={state.isFetching}
                hasSelection={false}
                onReady={(instance) => (gridHelper = instance)}
                totalRows={state.totalEntries}
                pageSize={state.pageSize}
                pageNumber={state.pageNumber}
                order={state.order}
                orderBy={state.orderBy}
                onPageNumberChange={handlePageNumberChange}
                onPageSizeChange={handlePageSizeChange}
                onSelectionChange={handleSelectionChange}
                onSortChange={handleSortChange}
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
      {state.viewHistory && (
        <Dialog
          open
          classes={{
            paper: classes.paper,
          }}
          onClose={handleCloseViewHistory}
        >
          <Dialog.Title hasClose>
            <div className="mb-2">
              <Typography variant="h4" color="primary" className="ml-2">
                Detailed Audit History
              </Typography>
              <Typography variant="body2" style={{ marginLeft: "10px" }}>
                View Detailed Audit History.
              </Typography>
            </div>
            <div className="mb-2">
              <Typography variant="body2" color="primary" className="ml-2">
                {state.isLoading ? (
                  <Skeleton variant="text" width="50%" height="100%" />
                ) : state.detailedEntries?.[0] !== undefined ? (
                  <div>
                    {`Schedule Date : ${getUTCDateString(
                      state.detailedEntries?.[0].schedule_date
                    )}`}
                    <br />
                    {!isRoleKAM
                      ? `KAM : ${
                          state.detailedEntries?.[0].kamUser.first_name || ""
                        } ${
                          state.detailedEntries?.[0].kamUser.last_name || ""
                        } - ${
                          state.detailedEntries?.[0].kamUser.email || ""
                        } (${
                          state.detailedEntries?.[0].kamUser.username || ""
                        })`
                      : null}
                    {!isRoleKAM ? <br /> : null}
                    {`Customer : ${state.detailedEntries?.[0].routeDetails?.customer_branch.customer?.name}`}
                    <br />
                    {`Route : ${state.detailedEntries?.[0].routeDetails?.name}`}
                  </div>
                ) : null}
              </Typography>
            </div>
          </Dialog.Title>
          <Dialog.Content>
            <Grid item xs={12}>
              <Paper
                elevation={2}
                className={clsx("p-4", classes.viewHitoryPaperSpacing)}
              >
                <div className="d-flex f-justify-end pb-2">
                  <Button
                    variant="contained"
                    color="primary"
                    className="ml-2 mr-2 mt-2"
                    startIcon={<CloudDownloadIcon />}
                    disabled={
                      state.isDownloadLoading ||
                      !state.totalEntries ||
                      state.isFetching
                    }
                    onClick={() => {
                      handleDownload(state.viewHistory.id);
                    }}
                  >
                    Download
                    {state.isDownloadLoading && (
                      <CircularProgress
                        size={24}
                        className={classes.progressBtn}
                      />
                    )}
                  </Button>
                </div>
                <CollapsibleGrid
                  columns={detailedColumnConfig}
                  rows={state.detailedEntries}
                  actionBarConfig={null}
                  childIdentifier={["changed_columns", "changed"]}
                  isLoading={state.isFetching}
                  totalRows={state.totalEntries}
                  pageSize={state.pageSize}
                  pageNumber={state.pageNumber}
                  onPageNumberChange={handlePageNumberChange}
                  onPageSizeChange={handlePageSizeChange}
                  hasSelection={false}
                  hasPagination={false}
                  onReady={(instance) => (gridHelper = instance)}
                  classes={{
                    gridActions: "f-justify-end",
                  }}
                />
              </Paper>
            </Grid>
          </Dialog.Content>
          <Dialog.Actions>
            <div className="p-4">
              <Button
                type="submit"
                variant="contained"
                onClick={handleCloseViewHistory}
                className="ml-2 mr-2"
                color="primary"
              >
                OK
              </Button>
            </div>
          </Dialog.Actions>
        </Dialog>
      )}
      {state.reportExportedPopup && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.reportExportedPopup}
          contentText="The audit history report has been successfully exported to your registered email address."
          onConfirm={() =>
            setState((prevState) => ({
              ...prevState,
              reportExportedPopup: false,
            }))
          }
          onClose={() => {
            setState((prevState) => ({
              ...prevState,
              reportExportedPopup: false,
            }));
          }}
        />
      )}
      {state.isFiltering && (
        <FilterComponent
          isFiltering={state.isFiltering}
          filterConfig={filterConfig}
          appliedFilters={state.pageFilters}
          onFilterApply={handleFilterChange}
          onClose={() => {
            setState((prevState) => ({
              ...prevState,
              isFiltering: false,
            }));
          }}
        />
      )}
    </>
  );
};

export default AuditHistory;
