import { useState, useCallback, useEffect, useContext } from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import { ERROR_CODES } from "../../shared/constants";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { NavLink } from "react-router-dom";
import isEmail from "validator/es/lib/isEmail";
import newOrgLogo from "shared/assets/images/pdx-logo.png";
import OrgLogo from "shared/assets/images/org-logo.png";
import { fieldErrorMessageMap } from "shared/constants";
import PersonIcon from "@material-ui/icons/Person";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import SignInService from "../service";
import {
  removeAlertToken,
  removeNonDynamicColumns,
  removeScheduleAlertToken,
  setAuthData,
  setUserData,
} from "utils";
import { AppContext } from "shared/contexts";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";

import useStyles from "./style";

const defaultState = {
  email: "",
  password: "",
  isLoading: false,
  showPassword: false,
  errors: {
    email: " ",
    password: " ",
  },
};

export default function SignInForm({ match, history }) {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);
  const { appData } = useContext(AppContext);
  const validate = useCallback(
    (field, value) => {
      let isValid = false;
      const fieldValidatorMap = {
        email: isEmail,
      };

      if (fieldValidatorMap[field]) {
        isValid = fieldValidatorMap[field](value);
      } else {
        if (!field) {
          isValid = true;
          Object.keys(fieldValidatorMap).forEach((key) => {
            if (!validate(key, state[key])) {
              isValid = false;
            }
          });
        } else {
          isValid = true;
        }
      }

      return isValid;
    },
    [state]
  );

  const handleFieldChange = (event) => {
    const field = event.currentTarget.name;
    const value = event.currentTarget.value;
    let errorMessage = " ";
    if (value) {
      const isValid = validate(field, value);
      if (!isValid) {
        errorMessage = fieldErrorMessageMap[field];
      }
    } else {
      errorMessage = "Required";
    }

    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  const handleSignInResponse = (error, data) => {
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      toast.error(Array.isArray(error) ? error[0]?.message : error?.message);
    } else {
      const params = new URLSearchParams(window.location.search);

      if (data?.code === ERROR_CODES.RESET_PASSWORD) {
        setTimeout(
          () => history.push(`${match.path}/change-password/${data?.token}`),
          1000 * 1
        );
      } else {
        setAuthData(data?.token);
        setUserData(data?.settings);
        const redirectUrl = params.get("redirectUrl");
        window.location.replace(
          redirectUrl && redirectUrl !== "null" ? redirectUrl : "/"
        );
      }
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  };

  const handleSubmit = async () => {
    const { email, password } = state;
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const { error, data } = await SignInService.signin({
      email,
      password,
    });
    if (!!data?.token) {
      removeNonDynamicColumns(email);
      removeAlertToken();
      removeScheduleAlertToken();
    }

    handleSignInResponse(error, data);
  };

  useEffect(() => {
    document.onkeypress = (event) => {
      if (
        event.key.toLowerCase() === "enter" ||
        event.key.toLowerCase() === "return"
      ) {
        if (validate()) {
          handleSubmit();
        }
      }
    };
  });

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item md={6} className={classes.image} />
      <Grid
        item
        square
        sm={12}
        md={6}
        elevation={6}
        // component={Paper}
        className={clsx(classes.formWrapper, {
          [classes.image]: appData.isTabletView,
        })}
      >
        <Box className={clsx("p-absolute", classes.orgLogo)}>
          <img alt="Pdx Logo" src={newOrgLogo} className={classes.img} />
        </Box>
        <Box></Box>
        <Box
          component={Paper}
          elevation={appData.isTabletView ? 7 : 2}
          className={classes.signInWrapper}
        >
          <Box className="mb-2 align-center">
            <Typography component="h1" variant="h5" className="mt-10">
              Welcome
            </Typography>
            <Typography variant="caption2">
              Please login to your Dashboard
            </Typography>
          </Box>
          <Box className="ml-auto mr-auto w-75">
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              error={!!state.errors.email.trim()}
              helperText={state.errors.email}
              id="email"
              label="Login ID"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleFieldChange}
              InputProps={{
                startAdornment: <PersonIcon />,
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              error={!!state.errors.password.trim()}
              helperText={state.errors.password}
              name="password"
              label="Password"
              type={state.showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              onChange={handleFieldChange}
              InputProps={{
                startAdornment: <LockOpenIcon />,
                endAdornment: state.showPassword ? (
                  <IconButton
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        showPassword: false,
                      }))
                    }
                  >
                    <VisibilityIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        showPassword: true,
                      }))
                    }
                  >
                    <VisibilityOffIcon />
                  </IconButton>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              className="mt-2 mb-2"
              disabled={!validate() || !state.password || state.isLoading}
              onClick={handleSubmit}
            >
              LOG IN
              {state.isLoading && (
                <CircularProgress size={24} className={classes.progressBtn} />
              )}
            </Button>
            <Box className="align-center mb-4 mt-4">
              <Typography variant="caption2">
                You Don't Remember your Password?
              </Typography>
            </Box>
            <NavLink to="/sign-in/forgot-password">
              <Button
                variant="contained"
                color="primary"
                className="mb-4"
                fullWidth
              >
                Recover My Password
              </Button>
            </NavLink>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
