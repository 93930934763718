import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const GridLoader = ({ pageSize = 5, columns: columnsLength = 0 }) => {
	if (pageSize === 0) {
		pageSize = 10;
	}

	const columns = new Array(columnsLength).fill('Loading');
	const rows = new Array(pageSize).fill('Rows Loading');
	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow key={`loader-head-row`}>
						{columns.map((column, columnIndex) => {
							return (
								<TableCell
									className='loading'
									key={`loader-head-col-${columnIndex}`}
								>
									<span className='v-hidden'>{column}</span>
								</TableCell>
							);
						})}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row, rowIndex) => {
						return (
							<TableRow key={`loader-row-${rowIndex}`}>
								{columns.map((column, columnIndex) => {
									return (
										<TableCell
											className='no-border'
											key={`loader-row-${rowIndex}-col-${columnIndex}`}
										>
											<div
												className='loading'
												style={{
													width:
														rowIndex % 2 === 0
															? '100px'
															: columnIndex % 2 === 0
															? '50px'
															: 'auto',
												}}
											>
												<span className='v-hidden'>{column}</span>
											</div>
										</TableCell>
									);
								})}
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default GridLoader;
