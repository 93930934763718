import { useState, useMemo } from "react";
import clsx from "clsx";
import GridLoader from "./loader";
import useTheme from "@material-ui/core/styles/useTheme";
import { noop } from "shared/constants";
import Grid from "./grid";
import NoRecords from "assets/images/norecord.svg";
import NoRecordsDark from "assets/images/no-records-dark.svg";
import useStyles from "./style";

let gridHelpers = {};
const defaultState = {
  selectedRows: {},
};

const CollapsibleGrid = ({
  columns = [],
  rows = [],
  uniqueBy = "id",
  isLoading = false,
  hasSelection = true,
  boxborder = null,
  selectedRef = null,
  tableHeadRef = null,
  isBillingAndSettelementReviewRowSelected = false,
  filterConfig = {},
  gridActions = [],
  classes = {},
  rowEvents = [],
  onReady = noop,
  onSelectionChange = noop,
  wrapRef2,
  handleScroll = noop,
  isRowOpen = false,
  handleRowOpen = noop,
  multiScroll = false,
}) => {
  const gridClasses = useStyles();
  const theme = useTheme();
  const [state, setState] = useState(defaultState);

  const sortedRows = useMemo(() => {
    let filteredRows = [...rows];
    if (filterConfig.search) {
      filteredRows = filteredRows.filter((row) =>
        columns.some(
          (column) =>
            String(row[column] || "")
              .toLowerCase()
              .trim()
              .indexOf(filterConfig.search.trim()) !== -1
        )
      );
    }
    return filteredRows.reduce((acc, current) => {
      const index = acc.findIndex(
        (item) => item.uniqueColumnName === current[uniqueBy]
      );
      index === -1
        ? acc.push({
            uniqueColumnName: current[uniqueBy],
            sortedRows: [current],
          })
        : acc[index]?.sortedRows.push(current);
      return acc;
    }, []);
  }, [rows, filterConfig]);

  const renderGrid = (rows, multiScroll) => (
    <Grid
      multiScroll={multiScroll}
      open={isRowOpen}
      handleRowOpen={handleRowOpen}
      boxborder={boxborder}
      tableHeadRef={tableHeadRef}
      rows={rows}
      selectedRef={selectedRef}
      columns={columns.filter((column) => column.id !== uniqueBy)}
      isLoading={isLoading}
      isBillingAndSettelementReviewRowSelected={
        isBillingAndSettelementReviewRowSelected
      }
      hasSelection={hasSelection}
      classes={classes}
      rowEvents={rowEvents}
      onReady={(gridHelper) =>
        (gridHelpers[rows.uniqueColumnName] = gridHelper)
      }
      onSelectionChange={(evt) => {
        setState((prevState) => {
          const selectedRows = {
            ...prevState.selectedRows,
            [rows.uniqueColumnName]: evt,
          };
          onSelectionChange(
            Object.keys(selectedRows)
              .map((key) => selectedRows[key])
              .flat()
          );
          return {
            ...prevState,
            selectedRows,
          };
        });
      }}
    />
  );

  onReady({
    resetSelection: () => {
      Object.keys(gridHelpers).map((key) => {
        gridHelpers[key] && gridHelpers[key].resetSelection();
      });
      setState((prevState) => ({
        ...prevState,
        selectedRows: {},
      }));
    },
    setSelection: (selectedRows) =>
      setState((prevState) => ({
        ...prevState,
        selectedRows,
      })),
    getSelection: () => state.selectedRows,
    toggle: (open = false, key = "") =>
      gridHelpers[key] && gridHelpers[key].toggle(open),
    toggleAll: (open = false) =>
      Object.keys(gridHelpers).map((key) => {
        gridHelpers[key] && gridHelpers[key].toggle(open);
      }),
  });

  return (
    <>
      {sortedRows.length === 0 && (
        <>
          {isLoading ? (
            [...new Array(5).fill("Loading")].map(() => (
              <GridLoader columns={columns.length} />
            ))
          ) : (
            <div className="d-flex f-justify-center">
              <img
                className={gridClasses.noRecordsImg}
                src={
                  theme && theme.palette.type === "dark"
                    ? NoRecordsDark
                    : NoRecords
                }
              />
            </div>
          )}
        </>
      )}

      {multiScroll ? (
        sortedRows.map((rows) => renderGrid(rows, true))
      ) : (
        <div
          ref={wrapRef2}
          onScroll={handleScroll}
          style={{
            display: "grid",
            overflow: "auto",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "&-ms-overflow-style:": {
              display: "none",
            },
            overflowY: "hidden",
          }}
        >
          {sortedRows.map((rows) => renderGrid(rows, false))}
        </div>
      )}
      {!!sortedRows.length && (
        <div className={clsx("d-flex f-align-center", classes.gridActions)}>
          {gridActions.map((action) => action)}
        </div>
      )}
    </>
  );
};

export default CollapsibleGrid;
