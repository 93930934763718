import makeStyles from '@material-ui/core/styles/makeStyles';
export default makeStyles(theme => ({
	dialog: {
		width: 652,
		height: 725,
	},
	listIcon: {
		minWidth: 32,
	},
	fileProgressWrapper: {
		width: 300,
	},
	removeFileBtn: {
		top: -24,
		right: -24,
	},
	progressBtn: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}));
