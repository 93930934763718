import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 500,
    maxWidth: 500,
    maxHeight: "75%",
  },
}));

export default useStyles;
