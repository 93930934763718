import makeStyles from "@material-ui/core/styles/makeStyles";
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";
import yellow from "@material-ui/core/colors/yellow";
import blue from "@material-ui/core/colors/blue";
import pink from "@material-ui/core/colors/pink";
import grey from "@material-ui/core/colors/grey";
import purple from "@material-ui/core/colors/purple";

const useStyles = makeStyles((theme) => ({
  baseRosterIcon: {
    height: 244,
  },
  datepickerWrapper: {
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
      width: "98px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  kam: {
    width: 250,
  },
  pageLoader: {
    position: "fixed",
    top: "50%",
    left: "50%",
  },
  confirmationPaper: {
    minWidth: 380,
  },
  pendingItemsDropdown: {
    maxHeight: 200,
    minWidth: 120,
  },
  calendarDay: {
    borderRadius: 4,
    color: `${theme.palette.common.black}`,
  },
  iconMargin: {
    marginBottom: 52,
  },
  clickableDate: {
    background: grey[300],
  },
  notCreatedDay: {
    background: red[500],
    "&$weekend": {
      backgroundImage: `linear-gradient(to bottom right, ${grey[200]}, ${red[500]} )`,
    },
  },
  notSubmittedDay: {
    background: yellow[500],
    "&$weekend": {
      backgroundImage: `linear-gradient(to bottom right,${grey[200]}, ${yellow[500]} )`,
    },
  },
  partialSubmittedDay: {
    background: orange[600],
    "&$weekend": {
      backgroundImage: `linear-gradient(to bottom right,${grey[200]}, ${orange[600]} )`,
    },
  },
  completeSubmittedDay: {
    background: green[500],
    "&$weekend": {
      backgroundImage: `linear-gradient(to bottom right,${grey[200]}, ${green[500]} )`,
    },
  },
  forcedSubmitDay: {
    background: blue[500],
    "&$weekend": {
      backgroundImage: `linear-gradient(to bottom right,${grey[200]}, ${blue[500]} )`,
    },
  },

  underReview: {
    background: purple[300],
    "&$weekend": {
      backgroundImage: `linear-gradient(to bottom right,${grey[200]}, ${purple[300]} )`,
    },
  },
  bsApproved: {
    background: blue[300],
    "&$weekend": {
      backgroundImage: `linear-gradient(to bottom right,${grey[200]}, ${blue[300]} )`,
    },
  },
  dateLegend: {
    bottom: 16,
    left: 8,
  },
  legendHint: {
    width: 16,
    height: 16,
    border: `1px solid ${theme.palette.divider}`,
  },
  backgroundWhite: {
    background: "white",
  },

  weekoff: {
    backgroundImage: `linear-gradient(to bottom right,white, ${grey[400]} )`,
  },
  colorNone: {
    color: "transparent !important",
  },
  transfer: {
    background: pink[200],
    "&$weekend": {
      backgroundImage: `linear-gradient(to bottom right,${grey[200]}, ${pink[200]} )`,
    },
  },
  weekend: {
    //border: "1px solid black",
    //backgroundImage: "linear-gradient(to bottom right, red, yellow)",
  },
  bgColorNone: {
    background: "none",
  },
  dialog: {
    height: 500,
    width: 700,
  },
  overrideDialog: {
    maxHeight: 320,
  },
}));

export default useStyles;
