import { http, responseFormatter } from "utils";

const get = (query) => {
  return responseFormatter(
    http.get(`/individual-contractors/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const post = (data) => {
  return responseFormatter(
    http.post("/individual-contractor", data, {
      setAuthHeader: true,
    })
  );
};

const put = (data) => {
  return responseFormatter(
    http.put("/individual-contractor", data, {
      setAuthHeader: true,
    })
  );
};

const patch = (data, id) => {
  return responseFormatter(
    http.patch(
      "/individual-contractor",
      { ...data, id },
      {
        setAuthHeader: true,
      }
    )
  );
};

const bulkUploads = (data) => {
  return responseFormatter(
    http.patch("/ic/upload-doc", data, {
      setAuthHeader: true,
    })
  );
};

const ICDocsDelete = (id) => {
  return responseFormatter(
    http.delete(`/ic/delete-doc/${id}`, {
      setAuthHeader: true,
    })
  );
};

const markActiveInactive = (data) => {
  return responseFormatter(
    http.patch("/individual-contractor-update-status", data, {
      setAuthHeader: true,
    })
  );
};

const markFlagUnflag = (data) => {
  return responseFormatter(
    http.patch("/change-ic-flag-status", data, {
      setAuthHeader: true,
    })
  );
};

const updateFlagReason = (data) => {
  return responseFormatter(
    http.patch("/change-ic-flag-reason", data, {
      setAuthHeader: true,
    })
  );
};

const getLocalResources = () => {
  return http.get("./resources.json", { baseURL: "" });
};
const getAllLocations = (query) => {
  return responseFormatter(
    http.get(`/addresses/get?entityType=ic`, {
      setAuthHeader: true,
    })
  );
};

const createLocation = (data) => {
  return responseFormatter(
    http.post("/addresses/create", data, {
      setAuthHeader: true,
    })
  );
};

const updateLocation = (data) => {
  return responseFormatter(
    http.put("/addresses/update", data, {
      setAuthHeader: true,
    })
  );
};

const deleteLocation = (entity_type, entity_id, address_id) => {
  return responseFormatter(
    http.delete(`/addresses/delete/${entity_type}/${entity_id}/${address_id}`, {
      setAuthHeader: true,
    })
  );
};

const download = (query) => {
  return responseFormatter(
    http.get(`/download/individualContractors/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const ContractorService = {
  get,
  post,
  put,
  patch,
  markActiveInactive,
  getLocalResources,
  download,
  markFlagUnflag,
  updateFlagReason,
  getAllLocations,
  createLocation,
  updateLocation,
  deleteLocation,
  bulkUploads,
  ICDocsDelete,
};

export default ContractorService;
