import clsx from "clsx";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import NoNotificationImg from "assets/images/no-notifications.svg";
import useStyles from "./style";

const NotificationNotFound = () => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(
        "d-flex flex-column f-align-center f-justify-between p-4",
        classes.notFoundWrapper
      )}
    >
      <Typography variant="body1" color="primary" className="mt-5 text-bold">
        No notification yet!
      </Typography>
      <img
        atl="No Notification"
        src={NoNotificationImg}
        className={classes.noNotificationImg}
      />
      <Typography variant="body2" color="primary" className="mb-10">
        We will notify you when something arrives.
      </Typography>
    </Box>
  );
};

export default NotificationNotFound;
