import makeStyles from '@material-ui/core/styles/makeStyles';
export default makeStyles(theme => ({
	heading: {
		width: '30%',
		height: 25,
	},
	inputField: {
		width: '40%',
		height: 40,
		margin: '25px 0 50px',
	},
	pageHeading: {
		width: '15%',
		height: 20,
	},
	subHeading: {
		height: 15,
		width: '43%',
		marginTop: 30,
	},
}));
