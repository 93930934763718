import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 520,
    minHeight: 350,
  },
  listPaper: {
    maxHeight: 150,
    overflow: "auto",
  },
  listBox: {
    height: 150,
    //overflow: "hidden",
  },
}));

export default useStyles;
