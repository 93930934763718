import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import useTheme from '@material-ui/core/styles/useTheme';
import Typography from '@material-ui/core/Typography';
import { renderLeftActions, renderRightActions } from './helpers';

import { useToolbarStyles } from './style';

const ActionToolbar = ({ config = {}, numSelected = 0 }) => {
	const classes = useToolbarStyles();
	const rightElements = config.right || [];
	const leftElements = config.left || [];
	const theme = useTheme();

	return (
		<Toolbar
			className={clsx(classes.root, {
				[classes.highlight]: !!numSelected,
				[classes.noHighlight]: !numSelected,
			})}
		>
			<div className='d-flex f-align-center f-justify-between flex-1'>
				<div className={classes.leftAligned}>
					{renderLeftActions(leftElements, theme, numSelected)}
				</div>
				{numSelected > 0 && (
					<div>
						<Typography variant='body1'>{numSelected} rows selected</Typography>
					</div>
				)}
				<div className={classes.rightAligned}>
					{renderRightActions(rightElements, theme, numSelected)}
				</div>
			</div>
		</Toolbar>
	);
};

export default ActionToolbar;
