import { http, responseFormatter } from "utils";

const get = (query) => {
  return responseFormatter(
    http.get(`/review-daily-schedule/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const approve = (payload) => {
  return responseFormatter(
    http.put(`/approve-daily-schedule`, payload, {
      setAuthHeader: true,
    })
  );
};

const reject = (payload) => {
  return responseFormatter(
    http.put(`/reject-daily-schedule`, payload, {
      setAuthHeader: true,
    })
  );
};

const getLocalResources = () => {
  return http.get("/resources.json", { baseURL: "" });
};

const getCustomerLocation = (query) => {
  return responseFormatter(
    http.get(`/customer/get-locations${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const addNotesInLookup = (payload) => {
  return responseFormatter(
    http.post(`/lookup`, payload, {
      setAuthHeader: true,
    })
  );
};

const download = (query) => {
  return responseFormatter(
    http.get(`/download/reviewDailySchedules/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const DailyScheduleReviewService = {
  get,
  approve,
  reject,
  getLocalResources,
  getCustomerLocation,
  addNotesInLookup,
  download,
};

export default DailyScheduleReviewService;
