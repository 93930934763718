import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  tabs: {
    background: "#eeeeee",
    maxWidth: "31rem",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
  tab: { paddingBottom: 0 },
  general: (props) => ({
    padding: 22,
    width: props.isTabletView ? 629 : 710,
    marginLeft: 2,
    borderRadius: 0,
    overflow: "auto",
    height: "calc(100vh - 345px)",
    gap: 5,
  }),
  addressGridridPaper: { maxHeight: 1000 },
  watchlistGridridPaper: { maxHeight: 1000, minHeight: 100 },
  document: {
    overflow: "auto",
    // height: "calc(100vh - 345px)",
    maxHeight: "calc(100vh - 345px)",
    minHeight: "calc(100vh - 874px)",
  },
  highlightMandatoryField: { background: "#ff000038" },
  highlightUncheckedField: { background: "aliceblue" },
  paper: {
    "& .MuiDialogTitle-root": {
      padding: "6px 5px 6px 10px",
      color: "#004700",
    },
    "& .MuiDialogActions-root": {
      background: "#bababa45",
      padding: "0px",
    },
    "& .MuiDialogContent-root": {
      background: "#bababa45",
      padding: "5px 9px 10px 9px",
      overflow: "hidden",
    },
    "& .MuiIconButton-root": {
      padding: "2px",
    },
    overflow: "hidden",
    minWidth: 600,
    maxWidth: 1600,
    // height: "calc(100vh - 191px)",
    maxHeight: "calc(100vh - 191px)",
    minHeight: "calc(100vh - 874px)",
  },
  paperGrid: {
    padding: 22,
    marginTop: 12,
    marginBottom: 2,
    minWidth: 120,
    maxWidth: 1600,
  },
  uploadDocument: (props) => ({
    padding: 22,
    minWidth: props.isTabletView ? 675 : 754,
    maxWidth: 1600,
  }),
  disabledText: {
    color: "rgba(0, 0, 0, 0.87)",
  },
  documentUpdatedByuser: {
    marginTop: "4px",
    marginLeft: "5px",
    fontSize: "initial",
    fontWeight: "bold",
  },
  circularProgress: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
  },
  docsPaper: {
    padding: 22,
    paddingTop: 6,
    // marginTop: 12,
    minWidth: 120,
    maxWidth: 1600,
  },
  checkbox: { padding: "10px", transform: "scale(1.2)" },
  agreementPaper: { padding: 22, minWidth: 120, maxWidth: 1600 },
  agreement: (props) => ({
    maxWidth: props.isTabletView ? 200 : "450px !important",
    minWidth: props.isTabletView ? 120 : "450px !important",
  }),
  fileRetentionStatus: (props) => ({
    maxWidth: props.isTabletView ? 200 : "275px !important",
    minWidth: props.isTabletView ? 120 : "275px !important",
  }),
  Watchlist: (props) => ({
    maxWidth: props.isTabletView ? 200 : "395px !important",
    minWidth: props.isTabletView ? 120 : "395px !important",
  }),
  documentType: (props) => ({
    maxWidth: props.isTabletView ? 200 : "250px !important",
    minWidth: props.isTabletView ? 120 : "250px !important",
  }),
  datepicker: (props) => ({
    maxWidth: props.isTabletView ? 200 : "250px !important",
    minWidth: props.isTabletView ? 200 : "250px !important",
  }),
  status: (props) => ({
    maxWidth: props.isTabletView ? 200 : "200px !important",
    minWidth: props.isTabletView ? 120 : "200px !important",
  }),
  dialogSize: {
    width: 600,
    height: 600,
  },
  datepickerWrapper: {
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
      color: "rgba(0, 0, 0, 0.87)",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  dateWrapper: {
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  SSNForm: {
    minWidth: 400,
    minHeight: 200,
  },
  changeSSNBtn: {
    bottom: 0,
  },
}));

export default useStyles;
