import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  gridPaper: {
    maxHeight: "470px",
  },
  paperSpacing: {
    marginTop: "14px !important",
    paddingTop: "0px !important",
    paddingBottom: "0px !important ",
  },
  actionsWrapper: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  highlightDisable: {
    background: theme.palette.grey[200],
  },
  popper: {
    zIndex: theme.zIndex.modal,
  },
  tableHeaderCell: {
    minWidth: 164,
  },
  flaggedButton: {
    "& .MuiTouchRipple-root span": {
      color: "rgb(255 103 103 / 76%)",
    },
  },
  cautionButton: {
    "& .MuiTouchRipple-root span": {
      color: "#ffff005c",
    },
  },
  warningIcon: { color: "#d7d718", zIndex: 1, position: "relative" },
  warningIconHeight: {
    height: "24px",
  },
  warningIconHeights: {
    height: "24px",
    position: "relative",
  },
  exclamationMark: {
    width: "3px",
    height: "9px",
    background: "black",
    position: "absolute",
    top: "12px",
    left: "19px",
  },
  exclamationMarks: {
    width: "3px",
    height: "9px",
    background: "black",
    position: "absolute",
    top: "11px",
    left: "11px",
  },
  progressBtn: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  editAddress: { width: "20px", paddingRight: "0px !important" },
  deleteAddress: {
    width: 370,
  },
  noRecordsImg: {
    maxHeight: 187,
  },
  addressGridridPaper: { minHeight: 120, maxHeight: 240, overflow: "hidden" },
  addressListPaper: {
    minWidth: 600,
    maxWidth: 600,
    minHeight: 120,
    maxHeight: 404,
  },

  // addressListPaper: {
  //   minWidth: 800,
  //   maxWidth: 600,
  //   minHeight: 120,
  //   maxHeight: 404,
  // },
  flagPaddingLeft: {
    paddingLeft: "21px !important",
  },
  tableHeadCell: {
    minWidth: "242px",
  },
  tableHeadWidth: {
    minWidth: "192px",
  },
  tableHeaderWidth: {
    minWidth: "171px",
  },

  autocompleteSearchInput: {
    minWidth: 100,
    "& .MuiInput-underline:before": {
      borderBottom: 0,
    },
    "&:hover": {
      "& .MuiInput-underline:before": {
        borderBottom: 0,
      },
    },
  },
  autocompletePaper: {
    minWidth: 150,
    maxHeight: 200,
    fontSize: 14,
  },
  autocompleteInput: {
    fontSize: 14,
  },
  dropdownPaper: {
    minWidth: 156,
  },
  icidFlagTitle: {
    paddingLeft: "48px !important",
  },
  locationWrapper: {
    minWidth: 150,
    maxHeight: 200,
    fontSize: 14,
  },
  dateOfBirthColumn: {
    minWidth: 192,
  },
  tunePaper: {
    minWidth: 256,
  },
  customSpace: {
    whiteSpace: "pre",
  },
  fixedHeader: {
    width: "100%",
    top: "64px",
    height: "62px",
    zIndex: 1000,
    position: "sticky",
    background: "#fafafa",
    marginBottom: 8,
  },
}));

export default useStyles;
