import { http, responseFormatter } from "utils";

function signin(data) {
  return responseFormatter(http.post("/login", data));
}

function forgotPassword(email) {
  return responseFormatter(http.post("/forgot-password", email));
}

function updatePassword(password, token) {
  let config = {
    setAuthHeader: !token,
  };

  if (token) {
    config = {
      ...config,
      headers: {
        Authorization: token,
      },
    };
  }

  return responseFormatter(http.put("/update-password", password, config));
}

function resetPassword(data) {
  return responseFormatter(http.put("/reset-password", data));
}

const sendVerificationEmail = (data, token) => {
  let config = {
    setAuthHeader: !token,
  };

  if (token) {
    config = {
      ...config,
      headers: {
        Authorization: token,
      },
    };
  }

  return responseFormatter(http.post("/send-verification-code", {}, config));
};

const SignInService = {
  signin,
  forgotPassword,
  resetPassword,
  sendVerificationEmail,
  updatePassword,
};

export default SignInService;
