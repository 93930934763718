import axios from 'axios';
import { getToken, setAuthData, isJWTExpired, getRefreshToken } from './token';
import responseFormatter from './response-formatter';

const sessionExpireMsg = 'Session expired. Redirecting to login screen...';
let config = {};
let isRefreshing = false;
let refreshSubscribers = [];
export let http = {};

const bootstrap = () => {
	http = axios.create({
		baseURL: config.apiBaseUrl,
		timeout: 30000,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
	});

	http.interceptors.request.use(config => {
		if (config.setAuthHeader) {
			const token = getToken();
			config.headers.common['Authorization'] = `${token}`;
		}
		if (config.setTimeout) {
			config.timeout = config.setTimeout;
		}

		return config;
	});

	// Add a request interceptor
	http.interceptors.request.use(
		async config => {
			if (!config.setAuthHeader) {
				return config;
			}
			// Do something before request is sent
			const originalRequest = config;
			let token = getToken();
			if (
				isJWTExpired(token) &&
				!/sign-in/i.test(originalRequest.url) &&
				!/refreshtoken/i.test(originalRequest.url) &&
				!/sign-up/i.test(originalRequest.url) &&
				!/profile-image/i.test(originalRequest.url)
			) {
				if (!isRefreshing && process.env?.NODE_ENV !== 'test') {
					isRefreshing = true;
					const refreshToken = getRefreshToken();
					const { data } = await refreshAccessToken(refreshToken);

					if (!data || !data.accessToken)
						return Promise.reject({ message: sessionExpireMsg });

					setAuthData(data);
					isRefreshing = false;
					onRefreshed(data.accessToken);
				}

				subscribeTokenRefresh(token => {
					// replace the expired token and retry
					originalRequest.headers['Authorization'] = `${token}`;

					return originalRequest;
				});

				return config;
			} else {
				return config;
			}
		},
		error => {
			// Do something with request error
			return Promise.reject(sessionExpireMsg);
		}
	);

	const subscribeTokenRefresh = cb => {
		refreshSubscribers.push(cb);
	};

	const onRefreshed = token => {
		refreshSubscribers.forEach(cb => {
			cb(token);
		});
		refreshSubscribers = [];
	};

	const refreshAccessToken = async token => {
		return await responseFormatter(
			http.post('refreshtoken', { refreshToken: token })
		);
	};
};

export const initializeHttp = appConfig => {
	config = appConfig;
	bootstrap();
};
