import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useMemo,
} from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import Service from "../service";
import SharedService from "modules/shared/service";
import { PAGE_KEYS, isTransferScheduleEnable } from "shared/constants";
import { PERMISSION } from "modules/shared/constants";
import FilterListIcon from "@material-ui/icons/FilterList";
import {
  queryStringBuilder,
  getUTCDateString,
  getPageConfig,
  hasFeatureAccess,
  setPageConfig,
  exportBase64ToFile,
  getTokenData,
  queryStringBuilderNew,
  updatePagination,
  updatePageConfig,
  getDateString,
} from "utils";
import { AppContext } from "shared/contexts";
import Chip from "@material-ui/core/Chip";
import ForceSubmitDialog from "../../daily-schedule/forceSubmit";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { ROLE } from "modules/shared/constants";
import Form from "../form";
import BulkUpload from "../bulk-upload";
import ErrorModal from "../error-modal";
import GasPrice from "../gas-price";
import { ActionDialog, Grid, FilterComponent } from "shared/components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Skeleton from "@material-ui/lab/Skeleton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import LaunchIcon from "@material-ui/icons/Launch";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import LockIcon from "@material-ui/icons/Lock";
import SearchIcon from "@material-ui/icons/Search";

import useStyles from "./style";

let gridHelper = null,
  timeout = null;

const defaultState = {
  pageSize: 100,
  pageNumber: 1,
  order: null,
  orderBy: null,
  entries: [],
  totalEntries: 0,
  search: "",
  rowBeingEdited: null,
  isFiltering: false,
  pageFilters: [],
  isFetching: false,
  isFetchingDateRange: false,
  isExchanging: false,
  openUpload: false,
  sheetError: null,
  dateRange: {},
  isDateRangeLocked: false,
  isLocked: false,
  dateRangeList: [],
  openForceSubmitDialog: false,
  rangeLockDialog: {},
  isRangeLocking: false,
  isPreviousWeek: false,
  stateList: [],
};

const FuelSurcharge = ({ history }) => {
  const pageConfig = getPageConfig(PAGE_KEYS.FUEL_SURCHARGE);
  const classes = useStyles();
  const [state, setState] = useState({
    ...defaultState,
    pageSize: pageConfig?.pageSize || defaultState.pageSize,
    pageNumber: pageConfig?.pageNumber || defaultState.pageNumber,
    pageFilters:
      (pageConfig && pageConfig?.filters) || defaultState.pageFilters,
    search: (pageConfig && pageConfig?.search) || defaultState.search,
    dateRange: (pageConfig && pageConfig?.dateRange) || defaultState.dateRange,
  });
  const currentUser = getTokenData() || {};
  const { appData, updateContextData, setHeaderElements } =
    useContext(AppContext);

  const isRoleKAM = (currentUser?.role || "").toLowerCase() === ROLE.KAM;
  const isRoleVIEW = (currentUser?.role || "").toLowerCase() === ROLE.MAPS_VIEW;
  const isRoleADMIN = (currentUser?.role || "").toLowerCase() === ROLE.ADMIN;
  const kamId = isRoleKAM ? currentUser?.id : state.selectedKAM?.id;
  const hasWritePermission = hasFeatureAccess(PERMISSION.FUEL_SURCHARGE_WRITE);
  const handleSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      order: order,
      orderBy: fieldObj.field || fieldObj.fieldName,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setPageConfig(PAGE_KEYS.FUEL_SURCHARGE, {
      pageSize,
    });
    setState((prevState) => ({
      ...prevState,
      pageSize,
      pageNumber: defaultState.pageNumber,
    }));
  }, []);

  const filterConfig = useMemo(
    () => [
      {
        field: "regular_price",
        fieldToDisplay: "Regular Price",
        operatorType: "number",
      },
      {
        field: "diesel_price",
        fieldToDisplay: "Diesel Price",
        operatorType: "number",
      },
    ],
    []
  );

  const handlePageNumberChange = useCallback((pageNumber) => {
    setPageConfig(PAGE_KEYS.FUEL_SURCHARGE, {
      pageNumber,
    });
    setState((prevState) => ({
      ...prevState,
      pageNumber,
    }));
  }, []);

  const handleSearch = useCallback((search) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        search,
        pageNumber: defaultState.pageNumber,
      }));
      setPageConfig(PAGE_KEYS.FUEL_SURCHARGE, {
        search,
      });
      gridHelper && gridHelper.resetSelection();
    }, 1000);
  }, []);

  const fetchLocalResources = async () => {
    const response = await SharedService.getLocalResources();
    setState((prevState) => ({
      ...prevState,
      stateList: response?.data?.states || defaultState.stateList,
    }));
  };

  const fetchEntries = useCallback(
    async (
      search,
      pageSize,
      pageNumber,
      order,
      orderBy,
      dateRange,
      pageFilters = [],
      hasLoader = true
    ) => {
      setState((prevState) => ({
        ...prevState,
        isFetching: hasLoader,
      }));

      const sortObj = { field: orderBy, order };
      const filters = [
        ...pageFilters.map((filter) => ({
          ...filter,
          value: filter?.value?.value || filter?.value,
        })),
        {
          field: "from_date",
          type: "=",
          value: dateRange.from_date,
          notIncludeInBuilder: true,
        },
        {
          field: "to_date",
          type: "=",
          value: dateRange.to_date,
          notIncludeInBuilder: true,
        },
      ];
      const queryString = queryStringBuilderNew(
        pageSize,
        pageSize * (pageNumber - 1),
        [
          { field: "state", value: search },
          { field: "abbreviation", value: search },
        ],
        filters,
        sortObj,
        search && search.length > 0
      );
      const { data, error } = await Service.get(queryString);
      if (error) {
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0].message : error.message
        );
      }

      setState((prevState) => ({
        ...prevState,
        isFetching: false,
        entries: data.fuelSurcharges || defaultState.entries,
        totalEntries: data.count,
        isPreviousWeek: data.is_previous_week,
        isLocked: data.locked,
        isDateRangeLocked:
          (data.fuelSurcharges || defaultState.entries).some(
            (entry) => !!entry.locked
          ) || defaultState.isDateRangeLocked,
      }));
      return data;
    },
    []
  );

  const handleFilterChange = useCallback((pageFilters = []) => {
    setState((prevState) => ({
      ...prevState,
      pageFilters,
    }));
    setPageConfig(PAGE_KEYS.FUEL_SURCHARGE, {
      filters: pageFilters,
    });
  }, []);

  const fetchDateRanges = async (shouldInitializeDateRange = false) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingDateRange: true,
    }));

    const { data, error } = await Service.getDateRange();
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingDateRange: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetchingDateRange: false,
      dateRangeList: data.dateRange || defaultState.dateRangeList,
      dateRange: shouldInitializeDateRange
        ? !!pageConfig?.dateRange
          ? state.dateRange
          : (data.dateRange || [])[0] || prevState.dateRange
        : prevState.dateRange,
    }));
    return data.dateRange || defaultState.dateRangeList;
  };

  const handleDownload = async (type, isTemplate = false) => {
    setState((prevState) => ({
      ...prevState,
      isExchanging: !isTemplate ? true : false,
      isTemplateDownloading: isTemplate ? true : false,
    }));

    const queryString = !isTemplate
      ? `?filter[where][from_date]=${state.dateRange.from_date}&filter[where][to_date]=${state.dateRange.to_date}`
      : `?filter[isTemplate]=${isTemplate}`;
    const { data, error } = await Service.download(type, queryString);
    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      !isTemplate
        ? toast.success(`Fuel price downloaded successfully.`)
        : toast.success(`Fuel price template downloaded successfully.`);
      !isTemplate
        ? exportBase64ToFile(data, "Fuel Price")
        : exportBase64ToFile(data, "Fuel Price Template");
    }
    setState((prevState) => ({
      ...prevState,
      isExchanging: false,
      isTemplateDownloading: false,
    }));
  };

  const handleRangeLock = async (dateRange = {}) => {
    setState((prevState) => ({
      ...prevState,
      isRangeLocking: true,
    }));

    const { data, error } = await Service.lockDateRange(dateRange);
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isRangeLocking: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }

    toast.success(
      `Date range (${getUTCDateString(
        dateRange.from_date
      )} - ${getUTCDateString(dateRange.to_date)}) ${
        state.isLocked ? "unlock." : "lock."
      }`
    );
    setState((prevState) => ({
      ...prevState,
      isRangeLocking: false,
      rangeLockDialog: {},
    }));
    fetchEntries(
      state.search,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.dateRange,
      state.pageFilters
    );
    fetchDateRanges();
  };

  const handleAAAPriceSave = async ({ from_date, to_date }) => {
    const isNewRangeCreated = !(
      from_date === getUTCDateString(state.dateRange.from_date) &&
      to_date === getUTCDateString(state.dateRange.to_date)
    );
    if (isNewRangeCreated) {
      const dateRanges = await fetchDateRanges();
      setState((prevState) => {
        const dateRange =
          dateRanges.find(
            (range) =>
              from_date === getUTCDateString(range.from_date) &&
              to_date === getUTCDateString(range.to_date)
          ) || prevState.dateRange;
        return {
          ...prevState,
          dateRange,
        };
      });
    } else {
      fetchEntries(
        state.search,
        state.pageSize,
        state.pageNumber,
        state.order,
        state.orderBy,
        state.dateRange,
        state.pageFilters
      );
    }
  };

  useEffect(() => {
    if (!!Object.keys(state.dateRange).length) {
      fetchEntries(
        state.search,
        state.pageSize,
        state.pageNumber,
        state.order,
        state.orderBy,
        state.dateRange,
        state.pageFilters,
        state.pageFilters
      ).then((data) => {
        updatePagination(
          data?.count,
          { pageNumber: state.pageNumber, pageSize: state.pageSize },
          (pageNumber) => {
            setState((prevState) => ({ ...prevState, pageNumber }));
          }
        );
      });
    }
  }, [
    fetchEntries,
    state.pageSize,
    state.pageNumber,
    state.order,
    state.orderBy,
    state.search,
    state.dateRange,
    state.pageFilters,
  ]);

  const fetchLeaveTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        settlementCompanies: true,
      },
    }));

    const { data, error } = await SharedService.getLeaveTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          filters: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetching: false,
      forceSubmitReasonList: data?.rows || [],
    }));
  };

  useEffect(() => {
    fetchLeaveTypeList();
    fetchDateRanges(true);
    fetchLocalResources();
  }, []);

  const moreActions = (row, rowIndex, isDateRangeLocked) => {
    return (
      <div
        className={clsx("d-flex f-align-center f-justify-end", {
          disabled: isDateRangeLocked,
        })}
      >
        <Tooltip title="Edit" placement="top-start">
          <EditOutlinedIcon
            disabled={state.isLocked}
            // className="ml-2 mr-2 c-pointer"
            className={clsx("ml-2 mr-2 c-pointer", {
              disabled: state.isLocked,
            })}
            onClick={() =>
              setState((prevState) => ({ ...prevState, rowBeingEdited: row }))
            }
          />
        </Tooltip>
      </div>
    );
  };

  const fetchList = async (listType = "pendingItems", ...params) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    switch (listType) {
      case "pendingItems":
        serviceMethod = "getPendingItems";
        responseKey = "pendingItems";
        break;
      case "transferRecords":
        serviceMethod = "getTransferScheduleRequests";
        responseKey = "transferRecords";
        break;
      default:
        serviceMethod = "getPendingItems";
        responseKey = "pendingItems";
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];

    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
  };

  useEffect(() => {
    !!appData.pendingItems.length &&
      isRoleKAM &&
      setHeaderElements([
        <div className="d-flex f-align-center m-2">
          <Chip
            className={"bg-danger color-white"}
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                openForceSubmitDialog: true,
              }));
            }}
            label={
              appData.isTabletView ? (
                <Tooltip title="PENDING SCHEDULE DATE(S)" placement="top-start">
                  <Typography variant="body2" className="text-bold">
                    ({appData.pendingItems.length})
                  </Typography>
                </Tooltip>
              ) : (
                <Typography variant="body2" className="text-bold">
                  PENDING SCHEDULE DATE(S) ({appData.pendingItems.length})
                </Typography>
              )
            }
            variant="outlined"
          />
        </div>,
      ]);

    return () => setHeaderElements([]);
  }, [appData.pendingItems]);

  useEffect(() => {
    if (kamId && isRoleKAM) {
      if (isTransferScheduleEnable) {
        fetchList("transferRecords", "", kamId);
      }
      fetchList("pendingItems", kamId);
    }
  }, [state.selectedKAM]);

  useEffect(() => {
    return () => updateContextData("pendingItems", []);
  }, []);

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      openForceSubmitDialog: false,
    }));
  };

  const columnConfig = [
    {
      id: "state",
      label: "State",
      field: "state",
      canSort: true,
      hasEllipses: true,
      render: (row) => {
        const title = `${row.state} (${row.abbreviation})`;
        return (
          <Tooltip title={title} placement="top-start">
            <Typography variant="body2">{title}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "regular_price",
      label: "Regular Price",
      field: "regular_price",
      startAdornment: "$",
      canSort: true,
      hasEllipses: true,
    },
    {
      id: "diesel_price",
      label: "Diesel Price",
      field: "diesel_price",
      startAdornment: "$",
      canSort: true,
      hasEllipses: true,
    },
  ];
  if (hasWritePermission) {
    columnConfig.unshift({
      headerClassName: classes.editFuel,
      id: "col-edit",
      noResize: true,
      render: (row, rowIndex) =>
        moreActions(row, rowIndex, state.isDateRangeLocked),
    });
  }
  return (
    <div className="mr-5">
      <div className={clsx("mb-1 mr-10", classes.fixedHeader)}>
        <Typography variant="h4" color="primary" className=" ml-2">
          Fuel Price
          <Button
            startIcon={<ArrowBackIcon />}
            style={{ float: "right" }}
            className="mt-1 mr-2"
            variant="contained"
            color="primary"
            onClick={() => history.push("/home")}
          >
            Back To Home
          </Button>
        </Typography>
        {hasWritePermission ? (
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            Download, Upload and Edit Fuel Price's information.
          </Typography>
        ) : (
          <Typography variant="body2" className="ml-2">
            View and Download Fuel Price's information.
          </Typography>
        )}
      </div>
      <div className="d-flex f-justify-between f-align-center">
        <div>
          <Button
            variant="contained"
            startIcon={<LaunchIcon />}
            disabled={state.isExchanging}
            className="mb-4"
            color="primary"
            onClick={() => history.push("/fuel-price/schedule")}
          >
            Fuel Surcharge Schedule
          </Button>

          <div className="d-flex f-align-center">
            <Autocomplete
              fullWidth
              disableClearable
              size="small"
              className={classes.rangeAutocomplete}
              value={state.dateRange}
              options={state.dateRangeList}
              getOptionLabel={({ from_date, to_date }) =>
                `${getUTCDateString(from_date)} - ${getUTCDateString(to_date)}`
              }
              renderInput={(params) =>
                state.isFetchingDateRange ? (
                  <Skeleton variant="rect" width="100%" height={40} />
                ) : (
                  <TextField
                    {...params}
                    label="Date Range"
                    variant="outlined"
                  />
                )
              }
              onChange={(evt, dateRange) => {
                setState((prevState) => ({
                  ...prevState,
                  dateRange,
                }));
                setPageConfig(PAGE_KEYS.FUEL_SURCHARGE, {
                  dateRange: dateRange,
                });
              }}
            />

            {hasWritePermission && (
              <Tooltip
                title={
                  state.isFetching || state.isFetchingDateRange
                    ? ""
                    : state.isLocked
                    ? "Date range is locked"
                    : "Lock date range"
                }
                placement="top-start"
              >
                <div>
                  {state.isFetching || state.isFetchingDateRange ? (
                    <IconButton>
                      <Skeleton
                        variant="circle"
                        width={32}
                        height={32}
                        className="ml-2"
                      />
                    </IconButton>
                  ) : (
                    <>
                      {/* {state.isDateRangeLocked && hasWritePermission ? (
                        <LockIcon color="error" className="ml-4" />
                      ) : (
                        <IconButton
                          className={clsx("ml-2", {
                            disabled: state.isPreviousWeek,
                          })}
                          onClick={() => {
                            if (state.isPreviousWeek) {
                              return false;
                            }
                            setState((prevState) => ({
                              ...prevState,
                              rangeLockDialog: {
                                open: true,
                                dateRange: state.dateRange,
                              },
                            }));
                          }}
                        >
                          <LockOpenIcon
                            color={
                              !state.isPreviousWeek ? "primary" : "disabled"
                            }
                          />
                        </IconButton>
                      )} */}

                      {!!state.dateRangeList.length &&
                        (hasWritePermission && state.isLocked ? (
                          <IconButton
                            onClick={() => {
                              if (!isRoleADMIN) {
                                return false;
                              } else {
                                setState((prevState) => ({
                                  ...prevState,
                                  rangeLockDialog: {
                                    open: true,
                                    dateRange: {
                                      ...state.dateRange,
                                      status: state.isLocked ? false : true,
                                    },
                                  },
                                }));
                              }
                            }}
                          >
                            <LockIcon
                              className="ml-2 c-pointer"
                              color="error"
                            />
                          </IconButton>
                        ) : (
                          <IconButton
                            // className={clsx("ml-2", {
                            //   disabled: state.isPreviousWeek,
                            // })}
                            onClick={() => {
                              setState((prevState) => ({
                                ...prevState,
                                rangeLockDialog: {
                                  open: true,
                                  dateRange: {
                                    ...state.dateRange,
                                    status: state.isLocked ? false : true,
                                  },
                                },
                              }));
                            }}
                          >
                            <LockOpenIcon color="primary" />
                          </IconButton>
                        ))}
                    </>
                  )}
                </div>
              </Tooltip>
            )}
          </div>
        </div>
        <div className="d-flex f-align-center">
          <Typography variant="body1">Enter to search by: </Typography>
          <TextField
            type="text"
            variant="outlined"
            className="ml-2"
            size="small"
            defaultValue={state.search}
            placeholder="State"
            InputProps={{ endAdornment: <SearchIcon /> }}
            onChange={(evt) => handleSearch((evt.target.value || "").trim())}
          />
        </div>
      </div>
      <Paper
        xs={12}
        elevation={2}
        className={clsx("p-4 mt-8 h-100", classes.paperSpacing)}
      >
        <div
          className={clsx(
            "d-flex f-align-center f-justify-between pb-4 f-wrap",
            classes.actionsWrapper
          )}
        >
          <div className="d-flex f-align-center">
            {hasWritePermission && (
              <GasPrice
                stateList={state.stateList}
                fuelPriceDateRange={state.dateRange}
                onSave={handleAAAPriceSave}
              />
            )}
          </div>
          <div className="d-flex f-align-center mt-2">
            {hasWritePermission && (
              <Button
                startIcon={<CloudUploadIcon />}
                disabled={state.isExchanging || state.isTemplateDownloading}
                className="mr-4"
                color="primary"
                variant="contained"
                onClick={() =>
                  setState((prevState) => ({ ...prevState, openUpload: true }))
                }
              >
                Upload
              </Button>
            )}
            <Button
              startIcon={<CloudDownloadIcon />}
              disabled={state.isExchanging || state.isTemplateDownloading}
              color="secondary"
              className="mr-4"
              variant="contained"
              onClick={() => handleDownload("fuelSurcharges")}
            >
              Download
              {state.isExchanging && (
                <CircularProgress
                  color="secondary"
                  size={24}
                  className={classes.progressBtn}
                />
              )}
            </Button>
            {hasWritePermission && (
              <Button
                startIcon={<CloudDownloadIcon />}
                disabled={state.isTemplateDownloading}
                color="secondary"
                variant="outlined"
                onClick={() => handleDownload("fuelSurcharges", true)}
              >
                Download Template
                {state.isTemplateDownloading && (
                  <CircularProgress
                    color="secondary"
                    size={24}
                    className={classes.progressBtn}
                  />
                )}
              </Button>
            )}
            <Tooltip placement="top-start" title="Filter">
              <IconButton
                color="primary"
                className={clsx("ml-2", {
                  "bg-primary": !!state.pageFilters?.length,
                })}
                onClick={() => {
                  setState((prevState) => ({
                    ...prevState,
                    isFiltering: true,
                  }));
                }}
              >
                <FilterListIcon
                  className={clsx({
                    "color-white": !!state.pageFilters?.length,
                  })}
                />
              </IconButton>
            </Tooltip>
            <Tooltip placement="top-end" title="Reset All Filters">
              <IconButton
                // disabled={pageConfig === undefined || pageConfig === null}
                color="primary"
                onClick={() => {
                  updatePageConfig("fuel-surcharge");
                  window.location.reload();
                }}
              >
                <RotateLeftIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <Grid
          columns={columnConfig}
          rows={state.entries}
          actionBarConfig={null}
          isLoading={state.isFetching || state.isFetchingDateRange}
          onReady={(instance) => (gridHelper = instance)}
          totalRows={state.totalEntries}
          pageSize={state.pageSize}
          pageNumber={state.pageNumber}
          order={state.order}
          orderBy={state.orderBy}
          search={state.search}
          hasSelection={false}
          onPageNumberChange={handlePageNumberChange}
          onPageSizeChange={handlePageSizeChange}
          onSortChange={handleSortChange}
          rowEvents={[
            {
              type: "onDoubleClick",
              handler: (row) => {
                if (hasWritePermission) {
                  setState((prevState) => {
                    return {
                      ...prevState,
                      rowBeingEdited: prevState.isLocked
                        ? prevState.rowBeingEdited
                        : row,
                    };
                  });
                }
              },
            },
          ]}
          classes={{
            gridActions: "f-justify-end",
          }}
        />
        {state.rowBeingEdited && (
          <Form
            open={!!state.rowBeingEdited}
            entry={state.rowBeingEdited}
            dateRange={state.dateRange}
            onClose={(isSubmitted = false) => {
              setState((prevState) => ({
                ...prevState,
                rowBeingEdited: null,
              }));
              if (typeof isSubmitted === "boolean" && isSubmitted) {
                fetchEntries(
                  state.search,
                  state.pageSize,
                  state.pageNumber,
                  state.order,
                  state.orderBy,
                  state.dateRange,
                  state.pageFilters
                );
              }
            }}
          />
        )}
      </Paper>
      {state.openUpload && (
        <BulkUpload
          open={state.openUpload}
          uploadKey="fuelSurcharges"
          onClose={(isUploaded = false) => {
            setState((prevState) => ({ ...prevState, openUpload: false }));
            if (isUploaded) {
              fetchDateRanges();
              fetchEntries(
                state.search,
                state.pageSize,
                state.pageNumber,
                state.order,
                state.orderBy,
                state.dateRange,
                state.pageFilters
              );
            }
          }}
          onSheetError={(sheetError) =>
            setState((prevState) => ({ ...prevState, sheetError }))
          }
        />
      )}
      {!!state.sheetError && (
        <ErrorModal
          open={!!state.sheetError}
          error={state.sheetError}
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              sheetError: null,
            }))
          }
        />
      )}
      {state.rangeLockDialog.open && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.paperSize,
          }}
          open={state.rangeLockDialog.open}
          contentText={
            <>
              <Typography variant="body1">
                Are you sure you want to{" "}
                {state.isLocked ? `${"Unlock"}` : `${"lock"}`} prices for this
                date range?
              </Typography>
            </>
          }
          onConfirm={() => handleRangeLock(state.rangeLockDialog.dateRange)}
          onCancel={() =>
            setState((prevState) => ({ ...prevState, rangeLockDialog: {} }))
          }
          isConfirmDisabled={state.isLoading}
          positiveActionLabel={
            <>
              {state.isLocked ? `${"Unlock"}` : `${"lock"}`}
              {state.isRangeLocking && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
        />
      )}
      {state.isFiltering && (
        <FilterComponent
          isFiltering={state.isFiltering}
          filterConfig={filterConfig}
          appliedFilters={state.pageFilters}
          onFilterApply={handleFilterChange}
          onClose={() => {
            setState((prevState) => ({
              ...prevState,
              isFiltering: false,
            }));
          }}
        />
      )}
      {state.openForceSubmitDialog && (
        <ForceSubmitDialog
          open={state.openForceSubmitDialog}
          pendingItems={appData.pendingItems.map((date, index) => ({
            date,
            id: index,
          }))}
          onClose={handleClose}
          kamId={kamId}
          forceSubmitReasonList={state.forceSubmitReasonList}
          onForceSubmit={() => fetchList("pendingItems", kamId)}
        />
      )}
    </div>
  );
};

export default FuelSurcharge;
