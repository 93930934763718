import { useState } from "react";
import clsx from "clsx";
import { noop, VALIDATIONS } from "shared/constants";
import { toPascalCase, validator } from "utils";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import useStyle from "./style";

const defaultState = {
  name: "",
  errors: {
    name: " ",
  },
};

const ScheduleForm = ({
  isAddingSchedule = false,
  hasWritePermission = false,
  onNext = noop,
  onCancel = noop,
  onNewSchedule = noop,
}) => {
  const classes = useStyle();
  const [state, setState] = useState(defaultState);

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = {
      name: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.MAX_LENGTH, value: 30 },
      ],
    };

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string",
          validation.customMessage
        )
      );

      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const handleFieldChange = (evt) => {
    const { name, value } = evt.currentTarget;
    const pascalValue = toPascalCase(value);
    const errorMessage = validate(name, (pascalValue || "").trim()) || " ";
    setState((prevState) => ({
      ...prevState,
      [name]: pascalValue,
      errors: {
        ...prevState.errors,
        [name]: errorMessage,
      },
    }));
  };

  return (
    <Box
      className={clsx(
        "d-flex f-align-center f-justify-center",
        classes.wrapper
      )}
    >
      {isAddingSchedule && (
        <Box className="d-flex">
          <TextField
            required
            label="Schedule Name"
            variant="outlined"
            name="name"
            size="small"
            value={state.name}
            error={!!state.errors.name.trim()}
            helperText={state.errors.name}
            onChange={handleFieldChange}
          />
          <Box className="d-flex f-align-center mb-6">
            <Button
              color="primary"
              variant="contained"
              className="ml-8"
              disabled={validate()}
              onClick={() => onNext((state.name || "").trim())}
            >
              Next
            </Button>
            <Button
              variant="contained"
              className="ml-4"
              onClick={() => {
                setState(defaultState);
                onCancel();
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ScheduleForm;
