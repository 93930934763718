import { noop } from "shared/constants";
import clsx from "clsx";
import { setPageConfig } from "utils";
import { Dropdown, Grid } from "shared/components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Skeleton from "@material-ui/lab/Skeleton";
import TextField from "@material-ui/core/TextField";
import FilterListIcon from "@material-ui/icons/FilterList";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TuneIcon from "@material-ui/icons/Tune";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { useHistory } from "react-router-dom";
import { Checkbox } from "@material-ui/core";

const TransferView = ({
  state = {},
  classes = {},
  PAGE_KEYS = {},
  columnConfig = {},
  appDataList = {},
  isRoleKAM = false,
  locationOPtions,
  setState = noop,
  updatePageConfig = noop,
  handleSelectionChange = noop,
  handleSortChange = noop,
  handleGridHelper = noop,
  handleTransferStatusDialog = noop,
  containerRef,
  handleScroll = noop,
}) => {
  const history = useHistory();
  const kamOptions = appDataList.kamUsers?.sort(
    (a, b) => b.is_active - a.is_active
  );

  return (
    <div className="mr-5">
      <div
        className={clsx("mb-2 mr-10", classes.fixedHeader)}
        style={{
          top: "64px",
        }}
      >
        <Typography variant="h4" color="primary" className="ml-2">
          Transferred Schedule
          <Button
            style={{ float: "right" }}
            className="mt-1 mr-2"
            startIcon={<ArrowBackIcon />}
            variant="contained"
            color="primary"
            onClick={() => history.push("/home")}
          >
            Back To Home
          </Button>
        </Typography>
        <Typography variant="body2" style={{ marginLeft: "10px" }}>
          Transferred Schedule of KAMs.
        </Typography>
      </div>
      <div className="d-flex f-justify-between f-align-start flex-05 ml-1 mb-4">
        <div className="d-flex f-wrap pr-8">
          <Autocomplete
            size="small"
            value={state.selectedKAM}
            fullWidth
            style={{ marginLeft: 18 }}
            classes={{
              root: classes.kamDropdownWidth,
              listbox: classes.kamDropdownWidth,
            }}
            options={kamOptions.filter(
              (item) => item?.id !== state.selectedTransferToKAM?.id
            )}
            getOptionLabel={(option) =>
              option.name ||
              `${option.first_name || ""} ${option.last_name || ""}-${
                option.email || ""
              }-(${option.username || ""})`
            }
            renderOption={({
              first_name,
              last_name,
              email,
              is_active,
              username,
            }) => (
              <Typography
                variant="body1"
                className={clsx({
                  "color-text-disabled": !is_active,
                })}
              >{`${first_name || " "} ${
                last_name || ""
              }-${email}-(${username})`}</Typography>
            )}
            renderInput={(params) =>
              state.isFetchingList.users ? (
                <Skeleton variant="rect" width="100%" height="36px" />
              ) : (
                <TextField
                  {...params}
                  label="Original KAM"
                  variant="outlined"
                />
              )
            }
            getOptionSelected={(option, value) => option?.id === value?.id}
            onChange={(evt, value) => {
              setPageConfig(PAGE_KEYS.TRANSFER_SCHEDULE, {
                kam: value,
                transferToKAM:
                  !!value && !isRoleKAM ? null : state.selectedTransferToKAM,
              });
              setState((prevState) => ({
                ...prevState,
                transferEntries: [],
                offset: 0,
                selectedKAM: value,
                selectedTransferToKAM:
                  !!value && !isRoleKAM
                    ? null
                    : prevState.selectedTransferToKAM,
              }));
            }}
          />
          <Autocomplete
            size="small"
            value={state.selectedTransferToKAM}
            disabled={isRoleKAM}
            fullWidth
            style={{ marginLeft: 18 }}
            classes={{
              root: classes.kamDropdownWidth,
              listbox: classes.kamDropdownWidth,
            }}
            options={
              !!state.selectedKAM
                ? Array.from(
                    new Set(
                      (state.transferEntries || [])
                        ?.filter((ele) => ele?.transfer_to)
                        ?.flatMap((item) => [
                          (
                            kamOptions?.find(
                              (data) => data?.id === item?.transfer_to
                            ) || {}
                          )?.id,
                        ])
                    )
                  ).map((id) => kamOptions.find((option) => option?.id === id))
                : kamOptions
            }
            getOptionLabel={(option) =>
              option.name ||
              `${option.first_name || ""} ${option.last_name || ""}-${
                option.email || ""
              }-(${option.username || ""})`
            }
            renderOption={({
              first_name,
              last_name,
              email,
              is_active,
              username,
            }) => (
              <Typography
                variant="body1"
                className={clsx({
                  "color-text-disabled": !is_active,
                })}
              >{`${first_name || " "} ${
                last_name || ""
              }-${email}-(${username})`}</Typography>
            )}
            renderInput={(params) =>
              state.isFetchingList.users ? (
                <Skeleton variant="rect" width="100%" height="36px" />
              ) : (
                <TextField
                  {...params}
                  label="Transfer To KAM"
                  variant="outlined"
                />
              )
            }
            getOptionSelected={(option, value) => option?.id === value?.id}
            onChange={(evt, value) => {
              setPageConfig(PAGE_KEYS.TRANSFER_SCHEDULE, {
                transferToKAM: value,
              });
              setState((prevState) => ({
                ...prevState,
                transferEntries: [],
                offset: 0,
                selectedTransferToKAM: value,
              }));
            }}
          />
          <Autocomplete
            classes={{
              root: classes.kamDropdownWidth,
              listbox: classes.kamDropdownWidth,
            }}
            style={{
              width: "244px",
              marginLeft: 18,
            }}
            size="small"
            fullWidth
            value={state.selectedLocation || []}
            multiple
            options={locationOPtions}
            getOptionLabel={(option) => option.label}
            renderInput={(params) =>
              state.isFetchingList.users ? (
                <Skeleton variant="rect" width="100%" height="36px" />
              ) : (
                <TextField {...params} label="Location" variant="outlined" />
              )
            }
            getOptionSelected={(option, value) => option.label === value.label}
            onChange={(evt, value) => {
              setPageConfig(PAGE_KEYS.TRANSFER_SCHEDULE, {
                locationFilter: value,
              });
              setState((prevState) => ({
                ...prevState,
                transferEntries: [],
                offset: 0,
                selectedLocation: value,
              }));
            }}
          />
        </div>
      </div>
      <Paper elevation={2} className="p-2 h-100 mb-4">
        <div
          className={clsx(
            "d-flex f-align-center f-justify-between p-2 pl-4 pr-4",
            classes.actionsWrapper
          )}
        >
          <div className="d-flex f-align-center">
            <FormControlLabel
              label="Submitted"
              checked={
                state.filters.statusSubmitted?.length &&
                state.filters.statusSubmitted[0]?.value === "submitted"
              }
              control={
                <Checkbox
                  color="primary"
                  onChange={(evt) => {
                    setState((prevState) => ({
                      ...prevState,
                      transferEntries: [],
                      offset: 0,
                      filters: {
                        ...prevState.filters,
                        statusSubmitted: evt.target.checked
                          ? [
                              {
                                field: "status",
                                type: "iLike",
                                value: "submitted",
                              },
                            ]
                          : [
                              {
                                field: "status",
                                type: "iLike",
                                value: "",
                              },
                            ],
                      },
                    }));
                    setPageConfig(PAGE_KEYS.TRANSFER_SCHEDULE, {
                      statusSubmitted: evt.target.checked
                        ? [
                            {
                              field: "status",
                              type: "iLike",
                              value: "submitted",
                            },
                          ]
                        : [
                            {
                              field: "status",
                              type: "iLike",
                              value: "",
                            },
                          ],
                    });
                  }}
                />
              }
            />
            <FormControlLabel
              label="Not Submitted"
              checked={
                state.filters.statusPending?.length &&
                state.filters.statusPending[0]?.value === "pending"
              }
              control={
                <Checkbox
                  color="primary"
                  onChange={(evt) => {
                    setState((prevState) => ({
                      ...prevState,
                      transferEntries: [],
                      offset: 0,
                      filters: {
                        ...prevState.filters,
                        statusPending: evt.target.checked
                          ? [
                              {
                                field: "status",
                                type: "iLike",
                                value: "pending",
                              },
                            ]
                          : [
                              {
                                field: "status",
                                type: "iLike",
                                value: "",
                              },
                            ],
                      },
                    }));
                    setPageConfig(PAGE_KEYS.TRANSFER_SCHEDULE, {
                      statusPending: evt.target.checked
                        ? [
                            {
                              field: "status",
                              type: "iLike",
                              value: "pending",
                            },
                          ]
                        : [
                            {
                              field: "status",
                              type: "iLike",
                              value: "",
                            },
                          ],
                    });
                  }}
                />
              }
            />
            {state.selectedRows.length > 0 && (
              <>
                <Button
                  className="mt-1 mr-4"
                  s
                  variant="outlined"
                  classes={{
                    root: "border-error",
                    label: "color-error",
                  }}
                  onClick={() => handleTransferStatusDialog(true, "reject")}
                >
                  Reject
                </Button>
                <Button
                  className="mt-1 mr-2"
                  s
                  variant="outlined"
                  color="primary"
                  onClick={() => handleTransferStatusDialog(true, "approve")}
                >
                  Accept
                </Button>
              </>
            )}
          </div>

          <div></div>

          <div className="d-flex f-justify-center f-align-center">
            <Dropdown
              classes={{
                paper: clsx("mr-1", classes.tunePaper),
              }}
              disablePortal={false}
              labelEllipses
              hasEllipses
              placement={"bottom-end"}
              options={[
                { label: "All", value: null },
                { label: "Accepted", value: true },
                { label: "Not Accepted", value: false },
              ]}
              label={`Response Type: ${
                state.filters.acceptedType.length
                  ? state.filters.acceptedType[0]?.value
                    ? "Accepted"
                    : "Not Accepted"
                  : "All"
              }`}
              onChange={(option) => {
                setState((prevState) => ({
                  ...prevState,
                  transferEntries: [],
                  offset: 0,
                  filters: {
                    ...prevState.filters,
                    acceptedType:
                      option.value === null
                        ? []
                        : [
                            {
                              field: "is_accepted",
                              type: "=",
                              value: option.value,
                            },
                          ],
                  },
                }));
                setPageConfig(PAGE_KEYS.TRANSFER_SCHEDULE, {
                  acceptedType:
                    option.value === null
                      ? []
                      : [
                          {
                            field: "is_accepted",
                            type: "=",
                            value: option.value,
                          },
                        ],
                });
              }}
            />
            <Dropdown
              classes={{
                paper: classes.tunePaper,
              }}
              disablePortal={false}
              remainOpen
              isMultiSelect
              placement="bottom-end"
              options={Object.keys(state.dynamicColumns).map(
                (key) => state.dynamicColumns[key]
              )}
              customToggle={({ anchorRef, onClick }) => (
                <Tooltip title="Show/Hide column(s)" placement="top-start">
                  <IconButton color="primary" ref={anchorRef} onClick={onClick}>
                    <TuneIcon />
                  </IconButton>
                </Tooltip>
              )}
              onChange={(options) => {
                const isSelectAll = options.some(
                  (item) => item.value === "select_all"
                );
                const isCurrentSelectAll =
                  state.dynamicColumns?.select_all?.isSelected;

                const isClickedOnSelectAll = isSelectAll !== isCurrentSelectAll;

                const isAllOptionSelected =
                  Object.keys(state.dynamicColumns).length - 1 ===
                  options.filter((item) => item.value !== "select_all").length;
                const updatedDynamicColumns = Object.keys(
                  state.dynamicColumns
                ).reduce((acc, key) => {
                  const isSelected = isClickedOnSelectAll
                    ? isSelectAll
                    : key === "select_all"
                    ? isAllOptionSelected
                    : !!options.some((item) => item.value === key);
                  return {
                    ...acc,
                    [key]: {
                      ...state.dynamicColumns[key],
                      isSelected,
                    },
                  };
                }, {});
                setPageConfig(PAGE_KEYS.TRANSFER_SCHEDULE, {
                  dynamicColumns: updatedDynamicColumns,
                });
                setState((prevState) => ({
                  ...prevState,
                  dynamicColumns: {
                    ...prevState.dynamicColumns,
                    ...updatedDynamicColumns,
                  },
                }));
              }}
            />
            <Tooltip placement="top-start" title="Filter">
              <IconButton
                color="primary"
                disabled={state.isFetching}
                className={clsx({
                  "bg-primary": !!state.pageFilters?.length,
                })}
                onClick={() => {
                  setState((prevState) => ({
                    ...prevState,
                    isFiltering: true,
                  }));
                }}
              >
                <FilterListIcon
                  className={clsx({
                    "color-white": !!state.pageFilters?.length,
                  })}
                />
              </IconButton>
            </Tooltip>
            <Tooltip placement="top-end" title="Reset All Filters">
              <IconButton
                color="primary"
                onClick={() => {
                  updatePageConfig("transfer_schedule");
                  window.location.reload();
                }}
              >
                <RotateLeftIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <Grid
          columns={columnConfig}
          rows={state.transferEntries.map((entry, rowIndex) => ({
            ...entry,
            rowIndex,
            preventSelection: entry.status !== "PENDING",
            className: clsx({
              [classes.isAccepted]: !!entry.is_accepted,
            }),
          }))}
          isLoading={state.isFetching}
          hasMoreEntries={state.totalEntries}
          isLazyLoadingAdded={true}
          search={state.search}
          handleScroll={handleScroll}
          containerRef={containerRef}
          isInfiniteLoading={state.isInfiniteLoading}
          order={state.order}
          orderBy={state.orderBy}
          onReady={handleGridHelper}
          hasPagination={false}
          onSelectionChange={handleSelectionChange}
          onSortChange={handleSortChange}
          actionBarConfig={null}
          classes={{
            gridActions: "f-justify-end",
          }}
        />

        {state.transferEntries.length > 0 && !state.isFetching && (
          <div className={clsx("d-flex p-absolute", classes.dateLegendCopy)}>
            <div className="d-flex f-align-center ml-2 ">
              <div
                className={clsx(
                  "mr-2",
                  classes.legendHint,
                  classes["isAccepted"]
                )}
              ></div>
              <Typography variant="body2">Transfer Record Accepted</Typography>
            </div>
          </div>
        )}
      </Paper>
    </div>
  );
};

export default TransferView;
