import { http, responseFormatter } from "utils";

const get = (query) => {
  return responseFormatter(
    http.get(`/get-all-transferred-schedule/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const transferSchedule = (data) => {
  return responseFormatter(
    http.post("/transfer-schedule", data, {
      setAuthHeader: true,
    })
  );
};

const getSubmissionHistory = (kamId, month, year) => {
  const timeZoneOffset = new Date().getTimezoneOffset();
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let query = `?kamId=${kamId}&month=${month}&year=${year}&timeZoneString=${timeZoneString}`;
  // let query = `?kamId=${kamId}&month=${month}&year=${year}`;
  if (timeZoneOffset >= 0) {
    query += `&offSet=${timeZoneOffset}`;
  }

  return responseFormatter(
    http.get(`kam-submission-history${query}`, {
      setAuthHeader: true,
    })
  );
};

const submitSchedule = (data) => {
  return responseFormatter(
    http.post("/submit-schedule", data, {
      setAuthHeader: true,
    })
  );
};

const getCustomerLocation = (query) => {
  return responseFormatter(
    http.get(`/customer/get-locations${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getKamSetting = (kamId) => {
  return responseFormatter(
    http.get(`user/${kamId}/settings`, {
      setAuthHeader: true,
    })
  );
};

const approveTransferShedule = (id) => {
  return responseFormatter(
    http.patch(`/approve-transfer-schedule`, id, {
      setAuthHeader: true,
    })
  );
};
const rejectTransferShedule = (id) => {
  return responseFormatter(
    http.patch(`/reject-transfer-schedule`, id, {
      setAuthHeader: true,
    })
  );
};

const TransferScheduleService = {
  get,
  getSubmissionHistory,
  getKamSetting,
  transferSchedule,
  submitSchedule,
  getCustomerLocation,
  approveTransferShedule,
  rejectTransferShedule,
};

export default TransferScheduleService;
