import { lighten, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: 30,
  },
  row: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  container: {
    minHeight: 200,
    maxHeight: 350,
    overflow: "auto",
  },
  customerHeadBorder: {
    borderBottom: `2px solid #DDDDDD"`,
    boxShadow: " 4px 4px 4px #DDDDDD",
  },

  customerMainSeparatorBorder: {
    // borderBottom: `2px solid grey`,
    boxShadow: " 4px -4px 14px #DDDDDD inset",
  },

  customerInternalSeparatorBorder: {
    //boxShadow: " 4px 4px 4px grey",
    boxShadow: " 4px -4px 14px #DDDDDD inset",
  },
  tableHeadPadding: { padding: "2px 16px 2px 0px !important" },
  tableHeadPaddingLeft: { padding: "2px 16px 2px 16px !important" },
  tableBottonMargin: { marginBottom: "0px" },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },

  tablePadding: {
    padding: "2px 16px 2px 0px !important",
  },
  tableCellPadding: {
    padding: "2px 16px 2px 1px !important",
  },
  tableCellLevelTwo: {
    minWidth: "92px",
    maxWidth: "92px",
  },
  tableCell: {
    minWidth: 100,
    maxWidth: 200,
  },
  tableTwoPadding: {
    width: "22px",
    padding: "2px 0px 2px 16px !important",
  },
  noRecordsImg: {
    maxWidth: 600,
    maxHeight: 500,
  },

  tableHeadCellEmpty: {
    "&:hover": {
      backgroundColor: "#00000",
      "& div[data-role='resize']": {
        visibility: "visible",
      },
    },
  },

  tableHeadCell: {
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.light, 0.9),
      "& div[data-role='resize']": {
        visibility: "visible",
      },
    },
  },
  resizeIconWrapper: {
    width: 2,
    cursor: "col-resize",
    userSelect: "none",
    visibility: "hidden",
    right: 0,
    top: 0,
    bottom: 0,
  },
  resizeIcon: {
    background: lighten(theme.palette.primary.light, 0.9),
    opacity: 0.6,
    width: 2,
    height: 15,
  },
}));

export const usePaginationStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

export default useStyles;
