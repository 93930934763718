import makeStyles from "@material-ui/core/styles/makeStyles";
const drawerWidth = 296;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: (props) =>
      props.isDashboard
        ? "none"
        : theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: "hidden",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(12) + 1,
    },
    "&::-webkit-scrollbar": {
      width: "3px",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "rgb(241 241 241)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#c1c1c1",
    },
  },
  activeListItem: {
    background: "#accf53",
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    "& svg": {
      color: theme.palette.primary.main,
    },
    marginLeft: "0 !important",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  poweredBy: {
    display: (props) => (props.isOpen ? "block" : "none"),
    width: "100%",
    textAlign: "center",
    color: theme.palette.grey[500],
    position: "absolute",
    // bottom: "32px",
    bottom: "4px",
    "& img": {
      width: "92px",
      // marginLeft: 20,
      // marginRight: '8px',
      // float: 'left',
    },
    "& p": {
      width: "100%",
      position: "relative",
      fontSize: theme.typography.fontSize,
      letterSpacing: "0.5px",
      marginBottom: "11px",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: (props) =>
      `calc(100% - ${
        props.isOpen ? drawerWidth : theme.spacing(7) + 1
      }px - 50px)`,
  },
  listItem: {
    "&:hover": {
      borderLeft: `4px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      "& svg": {
        color: theme.palette.primary.main,
      },
      marginLeft: "0 !important",
    },
  },
  poweredByHeader: {
    background: "#fff",
    color: theme.palette.grey[500],
    "& p": {
      fontSize: theme.typography.fontSize,
      letterSpacing: "0.5px",
      marginBottom: "0.5px",
    },
  },
  pdxLogo: {
    [theme.breakpoints.down("sm")]: {
      height: 68,
    },
    height: 78,
    marginBottom: 8,
  },
}));

export default useStyles;
