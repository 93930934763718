/* eslint-disable eqeqeq */
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  Menu,
  MenuItem,
  OutlinedInput,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";

import useStyles from "./style";
import clsx from "clsx";
import { ActionDialog, AppContext, Datepicker, VALIDATIONS } from "shared";
import InvoiceTable from "../grid";
import { toast } from "react-toastify";
import Service from "../service";
import { getDateString, getUTCDateString, validator } from "utils";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import GenerateInvoicePDF from "../generateInvoicePdf";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AddInvoiceItemDialog from "./invoiceItem/index";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { PictureAsPdf as PdfIcon } from "@material-ui/icons";
import DescriptionIcon from "@material-ui/icons/Description";
import NavigationIcon from "@material-ui/icons/Navigation";

let isFieldChange = false;

const defaultState = {
  entries: {},
  pdxCompany: null,
  addBillTo: false,
  isFetchingList: {
    customers: false,
    pdxCompany: false,
  },
  startDate: getDateString(new Date().setDate(new Date().getDate() - 7)),
  endDate: getDateString(new Date()),
  invoiceNo: null,
  billTo: "",
  customerNotes: "",
  termsAndCondition: "",
  isDataSaved: false,
  route: null,
  terms: 7,
  invoiceDate: getDateString(new Date()),
  status: null,
  showGrid: false,
  customer: null,
  isDraft: false,
  anchorE2: false,
  pdxCompanyList: [],
  streetAddress: "",
  city: "",
  zipCode: "",
  state: "",
  country: "",
  phone: "",
  primaryContact: "",
  contact: "",
  discount: 0,
  discountAmount: 0,
  adjustment: 0,
  errors: {
    phone: " ",
    terms: " ",
    discount: " ",
    discountAmount: " ",
    adjustment: " ",
    primaryContact: " ",
    customerNotes: " ",
    termsAndCondition: " ",
    contact: " ",
    streetAddress: " ",
    city: " ",
    zipCode: " ",
    state: " ",
    country: " ",
  },
  addInvoiceItem: false,
  isDiscountAmount: false,
  invoiceItemEdit: false,
  invoiceItemDelete: false,
  invoiceItemId: null,
  invoiceRowBeingEdited: [],
  pdfUrl: "",
  pdfViewerDialog: false,
  anchorEl: null,
  finalizeInvoiceData: false,
  isFinalised: false,
};

const LoadingOverlay = ({ message }) => (
  <Backdrop open={true} style={{ zIndex: 1301 }}>
    <div style={{ textAlign: "center", color: "#fff" }}>
      <CircularProgress color="inherit" />
      <Typography variant="h6" style={{ marginTop: "16px" }}>
        {message}
      </Typography>
    </div>
  </Backdrop>
);

const DraftInvoice = ({ history, location }) => {
  const { appData } = useContext(AppContext);
  const draftInvoice = location.state || {};
  const classes = useStyles({ isTabletView: appData.isTabletView });
  const [state, setState] = useState(defaultState);

  const getDraftInvoice = async () => {
    if (!draftInvoice?.invoiceId) return;
    setState((prevState) => ({ ...prevState, isLoading: true }));

    let queryString = `?invoiceIds=${draftInvoice?.invoiceId}`;

    const { data, error } = await Service.getAllInvoiceDetails(queryString);
    const invoiceEntries = data?.invoiceDataArray?.[0];

    if (error) {
      setState((prevState) => ({ ...prevState, isLoading: false }));
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      return null;
    }

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      entries: invoiceEntries || defaultState.entries,
    }));
    return invoiceEntries;
  };

  const saveInvoiceDetails = async (payload) => {
    if (!draftInvoice?.invoiceId) return;
    setState((prevState) => ({ ...prevState, isDataSaved: true }));

    const { error } = await Service.update(payload);

    if (error) {
      setState((prevState) => ({ ...prevState, isDataSaved: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success("Invoice updated successfully.");
    setState((prevState) => ({
      ...prevState,
      isDataSaved: false,
      finalizeInvoiceData: false,
    }));
    isFieldChange = false;
    getDraftInvoice();
  };

  const finalizeInvoiceDetails = async (payload) => {
    if (!draftInvoice?.invoiceId) return;
    setState((prevState) => ({ ...prevState, isFinalised: true }));

    const { error } = await Service.finalizeInvoice(payload);

    if (error) {
      setState((prevState) => ({ ...prevState, isFinalised: false }));
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      return null;
    }
    toast.success("Invoice is finalized.");
    setState((prevState) => ({
      ...prevState,
      isFinalised: false,
      finalizeInvoiceData: false,
    }));
    isFieldChange = false;
    history.push({
      pathname: "/invoice/view_invoice",
      state: {
        viewInvoiceSummary: true,
        selectedInvoice: state.entries?.invoiceDetails,
      },
    });
  };

  const handleOpen = (event) => {
    setState((prevState) => ({
      ...prevState,
      anchorE2: event.currentTarget,
    }));
  };

  const handleClose = (close) => {
    setState((prevState) => ({
      ...prevState,
      addInvoiceItem: close,
    }));
  };
  const handleEditClose = (close) => {
    setState((prevState) => ({
      ...prevState,

      invoiceItemEdit: close,
      invoiceRowBeingEdited: defaultState.invoiceRowBeingEdited,
    }));
  };

  const handleGetInvoice = useCallback(() => {
    const pollDraftInvoice = async () => {
      try {
        const data = await getDraftInvoice();

        if (
          data?.status === "COMPLETED" ||
          data?.status === "ERROR" ||
          data?.status === undefined ||
          data?.status === null
        ) {
          clearInterval(intervalId);
        }
      } catch (error) {
        clearInterval(intervalId);
      }
    };

    const intervalId = setInterval(pollDraftInvoice, 1000);

    pollDraftInvoice();

    return () => clearInterval(intervalId);
  }, []);

  const handleGeneratePDF = (value) => {
    const invoiceData = {
      invoiceDetails: state.entries.lineItems,
      invoiceNo: state.invoiceNo,
      invoiceDate: state.invoiceDate,
      termsAndCondition: state.termsAndCondition,
      terms: state.terms,
      customerNotes: state.customerNotes,
      invoiceStatus: state.entries?.invoiceDetails?.status,
      pdxCompany: state.pdxCompany.pdxCompany.value,
      balanceAmount: state.entries?.invoiceDetails?.balance_amt,
      paidAmount: state.entries?.invoiceDetails?.paid_amt,
      isOverdue: state.entries?.invoiceDetails?.is_overdue,
      completePaymentDoneOn:
        state.entries?.invoiceDetails?.complete_payment_done_on,
      billTo: {
        customerName: state.entries?.invoiceDetails?.bill_to?.customerName,
        branch: state.entries?.invoiceDetails?.bill_to?.branch,
        streetAddress: state.streetAddress,
        state: state.state,
        city: state.city,
        country: state.country,
        zipCode: state.zipCode,
        phone: state.phone,
        contact: state.contact,
      },
      discount: state.entries?.invoiceDetails?.discount_amt,
      isPreview: value,
    };

    if (!value) {
      GenerateInvoicePDF(invoiceData);
    } else {
      const blob = GenerateInvoicePDF(invoiceData);
      setState((prevState) => ({
        ...prevState,
        pdfUrl: blob,
        pdfViewerDialog: true,
      }));
    }
  };

  const handleViewerClose = () => {
    setState((prevState) => ({ ...prevState, pdfViewerDialog: false }));
  };

  useEffect(() => {
    handleGetInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!state.entries && Object.keys(state.entries).length > 0) {
      setState((prevState) => ({
        ...prevState,
        startDate:
          getUTCDateString(state.entries.invoiceDetails?.from_date) ||
          defaultState.startDate,
        endDate:
          getUTCDateString(state.entries.invoiceDetails?.to_date) ||
          defaultState.endDate,
        pdxCompany:
          draftInvoice.customerList
            .map((item) =>
              item.customer_branches.find(
                (branch) =>
                  branch.id === state.entries.invoiceDetails?.customer_branch_id
              )
            )
            .find((branch) => branch !== undefined) || defaultState.pdxCompany,
        discount:
          state.entries.invoiceDetails.discount || defaultState.discount,
        discountAmount:
          state.entries.invoiceDetails.discount_amt ||
          defaultState.discountAmount,
        location:
          draftInvoice.customerList
            .map((item) =>
              item.customer_branches.find(
                (branch) =>
                  branch.id === state.entries.invoiceDetails?.customer_branch_id
              )
            )
            .find((branch) => branch !== undefined) || defaultState.location,
        isDiscountAmount:
          (!!state.entries.invoiceDetails?.discount_method &&
            (state.entries.invoiceDetails?.discount_method === "percentage"
              ? false
              : true)) ||
          defaultState.isDiscountAmount,
        route:
          (!!state.entries.lineItems &&
            state.entries.lineItems.length === 1 &&
            state.entries.lineItems[0]?.route_name) ||
          defaultState.location,
        customer:
          draftInvoice.customerList.find((item) =>
            item.customer_branches.some(
              (branch) =>
                branch.id === state.entries.invoiceDetails?.customer_branch_id
            )
          ) || defaultState.customer,
        invoiceNo:
          state.entries.invoiceDetails?.serial_no || defaultState.invoiceNo,
        terms: state.entries.invoiceDetails?.terms || defaultState.terms,
        billTo:
          [
            state.entries.invoiceDetails?.bill_to?.street_address,
            state.entries.invoiceDetails?.bill_to?.state,
            state.entries.invoiceDetails?.bill_to?.city,
            state.entries.invoiceDetails?.bill_to?.country,
            state.entries.invoiceDetails?.bill_to?.zip_code,
            state.entries.invoiceDetails?.bill_to?.phone,
            state.entries.invoiceDetails?.bill_to?.contact,
          ]
            .filter((detail) => detail)
            .join(",\n") || defaultState.billTo,
        isDraft: state.entries.invoiceDetails?.is_draft || defaultState.isDraft,
        customerNotes:
          state.entries.invoiceDetails?.customer_notes ||
          defaultState.customerNotes,
        termsAndCondition:
          state.entries.invoiceDetails?.terms_and_condition ||
          defaultState.termsAndCondition,
        streetAddress:
          state.entries.invoiceDetails?.bill_to?.street_address ||
          defaultState.streetAddress,
        state:
          state.entries.invoiceDetails?.bill_to?.state || defaultState.state,
        city: state.entries.invoiceDetails?.bill_to?.city || defaultState.city,
        country:
          state.entries.invoiceDetails?.bill_to?.country ||
          defaultState.country,
        zipCode:
          state.entries.invoiceDetails?.bill_to?.zip_code ||
          defaultState.zipCode,
        phone:
          state.entries.invoiceDetails?.bill_to?.phone || defaultState.phone,
        contact:
          state.entries.invoiceDetails?.bill_to?.contact ||
          defaultState.contact,
        invoiceDate:
          getUTCDateString(state.entries.invoiceDetails?.invoice_date) ||
          defaultState.invoiceDate,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.entries]);

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = {
      phone: [
        { type: VALIDATIONS.MIN_LENGTH, value: 10 },
        { type: VALIDATIONS.MAX_LENGTH, value: 30 },
      ],
      primaryContact: [{ type: VALIDATIONS.MAX_LENGTH, value: 100 }],
      contact: [{ type: VALIDATIONS.MAX_LENGTH, value: 100 }],
      streetAddress: [{ type: VALIDATIONS.MAX_LENGTH, value: 100 }],
      city: [{ type: VALIDATIONS.MAX_LENGTH, value: 100 }],
      termsAndCondition: [{ type: VALIDATIONS.MAX_LENGTH, value: 2000 }],
      customerNotes: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
      zipCode: [{ type: VALIDATIONS.MAX_LENGTH, value: 10 }],
      state: [{ type: VALIDATIONS.MAX_LENGTH, value: 100 }],
      country: [{ type: VALIDATIONS.MAX_LENGTH, value: 100 }],
      terms: [{ type: VALIDATIONS.MAX_LENGTH, value: 10 }],
      discount: [{ type: VALIDATIONS.MAX, value: 100 }],
      adjustment: [{ type: VALIDATIONS.MAX_LENGTH, value: 5 }],
    };

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const handleFieldChange = (evt) => {
    isFieldChange = true;
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    let errorMessage = validate(field, value) || " ";
    const totalAmount =
      state.entries.invoiceDetails.total_amount_calculated || 0;

    if (
      (field === "discount" || field === "discountAmount") &&
      value.indexOf(".") !== -1
    ) {
      let s = value.toString().split(".");
      let length = s[1]?.length > 2;
      if (length) {
        value = +value;
        value = Math.round((+value + Number.EPSILON) * 100) / 100;
        value = value.toFixed(2);
      }
    }
    if (
      (field === "discount" || field === "discountAmount") &&
      value?.length > 1 &&
      value.indexOf(".") === -1
    ) {
      value = value?.replace(/^0+/, "");
    }

    if (field === "discount") {
      const discountAmount = (
        (parseFloat(totalAmount) * parseFloat(value || 0)) /
        100
      ).toFixed(2);

      setState((prevState) => ({
        ...prevState,
        [field]: value || 0,
        discountAmount: value == 0 ? 0 : discountAmount,
        errors: {
          ...prevState.errors,
          [field]: errorMessage,
        },
      }));
    } else if (field === "discountAmount") {
      const inputDiscountAmount = parseFloat(value || 0);

      if (inputDiscountAmount <= totalAmount) {
        const discountPercentage = (
          (inputDiscountAmount / totalAmount) *
          100
        ).toFixed(2);

        setState((prevState) => ({
          ...prevState,
          [field]: value || 0,
          discount: value == 0 ? 0 : discountPercentage,
        }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        [field]: value,
        errors: {
          ...prevState.errors,
          [field]: errorMessage,
        },
      }));
    }
  };

  const handleAddAddress = (evt) => {
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    let errorMessage = validate(field, value) || " ";

    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  const handleDeleteDialog = (id) => {
    setState((prevState) => ({
      ...prevState,
      invoiceItemDelete: true,
      invoiceItemId: id,
    }));
  };
  const handleDiscount = (isDiscountAmount) => {
    isFieldChange = true;
    setState((prevState) => ({
      ...prevState,
      isDiscountAmount,
      discount: defaultState.discount,
      discountAmount: defaultState.discountAmount,
      errors: {
        ...prevState.errors,
        discount: " ",
      },
    }));
  };
  const handleEditDialog = (id) => {
    setState((prevState) => ({
      ...prevState,
      invoiceItemEdit: true,
      invoiceRowBeingEdited: state.entries?.lineItems?.filter(
        (items) => items.id === id
      ),
      invoiceItemId: id,
    }));
  };

  const handleDeleteInvoiceRow = async (itemId) => {
    setState((prevState) => ({
      ...prevState,
      isItemDeleting: true,
    }));

    let queryString = `?id=${itemId}`;

    const { error } = await Service.deleteLineItem(queryString);

    setState((prevState) => ({
      ...prevState,
      isItemDeleting: false,
      invoiceItemDelete: null,
    }));

    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      toast.success("Deleted successfully.");
      handleGetInvoice();
    }
  };

  const handleMenuOpen = (event) => {
    setState((prevState) => ({
      ...prevState,
      anchorEl: event.currentTarget,
    }));
  };

  const handleMenuClose = () => {
    setState((prevState) => ({
      ...prevState,
      anchorEl: defaultState.anchorEl,
      anchorE2: defaultState.anchorEl,
    }));
  };

  return (
    <>
      {state.isLoading && (
        <LoadingOverlay message="Processing, please wait..." />
      )}
      <div className={clsx(state.isLoading && classes.blur)}>
        <div className={classes.fixedHeader}>
          <Typography variant="h4" color="primary" className=" ml-2">
            Draft Invoice
            <Button
              variant="outlined"
              color="primary"
              className={clsx("mt-1 mr-2 pl-4 pr-4", classes.sentIcon)}
              startIcon={<NavigationIcon style={{ rotate: "270deg" }} />}
              onClick={handleOpen}
            >
              Navigate
            </Button>
            <Menu
              anchorEl={state.anchorE2}
              open={Boolean(state.anchorE2)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => history.push("/home")}>
                <ListItemIcon>
                  <ArrowBackIcon className={classes.backArrow} />
                </ListItemIcon>
                Back To Home
              </MenuItem>
              <MenuItem onClick={() => history.push("/invoice")}>
                <ListItemIcon>
                  <ArrowBackIcon className={classes.backArrow} />
                </ListItemIcon>
                Back To Invoice
              </MenuItem>
              <MenuItem onClick={() => history.push("/invoice/view_invoice")}>
                <ListItemIcon>
                  <ArrowBackIcon className={classes.backArrow} />
                </ListItemIcon>
                Back To View Invoice
              </MenuItem>
              <MenuItem
                onClick={() =>
                  history.push({
                    pathname: "/invoice/view_invoice",
                    state: {
                      viewInvoiceSummary: true,
                      selectedInvoice: state.entries?.invoiceDetails,
                    },
                  })
                }
              >
                <ListItemIcon>
                  <ArrowBackIcon className={classes.backArrow} />
                </ListItemIcon>
                Back To View Invoice Summary
              </MenuItem>
            </Menu>
          </Typography>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            View Draft Invoice.
          </Typography>
        </div>
        <div className="d-flex f-align-center f-justify-between f-wrap">
          <div className="d-flex f-align-center f-wrap">
            <Datepicker
              mask
              preventClear
              disabled
              label="Start date"
              selected={new Date(state.startDate)}
              maxDate={new Date(state.endDate)}
              classes={{
                input: {
                  root: clsx("mr-4 mt-4", classes.datepickerWrapper),
                },
              }}
            />
            <Datepicker
              mask
              disabled
              preventClear
              label="End date"
              selected={new Date(state.endDate)}
              minDate={new Date(state.startDate)}
              maxDate={new Date()}
              classes={{
                input: {
                  root: clsx("mr-4 mt-4", classes.datepickerWrapper),
                },
              }}
            />
            <TextField
              className={clsx("mr-4 mt-4", classes.customerDropdown)}
              disabled
              label="PDX Company"
              variant="outlined"
              size="small"
              value={state.pdxCompany?.pdxCompany?.value || "-"}
            />
            <TextField
              className={clsx("mr-4 mt-4", classes.customerDropdown)}
              disabled
              label="Customer"
              variant="outlined"
              size="small"
              value={state.customer?.name || "-"}
            />
            <TextField
              className={clsx("mr-4 mt-4", classes.customerDropdown)}
              disabled
              label="Location"
              variant="outlined"
              size="small"
              value={state.location?.location || "-"}
            />
            {/* <TextField
              className={clsx("mr-4 mt-4", classes.customerDropdown)}
              disabled
              label="Route"
              variant="outlined"
              size="small"
              value={state.route || "-"}
            /> */}
          </div>
          <div className="d-flex f-align-center">
            <Datepicker
              mask
              preventClear
              label="Date"
              disabled={
                (!!state.entries?.lineItems &&
                  state.entries?.lineItems.length === 0) ||
                state.entries?.invoiceDetails?.status === "pending-approval" ||
                state.entries?.invoiceDetails?.status === "approved"
              }
              selected={new Date(state.invoiceDate)}
              minDate={new Date(state.startDate)}
              maxDate={new Date()}
              classes={{
                input: {
                  root: clsx("mr-4 mt-4", classes.datepickerWrapper),
                },
              }}
              onChange={(date) => {
                isFieldChange = true;
                setState((prevState) => ({
                  ...prevState,
                  invoiceDate: getDateString(date),
                }));
              }}
            />
            <TextField
              className="w-50 mt-4"
              disabled
              label="Invoice #"
              variant="outlined"
              size="small"
              value={state.invoiceNo || ""}
            />
          </div>
        </div>
        <div className={clsx("d-flex  mt-5", classes.content)}>
          <div className={clsx("p-4 mr-3 d-flex flex-column", classes.paper)}>
            <Box className="d-flex f-align-center">
              <Typography
                variant="body1"
                style={{ marginRight: 8, fontWeight: "bold", marginBottom: 22 }}
              >
                TERMS:
              </Typography>
              <Typography
                variant="body1"
                style={{ marginRight: 8, marginBottom: 22 }}
              >
                Net
              </Typography>
              <TextField
                style={{ width: 62 }}
                label="Days"
                variant="outlined"
                disabled={
                  (!!state.entries?.lineItems &&
                    state.entries?.lineItems.length === 0) ||
                  state.entries?.invoiceDetails?.status ===
                    "pending-approval" ||
                  state.entries?.invoiceDetails?.status === "approved"
                }
                size="small"
                type="number"
                name="terms"
                value={state.terms}
                error={!!state.errors.terms?.trim()}
                helperText={state.errors.terms}
                onChange={handleFieldChange}
              />
            </Box>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="bill-to">Bill To*</InputLabel>
              <OutlinedInput
                id="bill-to"
                label="Bill To"
                variant="outlined"
                size="small"
                helperText=" "
                disabled
                required
                value={`${state.entries?.invoiceDetails?.bill_to.customerName}, ${state.entries?.invoiceDetails?.bill_to.branch}, ${state.billTo}`}
                style={{ color: "black" }}
                minRows={6}
                maxRows={8}
                multiline
                endAdornment={
                  <InputAdornment position="end" style={{ marginTop: "90px" }}>
                    <Tooltip
                      title={!!state.billTo ? "Edit Address" : "Add Address"}
                      placement="top-start"
                    >
                      <IconButton
                        disabled={
                          (!!state.entries?.lineItems &&
                            state.entries?.lineItems.length === 0) ||
                          state.entries?.invoiceDetails?.status ===
                            "pending-approval" ||
                          state.entries?.invoiceDetails?.status === "approved"
                        }
                        color="primary"
                        onClick={() =>
                          setState((prevState) => ({
                            ...prevState,
                            addBillTo: true,
                          }))
                        }
                      >
                        {!!state.billTo ? (
                          <EditIcon fontSize="medium" />
                        ) : (
                          <AddCircleIcon fontSize="large" />
                        )}
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                }
              />
            </FormControl>
            <TextField
              name="customerNotes"
              label="Customer Note"
              variant="outlined"
              size="small"
              value={state.customerNotes}
              className={classes.customerNotes}
              disabled={
                (!!state.entries?.lineItems &&
                  state.entries?.lineItems.length === 0) ||
                state.entries?.invoiceDetails?.status === "pending-approval" ||
                state.entries?.invoiceDetails?.status === "approved"
              }
              minRows={2}
              maxRows={4}
              multiline
              error={!!state.errors.customerNotes?.trim()}
              helperText={state.errors.customerNotes}
              onChange={handleFieldChange}
            />
            <TextField
              name="termsAndCondition"
              label="Payment Terms And Condition"
              variant="outlined"
              size="small"
              className="mb-3"
              value={state.termsAndCondition}
              disabled={
                (!!state.entries?.lineItems &&
                  state.entries?.lineItems.length === 0) ||
                state.entries?.invoiceDetails?.status === "pending-approval" ||
                state.entries?.invoiceDetails?.status === "approved"
              }
              minRows={4}
              maxRows={7}
              multiline
              error={!!state.errors.termsAndCondition?.trim()}
              helperText={state.errors.termsAndCondition}
              onChange={handleFieldChange}
            />
          </div>
          <Paper
            elevation={2}
            className={clsx("p-4 mt-10", classes.paperSpacing)}
          >
            <Grid container spacing={1} style={{ overflowX: "auto" }}>
              <Grid item xs={12}>
                <InvoiceTable
                  rows={state.entries?.lineItems || []}
                  finalize={
                    state.entries?.invoiceDetails?.status ===
                      "pending-approval" ||
                    state.entries?.invoiceDetails?.status === "approved"
                  }
                  handleFieldChange={handleFieldChange}
                  discount={state.discount}
                  discountAmount={state.discountAmount}
                  adjustment={state.adjustment}
                  errors={state.errors}
                  isDiscountAmount={state.isDiscountAmount}
                  isDraft={state.isDraft}
                  handleDeleteDialog={handleDeleteDialog}
                  handleDiscount={handleDiscount}
                  handleEditDialog={handleEditDialog}
                />
              </Grid>
            </Grid>
          </Paper>
        </div>
        <div className={classes.footerContainer}>
          <div
            className="d-flex f-align-center"
            style={{ float: "right", paddingRight: 64 }}
          >
            <Button
              variant="outlined"
              color="primary"
              className="ml-2 mr-2 mt-2"
              startIcon={<AddCircleOutlineIcon />}
              disabled={
                state.entries?.invoiceDetails?.status === "pending-approval" ||
                state.entries?.invoiceDetails?.status === "approved"
              }
              onClick={() => {
                if (
                  isFieldChange &&
                  !(
                    parseFloat(state.discountAmount).toFixed(2) ===
                      parseFloat(
                        state.entries.invoiceDetails?.discount_amt || 0
                      ).toFixed(2) &&
                    parseFloat(state.discount).toFixed(2) ===
                      parseFloat(
                        state.entries.invoiceDetails?.discount || 0
                      ).toFixed(2) &&
                    state.invoiceDate ===
                      getUTCDateString(
                        state.entries.invoiceDetails?.invoice_date
                      ) &&
                    state.terms == state.entries.invoiceDetails?.terms &&
                    state.customerNotes ===
                      state.entries.invoiceDetails?.customer_notes &&
                    state.termsAndCondition ===
                      state.entries.invoiceDetails?.terms_and_condition
                  )
                ) {
                  return toast.error("Please save the changes first.");
                }
                setState((prevState) => ({
                  ...prevState,
                  addInvoiceItem: true,
                }));
              }}
            >
              Add
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className="ml-2 mr-4 mt-2"
              startIcon={<DescriptionIcon />}
              disabled={
                (state.entries?.lineItems &&
                  state.entries?.lineItems.length === 0) ||
                (state.billTo && !state.billTo?.length) ||
                (isFieldChange &&
                  !(
                    parseFloat(state.discountAmount).toFixed(2) ===
                      parseFloat(
                        state.entries.invoiceDetails?.discount_amt || 0
                      ).toFixed(2) &&
                    parseFloat(state.discount).toFixed(2) ===
                      parseFloat(
                        state.entries.invoiceDetails?.discount || 0
                      ).toFixed(2) &&
                    state.invoiceDate ===
                      getUTCDateString(
                        state.entries.invoiceDetails?.invoice_date
                      ) &&
                    state.terms == state.entries.invoiceDetails?.terms &&
                    state.customerNotes ===
                      state.entries.invoiceDetails?.customer_notes &&
                    state.termsAndCondition ===
                      state.entries.invoiceDetails?.terms_and_condition
                  ))
              }
              onClick={handleMenuOpen}
            >
              View/Download
            </Button>
            <Menu
              anchorEl={state.anchorEl}
              open={Boolean(state.anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleGeneratePDF(true)}>
                <ListItemIcon>
                  <VisibilityIcon />
                </ListItemIcon>
                Preview
              </MenuItem>
              <MenuItem onClick={() => handleGeneratePDF(false)}>
                <ListItemIcon>
                  <PdfIcon />
                </ListItemIcon>
                Download
              </MenuItem>
            </Menu>
            <Button
              variant="contained"
              color="primary"
              className="mr-4 mt-2"
              disabled={
                (parseFloat(state.discountAmount).toFixed(2) ===
                  parseFloat(
                    state.entries.invoiceDetails?.discount_amt || 0
                  ).toFixed(2) &&
                  parseFloat(state.discount).toFixed(2) ===
                    parseFloat(
                      state.entries.invoiceDetails?.discount || 0
                    ).toFixed(2) &&
                  state.invoiceDate ===
                    getUTCDateString(
                      state.entries.invoiceDetails?.invoice_date
                    ) &&
                  state.terms == state.entries.invoiceDetails?.terms &&
                  state.customerNotes ===
                    state.entries.invoiceDetails?.customer_notes &&
                  state.termsAndCondition ===
                    state.entries.invoiceDetails?.terms_and_condition) ||
                !isFieldChange ||
                state.isDataSaved ||
                validate() ||
                (!!state.entries?.lineItems &&
                  state.entries?.lineItems.length === 0)
              }
              onClick={() => {
                const invoiceDetails = {
                  id: draftInvoice?.invoiceId,
                  customer_notes: state.customerNotes,
                  terms_and_condition: state.termsAndCondition,
                  discount_method: state.isDiscountAmount
                    ? "amount"
                    : "percentage",
                  terms: state.terms || 0,
                  invoice_date: state.invoiceDate,
                  bill_to: {
                    customerName:
                      state.entries?.invoiceDetails?.bill_to?.customerName,
                    branch: state.entries?.invoiceDetails?.bill_to?.branch,
                    street_address: state.streetAddress,
                    state: state.state,
                    city: state.city,
                    country: state.country,
                    zip_code: state.zipCode,
                    phone: state.phone,
                    contact: state.contact,
                  },
                  ...(state.isDiscountAmount
                    ? { discount_amt: state.discountAmount || 0 }
                    : { discount: state.discount || 0 }),
                };

                saveInvoiceDetails(invoiceDetails);
              }}
            >
              Save
              {state.isDataSaved && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="mr-10 mt-2"
              disabled={
                state.isFinalised ||
                state.isDataSaved ||
                validate() ||
                (!!state.entries?.lineItems &&
                  state.entries?.lineItems.length === 0) ||
                state.entries?.invoiceDetails?.status === "pending-approval" ||
                state.entries?.invoiceDetails?.status === "approved"
              }
              onClick={() => {
                if (
                  isFieldChange &&
                  !(
                    parseFloat(state.discountAmount).toFixed(2) ===
                      parseFloat(
                        state.entries.invoiceDetails?.discount_amt || 0
                      ).toFixed(2) &&
                    parseFloat(state.discount).toFixed(2) ===
                      parseFloat(
                        state.entries.invoiceDetails?.discount || 0
                      ).toFixed(2) &&
                    state.invoiceDate ===
                      getUTCDateString(
                        state.entries.invoiceDetails?.invoice_date
                      ) &&
                    state.terms == state.entries.invoiceDetails?.terms &&
                    state.customerNotes ===
                      state.entries.invoiceDetails?.customer_notes &&
                    state.termsAndCondition ===
                      state.entries.invoiceDetails?.terms_and_condition
                  )
                ) {
                  return toast.error("Please save your changes first.");
                }
                if (!state.billTo) {
                  return toast.error(
                    "Please add the bill to information before finalizing the invoice."
                  );
                }
                setState((prevState) => ({
                  ...prevState,
                  finalizeInvoiceData: true,
                }));
              }}
            >
              {state.isFinalised && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
              Finalize Invoice
            </Button>
          </div>
        </div>
      </div>
      {state.addBillTo && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.addBillTo}
          contentText={
            <div>
              <Typography variant="h5" color="primary" className=" ml-2">
                {!!state.billTo ? "Edit Address" : "Add Address"}
              </Typography>

              <br />

              <div className={classes.root}>
                <div className="d-flex f-align-center">
                  <TextField
                    required
                    className={classes.StreetAddress}
                    size="small"
                    variant="outlined"
                    name="streetAddress"
                    label="Street Address"
                    value={state.streetAddress}
                    error={!!state.errors.streetAddress?.trim()}
                    helperText={state.errors.streetAddress}
                    onChange={handleAddAddress}
                  />
                </div>
                <div className="d-flex f-align-center">
                  <TextField
                    required
                    className={classes.city}
                    size="small"
                    variant="outlined"
                    label="City"
                    name="city"
                    value={state.city}
                    error={!!state.errors.city?.trim()}
                    helperText={state.errors.city}
                    onChange={handleAddAddress}
                  />

                  <TextField
                    required
                    className={classes.state}
                    size="small"
                    variant="outlined"
                    label="State"
                    name="state"
                    value={state.state}
                    error={!!state.errors.state?.trim()}
                    helperText={state.errors.state}
                    onChange={handleAddAddress}
                  />
                </div>
                <div className="d-flex f-align-center">
                  <TextField
                    required
                    className={classes.zip}
                    size="small"
                    variant="outlined"
                    label="ZIP Code"
                    name="zipCode"
                    value={state.zipCode}
                    error={!!state.errors.zipCode?.trim()}
                    helperText={state.errors.zipCode}
                    onChange={handleAddAddress}
                  />
                  <TextField
                    required
                    className={classes.country}
                    name="country"
                    size="small"
                    variant="outlined"
                    label="Country"
                    value={state.country}
                    error={!!state.errors.country?.trim()}
                    helperText={state.errors.country}
                    onChange={handleAddAddress}
                  />
                </div>
                <div className="d-flex f-align-center">
                  <TextField
                    className={classes.phoneNumber}
                    name="phone"
                    label="Phone"
                    variant="outlined"
                    size="small"
                    value={state.phone}
                    error={!!state.errors?.phone?.trim()}
                    helperText={state.errors?.phone}
                    onChange={handleAddAddress}
                  />
                  <TextField
                    className={classes.contact}
                    name="contact"
                    label="Primary Contact"
                    variant="outlined"
                    size="small"
                    value={state.contact}
                    error={!!state.errors.contact?.trim()}
                    helperText={state.errors.contact}
                    onChange={handleAddAddress}
                  />
                </div>
              </div>
            </div>
          }
          onConfirm={() => {
            const invoiceDetails = {
              id: draftInvoice?.invoiceId,
              customer_notes: state.customerNotes,
              terms_and_condition: state.termsAndCondition,
              discount_method: state.isDiscountAmount ? "amount" : "percentage",
              terms: state.terms || 0,
              invoice_date: state.invoiceDate,
              bill_to: {
                customerName:
                  state.entries?.invoiceDetails?.bill_to?.customerName,
                branch: state.entries?.invoiceDetails?.bill_to?.branch,
                street_address: state.streetAddress,
                state: state.state,
                city: state.city,
                country: state.country,
                zip_code: state.zipCode,
                phone: state.phone,
                contact: state.contact,
              },
              ...(state.isDiscountAmount
                ? { discount_amt: state.discountAmount || 0 }
                : { discount: state.discount || 0 }),
            };

            saveInvoiceDetails(invoiceDetails);
            setState((prevState) => ({
              ...prevState,
              addBillTo: false,
              billTo:
                [
                  state.streetAddress,
                  state.city,
                  state.state,
                  state.zipCode,
                  state.country,
                  state.phone,
                  state.contact,
                ]
                  .filter((detail) => detail)
                  .join(",\n") || defaultState.billTo,
            }));
          }}
          onCancel={() => {
            if (!!state.billTo) {
              setState((prevState) => ({
                ...prevState,
                addBillTo: false,
                state: state.entries?.invoiceDetails?.bill_to?.state,
                streetAddress:
                  state.entries?.invoiceDetails?.bill_to?.street_address,
                country: state.entries?.invoiceDetails?.bill_to?.country,
                city: state.entries?.invoiceDetails?.bill_to?.city,
                phone: state.entries?.invoiceDetails?.bill_to?.phone,
                zipCode: state.entries?.invoiceDetails?.bill_to?.zip_code,
                contact: state.entries?.invoiceDetails?.bill_to?.contact,
              }));
            } else {
              setState((prevState) => ({
                ...prevState,
                addBillTo: false,
                state: defaultState.state,
                streetAddress: defaultState.streetAddress,
                country: defaultState.country,
                city: defaultState.city,
                phone: defaultState.phone,
                zipCode: defaultState.zipCode,
                contact: defaultState.contact,
              }));
            }
          }}
          positiveActionLabel="Save"
          negativeActionLabel="Cancel"
          isConfirmDisabled={
            validate() ||
            !state.streetAddress ||
            !state.city ||
            !state.state ||
            !state.zipCode ||
            !state.country
          }
        />
      )}
      {state.finalizeInvoiceData && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.finalizeInvoiceData}
          contentText={
            <Typography variant="body2" color="primary" className=" ml-2">
              {`Are you sure you want to finalize the invoice?\n After this you cannot make any changes.`}
            </Typography>
          }
          onConfirm={() => {
            setState((prevState) => ({
              ...prevState,
              finalizeInvoiceData: false,
            }));
            finalizeInvoiceDetails({
              id: draftInvoice?.invoiceId,
            });
          }}
          onCancel={() => {
            setState((prevState) => ({
              ...prevState,
              finalizeInvoiceData: false,
            }));
          }}
          positiveActionLabel={
            <>
              Save{" "}
              {(state.isDataSaved || state.isFinalised) && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          negativeActionLabel="Cancel"
          isConfirmDisabled={state.isDataSaved || state.isFinalised}
        />
      )}
      {state.invoiceItemDelete && (
        <ActionDialog
          classes={{
            confirm: "bg-danger",
            paper: classes.paperSize,
          }}
          open={state.invoiceItemDelete}
          contentText="Are you sure you want to delete?"
          onConfirm={() => handleDeleteInvoiceRow(state.invoiceItemId)}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              invoiceItemDelete: false,
            }))
          }
          isConfirmDisabled={state.isItemDeleting}
          positiveActionLabel={
            <>
              Delete
              {state.isItemDeleting && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
        />
      )}
      {(state.addInvoiceItem || state.invoiceItemEdit) && (
        <AddInvoiceItemDialog
          open={state.addInvoiceItem || state.invoiceItemEdit}
          isEditing={state.invoiceItemEdit}
          handleClose={!state.addInvoiceItem ? handleEditClose : handleClose}
          customer={state.customer}
          location={state.location}
          // routeName={state.route}
          pdxCompany={state.pdxCompany?.pdxCompany}
          invoiceId={draftInvoice?.invoiceId}
          invoiceItemId={state.invoiceItemId}
          handleGetInvoice={handleGetInvoice}
          invoiceRowBeingEdited={state.invoiceRowBeingEdited}
        />
      )}

      {state.pdfViewerDialog && (
        <Dialog
          onClose={handleViewerClose}
          aria-labelledby="customized-dialog-title"
          open={state.pdfViewerDialog}
          classes={{ paper: classes.paperIframe }}
        >
          <DialogContent dividers>
            <iframe
              type="application/pdf"
              src={`${state.pdfUrl}#toolbar=0`}
              title="Invoice PDF"
              width="1150px"
              height="700px"
              style={{ border: "none" }}
              // onLoad={handleIframeLoad}
            ></iframe>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleViewerClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default DraftInvoice;
