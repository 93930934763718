import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  drawerPaper: {
    height: "100%",
    width: "464px",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  datepickerWrapper: {
    minWidth: "120px",
    maxWidth: "164px",
    backgroundColor: "grey.100",
    "& .MuiOutlinedInput-input": {
      padding: "11px 6px",
      height: "32px",
    },
  },
  reactDatepickerWrapper: {
    minWidth: "136px",
    maxWidth: "164px",
    backgroundColor: "grey.100",
    "& .MuiOutlinedInput-input": {
      padding: "11px 2px",
      height: "32px",
    },
  },
  filterWrapper: {
    padding: "12px 12px",
  },
  input: {
    minWidth: "120px",
    maxWidth: "164px",
    marginRight: "12px",
    backgroundColor: "grey.100",
  },
  filterButton: {
    height: "46px !important",
    minWidth: "36px !important",
    borderRadius: 8,
    padding: "5px 10px",
  },
  filterContainer: {
    marginBottom: 12,
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  filterHeading: {
    padding: "14px",
    backgroundColor: "grey.100",
    marginBottom: "32px",
  },
  clearbutton: {
    width: "100%",
    textAlign: "right",
  },
  confirmBtn: {
    display: "flex",
    justifyContent: "end",
    padding: "12px 20px",
  },
}));
