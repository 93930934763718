import { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import { Datepicker } from "shared/components";
import {
  exportBase64ToFile,
  getDateString,
  getPageConfig,
  getTokenData,
  setPageConfig,
  updatePageConfig,
} from "utils";
import { AppContext } from "shared/contexts";
import { ROLE } from "modules/shared/constants";
import SharedService from "modules/shared/service";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import Service from "../service";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Skeleton from "@material-ui/lab/Skeleton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import useStyles from "./style";
import { PAGE_KEYS } from "shared";
import { IconButton, Paper } from "@material-ui/core";

const ON_DEMAND_REPORT_TYPE = [
  {
    label: "By Schedule Date",
    value: "byDate",
  },
  {
    label: "By KAM",
    value: "byKam",
  },
  {
    label: "Both Schedule Date & KAM",
    value: "both",
  },
  {
    label: "Detailed View",
    value: "detailed",
  },
];

const defaultState = {
  isFetching: false,
  reportType: ON_DEMAND_REPORT_TYPE[0],
  startDate: null,
  endDate: null,
  filters: {
    reportType: null,
    kamId: [],
  },
  isFetchingList: {
    customers: false,
    settlementCompanies: false,
    filters: false,
    IC: false,
  },
};

const OnDemandReport = ({ match, history }) => {
  const pageConfig = getPageConfig(PAGE_KEYS.ON_DEMAND);
  const [state, setState] = useState({
    ...defaultState,
    startDate:
      (pageConfig && pageConfig?.startDateFilter) ||
      getDateString(new Date(Date.now() - 6 * 24 * 60 * 60 * 1000)),
    endDate:
      (pageConfig && pageConfig?.endDateFilter) || getDateString(new Date()),
    reportType:
      (pageConfig && pageConfig?.reportType) || ON_DEMAND_REPORT_TYPE[0],
    filters: {
      ...defaultState.filters,
      kamId: (pageConfig && pageConfig?.kamFilter?.value) || defaultState.kamId,
    },
  });

  // const currentUser = getTokenData() || {};
  // const isRoleKAM = (currentUser?.role || "").toLowerCase() === ROLE.KAM;
  const { appData, updateContextData } = useContext(AppContext);
  const classes = useStyles({ isTabletView: appData.isTabletView });

  const fetchList = async (listType, ...params) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    switch (listType) {
      case "customers":
        serviceMethod = "getCustomerList";
        responseKey = "customers";
        break;
      case "IC":
        serviceMethod = "getContractorList";
        responseKey = "contractors";
        break;
      case "users":
        serviceMethod = "getUsersList";
        responseKey = "users";
        break;
      case "pendingItems":
        serviceMethod = "getPendingItems";
        responseKey = "pendingItems";
        break;
      default:
        serviceMethod = "getCustomerList";
        responseKey = "customers";
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];
    if (listType === "customers") {
      listData.map((item = {}) => {
        (item.customer_branches || []).map((branch = {}) => {
          (branch.routes || []).sort((rowA, rowB) => {
            const valueA = (rowA?.name || "").toLowerCase();
            const valueB = (rowB?.name || "").toLowerCase();
            return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
          });
        });
      });
    }
    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
  };

  const filterQueryBuilder = (
    startDate,
    endDate,
    reportType,
    filters,
    pageFilters = []
  ) => {
    const kamId = filters?.kamId?.map((kam) => kam.id);

    let filterKeysMap = {};

    if (kamId?.length > 0) {
      Object.assign(filterKeysMap, {
        kamId: {
          key: "kamId",
          value: kamId,
        },
      });
    }

    let queryString = `?type=${reportType?.value}&fromDate=${startDate}&toDate=${endDate}`;
    Object.keys(filterKeysMap).map((item) => {
      return (queryString += `&${
        filterKeysMap[item]["key"]
      }=${encodeURIComponent(filterKeysMap[item]["value"])}`);
    });

    return queryString;
  };

  const handleDownload = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetching: true,
    }));
    const fileName = `${state.reportType?.label}_report`;
    let query = filterQueryBuilder(
      state.startDate,
      state.endDate,
      state.reportType,
      state.filters
    );

    const { data, error } = await Service.getOnDemandReport(query);

    if (error) {
      toast.error(Array.isArray(error) ? error[0].message : error);
    } else {
      exportBase64ToFile(data, fileName);
    }
    setState((prevState) => ({
      ...prevState,
      isFetching: false,
    }));
  };

  useEffect(() => {
    fetchList("users");
  }, [match?.params?.path]);

  return (
    <>
      <div className={clsx("mr-10", classes.fixedHeader)}>
        <Typography variant="h4" color="primary" className=" ml-2">
          On Demand Reports
          <Button
            style={{ float: "right" }}
            className="mt-1 mr-2"
            variant="contained"
            color="primary"
            onClick={() => history.push("/home")}
            startIcon={<ArrowBackIcon />}
          >
            Back To Home
          </Button>
          <Button
            style={{ float: "right" }}
            className="mt-1 mr-2"
            variant="contained"
            color="primary"
            onClick={() => history.push("/report")}
            startIcon={<ArrowBackIcon />}
          >
            Back To Reports
          </Button>
        </Typography>
        <Typography variant="body2" style={{ marginLeft: "10px" }}>
          Download On Demand Reports.
        </Typography>
      </div>
      <div className="d-flex f-align-center f-wrap mb-3">
        <Datepicker
          mask
          preventClear
          label="Start date"
          selected={new Date(state.startDate)}
          maxDate={new Date()}
          classes={{
            input: {
              root: clsx("mr-4 mt-4", classes.datepickerWrapper),
            },
          }}
          onChange={(startDate) => {
            const currentDate = new Date();
            const endDate = new Date(startDate);
            endDate.setDate(startDate.getDate() + 6);
            if (endDate > currentDate) {
              endDate.setDate(currentDate.getDate());
            }

            setState((prevState) => ({
              ...prevState,
              startDate: getDateString(startDate),
              endDate: getDateString(endDate),
            }));
            setPageConfig(PAGE_KEYS.ON_DEMAND, {
              startDateFilter: getDateString(startDate),
              endDateFilter: getDateString(endDate),
            });
          }}
        />
        <Datepicker
          mask
          preventClear
          label="End date"
          selected={new Date(state.endDate)}
          minDate={new Date(state.startDate)}
          maxDate={
            new Date(
              Math.min(
                new Date(state.startDate).setDate(
                  new Date(state.startDate).getDate() + 6
                ),
                new Date()
              )
            )
          }
          classes={{
            input: {
              root: clsx("mr-4 mt-4", classes.datepickerWrapper),
            },
          }}
          onChange={(endDate) => {
            setState((prevState) => ({
              ...prevState,
              endDate: getDateString(endDate),
            }));
            setPageConfig(PAGE_KEYS.ON_DEMAND, {
              endDateFilter: getDateString(endDate),
            });
          }}
        />

        <Autocomplete
          className={clsx("mr-4 mt-4", classes.customerDropdown)}
          classes={{
            paper: "mb-2",
          }}
          size="small"
          ChipProps={{
            component: (a) => {
              return (
                <div>
                  <Tooltip
                    placement={"top-start"}
                    title={a.children[1]?.props?.children || ""}
                  >
                    <Chip
                      size="small"
                      className={classes.chip}
                      width={15}
                      label={
                        <Typography variant="body3" noWrap>
                          {a.children[1]?.props?.children.length > 4
                            ? a.children[1]?.props?.children.slice(0, 4) + "..."
                            : a.children[1]?.props?.children}
                        </Typography>
                      }
                      deleteIcon
                      onDelete={(evt) => {
                        a.children[2]?.props?.onClick(evt);
                      }}
                    />
                  </Tooltip>
                </div>
              );
            },
          }}
          // value={state.filters?.kamId}
          defaultValue={state.filters?.kamId}
          options={appData.users.filter(
            (user) => user.role === "KAM" && user.is_active
          )}
          multiple
          getOptionLabel={(option) =>
            option.name ||
            `${option.first_name || ""} ${option.last_name || ""}-${
              option.email || ""
            }-(${option.username || ""})`
          }
          renderInput={(params) =>
            state.isFetchingList.filters ? (
              <Skeleton variant="rect" width="100%" height="42px" />
            ) : (
              <TextField {...params} label="KAM" variant="outlined" />
            )
          }
          onChange={(evt, kam) => {
            setState((prevState) => ({
              ...prevState,
              filters: {
                ...prevState.filters,
                kamId: kam,
              },
            }));
            setPageConfig(PAGE_KEYS.ON_DEMAND, {
              kamFilter: {
                value: kam,
              },
            });
          }}
        />
        <div style={{ marginTop: "16px" }}>
          <Tooltip placement="top-end" title="Reset All Filters">
            <IconButton
              color="primary"
              onClick={() => {
                updatePageConfig("on-demand");
                window.location.reload();
              }}
            >
              <RotateLeftIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <span variant="body1" className={classes.note}>
        Note: Please be aware that users can download the records for up to a
        maximum of 7 days.
      </span>
      <div
        className={clsx("w-75", {
          "w-100": appData.isTabletView,
        })}
      >
        <Paper
          elevation={4}
          className={clsx("p-4 pt-8 pl-8 mt-3", classes.paperr)}
        >
          <FormControl>
            <RadioGroup
              style={{ display: "flex", flexDirection: "column" }}
              row
              value={state.reportType?.value}
              onChange={(evt) => {
                const reportType = ON_DEMAND_REPORT_TYPE.find(
                  (type) => type.value === evt.target.value
                );
                setState((prevState) => ({
                  ...prevState,
                  reportType,
                  showGrid: false,
                }));
                setPageConfig(PAGE_KEYS.ON_DEMAND, {
                  reportType: reportType,
                });
              }}
            >
              {ON_DEMAND_REPORT_TYPE.map(({ label, value }) => (
                <FormControlLabel
                  disabled={state.isLoading}
                  value={value}
                  control={<Radio color="primary" />}
                  label={label}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <div className="d-flex mt-2 f-justify-end">
            <Button
              className="mt-4"
              variant="contained"
              color="primary"
              disabled={state.isFetching}
              onClick={() => {
                handleDownload();
              }}
            >
              Download
              {state.isFetching && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </Button>
          </div>
        </Paper>
      </div>
    </>
  );
};

export default OnDemandReport;
