import clsx from "clsx";
import { noop } from "shared/constants";
import { getDateString, getUTCDateString } from "utils";
import { Datepicker } from "shared/components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import useStyles from "../style";

const GeneralPanel = ({
  entry = {},
  routeDetails = null,
  isNew = false,
  KAMList = [],
  scheduleDate = null,
  onFieldChange = noop,
  calculateFuelSurcharge = noop,
  isTabletView = false,
}) => {
  const classes = useStyles({ isTabletView });

  return (
    <div className="d-flex f-align-center f-wrap">
      <Datepicker
        mask
        preventClear
        label="Schedule Date"
        required
        maxDate={new Date()}
        className={classes.field}
        selected={
          isNew
            ? getDateString(scheduleDate)
            : getUTCDateString(entry.schedule_date)
        }
        defaultHelperText=" "
        size="small"
        onChange={async (value) => {
          if (!!value && !!routeDetails) {
            const data = await calculateFuelSurcharge(
              routeDetails.id,
              getDateString(value)
            );

            if (!!data.pay_fuel_surcharge) {
              onFieldChange(
                "bill_fuel_surcharge",
                (Number(data.bill_fuel_surcharge) || 0).toFixed(2),
                "customers"
              );
              onFieldChange(
                "pay_fuel_surcharge",
                (Number(data.pay_fuel_surcharge) || 0).toFixed(2),
                "contractors"
              );

              const billTotalAmount =
                +routeDetails.default_bill_rate *
                +routeDetails.default_bill_quantity;
              const billFuelAmount =
                billTotalAmount * (Number(data.bill_fuel_surcharge) / 100);

              const payTotalAmount =
                +routeDetails.default_pay_rate *
                +routeDetails.default_pay_quantity;
              const payFuelAmount =
                payTotalAmount * (Number(data.pay_fuel_surcharge) / 100);
              console.log(billTotalAmount, payTotalAmount, "AMOUNT");

              onFieldChange(
                "bill_fuel_amount",
                Number(billFuelAmount || 0).toFixed(2),
                "customers"
              );
              onFieldChange(
                "pay_fuel_amount",
                Number(payFuelAmount || 0).toFixed(2),
                "contractors"
              );
            } else {
              onFieldChange("bill_fuel_surcharge", 0, "customers");
              onFieldChange("pay_fuel_surcharge", 0, "contractors");
              onFieldChange("bill_fuel_amount", 0, "customers");
              onFieldChange("pay_fuel_amount", 0, "contractors");
            }
          }
          onFieldChange("schedule_date", getDateString(value), "general");
        }}
      />
      <Autocomplete
        disableClearable
        className={clsx("mb-1", classes.field)}
        size="small"
        value={entry.kam}
        options={KAMList}
        getOptionLabel={(option) =>
          option.name ||
          `${option.first_name || ""} ${option.last_name || ""}-${
            option.email || ""
          }-(${option.username || ""})`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="KAM"
            variant="outlined"
            error={!!entry.errors.kam.trim()}
            helperText={entry.errors.kam}
          />
        )}
        onChange={(evt, value) => onFieldChange("kam", value, "general")}
      />
    </div>
  );
};

export default GeneralPanel;
