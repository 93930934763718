import { Switch, Route } from "react-router-dom";
import InvoiceHomePage from "./home";
import ViewInvoice from "./viewInvoice";
import DraftInvoice from "./draftInvoice";
import ViewRecordPaymentHistory from "./recordPaymentHistory";
import ApproveInvoice from "./approveInvoice";

const InvoiceModule = ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.path} component={InvoiceHomePage} />
      <Route
        exact
        path={`${match.path}/view_invoice`}
        component={ViewInvoice}
      />
      <Route
        exact
        path={`${match.path}/draft_invoice`}
        component={DraftInvoice}
      />
      <Route
        exact
        path={`${match.path}/record_payment`}
        component={ViewRecordPaymentHistory}
      />
      <Route
        exact
        path={`${match.path}/approve_invoice`}
        component={ApproveInvoice}
      />
    </Switch>
  );
};

export default InvoiceModule;
