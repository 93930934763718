import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  datepickerWrapper: {
    width: 160,
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  payDateWrapper: {
    width: 190,
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  isIncludingRecords: {
    width: 388,
  },
  permExcludePaperSize: {
    width: 388,
    height: 244,
  },
  scheduleDateWrapper: {
    "& .react-datepicker__close-icon": {
      top: 2,
      right: 36,
    },
    "& .react-datepicker__close-icon::after": {
      background: "transparent",
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: 28,
    },
  },
  paperHeight: {
    minHeight: 400,
  },
  autocompleteSearchInput: {
    minWidth: 100,
    "& .MuiInput-underline:before": {
      borderBottom: 0,
    },
    "&:hover": {
      "& .MuiInput-underline:before": {
        borderBottom: 0,
      },
    },
  },
  autocompletePaper: {
    minWidth: 150,
    maxHeight: 200,
    fontSize: 14,
  },
  dcnHistoryCloumnTitle: {
    minWidth: "40px",
  },
  holdPaddingLeft: {
    paddingLeft: "21px !important",
  },
  icidFlagTitle: {
    paddingLeft: "48px !important",
  },
  warningIcon: {
    color: "#d7d718",
    zIndex: 1,
    position: "relative",
    marginTop: "2px",
  },
  warningIconHeight: {
    height: "24px",
    position: "relative",
  },
  exclamationMark: {
    width: "3px",
    height: "9px",
    background: "black",
    position: "absolute",
    top: "11px",
    left: "11px",
  },
  autocompleteInput: {
    fontSize: 14,
  },
  settlementCompanyDropdown: {
    //marginTop: "14px",
    minWidth: 198,
    maxWidth: 198,
    [theme.breakpoints.down("sm")]: {
      minWidth: 244,
      maxWidth: 244,
      marginLeft: 0,
      marginBottom: 12,
      marginRight: 10,
    },
  },
  paperSpacing: {
    marginTop: "14px !important",
    paddingTop: "0px !important",
    paddingBottom: "0px !important ",
  },
  tunePaper: {
    minWidth: 232,
  },
  VisibilityIconColor: { color: "#004700" },
  fixedHeader: {
    width: "100%",
    top: "64px",
    height: "75px",
    zIndex: 1000,
    position: "sticky",
    background: "#fafafa",
    marginBottom: "6px",
  },
  paper: (props) => ({
    maxWidth: "90%",
    minWidth: "90%",
    maxHeight: props.isTabletView ? "95%" : "90%",
  }),
  updatedData: {
    marginTop: "20px",
  },
  upDatedRow: { paddingTop: "40px" },
}));

export default useStyles;
