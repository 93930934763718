import { lighten, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: 30,
  },
  container: {
    minHeight: 200,
    maxHeight: 325,
    overflow: "auto",
  },
  tableHeadPadding: { padding: "8px 16px !important" },
  tableBottonMargin: { marginBottom: "0px" },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    maxHeight: 300,
    overflow: "auto",
  },
  row: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  boxborder: {
    borderTop: "2px solid #00008B",
    borderBottom: "2px solid #00008B",
    boxShadow: "inset 0px 0px 4px 2px #00008B, inset 0px 0px 4px 2px #00008B",
  },
  borderCollapse: {
    borderCollapse: "collapse",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableCell: {
    minWidth: 72,
    maxWidth: 200,
  },
  showRightBorder: {
    borderRight: "2px solid black",
  },
  tableHeadCell: {
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.light, 0.9),
      "& div[data-role='resize']": {
        visibility: "visible",
      },
    },
  },
  noRecordsImg: {
    height: 500,
    width: 600,
  },
  noRecordsLable: {
    minHeight: 300,
  },
  resizeIconWrapper: {
    width: 2,
    cursor: "col-resize",
    userSelect: "none",
    visibility: "hidden",
    right: 0,
    top: 0,
    bottom: 0,
  },
  resizeIcon: {
    background: lighten(theme.palette.primary.light, 0.9),
    opacity: 0.6,
    width: 2,
    height: 15,
  },
}));

export const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  noHighlight: {
    backgroundColor: theme.palette.background.default,
  },
  highlight:
    theme.palette.type === "light"
      ? {
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          backgroundColor: theme.palette.background.paper,
        },
  title: {
    flex: "1 1 100%",
  },
}));

export const usePaginationStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));
