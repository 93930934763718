import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
	dialog: {
		height: 700,
		width: 420,
	},
	listIcon: {
		minWidth: 32,
	},
}));
