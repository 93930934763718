import { Dialog } from "shared/components";
import { fieldErrorMessageMap, noop, VALIDATIONS } from "shared/constants";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import { useState, useContext, useMemo } from "react";
import { useEffect } from "react";
import { preventInputKeyCodes, validator, getTokenData } from "utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";
import { AppContext } from "shared/contexts";
import Service from "../service";
import useStyles from "./style";
import { ROLE, USER_ROLES } from "modules/shared/constants";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

let isFieldChange = false;
const defaultState = {
  username: null,
  first_name: null,
  last_name: "",
  phone: "",
  email: null,
  role: null,
  location: "",
  selectedUserName: null,
  manager: null,
  isAutoGeneratedICID: false,
  errors: {
    username: " ",
    first_name: " ",
    last_name: " ",
    phone: " ",
    email: " ",
    roles: " ",
    location: " ",
  },
  isLoading: false,
};

const Form = ({
  open = false,
  entry = null,
  isNew = false,
  managerUsersOptions = [],
  fetchManagers = noop,
  onClose = noop,
  currentUser = {},
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);
  const currentUserRole = (
    (getTokenData() || {})?.role || ROLE.ADMIN
  ).toLowerCase();

  useEffect(() => {
    if (entry && !isNew) {
      setState((prevState) => ({
        ...prevState,
        id: entry.id || defaultState.id,
        username: entry.username || defaultState.username,
        first_name: entry.first_name || defaultState.first_name,
        last_name: entry.last_name || defaultState.last_name,
        role: entry.role || defaultState.role,
        email: entry.email || defaultState.email,
        phone: entry.phone || defaultState.phone,
        location: entry.location || defaultState.location,
        selectedUserName: entry.first_name + entry.last_name,
        manager: entry.manager || defaultState.manager,
      }));
    }
  }, [entry]);

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = {
      first_name: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.MAX_LENGTH, value: 25 },
      ],
      last_name: [{ type: VALIDATIONS.MAX_LENGTH, value: 25 }],
      email: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.EMAIL, value: true },
      ],
      phone: [{ type: VALIDATIONS.PHONE, value: true }],
      //roles: [{ type: VALIDATIONS.REQUIRED, value: true }],
      location: [{ type: VALIDATIONS.MAX_LENGTH, value: 25 }],
      manager: [{ type: VALIDATIONS.REQUIRED, value: state.role === "KAM" }],
    };

    if (
      (isNew && !state.isAutoGeneratedICID) ||
      (!isNew && currentUserRole === ROLE.ADMIN)
    ) {
      fieldValidatorMap["username"] = [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.MIN_LENGTH, value: 4 },
        { type: VALIDATIONS.MAX_LENGTH, value: 20 },
      ];
    }
    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );

      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        Object.keys(fieldValidatorMap).forEach((key) => {
          const message = validate(key, state[key]);
          if (!!message) {
            errorMessage = message;
          }
        });
      });
    }

    return errorMessage;
  };

  const handleFieldChange = (evt) => {
    isFieldChange = true;
    const field = evt.currentTarget?.name || evt.target?.name;
    const value = evt.currentTarget?.value || evt.target?.value;
    const type = evt.currentTarget?.type || evt.target?.type;
    let errorMessage = validate(field, value) || " ";

    setState((prevState) => ({
      ...prevState,
      [field]: type === "number" ? value && +value : value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  const handleSubmit = async () => {
    console.log(state, "state");
    const {
      username,
      first_name,
      last_name,
      role,
      phone,
      email,
      location,
      manager,
      isAutoGeneratedICID,
    } = state;
    if (!/[a-zA-Z0-9]+/.test(location || null)) {
      return toast.error("Only special character(s) not allowed in location.");
    }
    setState((prevState) => ({ ...prevState, isLoading: true }));
    const serviceMethod = isNew ? "post" : "put";

    const { error } = await Service[serviceMethod]({
      first_name,
      last_name,
      email,
      phone,
      location,
      role,
      username: isNew ? (isAutoGeneratedICID ? false : username) : username,
      id: entry?.id,
      manager_id: manager ? manager?.id : null,
      auto_generate: isNew && state.isAutoGeneratedICID,
    });

    if (error) {
      setState((prevState) => ({ ...prevState, isLoading: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success(`User ${isNew ? "created" : "updated"} successfully.`);
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
    }));
    handleClose(true);
    fetchManagers();
  };

  const handleClose = (isSubmitted = false) => {
    onClose(isSubmitted);
    isFieldChange = false;
    setState(defaultState);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <Dialog.Title hasClose onClose={() => {}}>
        {`${isNew ? "New" : "Update"} User`}
      </Dialog.Title>
      <Dialog.Content>
        <div className="d-flex f-align-center f-justify-between mb-1">
          {(isNew || currentUserRole === ROLE.ADMIN) && (
            <TextField
              required={!state.isAutoGeneratedICID}
              fullWidth={!isNew}
              className={clsx("ml-2", {
                "w-75": isNew,
              })}
              // type="number"
              name="username"
              label="Username"
              variant="outlined"
              size="small"
              value={state.username}
              error={!!state.errors.username.trim()}
              helperText={state.errors.username}
              onChange={(evt) => {
                evt.currentTarget.value = (
                  evt.currentTarget.value || ""
                ).replaceAll(" ", "");
                handleFieldChange(evt);
              }}
              disabled={state.isAutoGeneratedICID}
            />
          )}
          {isNew && (
            <FormControlLabel
              className="mb-5"
              control={
                <Checkbox
                  size="small"
                  checked={state.isAutoGeneratedICID}
                  onChange={(evt) => {
                    const { checked } = evt.target;
                    isFieldChange = true;
                    setState((prevState) => ({
                      ...prevState,
                      isAutoGeneratedICID: checked,
                      username: "",
                      errors: {
                        ...prevState.errors,
                        username: " ",
                      },
                    }));
                  }}
                  color="primary"
                />
              }
              label={<Typography variant="body2">Auto</Typography>}
            />
          )}
        </div>
        <div className="d-flex f-align-center">
          <TextField
            required
            label="First Name"
            variant="outlined"
            name="first_name"
            value={state.first_name}
            size="small"
            className="ml-2 w-50 mb-1"
            onChange={handleFieldChange}
            error={!!state.errors.first_name.trim()}
            helperText={state.errors.first_name}
          />
          <TextField
            label="Last Name"
            variant="outlined"
            name="last_name"
            size="small"
            value={state.last_name}
            className="ml-2 w-50 mb-1"
            onChange={handleFieldChange}
            error={!!state.errors.last_name.trim()}
            helperText={state.errors.last_name}
          />
        </div>
        <div className="d-flex f-align-center">
          <TextField
            required
            label="Email"
            variant="outlined"
            size="small"
            name="email"
            disabled={!isNew}
            value={state.email}
            className="ml-2 w-50 mb-1"
            onChange={handleFieldChange}
            error={!!state.errors.email.trim()}
            helperText={state.errors.email}
          />
          <TextField
            label="Phone"
            // type="number"
            variant="outlined"
            size="small"
            name="phone"
            value={state.phone}
            className="ml-2 w-50 mb-1"
            onChange={handleFieldChange}
            error={!!state.errors.phone.trim()}
            helperText={state.errors.phone}
          />
        </div>
        <div className="d-flex f-align-center">
          <Autocomplete
            disableClearable
            className=" w-50 mb-1 ml-2"
            size="small"
            disabled={
              currentUser?.email === entry?.email ||
              (!isNew &&
                (entry?.role || "").toLowerCase() === ROLE.KAM.toLowerCase())
            }
            value={state.role || ""}
            options={USER_ROLES.filter(
              (role) =>
                !(
                  !isNew &&
                  (entry?.role || "").toLowerCase() !==
                    ROLE.KAM.toLowerCase() &&
                  (role || "").toLowerCase() === ROLE.KAM.toLowerCase()
                )
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Role"
                required
                variant="outlined"
                error={!!state.errors.roles.trim()}
                helperText={state.errors.roles}
              />
            )}
            onChange={(evt, value) => {
              isFieldChange = true;
              setState((prevState) => ({ ...prevState, role: value }));
            }}
          />

          <TextField
            label="Location"
            variant="outlined"
            size="small"
            name="location"
            onChange={handleFieldChange}
            value={state.location}
            className="ml-2 w-50 mb-1"
            error={!!state.errors.location.trim()}
            helperText={state.errors.location}
          />
        </div>

        {(state.role === "AA" ||
          state.role === "KAM" ||
          state.role === "MGR") && (
          <div className="d-flex f-align-center">
            <Autocomplete
              className="w-100 mr-2 ml-2"
              size="small"
              value={state?.manager}
              options={managerUsersOptions
                .filter((manager) =>
                  !isNew && state.role === "MGR"
                    ? manager.email !== entry.email
                    : manager.is_active === true
                )
                .sort((a, b) => Intl.Collator().compare(a.name, b.name))}
              classes={{
                paper: classes.listPaper,
                listbox: classes.listBox,
              }}
              getOptionLabel={(option) =>
                option.label ||
                `${option.first_name} ${
                  option.last_name ? option.last_name : ""
                }-${option.email}` ||
                ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Manager"
                  required={state.role === "KAM"}
                  variant="outlined"
                  helperText=" "
                />
              )}
              onChange={(evt, mgr) => {
                isFieldChange = true;
                setState((prevState) => ({
                  ...prevState,
                  manager: mgr,
                }));
              }}
            />
          </div>
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <div className="p-4">
          <Button
            variant="outlined"
            onClick={handleClose}
            className="ml-2 mr-2"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="ml-2 mr-2"
            disabled={
              state.isLoading || !isFieldChange || validate() || !state.role
            }
            onClick={handleSubmit}
          >
            {`${isNew ? "Create" : "Update"}`}
            {state.isLoading && (
              <CircularProgress size={24} className="p-absolute progress-btn" />
            )}
          </Button>
        </div>
      </Dialog.Actions>
    </Dialog>
  );
};

export default Form;
