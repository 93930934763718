import { createMuiTheme, darken } from '@material-ui/core/styles';

export default createMuiTheme({
	palette: {
		type: 'dark',
		background: {
			primary: {
				light: '#7986cb',
				main: darken('#7986cb', 0.2),
				dark: darken('#7986cb', 0.4),
			},
			warning: {
				light: '#ffb74d',
				main: darken('#ffb74d', 0.2),
				dark: darken('#ffb74d', 0.4),
			},
			error: {
				light: '#e57373',
				main: darken('#e57373', 0.2),
				dark: darken('#e57373', 0.4),
			},
		},
	},
	spacing: 4,
});
