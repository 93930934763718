import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
  paper: {
    width: 520,
    minHeight: 164,
  },
  field: {
    width: 450,
    margin: "2px 20px 0px 16px",
  },
  checkboxLabel: {
    width: 55,
  },
}));

export default useStyles;
