import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  Fragment,
  useRef,
} from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import { noop, PAGE_KEYS } from "shared/constants";
import {
  DEDUCTION_REASONS,
  INVOICE_STATUS,
  INVOICE_TYPES,
  RATE_TYPES,
} from "modules/shared/constants";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import SharedService from "../../shared/service";
import Service from "../service";
import { useLocation } from "react-router-dom";
import FlagIcon from "@material-ui/icons/Flag";
import { AppContext } from "shared/contexts";
import FilterListIcon from "@material-ui/icons/FilterList";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import {
  ActionDialog,
  Grid as DataGrid,
  Datepicker,
  Dropdown,
  FilterComponent,
} from "shared/components";
import Form from "../form";
import { DynamicForm } from "modules/shared/components";
import {
  exportBase64ToFile,
  queryStringBuilder,
  getDateString,
  getPageConfig,
  getTokenData,
  setPageConfig,
  updatePagination,
  getUTCDateString,
  queryStringBuilderNew,
  getFormattedTime,
  updateLayout,
  updatePageConfig,
} from "utils";
import { VALIDATIONS } from "shared/constants";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Grid from "@material-ui/core/Grid";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import invoiceIcon from "../../../assets/icons/invoiceIcon.svg";
import invoiceDisabledIcon from "../../../assets/icons/invoiceDisabledIcon.svg";
import { ROLE } from "modules/shared/constants";
import Skeleton from "@material-ui/lab/Skeleton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TuneIcon from "@material-ui/icons/Tune";
import useStyles from "./style";
import { DialogContent } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import IcForm from "modules/shared/components/ic-form";

let gridHelper = null,
  timeout = null;
let entries = null;
let approveTimeout = null;

const defaultState = {
  entries: [],
  totalEntries: 0,
  areAllSelected: false,
  isFetching: false,
  isDownloadLoading: false,
  showDoNotShowCheckboxValue: false,
  isFetchingList: {
    customers: false,
    users: false,
    settlementCompany: false,
    pdxCompany: false,
    IC: false,
    approveLoading: false,
  },
  isFiltering: false,
  pageFilters: [],
  selectedRows: [],
  loaderID: null,
  aaUser: null,
  customer: null,
  isPriceMatrixFetched: false,
  kam: null,
  IC: null,
  filters: {
    customer: [],
    IC: [],
    aaUser: [],
    endDate: [],
    startDate: [],
    scheduleDate: [],
    kam: [],
    is_verified: [],
    daily_schedule_id: [],
    locations: [],
  },
  vehicleTypeList: [],
  deductionReasonList: [],
  selectedLocation: [],
  locationList: [],
  customerlocationList: [],
  isIcFetching: false,
  icDetails: {},
  showIcDetails: false,
  stateList: [],
  pdxCompanyList: [],
  isUnderReview: false,
  approved: true,
  priceMatrix: [],
  unapproved: true,
  pageSize: 100,
  testId: "",
  cautionFlagSubmitReasonList: [],
  pageNumber: 1,
  order: null,
  isApproveLoading: false,
  shouldApproveEnable: false,
  shouldUnapproveEnable: false,
  orderBy: null,
  search: "",
  searchColumns: [],
  settlementCompanyList: [],
  isNew: false,
  rowBeingEdited: null,
  deletingEntryId: null,
  isApproved: false,
  showConfirmationPopup: false,
  dynamicColumns: {
    select_all: { label: "Select All", value: "select_all", isSelected: true },
    status: {
      label: "Status",
      value: "status",
      isSelected: true,
    },

    schedule_date: {
      label: "Schedule Date",
      value: "schedule_date",
      isSelected: true,
    },
    customer_name: {
      label: "Customer Name",
      value: "customer_name",
      isSelected: true,
    },
    location: { label: "Location", value: "location", isSelected: true },
    route: { label: "Route", value: "route", isSelected: true },
    state: { label: "State", value: "state", isSelected: true },
    pdx_company: {
      label: "PDX Company",
      value: "pdx_company",
      isSelected: true,
    },
    vehicle: { label: "Vehicle", value: "vehicle", isSelected: true },
    icid: { label: "ICID/IC Name", value: "icid", isSelected: true },
    settlement_company: {
      label: "Settlement Company",
      value: "settlement_company",
      isSelected: true,
    },
    dont_bill: {
      label: "Do Not Bill",
      value: "dont_bill",
      isSelected: true,
    },
    dont_pay: {
      label: "Do Not Pay",
      value: "dont_pay",
      isSelected: true,
    },
    dont_bill_miles: {
      label: "Do Not Bill Extra Miles",
      value: "dont_bill_miles",
      isSelected: true,
    },
    dont_pay_miles: {
      label: "Do Not Pay Extra Miles",
      value: "dont_pay_miles",
      isSelected: true,
    },
    dont_bill_tolls: {
      label: "Do Not Bill Toll",
      value: "dont_bill_tolls",
      isSelected: true,
    },
    dont_pay_tolls: {
      label: "Do Not Pay Toll",
      value: "dont_pay_tolls",
      isSelected: true,
    },
    bill_rate_type: {
      label: "Billing Rate Type",
      value: "bill_rate_type",
      isSelected: true,
    },
    bill_rate: { label: "Billing Rate", value: "bill_rate", isSelected: true },
    bill_quantity: {
      label: "Billing Quantity",
      value: "bill_quantity",
      isSelected: true,
    },

    bill_amount: {
      label: "Billing Amount",
      value: "bill_amount",
      isSelected: true,
    },
    bill_fuel_surcharge: {
      label: "Billing Fuel Surcharge%",
      value: "bill_fuel_surcharge",
      isSelected: true,
    },
    bill_fuel_amount: {
      label: "Billing Fuel Amount",
      value: "bill_fuel_amount",
      isSelected: true,
    },
    bill_toll_amount: {
      label: "Billing Toll Amount",
      value: "bill_toll_amount",
      isSelected: true,
    },
    bill_extra_miles: {
      label: "Billing Extra Miles",
      value: "bill_extra_miles",
      isSelected: true,
    },
    bill_rate_per_extra_mile: {
      label: "Billing Rate Per Extra Mile",
      value: "bill_rate_per_extra_mile",
      isSelected: true,
    },
    bill_extra_mile_amount: {
      label: "Billing Extra Miles Amount",
      value: "bill_extra_mile_amount",
      isSelected: true,
    },
    pay_rate_type: {
      label: "Pay Rate Type",
      value: "pay_rate_type",
      isSelected: true,
    },
    pay_rate: { label: "Pay Rate", value: "pay_rate", isSelected: true },
    pay_quantity: {
      label: "Pay Quantity",
      value: "pay_quantity",
      isSelected: true,
    },
    pay_amount: { label: "Pay Amount", value: "pay_amount", isSelected: true },
    pay_fuel_surcharge: {
      label: "Pay Fuel Surcharge%",
      value: "pay_fuel_surcharge",
      isSelected: true,
    },
    pay_fuel_amount: {
      label: "Pay Fuel Amount",
      value: "pay_fuel_amount",
      isSelected: true,
    },
    pay_toll_amount: {
      label: "Pay Toll Amount",
      value: "pay_toll_amount",
      isSelected: true,
    },
    pay_extra_miles: {
      label: "Pay Extra Miles",
      value: "pay_extra_miles",
      isSelected: true,
    },
    pay_rate_per_extra_mile: {
      label: "Pay Rate Per Extra Mile",
      value: "pay_rate_per_extra_mile",
      isSelected: true,
    },
    pay_extra_mile_amount: {
      label: "Pay Extra Miles Amount",
      value: "pay_extra_mile_amount",
      isSelected: true,
    },

    reimbursement: {
      label: "Reimbursement",
      value: "reimbursement",
      isSelected: true,
    },
    deduction: {
      label: "Deduction",
      value: "deduction",
      isSelected: true,
    },
    deduction_reason: {
      label: "Deduction Reason",
      value: "deduction_reason",
      isSelected: true,
    },
    kam: {
      label: "KAM",
      value: "kam",
      isSelected: true,
    },
    office: {
      label: "Office",
      value: "office",
      isSelected: true,
    },
    manager: {
      label: "Manager",
      value: "manager",
      isSelected: true,
    },
    profit_percentage: {
      label: "Profit Percentage",
      value: "profit_percentage",
      isSelected: true,
    },
    change_notes: {
      label: "AA/MGR Notes",
      value: "change_notes",
      isSelected: true,
    },
    kam_notes: {
      label: "KAM Notes",
      value: "kam_notes",
      isSelected: true,
    },
    review_notes: {
      label: "Review Notes",
      value: "review_notes",
      isSelected: true,
    },
    approved_by: {
      label: "Approved/Rejected By",
      value: "approved_by",
      isSelected: true,
    },
    review_reason: {
      label: "Review Reason",
      value: "review_reason",
      isSelected: true,
    },
    submitted_by: {
      label: "Submitted By",
      value: "submitted_by",
      isSelected: true,
    },
    submitted_at: {
      label: "DS Submitted At",
      value: "submitted_at",
      isSelected: true,
    },
    updatedBy: {
      label: "Last Updated By",
      value: "updatedBy",
      isSelected: true,
    },
    updated_at: {
      label: "Last Updated At",
      value: "updated_at",
      isSelected: true,
    },
  },
  dateFilterType: "single",
  formAlertFields: {},
  clickedRowId: null,
  fieldAlert: {
    show: false,
    id: null,
    isLoading: false,
    subHeading: "",
    configs: [],
    updateValidations: noop,
    onChange: noop,
  },
};

const BillingSchedule = ({ match, history }) => {
  const pageConfig = getPageConfig(PAGE_KEYS.BILLING);
  const classes = useStyles();
  const location = useLocation();
  const data = location.state || {};
  const selectedRef = useRef(null);
  const { appData, updateContextData } = useContext(AppContext);
  const currentUser = getTokenData() || {};
  const userID = currentUser.id || "";
  const isRoleADMIN = (currentUser?.role || "").toLowerCase() === ROLE.ADMIN;
  const isRoleVIEW = (currentUser?.role || "").toLowerCase() === ROLE.MAPS_VIEW;
  const isRoleAA = (currentUser?.role || "").toLowerCase() === ROLE.AA;
  const isRoleMGR = (currentUser?.role || "").toLowerCase() === ROLE.MGR;

  const appDataList = useMemo(() => {
    const users = appData.users.reduce(
      (acc, user) => {
        const role = (user.role || "").toLowerCase();
        acc[role] && acc[role].push(user);
        return acc;
      },
      {
        kam: [],
        aa: [],
        mgr: [],
        admin: [],
      }
    );

    const IC = appData.IC.filter((ic) => ic.is_active);
    const icList = appData.icList
      .filter((ic) => ic.is_active)
      .sort((a, b) => a.first_name.localeCompare(b.first_name));
    const customers = appData.customers.filter(
      (customer) => customer.is_active
    );

    const getOptionList = (users = []) => {
      return users.map((list) => ({
        value: list,
        label:
          list?.name ||
          `${list.first_name || ""} ${list.last_name || ""}-- ${
            list?.email || ""
          }`,
      }));
    };

    const ICOptions = IC.map((list) => ({
      value: list,
      label: `${list?.icid || ""}/${list?.first_name || ""} ${
        list?.last_name || ""
      }`,
    }));

    return {
      kamUsers: users.kam,
      officeUsers: users.aa,
      managerUsers: users.mgr,
      adminUsers: users.admin,
      IC,
      icList,
      customers,
      kamUsersOptions: getOptionList(users.kam),
      officeUsersOptions: getOptionList(
        users.aa.filter((user) => user.is_active === true)
      ),
      managerUsersOptions: getOptionList(
        users.mgr.filter((user) => user.is_active === true)
      ),
      adminUsersOptions: getOptionList(
        users.admin.filter((user) => user.is_active === true)
      ),
      ICOptions,
    };
  }, [appData]);

  useEffect(() => {
    if (
      appDataList.kamUsers?.length &&
      appDataList.icList?.length &&
      appDataList.customers?.length &&
      appDataList.officeUsers?.length &&
      data?.kam_id
    ) {
      const kam = appDataList.kamUsers?.find((kam) => kam?.id == data?.kam_id);
      const customer = appDataList.customers?.find(
        (customer) => customer.id == data?.customer_id
      );
      const IC = appDataList.icList?.find(
        (ic) => ic.id == data?.individual_contractor_id
      );
      const aaUser = appDataList.officeUsers?.filter(
        (aa) => aa.id == data?.aa_id
      );
      setState((prevState) => ({
        ...prevState,
        kam,
        customer,
        IC,
        aaUser,
      }));
      window.history.replaceState(null, document.title);
      history.replace({ ...history.location, state: null });
    }
  }, [
    appDataList.kamUsers,
    appDataList.customers,
    appDataList.icList,
    appDataList.officeUsers,
  ]);

  const [state, setState] = useState({
    ...defaultState,
    pageSize: pageConfig?.pageSize || defaultState.pageSize,
    pageNumber: pageConfig?.pageNumber || defaultState.pageNumber,
    dynamicColumns: pageConfig?.dynamicColumns || defaultState.dynamicColumns,
    selectedLocation:
      (pageConfig && pageConfig?.locationFilter?.value) ||
      defaultState.selectedLocation,
    aaUser: isRoleAA
      ? pageConfig?.officeFilter === undefined
        ? currentUser
        : pageConfig?.officeFilter?.value || defaultState.aaUser
      : (pageConfig && pageConfig?.officeFilter?.value) || defaultState.aaUser,
    pageFilters:
      (pageConfig && pageConfig?.filters) || defaultState.pageFilters,
    clickedRowId:
      (pageConfig && pageConfig?.clickedRowId) || defaultState.clickedRowId,
    approved:
      pageConfig?.approved === undefined || pageConfig?.approved === null
        ? defaultState.approved
        : pageConfig?.approved,
    unapproved:
      pageConfig?.unapproved === undefined || pageConfig?.unapproved === null
        ? defaultState?.unapproved
        : pageConfig.unapproved,
    customer:
      (pageConfig && pageConfig?.customerFilter?.value) ||
      defaultState.customer,
    kam: (pageConfig && pageConfig?.kamFilter?.value) || defaultState.kam,
    IC: (pageConfig && pageConfig?.ICFilter?.value) || defaultState.IC,
    dateFilterType:
      (pageConfig && pageConfig?.dateFilterType) || defaultState.dateFilterType,
    testId: data?.daily_schedule_id || defaultState.testId,
    filters: {
      ...defaultState.filters,
      customer: (pageConfig && pageConfig?.customerFilter?.customer) || [],
      IC: (pageConfig && pageConfig?.ICFilter?.IC) || [],
      kam: (pageConfig && pageConfig?.kamFilter?.kam) || [],
      locations: (pageConfig && pageConfig?.locationFilter?.locations) || [],
      is_verified:
        (pageConfig?.unapproved && pageConfig?.approved) ||
        (!pageConfig?.unapproved && !pageConfig?.approved)
          ? []
          : pageConfig?.is_verified || [],
      aaUser: isRoleAA
        ? pageConfig?.officeFilter === undefined
          ? [{ field: "aa_id", type: "=", value: currentUser?.id }]
          : pageConfig?.officeFilter?.aaUser || []
        : (pageConfig && pageConfig?.officeFilter?.aaUser) || [],
      startDate: [
        {
          field: "schedule_date",
          type: "=",
          value: data.schedule_date
            ? getUTCDateString(data.schedule_date)
            : (pageConfig && pageConfig?.startDateFilter) ||
              getDateString(new Date()),
        },
      ],
      endDate:
        (pageConfig && pageConfig?.endDateFilter) || getDateString(new Date()),
      // kam: data.kam_id
      //   ? [
      //       {
      //         field: "kam_id",
      //         type: "=",
      //         value: data.kam_id,
      //       },
      //     ]
      //   : [],
      daily_schedule_id: data.daily_schedule_id
        ? [
            {
              field: "daily_schedule_id",
              type: "=",
              value: data.daily_schedule_id,
            },
          ]
        : [],
    },
  });

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (selectedRef.current && !!state.clickedRowId) {
        selectedRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        observer.disconnect();
      }
    });

    if (document.body) {
      observer.observe(document.body, { childList: true, subtree: true });
    }

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      setPageConfig(PAGE_KEYS.BILLING, {
        clickedRowId: null,
      });
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const locationOPtions = Object.entries(state.customerlocationList)
    .map(([state, value]) =>
      value.map((item) => ({
        label: `${item}-(${state})`,
        value: item,
      }))
    )
    .flat()
    .sort((a, b) => Intl.Collator().compare(a.label, b.label));

  const handleFilterChange = useCallback((pageFilters = []) => {
    setState((prevState) => ({
      ...prevState,
      pageFilters,
      selectedRows: [],
    }));
    setPageConfig(PAGE_KEYS.BILLING, {
      filters: pageFilters,
    });
    gridHelper && gridHelper.resetSelection();
  }, []);

  const getPzriceMatrix = useCallback(async () => {
    setState((prevState) => ({
      ...prevState,
      isPriceMatrixFetched: true,
    }));
    const { data, error } = await Service.priceMatrix();
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isPriceMatrixFetched: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      priceMatrix: data.rows || defaultState.priceMatrix,
      isPriceMatrixFetched: false,
    }));
  }, []);

  useEffect(() => {
    getPzriceMatrix();
  }, []);

  const filterConfig = useMemo(
    () => [
      {
        field: "status",
        fieldToDisplay: "Status",
        operatorType: "string",
        options: [
          {
            label: "New",
            value: "New",
          },
          {
            label: "AA Approved",
            value: "AA Approved",
          },

          {
            label: "Admin Approved",
            value: "Admin Approved",
          },
          {
            label: "Admin Rejected",
            value: "Admin Rejected",
          },
          {
            label: "Awaiting Review",
            value: "Awaiting Review",
          },
          {
            label: "DS Rejected",
            value: "DS Rejected",
          },

          {
            label: "MGR Approved",
            value: "MGR Approved",
          },
          {
            label: "MGR Rejected",
            value: "MGR Rejected",
          },
          {
            label: "Under Review",
            value: "Under Review",
          },
        ],
      },
      // {
      //   field: "location",
      //   fieldToDisplay: "Location",
      //   operatorType: "string",
      //   options: Object.entries(state.customerlocationList)
      //     .map(([state, value]) =>
      //       value.map((item) => ({
      //         label: `${item}-(${state})`,
      //         value: item,
      //       }))
      //     )
      //     .flat()
      //     .sort((a, b) => Intl.Collator().compare(a.label, b.label)),
      // },
      {
        field: "route_name",
        fieldToDisplay: "Route",
        operatorType: "string",
        options: appDataList?.customers
          .map((customer) => customer?.customer_branches)
          .flat()
          .map((branches) => branches?.routes)
          .flat()
          .map((route) => ({ label: route.name, value: route.name }))
          .sort((a, b) => Intl.Collator().compare(a.label, b.label)),
      },
      {
        field: "state",
        fieldToDisplay: "State",
        operatorType: "iLike",
        options: state.stateList?.map((state) => ({
          label: state.name,
          value: state.abbreviation,
        })),
      },
      {
        field: "pdx_company_name",
        fieldToDisplay: "PDX Company",
        operatorType: "string",
        options: state.pdxCompanyList?.map((company) => ({
          label: company.value,
          value: company.value,
        })),
      },
      {
        field: "vehicle",
        fieldToDisplay: "Vehicle",
        operatorType: "string",
        options: state.vehicleTypeList.map((vehicle) => ({
          ...vehicle,
          label: `${vehicle.value}`,
          value: vehicle.value,
        })),
      },
      {
        field: "settlement_co_name",
        fieldToDisplay: "Settlement Company",
        operatorType: "number",
        options: state.settlementCompanyList.map((company) => ({
          label: company.value,
          value: company.value,
        })),
      },
      {
        field: "bill_rate_type",
        fieldToDisplay: "Billing Rate Type",
        operatorType: "string",
        options: [
          { label: "Daily", value: "Daily" },
          { label: "Hourly", value: "Hourly" },
          { label: "Per Miles", value: "Per Miles" },
          { label: "Per Tire", value: "Per Tire" },
          { label: "Per Run", value: "Per Run" },
        ],
      },
      {
        field: "bill_rate",
        fieldToDisplay: "Billing Rate",
        operatorType: "number",
      },
      {
        field: "bill_quantity",
        fieldToDisplay: "Billing Quantity",
        operatorType: "number",
      },
      {
        field: "bill_amount",
        fieldToDisplay: "Billing Amount",
        operatorType: "number",
      },
      {
        field: "bill_fuel_surcharge",
        fieldToDisplay: "Billing Fuel Surcharge%",
        operatorType: "number",
      },
      {
        field: "bill_fuel_amount",
        fieldToDisplay: "Billing Fuel Amount",
        operatorType: "number",
      },
      {
        field: "bill_toll_amount",
        fieldToDisplay: "Billing Toll Amount",
        operatorType: "number",
      },
      {
        field: "bill_extra_miles",
        fieldToDisplay: "Billing Extra Miles",
        operatorType: "number",
      },
      {
        field: "bill_rate_per_extra_mile",
        fieldToDisplay: "Billing Rate Per Extra Mile",
        operatorType: "number",
      },
      {
        field: "bill_extra_mile_amount",
        fieldToDisplay: "Billing Extra Miles Amount",
        operatorType: "number",
      },
      {
        field: "pay_rate_type",
        fieldToDisplay: "Pay Rate Type",
        operatorType: "string",
        options: [
          { label: "Daily", value: "Daily" },
          { label: "Hourly", value: "Hourly" },
          { label: "Per Miles", value: "Per Miles" },
          { label: "Per Tire", value: "Per Tire" },
          { label: "Per Run", value: "Per Run" },
        ],
      },
      {
        field: "pay_rate",
        fieldToDisplay: "Pay Rate",
        operatorType: "number",
      },
      {
        field: "pay_quantity",
        fieldToDisplay: "Pay Quantity",
        operatorType: "number",
      },
      {
        field: "pay_amount",
        fieldToDisplay: "Pay Amount",
        operatorType: "number",
      },
      {
        field: "pay_fuel_surcharge",
        fieldToDisplay: "Pay Fuel Surcharge%",
        operatorType: "number",
      },
      {
        field: "pay_fuel_amount",
        fieldToDisplay: "Pay Fuel Amount",
        operatorType: "number",
      },
      {
        field: "pay_toll_amount",
        fieldToDisplay: "Pay Toll Amount",
        operatorType: "number",
      },
      {
        field: "pay_extra_miles",
        fieldToDisplay: "Pay Extra Miles",
        operatorType: "number",
      },
      {
        field: "pay_rate_per_extra_mile",
        fieldToDisplay: "Pay Rate Per Extra Mile",
        operatorType: "number",
      },
      {
        field: "pay_extra_mile_amount",
        fieldToDisplay: "Pay Extra Miles Amount",
        operatorType: "number",
      },
      {
        field: "reimbursement",
        fieldToDisplay: "Reimbursement",
        operatorType: "number",
      },
      {
        field: "deduction",
        fieldToDisplay: "Deduction",
        operatorType: "number",
      },
      {
        field: "deduction_reason",
        fieldToDisplay: "Deduction Reason",
        operatorType: "eq",
        options: state.deductionReasonList.map((reason) => ({
          label: reason?.concatName,
          value: reason?.id,
        })),
      },
      {
        field: "manager_name",
        fieldToDisplay: "Manager",
        operatorType: "string",
        options: appDataList?.managerUsers?.map((manager) => ({
          ...manager,
          label: `${manager.first_name || ""} ${manager.last_name || ""}-(${
            manager.username
          })`,
          value: `${manager.first_name || ""} ${manager.last_name || ""}`,
        })),
      },
      {
        field: "profit_percentage",
        fieldToDisplay: "Profit Percentage",
        operatorType: "number",
      },
      {
        field: "DS_submitted_by",
        fieldToDisplay: "Submitted By",
        operatorType: "eq",
        options: appData?.users?.map((user) => ({
          ...user,
          label: `${user.first_name || ""} ${user.last_name || ""}-(${
            user.username
          })`,
          value: user?.id,
        })),
      },
      {
        field: "submitted_at",
        fieldToDisplay: "DS Submitted At",
        operatorType: "range",
        isDateField: true,
      },
      {
        field: "updated_by",
        fieldToDisplay: "Last Updated By",
        operatorType: "eq",
        options: appData?.users?.map((user) => ({
          ...user,
          label: `${user.first_name || ""} ${user.last_name || ""}-(${
            user.username
          })`,
          value: user?.id,
        })),
      },
      {
        field: "updated_at",
        fieldToDisplay: "Last Updated At",
        operatorType: "range",
        isDateField: true,
      },
    ],
    [
      state.vehicleTypeList,
      state.settlementCompanyList,
      state.customerlocationList,
      appDataList.managerUsers,
      state.stateList,
      state.pdxCompanyList,
      state.deductionReasonList,
      appDataList?.customers,
    ]
  );

  const fetchLocalResources = async () => {
    const response = await Service.getLocalResources();
    setState((prevState) => ({
      ...prevState,
      stateList: (response?.data?.states || defaultState.stateList).sort(
        (a, b) => {
          const valueA = (a.name || "").toLowerCase();
          const valueB = (b.name || "").toLowerCase();
          return valueA > valueB ? 1 : valueB > valueA ? -1 : 0;
        }
      ),
      locationList: response?.data?.locations || defaultState.locationList,
    }));
  };

  const fetchCustomerLocation = async () => {
    const { data, error } = await Service.getCustomerLocation();

    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      customerlocationList:
        data?.locations || defaultState.customerlocationList,
    }));
  };

  const fetchCompanyList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        settlementCompany: true,
      },
    }));

    const { data, error } = await SharedService.getSettlementCompanyList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          settlementCompany: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      settlementCompanyList:
        data?.rows.filter((settlementCompany) => settlementCompany.is_active) ||
        defaultState.settlementCompanyList,
      isFetchingList: {
        ...prevState.isFetchingList,
        settlementCompany: false,
      },
    }));
  };

  const handleDownload = async (
    search,
    filters,
    order,
    orderBy,
    searchColumns,
    pageFilters = []
  ) => {
    const searchArray = getSearchArray(searchColumns, search);
    const sortObj = { field: orderBy, order };

    let filtersArr = [];
    if (
      state.dateFilterType === "single" &&
      filters.startDate.length &&
      filters.startDate[0].value
    ) {
      filtersArr = [...filtersArr, ...filters.startDate];
    }

    // if (filters.endDate.length && filters.endDate[0].value) {
    //   filtersArr = [...filtersArr, ...filters.endDate];
    // }

    if (filters.kam.length && filters.kam[0].value) {
      filtersArr = [...filtersArr, ...filters.kam];
    }

    if (filters.customer.length && filters.customer[0].value) {
      filtersArr = [...filtersArr, ...filters.customer];
    }

    if (filters.locations.length && filters.locations[0].value) {
      filtersArr = [...filtersArr, ...filters.locations];
    }
    if (filters.aaUser.length && filters.aaUser[0].value) {
      filtersArr = [...filtersArr, ...filters.aaUser];
    }
    if (filters.IC.length && filters.IC[0].value) {
      filtersArr = [...filtersArr, ...filters.IC];
    }

    if (
      filters.daily_schedule_id.length &&
      filters.daily_schedule_id[0].value
    ) {
      filtersArr = [...filtersArr, ...filters.daily_schedule_id];
    }

    if (filters.is_verified.length) {
      filtersArr = [...filtersArr, ...filters.is_verified];
    }

    filtersArr = [
      ...filtersArr.map((item) => {
        if (item.field === "location") {
          return { ...item, notIncludeInBuilder: false };
        }
        return { ...item, notIncludeInBuilder: true };
      }),
      ...pageFilters.map((filter) => {
        if (filter?.field === "submitted_at" && filter?.type === "eq") {
          return [
            {
              ...filter,
              field: "submitted_at",
              type: "lte",
              value: `${filter?.value} 23:59:59.999999`,
            },
            {
              ...filter,
              field: "submitted_at_gte",
              type: "gte",
              value: `${filter?.value} 00:00:00`,
            },
          ];
        } else if (filter?.field === "submitted_at" && filter?.type === "ra") {
          const [startDate, endDate] = filter?.value.split(" - ");
          return [
            {
              ...filter,
              field: "submitted_at",
              type: "gte",
              value: `${startDate}  00:00:00`,
            },
            {
              ...filter,
              field: "submitted_at_gte",
              type: "lte",
              value: `${endDate} 23:59:59.999999`,
            },
          ];
        } else if (filter?.field === "updated_at" && filter?.type === "eq") {
          return [
            {
              ...filter,
              field: "updated_at",
              type: "lte",
              value: `${filter?.value} 23:59:59.999999`,
            },
            {
              ...filter,
              field: "updated_at_gte",
              type: "gte",
              value: `${filter?.value} 00:00:00`,
            },
          ];
        } else if (filter?.field === "updated_at" && filter?.type === "ra") {
          const [startDate, endDate] = filter?.value.split(" - ");
          return [
            {
              ...filter,
              field: "updated_at",
              type: "gte",
              value: `${startDate}  00:00:00`,
            },
            {
              ...filter,
              field: "updated_at_gte",
              type: "lte",
              value: `${endDate} 23:59:59.999999`,
            },
          ];
        } else {
          return {
            ...filter,
            value: filter?.value?.value || filter?.value,
          };
        }
      }),
    ].flat();

    setState((prevState) => ({
      ...prevState,
      isDownloadLoading: true,
    }));

    let query = queryStringBuilderNew(
      0,
      0,
      searchArray,
      filtersArr,
      sortObj,
      search && search.length > 0
    );
    if (
      state.dateFilterType === "multiple" &&
      filtersArr?.length === 0 &&
      filters.startDate.length &&
      filters.startDate[0].value
    ) {
      query += `?filter[where][schedule_date][gte]=${filters.startDate[0].value}`;
    }
    if (
      state.dateFilterType === "multiple" &&
      filtersArr?.length > 0 &&
      filters.startDate.length &&
      filters.startDate[0].value
    ) {
      query += `&filter[where][schedule_date][gte]=${filters.startDate[0].value}`;
    }
    if (
      state.dateFilterType === "multiple" &&
      filters.endDate.length &&
      (filters.endDate[0].value || filters.endDate)
    ) {
      query += `&filter[where][schedule_date][lte]=${
        filters.endDate[0].value || filters.endDate
      }`;
    }
    if (query.includes("submitted_at_gte")) {
      query = query.replace(/submitted_at_gte/g, "submitted_at");
    }
    if (query.includes("updated_at_gte")) {
      query = query.replace(/updated_at_gte/g, "updated_at");
    }
    const { data, error } = await Service.download(query);
    if (error) {
      toast.error(Array.isArray(error) ? error[0].message : error.message);
      setState((prevState) => ({
        ...prevState,
        isDownloadLoading: false,
      }));
    } else {
      toast.success("Billing & Settlements records downloaded successfully.");
      exportBase64ToFile(data, "Billing & Settlements");
    }

    setState((prevState) => ({
      ...prevState,
      isDownloadLoading: false,
    }));
  };

  const fetchPDXCompanyList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: true,
      },
    }));

    const { data, error } = await SharedService.getPDXCompanyList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          pdxCompany: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      pdxCompanyList:
        data?.rows.filter((pdxCompany) => pdxCompany.is_active) ||
        defaultState.pdxCompanyList,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: false,
      },
    }));
  };

  const fetchVehicleTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        vehicleType: true,
      },
    }));
    const { data, error } = await SharedService.getVehicleTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          vehicleType: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      vehicleTypeList:
        data?.rows.filter((vehicle) => vehicle.is_active) ||
        defaultState.vehicleTypeList,
      isFetchingList: {
        ...prevState.isFetchingList,
        vehicleType: false,
      },
    }));
  };

  const fetchDeductionReasonList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        deductionReasonList: true,
      },
    }));
    const { data, error } = await SharedService.getDeductionReasonList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          deductionReasonList: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    const mappedDeductionReasonList = data?.rows.map((row) => {
      const concatName = `${row?.value} (${
        row?.description ?? "Misc2Deduction"
      })`;
      return {
        ...row,
        concatName,
      };
    });

    setState((prevState) => ({
      ...prevState,
      deductionReasonList:
        mappedDeductionReasonList.filter((reason) => reason.is_active) ||
        defaultState.deductionReasonList,
      isFetchingList: {
        ...prevState.isFetchingList,
        deductionReason: false,
      },
    }));
  };

  const getSearchArray = (searchColumns, search) => {
    const columns = searchColumns.length
      ? [...searchColumns]
      : [
          "icid",
          "contractor_first_name",
          "customer_name",
          "location",
          "route",
          "vehicle_type",
        ];
    if (columns.includes("contractor_first_name")) {
      columns.push("contractor_last_name");
    }
    return columns.map((column) => {
      const searchArr = search.split(" ");
      if (
        (column === "contractor_first_name" ||
          column === "contractor_last_name") &&
        searchArr.length > 1
      ) {
        return {
          field: column,
          value:
            column === "contractor_first_name" ? searchArr[0] : searchArr[1],
        };
      }

      return {
        field: column,
        value: search,
      };
    });
  };
  const calculateProfitPercentage = ({ bill_amount = 0, pay_amount = 0 }) => {
    let profit = +bill_amount - +pay_amount;
    profit = (+profit / Math.abs(+bill_amount)) * 100;

    return isFinite(profit) ? profit.toFixed(2) : 0;
  };

  const fetchEntries = useCallback(
    async (
      search,
      filters,
      pageSize,
      pageNumber,
      order,
      orderBy,
      searchColumns,
      pageFilters = [],
      hasLoader = true
    ) => {
      const searchArray = getSearchArray(searchColumns, search);
      const sortObj = { field: orderBy, order };

      let filtersArr = [];
      if (
        state.dateFilterType === "single" &&
        filters.startDate.length &&
        filters.startDate[0].value
      ) {
        filtersArr = [...filtersArr, ...filters.startDate];
      }

      // if (filters.endDate.length && filters.endDate[0].value) {
      //   filtersArr = [...filtersArr, ...filters.endDate];
      // }

      if (filters.kam.length && filters.kam[0].value) {
        filtersArr = [...filtersArr, ...filters.kam];
      }

      if (filters.locations.length && filters.locations[0].value) {
        filtersArr = [...filtersArr, ...filters.locations];
      }

      if (filters.customer.length && filters.customer[0].value) {
        filtersArr = [...filtersArr, ...filters.customer];
      }
      if (filters.aaUser.length && filters.aaUser[0].value) {
        filtersArr = [...filtersArr, ...filters.aaUser];
      }
      if (filters.IC.length && filters.IC[0].value) {
        filtersArr = [...filtersArr, ...filters.IC];
      }

      if (
        filters.daily_schedule_id.length &&
        filters.daily_schedule_id[0].value
      ) {
        filtersArr = [...filtersArr, ...filters.daily_schedule_id];
      }

      if (filters.is_verified.length) {
        filtersArr = [...filtersArr, ...filters.is_verified];
      }

      filtersArr = [
        ...filtersArr.map((item) => {
          if (item.field === "location") {
            return { ...item, notIncludeInBuilder: false };
          }
          return { ...item, notIncludeInBuilder: true };
        }),
        ...pageFilters.map((filter) => {
          if (filter?.field === "submitted_at" && filter?.type === "eq") {
            return [
              {
                ...filter,
                field: "submitted_at",
                type: "lte",
                value: `${filter?.value} 23:59:59.999999`,
              },
              {
                ...filter,
                field: "submitted_at_gte",
                type: "gte",
                value: `${filter?.value} 00:00:00`,
              },
            ];
          } else if (
            filter?.field === "submitted_at" &&
            filter?.type === "ra"
          ) {
            const [startDate, endDate] = filter?.value.split(" - ");
            return [
              {
                ...filter,
                field: "submitted_at",
                type: "gte",
                value: `${startDate}  00:00:00`,
              },
              {
                ...filter,
                field: "submitted_at_gte",
                type: "lte",
                value: `${endDate} 23:59:59.999999`,
              },
            ];
          } else if (filter?.field === "updated_at" && filter?.type === "eq") {
            return [
              {
                ...filter,
                field: "updated_at",
                type: "lte",
                value: `${filter?.value} 23:59:59.999999`,
              },
              {
                ...filter,
                field: "updated_at_gte",
                type: "gte",
                value: `${filter?.value} 00:00:00`,
              },
            ];
          } else if (filter?.field === "updated_at" && filter?.type === "ra") {
            const [startDate, endDate] = filter?.value.split(" - ");
            return [
              {
                ...filter,
                field: "updated_at",
                type: "gte",
                value: `${startDate}  00:00:00`,
              },
              {
                ...filter,
                field: "updated_at_gte",
                type: "lte",
                value: `${endDate} 23:59:59.999999`,
              },
            ];
          } else {
            return {
              ...filter,
              value: filter?.value?.value || filter?.value,
            };
          }
        }),
      ].flat();

      let queryString = queryStringBuilderNew(
        pageSize,
        pageSize * (pageNumber - 1),
        searchArray,
        filtersArr,
        sortObj,
        search && search.length > 0
      );
      if (
        state.dateFilterType === "multiple" &&
        filters.startDate.length &&
        filters.startDate[0].value
      ) {
        queryString += `&filter[where][schedule_date][gte]=${filters.startDate[0].value}`;
      }
      if (
        state.dateFilterType === "multiple" &&
        filters.endDate.length &&
        (filters.endDate[0].value || filters.endDate)
      ) {
        queryString += `&filter[where][schedule_date][lte]=${
          filters.endDate[0].value || filters.endDate
        }`;
      }

      setState((prevState) => ({
        ...prevState,
        isFetching: hasLoader,
      }));
      if (queryString.includes("submitted_at_gte")) {
        queryString = queryString.replace(/submitted_at_gte/g, "submitted_at");
      }
      if (queryString.includes("updated_at_gte")) {
        queryString = queryString.replace(/updated_at_gte/g, "updated_at");
      }
      const { data, error } = await Service.get(queryString);
      if (error) {
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0].message : error.message
        );
      }

      // const sortedEntries = (data.result || defaultState.entries).sort(
      //   (rowA, rowB) => {
      //     const rowAcustomerName = (
      //       rowA?.customer_branch?.customer?.name || ""
      //     ).toLowerCase();
      //     const rowAlocation = (
      //       rowA?.customer_branch?.location || ""
      //     ).toLowerCase();
      //     const rowAroute = (rowA?.route || "").toLowerCase();
      //     const rowAdate = rowA?.schedule_date || "";
      //     const rowBcustomerName = (
      //       rowB?.customer_branch?.customer?.name || ""
      //     ).toLowerCase();
      //     const rowBlocation = (
      //       rowB?.customer_branch?.location || ""
      //     ).toLowerCase();
      //     const rowBdate = rowB?.schedule_date || "";
      //     const rowBroute = (rowB?.route || "").toLowerCase();
      //     const valueA = `${rowAdate}${rowAcustomerName}${rowAlocation}${rowAroute}`;
      //     const valueB = `${rowBdate}${rowBcustomerName}${rowBlocation}${rowBroute}`;

      //     return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
      //   }
      // );

      setState((prevState) => ({
        ...prevState,
        isFetching: false,
        entries: data?.result || defaultState.entries,
        totalEntries: data.count,
      }));

      return data;
    },
    [state.dateFilterType]
  );

  const fetchList = async (listType = "customers", ...params) => {
    setState((prevState) => ({
      ...prevState,
      isIcFetching: true,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    switch (listType) {
      case "customers":
        serviceMethod = "getCustomerList";
        responseKey = "customers";
        break;
      case "IC":
        serviceMethod = "getContractorList";
        responseKey = "contractors";
        break;
      case "users":
        serviceMethod = "getUsersList";
        responseKey = "users";
        break;
      case "icList":
        serviceMethod = "getIcList";
        responseKey = "contractors";
        break;
      default:
        serviceMethod = "getCustomerList";
        responseKey = "customers";
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isIcFetching: false,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];
    if (listType === "customers") {
      listData.map((item = {}) => {
        (item.customer_branches || []).map((branch = {}) => {
          (branch.routes || []).sort((a, b) =>
            Intl.Collator().compare(a.name, b.name)
          );
        });
      });
    }
    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isIcFetching: false,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
  };

  const handleFieldAlertSubmit = async (id, payload) => {
    if (state.rowBeingEdited) {
      setState((prevState) => ({
        ...prevState,
        fieldAlert: defaultState.fieldAlert,
        formAlertFields: {
          ...prevState.formAlertFields,
          ...payload,
        },
      }));
    }
  };

  const showFieldAlert = (field, row) => {
    const alertFieldGroup = [
      {
        group: "bill",
        fields: ["bill_rate_type", "bill_quantity", "bill_rate"],
      },
      { group: "pay", fields: ["pay_rate_type", "pay_quantity", "pay_rate"] },
    ];

    const group = (
      alertFieldGroup.find((item) => item.fields.includes(field)) || {}
    ).group;
    if (!group || !row) {
      return;
    }

    const quantityValidations = [
      { type: VALIDATIONS.MIN, value: 0 },
      { type: VALIDATIONS.LIMIT, value: 10.2 },
    ];

    const rateValidations = [{ type: VALIDATIONS.MAX_LENGTH, value: 10 }];
    setState((prevState) => ({
      ...prevState,
      fieldAlert: {
        ...prevState.fieldAlert,
        show: true,
        id: row?.id,
        subHeading:
          group === "bill"
            ? `Bill Rate Type, Bill Quantity, and Bill Rate are interrelated fields.`
            : `Pay Rate Type, Pay Quantity, and Pay Rate are interrelated fields.`,
        onChange: (field, value, updatedState = {}, callback) => {
          if (field === "bill_rate_type") {
            if (row.bill_rate_type === value) {
              return false;
            } else {
              updatedState = {
                ...updatedState,
                bill_quantity:
                  value === "Hourly" ? "8" : value === "Daily" ? "1" : "",
                errors: {
                  ...updatedState.errors,
                  bill_quantity: " ",
                },
              };
              callback(updatedState);
            }
          }
          if (field === "pay_rate_type") {
            if (row.pay_rate_type === value) {
              return false;
            } else {
              updatedState = {
                ...updatedState,
                pay_quantity:
                  value === "Hourly" ? "8" : value === "Daily" ? "1" : "",
                errors: {
                  ...updatedState.errors,
                  pay_quantity: " ",
                },
              };
              callback(updatedState);
            }
          }
        },
        updateValidations: (fields = {}, fieldValidatorMap = {}) => {
          let updatedFieldValidatorMap = { ...fieldValidatorMap };

          if (fields["bill_rate_type"] === "Hourly") {
            if (
              !updatedFieldValidatorMap["bill_quantity"].some(
                (item) => item.id === "max_error"
              )
            ) {
              updatedFieldValidatorMap["bill_quantity"] = [
                {
                  type: VALIDATIONS.MAX,
                  value: 24,
                  id: "max_error",
                },
                ...updatedFieldValidatorMap["bill_quantity"],
              ];
            }
          }

          if (fields["pay_rate_type"] === "Hourly") {
            if (
              !updatedFieldValidatorMap["pay_quantity"].some(
                (item) => item.id === "max_error"
              )
            ) {
              updatedFieldValidatorMap["pay_quantity"] = [
                {
                  type: VALIDATIONS.MAX,
                  value: 24,
                  id: "max_error",
                },
                ...updatedFieldValidatorMap["pay_quantity"],
              ];
            }
          }

          return updatedFieldValidatorMap;
        },
        configs: [
          {
            type: "autoComplete",
            name: `${group}_rate_type`,
            label: `${group === "bill" ? "Bill" : "Pay"} Rate Type`,
            value: row[`${group}_rate_type`],
            options: RATE_TYPES,
            disableClearable: true,
            required: true,
          },
          {
            type: "textField",
            name: `${group}_quantity`,
            label: `${group === "bill" ? "Bill" : "Pay"} Quantity`,
            value: row[`${group}_quantity`],
            inputType: "number",
            validations: quantityValidations,
          },
          {
            type: "textField",
            name: `${group}_rate`,
            label: `${group === "bill" ? "Bill" : "Pay"} Rate`,
            disabled: group === "bill" ? row.dont_bill : row.dont_pay,
            value:
              group === "bill" && row.dont_bill
                ? "0"
                : group === "Pay" && row.dont_pay
                ? "0"
                : row[`${group}_rate`],
            inputType: "number",
            inputProps: {
              startAdornment: <InputAdornment>$</InputAdornment>,
            },
            validations: rateValidations,
          },
        ],
      },
    }));
  };

  useEffect(() => {
    setTimeout(updateLayout, 1000);
  }, [state.entries]);

  useEffect(() => {
    if (!state.approved && !state.unapproved) {
      setState((prevState) => ({
        ...prevState,
        entries: defaultState.entries,
        totalEntries: defaultState.totalEntries,
      }));
    } else {
      fetchEntries(
        state.search,
        state.filters,
        state.pageSize,
        state.pageNumber,
        state.order,
        state.orderBy,
        state.searchColumns,
        state.pageFilters
      ).then((data) => {
        updatePagination(
          data?.count,
          { pageNumber: state.pageNumber, pageSize: state.pageSize },
          (pageNumber) => {
            setState((prevState) => ({ ...prevState, pageNumber }));
          }
        );
      });
    }
  }, [
    fetchEntries,
    state.filters,
    state.pageSize,
    state.pageNumber,
    state.order,
    state.orderBy,
    state.search,
    state.pageFilters,
  ]);
  useEffect(() => {
    if (state.search) {
      fetchEntries(
        state.search,
        state.filters,
        state.pageSize,
        state.pageNumber,
        state.order,
        state.orderBy,
        state.searchColumns,
        state.pageFilters
      );
    }
  }, [state.searchColumns]);

  const fetchCautionFlagTypeList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetching: true,
    }));

    const { data, error } = await SharedService.getCautionFlagTypeList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetching: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      isFetching: false,
      cautionFlagSubmitReasonList: data?.rows || [],
    }));
  };

  useEffect(() => {
    getSetting();
    fetchLocalResources();
    fetchVehicleTypeList();
    fetchDeductionReasonList();
    fetchCautionFlagTypeList();
    fetchPDXCompanyList();
    fetchCompanyList();
    fetchCustomerLocation();
  }, []);

  useEffect(() => {
    fetchList("customers");
    // fetchList("IC");
    fetchList("icList");
    fetchList("users");
  }, [match.params.path]);

  const moreActions = (row) => {
    const invoiceStatus = !!row.invoice
      ? INVOICE_STATUS.find((item) => item.value === row.invoice.status)
      : "";
    return (
      <div className="d-flex f-align-center f-justify-end">
        <Tooltip title="Edit" placement="top-start">
          <EditOutlinedIcon
            className={clsx("ml-2 mr-2 c-pointer", {
              disabled:
                row.status === "UNDER REVIEW" ||
                row.status === "AWAITING REVIEW" ||
                row.status === "DS REJECTED",
            })}
            onClick={() => {
              getPzriceMatrix();
              setState((prevState) => ({ ...prevState, rowBeingEdited: row }));
            }}
          />
        </Tooltip>
        {(isRoleAA || isRoleADMIN) && (
          <Tooltip title="Delete" placement="top-start">
            <DeleteForeverOutlinedIcon
              color={
                row.status === "UNDER REVIEW" ||
                row.status === "AWAITING REVIEW" ||
                row.status === "DS REJECTED"
                  ? "disabled"
                  : "error"
              }
              className={clsx("ml-2 mr-2 c-pointer", {
                disabled:
                  row.status === "UNDER REVIEW" ||
                  row.status === "AWAITING REVIEW" ||
                  row.status === "DS REJECTED",
              })}
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  deletingEntryId: row.id,
                }))
              }
            />
          </Tooltip>
        )}
        {!!row.invoice ? (
          <Tooltip
            title={`Invoice no. #${row.invoice.serial_no}, Status: ${invoiceStatus.label}`}
            placement="top-start"
          >
            <div className="ml-4 mr-2 mt-2" style={{ cursor: "default" }}>
              <img src={invoiceIcon} alt="invoice" height={24} width={24} />
            </div>
          </Tooltip>
        ) : (
          <div className="ml-10 mr-2 mt-2"></div>
        )}
      </div>
    );
  };

  const handlePageNumberChange = useCallback((pageNumber) => {
    setPageConfig(PAGE_KEYS.BILLING, {
      pageNumber,
    });
    setState((prevState) => ({
      ...prevState,
      pageNumber,
      selectedRows: defaultState.selectedRows,
    }));
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setPageConfig(PAGE_KEYS.BILLING, {
      pageSize,
    });
    setState((prevState) => ({
      ...prevState,
      pageSize,
      pageNumber: defaultState.pageNumber,
      selectedRows: defaultState.selectedRows,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleSelectionChange = useCallback((selectedRows) => {
    setState((prevState) => ({
      ...prevState,
      selectedRows,
    }));
  }, []);

  const handleAllSelectionChange = useCallback((areAllSelected) => {
    setState((prevState) => ({
      ...prevState,
      areAllSelected,
      selectedRows: [],
    }));
  }, []);

  const handleSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      order: order,
      orderBy: fieldObj.field || fieldObj.fieldName,
      selectedRows: defaultState.selectedRows,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleSearch = useCallback((search) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        search,
        selectedRows: defaultState.selectedRows,
        pageNumber: defaultState.pageNumber,
      }));
      gridHelper && gridHelper.resetSelection();
    }, 1000);
  }, []);

  const handleDelete = async (entryId) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const { error } = await Service.delete(entryId);

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      deletingEntryId: null,
      selectedRows: defaultState.selectedRows,
    }));
    if (gridHelper) {
      gridHelper.resetSelection();
    }

    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      toast.success("Entry deleted successfully.");
      fetchEntries(
        state.search,
        state.filters,
        state.pageSize,
        state.pageNumber,
        state.order,
        state.orderBy,
        state.searchColumns,
        state.pageFilters
      ).then((data) => {
        updatePagination(
          data?.count,
          { pageNumber: state.pageNumber, pageSize: state.pageSize },
          (pageNumber) => {
            setState((prevState) => ({ ...prevState, pageNumber }));
          }
        );
      });
    }
  };

  const handleUnapprove = async () => {
    setState((prevState) => ({
      ...prevState,
      isApproveLoading: true,
    }));

    const payload = state.areAllSelected
      ? {
          status: false,
          is_all: true,
        }
      : {
          recordIds: state.selectedRows.map((id) => id),
          is_all: false,
          status: false,
        };
    //const payload = state.selectedRows.map((id) => ({ id, status: false }));

    const { error } = await Service.verify(payload);
    setState((prevState) => ({
      ...prevState,
      isApproved: false,
      isApproveLoading: false,
      showUnapproveConfirmationPopup: false,
      selectedRows: defaultState.selectedRows,
      areAllSelected: defaultState.areAllSelected,
    }));
    if (gridHelper) {
      gridHelper.resetSelection();
      gridHelper.resetAllSelection();
    }
    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success("All selected records are unapproved successfully.");
    setState((prevState) => ({
      ...prevState,

      isApproveLoading: false,
    }));
    fetchEntries(
      state.search,
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.searchColumns,
      state.pageFilters
    );
  };

  const handleApprove = async (
    search,
    filters,
    pageSize,
    pageNumber,
    order,
    orderBy,
    searchColumns,
    pageFilters = [],
    hasLoader = true
  ) => {
    const searchArray = getSearchArray(searchColumns, search);
    const sortObj = { field: orderBy, order };

    let filtersArr = [];
    if (filters.kam.length && filters.kam[0].value) {
      filtersArr = [...filtersArr, ...filters.kam];
    }

    if (filters.customer.length && filters.customer[0].value) {
      filtersArr = [...filtersArr, ...filters.customer];
    }
    if (filters.aaUser.length && filters.aaUser[0].value) {
      filtersArr = [...filtersArr, ...filters.aaUser];
    }
    if (filters.IC.length && filters.IC[0].value) {
      filtersArr = [...filtersArr, ...filters.IC];
    }

    if (
      filters.daily_schedule_id.length &&
      filters.daily_schedule_id[0].value
    ) {
      filtersArr = [...filtersArr, ...filters.daily_schedule_id];
    }

    if (filters.is_verified.length) {
      filtersArr = [...filtersArr, ...filters.is_verified];
    }

    filtersArr = [
      ...filtersArr.map((item) => ({ ...item, notIncludeInBuilder: true })),
      ...pageFilters.map((filter) => ({
        ...filter,
        value: filter?.value?.value || filter?.value,
      })),
    ];
    setState((prevState) => ({
      ...prevState,
      isApproved: true,
      isApproveLoading: true,
    }));
    let query = state.areAllSelected
      ? queryStringBuilderNew(
          0,
          0,
          searchArray,
          filtersArr,
          sortObj,
          search && search.length > 0
        )
      : "";
    const payload = state.areAllSelected
      ? state.dateFilterType === "single"
        ? {
            status: true,
            is_all: true,
            from_date: state.filters.startDate[0]?.value,
            to_date: state.filters.startDate[0]?.value,
          }
        : {
            status: true,
            is_all: true,
            from_date: state.filters.startDate[0]?.value,
            to_date: state.filters.endDate[0]?.value || state.filters?.endDate,
          }
      : {
          recordIds: state.selectedRows.map((id) => id),
          is_all: false,
          status: true,
        };

    const { error } = await Service.verify(payload, query);
    setState((prevState) => ({
      ...prevState,
      isApproved: false,
      isApproveLoading: false,
      isLoading: false,
      showConfirmationPopup: false,
      selectedRows: defaultState.selectedRows,
      areAllSelected: defaultState.areAllSelected,
    }));
    if (gridHelper) {
      gridHelper.resetSelection();
      gridHelper.resetAllSelection();
    }
    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success("All selected records are approved successfully.");
    setState((prevState) => ({
      ...prevState,
      showDoNotShowPopup: false,
      isApproveLoading: false,
    }));
    fetchEntries(
      state.search,
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.searchColumns,
      state.pageFilters
    );
  };

  const handleBulkApprove = async (
    search,
    filters,
    pageSize,
    pageNumber,
    order,
    orderBy,
    searchColumns,
    pageFilters = [],
    hasLoader = true
  ) => {
    const searchArray = getSearchArray(searchColumns, search);
    const sortObj = { field: orderBy, order };

    let filtersArr = [];
    if (filters.kam.length && filters.kam[0].value) {
      filtersArr = [...filtersArr, ...filters.kam];
    }

    if (filters.customer.length && filters.customer[0].value) {
      filtersArr = [...filtersArr, ...filters.customer];
    }
    if (filters.aaUser.length && filters.aaUser[0].value) {
      filtersArr = [...filtersArr, ...filters.aaUser];
    }
    if (filters.IC.length && filters.IC[0].value) {
      filtersArr = [...filtersArr, ...filters.IC];
    }

    if (
      filters.daily_schedule_id.length &&
      filters.daily_schedule_id[0].value
    ) {
      filtersArr = [...filtersArr, ...filters.daily_schedule_id];
    }

    if (filters.is_verified.length) {
      filtersArr = [...filtersArr, ...filters.is_verified];
    }

    filtersArr = [
      ...filtersArr.map((item) => ({ ...item, notIncludeInBuilder: true })),
      ...pageFilters.map((filter) => ({
        ...filter,
        value: filter?.value?.value || filter?.value,
      })),
    ];
    let query = state.areAllSelected
      ? queryStringBuilderNew(
          0,
          0,
          searchArray,
          filtersArr,
          sortObj,
          search && search.length > 0
        )
      : "";

    const payload =
      state.dateFilterType === "single"
        ? {
            from_date: state.filters.startDate[0]?.value,
            to_date: state.filters.startDate[0]?.value,
          }
        : {
            from_date: state.filters.startDate[0]?.value,
            to_date: state.filters.endDate[0]?.value || state.filters?.endDate,
          };

    const { data, error } = await Service.verifyAll(payload, query); // change the endpoint here

    if (!!data.id) {
      setState((prevState) => ({
        ...prevState,
        showApproveRecordsLoader: true,
        showDoNotShowPopup: false,
        showConfirmationPopup: false,
        loaderID: data?.id,
        isFetchingList: {
          ...prevState.isFetchingList,
          approveLoading: true,
        },
      }));
    }
    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
  };

  const checkBulkApproveAllStatus = async () => {
    const { data, error } = await Service.bulkApproveStatus(state.loaderID); // change the endpoint here

    if (data.status == 1) {
      setState((prevState) => ({
        ...prevState,
        areAllSelected: false,
        showApproveRecordsLoader: false,
        loaderID: defaultState.loaderID,
        isFetchingList: {
          ...prevState.isFetchingList,
          approveLoading: false,
        },
      }));
      toast.success("Records Approved Successfully");
      fetchEntries(
        state.search,
        state.filters,
        state.pageSize,
        state.pageNumber,
        state.order,
        state.orderBy,
        state.searchColumns,
        state.pageFilters
      );
    }

    if (data.status == 2) {
      setState((prevState) => ({
        ...prevState,
        areAllSelected: false,
        showApproveRecordsLoader: false,
        loaderID: defaultState.loaderID,
        isFetchingList: {
          ...prevState.isFetchingList,
          approveLoading: false,
        },
      }));
      toast.error("Records not Approved Successfully");
      fetchEntries(
        state.search,
        state.filters,
        state.pageSize,
        state.pageNumber,
        state.order,
        state.orderBy,
        state.searchColumns,
        state.pageFilters
      );
    }

    if (gridHelper) {
      gridHelper.resetSelection();
      gridHelper.resetAllSelection();
    }
  };

  const checkApproveStatus = () => {
    clearTimeout(approveTimeout);
    checkBulkApproveAllStatus();
    approveTimeout = setTimeout(checkApproveStatus, 1000 * 10);
  };

  useEffect(() => {
    if (!!state.showApproveRecordsLoader) {
      checkApproveStatus();
    }
    return () => {
      clearTimeout(approveTimeout);
    };
  }, [state.loaderID]);

  const getSetting = async () => {
    const { data, error } = await Service.getSetting(userID);
    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    const show = data.settings.doNotShow.bsAllApprove;

    setState((prevState) => ({
      ...prevState,
      showDoNotShowCheckboxValue: show,
    }));
  };

  const handleCheckBoxChange = async (evt) => {
    const value = evt.target.checked;
    const payload = {
      settings: { doNotShow: { bsAllApprove: value } },
      employee_id: currentUser?.id,
    };
    const { data, error } = await SharedService.updateUserSettings(payload);

    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    getSetting();
  };

  const handleDoNotShowDialog = () => {
    setState((prevState) => ({
      ...prevState,
      showDoNotShowPopup: true,
    }));
  };

  useEffect(() => {
    const shouldUnapproveEnable = state.entries
      ?.filter((entry) => state.selectedRows?.includes(entry.id))
      .every(
        (entry) =>
          entry.status === "MGR APPROVED" ||
          entry.status === "AA APPROVED" ||
          entry.status === "ADMIN APPROVED"
      );

    const shouldApproveEnable = state.entries
      ?.filter((entry) => state.selectedRows?.includes(entry.id))
      .every((entry) => entry.can_approve);

    setState((prevState) => ({
      ...prevState,
      shouldApproveEnable,
      shouldUnapproveEnable,
    }));
  }, [state.selectedRows]);

  useEffect(() => {
    const isVerified =
      (state.approved && state.unapproved) ||
      (!state.approved && !state.unapproved)
        ? null
        : state.approved;

    setState((prevState) => ({
      ...prevState,

      filters: {
        ...prevState.filters,
        is_verified:
          isVerified === null
            ? []
            : [
                {
                  field: "is_verified",
                  type: "=",
                  value: isVerified,
                },
              ],
      },
    }));
    setPageConfig(PAGE_KEYS.BILLING, {
      is_verified:
        isVerified === null
          ? []
          : [
              {
                field: "is_verified",
                type: "=",
                value: isVerified,
              },
            ],
    });
  }, [state.approved, state.unapproved]);

  const userInfo = (id) => appData.users?.find((data) => data?.id === id);
  const userFlagInfo = (id) =>
    state.cautionFlagSubmitReasonList?.find((data) => data?.id === id);

  const columnConfig = [
    {
      isHidden: !state.dynamicColumns?.status?.isSelected,
      id: "status",
      label: "Status",
      field: "status",
      canSort: true,
      render: (row) => (
        <Tooltip title={row.status || "-"} placement="top-start">
          <Typography variant="body2" noWrap>
            {row.status || "-"}
          </Typography>
        </Tooltip>
      ),
    },

    {
      isHidden: !state.dynamicColumns?.schedule_date?.isSelected,
      id: "schedule_date",
      label: "Schedule Date",
      field: "schedule_date",
      canSort: true,
      render: (row) => {
        const scheduleDate = getUTCDateString(row.schedule_date) || "";
        return (
          <Tooltip title={scheduleDate} placement="top-start">
            <Typography variant="body2" noWrap>
              {scheduleDate || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.customer_name?.isSelected,
      id: "customer_name",
      label: "Customer Name",
      field: "customer_name",
      canSort: true,
      render: (row) => {
        // const title = `
        //   Invoice: ${
        //     INVOICE_TYPES.find(
        //       (invoice) =>
        //         invoice.id === row.customer_branch?.invoice_type ||
        //         invoice.label === row.customer_branch?.invoice_type
        //     )?.label || "-"
        //   }
        // `;
        return (
          <Tooltip
            title={row.customer_branch?.customer?.name || "-"}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.customer_branch?.customer?.name || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.location?.isSelected,
      id: "location",
      label: "Location",
      field: "location",
      canSort: true,
      render: (row) => {
        // const title = `State: ${
        //   row.customer_branch?.state?.name || "-"
        // }, PDX Company: ${row.customer_branch?.pdxCompany?.value || "-"}`;
        return (
          <Tooltip
            title={row.customer_branch?.location || "-"}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.customer_branch?.location || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.route?.isSelected,
      headerClassName: classes.tableHeaderCell,
      id: "route_name",
      label: "Route",
      field: "route_name",
      canSort: true,
      hasEllipses: true,
      render: (row) => {
        return (
          <Tooltip title={row?.routeDetails?.name ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row?.routeDetails?.name || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.state?.isSelected,
      id: "state",
      label: "State",
      field: "state",
      canSort: true,
      render: (row, rowIndex) => {
        return (
          <Tooltip
            title={row.customer_branch?.state?.name ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.customer_branch?.state?.name ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.pdx_company?.isSelected,
      id: "pdx_company_name",
      label: "PDX Company",
      field: "pdx_company_name",
      canSort: true,
      render: (row, rowIndex) => {
        return (
          <Tooltip
            title={row.customer_branch?.pdxCompany?.value ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.customer_branch?.pdxCompany?.value ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },

    {
      isHidden: !state.dynamicColumns?.vehicle?.isSelected,
      id: "vehicle",
      label: "Vehicle",
      field: "vehicle",
      hasEllipses: true,
      canSort: true,
      render: (row) => {
        return (
          <Tooltip title={row.vehicleType?.value || ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.vehicleType?.value || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.icid?.isSelected,
      headerClassName: classes.icidFlagTitle,
      id: "contractor_first_name",
      label: "ICID/IC Name",
      field: "contractor_first_name",
      canSort: true,
      render: (row) => {
        const handleLabelClick = () => {
          if (!!row.individual_contractor?.icid) {
            fetchList(
              "IC",
              `?filter[limit]=1&filter[where][icid]=${row.individual_contractor?.icid}`
            );
            setState((prevState) => ({
              ...prevState,
              showIcDetails: true,
            }));
          }
        };
        let title = !!row.individual_contractor?.icid
          ? `
          IC Settlement Company Name: ${
            row.individual_contractor?.settlementCompany?.value || "-"
          }, 
         
          ICID: ${row.individual_contractor?.icid || "-"},
          IC Name: ${row.individual_contractor?.last_name || ""} ${
              row.individual_contractor?.first_name || ""
            },
        Contractor Type:${
          row.individual_contractor?.contractorType?.value || ""
        }
         `
          : "";
        if (
          row.individual_contractor_changed &&
          row.original_individual_contractor_id
        ) {
          const orginalIC = appData.icList.find(
            (contractor) =>
              contractor?.id === row.original_individual_contractor_id
          );
          if (orginalIC) {
            title += `, Original IC Name: ${orginalIC.last_name || ""} ${
              orginalIC.first_name || ""
            }`;
          }
        }
        const label = `${row.individual_contractor?.icid || ""}/${
          row.individual_contractor?.last_name || ""
        } ${row.individual_contractor?.first_name || ""}`;

        const flagLabel =
          (!!row.individual_contractor?.is_flagged &&
            !!row.individual_contractor?.is_cautious_flagged) ||
          !!row.individual_contractor?.is_flagged ? (
            <FlagIcon style={{ color: "red" }} />
          ) : row.individual_contractor?.is_cautious_flagged ? (
            <div className={classes.warningIconHeight}>
              <WarningIcon className={classes.warningIcon} />
              <div className={classes.exclamationMark}></div>
            </div>
          ) : (
            ""
          );

        const cautionFlaggedBy = userInfo(
          row?.individual_contractor?.ctn_flag_by
        );
        const cautionFlaggedReason = userFlagInfo(
          row?.individual_contractor?.ctn_flag_rsn
        );
        const flagTitles = `Flagged Reason: ${
          row.individual_contractor?.flagReason?.value ||
          cautionFlaggedReason?.value
        }, Flagged By: ${
          row.individual_contractor?.flaggedBy?.first_name ||
          cautionFlaggedBy?.first_name ||
          ""
        } ${
          row.individual_contractor?.flaggedBy?.last_name ||
          cautionFlaggedBy?.last_name ||
          ""
        }, USERNAME: ${
          row.individual_contractor?.flaggedBy?.username ||
          cautionFlaggedBy?.username ||
          ""
        }, ROLE: ${
          row.individual_contractor?.flaggedBy?.role ||
          cautionFlaggedBy?.role ||
          ""
        }, Flagged At: ${getDateString(
          row.individual_contractor?.flagged_at ||
            row?.individual_contractor?.ctn_flagged_at
        )}`;

        return (
          <Fragment>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <>
                <Tooltip title={flagTitles} placement="top-start">
                  <Typography noWrap variant="body2">
                    {flagLabel}
                  </Typography>
                </Tooltip>
              </>
              <>
                <Tooltip title={title ?? ""} placement="top-start">
                  <Typography
                    variant="body2"
                    noWrap
                    style={{
                      textDecoration:
                        !!row.individual_contractor?.icid && "underline",
                      cursor: !!row.individual_contractor?.icid && "pointer",
                    }}
                    onClick={
                      !!row.individual_contractor?.icid && handleLabelClick
                    }
                  >
                    {label || "-"}
                  </Typography>
                </Tooltip>
              </>
            </div>
          </Fragment>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.settlement_company?.isSelected,
      id: "settlement_co_name",
      label: "Settlement Company",
      field: "settlement_co_name",
      canSort: true,
      render: (row) => {
        return (
          <Tooltip
            title={row.settlementCompany?.value || ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.settlementCompany?.value || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_bill?.isSelected,
      headerClassName: classes.tableHeaderCell,
      id: "dont_bill",
      label: "Do Not Bill",
      field: "dont_bill",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.dont_bill ? (
                row.dont_bill_notes === null || row.dont_bill_notes === "" ? (
                  "This record will not be included in billing"
                ) : (
                  <>
                    This record will not be included in billing.
                    <br />
                    Reason : {row.dont_bill_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              className={!row.dont_bill && "no-events"}
              style={{ cursor: "default" }}
              color="primary"
              checked={row.dont_bill}
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_pay?.isSelected,
      headerClassName: classes.tableHeaderCell,
      id: "dont_pay",
      label: "Do Not Pay",
      field: "dont_pay",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.dont_pay ? (
                row.dont_pay_notes === null || row.dont_pay_notes === "" ? (
                  "This record will not be included in settlement"
                ) : (
                  <>
                    This record will not be included in settlement.
                    <br />
                    Reason : {row.dont_pay_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              className={!row.dont_pay && "no-events"}
              style={{ cursor: "default" }}
              color="primary"
              checked={row.dont_pay}
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_bill_miles?.isSelected,
      headerClassName: classes.tableHeaderCell,
      id: "dont_bill_miles",
      label: "Do Not Bill Extra Miles",
      field: "dont_bill_miles",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.dont_bill_miles ? (
                row?.dont_bill_miles_notes === null ||
                row?.dont_bill_miles_notes === "" ? (
                  "This record will not be included in billing."
                ) : (
                  <>
                    This record will not be included in billing.
                    <br />
                    Reason : {row.dont_bill_miles_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              className={!row.dont_bill_miles && "no-events"}
              style={{ cursor: "default" }}
              color="primary"
              checked={row.dont_bill_miles}
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_pay_miles?.isSelected,
      headerClassName: classes.tableHeaderCell,
      id: "dont_pay_miles",
      label: "Do Not Pay Extra Miles",
      field: "dont_pay_miles",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.dont_pay_miles ? (
                row?.dont_pay_miles_notes === null ||
                row?.dont_pay_miles_notes === "" ? (
                  "This record will not be included in settlement."
                ) : (
                  <>
                    This record will not be included in settlement.
                    <br />
                    Reason : {row.dont_pay_miles_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              className={!row.dont_pay_miles && "no-events"}
              style={{ cursor: "default" }}
              color="primary"
              checked={row.dont_pay_miles}
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_bill_tolls?.isSelected,
      headerClassName: classes.tableHeaderCell,
      id: "dont_bill_tolls",
      label: "Do Not Bill Toll",
      field: "dont_bill_tolls",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.dont_bill_tolls ? (
                row?.dont_bill_tolls_notes === null ||
                row?.dont_bill_tolls_notes === "" ? (
                  "This record will not be included in billing."
                ) : (
                  <>
                    This record will not be included in billing.
                    <br />
                    Reason : {row.dont_bill_tolls_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              className={!row.dont_bill_tolls && "no-events"}
              style={{ cursor: "default" }}
              color="primary"
              checked={row.dont_bill_tolls}
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.dont_pay_tolls?.isSelected,
      headerClassName: classes.tableHeaderCell,
      id: "dont_pay_tolls",
      label: "Do Not Pay Toll",
      field: "dont_pay_tolls",
      render: (row) => {
        return (
          <Tooltip
            title={
              !!row.dont_pay_tolls ? (
                row?.dont_pay_tolls_notes === null ||
                row?.dont_pay_tolls_notes === "" ? (
                  "This record will not be included in settlement."
                ) : (
                  <>
                    This record will not be included in settlement.
                    <br />
                    Reason : {row.dont_pay_tolls_notes}
                  </>
                )
              ) : (
                ""
              )
            }
            placement="top-start"
          >
            <Checkbox
              className={!row.dont_pay_tolls && "no-events"}
              style={{ cursor: "default" }}
              color="primary"
              checked={row.dont_pay_tolls}
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.bill_rate_type?.isSelected,
      headerClassName: classes.tableHeaderCell,
      id: "bill_rate_type",
      label: "Billing Rate Type",
      field: "bill_rate_type",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.bill_rate?.isSelected,
      id: "bill_rate",
      label: "Billing Rate",
      field: "bill_rate",
      startAdornment: "$",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.bill_quantity?.isSelected,
      id: "bill_quantity",
      label: "Billing Quantity",
      field: "bill_quantity",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.bill_amount?.isSelected,
      id: "bill_amount",
      label: "Billing Amount",
      field: "bill_amount",
      startAdornment: "$",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.bill_fuel_surcharge?.isSelected,
      headerClassName: classes.tableHeaderCell,
      id: "bill_fuel_surcharge",
      label: "Billing Fuel Surcharge%",
      field: "bill_fuel_surcharge",
      endAdornment: "%",
      canSort: true,
      render: (row) => {
        const label = row.bill_fuel_surcharge
          ? `${row.bill_fuel_surcharge || ""}%`
          : "-";

        return (
          <Tooltip title={label} placement="top-start">
            <Typography variant="body2" noWrap>
              {label}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.bill_fuel_amount?.isSelected,
      headerClassName: classes.tableHeaderCell,
      id: "bill_fuel_amount",
      label: "Billing Fuel Amount",
      field: "bill_fuel_amount",
      startAdornment: "$",
      hasEllipses: true,
      canSort: true,
      render: (row) => (
        <Tooltip title={row.bill_fuel_amount} placement="top-start">
          <Typography variant="body2">
            {`$${(+row.bill_fuel_amount).toFixed(2)}`}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.bill_toll_amount?.isSelected,
      id: "bill_toll_amount",
      label: "Billing Toll Amount",
      field: "bill_toll_amount",
      startAdornment: "$",
      hasEllipses: true,
      canSort: true,
    },

    {
      isHidden: !state.dynamicColumns?.bill_extra_miles?.isSelected,
      id: "bill_extra_miles",
      label: "Billing Extra Miles",
      field: "bill_extra_miles",
      hasEllipses: true,
      canSort: true,
    },

    {
      isHidden: !state.dynamicColumns?.bill_rate_per_extra_mile?.isSelected,
      headerClassName: classes.extraMilesheader,
      id: "bill_rate_per_extra_mile",
      label: "Billing Rate Per Extra Mile",
      field: "bill_rate_per_extra_mile",
      startAdornment: "$",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.bill_extra_mile_amount?.isSelected,
      headerClassName: classes.extraMilesheader,
      id: "bill_extra_mile_amount",
      label: "Billing Extra Miles Amount",
      field: "bill_extra_mile_amount",
      startAdornment: "$",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.pay_rate_type?.isSelected,
      headerClassName: classes.tableHeaderCell,
      id: "pay_rate_type",
      label: "Pay Rate Type",
      field: "pay_rate_type",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.pay_rate?.isSelected,
      id: "pay_rate",
      label: "Pay Rate",
      field: "pay_rate",
      startAdornment: "$",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.pay_quantity?.isSelected,
      id: "pay_quantity",
      label: "Pay Quantity",
      field: "pay_quantity",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.pay_amount?.isSelected,
      id: "pay_amount",
      label: "Pay Amount",
      field: "pay_amount",
      startAdornment: "$",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.pay_fuel_surcharge?.isSelected,
      headerClassName: classes.tableHeaderCell,
      id: "pay_fuel_surcharge",
      label: "Pay Fuel Surcharge%",
      field: "pay_fuel_surcharge",
      endAdornment: "%",
      hasEllipses: true,
      canSort: true,
      render: (row) => {
        const label = row.pay_fuel_surcharge
          ? `${row.pay_fuel_surcharge || ""}%`
          : "-";

        return (
          <Tooltip title={label} placement="top-start">
            <Typography variant="body2" noWrap>
              {label}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.pay_fuel_amount?.isSelected,
      headerClassName: classes.tableHeaderCell,
      id: "pay_fuel_amount",
      label: "Pay Fuel Amount",
      field: "pay_fuel_amount",
      startAdornment: "$",
      hasEllipses: true,
      canSort: true,
      render: (row) => (
        <Tooltip title={row.pay_fuel_amount} placement="top-start">
          <Typography variant="body2">
            {`$${(+row.pay_fuel_amount).toFixed(2)}`}
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.pay_toll_amount?.isSelected,
      id: "pay_toll_amount",
      label: "Pay Toll Amount",
      field: "pay_toll_amount",
      startAdornment: "$",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.pay_extra_miles?.isSelected,
      id: "pay_extra_miles",
      label: "Pay Extra Miles",
      field: "pay_extra_miles",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.pay_rate_per_extra_mile?.isSelected,
      headerClassName: classes.extraMilesheader,
      id: "pay_rate_per_extra_mile",
      label: "Pay Rate Per Extra Mile",
      field: "pay_rate_per_extra_mile",
      startAdornment: "$",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.pay_extra_mile_amount?.isSelected,
      headerClassName: classes.extraMilesheader,
      id: "pay_extra_mile_amount",
      label: "Pay Extra Miles Amount",
      field: "pay_extra_mile_amount",
      startAdornment: "$",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.reimbursement?.isSelected,
      id: "reimbursement",
      label: "Reimbursement",
      field: "reimbursement",
      startAdornment: "$",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.deduction?.isSelected,
      id: "deduction",
      label: "Deduction",
      field: "deduction",
      startAdornment: "$",
      hasEllipses: true,
      canSort: true,
    },
    {
      isHidden: !state.dynamicColumns?.deduction_reason?.isSelected,
      id: "deduction_reason",
      label: "Deduction Reason",
      field: "deduction_reason",
      hasEllipses: true,
      canSort: true,
      render: (row) => {
        const deductionReason = state.deductionReasonList?.filter(
          (reason) => reason.id == row.deduction_reason
        );
        return (
          <Tooltip
            title={deductionReason[0]?.concatName || ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {deductionReason[0]?.concatName || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.kam?.isSelected,
      id: "kam_first_name",
      label: "KAM",
      field: "kam_first_name",
      canSort: true,
      render: (row) => {
        const tooltip = `${row.kamUser?.first_name || ""} ${
          row.kamUser?.last_name || ""
        }-( ${row.kamUser?.username || ""})`;
        const title = `${row.kamUser?.first_name || ""} ${
          row.kamUser?.last_name || ""
        }`;
        return (
          <Tooltip title={tooltip} placement="top-start">
            <Typography variant="body2" noWrap>
              {title}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.office?.isSelected,
      id: "aa_first_name",
      label: "Office",
      field: "aa_first_name",
      canSort: true,
      render: (row) => {
        const tooltip = `${row.aaUser?.first_name || ""} ${
          row.aaUser?.last_name || ""
        }-(${row.aaUser?.username || ""})`;
        const title = `${row.aaUser?.first_name || ""} ${
          row.aaUser?.last_name || ""
        }`;
        return (
          <Tooltip title={tooltip} placement="top-start">
            <Typography variant="body2" noWrap>
              {title}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.manager?.isSelected,
      id: "mgr_first_name",
      label: "Manager",
      field: "mgr_first_name",
      canSort: true,
      render: (row) => {
        const tooltip = `${row.mgrUser?.first_name || ""} ${
          row.mgrUser?.last_name || ""
        }-(${row.mgrUser?.username || ""})`;
        const title = `${row.mgrUser?.first_name || ""} ${
          row.mgrUser?.last_name || ""
        }`;
        return (
          <Tooltip title={tooltip} placement="top-start">
            <Typography variant="body2" noWrap>
              {title}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.profit_percentage?.isSelected,
      id: "profit_percentage",
      label: "Profit Percentage",
      field: "profit_percentage",
      endAdornment: "%",
      hasEllipses: true,
      canSort: true,
      render: (row) => (
        <Tooltip title={row.profit_percentage} placement="top-start">
          <Typography
            variant="body2"
            noWrap
            className={clsx({ "color-error": row.profit_percentage < 0 })}
          >
            {(+row.profit_percentage || 0).toFixed(2)}%
          </Typography>
        </Tooltip>
      ),
    },
    {
      isHidden: !state.dynamicColumns?.change_notes?.isSelected,
      id: "change_notes",
      label: "AA/MGR Notes",
      field: "change_notes",
      hasEllipses: true,
      canSort: true,
      render: (row) => {
        return (
          <Tooltip title={row.change_notes ?? ""} placement="top-start">
            <Typography variant="body2" noWrap style={{ whiteSpace: "pre" }}>
              {row.change_notes ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.kam_notes?.isSelected,
      id: "kam_notes",
      label: "KAM Notes",
      field: "kam_notes",
      hasEllipses: true,
      canSort: true,
      render: (row) => {
        return (
          <Tooltip
            title={row.kam_notes ?? ""}
            placement="top-start"
            // classes={{ tooltip: classes.customSpace }}
          >
            <Typography variant="body2" noWrap style={{ whiteSpace: "pre" }}>
              {row.kam_notes || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.review_notes?.isSelected,
      id: "review_notes",
      label: "Review Notes",
      field: "review_notes",
      hasEllipses: true,
      canSort: true,
      render: (row) => {
        const splitNotes = row.review_notes?.split("||");

        const finalNotes = splitNotes?.map((note) => {
          let str = note;
          if (!str) {
            return "";
          }
          //const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          const startIndexOfDate = str?.indexOf("-");
          const lastIndexOfDate = str?.indexOf(": ");

          const formattedDate = new Date(
            str?.substring(startIndexOfDate + 1, lastIndexOfDate) + " GMT"
          )?.toLocaleString();

          const str1 =
            str.substring(0, startIndexOfDate) +
            "-" +
            formattedDate +
            str.substring(lastIndexOfDate);
          return str1;
        });

        const title = (
          <div
            dangerouslySetInnerHTML={{
              __html: (finalNotes || []).join("<br>"),
            }}
          />
        );

        return (
          <Tooltip
            title={title || "-"}
            placement="top-start"
            // classes={{ tooltip: classes.customSpace }}
          >
            <Typography variant="body2" noWrap style={{ whiteSpace: "pre" }}>
              {finalNotes}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.approved_by?.isSelected,
      id: "verified_by_name",
      label: "Approved/Rejected By",
      field: "verified_by_name",
      canSort: true,
      render: (row) =>
        row.verifiedBy === null ? (
          <Typography variant="body2" noWrap>
            -
          </Typography>
        ) : (
          <Tooltip
            title={
              row.verifiedBy.first_name +
              " " +
              row.verifiedBy.last_name +
              " (" +
              row.verifiedBy.username +
              ")"
            }
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.verifiedBy.first_name + " " + row.verifiedBy.last_name}
            </Typography>
          </Tooltip>
        ),
    },
    {
      isHidden: !state.dynamicColumns?.review_reason?.isSelected,
      id: "review_reason",
      label: "Review Reason",
      field: "review_reason",
      hasEllipses: true,
      canSort: true,
      render: (row) => {
        return (
          <Tooltip title={row.review_reason ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row.review_reason || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.submitted_by?.isSelected,
      id: "DS_submitter_name",
      label: "Submitted By",
      field: "DS_submitter_name",
      canSort: true,
      render: (row) => {
        const kam = appData.users.find(
          (ele) => ele?.id === row.daily_schedule?.submitted_by || null
        );
        let submittedBy = null;
        if (kam) {
          submittedBy = `${kam.first_name || ""} ${kam.last_name || ""}-${
            kam.email || ""
          }-(${kam.username || ""})`;
        }
        return (
          <Tooltip title={submittedBy ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {submittedBy || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "submitted_at",
      label: "DS Submitted At",
      isHidden: !state.dynamicColumns?.submitted_at?.isSelected,
      fieldName: "submitted_at",
      hasEllipses: true,
      canSort: true,
      render: (row) => {
        const submitAt = `${
          !!row.daily_schedule?.submitted_at
            ? `${getDateString(
                row.daily_schedule.submitted_at
              )} ${getFormattedTime(new Date(row.daily_schedule.submitted_at))}`
            : "-"
        }`;
        return (
          <Tooltip title={submitAt || ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {submitAt}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.updatedBy?.isSelected,
      id: "updatedBy",
      label: "Last Updated By",
      field: "updatedBy",
      hasEllipses: true,
      canSort: true,
      render: (row) => {
        const updatedBy = row.updatedBy
          ? `${row.updatedBy.first_name} ${row.updatedBy.last_name || ""} - (${
              row.updatedBy.username
            })`
          : "";
        return (
          <Tooltip title={updatedBy ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {updatedBy || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.updated_at?.isSelected,
      id: "updated_at",
      label: "Last Updated At",
      fieldName: "updated_at",
      hasEllipses: true,
      canSort: true,
      render: (row) => {
        const updatedAt = !!row.updated_at
          ? `${getDateString(row.updated_at)} ${getFormattedTime(
              new Date(row.updated_at)
            )}`
          : "";
        return (
          <Tooltip title={updatedAt ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {updatedAt || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  if (!isRoleVIEW) {
    columnConfig.splice(0, 0, {
      id: "col-edit",
      noResize: true,
      render: (row) => moreActions(row),
    });
  }

  const kamList = appDataList.customers
    .find((customer) => customer.id === state.customer?.id)
    ?.customer_branches.map((branch) => branch.kamUser);

  // const filteredCustomerList = () => {
  //   let newCustomerList = new Map();
  //   let customerList = [];

  //   if (!!state.completeEntries.length) {
  //     customerList = state.completeEntries.map(
  //       (entry) => entry.customer_branch?.customer
  //     );
  //     (customerList || []).forEach((customer) =>
  //       newCustomerList.set(customer.id, customer)
  //     );
  //     newCustomerList = [...newCustomerList.values()];
  //     return newCustomerList;
  //   } else {
  //     return customerList;
  //   }
  // };

  return (
    <div id="numbers-page-wrapper">
      <Grid container className="mr-5">
        <Grid item xs={12} className={classes.fixedHeader}>
          <div className="mb-2">
            <Typography variant="h4" color="primary" className="ml-2">
              Billing & Settlements
              <Button
                startIcon={<ArrowBackIcon />}
                style={{ float: "right" }}
                className="mt-1 mr-2"
                variant="contained"
                color="primary"
                onClick={() => history.push("/home")}
              >
                Back To Home
              </Button>
            </Typography>
            <Typography variant="body2" style={{ marginLeft: "10px" }}>
              {!isRoleVIEW
                ? " View/Edit Billing information."
                : "View Billing information."}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} className="ml-1">
          <div
            className={clsx({
              "d-flex f-align-center f-wrap f-justify-between":
                !appData.isTabletView,
            })}
          >
            <div
              className={clsx({
                "d-flex f-align-center mb-2": !appData.isTabletView,
              })}
            >
              <FormControl>
                <RadioGroup
                  row
                  value={state.dateFilterType}
                  onChange={(evt) => {
                    const { value } = evt.target;
                    const lastWeekDate = new Date();
                    const lastWeekDay = lastWeekDate.getDate() - 6;
                    lastWeekDate.setDate(lastWeekDay);
                    setState((prevState) => ({
                      ...prevState,
                      dateFilterType: value,
                      areAllSelected: false,
                      selectedRows: [],
                      filters: {
                        ...prevState.filters,
                        startDate: [
                          {
                            field: "schedule_date",
                            type: value === "single" ? "=" : "gte",
                            value: getDateString(
                              value === "single" ? new Date() : lastWeekDate
                            ),
                          },
                        ],
                        endDate:
                          value === "single"
                            ? []
                            : [
                                {
                                  field: "schedule_date",
                                  type: "lte",
                                  value: getDateString(new Date()),
                                },
                              ],
                      },
                      pageNumber: defaultState.pageNumber,
                    }));
                    setPageConfig(PAGE_KEYS.BILLING, {
                      dateFilterType: value,
                      startDateFilter: getDateString(
                        value === "single" ? new Date() : lastWeekDate
                      ),
                      endDateFilter: getDateString(new Date()),
                    });
                    if (gridHelper) {
                      gridHelper.resetSelection();
                      gridHelper.resetAllSelection();
                    }
                  }}
                >
                  <FormControlLabel
                    value="single"
                    control={<Radio size="small" color="primary" />}
                    label="Specific date"
                  />
                  <FormControlLabel
                    value="multiple"
                    control={<Radio size="small" color="primary" />}
                    label="Date range"
                  />
                </RadioGroup>
              </FormControl>
              <div className="d-flex mt-3">
                <Datepicker
                  mask
                  //preventClear={!state.filters.endDate[0]?.value}
                  preventClear
                  selected={
                    state.filters.startDate.length &&
                    state.filters.startDate[0]?.value
                  }
                  label={
                    state.dateFilterType === "single" ? "Date" : "Start date"
                  }
                  classes={{
                    input: {
                      root: clsx("mr-4", classes.datepickerWrapper),
                    },
                  }}
                  maxDate={
                    state.filters.endDate[0]?.value
                      ? new Date(state.filters.endDate[0]?.value)
                      : new Date()
                  }
                  onChange={(startDate) => {
                    setState((prevState) => ({
                      ...prevState,
                      areAllSelected: false,
                      selectedRows: [],
                      filters: {
                        ...prevState.filters,
                        startDate: [
                          {
                            field: "schedule_date",
                            type:
                              state.dateFilterType === "single" ? "=" : "gte",
                            value: getDateString(startDate),
                          },
                        ],
                        daily_schedule_id: [],
                      },
                    }));
                    setPageConfig(PAGE_KEYS.BILLING, {
                      startDateFilter: getDateString(startDate),
                    });
                    if (gridHelper) {
                      gridHelper.resetSelection();
                      gridHelper.resetAllSelection();
                    }
                  }}
                />
                {state.dateFilterType === "multiple" && (
                  <Datepicker
                    mask
                    preventClear
                    selected={
                      state.filters?.endDate.length &&
                      (state.filters?.endDate[0]?.value ||
                        state.filters?.endDate)
                    }
                    label="End date"
                    classes={{
                      input: {
                        root: clsx("mr-4", classes.datepickerWrapper),
                      },
                    }}
                    maxDate={new Date()}
                    minDate={new Date(state.filters.startDate[0]?.value)}
                    onChange={(endDate) => {
                      setState((prevState) => ({
                        ...prevState,
                        areAllSelected: false,
                        selectedRows: [],
                        filters: {
                          ...prevState.filters,
                          endDate: [
                            {
                              field: "schedule_date",
                              type: "lte",
                              value: getDateString(endDate),
                              daily_schedule_id: [],
                            },
                          ],
                        },
                      }));
                      setPageConfig(PAGE_KEYS.BILLING, {
                        endDateFilter: getDateString(endDate),
                      });
                      if (gridHelper) {
                        gridHelper.resetSelection();
                        gridHelper.resetAllSelection();
                      }
                    }}
                  />
                )}
              </div>
            </div>
            <div
              className={clsx("d-flex", {
                "mt-4 f-justify-start f-wrap": appData.isTabletView,
              })}
            >
              <Autocomplete
                size="small"
                disableClearable
                fullWidth
                multiple
                className={classes.filterFields}
                options={appDataList.officeUsers}
                defaultValue={
                  isRoleAA && state.aaUser?.length > 0
                    ? state.aaUser
                    : isRoleAA && state.aaUser
                    ? [state.aaUser]
                    : undefined
                }
                value={!isRoleAA ? state.aaUser || [] : undefined}
                getOptionLabel={(option) =>
                  option.name ||
                  `${option.first_name || ""} ${option.last_name || ""}-${
                    option.email || ""
                  }-(${option.username || ""})`
                }
                renderInput={(params) =>
                  state.isFetchingList.users ? (
                    <Skeleton variant="rect" width="100%" height="100%" />
                  ) : (
                    <TextField {...params} label="Office" variant="outlined" />
                  )
                }
                onChange={(evt, value) => {
                  if (value.length === 0) {
                    setPageConfig(PAGE_KEYS.BILLING, {
                      officeFilter: null,
                    });
                    return setState((prevState) => ({
                      ...prevState,
                      aaUser: value,
                      selectedRows: [],
                      filters: {
                        ...prevState.filters,
                        aaUser: [{ field: "aa_id", type: "=", value: null }],
                        daily_schedule_id: [],
                      },
                    }));
                  }
                  const id = value?.map((item) => item.id);

                  setState((prevState) => ({
                    ...prevState,
                    aaUser: value,
                    filters: {
                      ...prevState.filters,
                      aaUser: [{ field: "aa_id", type: "=", value: id }],
                    },
                  }));
                  setPageConfig(PAGE_KEYS.BILLING, {
                    officeFilter: {
                      value,
                      aaUser: [{ field: "aa_id", type: "=", value: id }],
                    },
                  });
                  gridHelper && gridHelper.resetSelection();
                }}
              />
              <Autocomplete
                size="small"
                className={classes.filterFields}
                options={appDataList.customers}
                value={state.customer}
                getOptionLabel={(option) => `${option.name || ""}`}
                renderInput={(params) =>
                  state.isFetchingList.users ? (
                    <Skeleton variant="rect" width="100%" height="100%" />
                  ) : (
                    <TextField
                      {...params}
                      label="Customer"
                      variant="outlined"
                    />
                  )
                }
                onChange={(evt, value) => {
                  setState((prevState) => ({
                    ...prevState,
                    customer: value,
                    selectedRows: [],
                    filters: {
                      ...prevState.filters,
                      customer: [
                        {
                          field: "customer_name",
                          type: "=",
                          value: value?.name,
                        },
                      ],
                      daily_schedule_id: [],
                    },
                  }));
                  setPageConfig(PAGE_KEYS.BILLING, {
                    customerFilter: {
                      value,
                      customer: [
                        {
                          field: "customer_name",
                          type: "=",
                          value: value?.name,
                        },
                      ],
                    },
                  });
                  gridHelper && gridHelper.resetSelection();
                }}
              />
              <Autocomplete
                className={classes.filterFields}
                size="small"
                fullWidth
                value={state.selectedLocation || []}
                multiple
                options={locationOPtions}
                getOptionLabel={(option) => option.label}
                renderInput={(params) =>
                  state.isFetchingList.users ? (
                    <Skeleton variant="rect" width="100%" height="36px" />
                  ) : (
                    <TextField
                      {...params}
                      label="Location"
                      variant="outlined"
                    />
                  )
                }
                getOptionSelected={(option, value) =>
                  option.label === value.label
                }
                onChange={(evt, value) => {
                  setState((prevState) => ({
                    ...prevState,
                    selectedLocation: value,
                    filters: {
                      ...prevState.filters,
                      locations: [
                        {
                          field: "location",
                          type: "=",
                          value: value?.map((item) => item.value),
                        },
                      ],
                    },
                  }));
                  setPageConfig(PAGE_KEYS.BILLING, {
                    locationFilter: {
                      value,
                      locations: [
                        {
                          field: "location",
                          type: "=",
                          value: value?.map((item) => item.value),
                        },
                      ],
                    },
                  });
                  gridHelper && gridHelper.resetSelection();
                }}
              />
              <Autocomplete
                size="small"
                className={classes.filterFields}
                options={appDataList.icList}
                value={state.IC}
                // options={
                //   !state.customer
                //     ? appDataList.IC
                //     : [
                //         ...new Set(
                //           state.entries.map((item) => item.individual_contractor)
                //         ),
                //       ]
                // }
                //value={appDataList.IC.find((kam) => kam.id === data.kam_id)}
                getOptionLabel={(item) =>
                  `${item.icid || ""} - ${item.last_name || ""} ${
                    item.first_name || ""
                  }${
                    state.vehicleTypeList.some(
                      (ele) => ele.id === item.default_vehicle_type
                    )
                      ? `-(${
                          state.vehicleTypeList.find(
                            (ele) => ele.id === item.default_vehicle_type
                          ).value
                        })`
                      : ""
                  }`
                }
                renderInput={(params) =>
                  state.isFetchingList.users ? (
                    <Skeleton variant="rect" width="100%" height="100%" />
                  ) : (
                    <TextField {...params} label="IC" variant="outlined" />
                  )
                }
                onChange={(evt, value) => {
                  setState((prevState) => ({
                    ...prevState,
                    IC: value,
                    selectedRows: [],
                    filters: {
                      ...prevState.filters,
                      IC: [
                        {
                          field: "individual_contractor_id",
                          type: "=",
                          value: value?.id,
                        },
                      ],
                      daily_schedule_id: [],
                    },
                  }));
                  setPageConfig(PAGE_KEYS.BILLING, {
                    ICFilter: {
                      value,
                      IC: [
                        {
                          field: "individual_contractor_id",
                          type: "=",
                          value: value?.id,
                        },
                      ],
                    },
                  });
                  gridHelper && gridHelper.resetSelection();
                }}
              />
              <Autocomplete
                size="small"
                className={classes.filterFields}
                // options={!state.customer ? appDataList.kamUsers : kamList}
                options={appDataList.kamUsers.filter((kam) => kam.is_active)}
                value={state.kam}
                getOptionLabel={(option) =>
                  option.name ||
                  `${option.first_name || ""} ${option.last_name || ""}-${
                    option.email || ""
                  }-(${option.username || ""})`
                }
                // renderOption={({
                //   first_name,
                //   last_name,
                //   email,
                //   is_active,
                //   username,
                // }) => (
                //   <Typography
                //     variant="body1"
                //     className={clsx({
                //       "color-text-disabled": !is_active,
                //     })}
                //   >{`${first_name || " "} ${
                //     last_name || ""
                //   }-${email}-(${username})`}</Typography>
                // )}
                renderInput={(params) =>
                  state.isFetchingList.users ? (
                    <Skeleton variant="rect" width="100%" height="100%" />
                  ) : (
                    <TextField {...params} label="KAM" variant="outlined" />
                  )
                }
                onChange={(evt, value) => {
                  setState((prevState) => ({
                    ...prevState,
                    kam: value,
                    selectedRows: [],
                    filters: {
                      ...prevState.filters,
                      kam: [{ field: "kam_id", type: "=", value: value?.id }],
                      daily_schedule_id: [],
                    },
                  }));
                  setPageConfig(PAGE_KEYS.BILLING, {
                    kamFilter: {
                      value,
                      kam: [{ field: "kam_id", type: "=", value: value?.id }],
                    },
                  });
                  gridHelper && gridHelper.resetSelection();
                }}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Paper
            elevation={2}
            className={clsx("p-4 mt-10", classes.paperSpacing)}
          >
            <div
              className={clsx(
                "d-flex f-align-center f-justify-between p-2 pl-4 pr-4",
                classes.actionsWrapper
              )}
            >
              <div>
                {state.isFetchingList.users ||
                state.isFetchingList.customers ||
                state.isFetchingList.IC ||
                state.isFetchingList.settlementCompany ? (
                  <Skeleton variant="rect" width={90} height={35} />
                ) : (
                  <>
                    {!isRoleVIEW && (
                      <Button
                        startIcon={<PlaylistAddIcon />}
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          getPzriceMatrix();
                          setState((prevState) => ({
                            ...prevState,
                            isNew: true,
                          }));
                        }}
                      >
                        New
                      </Button>
                    )}

                    <FormControlLabel
                      label="Approved"
                      className="ml-2"
                      control={
                        <Checkbox
                          color="primary"
                          checked={state.approved}
                          value={state.approved}
                          onChange={(evt) => {
                            setState((prevState) => ({
                              ...prevState,
                              approved: evt.target.checked,
                              selectedRows: [],
                            }));
                            setPageConfig(PAGE_KEYS.BILLING, {
                              approved: evt.target.checked,
                            });
                            gridHelper && gridHelper.resetSelection();
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Unapproved"
                      control={
                        <Checkbox
                          value={state.unapproved}
                          color="primary"
                          checked={state.unapproved}
                          onChange={(evt) => {
                            setState((prevState) => ({
                              ...prevState,
                              unapproved: evt.target.checked,
                              selectedRows: [],
                            }));
                            setPageConfig(PAGE_KEYS.BILLING, {
                              unapproved: evt.target.checked,
                            });
                            gridHelper && gridHelper.resetSelection();
                          }}
                        />
                      }
                    />
                  </>
                )}
                {(isRoleADMIN || isRoleAA) &&
                  !!state.selectedRows.length &&
                  !state.areAllSelected && (
                    <Button
                      startIcon={<DeleteForeverOutlinedIcon color="error" />}
                      classes={{
                        root: "border-error ml-4",
                        label: "color-error",
                      }}
                      variant="outlined"
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          deletingEntryId: state.selectedRows.join(","),
                        }))
                      }
                    >
                      Delete
                    </Button>
                  )}

                {(isRoleADMIN || isRoleMGR) &&
                  (state.areAllSelected || !!state.selectedRows.length) && (
                    <>
                      <Button
                        className="ml-4"
                        startIcon={<DoneAllIcon />}
                        color="primary"
                        variant="outlined"
                        //onClick={handleApprove}
                        onClick={() => {
                          setState((prevState) => ({
                            ...prevState,
                            showConfirmationPopup: true,
                          }));
                        }}
                        disabled={
                          !state.areAllSelected &&
                          (state.isApproved || !state.shouldApproveEnable)
                        }
                      >
                        {!state.areAllSelected ? "Approve" : "Approve All"}
                        {/* {state.isApproved && (
                    <CircularProgress
                      size={24}
                      className="p-absolute progress-btn"
                    />
                  )} */}
                      </Button>
                      {(isRoleADMIN || isRoleMGR) && !state.areAllSelected && (
                        <Button
                          className="ml-4"
                          startIcon={<DoneAllIcon />}
                          color="primary"
                          variant="outlined"
                          // onClick={handleUnapprove}
                          onClick={() => {
                            setState((prevState) => ({
                              ...prevState,
                              showUnapproveConfirmationPopup: true,
                            }));
                          }}
                          disabled={!state.shouldUnapproveEnable}
                        >
                          Unapprove
                          {/* {state.isApproved && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )} */}
                        </Button>
                      )}
                    </>
                  )}
              </div>
              <div className="d-flex f-justify-center">
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    className="ml-2 mr-2 mt-2"
                    startIcon={<CloudDownloadIcon />}
                    disabled={state.isDownloadLoading || !state.totalEntries}
                    onClick={() => {
                      handleDownload(
                        state.search,
                        state.filters,
                        state.order,
                        state.orderBy,
                        state.searchColumns,
                        state.pageFilters
                      );
                    }}
                  >
                    Download
                    {state.isDownloadLoading && (
                      <CircularProgress
                        size={24}
                        className={classes.progressBtn}
                      />
                    )}
                  </Button>
                </div>
                <Dropdown
                  classes={{
                    paper: classes.tunePaper,
                  }}
                  disablePortal={false}
                  remainOpen
                  isMultiSelect
                  placement="bottom-end"
                  options={Object.keys(state.dynamicColumns).map(
                    (key) => state.dynamicColumns[key]
                  )}
                  customToggle={({ anchorRef, onClick }) => (
                    <Tooltip title="Show/Hide column(s)" placement="top-start">
                      <IconButton
                        color="primary"
                        ref={anchorRef}
                        onClick={onClick}
                      >
                        <TuneIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  onChange={(options) => {
                    const isSelectAll = options.some(
                      (item) => item.value === "select_all"
                    );
                    const isCurrentSelectAll =
                      state.dynamicColumns?.select_all?.isSelected;

                    const isClickedOnSelectAll =
                      isSelectAll !== isCurrentSelectAll;

                    const isAllOptionSelected =
                      Object.keys(state.dynamicColumns).length - 1 ===
                      options.filter((item) => item.value !== "select_all")
                        .length;
                    const updatedDynamicColumns = Object.keys(
                      state.dynamicColumns
                    ).reduce((acc, key) => {
                      const isSelected = isClickedOnSelectAll
                        ? isSelectAll
                        : key === "select_all"
                        ? isAllOptionSelected
                        : !!options.some((item) => item.value === key);
                      return {
                        ...acc,
                        [key]: {
                          ...state.dynamicColumns[key],
                          isSelected,
                        },
                      };
                    }, {});
                    setPageConfig(PAGE_KEYS.BILLING, {
                      dynamicColumns: updatedDynamicColumns,
                    });
                    setState((prevState) => ({
                      ...prevState,
                      dynamicColumns: {
                        ...prevState.dynamicColumns,
                        ...updatedDynamicColumns,
                      },
                    }));
                  }}
                />
                <Tooltip placement="top-start" title="Filter">
                  <IconButton
                    color="primary"
                    disabled={state.isFetching}
                    className={clsx({
                      "bg-primary": !!state.pageFilters?.length,
                    })}
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        isFiltering: true,
                      }));
                    }}
                  >
                    <FilterListIcon
                      className={clsx({
                        "color-white": !!state.pageFilters?.length,
                      })}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip placement="top-end" title="Reset All Filters">
                  <IconButton
                    // disabled={
                    //   pageConfig === undefined ||
                    //   pageConfig === null ||
                    //   (Object.keys?.(pageConfig)?.length === 1 &&
                    //     pageConfig?.is_verified.length === 0)
                    // }
                    color="primary"
                    onClick={() => {
                      updatePageConfig("billing");
                      window.location.reload();
                    }}
                  >
                    <RotateLeftIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <DataGrid
              columns={columnConfig}
              rows={state.entries.map((entry) => ({
                ...entry,
                preventSelection:
                  entry.status === "UNDER REVIEW" ||
                  entry.status === "AWAITING REVIEW" ||
                  entry.status === "DS REJECTED" ||
                  state.areAllSelected,
                classes: {
                  contractor_first_name:
                    entry.individual_contractor_changed &&
                    classes.icidTitleColor,
                },
                className: clsx({
                  //[classes.highlightUpdate]: entry?.individual_contractor_changed,
                  [classes.highlightApproved]: entry.is_verified,
                  [classes.highlightAwaitingReview]:
                    entry.status === "AWAITING REVIEW" ||
                    entry.status === "DS REJECTED",
                  [classes.highlightAdminRejected]:
                    entry.status === "ADMIN REJECTED" ||
                    entry.status === "MGR REJECTED",
                  [classes.notification]:
                    state.testId === entry?.daily_schedule_id,
                }),
              }))}
              actionBarConfig={null}
              isLoading={
                state.isFetching || state.isFetchingList.approveLoading
              }
              hasSelection={
                !isRoleVIEW && (isRoleAA || isRoleADMIN || isRoleMGR)
                  ? true
                  : false
              }
              onReady={(instance) => (gridHelper = instance)}
              boxborder={state.clickedRowId}
              selectedRef={selectedRef}
              totalRows={state.totalEntries}
              pageSize={state.pageSize}
              pageNumber={state.pageNumber}
              order={state.order}
              orderBy={state.orderBy}
              onPageNumberChange={handlePageNumberChange}
              onPageSizeChange={handlePageSizeChange}
              onSelectionChange={handleSelectionChange}
              onSortChange={handleSortChange}
              onSelectAll={handleAllSelectionChange}
              areAllSelected={state.areAllSelected}
              hasSelectAll={isRoleADMIN || isRoleMGR ? true : false}
              rowEvents={[
                {
                  type: "onDoubleClick",
                  handler: (row) => {
                    if (
                      row.status === "UNDER REVIEW" ||
                      row.status === "AWAITING REVIEW" ||
                      row.status === "DS REJECTED" ||
                      isRoleVIEW
                    ) {
                      return false;
                    }
                    getPzriceMatrix();
                    setState((prevState) => ({
                      ...prevState,
                      rowBeingEdited: row,
                      clickedRowId: row.id,
                    }));
                    setPageConfig(PAGE_KEYS.BILLING, {
                      clickedRowId: row.id,
                    });
                  },
                },
                {
                  type: "onClick",
                  handler: (row) => {
                    setState((prevState) => ({
                      ...prevState,
                      clickedRowId: row.id,
                    }));
                    setPageConfig(PAGE_KEYS.BILLING, {
                      clickedRowId: row.id,
                    });
                  },
                },
              ]}
            />
          </Paper>
        </Grid>
        {state.deletingEntryId && (
          <ActionDialog
            classes={{
              confirm: "bg-danger",
              paper: classes.paperSize,
            }}
            open={!!state.deletingEntryId}
            contentText="Are you sure you want to delete the entry?"
            onConfirm={() => handleDelete(state.deletingEntryId)}
            onCancel={() =>
              setState((prevState) => ({ ...prevState, deletingEntryId: null }))
            }
            isConfirmDisabled={state.isLoading}
            positiveActionLabel={
              <>
                Delete
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}
        {(state.isNew || state.rowBeingEdited) && (
          <Form
            isTabletView={appData.isTabletView}
            isPriceMatrixFetched={state.isPriceMatrixFetched}
            vehicleTypeList={state.vehicleTypeList}
            deductionReasonList={state.deductionReasonList}
            priceMatrix={state.priceMatrix}
            appData={appData}
            cautionFlagSubmitReasonList={state.cautionFlagSubmitReasonList}
            gridHelper={gridHelper}
            open={state.isNew || state.rowBeingEdited}
            kamUsers={appDataList.kamUsers}
            scheduleDate={state.filters.startDate[0]?.value}
            isRoleMGR={isRoleMGR}
            officeUsers={appDataList.officeUsers.filter(
              (officeUser) => officeUser.is_active
            )}
            managerUsers={appDataList.managerUsers.filter(
              (managerUser) => managerUser.is_active
            )}
            adminUsers={appDataList.adminUsers.filter(
              (adminUser) => adminUser.is_active
            )}
            settlementCompanyList={state.settlementCompanyList}
            customerList={appDataList.customers}
            contractorList={appDataList.icList}
            entry={state.rowBeingEdited}
            formAlertFields={state.formAlertFields}
            isNew={state.isNew}
            calculateProfitPercentage={calculateProfitPercentage}
            onClose={(isSubmitted = false, isUnderReview = false) => {
              setState((prevState) => ({
                ...prevState,
                rowBeingEdited: null,
                isNew: false,
                formAlertFields: {},
                isUnderReview,
              }));
              if (typeof isSubmitted === "boolean" && isSubmitted) {
                fetchEntries(
                  state.search,
                  state.filters,
                  state.pageSize,
                  state.pageNumber,
                  state.order,
                  state.orderBy,
                  state.searchColumns,
                  state.pageFilters
                );
                fetchList();
              }
            }}
            showFieldAlert={showFieldAlert}
          />
        )}
        {state.fieldAlert.show && (
          <DynamicForm
            open={state.fieldAlert.show}
            title="Update Entry(s)"
            subHeading={state.fieldAlert.subHeading}
            positiveLabel="Save"
            isLoading={state.fieldAlert.isLoading}
            configs={state.fieldAlert.configs}
            updateValidations={state.fieldAlert.updateValidations}
            onChange={state.fieldAlert.onChange}
            onSubmit={(data = {}) => {
              const payload = Object.entries(data).reduce(
                (acc, [key, value]) => {
                  acc[key] =
                    key !== "bill_rate_type" &&
                    key !== "pay_rate_type" &&
                    !data[key]
                      ? "0"
                      : value;
                  return acc;
                },
                {}
              );

              handleFieldAlertSubmit(state.fieldAlert.id, payload);
            }}
            onClose={() => {
              setState((prevState) => ({
                ...prevState,
                fieldAlert: defaultState.fieldAlert,
              }));
            }}
          />
        )}
        {state.showIcDetails && (
          <IcForm
            open={!!state.showIcDetails}
            isTabletView={appData.isTabletView}
            isFetching={state.isIcFetching}
            entry={appDataList.IC[0]}
            usersList={appData.users}
            onClose={() => {
              setState((prevState) => ({
                ...prevState,
                showIcDetails: false,
              }));
            }}
          />
        )}
        {state.isFiltering && (
          <FilterComponent
            isFiltering={state.isFiltering}
            filterConfig={filterConfig}
            appliedFilters={state.pageFilters}
            onFilterApply={handleFilterChange}
            onClose={() => {
              setState((prevState) => ({
                ...prevState,
                isFiltering: false,
              }));
            }}
          />
        )}
        {state.showConfirmationPopup && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
              paper: classes.paper_Size,
            }}
            open={!!state.showConfirmationPopup}
            contentText="Are you sure you want to approve the selected record's?"
            onConfirm={() => {
              !state.areAllSelected
                ? handleApprove(
                    state.search,
                    state.filters,
                    state.pageSize,
                    state.pageNumber,
                    state.order,
                    state.orderBy,
                    state.searchColumns,
                    state.pageFilters
                  )
                : state.showDoNotShowCheckboxValue
                ? handleBulkApprove(
                    state.search,
                    state.filters,
                    state.pageSize,
                    state.pageNumber,
                    state.order,
                    state.orderBy,
                    state.searchColumns,
                    state.pageFilters
                  )
                : handleDoNotShowDialog();
            }}
            //onConfirm={() => handleApprove()}
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                showConfirmationPopup: false,
              }))
            }
            isConfirmDisabled={state.isApproveLoading}
            positiveActionLabel={
              <>
                Confirm
                {state.isApproveLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}
        {state.showUnapproveConfirmationPopup && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
              paper: classes.paper_Sizes,
            }}
            open={!!state.showUnapproveConfirmationPopup}
            contentText="Are you sure you want to unapprove the selected record's?"
            onConfirm={() => handleUnapprove()}
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                showUnapproveConfirmationPopup: false,
              }))
            }
            isConfirmDisabled={state.isApproveLoading}
            positiveActionLabel={
              <>
                Confirm
                {state.isApproveLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}

        {state.isUnderReview && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
            }}
            open={!!state.isUnderReview}
            contentText="Schedule will undergo for review process on billing & settlement review screen."
            onConfirm={() => {
              setState((prevState) => ({ ...prevState, isUnderReview: false }));
            }}
            onCancel={() => {
              setState((prevState) => ({ ...prevState, isUnderReview: false }));
            }}
            isConfirmDisabled={state.isLoading}
            positiveActionLabel={
              <>
                OK
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
            negativeActionLabel={null}
          />
        )}

        {state.showDoNotShowPopup && (
          <ActionDialog
            classes={{
              confirm: "bg-primary",
              paper: classes.paperSizeDoNotShowDialogs,
            }}
            open={!!state.showDoNotShowPopup}
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                showDoNotShowPopup: false,
              }))
            }
            negativeActionLabel={null}
            content={
              <div>
                <Typography variant="body1">
                  Rejected records in this submission, will not be approved.
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      //checked={state.showDoNotShowCheckboxValue}
                      onChange={(evt) => handleCheckBoxChange(evt)}
                      name="checkedF"
                    />
                  }
                  label="Do not show this checkbox again."
                />
              </div>
            }
            onConfirm={() =>
              handleBulkApprove(
                state.search,
                state.filters,
                state.pageSize,
                state.pageNumber,
                state.order,
                state.orderBy,
                state.searchColumns,
                state.pageFilters
              )
            }
            // onCancel={() =>
            //   setState((prevState) => ({ ...prevState, deletingEntryId: null }))
            // }
            isConfirmDisabled={state.isApproveLoading}
            positiveActionLabel={
              <>
                OK
                {state.isApproveLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}
        {state.showApproveRecordsLoader && (
          <Dialog
            classes={{
              confirm: "bg-primary",
              paper: classes.paperSizeDoNotShowDialog,
            }}
            aria-labelledby="simple-dialog-title"
            open={state.showApproveRecordsLoader}
          >
            <DialogContent>
              <>
                <div>
                  <Typography variant="body1" className="mb-2">
                    Approving records is under process.Please Wait...
                  </Typography>
                </div>
                <div className="d-flex f-justify-between f-align-center">
                  <div className="d-flex"></div>
                  <div className="d-flex mt-4">
                    <CircularProgress
                      size={36}
                      className={clsx("p-absolute progress-btn")}
                    />
                  </div>
                  <div className="d-flex"></div>
                </div>
              </>
            </DialogContent>
          </Dialog>
        )}
      </Grid>
    </div>
  );
};

export default BillingSchedule;
