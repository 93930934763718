import { useCallback, useEffect, useMemo, useContext, useState } from "react";
import { toast } from "react-toastify";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import baseRoster from "../assets/icons/baseRoster.svg";
import BillingSettlementReview from "../assets/icons/billingSettlementReview.svg";
import Lookup from "../assets/icons/lookup.svg";
import billingandsettlement from "../assets/icons/billingSettlement.svg";
import settlement from "../assets/icons/settlement.svg";
import customers from "../assets/icons/customers.svg";
import priceMatrix from "../assets/icons/priceMatrix.svg";
import dailySchedule from "../assets/icons/dailySchedule.svg";
import dailyScheduleReview from "../assets/icons/dailyScheduleReview.svg";
import reports from "../assets/icons/reports.svg";
import independentContractors from "../assets/icons/independentContractor.svg";
import users from "../assets/icons/users.svg";
import fuel from "../assets/icons/fuel.svg";
import uploadDownload from "../assets/icons/uploadDownload.svg";
import invoice from "../assets/icons/invoice.svg";
import LocationModule from "./customers/Location";
import LocationIcon from "./../assets/images/sidebarLocationIcon.png";
import TransferIcon from "./../assets/icons/transfer.svg";
import TransferWhiteIcon from "./../assets/icons/transfer-white.svg";

import {
  AppContextConsumer,
  GlobalStyles,
  SidebarHeaderLayout,
  ProfileMenu,
  PrivateRoute,
  ActionDialog,
  Grid,
  isTransferScheduleEnable,
} from "shared";
import { AppContext } from "shared/contexts";
import SharedService from "modules/shared/service";
import SignInModule from "./sign-in";
import ExchangeModule from "./exchange";
import DailyScheduleModule from "./daily-schedule";
import BillingModule from "./billing";
import Messenger from "./messenger/view/index";
import UsersModule from "./users";
import BaseRosterModule from "./base-roster";
import ReportgModule from "./report";
import InvoiceModule from "./invoice";
import ContractorsModule from "./contractors";
import CustomersModule from "./customers";
import HomeModule from "./home";
import DailyScheduleReviewModule from "./daily-schedule-review";
import BillingSettlementReviewModule from "./billing-settelement-review";
import LookUpModule from "./lookups";
import PriceMatrixModule from "./price-matrix";
import FuelSurchargeModule from "./fuel-surcharge";
import NotificationsModule from "./notifications";
import {
  getTokenData,
  hasFeatureAccess,
  isJWTExpired,
  getToken,
  setAlertToken,
  removeScheduleAlertToken,
  getScheduleAlertToken,
  getLastFetchToken,
  getUTCDateString,
  removeAlertToken,
} from "utils";
import { PERMISSION, ROLE } from "./shared/constants";
import KAMPdf from "../assets/pdfs/KAM-UserGuide.pdf";
import AAPdf from "../assets/pdfs/AA-user-guide.pdf";
import NotificationsIcon from "@material-ui/icons/Notifications";
import DCNComponent from "./DCN";
import TransferScheduleModule from "./Transfer Schedule";
import moment from "moment";

let notificationTimeout = null;
let isOnTime = false;

const NOTIFICATION_COUNT_INTERVAL = 60;
const helpDoc = {
  kam: KAMPdf,
  admin: AAPdf,
  aa: AAPdf,
  mgr: AAPdf,
};

const defaultSidebarElements = [
  {
    to: "/base-roster",
    label: "Base Roster",
    icon: <img src={baseRoster} alt="baseRoster" height={30} width={30} />,
  },
  {
    to: "/customers",
    label: "Customers",
    icon: <img src={customers} alt="baseRoster" height={30} width={30} />,
  },
  {
    to: "/daily-schedule",
    label: "Daily Schedule",
    icon: (
      <img src={dailySchedule} alt="dailySchedule" height={30} width={30} />
    ),
  },
  {
    to: "/fuel-price",
    label: "Fuel Price",
    icon: <img src={fuel} alt="fuel" height={30} width={30} />,
  },
  {
    to: "/contractors",
    label: "ICs",
    icon: (
      <img
        src={independentContractors}
        alt="independentContractors"
        height={30}
        width={30}
      />
    ),
  },
  {
    to: "/geo-location",
    label: "Location",
    icon: <img src={LocationIcon} alt="location" height={30} width={30} />,
  },
  {
    to: "/report",
    label: "Reports",
    icon: <img src={reports} alt="reports" height={30} width={30} />,
  },
  {
    to: "/transfer-schedule",
    label: "Transferred Schedule",
    icon: <img src={TransferIcon} alt="reports" height={30} width={30} />,
  },
  {
    to: "/exchange",
    label: "Upload/Download",
    icon: (
      <img src={uploadDownload} alt="upload Download" height={30} width={30} />
    ),
  },

  // {
  //   to: "/schedule-calendar",
  //   label: "Schedule Calendar",
  //   icon: (
  //     <img
  //       src={ScheduleCalendar}
  //       alt="Schedule Calendar"
  //       height={30}
  //       width={30}
  //     />
  //   ),
  // },
];

const deafultSalesOnlyView = [
  {
    to: "/customers",
    label: "Customers",
    icon: <img src={customers} alt="baseRoster" height={30} width={30} />,
  },
  {
    to: "/fuel-price",
    label: "Fuel Price",
    icon: <img src={fuel} alt="fuel" height={30} width={30} />,
  },
  {
    to: "/geo-location",
    label: "Location",
    icon: <img src={LocationIcon} alt="location" height={30} width={30} />,
  },
];

const defaultViewOnlyPages = [
  {
    to: "/geo-location",
    label: "Location",
    icon: <img src={LocationIcon} alt="location" height={30} width={30} />,
  },
];

const defaultState = {
  openScheduleSubmissionAlertDialog: false,
  isLoading: false,
  notSubmitted: [],
  isFetchingList: {
    users: false,
    transferRecords: false,
  },
};

const AppModule = () => {
  GlobalStyles();
  const currentUser = getTokenData() || {};
  const scheduleAlertToken = getScheduleAlertToken();
  const lastFetchToken = getLastFetchToken();
  const { appData, updateContextData } = useContext(AppContext);
  const isRoleAA = (currentUser?.role || "").toLowerCase() === ROLE.AA;
  const isRoleMGR = (currentUser?.role || "").toLowerCase() === ROLE.MGR;
  const isRoleADMIN = (currentUser?.role || "").toLowerCase() === ROLE.ADMIN;
  const isRoleMapsView =
    (currentUser?.role || "").toLowerCase() === ROLE.MAPS_VIEW;
  const isRoleSalesView =
    (currentUser?.role || "").toLowerCase() === ROLE.SALES_VIEW;
  const isRoleKAM = (currentUser?.role || "").toLowerCase() === ROLE.KAM;

  const [state, setState] = useState(defaultState);

  const kamId = isRoleKAM && currentUser?.id;

  const getSidebarElements = useCallback((role) => {
    if (role === ROLE.KAM || !role) {
      return defaultSidebarElements;
    } else if (isRoleMapsView) {
      return defaultViewOnlyPages;
    } else if (isRoleSalesView) {
      return deafultSalesOnlyView;
    }

    const sidebarElements = [];

    [
      "/base-roster",
      "/customers",
      "/daily-schedule",
      "/fuel-price",
      "/geo-location",
      "/report",
      "/exchange",
    ].forEach((route) => {
      let element = defaultSidebarElements.find(
        (element) => element.to === route
      );
      // if (!isRoleKAM && element?.to === "/daily-schedule") {
      //   let currentDate = getDateString(new Date())?.split("/");
      //   currentDate = `${currentDate[2]}-${currentDate[0]}-${currentDate[1]}`;
      //   element = {
      //     ...element,
      //     to: `/daily-schedule/create/day/${currentDate}`,
      //   };
      // }

      sidebarElements.push(element);
    });
    sidebarElements.splice(1, 0, {
      to: "/billing",
      label: "Billing & Settlements",
      icon: (
        <img
          src={billingandsettlement}
          alt="billingSettlement"
          height={30}
          width={30}
        />
      ),
    });

    hasFeatureAccess(PERMISSION.MANAGE_DCN) &&
      sidebarElements.splice(isRoleADMIN ? 4 : 4, 0, {
        to: "/settlements",
        label: "Settlements",
        icon: <img src={settlement} alt="settlement" height={35} width={35} />,
      });

    hasFeatureAccess(PERMISSION.REVIEWER) &&
      sidebarElements.splice(!isRoleAA ? 2 : 0, 0, {
        to: "/billing-settlement-review",
        label: "Billing & Settlement Review",
        icon: (
          <img
            src={BillingSettlementReview}
            alt="Billing Settlement Review"
            height={30}
            width={30}
          />
        ),
      });

    hasFeatureAccess(PERMISSION.MANAGE_CONTRACTOR) &&
      sidebarElements.splice(isRoleADMIN ? 7 : 6, 0, {
        to: "/contractors",
        label: "ICs",
        icon: (
          <img
            src={independentContractors}
            alt="independentContractors"
            height={30}
            width={30}
          />
        ),
      });

    hasFeatureAccess(PERMISSION.MANAGE_INVOICE) &&
      sidebarElements.splice(isRoleADMIN ? 8 : 7, 0, {
        to: "/invoice",
        label: "Invoice",
        icon: <img src={invoice} alt="invoice" height={30} width={30} />,
      });

    // sidebarElements.splice(isRoleAA ? 2 : 3, 0, {
    //   to: "/customers",
    //   label: "Customers",
    //   icon: <img src={customers} alt="customers" height={30} width={30} />,
    // });

    hasFeatureAccess(PERMISSION.REVIEWER) &&
      sidebarElements.splice(isRoleADMIN ? 9 : 8, 0, {
        to: "/daily-schedule-review",
        label: "Manager Review",
        icon: (
          <img
            src={dailyScheduleReview}
            alt="dailyScheduleReview"
            height={30}
            width={30}
          />
        ),
      });
    isTransferScheduleEnable &&
      hasFeatureAccess(PERMISSION.MANAGE_TRANSFER_SCHEDULE) &&
      sidebarElements.splice(isRoleADMIN ? 11 : isRoleMGR ? 10 : 9, 0, {
        to: "/transfer-schedule",
        label: "Transferred Schedule",
        icon: <img src={TransferIcon} alt="reports" height={30} width={30} />,
      });

    if (role === ROLE.AA) {
      sidebarElements.splice(8, 0, {
        to: "/lookup",
        label: "Lookup",
        icon: <img src={Lookup} alt="lookup" height={30} width={30} />,
      });
      sidebarElements.splice(9, 0, {
        to: "/price-matrix",
        label: "Price Matrix",
        icon: (
          <img src={priceMatrix} alt="priceMatrix" height={30} width={30} />
        ),
      });
    }

    if (role === ROLE.ADMIN || !role) {
      sidebarElements.splice(9, 0, {
        to: "/lookup",
        label: "Lookup",
        icon: <img src={Lookup} alt="lookup" height={30} width={30} />,
      });
      // sidebarElements.splice(9, 0, {
      //   to: "/geo-location",
      //   label: "Location",
      //   icon: <img src={LocationIcon} alt="lookup" height={30} width={30} />,
      // });
      sidebarElements.splice(11, 0, {
        to: "/price-matrix",
        label: "Price Matrix",
        icon: (
          <img src={priceMatrix} alt="priceMatrix" height={30} width={30} />
        ),
      });

      sidebarElements.push({
        to: "/users",
        label: "Users",
        icon: <img src={users} alt="users" height={30} width={30} />,
      });
    }

    return sidebarElements;
  }, []);

  const currentUserRole = (currentUser?.role || ROLE.KAM).toLowerCase();

  const fetchNotificationCount = async () => {
    const { data, error } = await SharedService.getNotificationCount();
    if (error) {
      console.error(
        `Error while fetching unread notifications: ${
          Array.isArray(error) ? error[0]?.message : error.message
        }`
      );
    } else {
      updateContextData("notificationCount", data?.unread || 0);
    }
  };

  const fetchList = async (listType = "users", ...params) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    switch (listType) {
      case "transferRecords":
        serviceMethod = "getTransferScheduleRequests";
        responseKey = "transferRecords";
        break;
      default:
        serviceMethod = "getUsersList";
        responseKey = "users";
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];
    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
  };

  const subscribeNotification = () => {
    clearTimeout(notificationTimeout);
    fetchNotificationCount();
    notificationTimeout = setTimeout(
      subscribeNotification,
      1000 * 3 * NOTIFICATION_COUNT_INTERVAL
    );
  };

  useEffect(() => {
    if (kamId && isRoleKAM && !isJWTExpired(getToken())) {
      if (isTransferScheduleEnable) {
        fetchList("transferRecords", "", kamId);
      }
      fetchList("users");
    }
  }, []);

  const fetchSubmissionHistory = async (kamId) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const { data, error } = await SharedService.getSubmissionHistory(kamId);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    const notSubmittedDates =
      data?.notSubmitted.length > 0
        ? data?.notSubmitted
            .map((item) => {
              return item.split("T")[0];
            })
            ?.sort((a, b) => new Date(b) - new Date(a))
        : defaultState.notSubmitted;

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      notSubmitted: notSubmittedDates,
    }));
  };

  const isScheduleOnTime = (date) => {
    const scheduleMoment = moment
      .tz(date, "America/New_York")
      .set({ hour: 12, minute: 0, second: 0, millisecond: 0 });

    const submittedMoment = moment().tz("America/New_York");

    const isWeekend =
      moment(getUTCDateString(date), "MM/DD/YYYY").day() === 5 ||
      moment(getUTCDateString(date), "MM/DD/YYYY").day() === 6;

    const nextDay = isWeekend
      ? scheduleMoment
          .clone()
          .isoWeekday(8)
          .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
      : scheduleMoment.clone().add(1, "day");

    const isSubmittedAfterNextDay = submittedMoment.isAfter(nextDay);

    if (isSubmittedAfterNextDay) {
      return (isOnTime = false);
    } else {
      return (isOnTime = true);
    }
  };

  const columnConfig = [
    {
      id: "notSubmitted",
      label: "Not Submitted Dates",
      render: (row) => {
        return (
          <Tooltip title={getUTCDateString(row) || ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {getUTCDateString(row)}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  const handleScheduleAlert = () => {
    const currentTime = Date.now();
    const eventTriggerTime = currentTime + 12 * 60 * 60 * 1000;
    setAlertToken(eventTriggerTime);

    setState((prevState) => ({
      ...prevState,
      openScheduleSubmissionAlertDialog: false,
    }));

    removeScheduleAlertToken();
  };

  const notSubmittedDatesArray = useMemo(() => {
    return state.notSubmitted.length > 0
      ? state.notSubmitted.filter((item) => !isScheduleOnTime(item))
      : [];
  }, [state.notSubmitted]);

  useEffect(() => {
    if (!notSubmittedDatesArray.length && !isRoleKAM) {
      removeScheduleAlertToken();
    } else if (isRoleKAM && notSubmittedDatesArray.length > 0) {
      setState((prevState) => ({
        ...prevState,
        openScheduleSubmissionAlertDialog: true,
      }));
    }
  }, [notSubmittedDatesArray, isRoleKAM]);

  useEffect(() => {
    if (scheduleAlertToken && isRoleKAM && !isJWTExpired(getToken())) {
      fetchSubmissionHistory(kamId);
    }
  }, [scheduleAlertToken, isRoleKAM]);

  const checkEventTrigger = () => {
    const eventTriggerTime = lastFetchToken;
    if (eventTriggerTime && Date.now() >= parseInt(eventTriggerTime)) {
      fetchSubmissionHistory(kamId);
      setState((prevState) => ({
        ...prevState,
        openScheduleSubmissionAlertDialog: true,
      }));
      removeAlertToken();
    }
  };

  useEffect(() => {
    if (lastFetchToken && isRoleKAM && !isJWTExpired(getToken())) {
      const intervalId = setInterval(() => {
        checkEventTrigger();
      }, 180000);

      return () => clearInterval(intervalId);
    }
  }, [lastFetchToken, isRoleKAM]);

  useEffect(() => {
    !isJWTExpired(getToken()) && subscribeNotification();
    return () => {
      clearTimeout(notificationTimeout);
    };
  }, []);

  return (
    <div id="numbers-page-wrapper">
      <Router>
        <Switch>
          <Route path="/sign-in" component={SignInModule} />
          {/* <Route path='/sign-up' component={SignUpModule} /> */}
          <AppContextConsumer>
            {({ appData, headerElements, themeVariant, updateContextData }) => {
              return (
                <>
                  <SidebarHeaderLayout
                    headerElements={
                      <div className="w-100 d-flex f-align-center f-justify-between">
                        <div className=" d-flex">
                          {" "}
                          <Typography
                            className="color-white text-bold mr-10 ml-10"
                            variant="body1"
                            component="a"
                            href={helpDoc[currentUserRole]}
                            target="blank"
                          >
                            {!isRoleMapsView && !isRoleSalesView ? "Help" : " "}
                          </Typography>
                        </div>

                        {/* <span className="brand-logo"></span> */}
                        <div className=" d-flex">
                          <Link to="/home" className="color-white">
                            <Typography variant="h6" className={"ml-10"}>
                              PDX Processing System
                            </Typography>
                          </Link>
                        </div>

                        <div className="d-flex">
                          <div className="d-flex f-align-center mr-4">
                            <Tooltip
                              title="Notifications"
                              placement="top-start"
                            >
                              <Link to="/notifications" className="color-white">
                                <Badge
                                  color="error"
                                  badgeContent={appData.notificationCount}
                                  className="c-pointer"
                                >
                                  {!isRoleMapsView && !isRoleSalesView ? (
                                    <NotificationsIcon />
                                  ) : null}
                                </Badge>
                              </Link>
                            </Tooltip>
                            {isTransferScheduleEnable &&
                              isRoleKAM &&
                              !!appData.transferRecords &&
                              appData.transferRecords.length > 0 && (
                                <Route
                                  render={(props) => (
                                    <Tooltip
                                      title="TRANSFER REQUEST RECEIVED"
                                      placement="top-start"
                                    >
                                      <IconButton
                                        className="color-white ml-6"
                                        onClick={() =>
                                          props.history.push(
                                            "/transfer-schedule"
                                          )
                                        }
                                      >
                                        <Badge
                                          color="error"
                                          badgeContent={
                                            appData.transferRecords?.filter(
                                              (data) =>
                                                data?.org_kam_id !== kamId &&
                                                data.status === "PENDING"
                                            )?.length
                                          }
                                          className="c-pointer"
                                        >
                                          <img
                                            src={TransferWhiteIcon}
                                            alt="reports"
                                            height={30}
                                            width={30}
                                          />
                                        </Badge>
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                />
                              )}
                          </div>
                          {headerElements}
                          {/* <IconButton
												className='pointer'
												onClick={() =>
													updateContextData(
														'themeVariant',
														themeVariant === THEME_VARIANT.LIGHT
															? THEME_VARIANT.DARK
															: THEME_VARIANT.LIGHT
													)
												}
											>
												{themeVariant === THEME_VARIANT.LIGHT ? (
													<DarkThemeVariantIcon />
												) : (
													<LightThemeVariantIcon />
												)}
											</IconButton> */}
                          <ProfileMenu />
                        </div>
                      </div>
                    }
                    sidebarElements={getSidebarElements(currentUserRole)}
                    alertBar={appData.alertBar}
                  >
                    <Switch>
                      {/* <PrivateRoute
										path='/user-profile'
										component={UserProfileModule}
									/> */}
                      {!(isRoleMapsView || isRoleSalesView) && (
                        <PrivateRoute
                          path="/daily-schedule"
                          component={DailyScheduleModule}
                        />
                      )}
                      {isTransferScheduleEnable &&
                        hasFeatureAccess(
                          PERMISSION.MANAGE_TRANSFER_SCHEDULE
                        ) && (
                          <PrivateRoute
                            path="/transfer-schedule"
                            component={TransferScheduleModule}
                          />
                        )}
                      {/* {currentUserRole === ROLE.KAM && (
                      <PrivateRoute
                        path="/schedule-calendar"
                        component={DisplayCalenderModule}
                      />
                    )} */}
                      {!(isRoleMapsView || isRoleSalesView) && (
                        <PrivateRoute
                          path="/exchange"
                          component={ExchangeModule}
                        />
                      )}
                      {hasFeatureAccess(PERMISSION.MANAGE_BILLING) && (
                        <PrivateRoute
                          path="/billing"
                          component={BillingModule}
                        />
                      )}
                      {hasFeatureAccess(PERMISSION.MANAGE_DCN) && (
                        <PrivateRoute
                          path="/settlements"
                          component={DCNComponent}
                        />
                      )}
                      {!(isRoleMapsView || isRoleSalesView) && (
                        <PrivateRoute
                          path="/base-roster"
                          component={BaseRosterModule}
                        />
                      )}
                      {hasFeatureAccess(PERMISSION.MANAGE_REPORT) && (
                        <PrivateRoute
                          path="/report"
                          component={ReportgModule}
                        />
                      )}

                      {hasFeatureAccess(PERMISSION.MANAGE_INVOICE) && (
                        <PrivateRoute
                          path="/invoice"
                          component={InvoiceModule}
                        />
                      )}
                      {hasFeatureAccess(PERMISSION.MANAGE_CONTRACTOR) && (
                        <PrivateRoute
                          path="/contractors"
                          component={ContractorsModule}
                        />
                      )}
                      {hasFeatureAccess(PERMISSION.MANAGE_USERS) && (
                        <PrivateRoute path="/users" component={UsersModule} />
                      )}
                      {hasFeatureAccess(PERMISSION.MANAGE_CUSTOMERS) && (
                        <PrivateRoute
                          path="/customers"
                          component={CustomersModule}
                        />
                      )}
                      {hasFeatureAccess(PERMISSION.REVIEWER) && (
                        <PrivateRoute
                          path="/daily-schedule-review"
                          component={DailyScheduleReviewModule}
                        />
                      )}
                      {hasFeatureAccess(PERMISSION.REVIEWER) && (
                        <PrivateRoute
                          path="/billing-settlement-review"
                          component={BillingSettlementReviewModule}
                        />
                      )}
                      <PrivateRoute
                        path="/geo-location"
                        component={LocationModule}
                      />
                      {!isRoleMapsView && (
                        <PrivateRoute
                          path="/fuel-price"
                          component={FuelSurchargeModule}
                        />
                      )}
                      {hasFeatureAccess(PERMISSION.MANAGE_LOOKUP) && (
                        <PrivateRoute path="/lookup" component={LookUpModule} />
                      )}
                      {hasFeatureAccess(PERMISSION.MANAGE_PRICE_MATRIX) && (
                        <PrivateRoute
                          path="/price-matrix"
                          component={PriceMatrixModule}
                        />
                      )}
                      <PrivateRoute path="/home" component={HomeModule} />
                      {!(isRoleMapsView || isRoleSalesView) && (
                        <PrivateRoute
                          path="/notifications"
                          component={NotificationsModule}
                        />
                      )}
                      <Redirect
                        exact
                        path="/"
                        // to={
                        //   currentUserRole === ROLE.KAM
                        //     ? "/daily-schedule"
                        //     : "/billing"
                        // }
                        to="/home"
                      />
                      <Route
                        render={(props) => (
                          <>
                            <div className="m-10 pt-10 d-flex f-justify-center f-align-center flex-1">
                              <div>
                                <h2>Uh Oh! Something is not right...</h2>
                                <h4>
                                  The page you are trying to access does not
                                  exist.
                                </h4>
                                <h4>
                                  Maybe you want to go to{" "}
                                  <span
                                    className="color-primary c-pointer"
                                    onClick={() => {
                                      props.history.push("/");
                                    }}
                                  >
                                    Home
                                  </span>{" "}
                                  page?
                                </h4>
                              </div>
                            </div>
                          </>
                        )}
                      />
                      <div></div>
                    </Switch>
                  </SidebarHeaderLayout>
                  {!isJWTExpired(getToken()) ? <Messenger /> : null}
                  {state.openScheduleSubmissionAlertDialog &&
                    notSubmittedDatesArray.length > 0 && (
                      <ActionDialog
                        classes={{
                          confirm: "bg-primary",
                        }}
                        open={!!state.openScheduleSubmissionAlertDialog}
                        positiveActionLabel="Ok"
                        negativeActionLabel={null}
                        disableBackdropClick={true}
                        content={
                          <>
                            <Typography
                              style={{ marginBottom: "20px", fontSize: "20px" }}
                              variant="body1"
                              className="mt-2"
                            >
                              The schedule for the below mentioned dates has not
                              been submitted yet!
                            </Typography>

                            <Grid
                              columns={columnConfig}
                              rows={notSubmittedDatesArray}
                              actionBarConfig={null}
                              isLoading={state.isLoading}
                              hasSelection={false}
                              hasPagination={false}
                            />
                          </>
                        }
                        onClose={() => {
                          handleScheduleAlert();
                        }}
                        onConfirm={() => {
                          handleScheduleAlert();
                        }}
                      />
                    )}
                </>
              );
            }}
          </AppContextConsumer>
        </Switch>
      </Router>
    </div>
  );
};

export default AppModule;
