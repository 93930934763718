import SaveAltIcon from '@material-ui/icons/SaveAlt';
import FilterListIcon from '@material-ui/icons/FilterList';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { noop, GRID_ACTION_TYPE, GRID_ACTION_VIEW } from 'shared/constants';

const buttonConfigMap = {
	[GRID_ACTION_TYPE.CREATE]: {
		icon: AddIcon,
		label: 'New',
		color: 'primary',
		view: GRID_ACTION_VIEW.DEFAULT,
	},
	[GRID_ACTION_TYPE.DELETE]: {
		icon: DeleteForeverIcon,
		label: 'Delete',
		color: 'secondary',
		view: GRID_ACTION_VIEW.DEFAULT,
	},
	[GRID_ACTION_TYPE.UPDATE]: {
		icon: EditOutlinedIcon,
		label: 'Edit',
		color: 'primary',
		view: GRID_ACTION_VIEW.DEFAULT,
	},
	[GRID_ACTION_TYPE.EXPORT]: {
		icon: SaveAltIcon,
		tooltip: 'Export data',
		color: 'primary',
		view: GRID_ACTION_VIEW.DEFAULT,
	},
	[GRID_ACTION_TYPE.FILTER]: {
		icon: FilterListIcon,
		tooltip: 'Export data',
		color: 'primary',
		view: GRID_ACTION_VIEW.DEFAULT,
	},
};

export const renderLeftActions = (
	actions,
	theme,
	numSelected,
	toolbar = 'action'
) => {
	return actions.map(element => {
		let Icon = null;
		let label = null;
		let color = null;
		if (element.render) {
			return element.render();
		} else {
			const btnConfig = buttonConfigMap[element.type];
			Icon = element.icon || btnConfig.icon || <></>;
			label = element.label || btnConfig.label || '';
			color = element.color || btnConfig.color || '';
			let view = element.view || btnConfig.view || GRID_ACTION_VIEW.DEFAULT;
			const content = (
				<Button
					key={`left-actions-${label}`}
					variant={theme.palette.type === 'light' ? 'outlined' : 'contained'}
					className='mr-6'
					color={color}
					startIcon={<Icon />}
					onClick={element.onClick || noop}
				>
					{label}
				</Button>
			);

			if (numSelected > 0) {
				if (
					(toolbar === 'action' && view === GRID_ACTION_VIEW.SELECTION) ||
					view === GRID_ACTION_VIEW.ALL ||
					(toolbar === 'filter' && view === GRID_ACTION_VIEW.FILTER_SELECTION)
				) {
					return content;
				} else {
					return null;
				}
			} else {
				if (
					(toolbar === 'action' && view === GRID_ACTION_VIEW.DEFAULT) ||
					view === GRID_ACTION_VIEW.ALL ||
					(toolbar === 'filter' && view === GRID_ACTION_VIEW.FILTER)
				) {
					return content;
				} else {
					return null;
				}
			}
		}
	});
};

export const renderRightActions = (
	actions,
	theme,
	numSelected,
	toolbar = 'action'
) => {
	return actions.map(element => {
		if (element.render) {
			return element.render();
		} else {
			const btnConfig = buttonConfigMap[element.type];
			let Icon = element.icon || btnConfig.icon || <></>;
			let tooltip = element.tooltip || btnConfig.tooltip || '';
			let color = element.color || btnConfig.color || 'primary';
			let view = element.view || btnConfig.view || GRID_ACTION_VIEW.DEFAULT;
			let content = (
				<Tooltip key={`right-actions-${element.type}`} title={tooltip}>
					<IconButton
						color={theme.palette.type === 'light' ? color : 'default'}
						onClick={element.onClick || noop}
						size='small'
						// className='c-pointer'
					>
						<Icon />
					</IconButton>
				</Tooltip>
			);
			if (numSelected > 0) {
				if (
					(toolbar === 'action' && view === GRID_ACTION_VIEW.SELECTION) ||
					view === GRID_ACTION_VIEW.ALL ||
					(toolbar === 'filter' && view === GRID_ACTION_VIEW.FILTER_SELECTION)
				) {
					return content;
				} else {
					return null;
				}
			} else {
				if (
					(toolbar === 'action' && view === GRID_ACTION_VIEW.DEFAULT) ||
					view === GRID_ACTION_VIEW.ALL ||
					(toolbar === 'filter' && view === GRID_ACTION_VIEW.FILTER)
				) {
					return content;
				} else {
					return null;
				}
			}
		}
	});
};
