import { getAppConfig } from "utils";

export * from "./themes";
export { default as GlobalStyles } from "./styles";
export const APP_NAME = getAppConfig().appName;

export const noop = () => {
  return;
};

export const APP_SUBSCRIPTION_TOPIC = {
  themeUpdate: "themeUpdate",
  themeVariantUpdate: "themeVariantUpdate",
};

export const THEME = {
  DEFAULT: "default",
};

export const NOTIFICATION_DISPLAY_NAMES = {
  "bs-approval": "Billing & Settlement Approval",
  "rds-approved": "Manager Review Approved",
  "rds-rejected": "Manager Review Rejected",
  "bs-approved": "Billing & Settlement Approved",
  "bs-reject": "Billing & Settlement Reject",
  "ic-flagged": "IC Flagged",
  "ic-unflagged": "IC Unflagged",
  "new/update-record": "New/Update Record",
  "customer-route-changed": "Customer Route Changed",
  "route-details-changed": "Route Details Changed",
  "price-changed": "Price Changed",
  "room-user-removed": "Room User Removed",
  "room-left": "Room Left",
  "room-user-added": "Room User Added",
  "room-name-changed": "Room Name Changed",
  "transfer-schedule-approved": "Transfer Schedule Request Approved",
  "transfer-schedule-rejected": "Transfer Schedule Request Rejected",
  "transfer-schedule": "Transfer Schedule Received",
};

export const GRID_ACTION_VIEW = {
  DEFAULT: "default",
  ALL: "default-selection",
  SELECTION: "selection",
  FILTER: "filter",
  FILTER_SELECTION: "filter-selection",
};

export const GRID_ACTION_TYPE = {
  CREATE: "create",
  DELETE: "delete",
  UPDATE: "update",
  EXPORT: "export",
  FILTER: "filter",
};

export const THEME_VARIANT = {
  DARK: "dark",
  LIGHT: "light",
};

export const PAGE_KEYS = {
  GLOBAL: "global",
  SIGNUP: "signup",
  SIGNIN: "signin",
  ENTRIES: "entries",
  PROJECTS: "projects",
  TEAMS: "teams",
  ROLES: "roles",
  USERS: "users",
  FEEDBACK: "feedback",
  BILLING: "billing",
  CUSTOMERS: "customers",
  CONTRACTORS: "contractors",
  DAILY_SCHEDULE: "daily-schedule",
  BASE_ROSTER: "base-roster",
  DAILY_SCHEDULE_REVIEW: "daily-schedule-review",
  BILLING_SETTLEMENT_REVIEW: "billing_settlement-review",
  REPORT: "report",
  LOOKUP: "lookup",
  DCN: "dcn",
  DCN_HISTORY: "dcn-history",
  DCN_DETALIED_HISTORY: "dcn-detalied-history",
  CUSTOM_FUEL_SURCHARGE: "custom-fuel-surcharge",
  FUEL_SURCHARGE: "fuel-surcharge",
  LOCATION: "location",
  IC_REPORT: "IC-report",
  KAM_REPORT: "KAM-report",
  PDX_COMPANY_REPORT: "PDX-company-report",
  PROFIT_AND_LOSS_REPORT: "profit-and-loss-report",
  DCN_FILTERS: "dcn-filters",
  AUDIT_HISTORY: "audit-history",
  DETALIED_AUDIT_HISTORY: "detailed-audit-history",
  PRICE_MATRIX: "price-matrix",
  ON_DEMAND: "on-demand",
  KAM_SUBMISSION_REPORT: "kam-submission-report",
  TRANSFER_SCHEDULE: "transfer_schedule",
  DCN_EXCLUDE_HISTORY: "dcn-exclude-history",
  VIEW_INVOICE: "view-invoice",
  RECORD_PAYMENT: "record-payment",
  APPROVE_INVOICE: "approve-invoice",
};

export const fieldErrorMessageMap = {
  firstName: "Only alphabets are allowed",
  lastName: "Only alphabets are allowed",
  email: "Invalid email",
  oldPassword:
    "Min. length: 8, must contain atleast one uppercase,one lowercase, one number digit, and special character",
  password:
    "Min. length: 8, must contain atleast one uppercase,one lowercase, one number digit, and special character",
  confirmPassword:
    "Min. length: 8, must contain atleast one uppercase,one lowercase, one number digit, and special character",
  name: "Max. length: 100, only alphanumeric characters are allowed",
  description: "Max. length: 255",
  vehicle_type: "Max. length: 50",
  notes: "Max. length: 250",
  route: "Max. length: 30",
  bill_rate_type: "Required",
  bill_rate: "Min: 0",
  bill_quantity: "Min: 0",
  bill_fuel_surcharge: "Min: 0",
  bill_fuel_amount: "Min: 0",
  bill_amount: "Min: 0",
  profit_percentage: "Min: 0",
  pay_rate_type: "Required",
  pay_rate: "Min: 0",
  pay_quantity: "Min: 0",
  pay_amount: "Min: 0",
  pay_fuel_surcharge: "Min: 0",
  pay_fuel_amount: "Min: 0",
  reimbursement: "Min: 0",
  deduction: "Min: 0",
  deduction_reason: "Max. length: 255",
  pay_toll_amount: "Min: 0",
  bill_toll_amount: "Min: 0",
};

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$.!%*#?&])[A-Za-z\d@$.!%*#?&]{8,15}$/;

export const PHONE_REGEX = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

export const EIN = /^\(?(\d{2})\)?[- ]?(\d{7})$/;

export const SSN = /^\(?(\d{3})\)?[- ]?(\d{2})[- ]?(\d{4})$/;
export const SSN_MASK = /^\(?([\d_*]{3})\)?[- ]?([\d_*]{2})[- ]?(\d{4})$/;

export const LOCATION_REGEX = /[a-zA-Z0-9]+/;

export const PREVENT_DECIMAL = /^[-+]?[0-9]+\.[0-9]+$/;

export const LIMIT = /^([\d]{0,10})(\.[\d]{1,3})?$/;

export const VALIDATIONS = {
  MAX_LENGTH: "maxLength",
  MIN_LENGTH: "minLength",
  MAX: "max",
  MIN: "min",
  REQUIRED: "required",
  EMAIL: "email",
  PHONE: "phone",
  LOCATION: "location",
  PREVENT_DECIMAL: "decimal",
  EIN: "ein",
  SSN: "ssn",
  SSN_MASK: "ssn_mask",
  LIMIT: "limit",
};

export const PRIMARY_ADDRESS = [
  {
    label: "Main Office",
    value: "main_office",
  },
  {
    label: "Billing Address",
    value: "billing_address",
  },
];

export const SECONDARY_ADDRESS = [
  {
    label: "Main Branch Office",
    value: "main_branch_office",
  },
  {
    label: "Branch Billing Address",
    value: "branch_billing_address",
  },
];

export const HEADERS = [
  "Hold",
  "IC Flag",
  "ICID",
  "Dept Num",
  "Last Name",
  "First Name",
  "SSN",
  "EIN",
  "IC Co. Name",
  "Sett.",
  "Fuel Reimb.",
  "Toll Reimb.",
  "Mileage Reimb.",
  "Reimb.",
  "Misc Ded.",
  "Misc2 Ded.",
  "Fuel Adv. Ded.",
  "Fuel Adv.2",
  "Cash Adv. Ded.",
  "Cash Adv.2 Ded.",
  "Claim Ded.",
  "Claim2 Ded.",
  "Claim3 Ded.",
  "Rental1 Ded.",
  "Rental2 Ded.",
  "Rental3 Ded.",
  "Rental4 Ded.",
  "EOR",
];

export const PDF_COLUMN_STYLES = {
  0: { columnWidth: 9 },
  1: { columnWidth: 9 },
  2: { columnWidth: 11 },
  3: { columnWidth: 10 },
  4: { columnWidth: 16 },
  5: { columnWidth: 16 },
  6: { columnWidth: 11 },
  7: { columnWidth: 21 },
  8: { columnWidth: 16 },
  9: { columnWidth: 18 },
  10: { columnWidth: 16 },
  11: { columnWidth: 15 },
  12: { columnWidth: 15 },
  13: { columnWidth: 15 },
  14: { columnWidth: 15 },
  15: { columnWidth: 15 },
  16: { columnWidth: 15 },
  17: { columnWidth: 15 },
  18: { columnWidth: 15 },
  19: { columnWidth: 15 },
  20: { columnWidth: 14 },
  21: { columnWidth: 14 },
  22: { columnWidth: 14 },
  23: { columnWidth: 14 },
  24: { columnWidth: 14 },
  25: { columnWidth: 14 },
  26: { columnWidth: 13 },
  27: { columnWidth: 13 },
};

export const isTransferScheduleEnable = true;

export const DEDUCTION_REASON_TYPE = [
  "CashAdvance2Deduction",
  "CashAdvanceDeduction",
  "Claim2Deduction",
  "Claim3Deduction",
  "ClaimDeduction",
  "FuelAdvanceDeduction",
  "Misc2Deduction",
  "MiscDeduction",
  "Rental1Deduction",
  "Rental2Deduction",
  "Rental3Deduction",
  "Rental4Deduction",
];
