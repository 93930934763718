import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { preventInputKeyCodes, validator } from "utils";
import Service from "../../service";
import { noop, VALIDATIONS } from "shared/constants";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import useStyles from "./style";

let isFieldChange = false;
const NUMBER_TYPE_FIELDS = ["fromPrice", "toPrice", "surchargePercent"];
const defaultState = {
  id: null,
  fromPrice: null,
  toPrice: null,
  surchargePercent: null,
  isLoading: false,
  errors: {
    fromPrice: " ",
    toPrice: " ",
    surchargePercent: " ",
  },
};

const Form = ({
  entry = null,
  isNew = false,
  schedule = "",
  onSave = noop,
  onClose = noop,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);
  const formRef = useRef(null);

  useEffect(() => {
    if (entry && !isNew) {
      setState((prevState) => ({
        ...prevState,
        id: entry.id || defaultState.id,
        fromPrice: entry.from_price || defaultState.fromPrice,
        toPrice: entry.to_price || defaultState.toPrice,
        surchargePercent:
          entry.surcharge_percent || defaultState.surchargePercent,
      }));
    }
    return () => (isFieldChange = false);
  }, [entry]);

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = {
      fromPrice: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.LIMIT, value: 10 },
      ],
      toPrice: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.LIMIT, value: 10 },
      ],
      surchargePercent: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.MIN, value: 0 },
        { type: VALIDATIONS.MAX, value: 100 },
      ],
    };

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );

      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        Object.keys(fieldValidatorMap).forEach((key) => {
          const message = validate(key, state[key]);
          console.log(message, "Message", key);
          if (!!message) {
            errorMessage = message;
          }
        });
      });
    }

    return errorMessage;
  };

  const handleFieldChange = (evt) => {
    isFieldChange = true;
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    const type = evt.currentTarget?.type || evt.target?.type;
    let errorMessage = validate(field, value) || " ";

    if (type === "number" && value.indexOf(".") !== -1) {
      let stringValue = value.toString().split(".");
      let length = stringValue[1]?.length > 2;
      if (length) {
        value = +value;
        value = Math.round((value + Number.EPSILON) * 100) / 100;
        value = value + "";
      }
    }
    if (type === "number" && value?.length > 1 && value.indexOf(".") === -1) {
      value = value?.replace(/^0+/, "");
    }

    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  const handleSubmit = async () => {
    Object.keys(state).map((key) => {
      if (NUMBER_TYPE_FIELDS.includes(key)) {
        state[key] = Number.isInteger(+state[key])
          ? (+state[key]).toFixed(0)
          : (+state[key]).toFixed(2);
      }
    });
    const { fromPrice, toPrice, surchargePercent } = state;
    setState((prevState) => ({ ...prevState, isLoading: true }));
    const serviceMethod = isNew
      ? "postCustomFuelSurcharge"
      : "putCustomFuelSurcharge";

    const { error } = await Service[serviceMethod]({
      id: entry?.id,
      name: schedule,
      from_price: fromPrice,
      to_price: toPrice,
      surcharge_percent: surchargePercent,
    });

    if (error) {
      setState((prevState) => ({ ...prevState, isLoading: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success(
      `Fuel surcharge schedule ${isNew ? "created" : "updated"} successfully.`
    );
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
    }));
    onSave();
  };

  useEffect(() => {
    if (isNew && formRef.current) {
      try {
        formRef.current.scrollIntoView(false);
      } catch (error) {
        console.error(`Error while scroll: ${error}`);
      }
    }
  }, []);

  return (
    <>
      <tr className={classes.formRow} ref={formRef}>
        <td></td>
        <td></td>
        <td>
          <Box className="d-flex f-align-center mr-4">
            <TextField
              fullWidth
              required
              type="number"
              label="From Price"
              variant="outlined"
              name="fromPrice"
              size="small"
              onKeyDown={preventInputKeyCodes}
              onWheel={(event) => event.target.blur()}
              value={state.fromPrice}
              className="mb-1 mt-2"
              onChange={handleFieldChange}
              error={!!state.errors.fromPrice.trim()}
              helperText={state.errors.fromPrice}
              InputProps={{
                startAdornment: <InputAdornment>$</InputAdornment>,
              }}
            />
          </Box>
        </td>
        <td>
          <Box className="d-flex f-align-center mr-4">
            <TextField
              fullWidth
              required
              type="number"
              label="To Price"
              variant="outlined"
              size="small"
              name="toPrice"
              onWheel={(event) => event.target.blur()}
              onKeyDown={preventInputKeyCodes}
              value={state.toPrice}
              className="mb-1 mt-2"
              onChange={handleFieldChange}
              error={!!state.errors.toPrice.trim()}
              helperText={state.errors.toPrice}
              InputProps={{
                startAdornment: <InputAdornment>$</InputAdornment>,
              }}
            />
          </Box>
        </td>
        <td>
          <Box className="d-flex f-align-center mr-4">
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              label="Surcharge"
              name="surchargePercent"
              size="small"
              className="mb-1 mt-2"
              value={state.surchargePercent}
              onKeyDown={preventInputKeyCodes}
              onChange={handleFieldChange}
              onWheel={(event) => event.target.blur()}
              error={!!state.errors.surchargePercent.trim()}
              helperText={state.errors.surchargePercent}
              InputProps={{
                endAdornment: <InputAdornment>%</InputAdornment>,
              }}
            />
          </Box>
        </td>
      </tr>
      <tr>
        <td colspan="100%">
          <Box className="d-flex f-align-center f-justify-end">
            <Button
              variant="contained"
              color="primary"
              className="ml-3 mr-3"
              disabled={state.isLoading || !isFieldChange || validate()}
              onClick={handleSubmit}
            >
              {`${isNew ? "Create" : "Update"}`}
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </Button>
            <Button className="ml-3 mr-3" onClick={onClose}>
              Cancel
            </Button>
          </Box>
        </td>
      </tr>
    </>
  );
};

export default Form;
