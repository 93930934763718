import { http, responseFormatter } from "utils";

const download = (type, isTemplate = false) => {
  return responseFormatter(
    http.get(
      `/download/${type}${isTemplate ? "/?filter[isTemplate]=true" : ""}`,
      { setAuthHeader: true }
    )
  );
};

const bulkUploads = (data) => {
  return responseFormatter(
    http.post("/bulk-upload", data, {
      setAuthHeader: true,
    })
  );
};

const ExchangeService = {
  download,
  bulkUploads,
};

export default ExchangeService;
