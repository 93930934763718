import { Switch, Route } from "react-router-dom";
import View from "./view";
import ProfitLossReport from "./profitLossReport";
import ReportHomePage from "./home";
import IcReport from "./icReport";
import kamReports from "./kamReports";
import PDXCompanyReport from "./pdxCompanyReport";
import OnDemandReports from "./onDemand";
import KamEfficiencyReport from "./kamSubmissionReport";
import { getTokenData } from "utils";
import { ROLE } from "modules/shared/constants";

const ReportgModule = ({ match }) => {
  const currentUser = getTokenData() || {};
  const isRoleKAM = (currentUser?.role || "").toLowerCase() === ROLE.KAM;
  return (
    <Switch>
      <Route exact path={match.path} component={ReportHomePage} />
      {!isRoleKAM && (
        <>
          {" "}
          <Route
            exact
            path={`${match.path}/billing_settlement_report`}
            component={View}
          />
          <Route
            exact
            path={`${match.path}/profit_loss_report`}
            component={ProfitLossReport}
          />
          <Route exact path={`${match.path}/ic_report`} component={IcReport} />
          <Route
            exact
            path={`${match.path}/kam_report`}
            component={kamReports}
          />
          <Route
            exact
            path={`${match.path}/pdx_company_report`}
            component={PDXCompanyReport}
          />
          <Route
            exact
            path={`${match.path}/on_demand_report`}
            component={OnDemandReports}
          />
          <Route
            exact
            path={`${match.path}/schedule_submission_behavior_report`}
            component={KamEfficiencyReport}
          />
        </>
      )}
      {isRoleKAM && (
        <Route
          exact
          path={`${match.path}/billing_settlement_report`}
          component={View}
        />
      )}
      <Route
        render={(props) => (
          <>
            <div className="m-10 pt-10 d-flex f-justify-center f-align-center flex-1">
              <div>
                <h2>Uh Oh! Something is not right...</h2>
                <h4>The page you are trying to access does not exist.</h4>
                <h4>
                  Maybe you want to go to{" "}
                  <span
                    className="color-primary c-pointer"
                    onClick={() => {
                      props.history.push("/");
                    }}
                  >
                    Home
                  </span>{" "}
                  page?
                </h4>
              </div>
            </div>
          </>
        )}
      />
    </Switch>
  );
};

export default ReportgModule;
