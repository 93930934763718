import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
	closeIcon: {
		right: 5,
		top: 5,
		outline: 'none',
		boxShadow: 'none',
		'& img': {
			width: 14,
		},
	},
	title: {},
}));
