import { noop } from "shared/constants";
import Avatar from "@material-ui/core/Avatar";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";

const avatarColors = [
  "#EA1212",
  "#FF7E28",
  "#FFAC1C",
  "#6DD400",
  "#44D7B6",
  "#32C5FF",
  "#0091FF",
  "#6236FF",
  "#B620E0",
  "#F71762",
  "#D8D8D8",
  "#D8C6A7",
  "#B6C9A2",
  "#B0A496",
  "#7E7B8F",
];

const ProfileAvatar = ({
  className = "",
  userData = {},
  innerRef = null,
  onClick = noop,
}) => {
  const getUserInitials = (name = "") => {
    const nameArr = name.split(" ").slice(0, 2);
    return nameArr.map((item) => item.charAt(0)).join("");
  };

  const userInitials = getUserInitials(userData.fullName);
  const charIndex = userInitials.toUpperCase().charCodeAt(0) - 64;

  const avatarProps = {
    src: userData?.profileImageUri,
    style:
      !userData?.profileImageUri && charIndex
        ? { backgroundColor: avatarColors[charIndex % avatarColors.length] }
        : null,
    children: !userData?.profileImageUri ? (
      userInitials ? (
        userInitials.toUpperCase()
      ) : (
        <AccountCircleOutlinedIcon />
      )
    ) : null,
    ref: innerRef,
    onClick,
  };

  return <Avatar className={className} {...avatarProps} />;
};

export default ProfileAvatar;
