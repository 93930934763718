import makeStyles from "@material-ui/core/styles/makeStyles";
import SigninBackground from "assets/icons/Artwork.svg";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  orgLogo: {
    top: 22,
  },
  img: {
    maxHeight: "150px",
    maxWidth: "150px",
  },
  image: {
    backgroundImage: `url(${SigninBackground})`,
    marginTop: "64px",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "auto",
    // backgroundPosition: "center",
  },
  formWrapper: {
    backgroundColor: "#fafafa",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  signInWrapper: {
    width: "50%",
    backgroundColor: "#F2F7FA",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "rgb(255,255,255)",
      border: `2px solid ${theme.palette.divider}`,
    },
  },
  progressBtn: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default useStyles;
