import { http, responseFormatter } from "utils";

const get = (query) => {
  return responseFormatter(
    http.get(`/fuel-surcharge/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const post = (data) => {
  return responseFormatter(
    http.post("/fuel-surcharge", data, {
      setAuthHeader: true,
    })
  );
};

const put = (data) => {
  return responseFormatter(
    http.put("/fuel-surcharge", data, {
      setAuthHeader: true,
    })
  );
};

const patch = (data) => {
  return responseFormatter(
    http.patch("/fuel-surcharge", data, {
      setAuthHeader: true,
    })
  );
};

const download = (type, queryString = "") => {
  return responseFormatter(
    http.get(`/download/${type}${queryString}`, {
      setAuthHeader: true,
    })
  );
};

const bulkUploads = (data, query = "") => {
  return responseFormatter(
    http.post(`/bulk-upload${query}`, data, {
      setAuthHeader: true,
    })
  );
};

const getFuelSurchargeSchedule = (query) => {
  return responseFormatter(
    http.get(`/custom-fuel-surcharge/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const postCustomFuelSurcharge = (data) => {
  return responseFormatter(
    http.post("/custom-fuel-surcharge", data, {
      setAuthHeader: true,
    })
  );
};

const putCustomFuelSurcharge = (data) => {
  return responseFormatter(
    http.put("/custom-fuel-surcharge", data, {
      setAuthHeader: true,
    })
  );
};

const patchFuelSurchargeSchedule = (data) => {
  return responseFormatter(
    http.patch("/custom-fuel-surcharge", data, {
      setAuthHeader: true,
    })
  );
};

const deleteFuelSurchargeSchedule = (entryIds) => {
  return responseFormatter(
    http.delete(`/custom-fuel-surcharge?recordIds=${entryIds}`, {
      setAuthHeader: true,
    })
  );
};

const getDateRange = () => {
  return responseFormatter(
    http.get("/fuel-surcharge/date-range", {
      setAuthHeader: true,
    })
  );
};

const lockDateRange = (data) => {
  return responseFormatter(
    http.patch("/fuel-surcharge/lock", data, {
      setAuthHeader: true,
    })
  );
};

const saveAAAPrices = (data) => {
  return responseFormatter(
    http.post("fuel-surcharge/bulk-upload", data, {
      setAuthHeader: true,
    })
  );
};

const renameSchedule = (data) => {
  return responseFormatter(
    http.patch("/custom-fuel-surcharge/rename", data, {
      setAuthHeader: true,
    })
  );
};

const FuelSurchargeService = {
  get,
  post,
  put,
  patch,
  getFuelSurchargeSchedule,
  postCustomFuelSurcharge,
  putCustomFuelSurcharge,
  patchFuelSurchargeSchedule,
  deleteFuelSurchargeSchedule,
  download,
  bulkUploads,
  getDateRange,
  lockDateRange,
  saveAAAPrices,
  renameSchedule,
};

export default FuelSurchargeService;
