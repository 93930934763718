import { useState, useCallback, useContext } from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import SignInService from "../service";
import { fieldErrorMessageMap } from "shared/constants";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import isEmail from "validator/es/lib/isEmail";
import newOrgLogo from "shared/assets/images/pdx-logo.png";
import OrgLogo from "shared/assets/images/org-logo.png";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { AppContext } from "shared/contexts";

import useStyles from "./style";

let timeout = null;
const defaultState = {
  email: "",
  verificationPending: false,
  isSetTimeout: false,
  isLoading: false,
  errors: {
    email: " ",
  },
};

const ForgotPasswordModule = ({ match }) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);
  const { appData } = useContext(AppContext);

  const validate = useCallback(
    (field, value) => {
      let isValid = false;
      const fieldValidatorMap = {
        email: isEmail,
      };

      if (fieldValidatorMap[field]) {
        isValid = fieldValidatorMap[field](value);
      } else {
        if (!field) {
          isValid = true;
          Object.keys(fieldValidatorMap).forEach((key) => {
            if (!validate(key, state[key])) {
              isValid = false;
            }
          });
        } else {
          isValid = true;
        }
      }

      return isValid;
    },
    [state]
  );

  const handleForgotPassword = async () => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      isSetTimeout: true,
    }));
    const { error } = await SignInService.forgotPassword({
      email: state.email,
    });
    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      toast.success("Reset password link sent successfully.");
    }
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      verificationPending: !error ? true : defaultState.verificationPending,
    }));
    timeout = setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        isSetTimeout: false,
      }));
      clearTimeout(timeout);
    }, 1000 * 20);
  };

  const handleFieldChange = (event) => {
    const field = event.currentTarget.name;
    const value = event.currentTarget.value;
    let errorMessage = "";
    if (value) {
      const isValid = validate(field, value);
      if (!isValid) {
        errorMessage = fieldErrorMessageMap[field];
      }
    } else {
      errorMessage = "Required";
    }

    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item md={8} className={classes.image} />

      <Grid
        item
        sm={12}
        md={4}
        square
        component={Paper}
        elevation={6}
        className={clsx(classes.formWrapper, {
          [classes.image]: appData.isTabletView,
        })}
      >
        <Box
          className={clsx({
            [classes.orgLogo]: appData.isTabletView,
          })}
        >
          <img alt="Pdx Logo" src={newOrgLogo} />
        </Box>
        <Box className={classes.emptyDiv}></Box>
        <Box>
          {state.verificationPending ? (
            <Box
              component={appData.isTabletView && Paper}
              elevation={7}
              className={clsx(
                "d-flex flex-column f-align-center p-5 flex-1",
                classes.formContainer
              )}
            >
              <Typography component="h6" variant="h6" className="mb-3">
                Reset Password
              </Typography>
              <Typography variant="body1" className="mb-2 align-center">
                We have sent you a Reset Password Link at {state.email}.
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                className="mb-2"
              >
                Didn't receive an email from us? Click on the button below to
                resend.
              </Typography>
              <Grid container justify="center" spacing={2}>
                <Grid item xs={12} sm={5} md={5}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleForgotPassword}
                    disabled={state.isSetTimeout}
                  >
                    Re-send email
                    {state.isLoading && (
                      <CircularProgress
                        size={24}
                        className={classes.progressBtn}
                      />
                    )}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={5} md={5}>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        verificationPending: false,
                        isSetTimeout: false,
                      }));
                      clearTimeout(timeout);
                    }}
                  >
                    Go back
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box
              component={appData.isTabletView && Paper}
              elevation={7}
              className={clsx("p-6", classes.formContainer)}
            >
              <div
                className={clsx("d-flex f-align-center", {
                  [classes.backToLoginBtn]: !appData.isTabletView,
                  "p-absolute": !appData.isTabletView,
                })}
              >
                <Link className="color-link" href="/sign-in">
                  <div className={"d-flex f-align-center"}>
                    {" "}
                    <ArrowBackIcon color="primary" />
                    <Typography variant="body2">Back to Login</Typography>
                  </div>
                </Link>
              </div>
              <Typography
                component="h1"
                variant="h5"
                className="mb-4 mt-4 mt-2"
              >
                Forgot Password
              </Typography>
              <TextField
                required
                fullWidth
                name="email"
                variant="outlined"
                autoComplete="email"
                label="Email Address"
                value={state.email}
                error={!!state.errors.email.trim()}
                helperText={state.errors.email}
                autoFocus
                onChange={handleFieldChange}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                className="mt-2 mb-2"
                disabled={state.isLoading || !validate()}
                onClick={handleForgotPassword}
              >
                Send Reset Password link
                {state.isLoading && (
                  <CircularProgress size={24} className={classes.progressBtn} />
                )}
              </Button>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ForgotPasswordModule;
