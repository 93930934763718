import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  modalDimensions: {
    minWidth: "97%",
  },
  tableHeaderCell: {
    minWidth: 144,
  },
  gridPaper: {
    maxHeight: "556px",
  },
  paper: {
    maxHeight: "556px",
    overflow: "auto",
  },
  autocompleteWidth: {
    minWidth: 180,
  },
  substitueWidth: {
    width: 320,
  },
  tableHeaderwidth: {
    minWidth: 200,
    maxWidth: 200,
  },
  transferSchedule: { background: "#fce4ec" },
}));

export default useStyles;
