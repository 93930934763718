import { http, responseFormatter } from "utils";

const get = (query) => {
  return responseFormatter(
    http.get(`/daily-schedule/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getDSEntries = (query) => {
  return responseFormatter(
    http.get(`/daily-schedule/customers-with-locations${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getTransferRecords = (query) => {
  return responseFormatter(
    http.get(`/get-all-transferred-schedule/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getOriginalKam = (query) => {
  return responseFormatter(
    http.get(`/get-original-kam/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const post = (data) => {
  return responseFormatter(
    http.post("/daily-schedule", data, {
      setAuthHeader: true,
    })
  );
};
const transferSchedule = (data) => {
  return responseFormatter(
    http.post("/transfer-schedule", data, {
      setAuthHeader: true,
    })
  );
};

const deleteEntry = (entryId) => {
  return responseFormatter(
    http.delete(`/daily-schedules?recordIds=${entryId}`, {
      setAuthHeader: true,
    })
  );
};

const download = (query) => {
  return responseFormatter(
    http.get(`/download/daily-schedules/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const auditHistoryDownload = (query) => {
  return responseFormatter(
    http.get(`/audit/download/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getAuditHistory = (query) => {
  return responseFormatter(
    http.get(`audit/daily-schedule/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getHistoryDetailsByID = (entryId) => {
  return responseFormatter(
    http.get(`/audit/daily-schedule/${entryId || ""}`, {
      setAuthHeader: true,
    })
  );
};

const put = (data) => {
  return responseFormatter(
    http.put("/daily-schedule", data, {
      setAuthHeader: true,
    })
  );
};

const generateSchedule = (data) => {
  return responseFormatter(
    http.post("/generate-daily-schedule", data, {
      setAuthHeader: true,
    })
  );
};

const submitSchedule = (data) => {
  return responseFormatter(
    http.post("/submit-schedule", data, {
      setAuthHeader: true,
    })
  );
};

const forcedSubmit = (data) => {
  return responseFormatter(
    http.post("/forced-submit", data, {
      setAuthHeader: true,
    })
  );
};

const getCustomerList = () => {
  return responseFormatter(
    http.get("/customers", {
      setAuthHeader: true,
    })
  );
};

const getContractorList = () => {
  return responseFormatter(
    http.get("/individual-contractors", {
      setAuthHeader: true,
    })
  );
};
const getLocationList = (customerId) => {
  return responseFormatter(
    http.get(`/customer/${customerId}/locations`, {
      setAuthHeader: true,
    })
  );
};

const patch = (data, id) => {
  return responseFormatter(
    http.patch(
      "/daily-schedule",
      { ...data, id },
      {
        setAuthHeader: true,
      }
    )
  );
};

const getSubmissionHistory = (kamId, month, year) => {
  const timeZoneOffset = new Date().getTimezoneOffset();
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let query = `?kamId=${kamId}&month=${month}&year=${year}&timeZoneString=${timeZoneString}`;
  // let query = `?kamId=${kamId}&month=${month}&year=${year}`;
  if (timeZoneOffset >= 0) {
    query += `&offSet=${timeZoneOffset}`;
  }

  return responseFormatter(
    http.get(`kam-submission-history${query}`, {
      setAuthHeader: true,
    })
  );
};

const getKamSetting = (kamId) => {
  return responseFormatter(
    http.get(`user/${kamId}/settings`, {
      setAuthHeader: true,
    })
  );
};

const getLocalResources = () => {
  return http.get("/resources.json", { baseURL: window.location?.origin });
};

const getCustomerLocation = (query) => {
  return responseFormatter(
    http.get(`/customer/get-locations${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const revertForcedSubmit = (data) => {
  return responseFormatter(
    http.patch("/revert-forced-submit", data, {
      setAuthHeader: true,
    })
  );
};

const priceMatrix = (query) => {
  return responseFormatter(
    http.get(`/price-matrix/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const approveTransferShedule = (id) => {
  return responseFormatter(
    http.patch(`/approve-transfer-schedule`, id, {
      setAuthHeader: true,
    })
  );
};
const rejectTransferShedule = (id) => {
  return responseFormatter(
    http.patch(`/reject-transfer-schedule`, id, {
      setAuthHeader: true,
    })
  );
};

const verifyTransferRequest = (data) => {
  return responseFormatter(
    http.post("/verify-transfer-request", data, {
      setAuthHeader: true,
    })
  );
};

const DailyScheduleService = {
  get,
  getTransferRecords,
  getOriginalKam,
  post,
  delete: deleteEntry,
  priceMatrix,
  put,
  transferSchedule,
  getAuditHistory,
  download,
  auditHistoryDownload,
  getHistoryDetailsByID,
  getCustomerLocation,
  generateSchedule,
  submitSchedule,
  getCustomerList,
  getContractorList,
  getLocationList,
  patch,
  getSubmissionHistory,
  forcedSubmit,
  getKamSetting,
  getLocalResources,
  revertForcedSubmit,
  approveTransferShedule,
  rejectTransferShedule,
  verifyTransferRequest,
  getDSEntries,
};

export default DailyScheduleService;
