import { Dialog, Grid, ActionDialog } from "shared/components";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useState, useEffect, useCallback } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import useStyles from "./style";
import { VALIDATIONS } from "shared/constants";
import React from "react";
import Service from "../service";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import TextField from "@material-ui/core/TextField";
import SharedService from "modules/shared/service";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { noop } from "shared";
import { ListItemAvatar } from "@material-ui/core";
import { getUTCDateString, getDateString, validator } from "utils";

const defaultState = {
  forceSubmitData: [],
  isLoading: false,
  selectedRows: [],
  forceSubmitReason: null,
  forceSubmitReasonInputValue: null,
  isConfirming: false,
  isReasonOptionOpen: false,
  errors: {
    forceSubmitReason: "",
  },
};

let gridHelper = null;

const ForceSubmitDialog = ({
  open,
  pendingItems = [],
  forceSubmitReasonList = [],
  kamId,
  onForceSubmit = noop,
  onClose = noop,
}) => {
  const classes = useStyles({ noData: !pendingItems.length });
  const history = useHistory();

  const [state, setState] = useState(defaultState);

  const handleForceSubmit = async () => {
    // if (state.forceSubmitReason.trim() === "") {
    //   return toast.error("Reason cannot be empty.");
    // }
    const date = pendingItems
      .filter((date) => state.selectedRows.includes(date.id))
      .map((item) => item.date);

    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const reason = state.forceSubmitReason;

    const { error } = await Service.forcedSubmit({
      forcedSubmitDates: date,
      kam_id: kamId,
      reason: reason?.id,
    });

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isConfirming: false,
      }));
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      return;
    }
    toast.success("Submitted successfully.");
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      isConfirming: false,
      forceSubmitReason: null,
      selectedRows: [],
      openReasonsDialog: false,
    }));
    onForceSubmit();
    if (gridHelper) {
      gridHelper.resetSelection();
    }
  };

  useEffect(() => {
    if (!pendingItems.length) {
      handleClose();
    }
  }, [pendingItems]);

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = {
      forceSubmitReason: [{ type: VALIDATIONS.MAX_LENGTH, value: 50 }],
    };

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }
    return errorMessage;
  };

  const handleSelectionChange = useCallback((selectedRows) => {
    setState((prevState) => ({
      ...prevState,

      selectedRows,
    }));
  }, []);

  const moreActions = (row) => {
    return (
      <div className="d-flex f-align-center f-justify-end">
        <Tooltip
          title={`Create Schedule for ${getUTCDateString(row.date)}`}
          placement="top-start"
        >
          <PlaylistAddIcon
            onClick={() => {
              let rowDate = getUTCDateString(row.date).split("/").join("-");
              history.push(`/daily-schedule/day/${rowDate}/kam/${kamId || ""}`);
              onClose();
            }}
            className="ml-2 mr-2 c-pointer"
          />
        </Tooltip>
      </div>
    );
  };

  const columnConfig = [
    {
      id: "pending_dates",
      label: "Pending Dates",
      render: (row) => {
        const dateString = getUTCDateString(row.date);
        return (
          <Tooltip title={dateString || ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {dateString}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "col-edit",
      noResize: true,
      render: (row) => moreActions(row),
    },
  ];

  const handleClose = () => {
    onClose();
    setState((prevState) => ({
      ...prevState,
      forceSubmitReason: null,
      errors: {
        ...prevState.errors,
        forceSubmitReason: " ",
      },
    }));
  };

  return (
    <>
      <Dialog
        open={open}
        classes={{
          paper: classes.dialog,
        }}
        onClose={handleClose}
      >
        <Dialog.Title hasClose>
          <Typography variant="h5" color="primary">
            Pending Schedule Dates
          </Typography>
          <Typography variant="body1" className="mt-2">
            View pending schedule dates and mark them complete, if needed.
          </Typography>
        </Dialog.Title>
        <Dialog.Content className={classes.dialogContent}>
          {" "}
          {/* <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={pendingItems}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            onChange={(evt, value) => {
              setState((prevState) => ({
                ...prevState,
                forceSubmitData: value,
              }));
            }}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  color="primary"
                  // icon={icon}
                  // checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </React.Fragment>
            )}
            fullWidth
            // style={{ width: 200 }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Leave/Holidays"
                placeholder="Add your leaves."
              />
            )}
          /> */}
          <Grid
            columns={columnConfig}
            rows={pendingItems}
            actionBarConfig={null}
            isLoading={state.isFetching}
            onReady={(instance) => (gridHelper = instance)}
            onSelectionChange={handleSelectionChange}
            hasPagination={false}
          />
        </Dialog.Content>
        <Dialog.Actions>
          <div className="p-4">
            <Button
              onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  openReasonsDialog: true,
                }));
              }}
              variant="contained"
              color="primary"
              className="ml-2 mr-2"
              disabled={state.isLoading || !state.selectedRows.length}
            >
              Force Submit
              {/* {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )} */}
            </Button>
          </div>
        </Dialog.Actions>
      </Dialog>
      {state.isConfirming && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
          }}
          open={!!state.isConfirming}
          contentText="Are you sure you want to FORCE SUBMIT?"
          onConfirm={handleForceSubmit}
          onCancel={() =>
            setState((prevState) => ({ ...prevState, isConfirming: false }))
          }
          isConfirmDisabled={state.isLoading || validate()}
          positiveActionLabel={
            <>
              Confirm
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
        />
      )}
      {state.openReasonsDialog && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.reasonDialog,
            content: "h-10",
          }}
          open={!!state.openReasonsDialog}
          contentText={
            <div>
              <Autocomplete
                disableClearable
                className={"mt-4"}
                size="small"
                // freeSolo
                value={state.forceSubmitReason}
                options={forceSubmitReasonList.filter((item) => item.is_active)}
                getOptionLabel={(option) => `${option.value}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Force Submit Reason"
                    placeholder="Add your reason"
                    variant="outlined"
                    // onBlur={(evt) => {
                    //   const value = evt?.target.value;
                    //   setState((prevState) => ({
                    //     ...prevState,
                    //     forceSubmitReason: value,
                    //     // forceSubmitReasonInputValue: value,
                    //   }));
                    // }}
                    // onChange={(evt) => {
                    //   const value = evt?.target.value;
                    //   let errorMessage =
                    //     validate("forceSubmitReason", value) || " ";
                    //   setState((prevState) => ({
                    //     ...prevState,
                    //     forceSubmitReasonInputValue: value,
                    //     errors: {
                    //       ...prevState.errors,
                    //       forceSubmitReason: errorMessage,
                    //     },
                    //   }));
                    // }}
                    error={!!state.errors.forceSubmitReason.trim()}
                    helperText={state.errors.forceSubmitReason}
                  />
                )}
                onChange={(evt, value) => {
                  setState((prevState) => ({
                    ...prevState,
                    forceSubmitReason: value,
                    forceSubmitReasonInputValue: value,
                  }));
                }}
                onOpen={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isReasonOptionOpen: true,
                  }))
                }
                onClose={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isReasonOptionOpen: false,
                  }))
                }
              />
            </div>
          }
          onConfirm={handleForceSubmit}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              openReasonsDialog: false,
              forceSubmitReason: null,
              forceSubmitReasonInputValue: null,
              errors: {
                ...prevState.errors,
                forceSubmitReason: " ",
              },
            }))
          }
          isConfirmDisabled={
            !!state.errors.forceSubmitReason.trim() ||
            !state.forceSubmitReasonInputValue ||
            state.isReasonOptionOpen
          }
          positiveActionLabel={
            <>
              Confirm
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
        />
      )}
    </>
  );
};
export default ForceSubmitDialog;
