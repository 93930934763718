import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minWidth: (props) => props.paperminWidth,
    minHeight: (props) => props.paperMinHeight,
  },
  //   avatarDiv: {
  //     marginLeft:135,
  //   },
  avatar: {
    height: 150,
    width: 150,
    border: `1px solid ${theme.palette.divider}`,
  },
  profileAvatar: {
    height: 150,
    width: 150,
    border: `1px solid ${theme.palette.divider}`,
    fontSize: "5.25rem",
  },
  avatarEditIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: theme.palette.common.white,
  },
  avatarEditWrapper: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: "100%",
    border: `1px solid ${theme.palette.divider}`,
    background: theme.palette.primary.main,
  },
}));

export default useStyles;
