import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import { Dialog } from "shared/components";
import { noop } from "shared/constants";
import WarningIcon from "@material-ui/icons/Warning";
import useStyles from "./style";

const ErrorModal = ({ open = false, error = {}, onClose = noop }) => {
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paper: classes.paper,
      }}
    >
      <Dialog.Title hasClose>
        <div className="d-flex f-align-center mb-4">
          <WarningIcon className="color-error" />
          <Typography variant="body1" className="text-bold color-error ml-2">
            Error while uploading
          </Typography>
        </div>
        <Typography variant="body2">
          Following error(s) were encountered while uploading data
        </Typography>
      </Dialog.Title>
      <Dialog.Content>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Error message</TableCell>
                <TableCell>Row(s)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(error).map((key) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{(error[key] || []).join(", ")}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Dialog.Content>
    </Dialog>
  );
};

export default ErrorModal;
