import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Box,
  Avatar,
  Typography,
  Tooltip,
  Badge,
  Divider,
  Paper,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import { noop } from "shared";
import Grid from "@material-ui/core/Grid";
import useStyles from "./style";
import Service from "../service";
import { toast } from "react-toastify";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import { NOTIFICATION_DISPLAY_NAMES } from "shared/constants";

const defaultState = {
  deletingEntryIds: null,
  selectedNotifications: [],
  allSelected: false,
  selectedNotificationTypeArray: [],
};

const CollapsibleGrid = ({
  notificationType,
  notification,
  selectedNotificationType = [],
  selectedNotification = [],
  index,
  markNotificationRead = noop,
  handleSelection = noop,
  unreadCount = noop,
  fetchNotificationCount = noop,
  fetchNotifications = noop,
}) => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [state, setState] = useState(defaultState);
  const classes = useStyles();

  const toggleCollapse = (index) => {
    if (index === expandedIndex) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  const handleDelete = async (notificationId) => {
    setState((prevState) => ({
      ...prevState,
    }));

    const { error } = await Service.deleteNotification(notificationId);

    setState((prevState) => ({
      ...prevState,
      deletingEntryId: null,
      selectedNotifications: [],
      allSelected: false,
      selectedNotificationTypeArray: [],
    }));
    selectedNotificationType = [];
    selectedNotification = [];
    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      toast.success("Notification removed successfully.");
      fetchNotifications();
      fetchNotificationCount();
    }
  };

  const handleSelectAll = (event, type) => {
    const { checked } = event.currentTarget;

    let newSelectedNotifications = [...selectedNotification];

    if (checked) {
      notification.notifications.forEach((notif) => {
        if (!newSelectedNotifications.includes(notif.id)) {
          newSelectedNotifications.push(notif.id);
        }
      });
    } else {
      newSelectedNotifications = newSelectedNotifications.filter(
        (id) => !notification.notifications.some((notif) => notif.id === id)
      );
    }

    newSelectedNotifications = [...new Set(newSelectedNotifications)];

    let updatedNotificationType = [...selectedNotificationType];

    if (checked) {
      if (!updatedNotificationType.includes(type)) {
        updatedNotificationType.push(type);
      }
    } else {
      updatedNotificationType = updatedNotificationType.filter(
        (t) => t !== type
      );
    }

    handleSelection(updatedNotificationType, newSelectedNotifications);

    setState((prevState) => ({
      ...prevState,
      selectedNotificationTypeArray: updatedNotificationType,
      selectedNotifications: newSelectedNotifications,
      allSelected: checked,
    }));
  };

  const handleSelectOne = (event, notificationId) => {
    const { checked } = event.currentTarget;

    let newSelectedNotifications = [...selectedNotification];
    if (checked) {
      if (!newSelectedNotifications.includes(notificationId)) {
        newSelectedNotifications.push(notificationId);
      }
    } else {
      newSelectedNotifications = newSelectedNotifications.filter(
        (id) => id !== notificationId
      );
    }
    const selectedNotificationIds = newSelectedNotifications.filter(
      (selectedId) =>
        notification?.notifications?.some((notif) => notif?.id === selectedId)
    );

    const allSelected =
      notification?.notifications?.length === selectedNotificationIds.length;

    let updatedNotificationType = [...selectedNotificationType];
    if (allSelected) {
      if (!updatedNotificationType.includes(notification.type)) {
        updatedNotificationType.push(notification.type);
      }
    } else {
      updatedNotificationType = updatedNotificationType.filter(
        (t) => t !== notification.type
      );
    }

    handleSelection(updatedNotificationType, newSelectedNotifications);

    setState((prevState) => ({
      ...prevState,
      selectedNotificationTypeArray: updatedNotificationType,
      selectedNotifications: newSelectedNotifications,
      allSelected,
    }));
  };

  return (
    <ExpansionPanel
      key={index}
      expanded={expandedIndex === index}
      onChange={() => toggleCollapse(index)}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.summary}
      >
        <div className={classes.expansionPanel}>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.allSelected}
                onChange={(evt) => handleSelectAll(evt, notification.type)}
                onClick={(evt) => evt.stopPropagation()}
                color="primary"
              />
            }
          />
          <Typography className="d-inline-block w-100" variant="h6" noWrap>
            {NOTIFICATION_DISPLAY_NAMES[notification.type] || notification.type}
          </Typography>
          <Badge badgeContent={unreadCount} color="error" />
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid item xs={12} md={12} component={Paper} elevation={0}>
          {notification.notifications
            ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            ?.map((notification, i) => {
              const data = notification.metadata;
              return (
                <>
                  <Box
                    key={i}
                    component={Link}
                    to={{
                      pathname:
                        notificationType.path &&
                        notificationType.path(notification.metadata),
                      state: data,
                    }}
                    onClick={() => {
                      if (!notification.is_read) {
                        setState((prevState) => ({
                          ...prevState,
                          selectedNotifications: [],
                          allSelected: false,
                          selectedNotificationTypeArray: [],
                        }));
                        selectedNotificationType = [];
                        selectedNotification = [];
                        markNotificationRead(notification.id);
                        if (
                          notification.type === "room-user-removed" ||
                          notification.type === "room-user-added" ||
                          notification.type === "room-left" ||
                          notification.type === "room-name-changed"
                        ) {
                          setTimeout(() => {
                            window.location.reload();
                          }, 2000);
                        }
                      }
                    }}
                    className={clsx(
                      "d-flex f-justify-between c-pointer pl-2 pr-4 pt-2 pb-2",
                      {
                        [classes.unreadBackground]: !notification.is_read,
                        [classes.readBackground]: notification.is_read,
                      }
                    )}
                  >
                    <div className="d-flex f-align-center">
                      <Checkbox
                        checked={state.selectedNotifications.includes(
                          notification.id
                        )}
                        onChange={(e) =>
                          handleSelectOne(e, notification.id, notification.type)
                        }
                        onClick={(evt) => evt.stopPropagation()}
                        color="primary"
                      />
                      {!notification.is_read && (
                        <div
                          className={clsx("bg-primary", classes.readIndicator)}
                        />
                      )}
                      <Avatar
                        variant="square"
                        className={clsx("bg-primary ml-2", {
                          "ml-4": notification.is_read,
                        })}
                      >
                        {notificationType.icon}
                      </Avatar>
                      <div className={clsx("ml-4", classes.boxWidth)}>
                        <Typography variant="body1">
                          {notification.title}
                        </Typography>
                        <Typography variant="body2">
                          {notification.description}
                        </Typography>
                      </div>
                    </div>
                    <div className="d-flex f-align-center">
                      <div
                        className={clsx(
                          "d-flex f-align-center f-justify-end pl-4",
                          classes.relativeTimeWrapper
                        )}
                      >
                        <Typography variant="body2" className="mr-2 mb-4">
                          {notification.created_at
                            ? formatDistanceToNowStrict(
                                new Date(notification.created_at)
                              )
                            : "-"}
                        </Typography>
                      </div>
                      <div className="mb-3 ml-4">
                        <Tooltip title="Remove" placement="top-start">
                          <DeleteForeverOutlinedIcon
                            color="error"
                            onClick={(evt) => {
                              handleDelete(notification.id);
                              evt.preventDefault();
                            }}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </Box>
                  <Divider />
                </>
              );
            })}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default CollapsibleGrid;
