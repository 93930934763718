import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  datepickerWrapper: {
    width: 160,
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },

    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },

  chip: {
    margin: theme.spacing(0.5),
    height: 22,
  },
  paper: (props) => ({
    width: "38rem",
    height: "15rem",
  }),
  actionsWrapper: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  countSkeleton: {
    display: "inline-flex!important",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    height: 3,
    position: "absolute",
    top: 0,
    zIndex: 1,
    borderRadius: 8,
  },
  gridPaper: {
    minHeight: 200,
    maxHeight: "100%",
    overflow: "auto",
  },
  addressGridridPaper: {
    maxHeight: 550,
  },
  paperSpacing: {
    width: "100%",
    // minWidth: "568px",
    // maxWidth: "2000px",
    // marginTop: "14px !important",
    paddingTop: "0px !important",
    paddingBottom: "0px !important ",
    paddingRight: "0px !important",
    paddingLeft: "0px !important",
  },
  tableHeaderCell: {
    minWidth: 144,
  },
  // billingRowColor:{
  //   background: "#fed8b1",
  // },
  settlementRowColor: {
    background: "#CBC3E3",
  },
  // grossProfit:{
  //   background: "#3d5a80",
  //   color:"#ffffff !important"
  // },
  billingColor: {
    color: "#DD7A59 !important",
  },
  settlementColor: {
    color: "#9278e2 !important",
  },
  customerDropdown: {
    minWidth: 220,
    maxWidth: 1000,
  },
  headerBorder: {
    borderBottom: "2px solid black !important",
    minWidth: "148px",
  },
  fixedHeader: {
    width: "100%",
    top: "64px",
    marginBottom: "8px",
    height: "64px",
    zIndex: 1000,
    position: "sticky",
    background: "#fafafa",
  },
}));

export default useStyles;
