import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  paper: (props) => ({
    width: props.isTabletView ? "98%" : props.width,
    height: props.height,
    maxHeight: props.isTabletView ? "95%" : props.height,
  }),
  routePaper: (props) => ({
    "& .MuiDialogTitle-root": {
      paddingBottom: "0px",
    },
    width: props.isTabletView ? "98%" : props.width,
    height: props.height,
    maxHeight: props.isTabletView ? "95%" : props.height,
  }),
  chipsContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "6px",
    marginBottom: theme.spacing(1),
  },
  inputRoot: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  emailChip: {
    marginRight: "4px",
    marginBottom: "4px",
    marginTop: "6px",
  },
}));

export default useStyles;
