import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  datepickerWrapper: {
    width: "16.8rem",
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiOutlinedInput-root": {
      width: "17.4rem",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  customerDropdown: {
    width: 160,
  },
  paperSpacing: {
    marginTop: "14px !important",
    paddingTop: "0px !important",
  },
  paper: {
    "& .MuiDialogActions-root": {
      paddingBottom: "26px",
    },
    minWidth: "620px !important",
    maxWidth: "800px !important",
    minHeight: "500px !important",
    maxHeight: "800px !important",
  },
  paperSize: {
    width: 716,
  },
}));

export default useStyles;
