import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import useStyles from "./style";
import { CircularProgress } from "@material-ui/core";
import NoRecords from "assets/images/norecord.svg";
import { noop } from "shared";

const StackedBarChart = ({
  entries = [],
  isFetching = false,
  tab = "",
  isTabletView,
  handleClick = noop,
}) => {
  const classes = useStyles({ isTabletView: isTabletView });

  const handleOpenDialog = (entry = {}) => {
    handleClick(entry);
  };

  return (
    <div className={classes.barChartHeadrer}>
      {entries.length > 0 ? (
        <div
          style={{
            width: entries.length * 150,
            minWidth: "100%",
            height: isTabletView
              ? "calc(100vh - 390px)"
              : "calc(100vh - 340px)",
            position: "relative",
          }}
        >
          {isFetching && (
            <div className={classes.isFetchingData}>
              <CircularProgress size={46} className="p-absolute progress-btn" />
            </div>
          )}
          <ResponsiveContainer>
            <BarChart
              width={entries.length * 150}
              data={entries}
              margin={{ top: 20, bottom: 80, left: 50 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="kamName"
                tick={({ payload: { value }, x, y }) => (
                  <g transform={`translate(${x},${y})`}>
                    <foreignObject x={-60} width={125} height={90}>
                      <div className={classes.xAxisLabel}>{value}</div>
                    </foreignObject>
                  </g>
                )}
                interval={0}
                label={{
                  value: "KAM(S) ⟶",
                  position: "insideBottom",
                  offset: isTabletView ? -70 : -68,
                  fontWeight: "bold",
                }}
              />
              <YAxis
                label={{
                  value: "Percentage ⟶",
                  angle: -90,
                  position: "insideLeft",
                  offset: -20,
                  fontWeight: "bold",
                }}
                tickCount={11}
                domain={[0, 100]}
                yAxisId={0}
                tickFormatter={(value) => `${value}%`}
              />
              <Tooltip
                content={({ label, payload }) => {
                  const entry = entries.find((item) => item.kamName === label);

                  return (
                    <div className={classes.customTooltip}>
                      <p style={{ marginBottom: 8, fontWeight: "bold" }}>
                        KAM: {label}
                      </p>
                      {entry && (
                        <>
                          {tab === "onTime" && (
                            <>
                              <p style={{ fontWeight: "bold" }}>
                                Total daily schedules:{" "}
                                {+entry.onTimeSubmissionRoutes +
                                  +entry.byNotOnTimeSubmissionRoutes}
                              </p>
                              <p
                                style={{ fontWeight: "bold", color: "#72c658" }}
                              >
                                Total on time submission of DS:{" "}
                                {entry.onTimeSubmissionRoutes}
                              </p>
                              <p style={{ fontWeight: "bold", color: "red" }}>
                                Total delayed submission of DS:{" "}
                                {entry.byNotOnTimeSubmissionRoutes}
                              </p>
                              <p style={{ fontWeight: "bold" }}>
                                Total no schedule days: {entry.isWeekOffCount}
                              </p>
                              <p style={{ fontWeight: "bold" }}>
                                Total force submits:{" "}
                                {entry.isForcedSubmissionCount}
                              </p>
                              <p
                                style={{ fontWeight: "bold", color: "#FB6F92" }}
                              >
                                DS can be submitted on time :{" "}
                                {entry.SchedulesCanBeSubmitted}
                              </p>
                            </>
                          )}
                          {tab === "review" && (
                            <>
                              <p style={{ fontWeight: "bold" }}>
                                Total submitted schedule:{" "}
                                {entry.totalRecordCount}
                              </p>
                              <p
                                style={{ color: "#4F7942", fontWeight: "bold" }}
                              >
                                Total schedule direct to BS:{" "}
                                {entry.submittedRecordsCount}
                              </p>
                              <p
                                style={{ color: "#800080", fontWeight: "bold" }}
                              >
                                Total schedule underwent review:{" "}
                                {entry.totalUnderReviewCount}
                              </p>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  );
                }}
              />

              {tab === "review" && (
                <>
                  <Bar
                    dataKey="submission_percentage"
                    fill="#4F7942"
                    name="Submission schedule percentage"
                    barSize={60}
                    stackId="a"
                    onClick={handleOpenDialog}
                    style={{ cursor: "pointer" }}
                    isAnimationActive={false}
                  >
                    <LabelList
                      dataKey="submission_percentage"
                      position="inside"
                      formatter={(value) =>
                        value === "0.00" ? "" : `${value}%`
                      }
                      fontSize={14}
                      fontWeight="bold"
                      fill="white"
                    />
                  </Bar>
                  <Bar
                    dataKey="review_percentage"
                    fill="#800080"
                    name="Schedules underwent review percentage"
                    barSize={60}
                    stackId="a"
                    onClick={handleOpenDialog}
                    style={{ cursor: "pointer" }}
                    isAnimationActive={false}
                  >
                    <LabelList
                      dataKey="review_percentage"
                      position="inside"
                      formatter={(value) =>
                        value === "0.00" ? "" : `${value}%`
                      }
                      fontSize={14}
                      fontWeight="bold"
                      fill="white"
                    />
                  </Bar>
                </>
              )}
              {tab === "onTime" && (
                <>
                  <Bar
                    dataKey="on_time_percentage"
                    fill="#72c658"
                    name="On time submission percentage"
                    barSize={60}
                    stackId="a"
                    onClick={handleOpenDialog}
                    style={{ cursor: "pointer" }}
                    isAnimationActive={false}
                  >
                    <LabelList
                      dataKey="on_time_percentage"
                      position="inside"
                      formatter={(value) =>
                        value === "0.00" ? "" : `${value}%`
                      }
                      fontSize={14}
                      fontWeight="bold"
                      fill="white"
                    />
                  </Bar>
                  <Bar
                    dataKey="not_on_time_percentage"
                    fill="red"
                    name="Not on time submission percentage"
                    barSize={60}
                    onClick={handleOpenDialog}
                    stackId="a"
                    style={{ cursor: "pointer" }}
                    isAnimationActive={false}
                  >
                    <LabelList
                      dataKey="not_on_time_percentage"
                      position="inside"
                      formatter={(value) =>
                        value === "0.00" ? "" : `${value}%`
                      }
                      fontSize={14}
                      fontWeight="bold"
                      fill="white"
                    />
                  </Bar>
                </>
              )}
              <Legend
                align="center"
                verticalAlign="bottom"
                wrapperStyle={{
                  position: "relative",
                  paddingTop: isTabletView ? "0px" : "12px",
                  paddingLeft: "60px",
                  bottom: isTabletView ? "50px" : "54px",
                }}
                formatter={(value, entry) => {
                  if (entry.dataKey === "submission_percentage") {
                    return (
                      <strong>
                        Submission schedules % ((Total schedule direct to BS /
                        Total submitted schedule) * 100)
                      </strong>
                    );
                  } else if (entry.dataKey === "review_percentage") {
                    return (
                      <strong>
                        Schedules underwent review % ((Total schedule underwent
                        review / Total submitted schedule) * 100)
                      </strong>
                    );
                  } else if (entry.dataKey === "on_time_percentage") {
                    return (
                      <strong>
                        On time submission of DS % ((Total on time submission of
                        DS / Total daily schedules) * 100)
                      </strong>
                    );
                  } else if (entry.dataKey === "not_on_time_percentage") {
                    return (
                      <strong>
                        Delayed submission of DS % ((Total delayed submission of
                        DS / Total daily schedules) * 100)
                      </strong>
                    );
                  }
                  return value;
                }}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div
          className="d-flex f-justify-center"
          style={{ height: "26rem", position: "relative" }}
        >
          {isFetching && (
            <div className={classes.isFetchingData}>
              <CircularProgress size={46} className="p-absolute progress-btn" />
            </div>
          )}
          <img
            className={classes.noRecordsImg}
            alt="NoRecords"
            src={NoRecords}
          />
        </div>
      )}
    </div>
  );
};

export default StackedBarChart;
