import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useMemo,
} from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import Service from "../service";
import SharedService from "modules/shared/service";
import { PAGE_KEYS } from "shared/constants";
import { validator } from "utils";
import FilterListIcon from "@material-ui/icons/FilterList";
import { VALIDATIONS } from "shared/constants";
import {
  getPageConfig,
  setPageConfig,
  exportBase64ToFile,
  queryStringBuilderNew,
  updatePagination,
  updatePageConfig,
  updateLayout,
} from "utils";
import { AppContext } from "shared/contexts";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import Form from "../form";
import BulkUpload from "../bulk-upload";
import ErrorModal from "../error-modal";
import { Grid, FilterComponent, ActionDialog } from "shared/components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Skeleton from "@material-ui/lab/Skeleton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import useStyles from "./style";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { Divider } from "@material-ui/core";

let gridHelper = null;
let isFInalEntryDeleted = false;
let isFieldChange = false;

const defaultState = {
  pageSize: 100,
  pageNumber: 1,
  order: null,
  orderBy: null,
  isLoading: false,
  isDataFetched: false,
  deletingEntryIds: null,
  selectedRows: [],
  isLoadingStarted: false,
  entries: [],
  customerList: [],
  PriceMatrixList:[],
  selectedPriceMatrix:null,
  totalEntries: 0,
  customer: null,
  location: null,
  rowBeingEdited: null,
  isFetchingList: {
    customers: false,
  },
  filters: {
    customer: [],
    location: [],
    priceMatrix:[]
  },
  isFiltering: false,
  isNew: false,
  pageFilters: [],
  isFetching: false,
  isExchanging: false,
  openUpload: false,
  sheetError: null,
  name:"",
  errors:{
    name:" "
  }
};

const PriceMatrix = ({ history }) => {
  const pageConfig = getPageConfig(PAGE_KEYS.PRICE_MATRIX);
  let isGoButtonIsClicked =
    (pageConfig && pageConfig?.isGoButtonIsClicked) || false;
  const classes = useStyles();
  const { appData, updateContextData } = useContext(AppContext);
  const appDataList = useMemo(() => {
    const customers = appData.customers.filter(
      (customer) => customer.is_active
    );

    return {
      customers,
    };
  }, [appData]);

  const [state, setState] = useState({
    ...defaultState,
    pageSize: pageConfig?.pageSize || defaultState.pageSize,
    pageNumber: pageConfig?.pageNumber || defaultState.pageNumber,
    pageFilters:
      (pageConfig && pageConfig?.filters) || defaultState.pageFilters,
    customer: isGoButtonIsClicked
      ? (pageConfig && pageConfig?.customerFilter?.value) ||
        defaultState.customer
      : defaultState.customer,
    location: isGoButtonIsClicked
      ? (pageConfig && pageConfig?.locationFilter?.value) ||
        defaultState.location
      : defaultState.location,
    selectedPriceMatrix: isGoButtonIsClicked
      ? (pageConfig && pageConfig?.priceMatrixFilter?.value) ||
        defaultState.selectedPriceMatrix
      : defaultState.selectedPriceMatrix,
    filters: {
      ...defaultState.filters,
      customer: (pageConfig && pageConfig?.customerFilter?.customer) || [],
      location: (pageConfig && pageConfig?.locationFilter?.location) || [],
      priceMatrix: (pageConfig && pageConfig?.priceMatrixFilter?.priceMatrix) || [],
    },
  });

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = {
      name: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.MIN_LENGTH, value: 3 },
        { type: VALIDATIONS.MAX_LENGTH, value: 50 }
      ],
    };

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        Object.keys(fieldValidatorMap).forEach((key) => {
          const message = validate(key, state[key]);
          if (!!message) {
            errorMessage = message;
          }
        });
      });
    }
    return errorMessage;
  };

  const handleFieldChange = (evt) => {
    isFieldChange = true;
    const field = evt.currentTarget?.name || evt.target?.name;
    const value = evt.currentTarget?.value || evt.target?.value;
    let errorMessage = validate(field, value) || " ";

    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  const fetchEntries = useCallback(
    async (
      filters,
      pageSize,
      pageNumber,
      order,
      orderBy,
      pageFilters = [],
      hasLoader = true
    ) => {
      setState((prevState) => ({
        ...prevState,
        isFetching: hasLoader,
      }));

      const sortObj = { field: orderBy, order };
      
      let filtersArr = [
        ...pageFilters.map((filter) => ({
          ...filter,
          value: filter?.value?.value || filter?.value,
        })),
      ];
      // if (filters.customer.length && filters.customer[0].value) {
      //   filtersArr = [...filtersArr, ...filters.customer];
      // }
      if (filters.location.length && filters.location[0].value) {
        filtersArr = [...filtersArr, ...filters.location];
      }

      if (filters.priceMatrix.length && filters.priceMatrix[0].value) 
      {
        filtersArr = [...filtersArr, ...filters.priceMatrix];
      }
   

      const queryString = queryStringBuilderNew(
        pageSize,
        pageSize * (pageNumber - 1),
        [],
        filtersArr,
        sortObj,
        false
      );
      const { data, error } = await Service.get(queryString);
      if (error) {
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
          isLoadingStarted: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0].message : error.message
        );
      }

      setState((prevState) => ({
        ...prevState,
        isFetching: false,
        entries: data.rows || defaultState.entries,
        totalEntries: data.count,
        isDataFetched: true,
        isLoadingStarted: false,
      }));
      if (gridHelper) {
        gridHelper.resetSelection();
      }
      if (isFInalEntryDeleted && data.rows?.length === 0) {
        fetchCustomersList();
        fetchCustomerList();
        isFInalEntryDeleted = false;
      }
      return data;
    },
    []
  );

  useEffect(() => {
    setTimeout(updateLayout, 2000);
  }, [state.entries]);

  const fetchCustomerList = useCallback(async () => {
    const { data, error } = await Service.get();
    if (error) {
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      customerList: data.rows || defaultState.customerList,
    }));
  }, []);

  useEffect(() => {
    fetchCustomerList();
  }, []);

  const handleSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      order: order,
      orderBy: fieldObj.field || fieldObj.fieldName,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setPageConfig(PAGE_KEYS.PRICE_MATRIX, {
      pageSize,
    });
    setState((prevState) => ({
      ...prevState,
      pageSize,
      pageNumber: defaultState.pageNumber,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const filterConfig = useMemo(
    () => [
      {
        field: "quantity",
        fieldToDisplay: "Hour",
        operatorType: "number",
      },
      {
        field: "bill_price",
        fieldToDisplay: "Billing",
        operatorType: "number",
      },
      {
        field: "pay_price",
        fieldToDisplay: "Settlement",
        operatorType: "number",
      },
    ],
    []
  );

  const handlePageNumberChange = useCallback((pageNumber) => {
    setPageConfig(PAGE_KEYS.PRICE_MATRIX, {
      pageNumber,
    });
    setState((prevState) => ({
      ...prevState,
      pageNumber,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleSelectionChange = useCallback((selectedRows) => {
    setState((prevState) => ({
      ...prevState,
      selectedRows,
    }));
  }, []);

  const handleDelete = async (entryIds) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const { error } = await Service.deleteEntry(entryIds);

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      deletingEntryIds: null,
      selectedRows: defaultState.selectedRows,
    }));
    if (gridHelper) {
      gridHelper.resetSelection();
    }

    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      toast.success("Deleted successfully.");
      fetchEntries(
        state.filters,
        state.pageSize,
        state.pageNumber,
        state.order,
        state.orderBy,
        state.pageFilters
      );
    }
  };

  const fetchCustomersList = async (...params) => {
    try {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          customers: !appData.customers?.length,
        },
      }));

      const serviceMethod = "getCustomerList";
      const responseKey = "customers";

      const { data, error } = await SharedService[serviceMethod](...params);

      if (error) {
        throw error;
      }

      const listData = data[responseKey] || [];

      listData.forEach((item = {}) => {
        (item.customer_branches || []).forEach((branch = {}) => {
          branch.routes = [...branch.routes].sort((a, b) =>
            Intl.Collator().compare(a.name, b.name)
          );
        });
      });

      updateContextData("customers", listData);

      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          customers: false,
        },
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          customers: false,
        },
      }));
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    }
  };

  useEffect(() => {
    fetchCustomersList();
  }, []);

  const handleFilterChange = useCallback((pageFilters = []) => {
    setState((prevState) => ({
      ...prevState,
      pageFilters,
    }));
    setPageConfig(PAGE_KEYS.PRICE_MATRIX, {
      filters: pageFilters,
    });
  }, []);

  const handleDownload = async (
    filters,
    pageFilters = [],
    isTemplate = false
  ) => {
    setState((prevState) => ({
      ...prevState,
      isExchanging: !isTemplate ? true : false,
      isTemplateDownloading: isTemplate ? true : false,
    }));
    let filtersArr = [
      ...pageFilters.map((filter) => ({
        ...filter,
        value: filter?.value?.value || filter?.value,
      })),
    ];
    // if (filters.customer.length && filters.customer[0].value && !isTemplate) {
    //   filtersArr = [...filtersArr, ...filters.customer];
    // }
    if (filters.location.length && filters.location[0].value && !isTemplate) {
      filtersArr = [...filtersArr, ...filters.location];
    }
    if (filters.priceMatrix.length && filters.priceMatrix[0].value && !isTemplate) {
      filtersArr = [...filtersArr, ...filters.priceMatrix];
    }
    const queryString = !isTemplate
      ? queryStringBuilderNew(0, 0, [], filtersArr, null, false)
      : `?filter[isTemplate]=${isTemplate}`;
    const { data, error } = await Service.download(queryString);
    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      !isTemplate
        ? toast.success(`Price matrix downloaded successfully.`)
        : toast.success(`Price matrix template downloaded successfully.`);
      !isTemplate
        ? exportBase64ToFile(data, "Price Matrix")
        : exportBase64ToFile(data, "Price Matrix Template");
    }
    setState((prevState) => ({
      ...prevState,
      isExchanging: false,
      isTemplateDownloading: false,
    }));
  };

  const getPriceMatrixNames = async (customerBranchId) => {

    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        priceMatrix: true,
      },
    }));

    const { data, error } = await Service.getPriceMatrixNames(customerBranchId);
    if (error) {
      //toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          priceMatrix: false,
        },
      }));
    }
    
 
      setState((prevState) => ({
        ...prevState,
        PriceMatrixList : ((data?.priceMatrixNames[0] !== null ? data?.priceMatrixNames : defaultState.PriceMatrixList) || defaultState.PriceMatrixList),
        isFetchingList: {
          ...prevState.isFetchingList,
          priceMatrix: false,
        },
      }));
    
  }

  const handleDataLoad = async (nameOfMatrix) => {
    setState((prevState) => ({
      ...prevState,
      selectedPriceMatrix : nameOfMatrix,
      filters: {
        ...prevState.filters,
        priceMatrix: [
          {
            field: "name",
            type: "=",
            value: nameOfMatrix,
          },
        ],
      },
    }));
   await fetchEntries(
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    );
  }

 
  // const handleSubmit = async () => {
  //   setState((prevState) => ({ ...prevState, isLoading: true }));
  //   const { quantity, bill_price, pay_price } = state;

  //   Object.keys(state).map((key) => {
  //     if (NUMBER_TYPE_FIELDS.includes(key)) {
  //       state[key] = Number.isInteger(+state[key])
  //         ? (+state[key]).toFixed(0)
  //         : (+state[key]).toFixed(2);
  //     }
  //   });
  //   const serviceMethod = isNew ? "post" : "put";

  //   const dataToSend = {
  //     customer_branch_id: customerBranchId?.id,
  //     name:priceMatrixName,
  //     quantity: quantity,
  //     bill_price: bill_price,
  //     pay_price: pay_price,
  //   };

  //   if (!isNew) {
  //     dataToSend.id = entry.id;
  //   }

  //   const { error } = await Service[serviceMethod](dataToSend);

  //   if (error) {
  //     setState((prevState) => ({ ...prevState, isLoading: false }));
  //     return toast.error(
  //       Array.isArray(error) ? error[0]?.message : error.message
  //     );
  //   }
  //   toast.success(`Entry ${isNew ? "created" : "updated"} successfully.`);
  //   setState((prevState) => ({
  //     ...prevState,
  //     isLoading: false,
  //     is
  //   }));
    
  // };



useEffect(()=>{
if(state.location){
  getPriceMatrixNames(state.location.id)
}
},[state.location])


  useEffect(() => {
    if (isGoButtonIsClicked) {
     
      fetchEntries(
        state.filters,
        state.pageSize,
        state.pageNumber,
        state.order,
        state.orderBy,
        state.pageFilters
      ).then((data) => {
        updatePagination(
          data?.count,
          { pageNumber: state.pageNumber, pageSize: state.pageSize },
          (pageNumber) => {
            setState((prevState) => ({ ...prevState, pageNumber }));
          }
        );
      });
      setState((prevState) => ({
        ...prevState,
        isDataFetched: true,
      }));
    }
  }, [
    fetchEntries,
    state.order,
    state.orderBy,
    state.pageFilters,
    state.pageSize,
    state.pageNumber,
  ]);

  const moreActions = (row) => {
    return (
      <div className="d-flex f-align-center f-justify-end">
        <Tooltip title="Edit" placement="top-start">
          <EditOutlinedIcon
            className="ml-2 mr-2 c-pointer"
            onClick={() =>
              setState((prevState) => ({ ...prevState, rowBeingEdited: row }))
            }
          />
        </Tooltip>
        <Tooltip title="Delete" placement="top-start">
          <DeleteForeverOutlinedIcon
            color="error"
            className="ml-2 mr-2 c-pointer"
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                deletingEntryIds: row.id,
              }))
            }
          />
        </Tooltip>
      </div>
    );
  };

  const columnConfig = [
    {
      headerClassName: classes.editFuel,
      id: "col-edit",
      noResize: true,
      render: (row) => moreActions(row),
    },
    {
      id: "quantity",
      label: "Hour",
      field: "quantity",
      canSort: true,
      hasEllipses: true,
      render: (row) => {
        const title = row.quantity;
        return (
          <Tooltip title={title} placement="top-start">
            <Typography variant="body2">{title}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "bill_price",
      label: "Billing",
      field: "bill_price",
      startAdornment: "$",
      canSort: true,
      hasEllipses: true,
    },
    {
      id: "pay_price",
      label: "Settlement",
      field: "pay_price",
      startAdornment: "$",
      canSort: true,
      hasEllipses: true,
    },
  ];

  const branchList = useMemo(
    () =>
      (
        appDataList.customers.find(
          (customer) => customer.id === state.customer?.id
        )?.customer_branches || []
      )
        .filter((branch) => branch.is_active && !branch.is_deleted)
        .sort((a, b) => {
          const valueA = (a.location || "").toLowerCase();
          const valueB = (b.location || "").toLowerCase();
          return valueA > valueB ? 1 : valueB > valueA ? -1 : 0;
        }),
    [state.customer, appDataList.customers]
  );

  const customerListIds = new Set(
    state.customerList?.map((item) => item.customer_branch?.customer?.id)
  );

  const customerOptions = appDataList?.customers
    ?.map((customer) => ({
      ...customer,
      isInCustomerList: customerListIds?.has(customer?.id),
    }))
    ?.sort((a, b) => {
      if (a.isInCustomerList && !b.isInCustomerList) {
        return -1;
      } else if (!a.isInCustomerList && b.isInCustomerList) {
        return 1;
      } else {
        return 0;
      }
    })
    ?.sort((a, b) => b.is_active - a.is_active || 0);

  const hasPriceMatrix = customerOptions?.filter(
    (option) => option.isInCustomerList === true
  );
  const hasNoPriceMatrix = customerOptions?.filter(
    (option) => option.isInCustomerList === false
  );

  return (
    <div className="mr-5">
      <div className={clsx("mb-1 mr-10", classes.fixedHeader)}>
        <Typography variant="h4" color="primary" className=" ml-2">
          Price Matrix
          <Button
            startIcon={<ArrowBackIcon />}
            style={{ float: "right" }}
            className="mt-1 mr-2"
            variant="contained"
            color="primary"
            onClick={() => history.push("/home")}
          >
            Back To Home
          </Button>
        </Typography>
        {
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            Download, Upload and Edit Price Matrix's information.
          </Typography>
        }
      </div>
      <div className="d-flex f-align-center mt-2">
        <Autocomplete
          disableClearable
          size="small"
          className={classes.field}
          value={state.customer}
          options={
            !!hasPriceMatrix?.length
              ? [...hasPriceMatrix, { type: "divider" }, ...hasNoPriceMatrix]
              : hasNoPriceMatrix
          }
          getOptionDisabled={(option) => option.type === "divider"}
          getOptionLabel={(option) =>
            option.type === "divider" ? "" : option.name
          }
          renderInput={(params) =>
            state.isFetchingList.customers ? (
              <Skeleton variant="rect" width="100%" height="36px" />
            ) : (
              <TextField
                {...params}
                required
                label="Customer"
                variant="outlined"
              />
            )
          }
          renderOption={(option) => {
            if (option.type === "divider") {
              return (
                <Divider
                  className={clsx(classes.divider, {
                    [classes.dividerBackground]: option.type === "divider",
                  })}
                />
              );
            }
            return (
              <Typography variant="body1">
                {option.isInCustomerList
                  ? `${option.name} (Price Matrix)`
                  : option.name}
              </Typography>
            );
          }}
          getOptionSelected={(option, value) => option.name === value.name}
          classes={{
            root: classes.autocompletePaper,
            listbox: classes.autocompletePaper,
          }}
          onChange={(evt, value) => {
            setState((prevState) => ({
              ...prevState,
              customer: value,
              location: null,
              selectedPriceMatrix:defaultState.selectedPriceMatrix,
              filters: {
                ...prevState.filters,
                customer: [
                  {
                    field: "customer_name",
                    type: "=",
                    value: value?.name,
                  },
                ],
                location: [],
                priceMatrix:[]
              },
            }));
          }}
        />
        <Autocomplete
          classes={{
            root: classes.autocompletePaper,
            listbox: classes.autocompletePaper,
          }}
          size="small"
          disableClearable
          className={classes.field}
          value={state.location}
          disabled={!state.customer}
          options={branchList}
          getOptionLabel={(option) =>
            `${option.location} (${option.state?.name || ""} ,${
              option?.pdxCompany?.value || ""
            })`
          }
          renderInput={(params) =>
            state.isFetchingList.customers ? (
              <Skeleton variant="rect" width="100%" height="36px" />
            ) : (
              <TextField
                {...params}
                required
                label="Location"
                variant="outlined"
              />
            )
          }
          getOptionSelected={(option, value) =>
            `${option.location} (${option.state?.name || ""} ,${
              option?.pdxCompany?.value || ""
            })` ===
            `${value.location} (${value.state?.name || ""} ,${
              value?.pdxCompany?.value || ""
            })`
          }
          onChange={(evt, value) => {
            getPriceMatrixNames(value.id)
            setState((prevState) => ({
              ...prevState,
              location: value,
              selectedPriceMatrix: defaultState.selectedPriceMatrix,
              filters: {
                ...prevState.filters,
                location: [
                  {
                    field: "customer_branch_id",
                    type: "=",
                    value: value.id,
                  },
                ],
                priceMatrix:[],
              },
            }));
          }}
        />
            <Autocomplete
              //fullWidth
              size="small"
              className={classes.field}
              // className={clsx("w-50 mr-2", {
              //   "d-none": state.isScheduleFormOpen,
              // })}
              value={state.selectedPriceMatrix}
              options={state.PriceMatrixList}
              disabled={!state.location}
              classes={{
                root: classes.fuelSurchargeScheduleDropdown,
              }}
              renderInput={(params) =>
                state.isFetchingList.priceMatrix ? (
                  <Skeleton variant="rect" width="100%" height={40} />
                ) : (
                  <TextField
                    {...params}
                    label={state.selectedPriceMatrix ? "Price Matrix" : "Select Price Matrix"}
                    variant="outlined"
                  />
                )
              }
              onChange={(evt, value) => {
                setState((prevState) => ({
                  ...prevState,
                  selectedPriceMatrix: value,
                  filters: {
                    ...prevState.filters,
                    priceMatrix: [
                      {
                        field: "name",
                        type: "=",
                        value: value,
                      },
                    ],
                  },
                }));
              }}
            />

           
              {/* <Tooltip title="Rename Schedule" placement="top-start">
                <EditOutlinedIcon
                  className={clsx("ml-2 mr-2 c-pointer", {
                    disabled: state.isFormOpen,
                  })}
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      renameScheduleOpen: true,
                    }))
                  }
                />
              </Tooltip> */}
            
              <>
                <Typography variant="h6" className="mr-4">
                  OR
                </Typography>
                <Button
                className="mr-4 mb-2"
                  disabled={state.selectedPriceMatrix || (!state.customer || !state.location)}
                  color="primary"
                  variant="contained"
                  startIcon={<PlaylistAddIcon />}
                  onClick={() => {
                    setState((prevState) => ({
                      ...prevState,
                      isAddingNewMatrixFormOpen: true,
                      addingNewMatrix:true
                    }));
                  }}
                >
                  Add new Matrix Name
                </Button>
              </>
            
          
        <Button
          variant="contained"
          color="primary"
          className="mb-2"
          disabled={!state.location || state.isLoadingStarted || !state.selectedPriceMatrix}
          onClick={() => {
            setPageConfig(PAGE_KEYS.PRICE_MATRIX, {
              isGoButtonIsClicked: true,
              customerFilter: {
                value: state.customer,
                customer: state.filters.customer,
              },
              locationFilter: {
                value: state.location,
                location: state.filters.location,
              },
              priceMatrixFilter: {
                value: state.selectedPriceMatrix,
                priceMatrix: state.filters.priceMatrix,
              },
            });
            setState((prevState) => ({
              ...prevState,
              isLoadingStarted: true,
            }));
            fetchEntries(
              state.filters,
              state.pageSize,
              state.pageNumber,
              state.order,
              state.orderBy,
              state.pageFilters
            );
          }}
        >
          Go
          {state.isLoadingStarted && (
            <CircularProgress size={24} className="p-absolute progress-btn" />
          )}
        </Button>
      </div>
      {!state.isDataFetched ? (
        <Paper
          elevation={2}
          className={clsx(
            "d-flex f-align-center f-justify-center mt-6 p-2 pl-4 pr-4 p-relative",
            classes.paperHeight
          )}
        >
          <div className="d-flex flex-column f-justify-center f-align-center">
            <Typography variant="h6">
              Please select the Customer, Location, Price Matrix and click on Go button to
              view the price matrix.
            </Typography>
          </div>
        </Paper>
      ) : (
        <Paper
          xs={12}
          elevation={2}
          className={clsx("p-4 mt-8 h-100", classes.paperSpacing)}
        >
          <div
            className={clsx(
              "d-flex f-align-center f-justify-between pb-4 f-wrap",
              classes.actionsWrapper
            )}
          >
            <div className="d-flex f-align-center">
              <Button
                startIcon={<PlaylistAddIcon />}
                className="mr-5 mt-2"
                variant="outlined"
                disabled={
                  !!state.selectedKAM?.length &&
                  state.selectedKAM.every((kam) => !kam.is_active)
                }
                color="primary"
                onClick={() =>
                  setState((prevState) => ({ ...prevState, isNew: true }))
                }
              >
                New
              </Button>
              {!!state.selectedRows.length && (
                <Button
                  startIcon={<DeleteForeverOutlinedIcon color="error" />}
                  classes={{
                    root: "border-error mt-2",
                    label: "color-error",
                  }}
                  variant="outlined"
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      deletingEntryIds: state.selectedRows.join(","),
                    }))
                  }
                >
                  Delete
                </Button>
              )}
            </div>
            <div className="d-flex f-align-center mt-2">
              <Button
                startIcon={<CloudUploadIcon />}
                disabled={state.isExchanging || state.isTemplateDownloading}
                className="mr-4"
                color="primary"
                variant="contained"
                onClick={() =>
                  setState((prevState) => ({ ...prevState, openUpload: true }))
                }
              >
                Upload
              </Button>
              {(state.entries?.length > 0 || !!state.pageFilters?.length) && (
                <Button
                  startIcon={<CloudDownloadIcon />}
                  disabled={
                    state.isExchanging ||
                    state.isTemplateDownloading ||
                    !state.totalEntries
                  }
                  color="secondary"
                  className="mr-4"
                  variant="contained"
                  onClick={() =>
                    handleDownload(state.filters, state.pageFilters)
                  }
                >
                  Download
                  {state.isExchanging && (
                    <CircularProgress
                      color="secondary"
                      size={24}
                      className={classes.progressBtn}
                    />
                  )}
                </Button>
              )}
              <Button
                startIcon={<CloudDownloadIcon />}
                disabled={state.isTemplateDownloading}
                color="secondary"
                variant="outlined"
                onClick={() => handleDownload(state.filters, [], true)}
              >
                Download Template
                {state.isTemplateDownloading && (
                  <CircularProgress
                    color="secondary"
                    size={24}
                    className={classes.progressBtn}
                  />
                )}
              </Button>
              {(state.entries?.length > 0 || !!state.pageFilters?.length) && (
                <Tooltip placement="top-start" title="Filter">
                  <IconButton
                    color="primary"
                    className={clsx("ml-2", {
                      "bg-primary": !!state.pageFilters?.length,
                    })}
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        isFiltering: true,
                      }));
                    }}
                  >
                    <FilterListIcon
                      className={clsx({
                        "color-white": !!state.pageFilters?.length,
                      })}
                    />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip placement="top-end" title="Reset All Filters">
                <IconButton
                  color="primary"
                  onClick={() => {
                    updatePageConfig("price-matrix");
                    window.location.reload();
                  }}
                >
                  <RotateLeftIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          {state.entries?.length > 0 || !!state.pageFilters?.length ? (
            <Grid
              columns={columnConfig}
              rows={state.entries}
              actionBarConfig={null}
              isLoading={state.isFetching}
              totalRows={state.totalEntries}
              pageSize={state.pageSize}
              pageNumber={state.pageNumber}
              order={state.order}
              orderBy={state.orderBy}
              hasSelection={true}
              onPageNumberChange={handlePageNumberChange}
              onReady={(instance) => (gridHelper = instance)}
              onPageSizeChange={handlePageSizeChange}
              onSortChange={handleSortChange}
              onSelectionChange={handleSelectionChange}
              hasSelectAll={false}
              rowEvents={[
                {
                  type: "onDoubleClick",
                  handler: (row) => {
                    setState((prevState) => {
                      return {
                        ...prevState,
                        rowBeingEdited: row,
                      };
                    });
                  },
                },
              ]}
              classes={{
                gridActions: "f-justify-end",
              }}
            />
          ) : (
            <div className={classes.noPriceMatrix}>
              <Typography variant="h6">
                No Price Matrix is available for this customer on this
                particular location. Please add/upload to add price matrix for
                the given location.
              </Typography>
            </div>
          )}
          {(state.isNew || state.rowBeingEdited) && (
            <Form
              open={state.isNew || !!state.rowBeingEdited}
              entries={state.entries}
              selectedPriceMatrixName={state.selectedPriceMatrix}
              isNew={state.isNew}
              customerBranchId={state.location}
              entry={state.rowBeingEdited}
              handleDataLoad={handleDataLoad}
              onClose={(isSubmitted = false) => {
                setState((prevState) => ({
                  ...prevState,
                  rowBeingEdited: null,
                  isNew: false,
                }));
                getPriceMatrixNames(state.location.id)
                if (typeof isSubmitted === "boolean" && isSubmitted ) {
                  setState((prevState) => ({
                    ...prevState,
                    isNew: false,
                  }));
                  fetchEntries(
                    state.filters,
                    state.pageSize,
                    state.pageNumber,
                    state.order,
                    state.orderBy,
                    state.pageFilters
                  );
                  if (state.entries?.length === 0) {
                    fetchCustomersList();
                    fetchCustomerList();
                  }
                }
              }}
            />
          )}
        </Paper>
      )}
      {state.openUpload && (
        <BulkUpload
          open={state.openUpload}
          uploadKey="priceMatrix"
          onClose={(isUploaded = false) => {
            setState((prevState) => ({ ...prevState, openUpload: false }));
            if (isUploaded) {
              fetchEntries(
                state.filters,
                state.pageSize,
                state.pageNumber,
                state.order,
                state.orderBy,
                state.pageFilters
              );
            }
          }}
          onSheetError={(sheetError) =>
            setState((prevState) => ({ ...prevState, sheetError }))
          }
        />
      )}
      {!!state.sheetError && (
        <ErrorModal
          open={!!state.sheetError}
          error={state.sheetError}
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              sheetError: null,
            }))
          }
        />
      )}
      {state.isFiltering && (
        <FilterComponent
          isFiltering={state.isFiltering}
          filterConfig={filterConfig}
          appliedFilters={state.pageFilters}
          onFilterApply={handleFilterChange}
          onClose={() => {
            setState((prevState) => ({
              ...prevState,
              isFiltering: false,
            }));
          }}
        />
      )}
      {state.deletingEntryIds && (
        <ActionDialog
          classes={{
            confirm: "bg-danger",
            paper: classes.paperSize,
          }}
          open={!!state.deletingEntryIds}
          contentText="Are you sure you want to delete?"
          onConfirm={() => {
            handleDelete(state.deletingEntryIds);
            isFInalEntryDeleted = true;
          }}
          onCancel={() =>
            setState((prevState) => ({ ...prevState, deletingEntryIds: null }))
          }
          isConfirmDisabled={state.isLoading}
          positiveActionLabel={
            <>
              Delete
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
        />
      )}
       {state.isAddingNewMatrixFormOpen && (
        <ActionDialog
          classes={{
            confirm: validate() ? "bg-grey" : "bg-primary",
            paper: classes.matrixPaperSize,
          }}
          open={!!state.isAddingNewMatrixFormOpen}
          contentText={ <div className="mt-4">
         <TextField
            required
            fullWidth
            label="Price Matrix Name"
            variant="outlined"
            name="name"
            size="small"
            value={state.name}
            error={!!state.errors.name.trim()}
            helperText={state.errors.name}
            onChange={handleFieldChange}
          />
        </div>}
          onConfirm={() => {
            const name = state.name
            if(state.PriceMatrixList.includes(name)){
              return toast.error("Matrix Name aldready exist")
            }
            setState((prevState) => (
              { 
                ...prevState,
                selectedPriceMatrix: name,
                isAddingNewMatrixFormOpen: false,
                PriceMatrixList:[state.name,...prevState.PriceMatrixList],
                name:defaultState.name,
                
                filters: {
                  ...prevState.filters,
                  priceMatrix: [
                    {
                      field: "name",
                      type: "=",
                      value: name,
                    },
                  ],
                },
             }))
            toast.success("New Matrix Name added.")
          }}
          onCancel={() =>
            setState((prevState) => (
              { ...prevState,
                 isAddingNewMatrixFormOpen: false,
                 name: defaultState.name,
                 errors:{
                  name: defaultState.errors.name
                },
             }))
          }
          isConfirmDisabled={state.isLoading || validate()}
          positiveActionLabel={
            <>
              Add
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
        />
      )}
    </div>
  );
};

export default PriceMatrix;
