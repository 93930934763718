import React from "react";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  Fragment,
} from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Chip from "@material-ui/core/Chip";
import { ROLE } from "modules/shared/constants";
import {
  getTokenData,
  exportBase64ToFile,
  getPageConfig,
  setPageConfig,
  updatePageConfig,
} from "utils";
import Button from "@material-ui/core/Button";
import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import TuneIcon from "@material-ui/icons/Tune";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { AppContext } from "shared/contexts";
import { toast } from "react-toastify";
import SharedService from "modules/shared/service";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Datepicker, Grid, Dropdown, Dialog } from "shared/components";
import clsx from "clsx";
import Paper from "@material-ui/core/Paper";
import Skeleton from "@material-ui/lab/Skeleton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import FormControl from "@material-ui/core/FormControl";
import Service from "../service";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import { getDateString } from "utils";
import useStyles from "./style";
import { objectTypeAnnotation } from "@babel/types";
import { CompassCalibrationOutlined } from "@material-ui/icons";
import { borderBottom } from "@material-ui/system";
import BarChartReport from "../chartReport/barChartReport";
import LineChartReport from "../chartReport/lineChartReport";
import html2canvas from "html2canvas";
import { PAGE_KEYS } from "shared";

let test = false;
let testQuarter = false;
let columnBillingConfig = [];
let columnSettlementConfig = [];
let columnBillingSettlementConfig = [];
let totalCustomerArray = [];
let monthYearArray = [];

const REPORT_TYPE = [
  {
    label: "Billing",
    value: "billing",
  },
  {
    label: "Settlement",
    value: "settlement",
  },
];

const FILTER_TYPE = [
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Quarterly",
    value: "quarterly",
  },
  {
    label: "Yearly",
    value: "yearly",
  },
  // {
  //   label : "Custom",
  //   value:"custom"
  // },
];

const defaultState = {
  startDate: null,
  endDate: null,
  startYear: (() => {
    const startDate = new Date(new Date().getFullYear(), 0, 1);
    return getDateString(startDate);
  })(),
  endYear: getDateString(new Date()),
  startMonth: (() => {
    let startDate = new Date();
    let firstDayOfMonth = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      1
    );
    return getDateString(firstDayOfMonth);
  })(),
  endMonth: getDateString(new Date()),
  startQuarter: (() => {
    let now = new Date();
    let quarter = Math.floor(now.getMonth() / 3);
    let startDate = new Date(now.getFullYear(), quarter * 3, 1);
    return getDateString(startDate);
  })(),
  endQuarter: getDateString(new Date()),
  kam: [],
  billing: true,
  settlement: false,
  openChartModel: false,
  showGrid: true,
  isCustomerSelected: false,
  isAutocompleteFocused: false,
  billingEntries: [],
  settlementEntries: [],
  reportType: REPORT_TYPE[0],
  filterType: FILTER_TYPE[0],
  dynamicBillingColumns: {},
  dynamicSettlementColumns: {},
  dynamicBillingSettlementColumns: {},
  quarter: null,
  tabIndex: 1,
  filters: {
    kam: [],
  },
  isFetchingList: {
    kams: false,
  },
};

const IcReport = ({ history, match }) => {
  const pageConfig = getPageConfig(PAGE_KEYS.KAM_REPORT);
  let isGoButtonIsClicked =
    (pageConfig && pageConfig?.isGoButtonIsClicked) || false;
  const classes = useStyles();
  const currentUser = getTokenData() || {};
  const isRoleKAM = (currentUser?.role || "").toLowerCase() === ROLE.KAM;
  const { appData, setHeaderElements, updateContextData } =
    useContext(AppContext);
  const [state, setState] = useState({
    ...defaultState,
    reportType: !isRoleKAM ? REPORT_TYPE[0] : REPORT_TYPE[1],
    filterType: isGoButtonIsClicked
      ? (pageConfig && pageConfig?.filterType) || defaultState.filterType
      : defaultState.filterType,
    startYear: isGoButtonIsClicked
      ? (pageConfig && pageConfig?.startYear) || defaultState.startYear
      : defaultState.startYear,
    endYear: isGoButtonIsClicked
      ? (pageConfig && pageConfig?.endYear) || defaultState.endYear
      : defaultState.endYear,
    startMonth: isGoButtonIsClicked
      ? (pageConfig && pageConfig?.startMonth) || defaultState.startMonth
      : defaultState.startMonth,
    endMonth: isGoButtonIsClicked
      ? (pageConfig && pageConfig?.endMonth) || defaultState.endMonth
      : defaultState.endMonth,
    startQuarter: isGoButtonIsClicked
      ? (pageConfig && pageConfig?.startQuarter) || defaultState.startQuarter
      : defaultState.startQuarter,
    endQuarter: isGoButtonIsClicked
      ? (pageConfig && pageConfig?.endQuarter) || defaultState.endQuarter
      : defaultState.endQuarter,
    billing:
      pageConfig?.billingChecked === undefined ||
      pageConfig?.billingChecked === null
        ? defaultState.billing
        : pageConfig?.billingChecked,
    settlement: !isRoleKAM
      ? pageConfig?.settlementChecked === undefined ||
        pageConfig?.settlementChecked === null
        ? false
        : pageConfig?.settlementChecked
      : true,
    kam: isGoButtonIsClicked
      ? (pageConfig && pageConfig?.kamFilter?.value) || defaultState.kam
      : defaultState.kam,
    filters: {
      ...defaultState.filters,
      kam: isGoButtonIsClicked
        ? (pageConfig && pageConfig?.kamFilter?.kam) || []
        : [],
    },
  });

  const fetchList = async (listType, ...params) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    switch (listType) {
      case "customers":
        serviceMethod = "getCustomerList";
        responseKey = "customers";
        break;
      case "IC":
        serviceMethod = "getContractorList";
        responseKey = "contractors";
        break;
      case "users":
        serviceMethod = "getUsersList";
        responseKey = "users";
        break;
      case "pendingItems":
        serviceMethod = "getPendingItems";
        responseKey = "pendingItems";
        break;
      default:
        serviceMethod = "getCustomerList";
        responseKey = "customers";
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];
    if (listType === "users") {
      listData.map((list) => ({
        value: list,
        label: `${list.first_name || ""} ${list.last_name || ""} - ${
          list.email || ""
        }-(${list.username || ""})`,
      }));
    }
    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
  };

  const handleTabChange = useCallback((event, tabIndex) => {
    setState((prevState) => ({
      ...prevState,
      tabIndex,
    }));
  }, []);

  const TAB_LIST = [
    { id: 1, label: "Data Table" },
    { id: 2, label: "Bar Graph" },
    { id: 3, label: "Line Graph" },
  ];

  const handleDownloadChart = async () => {
    setState((prevState) => ({
      ...prevState,
      isDownloading: true,
    }));
    const chartNode = document.querySelector(".recharts-wrapper");
    const canvas = await html2canvas(chartNode, {
      scrollX: 0,
      scrollY: -window.scrollY,
    });

    const chartNode1 = document.querySelector(".recharts-default-legend");
    const canvas1 = await html2canvas(chartNode1, {
      scrollX: 0,
      scrollY: -window.scrollY,
      // width: canvas.width,
    });

    const combinedCanvas = document.createElement("canvas");
    combinedCanvas.width = canvas.width;
    combinedCanvas.height = canvas.height + canvas1.height;
    const ctx = combinedCanvas.getContext("2d");
    ctx.drawImage(canvas, 0, 0);
    ctx.drawImage(canvas1, 0, canvas.height);

    const dataUrl =
      state.tabIndex === 2 ? canvas.toDataURL() : combinedCanvas.toDataURL();

    const fileName =
      state.tabIndex === 2
        ? `${
            state.filterType.value === "monthly"
              ? "KAM monthly_bar graph"
              : state.filterType.value === "quarterly"
              ? "KAM quarterly_bar graph"
              : "KAM yearly_bar graph"
          }`
        : `${
            state.filterType.value === "monthly"
              ? "KAM monthly_line graph"
              : state.filterType.value === "quarterly"
              ? "KAM quarterly_line graph"
              : "KAM yearly_line graph"
          }`;

    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = `${fileName}.png`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setState((prevState) => ({
      ...prevState,
      isDownloading: false,
    }));
  };

  const calculateYearRange = (startYear = "", endYear = "") => {
    const endDate = endYear || new Date().getFullYear();
    let years = [];

    for (var i = startYear; i <= endDate; i++) {
      years.push(startYear);
      startYear++;
    }
    return years;
  };

  const getQuarter = (date = "") => {
    return date.getFullYear() + "Qt" + Math.ceil((date.getMonth() + 1) / 3);
  };

  const listAllQuarter = (fromDate = "", toDate = "") => {
    if (fromDate > toDate) {
      let t = toDate;
      toDate = fromDate;
      fromDate = t;
    }

    fromDate = new Date(fromDate);
    fromDate.setDate(2);

    let startQ = getQuarter(fromDate);
    let endQ = getQuarter(toDate);
    let result = [startQ];

    while (startQ !== endQ) {
      fromDate.setMonth(fromDate.getMonth() + 3);
      startQ = getQuarter(fromDate);
      result.push(startQ);
    }
    return result;
  };

  const reportQueryStringBuilder = (
    filters = {},
    filterType = {},
    reportType = {},
    kamIds = "",
    fromDate = "",
    toDate = "",
    yearsRange = "",
    selectedQuarters = []
  ) => {
    let queryString = "";
    let first = selectedQuarters?.slice(0, 1);
    let last = selectedQuarters?.slice(-1);
    let temp = [...first, ...last];
    let qtrType = temp?.toString();

    if (!filters?.kam) {
      if (filterType?.value === "monthly") {
        return (queryString = `?fromDate=${fromDate}&toDate=${toDate}&type=${reportType.value}`);
      }
      if (filterType?.value === "quarterly") {
        return (queryString = `?fromDate=${fromDate}&toDate=${toDate}&type=${reportType.value}&qrtType=${qtrType}&year=${yearsRange}`);
      }
      if (filterType?.value === "yearly") {
        return (queryString = `?fromDate=${fromDate}&toDate=${toDate}&type=${reportType.value}&year=${yearsRange}`);
      }
    } else {
      if (filterType?.value === "monthly") {
        return (queryString = `?fromDate=${fromDate}&toDate=${toDate}&type=${reportType.value}&kamIds=${kamIds}`);
      }
      if (filterType?.value === "quarterly") {
        return (queryString = `?fromDate=${fromDate}&toDate=${toDate}&type=${reportType.value}&qrtType=${qtrType}&year=${yearsRange}&kamIds=${kamIds}`);
      }
      if (filterType?.value === "yearly") {
        return (queryString = `?fromDate=${fromDate}&toDate=${toDate}&type=${reportType.value}&year=${yearsRange}&kamIds=${kamIds}`);
      }
    }

    return queryString;
  };

  const handleExport = async (
    startDate,
    endDate,
    startMonth,
    endMonth,
    startYear,
    endYear,
    startQuarter,
    endQuarter,
    reportType = {},
    filterType = {},
    filters = {}
  ) => {
    setState((prevState) => ({
      ...prevState,
      isDownloading: true,
    }));
    const fileName = `KAM ${state.filterType.value}_report`;

    const fromDate =
      filterType?.value === "yearly"
        ? startYear
        : filterType?.value === "monthly"
        ? startMonth
        : filterType?.value === "quarterly"
        ? startQuarter
        : startDate;

    const toDate =
      filterType?.value === "yearly"
        ? endYear
        : filterType?.value === "monthly"
        ? endMonth
        : filterType?.value === "quarterly"
        ? endQuarter
        : endDate;

    const fromYear = fromDate?.split("/")[2];
    const toYear = toDate?.split("/")[2];
    const yearsRange = calculateYearRange(fromYear, toYear)?.toString();
    const qtrList =
      filterType?.value === "quarterly"
        ? listAllQuarter(new Date(fromDate), new Date(toDate))
        : [];
    const selectedQuarters = qtrList.map((quarter) => quarter.slice(4));

    let kamIds = [];
    if (!filters.kam !== null) {
      kamIds = (filters?.kam || [])?.map((filter) => filter?.id)?.toString();
    }
    let query = reportQueryStringBuilder(
      filters,
      filterType,
      reportType,
      kamIds,
      fromDate,
      toDate,
      yearsRange,
      selectedQuarters
    );
    query += "&excel=true";

    const { data, error } = await Service.getKamReport(query);

    if (error) {
      toast.error(Array.isArray(error) ? error[0].message : error);
    } else {
      exportBase64ToFile(data, fileName);
    }
    setState((prevState) => ({
      ...prevState,
      isDownloading: false,
    }));
  };

  const fetchEntries = async (
    startDate,
    endDate,
    startMonth,
    endMonth,
    startYear,
    endYear,
    startQuarter,
    endQuarter,
    reportType = {},
    filterType = {},
    filters = {}
  ) => {
    console.log("hello");

    setState((prevState) => ({
      ...prevState,
      isFetching: true,
    }));

    const fromDate =
      filterType?.value === "yearly"
        ? startYear
        : filterType?.value === "monthly"
        ? startMonth
        : filterType?.value === "quarterly"
        ? startQuarter
        : startDate;

    const toDate =
      filterType?.value === "yearly"
        ? endYear
        : filterType?.value === "monthly"
        ? endMonth
        : filterType?.value === "quarterly"
        ? endQuarter
        : endDate;

    const fromYear = fromDate?.split("/")[2];
    const toYear = toDate?.split("/")[2];
    const yearsRange = calculateYearRange(fromYear, toYear)?.toString();
    const qtrList =
      filterType?.value === "quarterly"
        ? listAllQuarter(new Date(fromDate), new Date(toDate))
        : [];
    const selectedQuarters = qtrList.map((quarter) => quarter.slice(4));

    let kamIds = [];
    if (!filters.kam !== null) {
      kamIds = (filters?.kam || [])?.map((filter) => filter?.id)?.toString();
    }

    let queryString = reportQueryStringBuilder(
      filters,
      filterType,
      reportType,
      kamIds,
      fromDate,
      toDate,
      yearsRange,
      selectedQuarters
    );

    const { data, error } = await Service.getKamReport(queryString);
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetching: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }

    if (!data?.monthYearArray) {
      return setState((prevState) => ({
        ...prevState,
        isFetching: false,
        billingEntries: [],
        settlementEntries: [],
      }));
    }
    let columnKeys = data?.monthYearArray;
    let tempArray = Object.keys(data)?.map((key) => {
      const nestedObject = data[key];
      return {
        customerName: key,
        ...nestedObject,
      };
    });
    const finalArray = tempArray?.filter(
      (entry) =>
        entry?.customerName !== "monthYearArray" &&
        entry?.customerName !== "sheetType"
    );
    totalCustomerArray = tempArray?.filter(
      (entry) =>
        entry?.customerName !== "monthYearArray" &&
        entry?.customerName !== "sheetType"
    );
    monthYearArray = data?.monthYearArray.filter(
      (date) => !date.includes("Total") && !date.includes("%")
    );

    let billingColumns = columnKeys?.map((key) => {
      return {
        id: `${key}`,
        label: `${key}`,
        field: `${key}`,
        headerClassName: clsx(classes.headerBorder, "c-pointer"),
        hasEllipses: true,
        render: (row) => {
          const billAmount = !!row[key]?.monthly_bill_amount
            ? row[key]?.monthly_bill_amount
            : !!row[key]?.monthly_bill_percent
            ? row[key]?.monthly_bill_percent
            : !!row[key]?.total_bill
            ? row[key]?.total_bill
            : !!row[key]?.total_bill_percent
            ? row[key]?.total_bill_percent
            : !!row[key]?.month_bill_percent
            ? row[key]?.month_bill_percent
            : "-";

          const isPercent = key.indexOf("%") > -1 ? true : false;
          return (
            <Tooltip
              title={
                key.includes("%") ? `${billAmount} %` : `$ ${billAmount}` || "-"
              }
              placement="top-start"
            >
              <Typography
                style={{ color: "#DD7A59" }}
                //  className={classes.billingColor}
                variant="body2"
                noWrap
              >
                {!isPercent && billAmount !== "-" && "$"} {billAmount || "-"}{" "}
                {isPercent && billAmount !== "-" && "%"}
              </Typography>
            </Tooltip>
          );
        },
      };
    });

    let settlementColumns = columnKeys?.map((key) => {
      return {
        id: `${key}`,
        label: `${key}`,
        field: `${key}`,
        headerClassName: classes.headerBorder,
        hasEllipses: true,
        render: (row) => {
          const payAmount = !!row[key]?.monthly_pay_amount
            ? row[key]?.monthly_pay_amount
            : !!row[key]?.monthly_pay_percent
            ? row[key]?.monthly_pay_percent
            : !!row[key]?.total_pay
            ? row[key]?.total_pay
            : !!row[key]?.total_pay_percent
            ? row[key]?.total_pay_percent
            : !!row[key]?.month_pay_percent
            ? row[key]?.month_pay_percent
            : "-";

          const isPercent = key.indexOf("%") > -1 ? true : false;

          return (
            <Tooltip
              title={
                key.includes("%") ? `${payAmount} %` : `$ ${payAmount}` || "-"
              }
              placement="top-start"
            >
              <Typography
                style={{
                  color:
                    row.customerName === "Gross Profit" ? "#004700" : "#9278e2",
                }}
                variant="body2"
                noWrap
              >
                {!isPercent && payAmount !== "-" && "$"} {payAmount || "-"}{" "}
                {isPercent && payAmount !== "-" && "%"}
              </Typography>
            </Tooltip>
          );
        },
      };
    });

    let billingSettlementColumns = columnKeys?.map((key) => {
      return {
        id: `${key}`,
        label: `${key}`,
        field: `${key}`,
        headerClassName: clsx(classes.headerBorder, "c-pointer"),
        hasEllipses: true,
        render: (row) => {
          const billAmount = !!row[key]?.monthly_bill_amount
            ? row[key]?.monthly_bill_amount
            : !!row[key]?.monthly_bill_percent
            ? row[key]?.monthly_bill_percent
            : !!row[key]?.total_bill
            ? row[key]?.total_bill
            : !!row[key]?.total_bill_percent
            ? row[key]?.total_bill_percent
            : !!row[key]?.month_bill_percent
            ? row[key]?.month_bill_percent
            : "-";

          const payAmount = !!row[key]?.monthly_pay_amount
            ? row[key]?.monthly_pay_amount
            : !!row[key]?.monthly_pay_percent
            ? row[key]?.monthly_pay_percent
            : !!row[key]?.total_pay
            ? row[key]?.total_pay
            : !!row[key]?.total_pay_percent
            ? row[key]?.total_pay_percent
            : !!row[key]?.month_pay_percent
            ? row[key]?.month_pay_percent
            : "-";

          const isPercent = key.indexOf("%") > -1 ? true : false;
          return (
            <>
              {row.customerName === "Gross Profit" ? (
                <Tooltip>
                  <Typography> </Typography>
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    key.includes("%")
                      ? `${billAmount} %`
                      : `$ ${billAmount}` || "-"
                  }
                  placement="top-start"
                >
                  <Typography
                    style={{ color: "#DD7A59" }}
                    className={classes.billingColor}
                    variant="body2"
                    noWrap
                  >
                    {!isPercent && billAmount !== "-" && "$"}{" "}
                    {billAmount || "-"} {isPercent && billAmount !== "-" && "%"}
                  </Typography>
                </Tooltip>
              )}

              <Tooltip
                title={
                  key.includes("%") ? `${payAmount} %` : `$ ${payAmount}` || "-"
                }
                placement="top-start"
              >
                <Typography
                  style={{
                    color:
                      row.customerName === "Gross Profit"
                        ? "#004700"
                        : "#9278e2",
                  }}
                  // className={clsx(row.customerName === "Gross Profit" ?"color-primary" :classes.settlementColor,)}
                  variant="body2"
                  noWrap
                >
                  {!isPercent && payAmount !== "-" && "$"} {payAmount || "-"}{" "}
                  {isPercent && payAmount !== "-" && "%"}
                </Typography>
              </Tooltip>
            </>
          );
        },
      };
    });

    setState((prevState) => ({
      ...prevState,
      isFetching: false,
      showGrid: true,
      billingEntries: finalArray,
      settlementEntries: finalArray,
      dynamicBillingColumns: billingColumns,
      dynamicSettlementColumns: settlementColumns,
      dynamicBillingSettlementColumns: billingSettlementColumns,
    }));
  };

  if (!!state.showGrid && !!state.dynamicBillingColumns.length) {
    columnBillingConfig = [
      {
        id: "customer_name",
        label: (
          <Typography
            className={clsx(classes.billingColor, "text-bold")}
            variant="body2"
          >
            BILLING
          </Typography>
        ),
        field: "customer_name",
        hasEllipses: true,
        headerClassName: classes.headerBorder,
        showRightBorder: true,
        render: (row) => {
          return (
            <Tooltip
              title={
                !state.settlement && row.customerName === "TOTAL SALES"
                  ? "Billing Total(Includes All KAM's)"
                  : row.customerName
              }
              placement="top-start"
            >
              <Typography
                className={clsx(
                  row.customerName === "TOTAL SALES" && "text-bold",
                  row.customerName === "TOTAL SALES" && classes.billingColor
                )}
                variant="body2"
                noWrap
              >
                {!state.settlement && row.customerName === "TOTAL SALES"
                  ? "Billing Total(Includes all KAM's)"
                  : row.customerName}
              </Typography>
            </Tooltip>
          );
        },
      },
      ...state.dynamicBillingColumns,
    ];
  }

  if (!!state.showGrid && !!state.dynamicSettlementColumns.length) {
    columnSettlementConfig = [
      {
        id: "customer_name",
        label: (
          <Typography
            className={clsx(classes.settlementColor, "text-bold")}
            variant="body2"
          >
            SETTLEMENT
          </Typography>
        ),
        field: "customer_name",
        hasEllipses: true,
        headerClassName: classes.headerBorder,
        showRightBorder: true,
        render: (row) => {
          return (
            <Tooltip
              title={
                !state.billing && row.customerName === "TOTAL SALES"
                  ? "Settlement Total(Includes All KAM's)"
                  : row.customerName
              }
              placement="top-start"
            >
              <Typography
                color={
                  row.customerName === "TOTAL SALES"
                    ? ""
                    : row.customerName === "Gross Profit"
                    ? "primary"
                    : ""
                }
                className={clsx(
                  (row.customerName === "TOTAL SALES" ||
                    row.customerName === "Gross Profit") &&
                    "text-bold",
                  row.customerName === "TOTAL SALES" && classes.settlementColor
                )}
                variant="body2"
                noWrap
              >
                {!state.billing && row.customerName === "TOTAL SALES"
                  ? "Settlement Total(Includes All KAM's)"
                  : row.customerName}
              </Typography>
            </Tooltip>
          );
        },
      },
      ...state.dynamicSettlementColumns,
    ];
  }

  if (!!state.showGrid && !!state.dynamicBillingSettlementColumns.length) {
    columnBillingSettlementConfig = [
      {
        id: "customer_name",
        label: (
          <>
            <Typography
              className={clsx(classes.billingColor, "text-bold")}
              variant="body2"
            >
              BILLING
            </Typography>
            <Typography
              className={clsx(classes.settlementColor, "text-bold")}
              variant="body2"
            >
              SETTLEMENT
            </Typography>
          </>
        ),
        field: "customer_name",
        hasEllipses: true,
        headerClassName: classes.headerBorder,
        showRightBorder: true,
        render: (row) => {
          return (
            <>
              <Tooltip
                title={
                  !!state.settlement &&
                  !!state.billing &&
                  row.customerName === "TOTAL SALES"
                    ? "Billing/Settlement Total(Includes All KAM's)"
                    : row.customerName
                }
                placement="top-start"
              >
                <Typography
                  style={{
                    color: row.customerName === "TOTAL SALES" && "#DD7A59",
                  }}
                  color={row.customerName === "Gross Profit" ? "primary" : ""}
                  className={clsx(
                    (row.customerName === "TOTAL SALES" ||
                      row.customerName === "Gross Profit") &&
                      "text-bold"
                  )}
                  variant="body2"
                  noWrap
                >
                  {!!state.settlement &&
                  !!state.billing &&
                  row.customerName === "TOTAL SALES"
                    ? "Billing Total"
                    : row.customerName}
                </Typography>
              </Tooltip>
              {row.customerName === "TOTAL SALES" && (
                <>
                  <Typography
                    style={{ color: "#9278e2" }}
                    className={clsx(
                      (row.customerName === "TOTAL SALES" ||
                        row.customerName === "Gross Profit") &&
                        "text-bold"
                    )}
                    variant="body2"
                    noWrap
                  >
                    {"Settlement Total"}
                  </Typography>
                  <Typography variant="body2">
                    {"(Includes All KAM's)"}
                  </Typography>
                </>
              )}
            </>
          );
        },
      },
      ...state.dynamicBillingSettlementColumns,
    ];
  }

  useEffect(() => {
    fetchList("users");
  }, []);

  useEffect(() => {
    fetchEntries(
      state.startDate,
      state.endDate,
      state.startMonth,
      state.endMonth,
      state.startYear,
      state.endYear,
      state.startQuarter,
      state.endQuarter,
      state.reportType,
      state.filterType,
      state.filters
    );
  }, []);

  return (
    <>
      <div className={classes.fixedHeader}>
        <Typography variant="h4" color="primary" className=" ml-2">
          KAM Report
          <Button
            style={{ float: "right" }}
            className="mt-1 mr-2"
            variant="contained"
            color="primary"
            onClick={() => history.push("/home")}
            startIcon={<ArrowBackIcon />}
          >
            Back To Home
          </Button>
          <Button
            style={{ float: "right" }}
            className="mt-1 mr-2"
            variant="contained"
            color="primary"
            onClick={() => history.push("/report")}
            startIcon={<ArrowBackIcon />}
          >
            Back To Reports
          </Button>
        </Typography>
        <Typography variant="body2" style={{ marginLeft: "10px" }}>
          View KAM Report.
        </Typography>
      </div>

      <div className="d-flex flex-wrap">
        <Typography className="ml-2 mt-3" variant="body2">
          Reports Type:
        </Typography>
        <div className="ml-4 ">
          {!isRoleKAM && (
            <FormControlLabel
              label="Billing"
              size="small"
              control={
                <Checkbox
                  color="primary"
                  checked={state.billing}
                  value={state.billing}
                  onChange={(evt) => {
                    setState((prevState) => ({
                      ...prevState,
                      billing: evt.target.checked,
                    }));
                    setPageConfig(PAGE_KEYS.KAM_REPORT, {
                      billingChecked: evt.target.checked,
                    });
                  }}
                />
              }
            />
          )}
          <FormControlLabel
            label="Settlement"
            control={
              <Checkbox
                color="primary"
                checked={state.settlement}
                value={state.settlement}
                onChange={(evt) => {
                  setState((prevState) => ({
                    ...prevState,
                    settlement: evt.target.checked,
                  }));
                  setPageConfig(PAGE_KEYS.KAM_REPORT, {
                    settlementChecked: evt.target.checked,
                  });
                }}
              />
            }
          />
        </div>
      </div>
      <div className="d-flex f-justify-between f-align-start ml-2 mt-0">
        <div className="d-flex pr-8">
          <FormControl>
            <FormLabel className="color-white mb-1">.</FormLabel>
            <RadioGroup
              row
              value={state.filterType?.value}
              onChange={(evt) => {
                const filterType = FILTER_TYPE.find(
                  (type) => type.value === evt.target.value
                );
                setState((prevState) => ({
                  ...prevState,
                  filterType,
                  showGrid: false,
                  startMonth: defaultState.startMonth,
                  endMonth: defaultState.endMonth,
                  startQuarter: defaultState.startQuarter,
                  endQuarter: defaultState.endQuarter,
                  startYear: defaultState.startYear,
                  endYear: defaultState.endYear,
                }));
              }}
            >
              {FILTER_TYPE.map(({ label, value }) => (
                <FormControlLabel
                  disabled={state.isLoading}
                  value={value}
                  control={<Radio color="primary" size="small" />}
                  label={label}
                />
              ))}
            </RadioGroup>
          </FormControl>
          {state.filterType.value === "yearly" && (
            <div className="d-flex f-align-center f-wrap">
              <Datepicker
                mask
                showYearPicker
                dateFormat="yyyy"
                preventClear
                label="Start Year"
                selected={new Date(state.startYear)}
                maxDate={new Date(state.endYear)}
                classes={{
                  input: {
                    root: clsx("mr-4 mt-4", classes.datepickerWrapper),
                  },
                }}
                onChange={(startYear) => {
                  setState((prevState) => ({
                    ...prevState,
                    startYear: getDateString(startYear),
                  }));
                }}
              />
              <Datepicker
                mask
                preventClear
                showYearPicker
                dateFormat="yyyy"
                label="End Year"
                selected={new Date(state.endYear)}
                minDate={new Date(state.startYear)}
                maxDate={new Date()}
                classes={{
                  input: {
                    root: clsx("mr-4 mt-4", classes.datepickerWrapper),
                  },
                }}
                onChange={(endYear) => {
                  const actualDate = endYear;
                  const eoYear = new Date(actualDate.getFullYear(), 12, 0);
                  setState((prevState) => ({
                    ...prevState,
                    endYear: getDateString(eoYear),
                  }));
                }}
              />
            </div>
          )}
          {state.filterType.value === "monthly" && (
            <div className="d-flex f-align-center f-wrap">
              <Datepicker
                mask
                showMonthYearPicker
                dateFormat="MM/yyyy"
                preventClear
                label="Start Month"
                selected={new Date(state.startMonth)}
                maxDate={new Date(state.endMonth)}
                classes={{
                  input: {
                    root: clsx("mr-4 mt-4", classes.datepickerWrapper),
                  },
                }}
                onChange={(startMonth) => {
                  setState((prevState) => ({
                    ...prevState,
                    startMonth: getDateString(startMonth),
                  }));
                }}
              />
              <Datepicker
                mask
                preventClear
                showMonthYearPicker
                dateFormat="MM/yyyy"
                label="End Month"
                selected={new Date(state.endMonth)}
                minDate={new Date(state.startMonth)}
                maxDate={
                  !test
                    ? new Date()
                    : new Date().setMonth(new Date().getMonth() + 1)
                }
                classes={{
                  input: {
                    root: clsx("mr-4 mt-4", classes.datepickerWrapper),
                  },
                }}
                onChange={(endMonth) => {
                  test = true;
                  const endDate = endMonth;
                  const lastDateMonth = new Date(
                    endDate.getFullYear(),
                    endDate.getMonth() + 1,
                    0
                  );
                  setState((prevState) => ({
                    ...prevState,
                    endMonth: getDateString(lastDateMonth),
                  }));
                }}
              />
            </div>
          )}
          {state.filterType.value === "quarterly" && (
            <div className="d-flex f-align-center f-wrap">
              <Datepicker
                mask
                showQuarterYearPicker
                dateFormat="yyyy, QQQ"
                preventClear
                label="Start Quarter"
                selected={new Date(state.startQuarter)}
                maxDate={new Date(state.endQuarter)}
                classes={{
                  input: {
                    root: clsx("mr-4 mt-4", classes.datepickerWrapper),
                  },
                }}
                onChange={(startQuarter) => {
                  console.log(startQuarter, "start quarter");
                  setState((prevState) => ({
                    ...prevState,
                    startQuarter: getDateString(startQuarter),
                  }));
                }}
              />
              <Datepicker
                mask
                preventClear
                showQuarterYearPicker
                dateFormat="yyyy, QQQ"
                label="End Quarter"
                selected={new Date(state.endQuarter)}
                minDate={new Date(state.startQuarter)}
                maxDate={
                  !testQuarter
                    ? new Date()
                    : new Date().setMonth(new Date().getMonth() + 2)
                }
                classes={{
                  input: {
                    root: clsx("mr-4 mt-4", classes.datepickerWrapper),
                  },
                }}
                onChange={(endQuarter) => {
                  testQuarter = true;
                  let now = endQuarter;
                  let quarter = Math.floor(now.getMonth() / 3);
                  let firstDate = new Date(now.getFullYear(), quarter * 3, 1);
                  console.log(firstDate, "firstDate");
                  let endDate = new Date(
                    firstDate.getFullYear(),
                    firstDate.getMonth() + 3,
                    0
                  );
                  setState((prevState) => ({
                    ...prevState,
                    endQuarter: getDateString(endDate),
                  }));
                }}
              />
            </div>
          )}
        </div>
        <div className="d-flex f-justify-between">
          <div style={{ minWidth: "16rem", maxWidth: "20rem" }}>
            <Autocomplete
              className={clsx("mr-2 mt-4 pt-0", classes.customerDropdown)}
              size="small"
              multiple
              //value={state.customer}
              defaultValue={state.kam || []}
              classes={{
                paper: "mb-2",
              }}
              ChipProps={{
                component: (a) => {
                  return (
                    <div>
                      <Tooltip
                        placement={"top-start"}
                        title={a.children[1]?.props?.children || ""}
                      >
                        <Chip
                          size="small"
                          className={classes.chip}
                          label={
                            <Typography variant="body3" noWrap>
                              {a.children[1]?.props?.children.length > 4
                                ? a.children[1]?.props?.children.slice(0, 4) +
                                  "..."
                                : a.children[1]?.props?.children}
                            </Typography>
                          }
                          deleteIcon
                          onDelete={(evt) => {
                            a.children[2]?.props?.onClick(evt);
                          }}
                        />
                      </Tooltip>
                    </div>
                  );
                },
              }}
              options={appData?.users}
              getOptionLabel={(option) =>
                `${option.first_name || ""} ${option.last_name || ""} - ${
                  option.email || ""
                }-(${option.username || ""})`
              }
              renderInput={(params) => {
                const showAllCustomerChip =
                  !state.isCustomerSelected && !state.isAutocompleteFocused;
                if (state.isFetchingList.kams) {
                  return <Skeleton variant="rect" width="100%" height="42px" />;
                } else if (state.kam?.length === 0 || state.kam === null) {
                  return (
                    <TextField
                      {...params}
                      label="KAM"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            {showAllCustomerChip && (
                              <Chip
                                label={
                                  <Tooltip
                                    placement={"top-start"}
                                    title={"All KAM"}
                                  >
                                    <Typography variant="body3" noWrap>
                                      {"All"}
                                    </Typography>
                                  </Tooltip>
                                }
                                style={{ backgroundColor: "#e0e0e0" }}
                                className={classes.chip}
                              />
                            )}
                            {params.InputProps.startAdornment}
                          </>
                        ),
                        onFocus: () =>
                          setState((prevState) => ({
                            ...prevState,
                            isAutocompleteFocused: true,
                          })),
                        onBlur: (evt) => {
                          const value = evt.target.value;
                          if (value === "" && state.kam?.length === 0) {
                            setState((prevState) => ({
                              ...prevState,
                              isCustomerSelected: false,
                              isAutocompleteFocused: false,
                            }));
                          } else {
                            setState((prevState) => ({
                              ...prevState,
                              isAutocompleteFocused: false,
                            }));
                          }
                        },
                      }}
                    />
                  );
                } else {
                  return (
                    <TextField {...params} label="KAM" variant="outlined" />
                  );
                }
              }}
              onChange={(evt, value) => {
                setState((prevState) => ({
                  ...prevState,
                  kam: value,
                  filters: {
                    ...prevState.filters,
                    kam: value,
                  },
                  isCustomerSelected: !!value.length,
                }));
              }}
              onInputChange={(evt, value) => {
                if (value === "") {
                  setState((prevState) => ({
                    ...prevState,
                    isCustomerSelected: false,
                    isAutocompleteFocused: false,
                  }));
                } else {
                  setState((prevState) => ({
                    ...prevState,
                    isAutocompleteFocused: true,
                  }));
                }
              }}
            />
          </div>
          <div>
            <Button
              className="mt-4  mr-1 ml-2"
              variant="contained"
              color="primary"
              disabled={
                !isRoleKAM
                  ? !state.billing && !state.settlement
                  : !state.settlement
              }
              onClick={() => {
                setPageConfig(PAGE_KEYS.KAM_REPORT, {
                  isGoButtonIsClicked: true,
                  filterType: state.filterType,
                  startYear: state.startYear,
                  endYear: state.endYear,
                  startMonth: state.startMonth,
                  endMonth: state.endMonth,
                  startQuarter: state.startQuarter,
                  endQuarter: state.endQuarter,
                  kamFilter: {
                    value: state.kam,
                    kam: state.filters.kam,
                  },
                });
                fetchEntries(
                  state.startDate,
                  state.endDate,
                  state.startMonth,
                  state.endMonth,
                  state.startYear,
                  state.endYear,
                  state.startQuarter,
                  state.endQuarter,
                  state.reportType,
                  state.filterType,
                  state.filters
                );
              }}
            >
              Generate Report
            </Button>
            <Tooltip
              title={
                state.tabIndex === 1
                  ? `Export Report`
                  : state.tabIndex === 2
                  ? `Export Bar Graph`
                  : `Export Line Graph`
              }
              placement="top-start"
            >
              <IconButton
                color="primary"
                className="mt-2"
                disabled={
                  state.isDownloading ||
                  !state.showGrid ||
                  (!state.settlement && !state.billing)
                }
                onClick={() =>
                  state.tabIndex === 1
                    ? handleExport(
                        state.startDate,
                        state.endDate,
                        state.startMonth,
                        state.endMonth,
                        state.startYear,
                        state.endYear,
                        state.startQuarter,
                        state.endQuarter,
                        state.reportType,
                        state.filterType,
                        state.filters
                      )
                    : handleDownloadChart()
                }
              >
                <SystemUpdateAltIcon />
              </IconButton>
            </Tooltip>
            <Tooltip placement="top-end" title="Reset All Filters">
              <IconButton
                // disabled={pageConfig === undefined || pageConfig === null}
                color="primary"
                className="mt-2"
                onClick={() => {
                  updatePageConfig("KAM-report");
                  window.location.reload();
                }}
              >
                <RotateLeftIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      <Box>
        {!!state.showGrid &&
          !isRoleKAM &&
          ((!!state.settlement && !state.billing) ||
            (!state.settlement && !!state.billing) ||
            (!!state.settlement && !!state.billing)) && (
            <Tabs
              value={state.tabIndex}
              onChange={handleTabChange}
              indicatorColor={"#eeeeee"}
              textColor={"primary"}
              // classes={{
              //   indicator: classes.indicator,
              // }}
              style={{
                marginTop: "1.5rem",
                background: "#eeeeee",
                maxWidth: "30rem",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                border: "2px solid primary",
              }}
            >
              {TAB_LIST.map((tab) => (
                <Tab
                  value={tab.id}
                  key={tab.id}
                  name={tab.id}
                  style={{
                    paddingBottom: 0,
                    borderTop:
                      tab.id === state.tabIndex
                        ? "4px solid #80808045"
                        : "none",
                    borderLeft:
                      tab.id === state.tabIndex
                        ? "4px solid #80808045"
                        : "none",
                    borderRight:
                      tab.id === state.tabIndex
                        ? "4px solid #80808045"
                        : "none",
                  }}
                  label={
                    <Box
                      // component={Paper}
                      elevation={tab.id === state.tabIndex ? 8 : 0}
                      style={{
                        px: "42px",
                        py: "7px",
                        backgroundColor: "grey.50",
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        component={"span"}
                        color={
                          tab.id === state.tabIndex
                            ? "primary.dark"
                            : "grey.300"
                        }
                      >
                        {tab.label}
                      </Typography>
                    </Box>
                  }
                />
              ))}
            </Tabs>
          )}
        {state.tabIndex === 1 && (
          <Paper
            elevation={0}
            style={{
              borderRadius: 1,

              borderTopLeftRadius: 0,
              ...(appData.isTabletView
                ? { overflow: "auto" }
                : { width: "100%" }),
            }}
          >
            {!!state.showGrid &&
              !!state.billing &&
              !state.settlement &&
              !isRoleKAM && (
                // <div className="d-flex f-align-center f-justify-start">
                <Paper
                  elevation={2}
                  className={clsx("p-4 ", classes.paperSpacing)}
                >
                  <Grid
                    columns={columnBillingConfig}
                    rows={state.billingEntries
                      ?.filter(
                        (entry) => entry?.customerName !== "Gross Profit"
                      )
                      ?.map((entry, index) => ({
                        ...entry,
                        className: index % 2 !== 0 && classes.billingRowColor,
                      }))}
                    actionBarConfig={null}
                    hasPagination={false}
                    hasSelection={false}
                    isLoading={state.isFetching}
                    classes={{
                      container: appData.isTabletView
                        ? classes.gridPaper
                        : classes.addressGridridPaper,
                    }}
                    //onReady={(instance) => (gridHelper = instance)}
                  />
                </Paper>
              )}

            {!!state.showGrid && !!state.settlement && !state.billing && (
              <div className="d-flex f-align-center f-justify-start">
                <Paper
                  elevation={2}
                  className={clsx("p-4 ", classes.paperSpacing)}
                >
                  <Grid
                    columns={columnSettlementConfig}
                    rows={state.settlementEntries?.map((entry, index) => ({
                      ...entry,
                      className: clsx({
                        [classes.grossProfit]:
                          entry.customerName === "Gross Profit",
                      }),
                    }))}
                    actionBarConfig={null}
                    hasPagination={false}
                    hasSelection={false}
                    isLoading={state.isFetching}
                    classes={{
                      container: appData.isTabletView
                        ? classes.gridPaper
                        : classes.addressGridridPaper,
                    }}
                    //onReady={(instance) => (gridHelper = instance)}
                  />
                </Paper>
              </div>
            )}

            {!!state.showGrid && !!state.settlement && !!state.billing && (
              <div className="d-flex f-align-center f-justify-start">
                <Paper
                  elevation={2}
                  className={clsx("p-4", classes.paperSpacing)}
                >
                  <Grid
                    columns={columnBillingSettlementConfig}
                    rows={state.settlementEntries?.map((entry, index) => ({
                      ...entry,
                      className: clsx({
                        [classes.grossProfit]:
                          entry.customerName === "Gross Profit",
                      }),
                    }))}
                    actionBarConfig={null}
                    hasPagination={false}
                    hasSelection={false}
                    isLoading={state.isFetching}
                    classes={{
                      container: appData.isTabletView
                        ? classes.gridPaper
                        : classes.addressGridridPaper,
                    }}
                    //onReady={(instance) => (gridHelper = instance)}
                  />
                </Paper>
              </div>
            )}
          </Paper>
        )}
        {!!state.showGrid &&
          !isRoleKAM &&
          state.tabIndex === 2 &&
          ((!!state.settlement && !state.billing) ||
            (!state.settlement && !!state.billing) ||
            (!!state.settlement && !!state.billing)) && (
            <Paper
              elevation={0}
              style={{
                borderRadius: 1,

                borderTopLeftRadius: 0,
              }}
            >
              <BarChartReport
                display={"KAM"}
                appData={appData}
                totalCustomerArray={state.billingEntries}
                billing={state.billing}
                settlement={state.settlement}
                startMonth={state.startMonth}
                endMonth={state.endMonth}
                startYear={state.startYear}
                endYear={state.endYear}
                startQuarter={state.startQuarter}
                endQuarter={state.endQuarter}
                filterType={state.filterType}
                isFetchingList={state.isFetchingList?.kams}
                customers={appData?.users}
                filters={state.filters.kam}
                fetchEntries={fetchEntries}
                reportType={state.reportType}
                startDate={state.startDate}
                endDate={state.endDate}
                customerFilter={state.filters}
              />
            </Paper>
          )}
        {!!state.showGrid &&
          !isRoleKAM &&
          state.tabIndex === 3 &&
          ((!!state.settlement && !state.billing) ||
            (!state.settlement && !!state.billing) ||
            (!!state.settlement && !!state.billing)) && (
            <Paper
              elevation={0}
              style={{
                borderRadius: 1,

                borderTopLeftRadius: 0,
              }}
            >
              <LineChartReport
                monthYearArray={monthYearArray}
                appData={appData}
                totalCustomerArray={state.billingEntries}
                billing={state.billing}
                settlement={state.settlement}
                startMonth={state.startMonth}
                endMonth={state.endMonth}
                startYear={state.startYear}
                endYear={state.endYear}
                startQuarter={state.startQuarter}
                endQuarter={state.endQuarter}
                filterType={state.filterType}
                isFetchingList={state.isFetchingList?.kams}
                customers={appData?.users}
                filters={state.filters.kam}
                fetchEntries={fetchEntries}
                reportType={state.reportType}
                startDate={state.startDate}
                endDate={state.endDate}
                customerFilter={state.filters}
              />
            </Paper>
          )}
      </Box>
    </>
  );
};

export default IcReport;
