import { makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles({
  inputcontainer: {
    background: "#e8e8e8",
    borderRadius: "0.5rem",
    marginLeft: "11px",
    margin: "8px 6px",
    width: "15.6rem",
  },
  divider: {
    margin: "0.1em",
  },
  showHeaderBadges: {
    zIndex: 1000,
    marginRight: "12px",
  },
  chatBoxClose: { minWidth: "13px", marginRight: "6px", borderRadius: "50px" },
  chatBoxHeader: {
    background: "#80808017",
    minHeight: "40px !important",
    height: "40px",
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
  },
  groupByDateHeader: {
    width: "100%",
    textAlign: "center",
    // borderBottom: "1px solid rgb(0 0 0 / 12%)",
    lineHeight: "0.1em",
    margin: "15px 0 15px",
  },
  groupByDate: {
    background: "#4b4949",
    borderRadius: "0.3rem",
    fontSize: "x-small",
    padding: "0 10px",
    color: "#faf5f5d9",
    fontFamily: "inherit",
    fontWeight: 400,
  },
  messageTime: {
    fontSize: ".54rem",
    color: "#ffffffa6",
    fontFamily: "cursive",
    display: "flex",
    justifyContent: "flex-end",
    fontWeight: 100,
  },
  viewUsers: { padding: "5px" },
  updateGroupName: { padding: "3px 8px 3px 4px ", borderRadius: "10px" },
  groupName: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "22px",
    alignItems: "center",
  },
  changeGroupName: (props) => ({
    width: "25rem",
    height: "15rem",
  }),
  paperr: (props) => ({
    width: "37rem",
    height: "12rem",
  }),
  groupChatHeader: {
    marginLeft: "8px",
    textOverflow: "ellipsis",
    minWidth: "1px",
    maxWidth: "160px",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  paperHeight: {
    minHeight: 120,
  },
  paper: (props) => ({
    width: "30rem",
    height: "10rem",
  }),
  groupList: (props) => ({
    width: "24rem",
    height: "25rem",
    zIndex: 10000,
  }),
  zeroParticipantsList: (props) => ({
    width: "20rem",
    height: "16rem",
    zIndex: 10000,
  }),
  ListItemIcon: { minWidth: "40px" },
  groupUsers: { padding: "3px 8px 3px 4px ", borderRadius: "10px" },
  reciverName: {
    fontSize: ".7rem",
    color: "#accf53b8",
    fontFamily: "sans-serif",
    display: "flex",
    justifyContent: "flex-start",
    fontWeight: 100,
  },

  ProfileAvatar: { width: "30px", height: "30px", fontSize: "0.9rem" },
  chatMessages: {
    wordBreak: "break-all",
    display: "flex",
    overscrollBehavior: "contain",
    flexDirection: "column",
    height: "250px",
    padding: "8px 0px",
    overflow: "auto",
  },
  sended: {
    justifyContent: "flex-end",
    "&:before": {
      right: "8px",
      width: "17px",
      backgroundColor: "#569976",
      borderBottomLeftRadius: "16px 14px",
    },

    "&:after": {
      right: "0px",
      width: "14px",
      backgroundColor: "white",
      borderBottomLeftRadius: "100px",
    },
  },
  recieved: {
    justifyContent: "flex-start",
    "&:before": {
      left: "8px",
      width: "17px",
      backgroundColor: "grey",
      borderBottomRightRadius: "16px 14px",
    },

    "&:after": {
      left: "0px",
      width: "14px",
      backgroundColor: "white",
      borderBottomRightRadius: "100px",
    },
  },
  style: {
    display: "flex",
    alignItems: "center",
    // gap: "54px",
  },
  accordionChatBox0: {
    boxShadow:
      "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
    zIndex: 1250,
    position: "fixed",
    bottom: 0,
    right: 298,
    width: 300,
  },

  accordionChatBoxlenght1: {
    boxShadow:
      "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
    zIndex: 1250,
    position: "fixed",
    right: 612,
    bottom: 0,
    width: 300,
  },
  accordionChatBoxlenght2: {
    boxShadow:
      "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
    zIndex: 1250,
    position: "fixed",
    right: 924,
    bottom: 0,
    width: 300,
  },
  accordionSelectUser: {
    boxShadow:
      "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
    zIndex: 1000,
    position: "fixed",
    bottom: 0,
    right: 0,
    width: 285,
  },
  showMessages: {
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    padding: "0.3rem",
    zIndex: 1000,
    position: "fixed",
    bottom: 0,
    right: 0,
    height: "2rem",
    width: "2.5rem",
    background: "#ffffff",
    marginBottom: "0.4rem",
    borderBottomLeftRadius: "1rem",
    borderTopLeftRadius: "1rem",
    "&:hover": { background: "#eeeeee" },
  },
  showMessagesTooltip: {
    zIndex: 1000,
    position: "fixed",
    bottom: 0,
    right: 0,
    marginBottom: "0.8rem",
  },
  closeMessaging: { padding: "4px" },
  buttonText: {
    width: "0.7em",
  },
  buttonStyle: {
    borderRadius: "1rem",
    background: "#0a66c2",
    border: "0px",
    height: "30px",
    minWidth: "28px",
    width: "28px",
    marginRight: "4px",
  },
  sendedMessage: {
    backgroundColor: "#12703eb5",
    color: "white",
    padding: "4px 1rem 4px 1rem",
    borderRadius: "1rem",
    margin: "3px 14px 3px 6px",
    overflowWrap: "break-word",
    fontSize: "0.8rem",
    marginLeft: "40px",
    fontWeight: "lighter",
  },
  recievedMessage: {
    backgroundColor: "grey",
    color: "white",
    padding: "4px 1rem 4px 1rem",
    margin: "3px 6px 3px 14px",
    overflowWrap: "break-word",
    fontSize: "0.8rem",
    borderRadius: "1rem",
    marginRight: "40px",
    fontWeight: "lighter",
  },

  messagee: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      bottom: 3,
      height: "20px",
    },
    " &:after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      height: "27px",
    },
  },
  moveInNextLine: { whiteSpace: "pre-wrap", wordBreak: "break-word" },
  notSeen: { fontSize: "16px" },
  seen: {
    fontSize: "16px",
    color: "#43c1e7",
  },
});

export default useStyles;
