/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";

import useStyles from "./style";
import {
  ActionDialog,
  Datepicker,
  Dropdown,
  FilterComponent,
  Grid,
} from "shared/components";
import clsx from "clsx";
import { toast } from "react-toastify";
import Service from "../service";
import {
  getDateString,
  getPageConfig,
  getUTCDateString,
  queryStringBuilderNew,
  setPageConfig,
  updateLayout,
  updatePagination,
} from "utils";
import { AppContext, PAGE_KEYS, noop } from "shared";
import TuneIcon from "@material-ui/icons/Tune";
import { SharedService } from "modules/shared";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import GenerateInvoicePDF from "../generateInvoicePdf";
import { INVOICE_STATUS } from "modules/shared/constants";
import FilterListIcon from "@material-ui/icons/FilterList";

let gridHelper = null;

const defaultState = {
  entries: [],
  isInvoiceLoading: false,
  pdfUrl: "",
  pdxCompanyList: [],
  customerlocationList: [],
  customer: null,
  pdxCompany: null,
  route: null,
  selectedLocation: null,
  invoiceDate: null,
  pageFilters: [],
  pdfViewerDialog: false,
  filters: {
    customer: [],
    pdxCompany: [],
    route: [],
    selectedLocation: [],
    invoiceDate: [],
  },
  totalEntries: 0,
  pageSize: 100,
  pageNumber: 1,
  order: null,
  orderBy: null,
  selectedRows: [],
  isLoading: false,
  isFetchingList: {
    customers: false,
    pdxCompany: false,
    locations: false,
    route: false,
  },
  dynamicColumns: {
    select_all: { label: "Select All", value: "select_all", isSelected: true },
    serial_no: { label: "Invoice No.", value: "serial_no", isSelected: true },
    status: { label: "Status", value: "status", isSelected: true },
    invoice_date: {
      label: "Invoice Date",
      value: "invoice_date",
      isSelected: true,
    },
    pdxCompany: { label: "PDX Company", value: "pdxCompany", isSelected: true },
    customer: { label: "Customer", value: "customer", isSelected: true },
    location: { label: "Location", value: "location", isSelected: true },
    route: { label: "Route", value: "route", isSelected: true },
    terms: {
      label: "Net Terms Days",
      value: "terms",
      isSelected: true,
    },
    total_amount: {
      label: "Total Amount",
      value: "total_amount",
      isSelected: true,
    },
    discount_amt: {
      label: "Discount Amount",
      value: "discount_amt",
      isSelected: true,
    },
    total_amount_finalized: {
      label: "Finalized Amount",
      value: "total_amount_finalized",
      isSelected: true,
    },
    balance_amt: {
      label: "Balance Amount",
      value: "balance_amt",
      isSelected: true,
    },
  },
  isApprovalLoading: false,
  isApprovedDialog: false,
  approvalType: "",
};

const ApproveInvoice = ({ history, location }) => {
  const classes = useStyles();
  const { appData, updateContextData } = useContext(AppContext);
  const data = location.state || {};
  const pageConfig = getPageConfig(PAGE_KEYS.APPROVE_INVOICE);
  const [state, setState] = useState({
    ...defaultState,
    dynamicColumns: pageConfig?.dynamicColumns || defaultState.dynamicColumns,
  });

  const appDataList = useMemo(() => {
    const customers = appData.customers.filter(
      (customer) => customer.is_active
    );

    return {
      customers,
    };
  }, [appData]);

  const fetchList = async (
    listType = "customers",
    callback = noop,
    ...params
  ) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    // eslint-disable-next-line default-case
    switch (listType) {
      case "customers":
        serviceMethod = "getCustomerList";
        responseKey = "customers";
        break;
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];
    if (listType === "customers") {
      listData.map((item = {}) => {
        (item.customer_branches || []).map((branch = {}) => {
          (branch.routes || []).sort((a, b) =>
            Intl.Collator().compare(a.name, b.name)
          );
        });
      });
    }
    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
    callback(data[responseKey]);
  };

  const fetchCustomerLocation = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        locations: true,
      },
    }));
    const { data, error } = await Service.getCustomerLocation();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          locations: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      customerlocationList:
        data?.locations || defaultState.customerlocationList,
      isFetchingList: {
        ...prevState.isFetchingList,
        locations: false,
      },
    }));
  };

  const fetchPDXCompanyList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: true,
      },
    }));

    const { data, error } = await SharedService.getPDXCompanyList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          pdxCompany: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      pdxCompanyList: data?.rows || defaultState.pdxCompanyList,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: false,
      },
    }));
  };

  const getCustomerList = useMemo(() => {
    return appDataList.customers.filter((customer) =>
      customer.customer_branches.some(
        (branch) =>
          branch.pdx_company_id === state.pdxCompany?.id &&
          branch.is_active &&
          !branch.is_deleted
      )
    );
  }, [state.pdxCompany]);

  const getLocationList = Object.entries(state.customerlocationList)
    .map(([stateName, locations]) => {
      const filteredLocations = !state.customer
        ? locations
        : locations.filter((location) =>
            state.customer?.customer_branches?.some(
              (branch) => branch.location === location
            )
          );
      return filteredLocations.map((location) => ({
        label: `${location}-(${stateName})`,
        value: location,
      }));
    })
    .flat()
    .sort((a, b) => Intl.Collator().compare(a.label, b.label));

  const getRouteList = useMemo(() => {
    return (
      state.customer && state.selectedLocation
        ? state.customer?.customer_branches?.filter(
            (item) => state.selectedLocation?.value === item.location
          )
        : appDataList.customers
            ?.filter((customer) => customer.is_active)
            ?.map((item) => item.customer_branches)
            .flat()
    )
      ?.map((item) => item.routes)
      .flat()
      ?.map((route) => ({ id: route.id, name: route.name }))
      ?.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
      );
  }, [appDataList.customers, state.customer, state.selectedLocation]);

  const getInvoice = async (row, preview) => {
    setState((prevState) => ({ ...prevState, isInvoiceLoading: true }));

    let queryString = `?invoiceIds=${row?.id}`;

    const { data, error } = await Service.getAllInvoiceDetails(queryString);

    const invoiceEntries = data?.invoiceDataArray?.[0];

    if (error) {
      setState((prevState) => ({ ...prevState, isInvoiceLoading: false }));
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      return null;
    }

    const blob = GenerateInvoicePDF({
      invoiceDetails: invoiceEntries?.lineItems,
      invoiceNo: invoiceEntries.invoiceDetails.serial_no,
      terms: invoiceEntries.invoiceDetails.terms,
      termsAndCondition: invoiceEntries.invoiceDetails.terms_and_condition,
      invoiceDate: invoiceEntries.invoiceDetails.invoice_date,
      customerNotes: invoiceEntries.invoiceDetails.customer_notes,
      invoiceStatus: invoiceEntries.invoiceDetails.status,
      discount: invoiceEntries.invoiceDetails.discount_amt,
      pdxCompany:
        invoiceEntries.invoiceDetails.customer_branch?.pdxCompany?.value,
      balanceAmount: invoiceEntries?.invoiceDetails?.balance_amt,
      paidAmount: invoiceEntries?.invoiceDetails?.paid_amt,
      isOverdue: invoiceEntries?.invoiceDetails?.is_overdue,
      completePaymentDoneOn:
        invoiceEntries?.invoiceDetails?.complete_payment_done_on,
      billTo: {
        customerName: invoiceEntries.invoiceDetails.bill_to?.customerName,
        branch: invoiceEntries.invoiceDetails.bill_to?.branch,
        streetAddress: invoiceEntries.invoiceDetails.bill_to?.street_address,
        state: invoiceEntries.invoiceDetails.bill_to?.state,
        city: invoiceEntries.invoiceDetails.bill_to?.city,
        country: invoiceEntries.invoiceDetails.bill_to?.country,
        zipCode: invoiceEntries.invoiceDetails.bill_to?.zip_code,
        phone: invoiceEntries.invoiceDetails.bill_to?.phone,
        contact: invoiceEntries.invoiceDetails.bill_to?.contact,
      },
      isPreview: preview,
    });

    setState((prevState) => ({
      ...prevState,
      isInvoiceLoading: false,
      pdfUrl: blob,
    }));
    return invoiceEntries;
  };

  const fetchEntries = useCallback(
    async (filters, pageSize, pageNumber, order, orderBy, pageFilters = []) => {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const sortObj = { field: orderBy, order };

      let filtersArr = [
        ...pageFilters.map((filter) => ({
          ...filter,
          value: filter?.value?.value || filter?.value,
        })),
      ];

      if (filters.customer.length && filters.customer[0].value) {
        filtersArr = [...filtersArr, ...filters.customer];
      }

      if (filters.pdxCompany.length && filters.pdxCompany[0].value) {
        filtersArr = [...filtersArr, ...filters.pdxCompany];
      }
      if (
        filters.selectedLocation.length &&
        filters.selectedLocation[0].value
      ) {
        filtersArr = [...filtersArr, ...filters.selectedLocation];
      }
      if (filters.invoiceDate.length && filters.invoiceDate[0].value) {
        filtersArr = [...filtersArr, ...filters.invoiceDate];
      }

      filtersArr = [
        ...filtersArr,
        {
          field: "status",
          type: "=",
          value: "pending-approval",
        },
      ];

      let queryString = queryStringBuilderNew(
        pageSize,
        pageSize * (pageNumber - 1),
        [],
        filtersArr,
        sortObj
      );

      if (filters.route.length && filters.route[0]) {
        queryString += `&filter[where][and][0][or][0][route_ids][contains]=${JSON.stringify(
          filters.route
        )}`;
      }
      const { data, error } = await Service.getAllInvoice(queryString);

      if (error) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          selectedRows: defaultState.selectedRows,
        }));
        gridHelper && gridHelper.resetSelection();
        return toast.error(
          Array.isArray(error) ? error[0]?.message : error.message
        );
      }

      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        entries: data.rows || defaultState.entries,
        selectedRows: defaultState.selectedRows,
        totalEntries: data.count,
      }));
      gridHelper && gridHelper.resetSelection();
      return data;
    },
    []
  );

  const filterConfig = useMemo(
    () => [
      {
        field: "terms",
        fieldToDisplay: "Terms",
        operatorType: "number",
      },
      {
        field: "total_amount_calculated",
        fieldToDisplay: "Total Amount",
        operatorType: "number",
      },
      {
        field: "discount_amt",
        fieldToDisplay: "Discount Amount",
        operatorType: "number",
      },
      {
        field: "total_amount_finalized",
        fieldToDisplay: "Finalized Amount",
        operatorType: "number",
      },
      {
        field: "balance_amt",
        fieldToDisplay: "Balance Amount",
        operatorType: "number",
      },
    ],
    []
  );

  useEffect(() => {
    fetchEntries(
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    ).then((data) => {
      updatePagination(
        data?.count,
        { pageNumber: state.pageNumber, pageSize: state.pageSize },
        (pageNumber) => {
          setState((prevState) => ({ ...prevState, pageNumber }));
        }
      );
    });
  }, [
    fetchEntries,
    state.filters,
    state.pageSize,
    state.pageNumber,
    state.order,
    state.orderBy,
    state.viewInvoiceSummary,
    state.pageFilters,
  ]);

  useEffect(() => {
    fetchList("customers", noop);
    fetchPDXCompanyList();
    fetchCustomerLocation();
  }, [state.viewInvoiceSummary]);

  useEffect(() => {
    if (
      state.pdxCompany &&
      (state.customer || state.selectedLocation || state.route)
    ) {
      setState((prevState) => ({
        ...prevState,
        customer: null,
        selectedLocation: null,
        route: null,
        filters: {
          ...prevState.filters,
          customer: [],
          selectedLocation: [],
          route: [],
        },
      }));
    }
  }, [state.pdxCompany]);

  useEffect(() => {
    if (state.customer && (state.selectedLocation || state.route)) {
      setState((prevState) => ({
        ...prevState,
        selectedLocation: null,
        route: null,
        filters: {
          ...prevState.filters,
          selectedLocation: [],
          route: [],
        },
      }));
    }
  }, [state.customer]);

  useEffect(() => {
    setTimeout(updateLayout, 1000);
  }, [state.entries]);

  const handlePageNumberChange = useCallback((pageNumber) => {
    setState((prevState) => ({
      ...prevState,
      pageNumber,
      selectedRows: defaultState.selectedRows,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setState((prevState) => ({
      ...prevState,
      pageSize,
      pageNumber: defaultState.pageNumber,
      selectedRows: defaultState.selectedRows,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleSelectionChange = useCallback(async (selectedRows) => {
    setState((prevState) => ({
      ...prevState,
      selectedRows,
    }));
  }, []);

  const handleSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      order: order,
      orderBy: fieldObj.field || fieldObj.fieldName,
      selectedRows: defaultState.selectedRows,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleApproval = async (id = [], type) => {
    setState((prevState) => ({
      ...prevState,
      isApprovalLoading: true,
    }));

    let serviceMethod, status;

    switch (type) {
      case "approve":
        serviceMethod = "approveInvoice";
        status = "approved";

        break;
      case "reject":
        serviceMethod = "rejectInvoice";
        status = "rejected";
        break;
      default:
        serviceMethod = null;
    }

    const { error } = await Service[serviceMethod]({ invoiceIds: id });

    setState((prevState) => ({
      ...prevState,
      isApprovalLoading: false,
      isApprovedDialog: false,
    }));

    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      toast.success(`Invoice has been ${status} successfully.`);
      fetchEntries(
        state.filters,
        state.pageSize,
        state.pageNumber,
        state.order,
        state.orderBy,
        state.pageFilters
      );
      getInvoice({ id: id }, true);
    }
  };

  const handleFilterChange = useCallback((pageFilters = []) => {
    setState((prevState) => ({
      ...prevState,
      pageFilters,
      selectedRows: [],
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleApprovalDialog = (type, value) => {
    setState((prevState) => ({
      ...prevState,
      isApprovedDialog: value,
      approvalType: type,
    }));
  };

  const columnConfig = [
    {
      isHidden: !state.dynamicColumns?.serial_no?.isSelected,
      id: "serial_no",
      label: "Invoice No.",
      field: "serial_no",
      canSort: true,
      render: (row) => {
        const title = row.serial_no;
        return (
          <Tooltip title={title} placement="top-start">
            <Typography
              variant="body2"
              onClick={() => {
                getInvoice(row, true);
                setState((prevState) => ({
                  ...prevState,
                  pdfViewerDialog: true,
                }));
              }}
              style={{
                color: "#775edc",
                textDecoration: "underline",
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              {title}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.status?.isSelected,
      id: "status",
      label: "Status",
      field: "status",
      render: (row) => {
        const title = INVOICE_STATUS.find((item) => item.value === row?.status);
        return (
          <Tooltip title={title?.label ?? ""} placement="top-start">
            <Typography variant="body2">{title?.label || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.invoice_date?.isSelected,
      id: "invoice_date",
      label: "Invoice Date",
      field: "invoice_date",
      canSort: true,
      render: (row) => {
        const title = getUTCDateString(row.invoice_date);
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.pdxCompany?.isSelected,
      id: "pdxCompany",
      label: "PDX Company",
      field: "pdxCompany",
      render: (row) => {
        const title = row?.customer_branch?.pdxCompany?.value;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.customer?.isSelected,
      id: "customer",
      label: "Customer",
      field: "customer",
      render: (row) => {
        const title = row?.customer_branch?.customer?.name;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.location?.isSelected,
      id: "location",
      label: "Location",
      field: "location",
      render: (row) => {
        const title = row?.customer_branch?.location;

        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.route?.isSelected,
      id: "route",
      label: "Route(s)",
      field: "route",
      render: (row) => {
        const routes = row?.RoutesDetails ?? [];
        const title = routes.map((ele) => ele?.name)?.join(", ");
        return (
          <Tooltip title={title || ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.terms?.isSelected,
      id: "terms",
      label: "Net Terms Days",
      field: "terms",
      canSort: true,
      render: (row) => {
        const title = row.terms;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.total_amount?.isSelected,
      id: "total_amount_calculated",
      label: "Total Amount",
      field: "total_amount_calculated",
      canSort: true,
      render: (row) => {
        const title = `$${row.total_amount_calculated || "0.00"}`;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.discount_amt?.isSelected,
      id: "discount_amt",
      label: "Discount Amount",
      field: "discount_amt",
      canSort: true,
      render: (row) => {
        const title = `$${row.discount_amt || "0.00"}`;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.total_amount_finalized?.isSelected,
      id: "total_amount_finalized",
      label: "Finalized Amount",
      field: "total_amount_finalized",
      canSort: true,
      render: (row) => {
        const title = `$${row.total_amount_finalized || "0.00"}`;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.balance_amt?.isSelected,
      id: "balance_amt",
      label: "Balance Amount",
      field: "balance_amt",
      canSort: true,
      render: (row) => {
        const title = row.balance_amt;
        return (
          <Tooltip title={`$${title || "0.00"}`} placement="top-start">
            <Typography variant="body2">{`$${title || "0.00"}`}</Typography>
          </Tooltip>
        );
      },
    },
  ];

  const handleViewerClose = () => {
    setState((prevState) => ({ ...prevState, pdfViewerDialog: false }));
  };

  const LoadingOverlay = ({ message }) => (
    <div className={classes.overlayStyles}>
      <div className={classes.messageStyles}>
        <CircularProgress color="primary" />
        <Typography variant="h6" color="primary" style={{ marginTop: "16px" }}>
          {message}
        </Typography>
      </div>
    </div>
  );

  return (
    <>
      <div className="mr-5">
        <div className={classes.fixedHeader}>
          <Typography variant="h4" color="primary" className=" ml-2">
            Approve/Reject Invoices
            <Button
              style={{ float: "right" }}
              className="mt-1 mr-2"
              variant="contained"
              color="primary"
              onClick={() => history.push("/home")}
              startIcon={<ArrowBackIcon />}
            >
              Back To Home
            </Button>
            <Button
              style={{ float: "right" }}
              className="mt-1 mr-2"
              variant="contained"
              color="primary"
              onClick={() => history.push("/invoice")}
              startIcon={<ArrowBackIcon />}
            >
              Back To Invoice
            </Button>
          </Typography>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            Select to approve/reject invoices.
          </Typography>
        </div>
        {!state.viewInvoiceSummary && (
          <>
            <div className="d-flex f-justify-between f-align-center">
              <div className="d-flex f-wrap">
                <div className="mt-4 mr-4">
                  <Datepicker
                    mask
                    label="Invoice Date"
                    selected={state.invoiceDate}
                    maxDate={new Date()}
                    placement={"bottom-start"}
                    classes={{
                      datepickerWrapper: classes.scheduleDateWrapper,
                      input: {
                        root: classes.datepickerWrapper,
                      },
                    }}
                    isClearable
                    onChange={(value) => {
                      setState((prevState) => ({
                        ...prevState,
                        invoiceDate: value,
                        filters: {
                          ...prevState.filters,
                          invoiceDate: [
                            {
                              field: "invoice_date",
                              type: "=",
                              value: getDateString(value),
                            },
                          ],
                        },
                      }));
                    }}
                  />
                </div>
                <div className="mr-2">
                  <Autocomplete
                    fullWidth
                    className={clsx("mr-4 mt-4", classes.customerDropdown)}
                    size="small"
                    value={state.pdxCompany}
                    classes={{
                      root: classes.autocompleteFilters,
                    }}
                    options={state.pdxCompanyList}
                    getOptionLabel={(option) => option.value || ""}
                    renderInput={(params) =>
                      state.isFetchingList.pdxCompany ? (
                        <Skeleton variant="rect" width="100%" height="42px" />
                      ) : (
                        <TextField
                          {...params}
                          label="PDX Company"
                          variant="outlined"
                        />
                      )
                    }
                    onChange={(evt, value) => {
                      setState((prevState) => ({
                        ...prevState,
                        pdxCompany: value,
                        filters: {
                          ...prevState.filters,
                          pdxCompany: [
                            {
                              field: "pdx_company_name",
                              type: "=",
                              value: value?.value,
                            },
                          ],
                        },
                      }));
                    }}
                  />
                </div>
                <div className="mr-2">
                  <Autocomplete
                    fullWidth
                    className={clsx("mr-4 mt-4", classes.customerDropdown)}
                    size="small"
                    value={state.customer}
                    classes={{
                      root: classes.autocompleteFilters,
                    }}
                    options={
                      !state.pdxCompany ? appData.customers : getCustomerList
                    }
                    getOptionLabel={({ name }) => name}
                    renderInput={(params) =>
                      state.isFetchingList.customers ? (
                        <Skeleton variant="rect" width="100%" height="42px" />
                      ) : (
                        <TextField
                          {...params}
                          label="Customer"
                          variant="outlined"
                        />
                      )
                    }
                    onChange={(evt, value) => {
                      setState((prevState) => ({
                        ...prevState,
                        customer: value,
                        filters: {
                          ...prevState.filters,
                          customer: [
                            {
                              field: "customer_name",
                              type: "=",
                              value: value?.name,
                            },
                          ],
                        },
                      }));
                    }}
                  />
                </div>
                <div className={clsx("mr-2")}>
                  <Autocomplete
                    classes={{
                      root: classes.autocompleteFilters,
                    }}
                    size="small"
                    fullWidth
                    className={clsx("mr-4 mt-4", classes.customerDropdown)}
                    value={state.selectedLocation}
                    options={getLocationList}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) =>
                      state.isFetchingList.locations ? (
                        <Skeleton variant="rect" width="100%" height="42px" />
                      ) : (
                        <TextField
                          {...params}
                          label="Location"
                          variant="outlined"
                        />
                      )
                    }
                    getOptionSelected={(option, value) =>
                      option.label === value.label
                    }
                    onChange={(evt, value) => {
                      setState((prevState) => ({
                        ...prevState,
                        selectedLocation: value,
                        filters: {
                          ...prevState.filters,
                          selectedLocation: [
                            {
                              field: "location",
                              type: "=",
                              value: value?.value,
                            },
                          ],
                        },
                      }));
                    }}
                  />
                </div>
                <div className={clsx("mr-2")}>
                  <Autocomplete
                    fullWidth
                    className={clsx("mr-4 mt-4", classes.customerDropdown)}
                    size="small"
                    classes={{
                      root: classes.autocompleteFilters,
                    }}
                    value={state.route}
                    options={getRouteList}
                    getOptionLabel={(option) => option?.name || option || ""}
                    renderInput={(params) =>
                      state.isFetchingList.customers ? (
                        <Skeleton variant="rect" width="100%" height="42px" />
                      ) : (
                        <TextField
                          {...params}
                          label="Route"
                          variant="outlined"
                        />
                      )
                    }
                    onChange={(evt, value) => {
                      setState((prevState) => ({
                        ...prevState,
                        route: value,
                        filters: {
                          ...prevState.filters,
                          route: [value?.id],
                        },
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
            <Paper
              elevation={2}
              className={clsx("p-4 mt-10", classes.paperSpacing)}
            >
              <div
                className={clsx(
                  "d-flex f-align-center f-justify-between p-2 pl-4 pr-4",
                  classes.actionsWrapper
                )}
              >
                <div className="d-flex f-align-center">
                  {!!state.selectedRows.length && (
                    <>
                      <Button
                        variant="outlined"
                        style={{
                          color: "#f44336",
                          borderColor: "#f44336",
                        }}
                        className={classes.iconButton}
                        onClick={() => handleApprovalDialog("reject", true)}
                      >
                        Reject
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.iconButton}
                        onClick={() => handleApprovalDialog("approve", true)}
                      >
                        Approve
                      </Button>
                    </>
                  )}
                </div>

                <div className="d-flex f-align-center">
                  <Dropdown
                    classes={{
                      paper: classes.tunePaper,
                    }}
                    disablePortal={false}
                    remainOpen
                    isMultiSelect
                    placement="bottom-end"
                    options={Object.keys(state.dynamicColumns).map(
                      (key) => state.dynamicColumns[key]
                    )}
                    customToggle={({ anchorRef, onClick }) => (
                      <Tooltip
                        title="Show/Hide column(s)"
                        placement="top-start"
                      >
                        <IconButton
                          color="primary"
                          ref={anchorRef}
                          onClick={onClick}
                        >
                          <TuneIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    onChange={(options) => {
                      const isSelectAll = options.some(
                        (item) => item.value === "select_all"
                      );
                      const isCurrentSelectAll =
                        state.dynamicColumns?.select_all?.isSelected;

                      const isClickedOnSelectAll =
                        isSelectAll !== isCurrentSelectAll;

                      const isAllOptionSelected =
                        Object.keys(state.dynamicColumns).length - 1 ===
                        options.filter((item) => item.value !== "select_all")
                          .length;
                      const updatedDynamicColumns = Object.keys(
                        state.dynamicColumns
                      ).reduce((acc, key) => {
                        const isSelected = isClickedOnSelectAll
                          ? isSelectAll
                          : key === "select_all"
                          ? isAllOptionSelected
                          : !!options.some((item) => item.value === key);

                        return {
                          ...acc,
                          [key]: {
                            ...state.dynamicColumns[key],
                            isSelected,
                          },
                        };
                      }, {});
                      setPageConfig(PAGE_KEYS.VIEW_INVOICE, {
                        dynamicColumns: updatedDynamicColumns,
                      });
                      setState((prevState) => ({
                        ...prevState,
                        dynamicColumns: {
                          ...prevState.dynamicColumns,
                          ...updatedDynamicColumns,
                        },
                      }));
                    }}
                  />
                  <Tooltip placement="top-start" title="Filter">
                    <IconButton
                      color="primary"
                      className={clsx("ml-2", {
                        "bg-primary": !!state.pageFilters?.length,
                      })}
                      onClick={() => {
                        setState((prevState) => ({
                          ...prevState,
                          isFiltering: true,
                        }));
                      }}
                    >
                      <FilterListIcon
                        className={clsx({
                          "color-white": !!state.pageFilters?.length,
                        })}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              <Grid
                columns={columnConfig}
                rows={state.entries}
                actionBarConfig={null}
                hasSelectAll={false}
                isLoading={state.isLoading}
                totalRows={state.totalEntries}
                onReady={(instance) => (gridHelper = instance)}
                pageSize={state.pageSize}
                pageNumber={state.pageNumber}
                order={state.order}
                orderBy={state.orderBy}
                onPageNumberChange={handlePageNumberChange}
                onPageSizeChange={handlePageSizeChange}
                onSelectionChange={handleSelectionChange}
                onSortChange={handleSortChange}
                classes={{
                  container: classes.addressGridridPaper,
                }}
              />
            </Paper>
          </>
        )}
        {state.pdfViewerDialog && (
          <Dialog
            onClose={handleViewerClose}
            aria-labelledby="customized-dialog-title"
            open={state.pdfViewerDialog}
            classes={{ paper: classes.paper }}
          >
            <DialogContent dividers>
              {state.isInvoiceLoading ? (
                <LoadingOverlay message="Processing, please wait..." />
              ) : (
                <iframe
                  type="application/pdf"
                  src={`${state.pdfUrl}#toolbar=0`}
                  title="Invoice PDF"
                  width="1150px"
                  height="700px"
                  style={{ border: "none" }}
                  // onLoad={handleIframeLoad}
                ></iframe>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleViewerClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {state.isApprovedDialog && (
          <ActionDialog
            classes={{
              confirm: `${
                state.approvalType === "reject" ? "bg-danger" : "bg-primary"
              }`,
              paper: classes.paperSize,
            }}
            open={state.isApprovedDialog}
            contentText={`Are you sure you want to ${state.approvalType} ?`}
            onConfirm={() =>
              handleApproval(state.selectedRows, state.approvalType)
            }
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                isApprovedDialog: false,
              }))
            }
            isConfirmDisabled={state.isApprovalLoading}
            positiveActionLabel={
              <>
                {state.approvalType}
                {state.isApprovalLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}
      </div>

      {state.isFiltering && (
        <FilterComponent
          isFiltering={state.isFiltering}
          filterConfig={filterConfig}
          appliedFilters={state.pageFilters}
          onFilterApply={handleFilterChange}
          onClose={() => {
            setState((prevState) => ({
              ...prevState,
              isFiltering: false,
            }));
          }}
        />
      )}
    </>
  );
};

export default ApproveInvoice;
