import { createRef, useState } from "react";
import clsx from "clsx";
import { noop } from "shared/constants";
import GridLoader from "./loader";
import Checkbox from "@material-ui/core/Checkbox";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import useStyles from "./style";

let collapsedRows = [];

const defaultState = {
  open: false,
  order: null,
  orderBy: null,
  selectedRows: [],
};

const Grid = ({
  columns = [],
  boxborder = null,
  tableHeadRef = null,
  rows = [],
  hasSelection = true,
  isBillingAndSettelementReviewRowSelected = false,
  isLoading = false,
  classes: passedClasses = {},
  selectedRef = null,
  rowEvents = noop,
  onReady = noop,
  onSelectionChange = noop,
  handleRowOpen = noop,
  multiScroll = false,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);
  const columnRefs = [];

  const handleSelectVisibleChange = (event) => {
    const isChecked = event.currentTarget.checked;
    let selectedRows = [];
    if (isChecked) {
      selectedRows = rows?.sortedRows
        .filter((row) => !row.preventSelection)
        .map((row) => row.id);
    }

    onSelectionChange(selectedRows);
    setState((prevState) => ({
      ...prevState,
      selectedRows,
    }));
  };

  const createSelectionHandler = (rowId) => {
    return (event) => {
      handleSelection(rowId, event);
    };
  };

  const handleSelection = (rowId) => {
    setState((prevState) => {
      const existingSelectionIndex = prevState.selectedRows.indexOf(rowId);
      let selectedRows = prevState.selectedRows.slice();
      if (existingSelectionIndex === -1) {
        isBillingAndSettelementReviewRowSelected
          ? selectedRows.push(rowId, rowId)
          : selectedRows.push(rowId);
      } else {
        isBillingAndSettelementReviewRowSelected
          ? (selectedRows = selectedRows?.filter((id) => id !== rowId))
          : selectedRows.splice(existingSelectionIndex, 1);
      }

      onSelectionChange(selectedRows);

      return {
        ...prevState,
        selectedRows,
      };
    });
  };

  const createSortHandler = (property) => (event) => {
    const isAsc = state.orderBy === property && state.order === "asc";
    setState((prevState) => ({
      ...prevState,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    }));
  };

  const descendingComparator = (a, b, orderBy) => {
    const splittedOrderBy = (orderBy || "").split(".");
    [...Array(splittedOrderBy.length)].map((item, index) => {
      a = a && a[splittedOrderBy[index]];
      b = b && b[splittedOrderBy[index]];
    });

    if (a === null && b !== null) return 1;
    if (a !== null && b === null) return -1;
    if (a === null && b === null) return 0;

    if (b < a) {
      return -1;
    }
    if (b > a) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (rows, comparator) => {
    const stablized = rows.map((el, index) => [el, index]);
    stablized.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stablized.map((el) => el[0]);
  };

  const handleDefaultOpen = () =>
    setState((prevState) => {
      return {
        ...prevState,
        open: !prevState.open,
      };
    });

  const handleOpen = () => {
    setState((prevState) => {
      collapsedRows = prevState.open
        ? collapsedRows.filter((item) => item !== rows.uniqueColumnName)
        : [...collapsedRows, rows.uniqueColumnName];
      handleRowOpen(collapsedRows);
      return {
        ...prevState,
        open: !prevState.open,
      };
    });
  };
  onReady({
    resetSelection: () =>
      setState((prevState) => ({
        ...prevState,
        selectedRows: [],
      })),
    setSelection: (selectedRows) =>
      setState((prevState) => ({
        ...prevState,
        selectedRows,
      })),
    getSelection: () => state.selectedRows,
    toggle: (open = false) =>
      setState((prevState) => ({
        ...prevState,
        open,
      })),
  });

  if (isLoading) {
    return (
      <GridLoader
        open={state.open}
        columns={columns.length}
        pageSize={rows.sortedRows?.length}
      />
    );
  }

  const slectableRows = rows?.sortedRows.filter((row) => !row.preventSelection);

  return (
    <List>
      <ListItem
        className={classes.tablePadding}
        button
        onClick={multiScroll ? handleDefaultOpen : handleOpen}
      >
        <ListItemIcon>
          {state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItemIcon>
        <ListItemText primary={rows.uniqueColumnName} />
      </ListItem>
      <Collapse in={state.open} unmountOnExit>
        <TableContainer
          style={{
            overflowX: "auto",
            overflowY: "hidden",
          }}
        >
          <Table className={classes.borderCollapse}>
            <TableHead ref={tableHeadRef}>
              <TableRow className={clsx(classes.row, passedClasses.header)}>
                {hasSelection && (
                  <TableCell padding="checkbox" className="no-border">
                    <Checkbox
                      disabled={!slectableRows.length}
                      indeterminate={
                        state.selectedRows.length > 0 &&
                        state.selectedRows.length < slectableRows.length
                      }
                      checked={
                        slectableRows.length > 0 &&
                        state.selectedRows.length === slectableRows.length
                      }
                      onChange={handleSelectVisibleChange}
                      inputProps={{ "aria-label": "select visible" }}
                    />
                  </TableCell>
                )}
                {columns.map((column, columnIndex) => {
                  if (column.isHidden) {
                    return null;
                  }
                  columnRefs[columnIndex] = createRef();
                  let content = <span></span>;
                  if (column.renderHeader) {
                    content = column.renderHeader();
                  } else {
                    content = column.canSort ? (
                      <TableSortLabel
                        key={column.id}
                        active={state.orderBy === column.id}
                        direction={
                          state.orderBy === column.id ? state.order : "asc"
                        }
                        onClick={createSortHandler(column.id)}
                      >
                        {column.label}
                        {state.orderBy === column.id ? (
                          <span className={classes.visuallyHidden}>
                            {state.order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      <span>{column.label}</span>
                    );
                  }

                  return (
                    <TableCell
                      key={column.id}
                      align={column.numeric ? "right" : "left"}
                      padding={column.disablePadding ? "none" : "default"}
                      sortDirection={
                        state.orderBy === column.id ? state.order : false
                      }
                      className={clsx(
                        "no-border p-relative",
                        classes.tableCell,
                        classes.tableHeadCell,
                        classes.tableHeadPadding,
                        column.headerClassName
                      )}
                      ref={columnRefs[columnIndex]}
                    >
                      <Tooltip
                        title={column.tooltip || column.label}
                        placement={column.tooltipPlacement || "top-start"}
                      >
                        <div className="d-flex f-justify-between">
                          {content}
                          {!column.noResize && (
                            <div
                              className={clsx(
                                "d-flex f-justify-between f-align-center p-absolute",
                                classes.resizeIconWrapper
                              )}
                              data-role="resize"
                              onMouseDown={(event) => {
                                if (
                                  columnRefs[columnIndex] &&
                                  columnRefs[columnIndex].current
                                ) {
                                  const element =
                                    columnRefs[columnIndex].current;
                                  document.onmouseup = () => {
                                    document.onmousemove = null;
                                  };

                                  document.onmousemove = (() => {
                                    const initialCursorX = event.pageX;
                                    const initialWidth = element.offsetWidth;
                                    return (event) => {
                                      if (initialCursorX) {
                                        const diffX =
                                          event.pageX - initialCursorX;
                                        const newWidth = initialWidth + diffX;

                                        const isTargetColumn =
                                          element.textContent ===
                                          "Review Notes ";

                                        element.style.minWidth = isTargetColumn
                                          ? `${Math.max(newWidth, 367)}px`
                                          : `${newWidth}px`;

                                        element.style.maxWidth = `${newWidth}px`;
                                      }
                                    };
                                  })();
                                }
                              }}
                              onMouseUp={() => {
                                console.log("Fired local");
                                document.onmousemove = null;
                              }}
                            >
                              <div className={classes.resizeIcon} />
                            </div>
                          )}
                        </div>
                      </Tooltip>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(
                rows.sortedRows,
                getComparator(state.order, state.orderBy)
              ).map((row, rowIndex) => {
                let content = <></>;
                const selectionHandler = createSelectionHandler(row.id);
                if (rows.render) {
                  content = rows.render();
                } else {
                  const labelId = `table-checkbox-${rowIndex}`;
                  content = (
                    <>
                      {hasSelection && (
                        <TableCell padding="checkbox" className="no-border">
                          <Checkbox
                            disabled={row.preventSelection}
                            checked={state.selectedRows.indexOf(row.id) !== -1}
                            inputProps={{ "aria-labelledby": labelId }}
                            onChange={selectionHandler}
                          />
                        </TableCell>
                      )}
                      {columns.map((column, columnIndex) => {
                        if (column.isHidden) {
                          return null;
                        }
                        let content = <span>{row[column.field] || ""}</span>;
                        if (column.render) {
                          content = column.render(row, row.rowIndex);
                        } else {
                          content = (
                            <Typography
                              className="d-inline-block w-100"
                              variant="body2"
                              component="span"
                              noWrap={column.hasEllipses}
                            >
                              {`${
                                row[column.field] === null ||
                                row[column.field] === undefined
                                  ? ""
                                  : column.startAdornment ?? ""
                              }${
                                row[column.field] || column.placeholder || "-"
                              }${
                                row[column.field] === null ||
                                row[column.field] === undefined
                                  ? ""
                                  : column.endAdornment ?? ""
                              }`}
                            </Typography>
                          );
                        }

                        return (
                          <TableCell
                            className={clsx(
                              "no-border",
                              classes.tableCell,
                              classes.tableHeadPadding,
                              row.classes && row.classes[column.field]
                            )}
                            key={`${
                              column.label || "grid-column"
                            }-${columnIndex}${row.id}`}
                          >
                            <Tooltip
                              title={row[column.field] || ""}
                              placement={
                                column.dataTooltipPlacement || "top-start"
                              }
                            >
                              {content}
                            </Tooltip>
                          </TableCell>
                        );
                      })}
                    </>
                  );
                }
                return (
                  <TableRow
                    hover
                    key={row.id}
                    ref={boxborder === row.id ? selectedRef : null}
                    className={clsx(
                      classes.row,
                      passedClasses.row,
                      row.className,
                      {
                        [classes.boxborder]:
                          boxborder === row.id &&
                          row.id !== null &&
                          boxborder !== null,
                      }
                    )}
                    {...rowEvents.reduce(
                      (acc, { type, handler }) => ({
                        ...acc,
                        [type]: () => handler(row, rowIndex),
                      }),
                      {}
                    )}
                  >
                    {content}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </List>
  );
};

export default Grid;
