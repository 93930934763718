import { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { fieldErrorMessageMap, noop } from "shared/constants";
import { Dropdown } from "shared";
import InputAdornment from "@material-ui/core/InputAdornment";
import Skeleton from "@material-ui/lab/Skeleton";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { preventInputKeyCodes, validator } from "utils";
import useStyles from "./style";

const defaultState = {
  isEditing: false,
  value: null,
  error: "",
  searchValue: "",
};

const InlineFieldForm = ({
  name = "",
  showInputOnly = false,
  updateOnError = false,
  initialValue = null,
  inputType = "string",
  disabled = false,
  type = "input",
  options = [],
  isLoading = false,
  dropdownLabel = "",
  dropdownTooltip = "",
  dropdownPlacement = "bottom-start",
  hasSearch = false,
  label = " ",
  placeholder = " ",
  validations = [],
  labelClassName = "",
  dropdownPaperClass = "",
  dropdownLabelClassName = "",
  startAdornment = "",
  endAdornment = "",
  textFieldTooltip = "",
  onUpdate = noop,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    setState((prevState) => ({ ...prevState, value: initialValue }));
  }, [initialValue, state.isEditing]);

  const validate = (value, validations = []) => {
    const validationError = validations.map((validation) =>
      validator(
        validation.type,
        validation.value,
        value,
        inputType,
        validation.customMessage
      )
    );
    return validationError.find((error) => !error?.isValid)?.message || "";
  };

  const handleChange = (name, value, inputType = "string") => {
    const errorMessage = validate(value, validations);

    if (
      (inputType === "number" &&
        value.indexOf(".") !== -1 &&
        name !== "ein" &&
        name !== "ssn") ||
      name === "pay_fuel_amount" ||
      name === "reimbursement" ||
      name === "deduction"
    ) {
      let s = value.toString().split(".");
      let length = s[1]?.length > 2;
      if (length) {
        value = +value;
        value = (Math.round((value + Number.EPSILON) * 100) / 100).toFixed(2);
        // value = value + "";
      }
    }
    setState((prevState) => ({
      ...prevState,
      error: errorMessage,
      value,
    }));
  };

  const handleSubmit = (value = null, error = "") => {
    if (!showInputOnly) {
      setState((prevState) => ({
        ...prevState,
        ...defaultState,
        value,
      }));
    }

    if ((!error && value !== initialValue) || updateOnError) {
      onUpdate({
        name,
        value:
          inputType === "number"
            ? Number.isInteger(+value)
              ? +value + ""
              : (+value).toFixed(2)
            : value,
      });
    }
  };

  const optionList = useMemo(() => {
    return hasSearch
      ? options.filter(
          (option) =>
            (option.label || "")
              .toLowerCase()
              .indexOf((state.searchValue || "").toLowerCase()) !== -1
        )
      : options;
  }, [options, state.searchValue]);

  return (
    <>
      {type === "dropdown" && (
        <>
          {isLoading ? (
            <Skeleton variant="rect" width="100%" />
          ) : disabled ? (
            <Tooltip
              title={dropdownTooltip || dropdownLabel || ""}
              placement="top-start"
            >
              <Typography noWrap variant="body2">
                {dropdownLabel}
              </Typography>
            </Tooltip>
          ) : (
            <Dropdown
              hasSearch={hasSearch}
              classes={{
                paper: `${classes.paper} ${dropdownPaperClass}`,
                label: dropdownLabelClassName,
              }}
              label={dropdownLabel || "Select"}
              labelEllipses
              hasEllipses
              labelTooltip={dropdownTooltip || dropdownLabel}
              placement={dropdownPlacement}
              options={optionList}
              onChange={({ value }) => handleSubmit(value)}
              onSearch={(evt) => {
                const { value } = evt.currentTarget;
                setState((prevState) => ({
                  ...prevState,
                  searchValue: (value || "").trim(),
                }));
              }}
              onToggleClick={() =>
                setState((prevState) => ({ ...prevState, searchValue: "" }))
              }
            />
          )}
        </>
      )}
      {type === "input" &&
        (state.isEditing || showInputOnly ? (
          <TextField
            autoFocus
            fullWidth
            label={label}
            className={classes.textField}
            variant="outlined"
            size="small"
            name={name}
            value={
              inputType === "number"
                ? (state.value ?? "").toString()
                : state.value
            }
            type={inputType}
            onChange={(evt) => {
              const { name, value, type } = evt.currentTarget;
              handleChange(name, value, type);
            }}
            onWheel={(event) => {
              if (inputType === "number") {
                event.target.blur();
              } else {
                return false;
              }
            }}
            onBlur={() => handleSubmit(state.value, state.error)}
            error={!!state.error}
            helperText={state.error}
            onKeyDown={inputType === "number" ? preventInputKeyCodes : noop}
            // InputProps={{
            //   startAdornment: <InputAdornment>{startAdornment}</InputAdornment>,
            //   endAdornment: <InputAdornment>{endAdornment}</InputAdornment>,
            // }}
          />
        ) : (
          <Tooltip
            title={textFieldTooltip || initialValue || ""}
            placement="top-start"
          >
            <Typography
              noWrap
              variant="body2"
              className={clsx(labelClassName, {
                "c-pointer": !disabled,
              })}
              onClick={() => {
                if (disabled) return;
                setState((prevState) => ({
                  ...prevState,
                  isEditing: true,
                  value:
                    inputType !== "number"
                      ? (prevState.value || "").trim()
                      : prevState.value,
                }));
              }}
            >
              {inputType === "number"
                ? initialValue === null || initialValue === undefined
                  ? "-"
                  : `${startAdornment}${initialValue}${endAdornment}`
                : initialValue
                ? `${startAdornment}${initialValue}${endAdornment}`
                : "-"}
            </Typography>
          </Tooltip>
        ))}
    </>
  );
};

export default InlineFieldForm;
