import { useRef, useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import InputBase from "@material-ui/core/InputBase";
import { Dialog } from "shared/components";
import { validator } from "utils";
import Paper from "@material-ui/core/Paper";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import SendIcon from "@material-ui/icons/Send";
import clsx from "clsx";
import { ProfileAvatar } from "shared/components";
import useStyles from "./style";
import ClearIcon from "@material-ui/icons/Clear";
import { Avatar, Button } from "@material-ui/core";
import { getFormattedTime } from "utils";
import { noop, VALIDATIONS } from "shared";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import Badge from "@material-ui/core/Badge";
import TextField from "@material-ui/core/TextField";
import CreateIcon from "@material-ui/icons/Create";
import DoneAllIcon from "@material-ui/icons/DoneAll";

let addedUsersInGroup = [];

const defaultState = {
  msgExistsForDate: false,
  message: "",
  fullName: {},
  groupUserName: null,
  openGroupChat: false,
  roomName: "",
  removeUsers: false,
  leaveGroup: false,
  showParticipants: false,
  errors: {
    roomName: " ",
  },
};

const GroupChat = ({
  id = "",
  entry = {},
  userData = {},
  entries = [],
  addedGroupUser = [],
  totalMessagesCount = 0,
  readGroupMessageUsers = [],
  unreadGroupMessageUsers = [],
  openGroupMessages = false,
  chatRoomUsers = {},
  onlineUsers = [],
  currentGroupSelected = {},
  addedUserIngroup = [],
  groupByDate = noop,
  changeRoomName = noop,
  removeUserFromGroup = noop,
  leaveGroup = noop,
  handleSendGroupMsg = noop,
  handleClose = noop,
}) => {
  const [state, setState] = useState({
    ...defaultState,
  });
  const classes = useStyles();
  const scrollRef = useRef();

  const groupMesgGroupedByDate = groupByDate(
    onlineUsers
      .filter((user) => user?.id !== userData?.id && user.is_room_msg === true)
      .sort((a, b) => Intl.Collator().compare(a.created_at, b.created_at))
  );
  const groupDateArray = Object.keys(groupMesgGroupedByDate);

  const setMsg = (event) => {
    setState((prevState) => ({
      ...prevState,
      message: event.target.value,
    }));
  };

  const sendGroupChat = (event) => {
    event.preventDefault();
    if (state.message.length > 0) {
      handleSendGroupMsg(state.message, entry);
      setState((prevState) => ({
        ...prevState,
        message: "",
      }));
    }
  };

  if (
    chatRoomUsers !== {} &&
    entry?.id in chatRoomUsers &&
    chatRoomUsers[entry?.id]
  ) {
    let temp = Object.entries(chatRoomUsers[entry?.id]);
    let temp1 = temp.map((item) => ({
      id: item[0],
      users: item[1],
    }));

    let tempArray = [];

    for (let i = 0; i < addedUserIngroup.length; i++) {
      let entry = addedUserIngroup[i];
      let users = {};
      let showAddedUserList = false;
      if (entry.is_room === undefined || entry.is_room === false) {
        for (let j = 0; j < temp1.length; j++) {
          let item = temp1[j];
          if (item.users == entry.id) {
            users = item;
            showAddedUserList = true;
          }
        }

        if (showAddedUserList === true) {
          tempArray.push({
            ...entry,
            users: users,
          });
        }
      }
    }

    addedUsersInGroup = tempArray;
  }

  const removeUser = (event) => {
    event.preventDefault();
    removeUserFromGroup(state.groupUserName, entry?.id);
    setState((prevState) => ({
      ...prevState,
      groupUserName: null,
      removeUsers: false,
    }));
  };

  const getFieldValidatorMap = (state, field) => {
    const fieldValidatorMap = {
      roomName: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.MIN_LENGTH, value: 3 },
        { type: VALIDATIONS.MAX_LENGTH, value: 30 },
      ],
    };
    return fieldValidatorMap;
  };

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = getFieldValidatorMap(state, field);

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const leaveCurrentGroup = (event) => {
    event?.preventDefault();
    leaveGroup(userData, entry?.id);
    setState((prevState) => ({
      ...prevState,
      leaveGroup: false,
      showParticipants: false,
    }));
  };

  const handleCloseRemoveUsers = () => {
    setState((prevState) => ({
      ...prevState,
      removeUsers: false,
      groupUserName: null,
    }));
  };

  const handleCloseLeaveGroup = () => {
    setState((prevState) => ({
      ...prevState,
      leaveGroup: false,
    }));
  };
  const handleCloseGroupParticipants = () => {
    setState((prevState) => ({
      ...prevState,
      showParticipants: false,
    }));
  };
  const handleShowParticipants = () => {
    setState((prevState) => ({
      ...prevState,
      showParticipants: true,
    }));
  };

  const handleRemoveUsers = (value) => {
    setState((prevState) => ({
      ...prevState,
      removeUsers: true,
      groupUserName: value,
    }));
  };

  const handleLeaveGroup = (event) => {
    setState((prevState) => ({
      ...prevState,
      leaveGroup: true,
    }));
  };

  const handleCloseGroupChat = () => {
    setState((prevState) => ({
      ...prevState,
      openGroupChat: false,
      roomName: "",
      errors: {
        ...prevState.errors,
        roomName: "",
      },
    }));
  };

  const setRoomName = (event) => {
    let errorMessage =
      validate(event.currentTarget.name, event.target.value) || " ";
    setState((prevState) => ({
      ...prevState,
      roomName: event.target.value,
      errors: {
        ...prevState.errors,
        roomName: errorMessage,
      },
    }));
  };

  const createRoomName = (event) => {
    // event.preventDefault();
    if (state.roomName.length > 0) {
      const string = state.roomName?.trimStart();
      const finalString = string?.trimEnd();
      changeRoomName(finalString, entry?.id);
      setState((prevState) => ({
        ...prevState,
        roomName: "",
        openGroupChat: false,
        showParticipants: false,
      }));
    }
  };

  const handleCreateGroupChat = () => {
    setState((prevState) => ({
      ...prevState,
      openGroupChat: true,
    }));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const scrollItThere = () => {
    scrollRef.current?.scrollIntoView({
      block: "start",
      behavior: "auto",
    });
  };

  useEffect(() => {
    scrollItThere();
  }, [currentGroupSelected, scrollItThere]);

  return (
    <>
      {
        <div style={{ margin: "0px" }}>
          <Accordion
            className={
              id == 1
                ? classes.accordionChatBoxlenght1
                : id == 2
                ? classes.accordionChatBoxlenght2
                : classes.accordionChatBox0
            }
            defaultExpanded={true}
          >
            <div
              className={clsx(
                "d-flex f-align-center f-justify-between",
                classes.chatBoxHeader
              )}
            >
              {" "}
              <div className={classes.style}>
                <Tooltip title={`${entry?.name || ""}`} placement="top-start">
                  <IconButton
                    onClick={handleCreateGroupChat}
                    className={classes.updateGroupName}
                  >
                    <ProfileAvatar
                      className={classes.ProfileAvatar}
                      userData={
                        (state.fullName = {
                          profile_image_path: userData?.profile_image_path,
                          fullName: `${entry?.name || ""}`,
                        })
                      }
                    />
                    <Typography className={classes.groupChatHeader}>{`${
                      entry?.name || ""
                    }`}</Typography>
                  </IconButton>
                </Tooltip>
              </div>
              <AccordionSummary
                classes={{
                  content: classes.content,
                  expanded: classes.expanded,
                }}
                style={{
                  width: "254px",
                  minHeight: "42px",
                }}
                aria-controls="panel1a-content"
                id="panel1a-header"
              />
              {(totalMessagesCount > 0
                ? [...unreadGroupMessageUsers, ...readGroupMessageUsers]
                : []
              ).map((element) =>
                entry?.id == element?.id ? (
                  <Badge
                    badgeContent={element.count > 0 ? element.count : null}
                    color="error"
                    className={classes.showHeaderBadges}
                  />
                ) : null
              )}
              <Tooltip title="Participants" placement="top-start">
                <IconButton
                  onClick={handleShowParticipants}
                  className={classes.viewUsers}
                  // key={entry.id}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
              <Button
                onClick={() => handleClose(entry, false)}
                className={classes.chatBoxClose}
              >
                <ClearIcon />
              </Button>
            </div>
            <div>
              <Divider />
              <div className={classes.chatMessages}>
                {groupDateArray.map((date) => {
                  return (
                    <div>
                      {groupMesgGroupedByDate[date].every((msg) => {
                        if (
                          entry?.id === msg.sender_id ||
                          entry?.id === msg.recipient_id
                        ) {
                          state.msgExistsForDate = true;
                        }
                        if (state.msgExistsForDate) return false;
                        else return true;
                      })}
                      {state.msgExistsForDate && (
                        <h2 className={classes.groupByDateHeader}>
                          <span className={classes.groupByDate}>{date}</span>
                        </h2>
                      )}
                      {(state.msgExistsForDate = false)}
                      {groupMesgGroupedByDate[date].map((message) => {
                        if (
                          entry?.id === message.sender_id ||
                          entry?.id === message.recipient_id
                        ) {
                          return (
                            <div ref={scrollRef}>
                              <div
                                className={`${classes.messagee} ${
                                  message.sender_id === userData?.id
                                    ? classes.sended
                                    : classes.recieved
                                }`}
                              >
                                <div>
                                  <p
                                    className={`${
                                      message.sender_id === userData?.id
                                        ? classes.sendedMessage
                                        : classes.recievedMessage
                                    }`}
                                  >
                                    {
                                      <>
                                        <div className={classes.reciverName}>
                                          {message.sender_id === userData?.id
                                            ? null
                                            : `${
                                                message.senderUser
                                                  ?.first_name || ""
                                              } ${
                                                message.senderUser?.last_name ||
                                                ""
                                              }`}
                                        </div>
                                        <div className={classes.moveInNextLine}>
                                          {message.message}
                                        </div>
                                        <div
                                          className="d-flex f-align-center f-justify-end"
                                          style={{ gap: "4px" }}
                                        >
                                          <div className={classes.messageTime}>
                                            {getFormattedTime(
                                              new Date(message.created_at)
                                            )}
                                          </div>
                                          <div className={classes.messageTime}>
                                            {message?.sender_id ==
                                              userData?.id &&
                                              (!message?.is_read ? (
                                                <DoneAllIcon
                                                  className={classes.notSeen}
                                                />
                                              ) : (
                                                <DoneAllIcon
                                                  className={classes.seen}
                                                />
                                              ))}
                                          </div>
                                        </div>
                                      </>
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  );
                })}
              </div>
              <Divider />
              <Grid
                item
                xs={12}
                style={{
                  width: "100%",
                }}
                className="d-flex f-align-center"
              >
                <InputBase
                  className={classes.inputcontainer}
                  type="text"
                  inputProps={{
                    maxLength: 200,
                    style: { margin: "0px 10px", fontSize: "0.8rem" },
                  }}
                  fullWidth={true}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && !!e.shiftKey) {
                      // prevent default behavior
                      e.preventDefault();
                      return setState((prevState) => ({
                        ...prevState,
                        message: e.target.value + "\n",
                      }));
                    }

                    if (e.key === "Enter") {
                      e.preventDefault();
                      sendGroupChat(e);
                    }
                  }}
                  maxRows="4"
                  multiline={true}
                  placeholder="Write a message..."
                  onChange={setMsg}
                  value={state.message}
                />

                <Button
                  type="submit"
                  onClick={(event) => sendGroupChat(event)}
                  variant="contained"
                  color="primary"
                  disabled={!state.message}
                  className={classes.buttonStyle}
                >
                  <SendIcon className={classes.buttonText} />
                </Button>
              </Grid>
            </div>
          </Accordion>
        </div>
      }
      <div>
        {state.removeUsers && (
          <Dialog
            open
            classes={{
              paper: classes.paperr,
            }}
            onClose={handleCloseRemoveUsers}
          >
            <Dialog.Title hasClose>
              Are you sure you want to remove this participant from group?
            </Dialog.Title>
            <Dialog.Actions>
              <div className="p-4">
                <Button
                  variant="outlined"
                  onClick={handleCloseRemoveUsers}
                  className="ml-2 mr-2"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className="ml-2 mr-2"
                  onClick={removeUser}
                >
                  Remove
                </Button>
              </div>
            </Dialog.Actions>
          </Dialog>
        )}
      </div>
      <div>
        {state.openGroupChat && (
          <Dialog
            open
            classes={{
              paper: classes.changeGroupName,
            }}
            onClose={handleCloseGroupChat}
          >
            <Dialog.Title hasClose>Change group name</Dialog.Title>
            <Dialog.Content>
              <>
                <TextField
                  fullWidth
                  required
                  label="New group name"
                  variant="outlined"
                  size="small"
                  name="roomName"
                  onChange={setRoomName}
                  value={state.roomName}
                  error={!!state.errors.roomName.trim()}
                  helperText={state.errors.roomName}
                ></TextField>
              </>
            </Dialog.Content>
            <Dialog.Actions>
              <div className="p-4">
                <Button
                  variant="outlined"
                  onClick={handleCloseGroupChat}
                  className="ml-2 mr-2"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="ml-2 mr-2"
                  disabled={!state.roomName.trim() || validate()}
                  // onClick={createRoomName}
                  onClick={async () => {
                    try {
                      await createRoomName();
                      await handleClose(entry, false);
                    } catch (error) {
                      console.error(`Error: ${error.message}`);
                    }
                  }}
                >
                  Change
                </Button>
              </div>
            </Dialog.Actions>
          </Dialog>
        )}
      </div>
      <div>
        {state.leaveGroup && (
          <Dialog
            open
            classes={{
              paper: classes.paper,
            }}
            onClose={handleCloseLeaveGroup}
          >
            <Dialog.Title hasClose>
              Are you sure you want to exit group?
            </Dialog.Title>
            <Dialog.Actions>
              <div className="p-4">
                <Button
                  variant="outlined"
                  onClick={handleCloseLeaveGroup}
                  className="ml-2 mr-2"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className="ml-2 mr-2"
                  onClick={async () => {
                    try {
                      await leaveCurrentGroup();
                      await handleClose(entry, false);
                    } catch (error) {
                      // handle error
                    }
                  }}
                >
                  Exit
                </Button>
              </div>
            </Dialog.Actions>
          </Dialog>
        )}
      </div>
      <div>
        {state.showParticipants &&
          (addedUsersInGroup?.length !== 0 ? (
            <Dialog
              open
              classes={{
                paper: classes.groupList,
              }}
              onClose={handleCloseGroupParticipants}
            >
              <Dialog.Title hasClose>
                <div className="d-flex  f-align-center f-justify-between mr-5">
                  {`${addedUsersInGroup?.length} Participants`}
                </div>
              </Dialog.Title>
              <Dialog.Content>
                <>
                  <Grid>
                    <List>
                      {addedUsersInGroup.map((data) => {
                        return (
                          <div className="d-flex f-justify-between f-align-center">
                            <ListItem
                              style={{
                                paddingLeft: "8px",
                              }}
                            >
                              <ListItemIcon className={classes.ListItemIcon}>
                                {data?.profile_image_path === null ||
                                data?.profile_image_path === undefined ? (
                                  <ProfileAvatar
                                    className={classes.ProfileAvatar}
                                    userData={
                                      (state.fullName = {
                                        profile_image_path:
                                          userData?.profile_image_path,
                                        fullName: `${data.first_name || ""} ${
                                          data.last_name || ""
                                        }`,
                                      })
                                    }
                                  />
                                ) : (
                                  <Avatar
                                    className={classes.ProfileAvatar}
                                    alt="avatar"
                                    src={data?.profile_image_path}
                                  />
                                )}
                              </ListItemIcon>
                              <ListItemText
                                primary={`${data.first_name || ""} ${
                                  data.last_name || ""
                                }-(${data.role || ""})`}
                              />
                            </ListItem>
                            {data?.id != userData?.id && (
                              <Tooltip
                                title="Remove participant"
                                placement="top-start"
                              >
                                <IconButton
                                  onClick={() => handleRemoveUsers(data.id)}
                                  className={classes.viewUsers}
                                >
                                  <DeleteForeverIcon color="error" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </div>
                        );
                      })}
                    </List>
                  </Grid>
                </>
              </Dialog.Content>
              <Dialog.Actions>
                <div className="p-4">
                  <Tooltip title="Exit group" placement="top-start">
                    <Button
                      type="submit"
                      variant="contained"
                      color="error"
                      // className="ml-2 mr-2"
                      style={{ marginRight: "6.8rem" }}
                      onClick={handleLeaveGroup}
                    >
                      Exit group
                    </Button>
                  </Tooltip>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="ml-2 mr-2"
                    onClick={handleCloseGroupParticipants}
                  >
                    OK
                  </Button>
                </div>
              </Dialog.Actions>
            </Dialog>
          ) : (
            <Dialog
              open
              classes={{
                paper: classes.zeroParticipantsList,
              }}
              onClose={handleCloseGroupParticipants}
            >
              <Dialog.Title hasClose className="mb-4" />
              <Paper
                elevation={2}
                className={clsx(
                  "d-flex f-align-center f-justify-center mt-1 p-2 ml-4 mr-4 p-relative",
                  classes.paperHeight
                )}
              >
                <div className="d-flex flex-column f-justify-center f-align-center">
                  <Typography variant="h6">{`${addedUsersInGroup?.length} Participants.`}</Typography>
                </div>
              </Paper>
              <Dialog.Actions>
                <div className="p-4 pt-2">
                  <Tooltip title="Exit group" placement="top-start">
                    <Button
                      type="submit"
                      variant="contained"
                      color="error"
                      // className="ml-2 mr-2"
                      style={{ marginRight: "4.8rem" }}
                      onClick={handleLeaveGroup}
                    >
                      Exit group
                    </Button>
                  </Tooltip>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="ml-2 mr-2"
                    onClick={handleCloseGroupParticipants}
                  >
                    OK
                  </Button>
                </div>
              </Dialog.Actions>
            </Dialog>
          ))}
      </div>
    </>
  );
};

export default GroupChat;
