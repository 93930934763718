import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Service from "../../service";
import { Dialog } from "shared/components";
import { noop, VALIDATIONS } from "shared/constants";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import { validator } from "utils";
import useStyles from "./style";

let isFieldChange = false;
const defaultState = {
  isLoading: false,
  schedule: "",
  errors: {
    schedule: " ",
  },
};

const RenameScheduleForm = ({
  id = null,
  schedule = "",
  open = false,
  fetchFuelSurchargeSchedule = noop,
  onClose = noop,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = {
      schedule: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.MAX_LENGTH, value: 30 },
      ],
    };

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        Object.keys(fieldValidatorMap).forEach((key) => {
          const message = validate(key, state[key]);
          if (!!message) {
            errorMessage = message;
          }
        });
      });
    }
    return errorMessage;
  };

  const handleFieldChange = (evt) => {
    isFieldChange = true;
    const field = evt.currentTarget?.name || evt.target?.name;
    const value = evt.currentTarget?.value || evt.target?.value;
    let errorMessage = validate(field, value) || " ";

    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  const handleClose = (isSubmitted = false, renamedSchedule = "") => {
    isFieldChange = false;
    setState(defaultState);
    onClose(isSubmitted, renamedSchedule);
  };

  const handleSubmit = async () => {
    if (!id) {
      return handleClose(true, state.schedule);
    }
    setState((prevState) => ({ ...prevState, isLoading: true }));
    const { error } = await Service.renameSchedule({
      id,
      name: state.schedule,
    });

    if (error) {
      setState((prevState) => ({ ...prevState, isLoading: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    toast.success(`Schedule renamed successfully.`);
    setState((prevState) => ({ ...prevState, isLoading: false }));
    fetchFuelSurchargeSchedule();
    handleClose(true, state.schedule);
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      schedule,
    }));
  }, [schedule]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <Dialog.Title hasClose>Rename Schedule</Dialog.Title>
      <Dialog.Content>
        <TextField
          fullWidth
          variant="outlined"
          name="schedule"
          label="Schedule Name"
          value={state.schedule}
          size="small"
          className="mr-2 w-50 mb-1"
          error={!!state.errors.schedule.trim()}
          helperText={state.errors.schedule}
          onChange={handleFieldChange}
        />
      </Dialog.Content>
      <Dialog.Actions>
        <div className="p-4">
          <Button
            variant="outlined"
            onClick={handleClose}
            className="ml-2 mr-2"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="ml-2 mr-2"
            disabled={state.isLoading || !isFieldChange || validate()}
            onClick={handleSubmit}
          >
            Rename
            {state.isLoading && (
              <CircularProgress size={24} className="p-absolute progress-btn" />
            )}
          </Button>
        </div>
      </Dialog.Actions>
    </Dialog>
  );
};

export default RenameScheduleForm;
