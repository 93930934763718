import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableContainer: (props) => ({
    // minHeight: 200,
    // height: props.rows.length > 0 ? "calc(100vh - 550px)" : 490,
    // overflow: "auto",
    borderBottom: props.rows.length > 0 ? "1px solid #d6d4d4" : "0px",
  }),
  stickyFooter: {
    position: "sticky",
    bottom: 0,
    backgroundColor: "white",
  },
  totalRow: {
    fontWeight: "bold",
  },
  noRecordsImg: {
    maxWidth: 500,
    maxHeight: 400,
  },
  grid: {
    position: "relative",
    height: "calc(100vh - 315px)",
    overflow: "auto",
  },
  totalAmount: {
    background: "#80deea1c",
    padding: "16px",
    borderRadius: 8,
    width: "500px",
    boxSizing: "border-box",
    marginRight: "170px",
    marginTop: "20px",
    marginBottom: "20px",
    float: "right",
  },
  watermark: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%) rotate(-45deg)",
    fontSize: "8em",
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.1)",
    zIndex: 1,
    pointerEvents: "none",
  },
  selectedButton: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
