import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  dialog: {
    height: 250,
    width: 900,
  },

  weekDay: {
    minWidth: 60,
  },
  listIcon: {
    minWidth: 32,
  },
}));

export default useStyles;
