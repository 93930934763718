import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { preventInputKeyCodes, validator } from "utils";
import Service from "./../../service";
import { DEDUCTION_REASON_TYPE, noop, VALIDATIONS } from "shared/constants";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import useStyles from "./style";
import { Autocomplete } from "@material-ui/lab";

let isFieldChange = false;

const defaultState = {
  id: null,
  lookupValue: null,
  deductionReasonType: null,
  isLoading: false,
  errors: {
    lookupValue: " ",
  },
};

const Form = ({
  entry = null,
  isNew = false,
  selectedLookupType = "",
  onSave = noop,
  onClose = noop,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);
  const formRef = useRef(null);

  const shouldUpdate = entry?.value?.toLowerCase() === "stand-by";

  useEffect(() => {
    if (entry && !isNew) {
      setState((prevState) => ({
        ...prevState,
        id: entry.id || defaultState.id,
        lookupValue:
          selectedLookupType === "Route Bill F/S%" ||
          selectedLookupType === "Route Pay F/S%"
            ? entry?.value?.replace("% Less", "")
            : entry?.value || defaultState.lookupValue,
        deductionReasonType:
          entry?.description || defaultState.deductionReasonType,
      }));
    }
    return () => (isFieldChange = false);
  }, [entry]);

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap =
      selectedLookupType === "Route Bill F/S%" ||
      selectedLookupType === "Route Pay F/S%"
        ? {
            lookupValue: [
              { type: VALIDATIONS.REQUIRED, value: true },
              { type: VALIDATIONS.MIN, value: 0 },
              { type: VALIDATIONS.LIMIT, value: 10.2 },
            ],
          }
        : selectedLookupType === "DCN Email Recipients"
        ? {
            lookupValue: [
              { type: VALIDATIONS.REQUIRED, value: true },
              { type: VALIDATIONS.EMAIL, value: true },
            ],
          }
        : selectedLookupType === "Review Notes"
        ? {
            lookupValue: [
              { type: VALIDATIONS.REQUIRED, value: true },
              { type: VALIDATIONS.MIN_LENGTH, value: 3 },
              { type: VALIDATIONS.MAX_LENGTH, value: 200 },
            ],
          }
        : selectedLookupType === "AA/MGR Notes"
        ? {
            lookupValue: [
              { type: VALIDATIONS.REQUIRED, value: true },
              { type: VALIDATIONS.MIN_LENGTH, value: 3 },
              { type: VALIDATIONS.MAX_LENGTH, value: 200 },
            ],
          }
        : {
            lookupValue: [
              { type: VALIDATIONS.REQUIRED, value: true },
              { type: VALIDATIONS.MIN_LENGTH, value: 3 },
              { type: VALIDATIONS.MAX_LENGTH, value: 50 },
            ],
          };

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );

      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        Object.keys(fieldValidatorMap).forEach((key) => {
          const message = validate(key, state[key]);
          console.log(message, "Message", key);
          if (!!message) {
            errorMessage = message;
          }
        });
      });
    }

    return errorMessage;
  };

  const handleFieldChange = (evt) => {
    isFieldChange = true;
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    const type = evt.currentTarget?.type || evt.target?.type;
    let errorMessage = validate(field, value) || " ";

    if (type === "number" && value.indexOf(".") !== -1) {
      let s = value.toString().split(".");
      let length = s[1]?.length > 2;
      if (length) {
        value = +value;
        value = Math.round((+value + Number.EPSILON) * 100) / 100;
        value = value.toFixed(2);
      }
    }
    if (type === "number" && value?.length > 1 && value.indexOf(".") === -1) {
      value = value?.replace(/^0+/, "");
    }

    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  const handleSubmit = async (isSubmitted = false) => {
    let { lookupValue } = state;
    setState((prevState) => ({ ...prevState, isLoading: true }));
    const serviceMethod = isNew ? "createLookup" : "editLookup";

    if (selectedLookupType === "Deduction Reason") {
      lookupValue = lookupValue.trim().replace(/\s+/g, " ");
    }

    const payload = !isNew
      ? {
          id: entry?.id,
          type: selectedLookupType,
          value: lookupValue,
          is_active: entry?.is_active,
          description:
            selectedLookupType === "Deduction Reason"
              ? state.deductionReasonType || "Misc2Deduction"
              : "description",
        }
      : {
          type: selectedLookupType,
          value: lookupValue,
          is_active: true,
          description:
            selectedLookupType === "Deduction Reason"
              ? state.deductionReasonType || "Misc2Deduction"
              : "description",
        };

    const { error } = await Service[serviceMethod](payload);

    if (error) {
      setState((prevState) => ({ ...prevState, isLoading: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success(
      `Lookup value ${isNew ? "created" : "updated"} successfully.`
    );
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
    }));
    onSave(isSubmitted);
  };

  useEffect(() => {
    if (isNew && formRef.current) {
      try {
        formRef.current.scrollIntoView(false);
      } catch (error) {
        console.error(`Error while scroll: ${error}`);
      }
    }
  }, []);

  return (
    <>
      <tr className={classes.formRow} ref={formRef}>
        <td></td>
        <td>
          <Box className="d-flex f-justify-start f-align-center mt-4">
            <TextField
              fullWidth
              required
              disabled={shouldUpdate}
              label="Lookup Value"
              type={
                selectedLookupType === "Route Bill F/S%" ||
                selectedLookupType === "Route Pay F/S%"
                  ? "number"
                  : "text"
              }
              variant="outlined"
              name="lookupValue"
              size="small"
              onWheel={(event) => event.target.blur()}
              value={state.lookupValue}
              className="mb-1 mt-2"
              onChange={handleFieldChange}
              onKeyDown={
                selectedLookupType === "Route Bill F/S%" ||
                selectedLookupType === "Route Pay F/S%"
                  ? preventInputKeyCodes
                  : null
              }
              error={!!state.errors.lookupValue.trim()}
              helperText={state.errors.lookupValue}
            />
            {selectedLookupType === "Deduction Reason" && (
              <Autocomplete
                size="small"
                disableClearable
                className={classes.field}
                value={state.deductionReasonType}
                options={DEDUCTION_REASON_TYPE}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Deduction Reason Type"
                    variant="outlined"
                    helperText=" "
                    required
                  />
                )}
                onChange={(evt, value) => {
                  isFieldChange = true;
                  setState((prevState) => ({
                    ...prevState,
                    deductionReasonType: value,
                  }));
                }}
              />
            )}
          </Box>
        </td>
      </tr>
      <tr>
        <td colspan="100%">
          <Box className="d-flex f-align-center f-justify-end">
            <Button
              variant="contained"
              color="primary"
              className="ml-3 mr-3"
              disabled={
                state.isLoading ||
                !isFieldChange ||
                validate() ||
                (selectedLookupType === "Deduction Reason" &&
                  !state.deductionReasonType)
              }
              onClick={handleSubmit}
            >
              {`${isNew ? "Create" : "Update"}`}
              {state.isLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </Button>
            <Button className="ml-3 mr-3" onClick={onClose}>
              Cancel
            </Button>
          </Box>
        </td>
      </tr>
    </>
  );
};

export default Form;
