import { decode } from "jsonwebtoken";

const ACCESS_TOKEN = "at";
const REFRESH_TOKEN = "rt";
const ALERT_TOKEN = "lastFetchToken";
const SCHEDULE_ALERT_TOKEN = "scheduleAlertToken";
const USER_DATA = "user-data";
const PAGE_CONFIG = "page-config";
let handleTokenUpdate = null;

export const decodeToken = (access_token = "") => {
  if (!access_token) return {};
  const decodedTokenData = decode(access_token);
  return decodedTokenData;
};

export const getToken = () => localStorage.getItem(ACCESS_TOKEN) || "";

export const setToken = (token) => {
  localStorage.setItem(ACCESS_TOKEN, token);

  if (handleTokenUpdate) {
    handleTokenUpdate();
  }
};

export const removeToken = () => {
  localStorage.removeItem(ACCESS_TOKEN);
};

export const replaceToken = (token) => {
  localStorage.setItem(ACCESS_TOKEN, token);
};

export const isJWTExpired = (token, alreadyDecoded) => {
  try {
    const { exp } = alreadyDecoded ? token : decode(token);
    if (Date.now() >= exp * 1000 - 300000) {
      return true;
    }
  } catch (err) {
    return true;
  }
  return false;
};

// eslint-disable-next-line consistent-return
export const onTokenUpdate = (callBack, accountUid) => {
  if (callBack) {
    handleTokenUpdate = callBack;
  }

  if (!callBack && handleTokenUpdate) {
    return handleTokenUpdate(accountUid);
  }
};

export const getTokenData = () => {
  return decodeToken(getToken());
};

export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN) || "";

export const getScheduleAlertToken = () =>
  localStorage.getItem(SCHEDULE_ALERT_TOKEN) || "";

export const getLastFetchToken = () => localStorage.getItem(ALERT_TOKEN) || "";

export const setScheduleAlertToken = (token) => {
  localStorage.setItem(SCHEDULE_ALERT_TOKEN, token);
};

export const setAlertToken = (token) => {
  localStorage.setItem(ALERT_TOKEN, token);
};

export const removeAlertToken = () => {
  localStorage.removeItem(ALERT_TOKEN);
};

export const removeScheduleAlertToken = () => {
  localStorage.removeItem(SCHEDULE_ALERT_TOKEN);
};

export const setRefreshToken = (token) => {
  localStorage.setItem(REFRESH_TOKEN, token);
};

export const removeRefreshToken = () => {
  localStorage.removeItem(REFRESH_TOKEN);
};

export const removePageConfig = () => {
  localStorage.removeItem(PAGE_CONFIG);
};

export const replaceRefreshToken = (token) => {
  localStorage.setItem(REFRESH_TOKEN, token);
};

export const setAuthData = (tokens) => {
  setToken(tokens);
  setRefreshToken("");
  setScheduleAlertToken(tokens);
};

export const removeNonDynamicColumns = (loggedInEmail) => {
  const tokenData = decodeToken(getToken());

  const pageConfig = localStorage.getItem("page-config");

  if (pageConfig) {
    try {
      const pageConfigObj = JSON.parse(pageConfig);

      if (
        pageConfigObj &&
        (pageConfigObj[tokenData.email] || pageConfigObj[loggedInEmail])
      ) {
        const userConfig =
          pageConfigObj[tokenData.email] || pageConfigObj[loggedInEmail];

        for (const configKey in userConfig) {
          const config = userConfig[configKey];

          if (config.dynamicColumns || config.dynamicColumnsKAM) {
            userConfig[configKey] = {
              ...(config.dynamicColumns
                ? { dynamicColumns: config.dynamicColumns }
                : {}),
              ...(config.dynamicColumnsKAM
                ? { dynamicColumnsKAM: config.dynamicColumnsKAM }
                : {}),
            };
          } else {
            delete userConfig[configKey];
          }
        }

        const updatedPageConfig = JSON.stringify(pageConfigObj);

        localStorage.setItem("page-config", updatedPageConfig);
      }
    } catch (error) {
      console.error("Error parsing or updating page-config:", error);
    }
  }
};

export const removeAuthData = () => {
  //removePageConfig();
  removeNonDynamicColumns();
  removeToken();
  removeRefreshToken();
  removeScheduleAlertToken();
  removeAlertToken();
};

export const getUserData = () => {
  return JSON.parse(localStorage.getItem(USER_DATA));
};

export const setUserData = (data) => {
  const userData = JSON.parse(localStorage.getItem(USER_DATA));
  localStorage.setItem(USER_DATA, JSON.stringify({ ...userData, ...data }));
};
