import { getTokenData } from "./";

let permissionMatrix = null;

export const setPermissionMatrix = (matrix) => (permissionMatrix = matrix);

export const hasFeatureAccess = (feature, role) => {
  let userRole = role;
  let hasAccess = false;
  if (!role) {
    userRole = getTokenData().role?.toLowerCase() || "kam";
  }

  if (permissionMatrix) {
    const rolePermissions = permissionMatrix[userRole];

    hasAccess = rolePermissions.indexOf(feature) !== -1;
  }

  return hasAccess;
};
