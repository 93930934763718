import { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import MenuList from "@material-ui/core/MenuList";
import MenuListItem from "@material-ui/core/MenuItem";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import { areEqual } from "utils";

import DropdownIcon from "@material-ui/icons/ArrowDropDown";
import SearchIcon from "@material-ui/icons/Search";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import useStyles from "./style";

const defaultState = {
  isOpen: false,
  options: [],
};

const noop = () => {};

const Dropdown = ({
  asInput = false,
  asSelect = false,
  classes = {},
  className = "",
  customToggle = null,
  disabled = false,
  isMultiSelect = false,
  isRequired = false,
  hasEllipses = false,
  disablePortal = true,
  hasSearch = false,
  labelVariant = "body2",
  labelTooltip = "",
  labelEllipses = false,
  noLabelTooltip = false,
  keepSelection = false,
  label = "Select",
  options = [],
  onChange = noop,
  onClose = noop,
  onReady = noop,
  onSearch = noop,
  onToggleClick = noop,
  placement = "bottom-start",
  remainOpen = false,
  searchValue = null,
  tooltip = "",
  tooltipPlacement = "top",
}) => {
  const styleClasses = useStyles();
  const [state, setState] = useState(defaultState);
  const popperRef = useRef(null);
  const anchorRef = useRef(null);

  useEffect(() => {
    if (options !== null && !areEqual(options, state.options)) {
      setState((prevState) => {
        const updatedOptions = keepSelection
          ? options.map((option) => {
              const stateOption = prevState.options.find(
                (stateOption) => stateOption.id === option.id
              );
              return stateOption
                ? { ...option, isSelected: stateOption.isSelected }
                : { ...option, isSelected: false };
            })
          : options;
        return {
          ...prevState,
          options: updatedOptions,
        };
      });
    }

    if (options === null) {
      setState((prevState) => ({
        ...prevState,
        options: null,
      }));
    }
    // eslint-disable-next-line
  }, [options, keepSelection]);

  useEffect(() => {
    return () => {
      setState(defaultState);
    };
  }, []);

  const handleToggleClick = (event) => {
    event.preventDefault && event.preventDefault();
    event.stopPropagation && event.stopPropagation();
    if (disabled) {
      return;
    }

    setState((prevState) => ({
      ...prevState,
      isOpen:
        typeof event === "boolean" && event !== undefined && event !== null
          ? event
          : !prevState.isOpen,
    }));
    onToggleClick(!state.isOpen);
  };

  const handleMenuClose = () => {
    setState((prevState) => ({ ...prevState, isOpen: false }));
    onClose();
  };

  const handleSelection = (option, optionIndex) => {
    let updatedOptions = state.options.slice();
    if (isMultiSelect) {
      updatedOptions[optionIndex] = {
        ...updatedOptions[optionIndex],
        isSelected: !updatedOptions[optionIndex].isSelected,
      };

      onChange(updatedOptions.filter((option) => option.isSelected));
    } else {
      onChange(option, optionIndex);
    }
    setState((prevState) => ({
      ...prevState,
      isOpen: !!remainOpen,
      options: updatedOptions,
    }));
  };

  let labelContent = label ? (
    <Typography
      className={clsx(classes.label, "pl-1", {
        "no-events color-text-disabled": disabled,
      })}
      variant={labelVariant}
      noWrap={labelEllipses}
    >
      {label} {isRequired && " *"}
    </Typography>
  ) : (
    <></>
  );

  if (!noLabelTooltip) {
    labelContent = (
      <Tooltip
        className="all-events"
        title={labelTooltip || label}
        placement={tooltipPlacement}
      >
        {labelContent}
      </Tooltip>
    );
  }

  onReady({
    open: () =>
      setState((prevState) => ({
        ...prevState,
        isOpen: true,
      })),
    close: () =>
      setState((prevState) => ({
        ...prevState,
        isOpen: false,
      })),
  });

  return (
    <ClickAwayListener
      onClickAway={(event) => {
        // event.preventDefault();
        // event.stopPropagation();
        setState((prevState) => ({
          ...prevState,
          isOpen: false,
        }));
      }}
      mouseEvent="onMouseDown"
    >
      <div
        className={clsx("d-flex", classes.wrapper)}
        data-testid="dropdown-wrapper"
      >
        {customToggle ? (
          customToggle({
            anchorRef,
            onClick: (isOpen) => {
              handleToggleClick(isOpen);
            },
          })
        ) : (
          <div
            ref={anchorRef}
            className={clsx("w-100", {
              "c-pointer": !disabled,
            })}
          >
            <div
              className={clsx(
                "d-flex flex-1 f-justify-between f-align-center",
                classes.labelWrapper
              )}
              onClick={handleToggleClick}
              data-testid="dropdown-label"
            >
              <div className={classes.labelContent}>{labelContent}</div>
              <DropdownIcon
                className={classes.dropdownIcon}
                data-testid="dropdown-open-icon"
              />
            </div>
          </div>
        )}

        {state.options !== null && state.isOpen && (
          <Popper
            anchorEl={anchorRef.current}
            className={clsx(styleClasses.popperWrapper, classes.popperWrapper)}
            disablePortal={disablePortal}
            open={state.isOpen}
            onClose={handleMenuClose}
            modifiers={{
              flip: {
                enabled: false,
              },
              preventOverflow: {
                enabled: !disablePortal,
                boundariesElement: "scrollParent",
              },
            }}
            placement={placement}
            ref={popperRef}
          >
            <Paper
              className={clsx(styleClasses.paper, classes.paper, {
                [styleClasses.ellipsesWidth]: hasEllipses,
              })}
              //   elevation={6}
              variant="outlined"
            >
              {hasSearch && (
                <div className="d-flex">
                  <SearchIcon />
                  <TextField
                    fullWidth
                    classes={{
                      root: clsx(styleClasses.searchInput, classes.searchInput),
                    }}
                    value={searchValue}
                    onChange={onSearch}
                    variant="standard"
                  />
                </div>
              )}
              <MenuList className={classes.list}>
                {state.options && state.options.length > 0 ? (
                  state.options.map((option, optionIndex) => {
                    return (
                      <MenuListItem
                        className={clsx(
                          "p-2",
                          classes.listItem,
                          option.className
                        )}
                        disabled={option.disabled}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          handleSelection(option, optionIndex);
                        }}
                        key={option.id || optionIndex}
                        selected={option.isSelected}
                        data-testid="dropdown-menu-items"
                      >
                        {isMultiSelect && (
                          <Checkbox checked={!!option.isSelected} />
                        )}
                        {option.label &&
                          (typeof option.label === "string" ? (
                            <Typography
                              variant="body2"
                              className={clsx({
                                "w-normal": hasEllipses,
                              })}
                            >
                              {option.label}
                            </Typography>
                          ) : (
                            option.label
                          ))}
                      </MenuListItem>
                    );
                  })
                ) : (
                  <>
                    {state.options !== null && (
                      <MenuListItem
                        className={clsx("p-2", classes.listItem)}
                        disabled={true}
                      >
                        No Options
                      </MenuListItem>
                    )}
                  </>
                )}
              </MenuList>
            </Paper>
          </Popper>
        )}
        {tooltip && (
          <Tooltip
            className="ml-2"
            title={tooltip}
            placement={tooltipPlacement}
            data-testid="dropdown-tooltip"
          >
            <InfoOutlinedIcon className="info-icon" />
          </Tooltip>
        )}
      </div>
    </ClickAwayListener>
  );
};

// export default React.memo(Dropdown, (prevProps, nextProps) => {
//   return areEqual(prevProps, nextProps) && !nextProps.forceUpdate;
// });

export default Dropdown;
