import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  datepickerWrapper: {
    width: 160,
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },

    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },

  chip: {
    margin: theme.spacing(0.5),
  },

  actionsWrapper: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  countSkeleton: {
    display: "inline-flex!important",
  },
  paperSpacing: {
    marginTop: "14px !important",
    paddingTop: "0px !important",
  },
  paperSize: {
    "& .MuiDialogTitle-root": {
      paddingBottom: "0px",
      paddingTop: "5px",
    },
    width: 1120,
  },
  paperHeight: {
    minHeight: 320,
  },
  paperWidthSm: {
    maxWidth: "98%",
  },
  tableHeaderCell: {
    minWidth: 144,
  },
  reviewNotesHeaderCell: {
    minWidth: 200,
    maxWidth: 600,
  },
  customerDropdown: {
    width: 160,
  },
  icidTitle: {
    minWidth: "450px",
  },
  warningIcon: {
    color: "#d7d718",
    zIndex: 1,
    position: "relative",
    marginTop: "2px",
  },
  warningIconHeight: {
    height: "24px",
    position: "relative",
  },
  exclamationMark: {
    width: "3px",
    height: "9px",
    background: "black",
    position: "absolute",
    top: "11px",
    left: "11px",
  },
  icidFlagTitle: {
    minWidth: "450px",
    paddingLeft: "48px",
  },
  highlightAwaitingReview: {
    background: "#8000806b",
  },
  highlightAdminRejected: {
    background: "#ff00005c",
  },
  VisibilityIconColor: { color: "#004700" },
  fixedHeader: {
    width: "100%",
    top: "64px",
    height: "70px",
    zIndex: 1000,
    position: "sticky",
    background: "#fafafa",
  },
}));

export default useStyles;
