import React from "react";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import {
  ActionDialog,
  Grid,
  Datepicker,
  Dropdown,
  FilterComponent,
  Dialog,
} from "shared/components";
import { ProfileModal } from "modules/shared/components";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Popper from "@material-ui/core/Popper";
import SharedService from "modules/shared/service";
import { toast } from "react-toastify";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import Service from "../service";
import { AppContext } from "shared/contexts";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";
import TuneIcon from "@material-ui/icons/Tune";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Form from "../form";
import {
  queryStringBuilderNew,
  getDateString,
  getPageConfig,
  setPageConfig,
  getTokenData,
  getFormattedTime,
  updatePagination,
  updateLayout,
  updatePageConfig,
} from "utils";
import { noop, PAGE_KEYS } from "shared/constants";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import FilterListIcon from "@material-ui/icons/FilterList";

import useStyles from "./style";
import { USER_ROLES, STATUS, ROLE } from "modules/shared/constants";
import Maps from "./../Maps";

let gridHelper = null,
  timeout = null;

const defaultState = {
  isNew: false,
  pageSize: 100,
  pageNumber: 1,
  currentStep: 1,
  order: null,
  orderBy: null,
  entries: [],
  totalEntries: 0,
  search: "",
  role: [],
  filters: {
    role: [],
    userStatus: [],
  },
  pageFilters: [],
  markActiveInactiveIds: [],
  isDataFetching: false,
  rowBeingEdited: null,
  customerDefaultKAMChecked: [],
  customerDefaultKAMUnchecked: [],
  ICDefaultKAMUnchecked: [],
  ICDefaultKAMChecked: [],
  selectedCustomerRows: [],
  selectedICRows: [],
  isFetching: false,
  deletingEntryIds: null,
  editLocation: [],
  deletingAddressIds: null,
  openAddressDialog: false,
  deleteAddress: false,
  addressRowBeingEdited: null,
  addressAdded: true,
  isAddressListLoading: false,
  allLocation: [],
  statusUser: null,
  showManagerAssignedTokAM: false,
  selectDefaultKAM: false,
  kamNameArray: [],
  errorMessage: "",
  customerNameArray: [],
  contractorNameArray: [],
  usersNameArray: [],
  managerList: [],
  contractorDefaultKAM: null,
  customerDefaultKAM: null,
  isFiltering: false,
  openProfileModal: false,
  editProfileImg: false,
  userInfo: {},
  dynamicColumns: {
    select_all: { label: "Select All", value: "select_all", isSelected: true },
    username: { label: "Username", value: "username", isSelected: true },
    first_name: { label: "First Name", value: "first_name", isSelected: true },
    last_name: { label: "Last Name", value: "last_name", isSelected: true },
    email: { label: "Email", value: "email", isSelected: true },
    phone: { label: "Phone", value: "phone", isSelected: true },
    role: { label: "Role", value: "role", isSelected: true },
    manager: { label: "Manager", value: "manager", isSelected: true },
    location: { label: "Location", value: "location", isSelected: true },
    last_login: { label: "Last Login", value: "last_login", isSelected: true },
    address: {
      label: "Address",
      value: "address",
      isSelected: true,
    },
  },
};

const UsersScreen = ({ history }) => {
  const pageConfig = getPageConfig(PAGE_KEYS.USERS);
  const { appData, updateContextData } = useContext(AppContext);
  const classes = useStyles({ isTabletView: appData.isTabletView });
  const [state, setState] = useState({
    ...defaultState,
    pageSize: pageConfig?.pageSize || defaultState.pageSize,
    pageNumber: pageConfig?.pageNumber || defaultState.pageNumber,
    pageFilters:
      (pageConfig && pageConfig?.filters) || defaultState.pageFilters,
    search: (pageConfig && pageConfig?.search) || defaultState.search,
    dynamicColumns: pageConfig?.dynamicColumns || defaultState.dynamicColumns,
    role: (pageConfig && pageConfig?.roleFilter?.value) || defaultState.role,
    statusUser:
      (pageConfig && pageConfig?.statusUser?.value) || defaultState.statusUser,
    filters: {
      ...defaultState.filters,
      role: (pageConfig && pageConfig?.roleFilter?.role) || [],
      userStatus: (pageConfig && pageConfig?.statusUser?.userStatus) || [],
    },
  });
  const currentUser = getTokenData() || {};
  const isRoleView = (currentUser?.role || "").toLowerCase() === ROLE.MAPS_VIEW;

  const filterConfig = useMemo(
    () => [
      {
        field: "manager_name",
        fieldToDisplay: "Manager",
        operatorType: "string",
        options: state.managerList
          .map((manager) => ({
            ...manager,
            label: `${manager.name}-(${manager.username})`,
            value: manager.name,
          }))
          .sort((a, b) => Intl.Collator().compare(a.label, b.label)),
      },
      {
        field: "location",
        fieldToDisplay: "Location",
        operatorType: "regexp",
      },
      {
        field: "phone",
        fieldToDisplay: "Phone",
        operatorType: "regexp",
      },
    ],
    [state.managerList]
  );

  const handleFilterChange = useCallback((pageFilters = []) => {
    setState((prevState) => ({
      ...prevState,
      pageFilters: pageFilters.map((filter) => {
        if (filter.field === "phone") {
          filter.value = (filter.value || "").replace(
            /(\d{3})(\d{3})(\d{4})/,
            "$1-$2-$3"
          );
        }
        return filter;
      }),
    }));
    setPageConfig(PAGE_KEYS.USERS, {
      filters: pageFilters,
    });
  }, []);

  const handleSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      order: order,
      orderBy: fieldObj.field || fieldObj.fieldName,
      selectedRows: defaultState.selectedRows,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleSelectionChange = useCallback((selectedRows) => {
    setState((prevState) => ({
      ...prevState,
      selectedRows,
    }));
  }, []);

  const handleCustomerSelectionChange = useCallback((selectedCustomerRows) => {
    setState((prevState) => {
      const filteredChecked = Object.keys(prevState.customerDefaultKAMChecked)
        ?.filter((key) => selectedCustomerRows.includes(+key))
        ?.reduce((obj, key) => {
          obj[key] = prevState.customerDefaultKAMChecked[key];
          return obj;
        }, {});
      const filteredUnchecked = Object.keys(
        prevState.customerDefaultKAMUnchecked
      )
        ?.filter((key) => !selectedCustomerRows.includes(+key))
        ?.reduce((obj, key) => {
          obj[key] = prevState.customerDefaultKAMUnchecked[key];
          return obj;
        }, {});
      return {
        ...prevState,
        selectedCustomerRows,
        customerDefaultKAMChecked: filteredChecked,
        customerDefaultKAMUnchecked: filteredUnchecked,
      };
    });
  }, []);
  const handleICSelectionChange = useCallback((selectedICRows) => {
    setState((prevState) => {
      const filteredChecked = Object.keys(prevState.ICDefaultKAMChecked)
        ?.filter((key) => selectedICRows.includes(+key))
        ?.reduce((obj, key) => {
          obj[key] = prevState.ICDefaultKAMChecked[key];
          return obj;
        }, {});
      const filteredUnchecked = Object.keys(prevState.ICDefaultKAMUnchecked)
        ?.filter((key) => !selectedICRows.includes(+key))
        ?.reduce((obj, key) => {
          obj[key] = prevState.ICDefaultKAMUnchecked[key];
          return obj;
        }, {});
      return {
        ...prevState,
        selectedICRows,
        ICDefaultKAMChecked: filteredChecked,
        ICDefaultKAMUnchecked: filteredUnchecked,
      };
    });
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setPageConfig(PAGE_KEYS.USERS, {
      pageSize,
    });
    setState((prevState) => ({
      ...prevState,
      pageSize,
      pageNumber: defaultState.pageNumber,
      selectedRows: defaultState.selectedRows,
    }));
  }, []);

  const handlePageNumberChange = useCallback((pageNumber) => {
    setPageConfig(PAGE_KEYS.USERS, {
      pageNumber,
    });
    setState((prevState) => ({
      ...prevState,
      pageNumber,
      selectedRows: defaultState.selectedRows,
    }));
  }, []);

  const getSearchArray = (search) => {
    const columns = [
      "first_name",
      "last_name",
      "email",
      // "phone",
      // "location",
      "username",
    ];
    return columns.map((column) => ({
      field: column,
      value: search,
    }));
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      openProfileModal: false,
    }));
  };

  const fetchEntries = useCallback(
    async (
      search,
      filters,
      pageSize,
      pageNumber,
      order,
      orderBy,
      pageFilters = [],
      hasLoader = true
    ) => {
      const searchArray = getSearchArray(search);
      setState((prevState) => ({
        ...prevState,
        isFetching: hasLoader,
      }));

      const sortObj = { field: orderBy, order };
      let filtersArr = [
        ...pageFilters.map((filter) => ({
          ...filter,
          value: filter?.value?.value || filter?.value,
        })),
      ];
      if (filters.role.length && filters.role[0].value) {
        filtersArr = [...filtersArr, ...filters.role];
      }
      if (filters.userStatus.length && filters.userStatus) {
        filtersArr = [...filtersArr, ...filters.userStatus];
      }
      const queryString = queryStringBuilderNew(
        pageSize,
        pageSize * (pageNumber - 1),
        searchArray,
        filtersArr,
        sortObj,
        search && search.length > 0
      );
      const { data, error } = await SharedService.getUsersList(queryString);
      if (error) {
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0].message : error.message
        );
      }

      setState((prevState) => ({
        ...prevState,
        isFetching: false,
        entries: data.users || defaultState.entries,
        totalEntries: data.count,
      }));
      return data;
    },
    []
  );

  const fetchICCustomerForAA = async (kamId) => {
    setState((prevState) => ({
      ...prevState,
      isDataFetching: true,
    }));

    const { data, error } = await Service.getICCustomerForUsers(kamId);
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isDataFetching: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      selectDefaultAA: true,
      isDataFetching: false,
      isLoading: false,
      isFetching: false,
      customerNameArray:
        data.associated_customers || defaultState.customerNameArray,
      contractorNameArray:
        data.associated_contractors || defaultState.contractorNameArray,
      markActiveInactiveIds: [],
    }));
  };

  const fetchICCustomerForMGR = async (kamId) => {
    setState((prevState) => ({
      ...prevState,
      isDataFetching: true,
    }));

    const { data, error } = await Service.getICCustomerForUsers(kamId);
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isDataFetching: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      selectDefaultMGR: true,
      isDataFetching: false,
      isLoading: false,
      isFetching: false,
      customerNameArray:
        data.associated_customers || defaultState.customerNameArray,
      contractorNameArray:
        data.associated_contractors || defaultState.contractorNameArray,
      usersNameArray: data.associated_users || defaultState.usersNameArray,
      markActiveInactiveIds: [],
    }));
  };

  const fetchICCustomerForKams = async (kamId) => {
    setState((prevState) => ({
      ...prevState,
      isDataFetching: true,
    }));

    const { data, error } = await Service.getICCustomerForUsers(kamId);
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isDataFetching: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      selectDefaultKAM: true,
      isDataFetching: false,
      isLoading: false,
      isFetching: false,
      customerNameArray:
        data.associated_customers || defaultState.customerNameArray,
      contractorNameArray:
        data.associated_contractors || defaultState.contractorNameArray,
      markActiveInactiveIds: [],
    }));
  };

  const getAllLocations = async () => {
    setState((prevState) => ({
      ...prevState,
      isAddressListLoading: true,
    }));
    const { data, error } = await Service.getAllLocations();
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isAddressListLoading: false,
      }));
    }
    setState((prevState) => ({
      ...prevState,
      allLocation: data?.locations?.kams || defaultState?.allLocation,
      isAddressListLoading: false,
    }));
  };

  const deleteLocation = async () => {
    setState((prevState) => ({
      ...prevState,
      isAddressListLoading: true,
    }));
    const { error } = await Service.deleteLocation(
      "kamUser",
      state.kamID,
      state.deletingAddressIds
    );
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isAddressListLoading: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success(`Address deleted successfully.`);
    setState((prevState) => ({
      ...prevState,
      isAddressListLoading: false,
      deleteAddress: false,
      openAddressDialog: false,
    }));
  };

  const handleAddNewLocation = () => {
    setState((prevState) => ({
      ...prevState,
      openMapsDialog: true,
    }));
  };

  useEffect(() => {
    getAllLocations();
  }, []);

  const handleEditAddressClose = () => {
    setState((prevState) => ({
      ...prevState,
      openAddressDialog: false,
    }));
  };

  const fetchManagers = async () => {
    const { data, error } = await SharedService.getUsersList("MGR");
    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetching: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }
    setState((prevState) => ({
      ...prevState,
      isFetching: false,
      managerList: data.users,
    }));
  };

  useEffect(() => {
    fetchManagers();
    fetchList();
  }, []);

  useEffect(() => {
    setTimeout(updateLayout, 2000);
  }, [state.entries]);

  const handleMarkActiveInactive = async (markActiveInactiveIds) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      existing_kam_id: markActiveInactiveIds.map((item) => item.id),
    }));
    markActiveInactiveIds = markActiveInactiveIds.map((item) => ({
      ...item,
      status: !item.status,
    }));

    const { message, error } = await Service.markActiveInactive(
      markActiveInactiveIds
    );
    setState((prevState) => ({
      ...prevState,
      isFetching: true,
    }));
    if (error) {
      if (Array.isArray(error) && error[0].code === "PDX-IU-001") {
        return fetchICCustomerForKams(markActiveInactiveIds[0]?.id);
      } else if (Array.isArray(error) && error[0].code === "PDX-IU-002") {
        const userRole = appData.users.filter(
          (user) => user.id === markActiveInactiveIds[0]?.id
        )[0]?.role;
        return userRole === "AA"
          ? fetchICCustomerForAA(markActiveInactiveIds[0]?.id)
          : fetchICCustomerForMGR(markActiveInactiveIds[0]?.id);

        // return setState((prevState) => ({
        //   ...prevState,
        //   showManagerAssignedTokAM: true,
        //   isLoading: false,
        //   isFetching: false,
        //   errorMessage: error[0].message,
        //   kamNameArray: error[0].kamNameArray,
        //   customerNameArray: error[0].customerNameArray,
        //   markActiveInactiveIds: [],
        // }));
      } else
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    } else {
      toast.success(message || "Status updated successfully.");
      fetchManagers();
    }

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      markActiveInactiveIds: [],
      selectedRows: defaultState.selectedRows,
      isFetching: false,
    }));
    if (gridHelper) {
      gridHelper.resetSelection();
    }
    fetchEntries(
      state.search,
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    );
    fetchList();
  };

  useEffect(() => {
    fetchEntries(
      state.search,
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    ).then((data) => {
      updatePagination(
        data?.count,
        { pageNumber: state.pageNumber, pageSize: state.pageSize },
        (pageNumber) => {
          setState((prevState) => ({ ...prevState, pageNumber }));
        }
      );
    });
  }, [
    fetchEntries,
    state.filters,
    state.pageSize,
    state.pageNumber,
    state.order,
    state.orderBy,
    state.search,
    state.pageFilters,
  ]);

  const handleSearch = useCallback((search) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        search,
        selectedRows: defaultState.selectedRows,
        pageNumber: defaultState.pageNumber,
      }));
      setPageConfig(PAGE_KEYS.USERS, {
        search,
      });
      gridHelper && gridHelper.resetSelection();
    }, 1000);
  }, []);

  const moreActions = (row) => {
    return (
      <div className="d-flex f-align-center f-justify-end">
        {!isRoleView ? (
          <>
            <Tooltip title="Edit" placement="top-start">
              <EditOutlinedIcon
                className="ml-2 mr-2 c-pointer"
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    rowBeingEdited: row,
                  }))
                }
              />
            </Tooltip>
            <Tooltip title="Delete" placement="top-start">
              <DeleteForeverOutlinedIcon
                color="error"
                className="ml-2 mr-2 c-pointer"
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    deletingEntryIds: row.id,
                  }))
                }
              />
            </Tooltip>
            <Tooltip
              title={`Mark ${row.is_active ? "Inactive" : "Active"}`}
              placement="top-start"
            >
              <Switch
                size="small"
                disabled={currentUser?.email === row.email}
                checked={row.is_active}
                onChange={() => {
                  setState((prevState) => ({
                    ...prevState,
                    markActiveInactiveIds: [
                      { id: row.id, status: row.is_active },
                    ],
                  }));
                }}
                color="primary"
              />
            </Tooltip>
          </>
        ) : (
          <Tooltip
            title={`${!row.is_active ? "Inactive" : "Active"}`}
            placement="top-start"
            style={{ cursor: "default" }}
          >
            <Switch
              size="small"
              className="none-events"
              checked={row.is_active}
              color="primary"
            />
          </Tooltip>
        )}
      </div>
    );
  };

  const handleDelete = async (entryId) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const { error } = await Service.deleteEntry(entryId);

    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      deletingEntryIds: null,
      selectedRows: defaultState.selectedRows,
    }));
    if (gridHelper) {
      gridHelper.resetSelection();
    }

    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      toast.success("Deleted successfully.");
      setTimeout(() => {
        window.location.reload();
      }, 2500);

      fetchEntries(
        state.search,
        state.filters,
        state.pageSize,
        state.pageNumber,
        state.order,
        state.orderBy,
        state.pageFilters
      ).then((data) => {
        updatePagination(
          data?.count,
          { pageNumber: state.pageNumber, pageSize: state.pageSize },
          (pageNumber) => {
            setState((prevState) => ({ ...prevState, pageNumber }));
          }
        );
      });
    }
  };

  const moreAddressActions = (row) => {
    return (
      <div className="d-flex f-align-center">
        <Tooltip title="Edit" placement="top-start">
          <EditOutlinedIcon
            className={"ml-2 mr-2 c-pointer"}
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                openMapsDialog: row.address_id,
              }))
            }
          />
        </Tooltip>
        <Tooltip title="Delete" placement="top-start">
          <DeleteForeverOutlinedIcon
            color={"error"}
            className={"ml-2 mr-2 c-pointer"}
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                deleteAddress: true,
                deletingAddressIds: row?.address_id,
              }));
            }}
          />
        </Tooltip>
      </div>
    );
  };

  const mapColumnConfig = [
    {
      headerClassName: classes.editAddress,
      id: "col-edit",
      noResize: true,
      render: (row) => moreAddressActions(row),
    },
    {
      id: "address",
      label: "Address",
      fieldName: "address",
      render: (row) => {
        return (
          <Tooltip
            title={
              `${row?.kamAddress?.street_address}, ${row?.kamAddress?.city}, ${row?.kamAddress?.state}, ${row?.kamAddress?.zip_code}, ${row?.kamAddress?.country}` ??
              ""
            }
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {`${row?.kamAddress?.street_address}, ${row?.kamAddress?.city}, ${row?.kamAddress?.state}, ${row?.kamAddress?.zip_code}, ${row?.kamAddress?.country}` ||
                "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "phone",
      label: "Phone",
      field: "phone",
      render: (row) => {
        return (
          <Tooltip title={row?.kamAddress?.phone ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row?.kamAddress?.phone || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "primary_contact",
      label: "Primary Contact",
      field: "primary_contact",
      render: (row) => {
        const primaryContact = row?.kamAddress?.primary_contact;
        const modifiedContact =
          primaryContact && primaryContact.length > 0
            ? primaryContact.length > 10
              ? `${primaryContact.slice(0, 10)}...`
              : primaryContact
            : "-";
        return (
          <Tooltip
            title={row?.kamAddress?.primary_contact ?? ""}
            placement="top-start"
          >
            <Typography variant="body2">
              {modifiedContact}
              {/* {row?.kamAddress?.primary_contact || "-"} */}
            </Typography>
          </Tooltip>
        );
      },
    },
    // {
    //   id: "type",
    //   label: "Type",
    //   field: "type",
    //   render: (row) => {
    //     return (
    //       <Tooltip title={row?.type?.label ?? ""} placement="top-start">
    //         <Typography variant="body2">{row?.type?.label || "-"}</Typography>
    //       </Tooltip>
    //     );
    //   },
    // },
  ];

  const kamDropdown = (
    row,
    selectedICCustomerRows,
    customerICDefaultKAMChecked,
    customerICDefaultKAMUnchecked,
    customerICNameArray,
    customerICKAMChecked,
    customerICKAMUnchecked
  ) => {
    const isChecked =
      !!selectedICCustomerRows && selectedICCustomerRows.includes(row.id);

    const selectedValue = isChecked
      ? customerICDefaultKAMChecked[row.id]
      : customerICDefaultKAMUnchecked[row.id];

    const missingIds =
      !!selectedICCustomerRows &&
      selectedICCustomerRows.filter((id) => !customerICDefaultKAMChecked[id]);

    if (
      missingIds.length > 0 &&
      Object.keys(customerICDefaultKAMChecked).length > 0
    ) {
      const defaultValue = Object.values(customerICDefaultKAMChecked)[0];

      setState((prevState) => ({
        ...prevState,
        [customerICKAMChecked]: {
          ...customerICDefaultKAMChecked,
          ...missingIds.reduce((acc, id) => {
            acc[id] = defaultValue;
            return acc;
          }, {}),
        },
      }));
    }

    return (
      <Autocomplete
        className={clsx("mb-1", classes.autocompleteWidth)}
        disableClearable
        size="small"
        value={selectedValue !== undefined ? selectedValue : null}
        options={kamUsers.filter((kam) => kam.id !== state.existing_kam_id[0])}
        PopperComponent={({ style, ...props }) => (
          <Popper {...props} style={{ ...style, height: 0 }} />
        )}
        getOptionLabel={(option) =>
          option.name ||
          `${option.first_name || ""} ${option.last_name || ""}-${
            option.email || ""
          }${option.username ? `-(${option.username})` : ""}`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Default KAM"
            variant="outlined"
            required
          />
        )}
        onChange={(evt, value) => {
          const updatedState = isChecked
            ? {
                [customerICKAMChecked]: {
                  ...customerICDefaultKAMChecked,
                  ...selectedICCustomerRows.reduce((acc, checkedRowId) => {
                    acc[checkedRowId] = {
                      ...value,
                      row: customerICNameArray.find(
                        (item) => item.id === checkedRowId
                      ),
                    };
                    return acc;
                  }, {}),
                },
              }
            : {
                [customerICKAMUnchecked]: {
                  ...customerICDefaultKAMUnchecked,
                  [row.id]: {
                    ...value,
                    row: row,
                  },
                },
              };

          setState((prevState) => ({
            ...prevState,
            ...updatedState,
          }));
        }}
      />
    );
  };

  const AADropdown = (
    row,
    selectedICCustomerRows,
    customerICDefaultKAMChecked,
    customerICDefaultKAMUnchecked,
    customerICNameArray,
    customerICKAMChecked,
    customerICKAMUnchecked
  ) => {
    const isChecked =
      !!selectedICCustomerRows && selectedICCustomerRows.includes(row.id);

    const selectedValue = isChecked
      ? customerICDefaultKAMChecked[row.id]
      : customerICDefaultKAMUnchecked[row.id];

    const missingIds =
      !!selectedICCustomerRows &&
      selectedICCustomerRows.filter((id) => !customerICDefaultKAMChecked[id]);

    if (
      missingIds.length > 0 &&
      Object.keys(customerICDefaultKAMChecked).length > 0
    ) {
      const defaultValue = Object.values(customerICDefaultKAMChecked)[0];

      setState((prevState) => ({
        ...prevState,
        [customerICKAMChecked]: {
          ...customerICDefaultKAMChecked,
          ...missingIds.reduce((acc, id) => {
            acc[id] = defaultValue;
            return acc;
          }, {}),
        },
      }));
    }

    return (
      <Autocomplete
        className={clsx("mb-1", classes.autocompleteWidth)}
        disableClearable
        size="small"
        value={selectedValue !== undefined ? selectedValue : null}
        options={AAUsers.filter((aa) => aa.id !== state.existing_kam_id[0])}
        PopperComponent={({ style, ...props }) => (
          <Popper {...props} style={{ ...style, height: 0 }} />
        )}
        getOptionLabel={(option) =>
          option.name ||
          `${option.first_name || ""} ${option.last_name || ""}-${
            option.email || ""
          }${option.username ? `-(${option.username})` : ""}`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Default AA"
            variant="outlined"
            required
          />
        )}
        onChange={(evt, value) => {
          const updatedState = isChecked
            ? {
                [customerICKAMChecked]: {
                  ...customerICDefaultKAMChecked,
                  ...selectedICCustomerRows.reduce((acc, checkedRowId) => {
                    acc[checkedRowId] = {
                      ...value,
                      row: customerICNameArray.find(
                        (item) => item.id === checkedRowId
                      ),
                    };
                    return acc;
                  }, {}),
                },
              }
            : {
                [customerICKAMUnchecked]: {
                  ...customerICDefaultKAMUnchecked,
                  [row.id]: {
                    ...value,
                    row: row,
                  },
                },
              };

          setState((prevState) => ({
            ...prevState,
            ...updatedState,
          }));
        }}
      />
    );
  };

  const MGRDropdown = (
    row,
    selectedICCustomerRows,
    customerICDefaultKAMChecked,
    customerICDefaultKAMUnchecked,
    customerICNameArray,
    customerICKAMChecked,
    customerICKAMUnchecked
  ) => {
    const isChecked =
      !!selectedICCustomerRows && selectedICCustomerRows.includes(row.id);

    const selectedValue = isChecked
      ? customerICDefaultKAMChecked[row.id]
      : customerICDefaultKAMUnchecked[row.id];

    const missingIds =
      !!selectedICCustomerRows &&
      selectedICCustomerRows.filter((id) => !customerICDefaultKAMChecked[id]);

    if (
      missingIds.length > 0 &&
      Object.keys(customerICDefaultKAMChecked).length > 0
    ) {
      const defaultValue = Object.values(customerICDefaultKAMChecked)[0];

      setState((prevState) => ({
        ...prevState,
        [customerICKAMChecked]: {
          ...customerICDefaultKAMChecked,
          ...missingIds.reduce((acc, id) => {
            acc[id] = defaultValue;
            return acc;
          }, {}),
        },
      }));
    }

    return (
      <Autocomplete
        className={clsx("mb-1", classes.autocompleteWidth)}
        disableClearable
        size="small"
        value={selectedValue !== undefined ? selectedValue : null}
        options={MGRUsers.filter((kam) => kam.id !== state.existing_kam_id[0])}
        PopperComponent={({ style, ...props }) => (
          <Popper {...props} style={{ ...style, height: 0 }} />
        )}
        getOptionLabel={(option) =>
          option.name ||
          `${option.first_name || ""} ${option.last_name || ""}-${
            option.email || ""
          }${option.username ? `-(${option.username})` : ""}`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Default MGR"
            variant="outlined"
            required
          />
        )}
        onChange={(evt, value) => {
          const updatedState = isChecked
            ? {
                [customerICKAMChecked]: {
                  ...customerICDefaultKAMChecked,
                  ...selectedICCustomerRows.reduce((acc, checkedRowId) => {
                    acc[checkedRowId] = {
                      ...value,
                      row: customerICNameArray.find(
                        (item) => item.id === checkedRowId
                      ),
                    };
                    return acc;
                  }, {}),
                },
              }
            : {
                [customerICKAMUnchecked]: {
                  ...customerICDefaultKAMUnchecked,
                  [row.id]: {
                    ...value,
                    row: row,
                  },
                },
              };

          setState((prevState) => ({
            ...prevState,
            ...updatedState,
          }));
        }}
      />
    );
  };

  const customerColumnConfig = [
    {
      id: "customer",
      label: "Customer",
      field: "customer",
      render: (row) => {
        return (
          <Tooltip title={row?.customer?.name ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row?.customer?.name || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "location",
      label: "Location",
      field: "location",
      render: (row) => {
        return (
          <Tooltip title={row?.location ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row?.location || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "default_KAM",
      label: "Default KAM",
      field: "default_KAM",
      render: (row) =>
        kamDropdown(
          row,
          state.selectedCustomerRows,
          state.customerDefaultKAMChecked,
          state.customerDefaultKAMUnchecked,
          state.customerNameArray,
          "customerDefaultKAMChecked",
          "customerDefaultKAMUnchecked"
        ),
    },
  ];

  const contractorColumnConfig = [
    {
      id: "contractor",
      label: "Independent Contractor",
      field: "contractor",
      render: (row) => {
        const contractorName = `${row?.first_name || ""} ${
          row.last_name || ""
        }`;
        return (
          <Tooltip title={contractorName ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {contractorName || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "default_KAM",
      label: "Default KAM",
      field: "default_KAM",
      render: (row) =>
        kamDropdown(
          row,
          state.selectedICRows,
          state.ICDefaultKAMChecked,
          state.ICDefaultKAMUnchecked,
          state.contractorNameArray,
          "ICDefaultKAMChecked",
          "ICDefaultKAMUnchecked"
        ),
    },
  ];

  const customerColumnConfigAA = [
    {
      id: "customer",
      label: "Customer",
      field: "customer",
      render: (row) => {
        return (
          <Tooltip title={row?.customer?.name ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row?.customer?.name || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "location",
      label: "Location",
      field: "location",
      render: (row) => {
        return (
          <Tooltip title={row?.location ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row?.location || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "default_AA",
      label: "Default AA",
      field: "default_AA",
      render: (row) =>
        AADropdown(
          row,
          state.selectedCustomerRows,
          state.customerDefaultKAMChecked,
          state.customerDefaultKAMUnchecked,
          state.customerNameArray,
          "customerDefaultKAMChecked",
          "customerDefaultKAMUnchecked"
        ),
    },
  ];

  const contractorColumnConfigAA = [
    {
      id: "contractor",
      label: "Independent Contractor",
      field: "contractor",
      render: (row) => {
        const contractorName = `${row?.first_name || ""} ${
          row.last_name || ""
        }`;
        return (
          <Tooltip title={contractorName ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {contractorName || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "default_AA",
      label: "Default AA",
      field: "default_AA",
      render: (row) =>
        AADropdown(
          row,
          state.selectedICRows,
          state.ICDefaultKAMChecked,
          state.ICDefaultKAMUnchecked,
          state.contractorNameArray,
          "ICDefaultKAMChecked",
          "ICDefaultKAMUnchecked"
        ),
    },
  ];

  const customerColumnConfigMGR = [
    {
      id: "customer",
      label: "Customer",
      field: "customer",
      render: (row) => {
        return (
          <Tooltip title={row?.customer?.name ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row?.customer?.name || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "location",
      label: "Location",
      field: "location",
      render: (row) => {
        return (
          <Tooltip title={row?.location ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row?.location || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "default_mgr",
      label: "Default MGR",
      field: "default_mgr",
      render: (row) =>
        MGRDropdown(
          row,
          state.selectedCustomerRows,
          state.customerDefaultKAMChecked,
          state.customerDefaultKAMUnchecked,
          state.customerNameArray,
          "customerDefaultKAMChecked",
          "customerDefaultKAMUnchecked"
        ),
    },
  ];

  const contractorColumnConfigMGR = [
    {
      id: "contractor",
      label: "Independent Contractor",
      field: "contractor",
      render: (row) => {
        const contractorName = `${row?.first_name || ""} ${
          row.last_name || ""
        }`;
        return (
          <Tooltip title={contractorName ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {contractorName || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "default_mgr",
      label: "Default MGR",
      field: "default_ngr",
      render: (row) =>
        MGRDropdown(
          row,
          state.selectedICRows,
          state.ICDefaultKAMChecked,
          state.ICDefaultKAMUnchecked,
          state.contractorNameArray,
          "ICDefaultKAMChecked",
          "ICDefaultKAMUnchecked"
        ),
    },
  ];

  const usersColumnConfigMGR = [
    {
      id: "name",
      label: "Name",
      field: "name",
      render: (row) => {
        const userName = `${row?.first_name || ""} ${row.last_name || ""}`;
        return (
          <Tooltip title={userName ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {userName || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "role",
      label: "Role",
      field: "role",
      render: (row) => {
        return (
          <Tooltip title={row?.role ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {row?.role || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "default_mgr",
      label: "Default MGR",
      field: "default_mgr",
      render: (row) =>
        MGRDropdown(
          row,
          state.selectedICRows,
          state.ICDefaultKAMChecked,
          state.ICDefaultKAMUnchecked,
          state.usersNameArray,
          "ICDefaultKAMChecked",
          "ICDefaultKAMUnchecked"
        ),
    },
  ];

  const columnConfig = [
    {
      id: "col-edit",
      noResize: true,
      render: (row) => moreActions(row),
    },
    {
      id: "username",
      label: "Username",
      field: "username",
      canSort: true,
      hasEllipses: true,
      isHidden: !state.dynamicColumns?.username?.isSelected,
      render: (row) => {
        return (
          <>
            <Link
              underline="always"
              to="#"
              onClick={() => {
                setState((prevState) => ({
                  ...prevState,
                  openProfileModal: true,
                  editProfileImg: true,
                  userInfo: row,
                }));
              }}
            >
              <Tooltip title={row.username} placement="top-start">
                <Typography
                  variant="body2"
                  noWrap
                  className={classes.underlined}
                >
                  {row.username}
                </Typography>
              </Tooltip>
            </Link>
          </>
        );
      },
    },
    {
      headerClassName: classes.tableHeaderCell,
      id: "first_name",
      label: "First Name",
      field: "first_name",
      canSort: true,
      hasEllipses: true,
      isHidden: !state.dynamicColumns?.first_name?.isSelected,
    },
    {
      headerClassName: classes.tableHeaderCell,
      id: "last_name",
      label: "Last Name",
      field: "last_name",
      canSort: true,
      hasEllipses: true,
      isHidden: !state.dynamicColumns?.last_name?.isSelected,
    },
    {
      id: "email",
      label: "Email",
      field: "email",
      canSort: true,
      hasEllipses: true,
      isHidden: !state.dynamicColumns?.email?.isSelected,
    },
    {
      id: "phone",
      label: "Phone",
      field: "phone",
      canSort: true,
      hasEllipses: true,
      isHidden: !state.dynamicColumns?.phone?.isSelected,
    },
    {
      id: "role",
      label: "Role",
      field: "role",
      canSort: true,
      hasEllipses: true,
      isHidden: !state.dynamicColumns?.role?.isSelected,
    },
    {
      id: "manager_name",
      label: "Manager",
      fieldName: "manager_name",
      hasEllipses: true,
      isHidden: !state.dynamicColumns?.manager?.isSelected,
      canSort: true,
      render: (row) => {
        const manager = row.manager
          ? (row.manager.first_name || "") + " " + (row.manager.last_name || "")
          : "-";
        const title = row.manager?.username
          ? `${manager}-(${row.manager.username})`
          : "";
        return (
          <Tooltip title={title} placement="top-start">
            <Typography variant="body2" noWrap>
              {manager}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "location",
      label: "Location",
      field: "location",
      isHidden: !state.dynamicColumns?.location?.isSelected,
      canSort: true,
      hasEllipses: true,
    },
    {
      id: "last_login",
      label: "Last Login",
      isHidden: !state.dynamicColumns?.last_login?.isSelected,
      fieldName: "last_login",
      hasEllipses: true,
      canSort: true,
      render: (row) => {
        const lastLogin = `${
          row.last_login
            ? `${getDateString(row.last_login)} ${getFormattedTime(
                new Date(row.last_login)
              )}`
            : ""
        }`;
        return (
          <Tooltip title={lastLogin || ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {lastLogin}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.address?.isSelected,
      id: "Address",
      label: "Address",
      field: "Address",
      render: (row) => (
        <div className="c-pointer">
          {row.role === "KAM" ? (
            row.addresses === null || row.addresses === "[]" ? (
              <Tooltip title={"Add KAM Address"} placement="top-end">
                <LocationOnIcon
                  style={{ color: "#9e9e9e" }}
                  onClick={() => {
                    setState((prevState) => ({
                      ...prevState,
                      openMapsDialog: true,
                      kamID: row.id,
                      KAM_name: row.name,
                    }));
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title={"Edit KAM Address"} placement="top-end">
                <LocationOnIcon
                  style={{ color: "#004700" }}
                  onClick={() => {
                    setState((prevState) => ({
                      ...prevState,
                      openAddressDialog: true,
                      kamID: row.id,
                      formType: "KAMAddress",
                      KAM_name: row.name,
                      editLocation: state?.allLocation?.filter(
                        (location) => location.id === row.id
                      ),
                    }));
                  }}
                />
              </Tooltip>
            )
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];

  const fetchList = async (listType = "users", ...params) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    switch (listType) {
      case "customers":
        serviceMethod = "getCustomerList";
        responseKey = "customers";
        break;
      case "IC":
        serviceMethod = "getContractorList";
        responseKey = "contractors";
        break;
      case "users":
        serviceMethod = "getUsersList";
        responseKey = "users";
        break;
      default:
        serviceMethod = "getUsersList";
        responseKey = "users";
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];

    if (listType === "customers") {
      listData.map((item = {}) => {
        (item.customer_branches || [])
          .filter((branch) => !branch.is_deleted)
          .map((branch = {}) =>
            (branch.routes || [])
              .filter((route) => !route.is_deleted)
              .sort((a, b) => Intl.Collator().compare(a.name, b.name))
          );
      });
    }
    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
  };

  const handleSubmitDefaultKAM = async () => {
    const {
      existing_kam_id,
      customerDefaultKAMChecked,
      customerDefaultKAMUnchecked,
      ICDefaultKAMChecked,
      ICDefaultKAMUnchecked,
    } = state;

    const mergedCustomers = Object.values([
      ...Object.values(customerDefaultKAMChecked),
      ...Object.values(customerDefaultKAMUnchecked),
      ...Object.values(ICDefaultKAMChecked),
      ...Object.values(ICDefaultKAMUnchecked),
    ]).reduce((acc, customer) => {
      const id = customer.id;
      if (!acc[id]) {
        acc[id] = { new_kam_id: id, customers: [], contractors: [] };
      }

      const customer_id = !!customer.row.customer_id && customer.row.id;
      if (customer_id) {
        acc[id].customers.push(customer_id);
      }
      const contractor_id = !!customer.row.icid && customer.row.id;
      if (contractor_id) {
        acc[id].contractors.push(contractor_id);
      }

      return acc;
    }, {});

    const customerNameArray = Object.values(mergedCustomers);

    setState((prevState) => ({ ...prevState, isLoading: true }));
    const serviceMethod = "patch";

    const payload = {
      existing_kam_id: existing_kam_id[0],
      new_mappings: customerNameArray,
    };

    const { error } = await Service[serviceMethod](payload);

    if (error) {
      setState((prevState) => ({ ...prevState, isLoading: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success(`Updated successfully.`);
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      selectDefaultKAM: false,
      customerDefaultKAM: defaultState.customerDefaultKAM,
      contractorDefaultKAM: defaultState.contractorDefaultKAM,
      customerDefaultKAMChecked: defaultState.customerDefaultKAMChecked,
      customerDefaultKAMUnchecked: defaultState.customerDefaultKAMUnchecked,
      ICDefaultKAMUnchecked: defaultState.ICDefaultKAMUnchecked,
      ICDefaultKAMChecked: defaultState.ICDefaultKAMChecked,
      currentStep: defaultState.currentStep,
      selectedCustomerRows: defaultState.selectedCustomerRows,
      selectedICRows: defaultState.selectedICRows,
    }));
    fetchEntries(
      state.search,
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    );
    fetchList();
  };

  const handleSubmitDefaultAA = async () => {
    const {
      existing_kam_id,
      customerDefaultKAMChecked,
      customerDefaultKAMUnchecked,
      ICDefaultKAMChecked,
      ICDefaultKAMUnchecked,
    } = state;

    const mergedCustomers = Object.values([
      ...Object.values(customerDefaultKAMChecked),
      ...Object.values(customerDefaultKAMUnchecked),
      ...Object.values(ICDefaultKAMChecked),
      ...Object.values(ICDefaultKAMUnchecked),
    ]).reduce((acc, customer) => {
      const id = customer.id;
      if (!acc[id]) {
        acc[id] = { new_aa_id: id, customers: [], contractors: [] };
      }

      const customer_id = !!customer.row.customer_id && customer.row.id;
      if (customer_id) {
        acc[id].customers.push(customer_id);
      }
      const contractor_id = !!customer.row.icid && customer.row.id;
      if (contractor_id) {
        acc[id].contractors.push(contractor_id);
      }

      return acc;
    }, {});

    const customerNameArray = Object.values(mergedCustomers);

    setState((prevState) => ({ ...prevState, isLoading: true }));
    const serviceMethod = "patchUpdateAA";

    const payload = {
      existing_aa_id: existing_kam_id[0],
      new_mappings: customerNameArray,
    };

    const { error } = await Service[serviceMethod](payload);

    if (error) {
      setState((prevState) => ({ ...prevState, isLoading: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success(`Updated successfully.`);
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      selectDefaultAA: false,
      customerDefaultKAM: defaultState.customerDefaultKAM,
      contractorDefaultKAM: defaultState.contractorDefaultKAM,
      customerDefaultKAMChecked: defaultState.customerDefaultKAMChecked,
      customerDefaultKAMUnchecked: defaultState.customerDefaultKAMUnchecked,
      ICDefaultKAMUnchecked: defaultState.ICDefaultKAMUnchecked,
      ICDefaultKAMChecked: defaultState.ICDefaultKAMChecked,
      currentStep: defaultState.currentStep,
      selectedCustomerRows: defaultState.selectedCustomerRows,
      selectedICRows: defaultState.selectedICRows,
    }));
    fetchEntries(
      state.search,
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    );
    fetchList();
  };

  const handleSubmitDefaultMGR = async () => {
    const {
      existing_kam_id,
      customerDefaultKAMChecked,
      customerDefaultKAMUnchecked,
      ICDefaultKAMChecked,
      ICDefaultKAMUnchecked,
    } = state;

    const mergedCustomers = Object.values([
      ...Object.values(customerDefaultKAMChecked),
      ...Object.values(customerDefaultKAMUnchecked),
      ...Object.values(ICDefaultKAMChecked),
      ...Object.values(ICDefaultKAMUnchecked),
    ]).reduce((acc, customer) => {
      const id = customer.id;
      if (!acc[id]) {
        acc[id] = { new_mgr_id: id, customers: [], associatedUsers: [] };
      }

      const customer_id = !!customer?.row?.customer_id && customer?.row?.id;
      if (customer_id) {
        acc[id].customers.push(customer_id);
      }
      const user_id = !!customer?.row?.id && customer?.row?.id;
      if (!!user_id && customer_id !== user_id) {
        acc[id].associatedUsers.push(user_id);
      }

      return acc;
    }, {});

    const customerNameArray = Object.values(mergedCustomers);

    setState((prevState) => ({ ...prevState, isLoading: true }));
    const serviceMethod = "patchUpdateMGR";

    const payload = {
      existing_mgr_id: existing_kam_id[0],
      new_mappings: customerNameArray,
    };

    const { error } = await Service[serviceMethod](payload);

    if (error) {
      setState((prevState) => ({ ...prevState, isLoading: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success(`Updated successfully.`);
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      selectDefaultMGR: false,
      customerDefaultKAM: defaultState.customerDefaultKAM,
      contractorDefaultKAM: defaultState.contractorDefaultKAM,
      customerDefaultKAMChecked: defaultState.customerDefaultKAMChecked,
      customerDefaultKAMUnchecked: defaultState.customerDefaultKAMUnchecked,
      ICDefaultKAMUnchecked: defaultState.ICDefaultKAMUnchecked,
      ICDefaultKAMChecked: defaultState.ICDefaultKAMChecked,
      currentStep: defaultState.currentStep,
      selectedCustomerRows: defaultState.selectedCustomerRows,
      selectedICRows: defaultState.selectedICRows,
    }));
    fetchEntries(
      state.search,
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    );
    fetchList();
  };

  const kamUsers = appData.users.filter(
    (user) => user.role === "KAM" && user.is_active
  );

  const AAUsers = appData.users.filter(
    (user) => user.role === "AA" && user.is_active
  );

  const MGRUsers = appData.users.filter(
    (user) => user.role === "MGR" && user.is_active
  );

  return (
    <div id="numbers-page-wrapper">
      <div className="mr-5">
        <div className={clsx("  mr-10", classes.fixedHeader)}>
          <Typography variant="h4" color="primary" className=" ml-2">
            Users
            <Button
              style={{ float: "right" }}
              className="mt-1 mr-2"
              variant="contained"
              color="primary"
              onClick={() => history.push("/home")}
              startIcon={<ArrowBackIcon />}
            >
              Back To Home
            </Button>
          </Typography>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            {!isRoleView
              ? "Add/Edit User's information."
              : "View User's information."}
          </Typography>
        </div>
        <div className="d-flex f-justify-between f-align-center">
          <div className="d-flex"></div>
          <div className="d-flex f-align-center">
            <Typography variant="body1">Enter to search by: </Typography>
            <TextField
              type="text"
              variant="outlined"
              className="mr-5 ml-2"
              size="small"
              defaultValue={state.search}
              placeholder="Name, Email, Username"
              InputProps={{ endAdornment: <SearchIcon /> }}
              onChange={(evt) => handleSearch((evt.target.value || "").trim())}
            />
            <Autocomplete
              multiple
              classes={{
                root: classes.kamInput,
              }}
              size="small"
              options={USER_ROLES}
              defaultValue={state.role}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" placeholder="Role" />
              )}
              onChange={(evt, value) => {
                setState((prevState) => ({
                  ...prevState,
                  role: value,
                  filters: {
                    ...prevState.filters,
                    role: value.map((item) => ({
                      field: "role",
                      type: "iLike",
                      value: item,
                    })),
                  },
                  pageNumber: defaultState.pageNumber,
                }));
                setPageConfig(PAGE_KEYS.USERS, {
                  roleFilter: {
                    value,
                    role: value.map((item) => ({
                      field: "role",
                      type: "iLike",
                      value: item,
                    })),
                  },
                });
              }}
            />
          </div>
        </div>
        <Paper
          elevation={2}
          className={clsx("p-4 mt-10", classes.paperSpacing)}
        >
          <div
            className={clsx(
              !isRoleView
                ? "d-flex f-align-center f-justify-between p-2 pl-4 pr-4"
                : "d-flex f-align-center f-justify-end p-2 pl-4 pr-4",
              classes.actionsWrapper
            )}
          >
            {!isRoleView && (
              <div>
                <Button
                  startIcon={<PlaylistAddIcon />}
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    setState((prevState) => ({ ...prevState, isNew: true }))
                  }
                >
                  New
                </Button>
              </div>
            )}
            <div className="d-flex f-align-center">
              <Dropdown
                classes={{
                  paper: classes.dropdownPaper,
                }}
                placement="bottom-end"
                options={STATUS.map((item) => ({
                  value: item,
                  label: item,
                }))}
                labelEllipses
                hasEllipses
                label={`Show: ${state.statusUser || "All"}`}
                onChange={(evt) => {
                  setState((prevState) => ({
                    ...prevState,
                    statusUser: evt?.value,
                    filters: {
                      ...prevState.filters,

                      userStatus:
                        evt?.value === "All"
                          ? []
                          : [
                              {
                                field: "is_active",
                                type: "=",
                                value:
                                  evt?.value === "Only Active" ? true : false,
                              },
                            ],
                    },
                    pageNumber: defaultState.pageNumber,
                  }));
                  setPageConfig(PAGE_KEYS.USERS, {
                    statusUser: {
                      value: evt?.value,
                      userStatus:
                        evt?.value === "All"
                          ? []
                          : [
                              {
                                field: "is_active",
                                type: "=",
                                value:
                                  evt?.value === "Only Active" ? true : false,
                              },
                            ],
                    },
                  });
                }}
              />
              <Dropdown
                classes={{
                  paper: classes.tunePaper,
                }}
                disablePortal={false}
                remainOpen
                isMultiSelect
                placement="bottom-end"
                options={Object.keys(state.dynamicColumns).map(
                  (key) => state.dynamicColumns[key]
                )}
                customToggle={({ anchorRef, onClick }) => (
                  <Tooltip title="Show/Hide column(s)" placement="top-start">
                    <IconButton
                      color="primary"
                      ref={anchorRef}
                      onClick={onClick}
                    >
                      <TuneIcon />
                    </IconButton>
                  </Tooltip>
                )}
                onChange={(options) => {
                  const isSelectAll = options.some(
                    (item) => item.value === "select_all"
                  );
                  const isCurrentSelectAll =
                    state.dynamicColumns?.select_all?.isSelected;

                  const isClickedOnSelectAll =
                    isSelectAll !== isCurrentSelectAll;

                  const isAllOptionSelected =
                    Object.keys(state.dynamicColumns).length - 1 ===
                    options.filter((item) => item.value !== "select_all")
                      .length;
                  const updatedDynamicColumns = Object.keys(
                    state.dynamicColumns
                  ).reduce((acc, key) => {
                    const isSelected = isClickedOnSelectAll
                      ? isSelectAll
                      : key === "select_all"
                      ? isAllOptionSelected
                      : !!options.some((item) => item.value === key);

                    return {
                      ...acc,
                      [key]: {
                        ...state.dynamicColumns[key],
                        isSelected,
                      },
                    };
                  }, {});
                  setPageConfig(PAGE_KEYS.USERS, {
                    dynamicColumns: updatedDynamicColumns,
                  });
                  setState((prevState) => ({
                    ...prevState,
                    dynamicColumns: {
                      ...prevState.dynamicColumns,
                      ...updatedDynamicColumns,
                    },
                  }));
                }}
              />
              <Tooltip placement="top-start" title="Filter">
                <IconButton
                  color="primary"
                  className={clsx({
                    "bg-primary": !!state.pageFilters?.length,
                  })}
                  onClick={() => {
                    setState((prevState) => ({
                      ...prevState,
                      isFiltering: true,
                    }));
                  }}
                >
                  <FilterListIcon
                    className={clsx({
                      "color-white": !!state.pageFilters?.length,
                    })}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip placement="top-end" title="Reset All Filters">
                <IconButton
                  // disabled={pageConfig === undefined || pageConfig === null}
                  color="primary"
                  onClick={() => {
                    updatePageConfig("users");
                    window.location.reload();
                  }}
                >
                  <RotateLeftIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          {!!state.markActiveInactiveIds.length && (
            <ActionDialog
              classes={{
                confirm: clsx({ "bg-primary": !state.isLoading }),
              }}
              open={!!state.markActiveInactiveIds.length}
              contentText={`Are you sure you want to mark the user ${
                state.markActiveInactiveIds[0]?.status ? "inactive" : "active"
              }?`}
              onConfirm={() =>
                handleMarkActiveInactive(state.markActiveInactiveIds)
              }
              onCancel={() =>
                setState((prevState) => ({
                  ...prevState,
                  markActiveInactiveIds: [],
                }))
              }
              isConfirmDisabled={state.isLoading}
              positiveActionLabel={
                <>
                  Mark{" "}
                  {state.markActiveInactiveIds[0]?.status
                    ? "inactive"
                    : "active"}
                  {state.isLoading && (
                    <CircularProgress
                      size={24}
                      className="p-absolute progress-btn"
                    />
                  )}
                </>
              }
            />
          )}
          {state.showManagerAssignedTokAM && (
            <ActionDialog
              classes={{
                confirm: "bg-primary",
              }}
              open={state.showManagerAssignedTokAM}
              contentText={
                <div>
                  {state.errorMessage}
                  {!!state.kamNameArray.length && (
                    <p className={"text-bold"}>KAMs:</p>
                  )}
                  <ul>
                    <b>
                      {state.kamNameArray?.map((kamName) => {
                        return <li>{kamName}</li>;
                      })}
                    </b>
                  </ul>
                  {!!state.customerNameArray.length && (
                    <p className={"text-bold"}>Customers:</p>
                  )}
                  <ul>
                    <b>
                      {state.customerNameArray?.map((customerName) => {
                        return <li>{customerName}</li>;
                      })}
                    </b>
                  </ul>
                </div>
              }
              onConfirm={() => {
                setState((prevState) => ({
                  ...prevState,
                  showManagerAssignedTokAM: false,
                }));
                fetchEntries(
                  state.search,
                  state.filters,
                  state.pageSize,
                  state.pageNumber,
                  state.order,
                  state.orderBy,
                  state.pageFilters
                );
              }}
              onCancel={() => {
                setState((prevState) => ({
                  ...prevState,
                  showManagerAssignedTokAM: false,
                }));
                fetchEntries(
                  state.search,
                  state.filters,
                  state.pageSize,
                  state.pageNumber,
                  state.order,
                  state.orderBy,
                  state.pageFilters
                );
              }}
              isConfirmDisabled={state.isLoading}
            />
          )}

          {state.selectDefaultKAM && (
            <ActionDialog
              classes={{
                confirm:
                  state.isLoading ||
                  !(state.currentStep === 2
                    ? state.contractorNameArray.length ===
                      Object.values({
                        ...state.ICDefaultKAMChecked,
                        ...state.ICDefaultKAMUnchecked,
                      }).length
                    : state.currentStep === 1 &&
                      !!state.customerNameArray.length
                    ? !!state.customerNameArray.length &&
                      state.customerNameArray.length ===
                        Object.values({
                          ...state.customerDefaultKAMChecked,
                          ...state.customerDefaultKAMUnchecked,
                        }).length
                    : !!state.contractorNameArray.length &&
                      state.contractorNameArray.length ===
                        Object.values({
                          ...state.ICDefaultKAMChecked,
                          ...state.ICDefaultKAMUnchecked,
                        }).length)
                    ? ""
                    : "bg-primary",
                paper: classes.modalDimensions,
              }}
              open={state.selectDefaultKAM}
              contentText={
                <div>
                  {state.currentStep === 2 &&
                    !!state.customerNameArray.length &&
                    !!state.contractorNameArray.length && (
                      <Tooltip title="Click to go back" placement="top-start">
                        <IconButton
                          color="primary"
                          className="mb-4"
                          style={{
                            position: "absolute",
                            bottom: "8px",
                            left: "26px",
                          }}
                          onClick={() => {
                            setState((prevState) => ({
                              ...prevState,
                              currentStep: 1,
                            }));
                          }}
                        >
                          <ArrowBackIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  <Typography className="text-bold mb-4">{`This KAM is present as a Default KAM with the following ${
                    state.currentStep === 1 && !!state.customerNameArray.length
                      ? "Customers"
                      : "Independent Contractors"
                  }:`}</Typography>
                  {state.currentStep === 1 &&
                    !!state.customerNameArray.length && (
                      <Grid
                        columns={customerColumnConfig}
                        rows={state.customerNameArray?.sort((a, b) =>
                          a?.customer?.name.localeCompare(b?.customer?.name)
                        )}
                        selectedCustomerRows={state.selectedCustomerRows}
                        actionBarConfig={null}
                        isLoading={state.isDataFetching}
                        onReady={(instance) => (gridHelper = instance)}
                        hasPagination={false}
                        onSelectionChange={handleCustomerSelectionChange}
                        hasSelection={true}
                      />
                    )}
                  {((state.currentStep === 2 &&
                    !!state.contractorNameArray.length &&
                    !!state.customerNameArray.length) ||
                    (!!state.contractorNameArray.length &&
                      state.customerNameArray.length === 0)) && (
                    <Grid
                      columns={contractorColumnConfig}
                      rows={state.contractorNameArray?.sort((a, b) =>
                        a?.first_name.localeCompare(b?.first_name)
                      )}
                      selectedICRows={state.selectedICRows}
                      actionBarConfig={null}
                      isLoading={state.isDataFetching}
                      onReady={(instance) => (gridHelper = instance)}
                      hasPagination={false}
                      onSelectionChange={handleICSelectionChange}
                      hasSelection={true}
                    />
                  )}
                </div>
              }
              positiveActionLabel={
                state.currentStep === 1 &&
                !!state.customerNameArray.length &&
                !!state.contractorNameArray.length ? (
                  "Next"
                ) : state.currentStep === 2 &&
                  !!state.customerNameArray.length &&
                  !!state.contractorNameArray.length ? (
                  <>
                    Update
                    {state.isLoading && (
                      <CircularProgress
                        size={24}
                        className="p-absolute progress-btn"
                      />
                    )}
                  </>
                ) : (
                  <>
                    Update
                    {state.isLoading && (
                      <CircularProgress
                        size={24}
                        className="p-absolute progress-btn"
                      />
                    )}
                  </>
                )
              }
              onConfirm={() => {
                if (
                  state.currentStep === 1 &&
                  !!state.customerNameArray.length &&
                  !!state.contractorNameArray.length
                ) {
                  setState((prevState) => ({
                    ...prevState,
                    currentStep: 2,
                  }));
                } else if (
                  state.currentStep === 1 &&
                  !!state.customerNameArray.length &&
                  state.contractorNameArray.length === 0
                ) {
                  handleSubmitDefaultKAM();
                } else if (
                  (state.currentStep === 2 &&
                    !!state.contractorNameArray.length) ||
                  (!!state.contractorNameArray.length &&
                    state.customerNameArray.length === 0)
                ) {
                  handleSubmitDefaultKAM();
                }
              }}
              onCancel={() => {
                setState((prevState) => ({
                  ...prevState,
                  selectDefaultKAM: false,
                  existing_kam_id: [],
                  customerDefaultKAM: defaultState.customerDefaultKAM,
                  contractorDefaultKAM: defaultState.contractorDefaultKAM,
                  customerDefaultKAMChecked:
                    defaultState.customerDefaultKAMChecked,
                  customerDefaultKAMUnchecked:
                    defaultState.customerDefaultKAMUnchecked,
                  ICDefaultKAMUnchecked: defaultState.ICDefaultKAMUnchecked,
                  ICDefaultKAMChecked: defaultState.ICDefaultKAMChecked,
                  currentStep: defaultState.currentStep,
                  selectedCustomerRows: defaultState.selectedCustomerRows,
                  selectedICRows: defaultState.selectedICRows,
                }));
                fetchEntries(
                  state.search,
                  state.filters,
                  state.pageSize,
                  state.pageNumber,
                  state.order,
                  state.orderBy,
                  state.pageFilters
                );
              }}
              isConfirmDisabled={
                state.isLoading ||
                !(state.currentStep === 2
                  ? state.contractorNameArray.length ===
                    Object.values({
                      ...state.ICDefaultKAMChecked,
                      ...state.ICDefaultKAMUnchecked,
                    }).length
                  : state.currentStep === 1 && !!state.customerNameArray.length
                  ? !!state.customerNameArray.length &&
                    state.customerNameArray.length ===
                      Object.values({
                        ...state.customerDefaultKAMChecked,
                        ...state.customerDefaultKAMUnchecked,
                      }).length
                  : !!state.contractorNameArray.length &&
                    state.contractorNameArray.length ===
                      Object.values({
                        ...state.ICDefaultKAMChecked,
                        ...state.ICDefaultKAMUnchecked,
                      }).length)
              }
            />
          )}
          {state.selectDefaultAA && (
            <ActionDialog
              classes={{
                confirm:
                  state.isLoading ||
                  !(state.currentStep === 2
                    ? state.contractorNameArray.length ===
                      Object.values({
                        ...state.ICDefaultKAMChecked,
                        ...state.ICDefaultKAMUnchecked,
                      }).length
                    : state.currentStep === 1 &&
                      !!state.customerNameArray.length
                    ? !!state.customerNameArray.length &&
                      state.customerNameArray.length ===
                        Object.values({
                          ...state.customerDefaultKAMChecked,
                          ...state.customerDefaultKAMUnchecked,
                        }).length
                    : !!state.contractorNameArray.length &&
                      state.contractorNameArray.length ===
                        Object.values({
                          ...state.ICDefaultKAMChecked,
                          ...state.ICDefaultKAMUnchecked,
                        }).length)
                    ? ""
                    : "bg-primary",
                paper: classes.modalDimensions,
              }}
              open={state.selectDefaultAA}
              contentText={
                <div>
                  {state.currentStep === 2 &&
                    !!state.customerNameArray.length &&
                    !!state.contractorNameArray.length && (
                      <Tooltip title="Click to go back" placement="top-start">
                        <IconButton
                          color="primary"
                          className="mb-4"
                          style={{
                            position: "absolute",
                            bottom: "8px",
                            left: "26px",
                          }}
                          onClick={() => {
                            setState((prevState) => ({
                              ...prevState,
                              currentStep: 1,
                            }));
                          }}
                        >
                          <ArrowBackIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  <Typography className="text-bold mb-4">{`This AA is present as a Default AA with the following ${
                    state.currentStep === 1 && !!state.customerNameArray.length
                      ? "Customers"
                      : "Independent Contractors"
                  }:`}</Typography>
                  {state.currentStep === 1 &&
                    !!state.customerNameArray.length && (
                      <Grid
                        columns={customerColumnConfigAA}
                        rows={state.customerNameArray?.sort((a, b) =>
                          a?.customer?.name.localeCompare(b?.customer?.name)
                        )}
                        selectedCustomerRows={state.selectedCustomerRows}
                        actionBarConfig={null}
                        isLoading={state.isDataFetching}
                        onReady={(instance) => (gridHelper = instance)}
                        hasPagination={false}
                        onSelectionChange={handleCustomerSelectionChange}
                        hasSelection={true}
                      />
                    )}
                  {((state.currentStep === 2 &&
                    !!state.contractorNameArray.length &&
                    !!state.customerNameArray.length) ||
                    (!!state.contractorNameArray.length &&
                      state.customerNameArray.length === 0)) && (
                    <Grid
                      columns={contractorColumnConfigAA}
                      rows={state.contractorNameArray?.sort((a, b) =>
                        a?.first_name.localeCompare(b?.first_name)
                      )}
                      selectedICRows={state.selectedICRows}
                      actionBarConfig={null}
                      isLoading={state.isDataFetching}
                      onReady={(instance) => (gridHelper = instance)}
                      hasPagination={false}
                      onSelectionChange={handleICSelectionChange}
                      hasSelection={true}
                    />
                  )}
                </div>
              }
              positiveActionLabel={
                state.currentStep === 1 &&
                !!state.customerNameArray.length &&
                !!state.contractorNameArray.length ? (
                  "Next"
                ) : state.currentStep === 2 &&
                  !!state.customerNameArray.length &&
                  !!state.contractorNameArray.length ? (
                  <>
                    Update
                    {state.isLoading && (
                      <CircularProgress
                        size={24}
                        className="p-absolute progress-btn"
                      />
                    )}
                  </>
                ) : (
                  <>
                    Update
                    {state.isLoading && (
                      <CircularProgress
                        size={24}
                        className="p-absolute progress-btn"
                      />
                    )}
                  </>
                )
              }
              onConfirm={() => {
                if (
                  state.currentStep === 1 &&
                  !!state.customerNameArray.length &&
                  !!state.contractorNameArray.length
                ) {
                  setState((prevState) => ({
                    ...prevState,
                    currentStep: 2,
                  }));
                } else if (
                  state.currentStep === 1 &&
                  !!state.customerNameArray.length &&
                  state.contractorNameArray.length === 0
                ) {
                  handleSubmitDefaultAA();
                } else if (
                  (state.currentStep === 2 &&
                    !!state.contractorNameArray.length) ||
                  (!!state.contractorNameArray.length &&
                    state.customerNameArray.length === 0)
                ) {
                  handleSubmitDefaultAA();
                }
              }}
              onCancel={() => {
                setState((prevState) => ({
                  ...prevState,
                  selectDefaultAA: false,
                  existing_kam_id: [],
                  customerDefaultKAM: defaultState.customerDefaultKAM,
                  contractorDefaultKAM: defaultState.contractorDefaultKAM,
                  customerDefaultKAMChecked:
                    defaultState.customerDefaultKAMChecked,
                  customerDefaultKAMUnchecked:
                    defaultState.customerDefaultKAMUnchecked,
                  ICDefaultKAMUnchecked: defaultState.ICDefaultKAMUnchecked,
                  ICDefaultKAMChecked: defaultState.ICDefaultKAMChecked,
                  currentStep: defaultState.currentStep,
                  selectedCustomerRows: defaultState.selectedCustomerRows,
                  selectedICRows: defaultState.selectedICRows,
                }));
                fetchEntries(
                  state.search,
                  state.filters,
                  state.pageSize,
                  state.pageNumber,
                  state.order,
                  state.orderBy,
                  state.pageFilters
                );
              }}
              isConfirmDisabled={
                state.isLoading ||
                !(state.currentStep === 2
                  ? state.contractorNameArray.length ===
                    Object.values({
                      ...state.ICDefaultKAMChecked,
                      ...state.ICDefaultKAMUnchecked,
                    }).length
                  : state.currentStep === 1 && !!state.customerNameArray.length
                  ? !!state.customerNameArray.length &&
                    state.customerNameArray.length ===
                      Object.values({
                        ...state.customerDefaultKAMChecked,
                        ...state.customerDefaultKAMUnchecked,
                      }).length
                  : !!state.contractorNameArray.length &&
                    state.contractorNameArray.length ===
                      Object.values({
                        ...state.ICDefaultKAMChecked,
                        ...state.ICDefaultKAMUnchecked,
                      }).length)
              }
            />
          )}

          {state.selectDefaultMGR && (
            <ActionDialog
              classes={{
                confirm:
                  state.isLoading ||
                  !(state.currentStep === 2
                    ? state.usersNameArray.length ===
                      Object.values({
                        ...state.ICDefaultKAMChecked,
                        ...state.ICDefaultKAMUnchecked,
                      }).length
                    : state.currentStep === 1 &&
                      !!state.customerNameArray.length
                    ? !!state.customerNameArray.length &&
                      state.customerNameArray.length ===
                        Object.values({
                          ...state.customerDefaultKAMChecked,
                          ...state.customerDefaultKAMUnchecked,
                        }).length
                    : !!state.usersNameArray.length &&
                      state.usersNameArray.length ===
                        Object.values({
                          ...state.ICDefaultKAMChecked,
                          ...state.ICDefaultKAMUnchecked,
                        }).length)
                    ? ""
                    : "bg-primary",
                paper: classes.modalDimensions,
              }}
              open={state.selectDefaultMGR}
              contentText={
                <div>
                  {state.currentStep === 2 &&
                    !!state.customerNameArray.length &&
                    !!state.usersNameArray.length && (
                      <Tooltip title="Click to go back" placement="top-start">
                        <IconButton
                          color="primary"
                          className="mb-4"
                          style={{
                            position: "absolute",
                            bottom: "8px",
                            left: "26px",
                          }}
                          onClick={() => {
                            setState((prevState) => ({
                              ...prevState,
                              currentStep: 1,
                            }));
                          }}
                        >
                          <ArrowBackIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  <Typography className="text-bold mb-4">{`This Manager is present as a Default Manager with the following ${
                    state.currentStep === 1 && !!state.customerNameArray.length
                      ? "Customers"
                      : "Users"
                  }:`}</Typography>
                  {state.currentStep === 1 &&
                    !!state.customerNameArray.length && (
                      <Grid
                        columns={customerColumnConfigMGR}
                        rows={state.customerNameArray?.sort((a, b) =>
                          a?.customer?.name.localeCompare(b?.customer?.name)
                        )}
                        selectedCustomerRows={state.selectedCustomerRows}
                        actionBarConfig={null}
                        isLoading={state.isDataFetching}
                        onReady={(instance) => (gridHelper = instance)}
                        hasPagination={false}
                        onSelectionChange={handleCustomerSelectionChange}
                        hasSelection={true}
                      />
                    )}
                  {/* {((state.currentStep === 2 &&
                    !!state.contractorNameArray.length &&
                    !!state.customerNameArray.length) ||
                    (!!state.contractorNameArray.length &&
                      state.customerNameArray.length === 0)) && (
                    <Grid
                      columns={contractorColumnConfigMGR}
                      rows={state.contractorNameArray?.sort((a, b) =>
                        a?.first_name.localeCompare(b?.first_name)
                      )}
                      selectedICRows={state.selectedICRows}
                      actionBarConfig={null}
                      isLoading={state.isDataFetching}
                      onReady={(instance) => (gridHelper = instance)}
                      hasPagination={false}
                      onSelectionChange={handleICSelectionChange}
                      hasSelection={true}
                    />
                  )} */}

                  {((state.currentStep === 2 &&
                    !!state.usersNameArray.length &&
                    !!state.customerNameArray.length) ||
                    (!!state.usersNameArray.length &&
                      state.customerNameArray.length === 0)) && (
                    <Grid
                      columns={usersColumnConfigMGR}
                      rows={state.usersNameArray?.sort((a, b) =>
                        a?.first_name.localeCompare(b?.first_name)
                      )}
                      selectedICRows={state.selectedICRows}
                      actionBarConfig={null}
                      isLoading={state.isDataFetching}
                      onReady={(instance) => (gridHelper = instance)}
                      hasPagination={false}
                      onSelectionChange={handleICSelectionChange}
                      hasSelection={true}
                    />
                  )}
                </div>
              }
              positiveActionLabel={
                state.currentStep === 1 &&
                !!state.customerNameArray.length &&
                !!state.usersNameArray.length ? (
                  "Next"
                ) : state.currentStep === 2 &&
                  !!state.customerNameArray.length &&
                  !!state.usersNameArray.length ? (
                  <>
                    Update
                    {state.isLoading && (
                      <CircularProgress
                        size={24}
                        className="p-absolute progress-btn"
                      />
                    )}
                  </>
                ) : (
                  <>
                    Update
                    {state.isLoading && (
                      <CircularProgress
                        size={24}
                        className="p-absolute progress-btn"
                      />
                    )}
                  </>
                )
              }
              onConfirm={() => {
                if (
                  state.currentStep === 1 &&
                  !!state.customerNameArray.length &&
                  !!state.usersNameArray.length
                ) {
                  setState((prevState) => ({
                    ...prevState,
                    currentStep: 2,
                  }));
                } else if (
                  state.currentStep === 1 &&
                  !!state.customerNameArray.length &&
                  state.usersNameArray.length === 0
                ) {
                  handleSubmitDefaultMGR();
                } else if (
                  (state.currentStep === 2 && !!state.usersNameArray.length) ||
                  (!!state.usersNameArray.length &&
                    state.customerNameArray.length === 0)
                ) {
                  handleSubmitDefaultMGR();
                }
              }}
              onCancel={() => {
                setState((prevState) => ({
                  ...prevState,
                  selectDefaultMGR: false,
                  existing_kam_id: [],
                  customerDefaultKAM: defaultState.customerDefaultKAM,
                  contractorDefaultKAM: defaultState.contractorDefaultKAM,
                  customerDefaultKAMChecked:
                    defaultState.customerDefaultKAMChecked,
                  customerDefaultKAMUnchecked:
                    defaultState.customerDefaultKAMUnchecked,
                  ICDefaultKAMUnchecked: defaultState.ICDefaultKAMUnchecked,
                  ICDefaultKAMChecked: defaultState.ICDefaultKAMChecked,
                  currentStep: defaultState.currentStep,
                  selectedCustomerRows: defaultState.selectedCustomerRows,
                  selectedICRows: defaultState.selectedICRows,
                }));
                fetchEntries(
                  state.search,
                  state.filters,
                  state.pageSize,
                  state.pageNumber,
                  state.order,
                  state.orderBy,
                  state.pageFilters
                );
              }}
              isConfirmDisabled={
                state.isLoading ||
                !(state.currentStep === 2
                  ? state.usersNameArray.length ===
                    Object.values({
                      ...state.ICDefaultKAMChecked,
                      ...state.ICDefaultKAMUnchecked,
                    }).length
                  : state.currentStep === 1 && !!state.customerNameArray.length
                  ? !!state.customerNameArray.length &&
                    state.customerNameArray.length ===
                      Object.values({
                        ...state.customerDefaultKAMChecked,
                        ...state.customerDefaultKAMUnchecked,
                      }).length
                  : !!state.usersNameArray.length &&
                    state.usersNameArray.length ===
                      Object.values({
                        ...state.ICDefaultKAMChecked,
                        ...state.ICDefaultKAMUnchecked,
                      }).length)
              }
            />
          )}
          <Grid
            columns={columnConfig}
            rows={state.entries.map((entry) => ({
              ...entry,
              className: !entry.is_active && classes.highlightDisable,
              fullName: `${entry.first_name} ${entry.last_name}`,
            }))}
            actionBarConfig={null}
            isLoading={state.isFetching}
            onReady={(instance) => (gridHelper = instance)}
            totalRows={state.totalEntries}
            pageSize={state.pageSize}
            pageNumber={state.pageNumber}
            order={state.order}
            orderBy={state.orderBy}
            onPageNumberChange={handlePageNumberChange}
            onPageSizeChange={handlePageSizeChange}
            onSelectionChange={handleSelectionChange}
            onSortChange={handleSortChange}
            hasSelection={false}
            search={state.search}
            rowEvents={[
              {
                type: "onDoubleClick",
                handler: (row) => {
                  if (isRoleView) {
                    return false;
                  }
                  setState((prevState) => ({
                    ...prevState,
                    rowBeingEdited: row,
                  }));
                },
              },
            ]}
            classes={{
              container: appData.isTabletView && classes.gridPaper,
            }}
          />
          {(state.isNew || state.rowBeingEdited) && (
            <Form
              open={state.isNew || state.rowBeingEdited}
              isNew={state.isNew}
              entry={state.rowBeingEdited}
              currentUser={currentUser}
              managerUsersOptions={state.managerList}
              fetchManagers={fetchManagers}
              onClose={(isSubmitted = false) => {
                setState((prevState) => ({
                  ...prevState,
                  isNew: false,
                  rowBeingEdited: null,
                }));
                if (typeof isSubmitted === "boolean" && isSubmitted) {
                  fetchEntries(
                    state.search,
                    state.filters,
                    state.pageSize,
                    state.pageNumber,
                    state.order,
                    state.orderBy,
                    state.pageFilters
                  );
                }
              }}
            />
          )}
        </Paper>
        {state.deletingEntryIds && (
          <ActionDialog
            classes={{
              confirm: "bg-danger",
              paper: classes.paperSize,
            }}
            open={!!state.deletingEntryIds}
            contentText="Are you sure you want to delete?"
            onConfirm={() => handleDelete(state.deletingEntryIds)}
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                deletingEntryIds: null,
              }))
            }
            isConfirmDisabled={state.isLoading}
            positiveActionLabel={
              <>
                Delete
                {state.isLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}
        {state.isFiltering && (
          <FilterComponent
            isFiltering={state.isFiltering}
            filterConfig={filterConfig}
            appliedFilters={state.pageFilters}
            onFilterApply={handleFilterChange}
            onClose={() => {
              setState((prevState) => ({
                ...prevState,
                isFiltering: false,
              }));
            }}
          />
        )}
        {state.openProfileModal && (
          <ProfileModal
            open={state.openProfileModal}
            editProfileImg={state.editProfileImg}
            userInfo={state.userInfo}
            onClose={handleClose}
          />
        )}
        {state.openMapsDialog && (
          <Maps
            isTabletView={appData.isTabletView}
            allLocation={
              state.allLocation.length > 0
                ? state.allLocation
                    .filter((location) => location.id === state.kamID)
                    .flatMap((address) => address.addresses)
                    .flatMap((address) => {
                      if (!address) return [];
                      return JSON.parse(address);
                    })
                    .filter(
                      (address) => address.address_id === state.openMapsDialog
                    )
                : []
            }
            getAllLocations={getAllLocations}
            kamID={state.kamID}
            KAMName={state.KAM_name}
            onClose={async (isSubmitted = false) => {
              setState((prevState) => ({
                ...prevState,
                openMapsDialog: false,
              }));
              if (typeof isSubmitted === "boolean" && isSubmitted) {
                await (() => {
                  getAllLocations();
                  fetchEntries(
                    state.search,
                    state.filters,
                    state.pageSize,
                    state.pageNumber,
                    state.order,
                    state.orderBy,
                    state.pageFilters
                  );
                })();
              }
            }}
          />
        )}
        {state.openAddressDialog && (
          <Dialog
            open={true}
            onClose={handleEditAddressClose}
            classes={{ paper: classes.addressListPaper }}
          >
            <Dialog.Title hasClose>KAM Address List</Dialog.Title>
            <Dialog.Content>
              <>
                {/* <Button
                  className="mb-4"
                  variant="contained"
                  color="primary"
                  onClick={handleAddNewLocation}
                  disabled
                >
                  Add New Address
                </Button> */}
                <Grid
                  columns={mapColumnConfig}
                  rows={state.allLocation
                    ?.filter((location) => location?.id === state.kamID)
                    ?.flatMap((location) => {
                      if (!location?.addresses) return [];
                      return JSON.parse(location.addresses);
                    })}
                  actionBarConfig={null}
                  isLoading={state.isAddressListLoading}
                  hasSelection={false}
                  hasPagination={false}
                  onReady={(instance) => (gridHelper = instance)}
                  classes={{
                    container: classes.addressGridridPaper,
                    noRecordsImg: classes.noRecordsImg,
                  }}
                />
              </>
            </Dialog.Content>
            <Dialog.Actions>
              <div className="d-flex f-align-center f-justify-end p-4">
                <Button
                  className="ml-2"
                  variant="contained"
                  color="primary"
                  onClick={handleEditAddressClose}
                >
                  Close
                </Button>
              </div>
            </Dialog.Actions>
          </Dialog>
        )}
        {state.deleteAddress && (
          <ActionDialog
            classes={{
              confirm: "bg-danger",
              paper: classes.deleteAddress,
            }}
            open={!!state.deleteAddress}
            contentText={
              <Typography variant="body2" className="mt-2 text-bold">
                Are you sure you want to delete this address?
              </Typography>
            }
            onConfirm={() => {
              deleteLocation();
              getAllLocations().then(() =>
                fetchEntries(
                  state.search,
                  state.filters,
                  state.pageSize,
                  state.pageNumber,
                  state.order,
                  state.orderBy,
                  state.pageFilters
                )
              );
            }}
            onCancel={() => {
              setState((prevState) => ({
                ...prevState,
                deleteAddress: false,
              }));
            }}
            isConfirmDisabled={state.isAddressListLoading}
            positiveActionLabel={
              <>
                DELETE
                {state.isAddressListLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
            negativeActionLabel={<>CANCEL</>}
          />
        )}
      </div>
    </div>
  );
};

export default UsersScreen;
