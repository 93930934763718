import { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import { noop } from "shared/constants";
import { validator, preventInputKeyCodes } from "utils";
import { toast } from "react-toastify";
import Divider from "@material-ui/core/Divider";
import { MAX_ALLOWED_FILE_SIZE } from "../../constants";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { getAppConfig } from "utils";
import PhoneIcon from "@material-ui/icons/Phone";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import EmailIcon from "@material-ui/icons/Email";
import EditIcon from "@material-ui/icons/Edit";
import Service from "./service";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import { Dialog, ProfileAvatar, Settings } from "shared/components";
import useStyles from "./style";
import { Tooltip, Typography } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";

let isFormUpdate = false;
let imgUploaded = false;
let imgRemoved = false;
const defaultState = {
  anchorEl: null,
  profileImage: null,
  errors: {},
};

const ProfileModal = ({
  open = false,
  userInfo = {},
  isEditable = false,
  title = "View Profile",
  subHeading = "",
  positiveLabel = "Submit",
  negativeLabel = "Cancel",
  editProfileImg = false,
  paperMinHeight = 450,
  paperminWidth = 450,
  isLoading = false,
  fetchEntries = noop,
  updateValidations = noop,
  onSubmit = noop,
  onClose = noop,
  onChange = noop,
}) => {
  const [state, setState] = useState(defaultState);
  const classes = useStyles({
    paperMinHeight,
    paperminWidth,
  });

  const handleClose = () => {
    onClose();
  };

  const updateUserDetails = async (payload) => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    const serviceMethod = "put";

    const { error } = await Service[serviceMethod](payload);

    if (error) {
      setState((prevState) => ({ ...prevState, isLoading: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    } else if (imgUploaded === true) {
      toast.success("Photo uploaded successfully.");
    } else if (imgRemoved === true) {
      toast.success("Photo removed successfully.");
    }
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
    }));
    fetchEntries();
  };

  const handleFileChange = (evt) => {
    const file = evt.target.files[0];

    if (file.size > MAX_ALLOWED_FILE_SIZE.USER_PROFILE * 1048576) {
      return toast.error(
        `Size of "${file.name}" is more than ${MAX_ALLOWED_FILE_SIZE.USER_PROFILE} mb.`
      );
    }

    const payload = {
      id: userInfo.id,
      first_name: userInfo.first_name,
      last_name: userInfo.last_name,
      username: userInfo.username,
      role: userInfo.role,
      email: userInfo.email,
      profileImage: file,
    };

    if (userInfo?.role === "KAM") {
      payload.manager_id = userInfo?.manager?.id;
    }
    imgUploaded = true;
    imgRemoved = false;
    const formData = new FormData();
    Object.keys(payload).map((key) => formData.append([key], payload[key]));
    updateUserDetails(formData);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <Dialog.Title hasClose>
        <Typography variant="h6">{title}</Typography>
        {/* <Typography variant="body2" className="mt-2">
          {subHeading}
        </Typography> */}
      </Dialog.Title>
      <Dialog.Content>
        <div className="d-flex f-justify-center f-align-center">
          <Badge
            overlap="circle"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={
              !editProfileImg && (
                <>
                  <div
                    className={clsx(
                      "d-flex f-align-center f-justify-center c-pointer",
                      classes.avatarEditWrapper
                    )}
                  >
                    {" "}
                    <Tooltip title="Upload a photo" placement="top-start">
                      <IconButton
                        onClick={(event) =>
                          setState((prevState) => ({
                            ...prevState,
                            anchorEl: event.currentTarget,
                          }))
                        }
                      >
                        <EditIcon className={classes.avatarEditIcon} />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <input
                    type="file"
                    name="avatar"
                    id="avatar"
                    className="d-none"
                    onChange={handleFileChange}
                    onClick={(event) => (event.currentTarget.value = "")}
                    accept="image/*"
                  />
                  <Menu
                    keepMounted
                    anchorEl={state.anchorEl}
                    open={!!state.anchorEl}
                    onClose={() =>
                      setState((prevState) => ({
                        ...prevState,
                        anchorEl: null,
                      }))
                    }
                  >
                    <MenuItem
                      htmlFor="avatar"
                      component="label"
                      onClick={() => {
                        setState((prevState) => ({
                          ...prevState,
                          anchorEl: null,
                        }));
                      }}
                    >
                      Upload a photo
                    </MenuItem>
                    <MenuItem
                      disabled={
                        userInfo?.profile_image_path === null ||
                        userInfo?.profile_image_path === undefined
                      }
                      onClick={() => {
                        setState((prevState) => ({
                          ...prevState,
                          anchorEl: null,
                        }));
                        const payload = {
                          id: userInfo.id,
                          first_name: userInfo.first_name,
                          last_name: userInfo.last_name,
                          username: userInfo.username,
                          role: userInfo.role,
                          email: userInfo.email,
                          profile_image_path: null,
                        };
                        if (userInfo?.role === "KAM") {
                          payload.manager_id = userInfo?.manager?.id;
                        }
                        imgUploaded = false;
                        imgRemoved = true;
                        updateUserDetails(payload);
                        fetchEntries();
                      }}
                    >
                      Remove Photo
                    </MenuItem>
                  </Menu>
                </>
              )
            }
          >
            {isLoading ? (
              <CircularProgress />
            ) : userInfo?.profile_image_path === null ||
              userInfo?.profile_image_path === undefined ? (
              <ProfileAvatar
                className={classes.profileAvatar}
                userData={userInfo}
              />
            ) : (
              <Avatar
                className={classes.avatar}
                alt="avatar"
                src={userInfo?.profile_image_path}
              />
            )}
          </Badge>
        </div>
        <div className="d-flex f-justify-center f-align-center">
          <Typography variant="body1" className="text-bold mt-2">
            {!userInfo.fullName
              ? `${userInfo.first_name}${"  "}${userInfo.last_name}`
              : `${userInfo.fullName}`}
          </Typography>
        </div>
        <div className="d-flex f-justify-center">
          {/* <Typography className="mr-2 text-bold" variant="body2">USER ID: </Typography> */}
          <Typography variant="body2">{`(${userInfo.username})`}</Typography>
        </div>
        <div className="d-flex f-justify-center mb-2">
          <Typography className="mr-2 text-bold" variant="body2">
            ROLE:{" "}
          </Typography>
          <Typography variant="body2">{userInfo.role}</Typography>
        </div>
        <Divider className="mb-4" />

        <div className="d-flex f-justify-start mb-2 f-align-center">
          <Tooltip title="Email" placement="top-start">
            <EmailIcon className="mr-2" style={{ color: "#004700" }} />
          </Tooltip>
          {userInfo.email || "-"}
        </div>

        {userInfo?.role !== "ADMIN" &&
          userInfo?.role !== "MAP VIEW" &&
          userInfo?.role !== "SALES VIEW" && (
            <div className="d-flex f-justify-start f-align-center mb-1">
              <Typography className="mr-2 text-bold" variant="body2">
                <Tooltip title="Manager" placement="top-start">
                  <PersonIcon style={{ color: "#004700" }} />
                </Tooltip>
              </Typography>
              <Typography variant="body2">
                {userInfo?.manager === null || userInfo?.manager === undefined
                  ? "-"
                  : `${userInfo?.manager?.first_name || ""} ${
                      userInfo?.manager?.last_name || ""
                    } (MGR)`}
              </Typography>
            </div>
          )}
        <div className="d-flex f-justify-start mb-2 f-align-center">
          <Tooltip title="Phone" placement="top-start">
            <PhoneIcon className="mr-2" style={{ color: "#004700" }} />
          </Tooltip>
          {userInfo.phone || "-"}
        </div>
        <div className="d-flex f-justify-start f-align-center flex-wrap mb-1">
          <Tooltip title="Location" placement="top-start">
            <LocationOnIcon className="mr-2" color="primary" />
          </Tooltip>
          <Typography variant="body2">{userInfo.location || "-"}</Typography>
        </div>
        {userInfo.role === "KAM" && <Settings kamUserData={userInfo} />}
      </Dialog.Content>
    </Dialog>
  );
};

export default ProfileModal;
