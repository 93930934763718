import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: 600,
    maxHeight: 600,
    minWidth: 700,
    maxWidth: 700,
  },
  // TODO: change image element to the class
  dialogContent: {
    overflow: (props) => (props.noData ? "hidden" : "auto"),
    "& Img": {
      height: 400,
    },
  },
  reasonDialog: {
    minHeight: 216,
    maxHeight: 216,
    minWidth: 350,
    maxWidth: 350,
  },
}));

export default useStyles;
