import makeStyles from "@material-ui/core/styles/makeStyles";
import yellow from "@material-ui/core/colors/yellow";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import blue from "@material-ui/core/colors/blue";
import pink from "@material-ui/core/colors/pink";
import grey from "@material-ui/core/colors/grey";
import purple from "@material-ui/core/colors/purple";

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  notification: {
    border: `2px solid ${theme.palette.grey[500]}`,
  },
  datepickerWrapper: {
    minWidth: 164,
    maxWidth: 164,
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  icidTitleColor: {
    background: `${yellow[50]}`,
  },
  datepickerWrapperDesktop: {
    minWidth: 190,
  },
  pageLoader: {
    position: "fixed",
    top: "50%",
    left: "50%",
  },
  inactiveICID: {
    background: red[400],
  },
  warningIcon: {
    color: "#d7d718",
    zIndex: 1,
    position: "relative",
    marginTop: "2px",
  },
  transferSchedule: { background: "#fce4ec" },
  warningIconHeight: {
    height: "24px",
    position: "relative",
  },
  exclamationMark: {
    width: "3px",
    height: "9px",
    background: "black",
    position: "absolute",
    top: "11px",
    left: "11px",
  },
  pendingItemsDropdown: {
    maxHeight: 200,
    minWidth: 120,
  },
  highlightUpdate: {
    background: yellow[50],
  },
  paper: {
    minHeight: 400,
  },
  tableHeaderCell: {
    minWidth: 144,
  },
  autocompleteSearchInput: {
    minWidth: 100,
    "& .MuiInput-underline:before": {
      borderBottom: 0,
    },
    "&:hover": {
      "& .MuiInput-underline:before": {
        borderBottom: 0,
      },
    },
  },
  autocompletePaper: {
    minWidth: 150,
    maxHeight: 200,
    fontSize: 14,
  },
  autocompleteInput: {
    fontSize: 14,
  },
  highlightDisable: {
    background: theme.palette.grey[200],
  },
  highlightDeleted: {
    background: red[50],
  },
  highlightTransfer: {
    background: red[50],
  },
  clickableDate: {
    background: grey[300],
  },
  completeSubmittedAlert: {
    background: `${green[500]}!important`,
  },
  approvedAlert: {
    background: `#bbdefb!important`,
    color: "#000",
  },
  dateLegend: {
    marginTop: 350,
    left: 0,
  },
  dateLegendCopy: {
    marginTop: 15,
    left: 75,
  },
  legendHint: {
    width: 10,
    height: 10,
    border: `1px solid ${theme.palette.divider}`,
  },
  backgroundWhite: {
    background: "white",
  },
  kamNotesTableHeaderCell: { minWidth: "320px" },
  paperSize: {
    width: 316,
  },
  paperWidthSm: {
    width: "27rem",
  },
  customSpace: {
    whiteSpace: "pre",
  },
  calendarDay: {
    borderRadius: 4,
    color: `${theme.palette.common.black}`,
  },
  colorNone: {
    color: "transparent !important",
  },
  bgColorNone: {
    background: "transparent !important",
  },
  transferRecordRequest: {
    background: pink[200],
  },
  icidTitle: {
    minWidth: "450px",
  },
  icidFlagTitle: {
    minWidth: "450px",
    paddingLeft: "48px",
  },
  kamDropdownWidth: {
    minWidth: 174,
    maxWidth: 174,
  },
  bottomNavigation: {
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    height: 64,
  },
  fixedHeader: {
    width: "100%",
    height: "75px",
    zIndex: 1000,
    position: "sticky",
    background: "#fafafa",
  },
  legendsPosition: {
    marginLeft: "24.375rem",
  },
  isAccepted: {
    backgroundColor: "#e8f5e9",
  },
}));

export default useStyles;
