import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import matches from "validator/es/lib/matches";

import { fieldErrorMessageMap, passwordRegex } from "shared/constants";
import SignInService from "../service";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import newOrgLogo from "shared/assets/images/pdx-logo.png";
import OrgLogo from "shared/assets/images/org-logo.png";

import useStyles from "./style";
import clsx from "clsx";

let timeout = null;
const defaultState = {
  password: "",
  oldPassword: "",
  confirmPassword: "",
  verificationCode: "",
  isLoading: false,
  errors: {
    password: "",
    oldPassword: "",
    confirmPassword: "",
    verificationCode: "",
  },
  isEmailSend: false,
  isPasswordReset: false,
  isSubmit: false,
  isReset: false,
  isSetTimeout: false,
};

const ResetPasswordModule = ({ history, match }) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);

  const handleVerificationEmail = async (isResend = false) => {
    if (!isResend) {
      if (state.password !== state.confirmPassword) {
        return toast.error("Password and Confirm password not matched.");
      }

      if (state.oldPassword === state.password) {
        return toast.error("New password and old password cannot be same.");
      }
    }

    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      isSetTimeout: true,
    }));

    const { data, error } = await SignInService.sendVerificationEmail(
      {
        emailType: "VerificationCode",
      },
      match.params?.accessToken
    );

    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      if (isResend) {
        toast.success(data.message || "Email Sent Successfully.");
      }
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isEmailSend: true,
      }));
    }
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
    }));
    timeout = setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        isSetTimeout: false,
      }));
      clearTimeout(timeout);
    }, 1000 * 20);
  };

  const handleConfirm = async () => {
    if (state.password !== state.confirmPassword) {
      return toast.error("New Password and Confirm Password not matched.");
    }
    setState((prevState) => ({
      ...prevState,
      isSubmit: true,
      isSetTimeout: false,
    }));
    clearTimeout(timeout);
    const { error } = await SignInService.updatePassword(
      {
        //oldPassword: state.oldPassword,
        newPassword: state.password,
        isVerify: true,
        verificationCode: state.verificationCode,
      },
      match.params?.accessToken
    );

    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        isPasswordReset: true,
      }));
    }
    setState((prevState) => ({
      ...prevState,
      isSubmit: false,
    }));
  };

  const handleResetPassword = async () => {
    if (state.password !== state.confirmPassword) {
      return toast.error("New Password and Confirm Password not matched.");
    }
    setState((prevState) => ({
      ...prevState,
      isReset: true,
    }));
    const { error, message } = await SignInService.resetPassword({
      newPassword: state.password,
      token: match.params.token,
    });
    setState((prevState) => ({
      ...prevState,
      isReset: false,
    }));
    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      toast.success(message);
      setTimeout(() => history.push("/sign-in"), 1000 * 1);
    }
  };

  const validate = useCallback(
    (field, value) => {
      let isValid = false;
      let fieldValidatorMap = {};
      if (state.isEmailSend) {
        fieldValidatorMap = {
          verificationCode: (value) => !!value,
        };
      } else {
        fieldValidatorMap = {
          //oldPassword: (value) => matches(value, passwordRegex),
          password: (value) =>
            field == "password" && state.confirmPassword
              ? value === state.confirmPassword
              : matches(value, passwordRegex),
          confirmPassword: (value) =>
            field == "confirmPassword" && state.password
              ? value === state.password
              : matches(value, passwordRegex),
        };
        // if (!match.params?.token) {
        //   fieldValidatorMap["oldPassword"] = (value) =>
        //     matches(value, passwordRegex);
        // }
      }

      if (fieldValidatorMap[field]) {
        isValid = fieldValidatorMap[field](value);
      } else {
        if (!field) {
          isValid = true;
          Object.keys(fieldValidatorMap).forEach((key) => {
            if (!validate(key, state[key])) {
              isValid = false;
            }
          });
        } else {
          isValid = true;
        }
      }

      return isValid;
    },
    [state]
  );

  const handleFieldChange = (event) => {
    const field = event.currentTarget.name;
    const value = event.currentTarget.value;
    let errorMessage = "";
    if (value) {
      const isValid = validate(field, value);
      if (!isValid) {
        errorMessage =
          (field === "password" && state.confirmPassword) ||
          (field === "confirmPassword" && state.password)
            ? "Password do not matched."
            : fieldErrorMessageMap[field];
      }
    } else {
      errorMessage = "Required";
    }
    console.log(errorMessage, "----------error message");
    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  return (
    <div className="d-flex f-justify-center f-align-center flex-column flex-1">
      <img alt="Thinksys Logo" src={newOrgLogo} className="mt-8 mb-8" />
      {match.params?.token ? (
        <Paper
          className={clsx(
            "d-flex flex-column f-align-center p-4",
            classes.paper
          )}
        >
          <Typography component="h6" variant="h6" className="mb-4">
            Reset Password
          </Typography>
          <TextField
            required
            fullWidth
            variant="outlined"
            type="password"
            name="password"
            label="New Password"
            className="mb-4"
            value={state.password}
            autoComplete="current-password"
            error={!!state.errors.password}
            helperText={state.errors.password}
            onChange={handleFieldChange}
          />
          <TextField
            required
            fullWidth
            className="mb-4"
            variant="outlined"
            type="password"
            name="confirmPassword"
            label="Confirm Password"
            value={state.confirmPassword}
            autoComplete="current-password"
            error={!!state.errors.confirmPassword}
            helperText={state.errors.confirmPassword}
            onChange={handleFieldChange}
          />
          <Button
            fullWidth
            color="primary"
            variant="contained"
            className="mb-4"
            disabled={state.isReset || !validate()}
            onClick={handleResetPassword}
          >
            Reset Password
            {state.isReset && (
              <CircularProgress size={24} className={classes.progressBtn} />
            )}
          </Button>
        </Paper>
      ) : (
        <div>
          {!state.isPasswordReset ? (
            <Paper
              className={clsx(
                "d-flex flex-column f-align-center p-4",
                classes.paper
              )}
            >
              {!state.isEmailSend && !state.isPasswordReset && (
                <>
                  <Typography component="h6" variant="h6" className="mb-4">
                    Change Password
                  </Typography>
                  <Typography
                    variant="body2"
                    className="mb-4 color-text-secondary align-center"
                  >
                    You need to change the default password before you can use
                    the system.
                  </Typography>
                </>
              )}
              {state.isEmailSend && !state.isPasswordReset && (
                <>
                  <Typography component="h6" variant="h6" className="mb-4">
                    Enter Verification Code
                  </Typography>
                  <Typography
                    className="mb-4 color-text-secondary align-center"
                    variant="body2"
                  >
                    Just one last step remains. We have sent you an email with a
                    verification code to ensure that you are accessing the
                    correct
                    <br />
                    account.
                  </Typography>
                </>
              )}
              <Grid item xs={12} className="w-100">
                {!state.isEmailSend ? (
                  <>
                    {/* <TextField
                      required
                      fullWidth
                      variant="outlined"
                      type="password"
                      name="oldPassword"
                      label="Old Password"
                      className="mb-4"
                      value={state.oldPassword}
                      autoComplete="old-password"
                      error={!!state.errors.oldPassword}
                      helperText={state.errors.oldPassword}
                      onChange={handleFieldChange}
                    /> */}
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      type="password"
                      name="password"
                      label="New Password"
                      className="mb-4"
                      value={state.password}
                      autoComplete="current-password"
                      error={!!state.errors.password}
                      helperText={state.errors.password}
                      onChange={handleFieldChange}
                    />
                    <TextField
                      required
                      fullWidth
                      className="mb-4"
                      variant="outlined"
                      type="password"
                      name="confirmPassword"
                      label="Confirm Password"
                      value={state.confirmPassword}
                      autoComplete="current-password"
                      error={!!state.errors.confirmPassword}
                      helperText={state.errors.confirmPassword}
                      onChange={handleFieldChange}
                    />
                    <Button
                      fullWidth
                      color="primary"
                      variant="contained"
                      className="mb-4"
                      disabled={state.isLoading || !validate()}
                      onClick={handleVerificationEmail}
                    >
                      Next
                      {state.isLoading && (
                        <CircularProgress
                          size={24}
                          className={classes.progressBtn}
                        />
                      )}
                    </Button>
                  </>
                ) : (
                  <div>
                    <TextField
                      required
                      fullWidth
                      className="mt-4 mb-8"
                      variant="outlined"
                      type="text"
                      name="verificationCode"
                      label="Verification Code"
                      value={state.verificationCode}
                      error={!!state.errors.verificationCode}
                      helperText={state.errors.verificationCode}
                      onChange={handleFieldChange}
                    />
                    <Grid container justify="center" spacing={2}>
                      <Grid item xs={12} sm={5} md={5}>
                        <Button
                          fullWidth
                          variant="outlined"
                          color="primary"
                          disabled={state.isLoading || state.isSetTimeout}
                          onClick={() => handleVerificationEmail(true)}
                        >
                          Re-send email
                          {state.isLoading && (
                            <CircularProgress
                              size={24}
                              className={classes.progressBtn}
                            />
                          )}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={5} md={5}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled={state.isSubmit || !validate()}
                          onClick={handleConfirm}
                        >
                          Submit
                          {state.isSubmit && (
                            <CircularProgress
                              size={24}
                              className={classes.progressBtn}
                            />
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </Grid>
            </Paper>
          ) : (
            <>
              <Typography className="mb-10" variant="body1">
                Your password has been reset successfully.
              </Typography>
              <NavLink to="/sign-in">
                <Button variant="contained" color="primary" fullWidth>
                  LOG IN
                </Button>
              </NavLink>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ResetPasswordModule;
