import makeStyles from "@material-ui/core/styles/makeStyles";
import grey from "@material-ui/core/colors/grey";
import blue from "@material-ui/core/colors/blue";
import yellow from "@material-ui/core/colors/yellow";

const useStyles = makeStyles((theme) => ({
  actionsWrapper: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  datepickerWrapper: {
    width: 160,
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  actionsWrapper: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  paperSize: {
    width: 388,
  },
  warningIcon: {
    color: "#d7d718",
    zIndex: 1,
    position: "relative",
    marginTop: "2px",
  },
  warningIconHeight: {
    height: "24px",
    position: "relative",
  },
  exclamationMark: {
    width: "3px",
    height: "9px",
    background: "black",
    position: "absolute",
    top: "11px",
    left: "11px",
  },
  paperSizeDoNotShowDialogs: {
    width: 464,
    // height:148,
  },
  paperSizeDoNotShowDialog: {
    width: 424,
    height: 148,
  },
  paper_Size: {
    width: 488,
  },
  paper_Sizes: {
    width: 498,
  },
  paperSpacing: {
    marginTop: "14px !important",
    paddingTop: "0px !important",
    paddingBottom: "0px !important ",
  },
  tunePaper: {
    minWidth: 232,
  },
  highlightUpdate: {
    background: `${yellow[50]} !important`,
  },
  highlightApproved: {
    background: blue[100],
  },
  highlightAwaitingReview: {
    background: "#8000806b",
  },
  highlightAdminRejected: {
    background: "#ff00005c",
  },
  notification: {
    border: `2px solid ${theme.palette.grey[500]} !important`,
  },
  tableHeaderCell: {
    minWidth: 144,
  },
  customSpace: {
    whiteSpace: "pre",
  },
  autocompleteSearchInput: {
    minWidth: 100,
    "& .MuiInput-underline:before": {
      borderBottom: 0,
    },
    "&:hover": {
      "& .MuiInput-underline:before": {
        borderBottom: 0,
      },
    },
  },
  autocompletePaper: {
    minWidth: 150,
    maxHeight: 200,
    fontSize: 14,
  },
  autocompleteInput: {
    fontSize: 14,
  },
  filterFields: {
    marginLeft: 12,

    minWidth: 168,
    maxWidth: 168,
    [theme.breakpoints.down("sm")]: {
      minWidth: 244,
      maxWidth: 244,
      marginLeft: 0,
      marginBottom: 12,
      marginRight: 10,
    },
  },
  icidTitle: {
    minWidth: "450px",
  },
  icidFlagTitle: {
    minWidth: "450px",
    paddingLeft: "48px",
  },
  icidTitleColor: {
    background: `${yellow[50]}`,
  },
  extraMilesheader: {
    minWidth: 148,
  },
  fixedHeader: {
    width: "100%",
    top: "64px",
    height: "75px",
    zIndex: 1000,
    position: "sticky",
    background: "#fafafa",
    marginBottom: "6px",
  },
}));

export default useStyles;
