import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  //   customerDropdown: {
  //     width: 5000,
  //   },
  paperSpacing: {
    marginTop: "14px !important",
    paddingTop: "0px !important",
  },
  paper: {
    "& .MuiDialogActions-root": {
      paddingBottom: "26px",
    },
    width: 800,
    height: 590,
  },
}));

export default useStyles;
