export const dateDiff = (startDate = new Date(), endDate = new Date()) => {
	const date1 = new Date(startDate);
	const date2 = new Date(endDate);
	const diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24));
	return diffDays;
};

export const getFormattedDay = (date, isFull = false) => {
	const days = isFull
		? [
				'Sunday',
				'Monday',
				'Tuesday',
				'Wednesday',
				'Thursday',
				'Friday',
				'Saturday',
		  ]
		: ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
	const dayIndex = date.getDay();
	return `${days[dayIndex]}`;
};

export const getFormattedDate = date => {
	const monthNames = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sept',
		'Oct',
		'Nov',
		'Dec',
	];

	const day = date.getDate();
	const monthIndex = date.getMonth();
	const year = date.getFullYear();
	return `${monthNames[monthIndex]} ${day}, ${year}`;
};

export const getFormattedTime = date => {
	let hours = date.getHours();
	let minutes = date.getMinutes();
	const ampm = hours >= 12 ? 'pm' : 'am';
	hours %= 12;
	hours = hours || 12;
	minutes = minutes < 10 ? `0${minutes}` : minutes;
	const strTime = `${hours}:${minutes} ${ampm}`;
	return strTime;
};

export const getDateString = date => {
	if (date) {
		const dateInstance = new Date(date);
		return `${
			dateInstance.getMonth() + 1 < 10
				? `0${dateInstance.getMonth() + 1}`
				: dateInstance.getMonth() + 1
		}/${
			dateInstance.getDate() < 10
				? `0${dateInstance.getDate()}`
				: dateInstance.getDate()
		}/${dateInstance.getFullYear()}`;
	}

	return '';
};

const getDateMonthAndYear = dateString => {
	const dateStringHyphenSplit = dateString.split('-');
	const dateStringSlashSplit = dateString.split('/');

	if (dateStringHyphenSplit.length === 3 && dateStringSlashSplit.length === 1) {
		const [year, month, date] = dateStringHyphenSplit;
		return { year, month, date };
	}

	if (dateStringSlashSplit.length === 3 && dateStringHyphenSplit.length === 1) {
		const [month, date, year] = dateStringSlashSplit;
		return { year, month, date };
	}

	return {};
};

// dateString must be of format yyyy-mm-dd or mm/dd/yyyy
export const getDateObject = dateString => {
	if (!dateString) {
		return null;
	}

	const dateMonthAndYear = getDateMonthAndYear(dateString);

	if (Object.keys(dateMonthAndYear) === 0) {
		return null;
	}

	const { year, month, date } = dateMonthAndYear;

	const dateObject = new Date();

	dateObject.setHours(0);
	dateObject.setMinutes(0);
	dateObject.setSeconds(0);
	dateObject.setMilliseconds(0);

	dateObject.setFullYear(year);
	dateObject.setMonth(month - 1);
	dateObject.setDate(date);

	return dateObject;
};

export const getUTCDateString = date => {
	if (date) {
		const dateInstance = new Date(date);
		return `${
			dateInstance.getUTCMonth() + 1 < 10
				? `0${dateInstance.getUTCMonth() + 1}`
				: dateInstance.getUTCMonth() + 1
		}/${
			dateInstance.getUTCDate() < 10
				? `0${dateInstance.getUTCDate()}`
				: dateInstance.getUTCDate()
		}/${dateInstance.getUTCFullYear()}`;
	}
	return '';
};

export const getUTCFormattedTime = date => {
	let hours = date.getUTCHours();
	let minutes = date.getUTCMinutes();
	const ampm = hours >= 12 ? 'pm' : 'am';
	hours %= 12;
	hours = hours || 12;
	minutes = minutes < 10 ? `0${minutes}` : minutes;
	const strTime = `${hours}:${minutes} ${ampm}`;
	return strTime;
};

export const getTimeInHoursAndMinutes = (timeInSeconds, formatted = false) => {
	const hours = Math.floor(timeInSeconds / 3600);
	let remainingSeconds = timeInSeconds % 3600;
	const minutes = Math.floor(remainingSeconds / 60);
	return formatted ? `${hours}:${minutes}` : `${hours}h ${minutes}m`;
};
