import { http, responseFormatter } from "utils";

const getDCN = (query) => {
  return responseFormatter(
    http.get(`get-ic-settlement-data/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getDcnHistory = (query) => {
  return responseFormatter(
    http.get(`get-history/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getHistoryDetailsByID = (entryId) => {
  return responseFormatter(
    http.get(`/get-history-details/${entryId || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getLocalResources = () => {
  return http.get("./resources.json", { baseURL: "" });
};

const getCustomerLocation = (query) => {
  return responseFormatter(
    http.get(`/customer/get-locations${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const sendToDCN = (data) => {
  return responseFormatter(
    http.post("/upload-to-dcn", data, {
      setAuthHeader: true,
    })
  );
};

const addEmailInLookup = (payload) => {
  return responseFormatter(
    http.post(`/lookup`, payload, {
      setAuthHeader: true,
    })
  );
};

const getSettlementExcludedRecords = (query) => {
  return responseFormatter(
    http.get(`get-excluded-settlements/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getSettlementExcludedHistory = (query) => {
  return responseFormatter(
    http.get(`get-excluded-history/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const excludeRecordsFromSettlements = (payload) => {
  return responseFormatter(
    http.post(`/exclude-from-settlements`, payload, {
      setAuthHeader: true,
    })
  );
};
const permanentExcludeRecordsFromSettlements = (payload) => {
  return responseFormatter(
    http.put(`/exclude-from-settlements`, payload, {
      setAuthHeader: true,
    })
  );
};
const includeRecordsToSettlements = (query) => {
  return responseFormatter(
    http.delete(`/include-to-settlements/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};
const permanentExclude = (payload) => {
  return responseFormatter(
    http.patch(`/excluded-history`, payload, {
      setAuthHeader: true,
    })
  );
};
const revertDCN = (payload) => {
  return responseFormatter(
    http.patch(`/revert-dcn`, payload, {
      setAuthHeader: true,
    })
  );
};
const includeRecords = (id) => {
  return responseFormatter(
    http.delete(`/excluded-history?recordIds=${id}`, {
      setAuthHeader: true,
    })
  );
};

const DailyScheduleService = {
  getDCN,
  getDcnHistory,
  revertDCN,
  getHistoryDetailsByID,
  getCustomerLocation,
  getLocalResources,
  sendToDCN,
  addEmailInLookup,
  getSettlementExcludedRecords,
  getSettlementExcludedHistory,
  excludeRecordsFromSettlements,
  permanentExcludeRecordsFromSettlements,
  includeRecordsToSettlements,
  includeRecords,
  permanentExclude,
};

export default DailyScheduleService;
