import { http, responseFormatter } from "utils";

const post = (data) => {
  return responseFormatter(
    http.post("/user", data, {
      setAuthHeader: true,
    })
  );
};

const put = (data) => {
  return responseFormatter(
    http.put("/user", data, {
      setAuthHeader: true,
    })
  );
};

const patch = (data) => {
  return responseFormatter(
    http.patch("/update-default-kam", data, {
      setAuthHeader: true,
    })
  );
};

const patchUpdateAA = (data) => {
  return responseFormatter(
    http.patch("/update-default-aa", data, {
      setAuthHeader: true,
    })
  );
};

const patchUpdateMGR = (data) => {
  return responseFormatter(
    http.patch("/update-default-mgr", data, {
      setAuthHeader: true,
    })
  );
};

const markActiveInactive = (data) => {
  return responseFormatter(
    http.patch(`/user-update-status/`, data, {
      setAuthHeader: true,
    })
  );
};

const deleteEntry = (entryId) => {
  return responseFormatter(
    http.delete(`/user/${entryId}`, {
      setAuthHeader: true,
    })
  );
};

const getAllLocations = (query) => {
  return responseFormatter(
    http.get(`/addresses/get?entityType=kamUser`, {
      setAuthHeader: true,
    })
  );
};

const createLocation = (data) => {
  return responseFormatter(
    http.post("/addresses/create", data, {
      setAuthHeader: true,
    })
  );
};

const updateLocation = (data) => {
  return responseFormatter(
    http.put("/addresses/update", data, {
      setAuthHeader: true,
    })
  );
};

const getICCustomerForUsers = (userId) => {
  return responseFormatter(
    http.get(`/user-associated-data/${userId}`, {
      setAuthHeader: true,
    })
  );
};

const deleteLocation = (entity_type, entity_id, address_id) => {
  return responseFormatter(
    http.delete(`/addresses/delete/${entity_type}/${entity_id}/${address_id}`, {
      setAuthHeader: true,
    })
  );
};

const UserService = {
  post,
  put,
  patch,
  markActiveInactive,
  getICCustomerForUsers,
  deleteEntry,
  getAllLocations,
  createLocation,
  updateLocation,
  deleteLocation,
  patchUpdateAA,
  patchUpdateMGR,
};

export default UserService;
