import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import { noop, PAGE_KEYS, VALIDATIONS } from "shared/constants";
import Service from "../service";
import { AppContext } from "shared/contexts";
import {
  Grid as DataGrid,
  Dropdown,
  FilterComponent,
  Datepicker,
  ActionDialog,
} from "shared/components";
import {
  getPageConfig,
  setPageConfig,
  queryStringBuilderNew,
  updatePagination,
  updateLayout,
  getDateString,
  updatePageConfig,
  getFormattedTime,
  validator,
  getUTCDateString,
} from "utils";
import Button from "@material-ui/core/Button";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import FilterListIcon from "@material-ui/icons/FilterList";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TuneIcon from "@material-ui/icons/Tune";
import useStyles from "./style";
import { SharedService } from "modules/shared";
import { CircularProgress, Switch, TextField } from "@material-ui/core";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import { CollapsibleGrid } from "modules/shared/components";

let gridHelper = null;

const defaultState = {
  entries: [],
  totalEntries: 0,
  isFetching: false,
  pageFilters: [],
  selectedRows: [],
  showConfirmationPopup: false,
  isDataFetched: false,
  isFiltering: false,
  settlementCompanyList: [],
  userList: [],
  startDate: null,
  isRecordIncluding: false,
  isIncludingRecords: false,
  isPermanentExclude: false,
  isPermanentExcluding: false,
  endDate: null,
  metadata: "",
  isLoading: false,
  isFetchingList: {
    userNameList: false,
    icList: false,
  },
  pageSize: 100,
  testId: "",
  pageNumber: 1,
  isRowOpen: false,
  order: null,
  permExcludeReason: "",
  errors: {
    permExcludeReason: " ",
  },
  orderBy: null,
  IC: null,
  search: "",
  dynamicColumns: {
    select_all: { label: "Select All", value: "select_all", isSelected: true },
    ICID: {
      label: "ICID",
      value: "ICID",
      isSelected: true,
    },
    PermanentlyExcluded: {
      label: "Permanently Excluded",
      value: "PermanentlyExcluded",
      isSelected: true,
    },
    ExcludedDate: {
      label: "Excluded Date",
      value: "ExcludedDate",
      isSelected: true,
    },

    ExcludedBy: {
      label: "Excluded By",
      value: "ExcludedBy",
      isSelected: true,
    },
    ExcludedAt: {
      label: "Excluded At",
      value: "ExcludedAt",
      isSelected: true,
    },
    Reason: {
      label: "Reason",
      value: "Reason",
      isSelected: true,
    },
  },
  dateFilterType: "single",
  formAlertFields: {},
  fieldAlert: {
    show: false,
    id: null,
    isLoading: false,
    subHeading: "",
    configs: [],
    updateValidations: noop,
    onChange: noop,
  },
  isSendingToDCN: false,
};

const ExcludedHistory = ({ match, history }) => {
  const pageConfig = getPageConfig(PAGE_KEYS.DCN_EXCLUDE_HISTORY);
  const classes = useStyles();
  const { appData, updateContextData } = useContext(AppContext);
  const wrapRef1 = useRef(null);
  const wrapRef2 = useRef(null);

  const handleRowOpen = (value) =>
    setState((prevState) => ({
      ...prevState,
      isRowOpen: value || defaultState.isRowOpen,
    }));

  const handleScroll = (event) => {
    const targetDiv = event.target;

    if (targetDiv === wrapRef1.current && wrapRef2.current) {
      wrapRef2.current.scrollLeft = targetDiv.scrollLeft;
    } else if (targetDiv === wrapRef2.current && wrapRef1.current) {
      wrapRef1.current.scrollLeft = targetDiv.scrollLeft;
    }
  };

  const [state, setState] = useState({
    ...defaultState,
    pageSize: pageConfig?.pageSize || defaultState.pageSize,
    pageNumber: pageConfig?.pageNumber || defaultState.pageNumber,
    pageFilters:
      (pageConfig && pageConfig?.filters) || defaultState.pageFilters,
    startDate:
      pageConfig?.startDate === undefined || pageConfig?.startDate === null
        ? getDateString(new Date().setDate(new Date().getDate() - 7))
        : (pageConfig && pageConfig?.startDate) || defaultState.startDate,
    endDate:
      pageConfig?.endDate === undefined || pageConfig?.endDate === null
        ? getDateString(new Date())
        : (pageConfig && pageConfig?.endDate) || defaultState.endDate,
    dynamicColumns: pageConfig?.dynamicColumns || defaultState.dynamicColumns,
    IC: (pageConfig && pageConfig?.icFilter?.value) || defaultState.IC,
  });

  const appDataList = useMemo(() => {
    const icList = appData.icList
      .filter((ic) => ic.is_active)
      .sort((a, b) => a.first_name.localeCompare(b.first_name));

    return {
      icList,
    };
  }, [appData]);

  const fetchList = async (listType, ...params) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        icList: !appData[listType]?.length,
      },
    }));

    const { data, error } = await SharedService["getIcList"](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data["contractors"] || [];

    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
  };

  useEffect(() => {
    fetchList("icList");
  }, []);

  // const handleFilterChange = useCallback((pageFilters = []) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     pageFilters,
  //   }));
  //   setPageConfig(PAGE_KEYS.DCN_EXCLUDE_HISTORY, {
  //     filters: pageFilters,
  //   });
  // }, []);

  const fetchUserList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        userNameList: true,
      },
    }));

    const { data, error } = await SharedService.getUsersList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          userNameList: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      userList:
        data?.users.filter(
          (user) =>
            user.is_active && (user.role === "ADMIN" || user.role === "AA")
        ) || defaultState.userList,
      isFetchingList: {
        ...prevState.isFetchingList,
        userNameList: false,
      },
    }));
  };

  const fetchEntries = useCallback(
    async (
      startDate = "",
      endDate = "",
      search,
      IC,
      pageSize,
      pageNumber,
      order,
      orderBy,
      pageFilters = [],
      hasLoader = true
    ) => {
      setState((prevState) => ({
        ...prevState,
        isFetching: hasLoader,
      }));

      let filtersArr = [];

      startDate &&
        filtersArr.push({
          field: "exclude_date",
          type: "gte",
          value: startDate,
        });

      filtersArr = [
        ...filtersArr.map((item) => {
          if (item.field === "exclude_date") {
            return { ...item, notIncludeInBuilder: true };
          }
          return { ...item, notIncludeInBuilder: false };
        }),
        ...pageFilters.map((filter) => ({
          ...filter,
          value: filter?.value?.value || filter?.value,
        })),
      ];

      let queryString = queryStringBuilderNew(
        pageSize,
        pageSize * (pageNumber - 1),
        [],
        filtersArr,
        { field: orderBy, order },
        search && search.length > 0
      );

      if (endDate) {
        queryString += `&filter[where][exclude_date][lte]=${endDate}`;
      }

      if (!!IC) {
        queryString += `&filter[where][and][0][or][0][contractor_id]=${IC?.id}`;
      }

      const { data, error } = await Service.getSettlementExcludedHistory(
        queryString
      );
      if (error) {
        setState((prevState) => ({
          ...prevState,
          isFetching: false,
          isPermanentExcluding: false,
          isIncludingRecords: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0].message : error.message
        );
      }
      gridHelper && gridHelper.resetSelection();
      setState((prevState) => ({
        ...prevState,
        data,
        isFetching: false,
        isPermanentExcluding: false,
        isIncludingRecords: false,
        entries: data.result || defaultState.entries,
        totalEntries: data.count,
      }));

      return data;
    },
    []
  );

  useEffect(() => {
    fetchUserList();
  }, []);

  useEffect(() => {
    fetchEntries(
      state.startDate,
      state.endDate,
      state.search,
      state.IC,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    ).then((data) => {
      updatePagination(
        data?.count,
        { pageNumber: state.pageNumber, pageSize: state.pageSize },
        (pageNumber) => {
          setState((prevState) => ({ ...prevState, pageNumber }));
        }
      );
    });
  }, [
    fetchEntries,
    state.startDate,
    state.endDate,
    state.IC,
    state.pageSize,
    state.pageNumber,
    state.order,
    state.orderBy,
    state.search,
    state.pageFilters,
  ]);

  useEffect(() => {
    setTimeout(updateLayout, 1000);
  }, [state.entries]);

  const handleSelectionChange = useCallback((selectedRows) => {
    setState((prevState) => ({
      ...prevState,
      selectedRows,
    }));
  }, []);

  const handleSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      order: order,
      orderBy: fieldObj.field || fieldObj.fieldName,
      selectedRows: defaultState.selectedRows,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const columnConfig = [
    {
      headerClassName: classes.dcnHistoryCloumnTitle,
      id: "individual_contractor.settlementCompany.value",
      label: "Settlement Company",
      field: "individual_contractor.settlementCompany.value",
      hasEllipses: true,
      render: (row) => {
        return (
          <Tooltip
            title={row.individual_contractor?.settlementCompany?.value ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {row.individual_contractor?.settlementCompany?.value || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.PermanentlyExcluded?.isSelected,
      id: "is_permanently_excluded",
      label: "Permanently Excluded",
      field: "is_permanently_excluded",
      canSort: true,
      render: (row) => {
        return (
          <Tooltip title="Permanent Exclude" placement="top-start">
            <Switch
              size="small"
              className={row?.is_permanently_excluded && "no-events"}
              checked={row?.is_permanently_excluded}
              onChange={(evt) => {
                const reason = row?.reason?.split("||");
                setState((prevState) => {
                  return {
                    ...prevState,
                    isPermanentExcluding: row?.id,
                    permExcludeReason: reason[0],
                  };
                });
              }}
              color="primary"
            />
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.ExcludedDate?.isSelected,
      id: "exclude_date",
      label: "Excluded Date",
      field: "exclude_date",
      canSort: true,
      render: (row) => {
        return (
          <Tooltip
            title={getUTCDateString(row?.exclude_date) ?? ""}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {getUTCDateString(row?.exclude_date) || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.ExcludedBy?.isSelected,
      id: "createdBy.first_name",
      label: "Excluded By",
      field: "createdBy.first_name",
      canSort: true,
      render: (row) => {
        return (
          <Tooltip
            title={`${row?.createdBy?.first_name} ${row?.createdBy?.last_name} ${row?.createdBy?.email} (${row?.createdBy?.username})`}
            placement="top-start"
          >
            <Typography variant="body2" noWrap>
              {`${row?.createdBy?.first_name} ${row?.createdBy?.last_name}`}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.ExcludedAt?.isSelected,
      id: "created_at",
      label: "Excluded At",
      field: "created_at",
      canSort: true,
      render: (row) => {
        const uploadDate =
          `${getDateString(row.created_at)} ${getFormattedTime(
            new Date(row.created_at)
          )}` || "";
        return (
          <Tooltip title={uploadDate ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {uploadDate || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.Reason?.isSelected,
      id: "reason",
      label: "Reason",
      field: "reason",
      canSort: true,
      render: (row) => {
        const reason = row?.reason?.split("||");
        return (
          <Tooltip title={row?.reason ?? ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {reason[0] || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
  ];

  const renderDropdown = (columnState, columns, pageKey) => (
    <div className="d-flex f-justify-center">
      <Dropdown
        classes={{
          paper: classes.tunePaper,
        }}
        disablePortal={false}
        remainOpen
        isMultiSelect
        placement="bottom-end"
        options={Object.keys(columns).map((key) => columns[key])}
        customToggle={({ anchorRef, onClick }) => (
          <Tooltip title="Show/Hide column(s)" placement="top-start">
            <IconButton color="primary" ref={anchorRef} onClick={onClick}>
              <TuneIcon />
            </IconButton>
          </Tooltip>
        )}
        onChange={(options) => {
          const isSelectAll = options.some(
            (item) => item.value === "select_all"
          );
          const isCurrentSelectAll = columns?.select_all?.isSelected;
          const isClickedOnSelectAll = isSelectAll !== isCurrentSelectAll;
          const isAllOptionSelected =
            Object.keys(columns).length - 1 ===
            options.filter((item) => item.value !== "select_all").length;
          const updatedDynamicColumns = Object.keys(columns).reduce(
            (acc, key) => {
              const isSelected = isClickedOnSelectAll
                ? isSelectAll
                : key === "select_all"
                ? isAllOptionSelected
                : !!options.some((item) => item.value === key);
              return {
                ...acc,
                [key]: {
                  ...columns[key],
                  isSelected,
                },
              };
            },
            {}
          );
          setPageConfig(pageKey, {
            [columnState]: updatedDynamicColumns,
          });
          setState((prevState) => ({
            ...prevState,
            [columnState]: {
              ...prevState[columnState],
              ...updatedDynamicColumns,
            },
          }));
        }}
      />
    </div>
  );

  const includeRecords = async (selectedRows) => {
    setState((prevState) => ({
      ...prevState,
      isRecordIncluding: true,
    }));

    const selectedRowIds = selectedRows.join(",");

    const { error } = await Service.includeRecords(selectedRowIds);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isRecordIncluding: false,
        isIncludingRecords: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success(`Record(s) included successfully.`);
    setState((prevState) => ({
      ...prevState,
      isRecordIncluding: false,
      isIncludingRecords: false,
    }));
    fetchEntries(
      state.startDate,
      state.endDate,
      state.search,
      state.IC,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    );
  };

  const permanentExclude = async (excludedId, isExcluded, excludedReason) => {
    setState((prevState) => ({
      ...prevState,
      isPermanentExclude: true,
    }));

    const payload = {
      id: excludedId,
      is_permanently_excluded: isExcluded,
      reason: excludedReason,
    };

    const { error } = await Service.permanentExclude(payload);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isPermanentExclude: false,
        isPermanentExcluding: false,
        permExcludeReason: defaultState.permExcludeReason,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success(`Record permanently excluded.`);
    setState((prevState) => ({
      ...prevState,
      isPermanentExclude: false,
      isPermanentExcluding: false,
      permExcludeReason: defaultState.permExcludeReason,
    }));
    fetchEntries(
      state.startDate,
      state.endDate,
      state.search,
      state.IC,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    );
  };

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = {
      permExcludeReason: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
    };

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const handleFieldChange = (evt) => {
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;

    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: validate(field, value) || " ",
      },
    }));
  };

  return (
    <>
      <div id="numbers-page-wrapper">
        <Grid container className="mr-5">
          <Grid item xs={12} className={classes.fixedHeader}>
            <div className="mb-2">
              <Typography variant="h4" color="primary" className="ml-2">
                Excluded Records
                <Button
                  startIcon={<ArrowBackIcon />}
                  style={{ float: "right" }}
                  className="mt-1 mr-2"
                  variant="contained"
                  color="primary"
                  onClick={() => history.push("/home")}
                >
                  Back To Home
                </Button>
                <Button
                  startIcon={<ArrowBackIcon />}
                  style={{ float: "right" }}
                  className="mt-1 mr-2"
                  variant="contained"
                  color="primary"
                  onClick={() => history.push("/settlements")}
                >
                  Back To Settlements
                </Button>
              </Typography>
              <Typography variant="body2" style={{ marginLeft: "10px" }}>
                View Excluded Records.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} className="ml-1">
            <div className={"d-flex f-align-center f-wrap f-justify-between"}>
              <div className={"d-flex f-align-center mb-2"}>
                <div style={{ position: "relative" }}>
                  <Datepicker
                    preventClear
                    mask
                    label="Start date"
                    selected={state.startDate}
                    maxDate={new Date(state.endDate)}
                    placement={"bottom-start"}
                    classes={{
                      datepickerWrapper: classes.scheduleDateWrapper,
                      input: {
                        root: classes.payDateWrapper,
                      },
                    }}
                    onCalendarClose={() => {}}
                    onCalendarOpen={() => {}}
                    onMonthChange={() => {}}
                    onChange={(startDate) => {
                      setState((prevState) => ({
                        ...prevState,
                        startDate: getDateString(startDate),
                      }));
                      setPageConfig(PAGE_KEYS.DCN_EXCLUDE_HISTORY, {
                        startDate: getDateString(startDate),
                      });
                    }}
                  />
                </div>
                <div style={{ position: "relative", marginLeft: "16px" }}>
                  <Datepicker
                    preventClear
                    mask
                    label="End date"
                    selected={state.endDate}
                    minDate={new Date(state.startDate)}
                    maxDate={new Date()}
                    placement={"bottom-start"}
                    classes={{
                      datepickerWrapper: classes.scheduleDateWrapper,
                      input: {
                        root: classes.payDateWrapper,
                      },
                    }}
                    onCalendarClose={() => {}}
                    onCalendarOpen={() => {}}
                    onMonthChange={() => {}}
                    onChange={(endDate) => {
                      setState((prevState) => ({
                        ...prevState,
                        endDate: getDateString(endDate),
                      }));
                      setPageConfig(PAGE_KEYS.DCN_EXCLUDE_HISTORY, {
                        endDate: getDateString(endDate),
                      });
                    }}
                  />
                </div>
                <Autocomplete
                  style={{ marginLeft: "16px" }}
                  size="small"
                  className={clsx(classes.settlementCompanyDropdown, {
                    "mt-3": appData.isTabletView,
                  })}
                  options={appDataList.icList}
                  value={state.IC}
                  getOptionLabel={(item) =>
                    `${item.icid || ""} - ${item.last_name || ""} ${
                      item.first_name || ""
                    }`
                  }
                  renderInput={(params) =>
                    state.isFetchingList.users ? (
                      <Skeleton variant="rect" width="100%" height="100%" />
                    ) : (
                      <TextField {...params} label="IC" variant="outlined" />
                    )
                  }
                  onChange={(evt, value) => {
                    setState((prevState) => ({
                      ...prevState,
                      IC: value,
                    }));
                    setPageConfig(PAGE_KEYS.DCN_EXCLUDE_HISTORY, {
                      icFilter: {
                        value,
                      },
                    });
                  }}
                />
              </div>
              <div
                className={clsx("d-flex", {
                  "mt-4 f-justify-start f-wrap": appData.isTabletView,
                })}
              ></div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Paper
              elevation={2}
              className={clsx("p-4 mt-10", classes.paperSpacing)}
            >
              <div
                className={clsx(
                  "d-flex f-align-center f-justify-end p-2 pl-4 pr-4",
                  classes.actionsWrapper
                )}
              >
                {" "}
                {state.selectedRows.length > 0 && (
                  <Button
                    style={{ float: "left" }}
                    className="mt-1 mr-2"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setState((prevState) => {
                        return {
                          ...prevState,
                          isIncludingRecords: true,
                        };
                      });
                    }}
                  >
                    Include Record
                  </Button>
                )}
                <div className="d-flex f-justify-center">
                  {renderDropdown(
                    "dynamicColumns",
                    state.dynamicColumns,
                    PAGE_KEYS.DCN_EXCLUDE_HISTORY
                  )}
                </div>
                {/* <Tooltip placement="top-start" title="Filter">
                  <IconButton
                    color="primary"
                    className={clsx({
                      "bg-primary": !!state.pageFilters?.length,
                    })}
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        isFiltering: true,
                      }));
                    }}
                  >
                    <FilterListIcon
                      className={clsx({
                        "color-white": !!state.pageFilters?.length,
                      })}
                    />
                  </IconButton>
                </Tooltip> */}
                <Tooltip placement="top-end" title="Reset All Filters">
                  <IconButton
                    // disabled={pageConfig === undefined || pageConfig === null}
                    color="primary"
                    onClick={() => {
                      updatePageConfig("dcn-exclude-history");
                      window.location.reload();
                    }}
                  >
                    <RotateLeftIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <CollapsibleGrid
                wrapRef2={wrapRef2}
                isRowOpen={state.isRowOpen}
                handleRowOpen={handleRowOpen}
                handleScroll={handleScroll}
                columns={columnConfig}
                rows={state.entries.map((entry) => ({
                  ...entry,
                  unique_key: `${entry.individual_contractor?.first_name} ${entry.individual_contractor?.last_name} (${entry.individual_contractor?.icid})`,
                }))}
                isLoading={state.isFetching}
                uniqueBy="unique_key"
                hasSelection={true}
                onReady={(instance) => (gridHelper = instance)}
                onSelectionChange={handleSelectionChange}
                onSortChange={handleSortChange}
                classes={{
                  gridActions: "f-justify-end",
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
      {state.isIncludingRecords && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.isIncludingRecords,
          }}
          open={!!state.isIncludingRecords}
          contentText={
            <Typography variant="body2" className="mt-2">
              Do you want to include these records?
            </Typography>
          }
          onConfirm={() => {
            includeRecords(state.selectedRows);
          }}
          onCancel={() => {
            setState((prevState) => {
              return {
                ...prevState,
                isIncludingRecords: false,
              };
            });
          }}
          isConfirmDisabled={state.isRecordIncluding}
          positiveActionLabel={
            <>
              OK
              {state.isRecordIncluding && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn color-white"
                />
              )}
            </>
          }
          negativeActionLabel={false}
        />
      )}
      {state.isPermanentExcluding && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.permExcludePaperSize,
          }}
          open={!!state.isPermanentExcluding}
          contentText={
            <>
              <Typography variant="body2" className="mt-2">
                Do you want to permanent exclude this record?
              </Typography>
              <br></br>

              <TextField
                className="mb-1"
                fullWidth
                name={"permExcludeReason"}
                value={state.permExcludeReason}
                label={`Exclude Reason`}
                variant="outlined"
                size="small"
                error={!!state.errors.permExcludeReason?.trim()}
                helperText={state.errors.permExcludeReason}
                onChange={(evt) => handleFieldChange(evt)}
              />
            </>
          }
          onConfirm={() => {
            permanentExclude(
              state.isPermanentExcluding,
              true,
              state.permExcludeReason
            );
          }}
          onCancel={() => {
            setState((prevState) => {
              return {
                ...prevState,

                isPermanentExcluding: false,
                permExcludeReason: defaultState.permExcludeReason,
                errors: {
                  permExcludeReason: " ",
                },
              };
            });
          }}
          isConfirmDisabled={
            validate() ||
            !state.permExcludeReason?.trim() ||
            state.isPermanentExclude
          }
          positiveActionLabel={
            <>
              OK
              {state.isPermanentExclude && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn color-white"
                />
              )}
            </>
          }
          negativeActionLabel={false}
        />
      )}
      {/* {state.isFiltering && (
        <FilterComponent
          isFiltering={state.isFiltering}
          filterConfig={filterConfig}
          appliedFilters={state.pageFilters}
          onFilterApply={handleFilterChange}
          onClose={() => {
            setState((prevState) => ({
              ...prevState,
              isFiltering: false,
            }));
          }}
        />
      )} */}
      {state.isRowOpen.length > 0 && (
        <div
          ref={wrapRef1}
          onScroll={handleScroll}
          style={{
            overflowX: "auto",
            width: appData.isTabletView
              ? "calc(100vw - 42px)"
              : "calc(100vw - 110px)",
            height: "4%",
            bottom: 0,
            position: "fixed",
          }}
        >
          <div
            style={{
              overflowX: "auto",
              width: "54rem",
            }}
          >
            &nbsp;
          </div>
        </div>
      )}
    </>
  );
};

export default ExcludedHistory;
