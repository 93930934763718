import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  listIcon: {
    minWidth: 32,
  },
  paper: {
    borderRadius: 5,
  },
  profileDetailsWrapper: {
    width: 245,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    boxSizing: "border-box",
    backgroundColor: theme.palette.background.default,
  },
  caretWrapper: {
    width: 200,
    background: "#accf53",
    marginRight: -12,
  },
  arrowRight: {
    width: 0,
    height: 0,
    borderTop: "10px solid transparent",
    borderBottom: "10px solid transparent",
    borderRight: `10px solid #accf53`,
  },
}));
