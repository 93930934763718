import makeStyles from "@material-ui/core/styles/makeStyles";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: "calc(100vh - 550px)",
  },

  lookuptypeInput: {
    minWidth: 198,
    [theme.breakpoints.down("sm")]: {
      minWidth: 244,
    },
  },
  progressBtn: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  paperSpacing: {
    marginTop: "14px !important",
    paddingTop: "7px !important",
    paddingBottom: "0px !important ",
  },
  editLookup: {
    width: "112px",
  },
  paper: {
    minHeight: 400,
  },
  fixedHeader: {
    width: "100%",
    top: "64px",
    height: "75px",
    zIndex: 1000,
    position: "sticky",
    background: "#fafafa",
  },
}));

export default useStyles;
