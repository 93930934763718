export const pdxWestCoordinates = [
  { lat: 47.29799370775518, lng: -89.99788272203509 },

  { lat: 47.31289314270156, lng: -90.67903506578509 },

  { lat: 46.68349908369354, lng: -91.97542178453509 },
  {
    lat: 46.77386651561339,
    lng: -92.15120303453509,
  },

  { lat: 46.65334296034612, lng: -92.23909365953509 },

  { lat: 46.06193455472143, lng: -92.28303897203509 },

  { lat: 45.86337561695426, lng: -92.78841006578509 },

  { lat: 45.72549482941401, lng: -92.81038272203509 },

  { lat: 45.67945875571354, lng: -92.96419131578509 },

  { lat: 45.587272882423584, lng: -92.81038272203509 },

  { lat: 45.46412249341043, lng: -92.65657412828509 },

  { lat: 45.309805367098654, lng: -92.76643740953509 },

  { lat: 45.124068704608355, lng: -92.81038272203509 },

  { lat: 44.968824706554486, lng: -92.76643740953509 },

  { lat: 44.81315938937766, lng: -92.78841006578509 },

  { lat: 44.82355014515319, lng: -92.76643740953509 },

  { lat: 44.6205918890523, lng: -92.61262881578509 },

  { lat: 44.5110117724785, lng: -92.32698428453509 },

  { lat: 44.3698197470647, lng: -92.04133975328509 },

  { lat: 44.306958216933346, lng: -91.84358584703509 },

  { lat: 44.16527337965506, lng: -91.84358584703509 },

  { lat: 44.00744570125718, lng: -91.53596865953509 },

  { lat: 43.8174966868674, lng: -91.22835147203509 },

  { lat: 43.626941490140375, lng: -91.22835147203509 },

  { lat: 43.403861188448495, lng: -91.22835147203509 },

  { lat: 43.30800292495715, lng: -91.00862490953509 },

  { lat: 43.11583219663031, lng: -91.18440615953509 },

  { lat: 42.92305608098867, lng: -91.09651553453509 },

  { lat: 42.761947071938046, lng: -91.07454287828509 },

  { lat: 42.58424202973844, lng: -90.63508975328509 },

  { lat: 42.42225117122474, lng: -90.65074569510482 },

  { lat: 42.37357207898629, lng: -90.43101913260482 },

  { lat: 42.259840697925725, lng: -90.40904647635482 },

  { lat: 42.11331268399706, lng: -90.14537460135482 },

  { lat: 41.81923822354575, lng: -90.14537460135482 },
  { lat: 41.83616276490677, lng: -90.16131040235508 },
  { lat: 41.721464298679976, lng: -90.34807798048008 },
  { lat: 41.59834549332322, lng: -90.34807798048008 },
  { lat: 41.51613554691525, lng: -90.49090024610508 },
  { lat: 41.45029231048913, lng: -90.69964048048008 },
  { lat: 41.433821048382484, lng: -90.84246274610508 },
  { lat: 41.417345605673795, lng: -90.93035337110508 },
  { lat: 41.417345605673795, lng: -91.06218930860508 },
  { lat: 41.219314317430644, lng: -91.08416196485508 },
  { lat: 41.15317021098255, lng: -90.99627133985508 },
  { lat: 41.037257289254285, lng: -90.93035337110508 },
  { lat: 40.946039399632355, lng: -90.96331235548008 },
  { lat: 40.82144803917365, lng: -91.06218930860508 },
  { lat: 40.66329573219932, lng: -91.12810727735508 },
  { lat: 40.59659281350177, lng: -91.38079282423008 },
  { lat: 40.53817311249678, lng: -91.40276548048008 },
  { lat: 40.4546279539754, lng: -91.35882016798008 },
  { lat: 40.38771693977267, lng: -91.41375180860508 },
  { lat: 40.287225664946284, lng: -91.50164243360508 },
  { lat: 40.06898138197113, lng: -91.50164243360508 },
  { lat: 39.93432847048302, lng: -91.39177915235508 },
  { lat: 39.88376518291048, lng: -91.46868344923008 },
  { lat: 39.816289421517716, lng: -91.36980649610508 },
  { lat: 39.74874734945215, lng: -91.36980649610508 },
  { lat: 39.75608225262614, lng: -91.35846251038889 },
  { lat: 39.51918838623532, lng: -91.09479063538889 },
  { lat: 39.3834563835328, lng: -90.94098204163889 },
  { lat: 39.21341948614922, lng: -90.69928282288889 },
  { lat: 39.008830419466456, lng: -90.72125547913889 },
  { lat: 38.83788613956153, lng: -90.58941954163889 },
  { lat: 38.97467451791397, lng: -90.39166563538889 },
  { lat: 38.87210794150261, lng: -90.12799376038889 },
  { lat: 38.717980203593825, lng: -90.04010313538889 },
  { lat: 38.49476329526713, lng: -90.30377501038889 },
  { lat: 38.236343349675685, lng: -90.34772032288889 },
  { lat: 38.04624950561131, lng: -90.19391172913889 },
  { lat: 37.9423533285458, lng: -89.95221251038889 },
  { lat: 37.80359630036147, lng: -89.66656797913889 },
  { lat: 37.664578090658615, lng: -89.46881407288889 },
  { lat: 37.45556178082248, lng: -89.53473204163889 },
  { lat: 37.350833885496556, lng: -89.38092344788889 },
  { lat: 37.26344887578323, lng: -89.53473204163889 },
  { lat: 37.05331084633267, lng: -89.35895079163889 },
  { lat: 36.98313510808659, lng: -89.16119688538889 },
  { lat: 36.86017169547374, lng: -89.13922422913889 },
  { lat: 36.71939943952652, lng: -89.13922422913889 },
  { lat: 36.578368725103616, lng: -89.20514219788889 },
  { lat: 36.47242630514837, lng: -89.40289610413889 },
  { lat: 36.50775656491177, lng: -89.62262266663889 },
  { lat: 36.313240842692615, lng: -89.60065001038889 },
  { lat: 36.15372968089157, lng: -89.66656797913889 },
  { lat: 35.993893392189364, lng: -89.73248594788889 },
  { lat: 35.86935187292711, lng: -89.73248594788889 },
  { lat: 35.72677866399957, lng: -89.88629454163889 },
  { lat: 35.566078254737306, lng: -89.93023985413889 },
  { lat: 35.40505491433186, lng: -90.19391172913889 },
  { lat: 35.18985587241545, lng: -90.19391172913889 },
  { lat: 35.01008673069406, lng: -90.25982969788889 },
  { lat: 34.811883374941225, lng: -90.45758360413889 },
  { lat: 34.649361477677765, lng: -90.54547422913889 },
  { lat: 34.4140442188354, lng: -90.58941954163889 },
  { lat: 34.268903444202216, lng: -90.80914610413889 },
  { lat: 34.270598117852124, lng: -90.85987222588192 },
  { lat: 34.08882296909943, lng: -90.88184488213192 },
  { lat: 33.94312119040322, lng: -91.12354410088192 },
  { lat: 33.797169602817796, lng: -91.05762613213192 },
  { lat: 33.72410029302434, lng: -91.23340738213192 },
  { lat: 33.57777497013322, lng: -91.21143472588192 },
  { lat: 33.43120115315317, lng: -91.21143472588192 },
  { lat: 33.32110804005048, lng: -91.16748941338192 },
  { lat: 33.43120115315317, lng: -91.23340738213192 },
  { lat: 33.32110804005048, lng: -91.05762613213192 },
  { lat: 33.17410065950491, lng: -91.12354410088192 },
  { lat: 33.026846284592345, lng: -91.14551675713192 },
  { lat: 32.897796533477795, lng: -91.10157144463192 },
  { lat: 33.014563861396915, lng: -91.14551675713192 },
  { lat: 32.88549616062818, lng: -91.01368081963192 },
  { lat: 32.719275398836984, lng: -91.21143472588192 },
  { lat: 32.57126301131872, lng: -91.10157144463192 },
  { lat: 32.423005960291356, lng: -91.01368081963192 },
  { lat: 32.293080803639754, lng: -90.92579019463192 },
  { lat: 32.181567954195685, lng: -91.23340738213192 },
  { lat: 32.06991836138434, lng: -91.05762613213192 },
  { lat: 31.88353253110706, lng: -91.32129800713192 },
  { lat: 31.734151702682226, lng: -91.34327066338192 },
  { lat: 31.603245558010304, lng: -91.47510660088192 },
  { lat: 31.565809936081735, lng: -91.62891519463192 },
  { lat: 31.359645559616744, lng: -91.51905191338192 },
  { lat: 31.209423148509384, lng: -91.67286050713192 },
  { lat: 31.340880850925043, lng: -91.54102456963192 },
  { lat: 31.209423148509384, lng: -91.67286050713192 },
  { lat: 31.204192267668233, lng: -91.67285666668539 },
  { lat: 31.19009587358353, lng: -91.58496604168539 },
  { lat: 31.11958241611385, lng: -91.62341819012289 },
  { lat: 31.05372255214237, lng: -91.55750022137289 },
  { lat: 30.98781707236579, lng: -91.62891135418539 },
  { lat: 30.992526118219406, lng: -89.72827658856039 },
  { lat: 30.918016880007652, lng: -89.75574240887289 },
  { lat: 30.88030862097555, lng: -89.77222190106039 },
  { lat: 30.83315241987198, lng: -89.77771506512289 },
  { lat: 30.804847572528796, lng: -89.81616721356039 },
  { lat: 30.748212863577184, lng: -89.82166037762289 },
  { lat: 30.69154483204445, lng: -89.84912619793539 },
  { lat: 30.649021962017027, lng: -89.83264670574789 },
  { lat: 30.606480383389666, lng: -89.79968772137289 },
  { lat: 30.54972920262037, lng: -89.77771506512289 },
  { lat: 30.492944825440603, lng: -89.73376975262289 },
  { lat: 30.464540199562634, lng: -89.69531760418539 },
  { lat: 30.41718074659105, lng: -89.66785178387289 },
  { lat: 30.37453757849531, lng: -89.66785178387289 },
  { lat: 30.379440045546552, lng: -89.66804121097336 },
  { lat: 30.32255639310816, lng: -89.61860273441086 },
  { lat: 30.284615594731033, lng: -89.65156171878586 },
  { lat: 30.241914652355796, lng: -89.61310957034836 },
  { lat: 30.18495119233523, lng: -89.56916425784836 },
  { lat: 30.199195148832175, lng: -89.52521894534836 },
];

export const pdxSouthCoordinates = [
  { lat: 36.485358, lng: -89.544233 },
  { lat: 36.52355, lng: -88.047566 },
  { lat: 36.676128, lng: -88.071323 },
  { lat: 36.67368, lng: -88.047317 },
  { lat: 36.639355, lng: -87.854776 },
  { lat: 36.622187, lng: -85.202001 },
  { lat: 36.587839, lng: -81.693491 },
  { lat: 36.587839, lng: -79.147682 },
  { lat: 36.536289, lng: -75.874498 },
];

export const pdxNorthCoordinates = [
  { lat: 42.270196, lng: -79.761767 },
  { lat: 41.998812, lng: -79.761752 },
  { lat: 41.9994, lng: -75.359366 },
  { lat: 41.995318, lng: -75.346491 },
  { lat: 41.992766, lng: -75.341084 },
  { lat: 41.986993, lng: -75.337522 },
  { lat: 41.98409, lng: -75.337608 },
  { lat: 41.975189, lng: -75.34177 },
  { lat: 41.970308, lng: -75.33838 },
  { lat: 41.97085, lng: -75.336406 },
  { lat: 41.968138, lng: -75.328381 },
  { lat: 41.961501, lng: -75.321772 },
  { lat: 41.959682, lng: -75.319497 },
  { lat: 41.953394, lng: -75.317266 },
  { lat: 41.948894, lng: -75.310314 },
  { lat: 41.949213, lng: -75.306752 },
  { lat: 41.948575, lng: -75.301044 },
  { lat: 41.953171, lng: -75.30143 },
  { lat: 41.954799, lng: -75.294435 },
  { lat: 41.951671, lng: -75.291345 },
  { lat: 41.946309, lng: -75.29186 },
  { lat: 41.942574, lng: -75.288856 },
  { lat: 41.941968, lng: -75.28465 },
  { lat: 41.938712, lng: -75.278943 },
  { lat: 41.918373, lng: -75.274651 },
  { lat: 41.90496, lng: -75.266798 },
  { lat: 41.896336, lng: -75.273535 },
  { lat: 41.886784, lng: -75.271089 },
  { lat: 41.883876, lng: -75.260875 },
  { lat: 41.875792, lng: -75.257656 },
  { lat: 41.869433, lng: -75.264522 },
  { lat: 41.861795, lng: -75.257914 },
  { lat: 41.862338, lng: -75.249374 },
  { lat: 41.867356, lng: -75.24019 },
  { lat: 41.85716, lng: -75.226372 },
  { lat: 41.857608, lng: -75.223453 },
  { lat: 41.867963, lng: -75.214226 },
  { lat: 41.869465, lng: -75.202295 },
  { lat: 41.864767, lng: -75.191052 },
  { lat: 41.859909, lng: -75.186074 },
  { lat: 41.872501, lng: -75.175903 },
  { lat: 41.869912, lng: -75.168393 },
  { lat: 41.856297, lng: -75.168435 },
  { lat: 41.848849, lng: -75.158662 },
  { lat: 41.851918, lng: -75.141161 },
  { lat: 41.845268, lng: -75.130861 },
  { lat: 41.845524, lng: -75.116785 },
  { lat: 41.839386, lng: -75.113351 },
  { lat: 41.829409, lng: -75.115068 },
  { lat: 41.822502, lng: -75.113351 },
  { lat: 41.818664, lng: -75.100992 },
  { lat: 41.811244, lng: -75.088977 },
  { lat: 41.81585, lng: -75.077303 },
  { lat: 41.813035, lng: -75.072153 },
  { lat: 41.797936, lng: -75.076959 },
  { lat: 41.7964, lng: -75.079706 },
  { lat: 41.797424, lng: -75.090005 },
  { lat: 41.792561, lng: -75.096185 },
  { lat: 41.788209, lng: -75.101678 },
  { lat: 41.773616, lng: -75.104768 },
  { lat: 41.769263, lng: -75.101335 },
  { lat: 41.768239, lng: -75.092065 },
  { lat: 41.771056, lng: -75.073526 },
  { lat: 41.765422, lng: -75.063226 },
  { lat: 41.75108, lng: -75.052927 },
  { lat: 41.734686, lng: -75.054986 },
  { lat: 41.711623, lng: -75.050866 },
  { lat: 41.712136, lng: -75.065972 },
  { lat: 41.707779, lng: -75.068719 },
  { lat: 41.685989, lng: -75.05224 },
  { lat: 41.678041, lng: -75.05224 },
  { lat: 41.672655, lng: -75.059106 },
  { lat: 41.669321, lng: -75.057733 },
  { lat: 41.66836, lng: -75.054345 },
  { lat: 41.65406, lng: -75.048422 },
  { lat: 41.631162, lng: -75.048594 },
  { lat: 41.616726, lng: -75.044045 },
  { lat: 41.615635, lng: -75.047306 },
  { lat: 41.618972, lng: -75.054001 },
  { lat: 41.616662, lng: -75.061897 },
  { lat: 41.610373, lng: -75.059409 },
  { lat: 41.609153, lng: -75.063442 },
  { lat: 41.608512, lng: -75.075201 },
  { lat: 41.590347, lng: -75.059409 },
  { lat: 41.582066, lng: -75.046363 },
  { lat: 41.568197, lng: -75.039582 },
  { lat: 41.564472, lng: -75.030312 },
  { lat: 41.56383, lng: -75.027909 },
  { lat: 41.543983, lng: -75.015378 },
  { lat: 41.539293, lng: -75.025419 },
  { lat: 41.533254, lng: -75.024475 },
  { lat: 41.531841, lng: -75.014949 },
  { lat: 41.517896, lng: -75.000529 },
  { lat: 41.50832, lng: -75.004048 },
  { lat: 41.507163, lng: -75.000271 },
  { lat: 41.509091, lng: -74.986882 },
  { lat: 41.496107, lng: -74.982161 },
  { lat: 41.487106, lng: -74.986538 },
  { lat: 41.479583, lng: -74.981903 },
  { lat: 41.476239, lng: -74.956413 },
  { lat: 41.483312, lng: -74.944911 },
  { lat: 41.482927, lng: -74.933324 },
  { lat: 41.476689, lng: -74.923711 },
  { lat: 41.476303, lng: -74.913926 },
  { lat: 41.472895, lng: -74.909977 },
  { lat: 41.460546, lng: -74.907231 },
  { lat: 41.455336, lng: -74.889979 },
  { lat: 41.449739, lng: -74.890837 },
  { lat: 41.441761, lng: -74.897274 },
  { lat: 41.438158, lng: -74.892811 },
  { lat: 41.444335, lng: -74.857794 },
  { lat: 41.430243, lng: -74.834705 },
  { lat: 41.431273, lng: -74.826207 },
  { lat: 41.443949, lng: -74.811701 },
  { lat: 41.437064, lng: -74.800458 },
  { lat: 41.422778, lng: -74.794793 },
  { lat: 41.421619, lng: -74.787496 },
  { lat: 41.426575, lng: -74.772649 },
  { lat: 41.422907, lng: -74.759602 },
  { lat: 41.431402, lng: -74.74072 },
  { lat: 41.424709, lng: -74.734368 },
  { lat: 41.40733, lng: -74.742178 },
  { lat: 41.397158, lng: -74.734969 },
  { lat: 41.394711, lng: -74.727587 },
  { lat: 41.394325, lng: -74.717717 },
  { lat: 41.376873, lng: -74.706902 },
  { lat: 41.363475, lng: -74.688449 },
  { lat: 41.353683, lng: -74.700807 },
  { lat: 41.345693, lng: -74.728787 },
  { lat: 41.344469, lng: -74.756167 },
  { lat: 41.32507, lng: -74.768957 },
  { lat: 41.322943, lng: -74.793849 },
  { lat: 41.318302, lng: -74.796681 },
  { lat: 41.31134, lng: -74.792561 },
  { lat: 41.296897, lng: -74.812816 },
  { lat: 41.28832, lng: -74.830413 },
  { lat: 41.262259, lng: -74.846376 },
  { lat: 41.254904, lng: -74.845604 },
  { lat: 41.251097, lng: -74.848866 },
  { lat: 41.250064, lng: -74.85659 },
  { lat: 41.235866, lng: -74.862942 },
  { lat: 41.227733, lng: -74.868092 },
  { lat: 41.219728, lng: -74.859681 },
  { lat: 41.189829, lng: -74.878906 },
  { lat: 41.177168, lng: -74.885 },
  { lat: 41.137296, lng: -74.924996 },
  { lat: 41.130832, lng: -74.937957 },
  { lat: 41.126435, lng: -74.947571 },
  { lat: 41.122944, lng: -74.948086 },
  { lat: 41.113309, lng: -74.966882 },
  { lat: 41.110011, lng: -74.98113 },
  { lat: 41.088279, lng: -74.992031 },
  { lat: 41.088667, lng: -74.98362 },
  { lat: 41.096947, lng: -74.969287 },
  { lat: 41.088667, lng: -74.967226 },
  { lat: 41.078316, lng: -74.984477 },
  { lat: 41.077345, lng: -74.993662 },
  { lat: 41.061492, lng: -75.015376 },
  { lat: 41.039679, lng: -75.027136 },
  { lat: 41.014685, lng: -75.080005 },
  { lat: 41.014296, lng: -75.088161 },
  { lat: 40.990783, lng: -75.13245 },
  { lat: 40.980092, lng: -75.132536 },
  { lat: 40.973483, lng: -75.136398 },
  { lat: 40.968428, lng: -75.131249 },
  { lat: 40.970113, lng: -75.121722 },
  { lat: 40.952418, lng: -75.117859 },
  { lat: 40.918961, lng: -75.089107 },
  { lat: 40.913577, lng: -75.078978 },
  { lat: 40.89483, lng: -75.075373 },
  { lat: 40.863809, lng: -75.051342 },
  { lat: 40.84706, lng: -75.067905 },
  { lat: 40.849268, lng: -75.092538 },
  { lat: 40.839853, lng: -75.098032 },
  { lat: 40.825501, lng: -75.083356 },
  { lat: 40.806338, lng: -75.101207 },
  { lat: 40.798542, lng: -75.101208 },
  { lat: 40.78964, lng: -75.109877 },
  { lat: 40.785026, lng: -75.126613 },
  { lat: 40.773327, lng: -75.133738 },
  { lat: 40.778332, lng: -75.171502 },
  { lat: 40.761041, lng: -75.179399 },
  { lat: 40.751418, lng: -75.197509 },
  { lat: 40.730088, lng: -75.182405 },
  { lat: 40.724234, lng: -75.18721 },
  { lat: 40.718249, lng: -75.187811 },
  { lat: 40.711288, lng: -75.196051 },
  { lat: 40.694371, lng: -75.204291 },
  { lat: 40.689294, lng: -75.204462 },
  { lat: 40.688644, lng: -75.202915 },
  { lat: 40.681549, lng: -75.19751 },
  { lat: 40.680247, lng: -75.19339 },
  { lat: 40.679336, lng: -75.190215 },
  { lat: 40.680052, lng: -75.183177 },
  { lat: 40.677839, lng: -75.177941 },
  { lat: 40.67504, lng: -75.176825 },
  { lat: 40.667358, lng: -75.182318 },
  { lat: 40.650169, lng: -75.20177 },
  { lat: 40.636492, lng: -75.190987 },
  { lat: 40.620859, lng: -75.190644 },
  { lat: 40.617015, lng: -75.202574 },
  { lat: 40.602875, lng: -75.192103 },
  { lat: 40.588276, lng: -75.190129 },
  { lat: 40.579607, lng: -75.19545 },
  { lat: 40.568198, lng: -75.185065 },
  { lat: 40.561873, lng: -75.169702 },
  { lat: 40.576347, lng: -75.139403 },
  { lat: 40.572762, lng: -75.11477 },
  { lat: 40.566763, lng: -75.099149 },
  { lat: 40.541461, lng: -75.070436 },
  { lat: 40.473591, lng: -75.064599 },
  { lat: 40.456352, lng: -75.070435 },
  { lat: 40.419769, lng: -75.059793 },
  { lat: 40.414542, lng: -75.047777 },
  { lat: 40.404477, lng: -75.031513 },
  { lat: 40.409771, lng: -74.992377 },
  { lat: 40.398856, lng: -74.96744 },
  { lat: 40.338956, lng: -74.942034 },
  { lat: 40.315399, lng: -74.903926 },
  { lat: 40.314614, lng: -74.893282 },
  { lat: 40.28555, lng: -74.860667 },
  { lat: 40.251494, lng: -74.84247 },
  { lat: 40.243011, lng: -74.830906 },
  { lat: 40.226597, lng: -74.791134 },
  { lat: 40.214604, lng: -74.771018 },
  { lat: 40.185824, lng: -74.754838 },
  { lat: 40.180185, lng: -74.74162 },
  { lat: 40.160116, lng: -74.722223 },
  // New coordinates for the line dividing central and southern NJ
  { lat: 40.150538, lng: -74.724161 },
  { lat: 40.163133, lng: -74.706309 },
  { lat: 40.180972, lng: -74.707682 },
  { lat: 40.137678, lng: -74.588413 },
  { lat: 39.773714, lng: -74.389628 },
  { lat: 39.59643, lng: -74.389971 },
  { lat: 39.581349, lng: -74.396837 },
  { lat: 39.56944, lng: -74.404734 },
  { lat: 39.568646, lng: -74.418123 },
  { lat: 39.561236, lng: -74.414346 },
  { lat: 39.557001, lng: -74.418466 },
];
