import makeStyles from "@material-ui/core/styles/makeStyles";
import yellow from "@material-ui/core/colors/yellow";

const useStyles = makeStyles((theme) => ({
  datepickerWrapper: {
    minWidth: 208,
    maxWidth: 208,
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  actionsWrapper: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  notesPaperSize: {
    width: 470,
    height: 270,
  },
  addLookupEmailpaperSize: {
    width: 530,
  },
  tableHeaderwidth: {
    minWidth: 144,
    maxWidth: 144,
  },
  buttonStyle: {
    borderRadius: "1rem",
    background: "rgb(0 71 0)",
    marginTop: "6px",
    border: "0px",
    height: "30px",
    minWidth: "28px",
    width: "28px",
    marginRight: "4px",
  },
  tableHeaderCell: {
    minWidth: 144,
  },
  addNewreviewNotesHeaderCell: {
    maxWidth: "500px",
    minWidth: "360px",
  },
  warningIcon: {
    color: "#d7d718",
    zIndex: 1,
    position: "relative",
    marginTop: "2px",
  },
  warningIconHeight: {
    height: "24px",
    position: "relative",
  },
  exclamationMark: {
    width: "3px",
    height: "9px",
    background: "black",
    position: "absolute",
    top: "11px",
    left: "11px",
  },
  addNewReviewNote: {
    minWidth: "200px",
  },
  notification: {
    border: `2px solid ${theme.palette.grey[500]}`,
  },
  rejectBtn: {
    background: theme.palette.background.error.light,
  },
  highlightYellow: {
    fontColor: yellow[50],
  },
  notesDesign: {
    minWidth: 264,
    minHeight: 150,
  },
  rightAction: {
    minWidth: 196,
  },
  wrapTableTitle: {
    minWidth: 125,
    maxWidth: 125,
  },
  autocompleteFilters: {
    minWidth: 208,
  },
  icidTitle: {
    minWidth: "450px",
  },
  pdxCompanyHeader: {
    minWidth: 200,
    maxWidth: 200,
  },
  tableHeaderNotesWidth: {
    minWidth: 200,
    maxWidth: 200,
  },
  icidFlagTitle: {
    minWidth: 320,
    maxWidth: 320,
    paddingLeft: "48px",
  },
  scheduleDateWrapper: {
    "& .react-datepicker__close-icon": {
      top: 2,
      right: 36,
    },
    "& .react-datepicker__close-icon::after": {
      background: "transparent",
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: 28,
    },
  },
  bottomNavigation: {
    backgroundColor: "white",
    position: "fixed",
    bottom: 0,
    right: 0,
    width: "100%",
  },
  fixedHeader: {
    width: "100%",
    top: "64px",
    height: "75px",
    zIndex: 1000,
    position: "sticky",
    background: "#fafafa",
  },
}));

export default useStyles;
