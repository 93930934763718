import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  datepickerWrapper: {
    minWidth: 164,
    maxWidth: 164,
    "& .MuiOutlinedInput-input": {
      padding: "11px 16px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 14px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  paper: {
    maxHeight: window.innerHeight - 100,
    minHeight: window.innerHeight - 100,
    maxWidth: window.innerWidth - 200,
    minWidth: window.innerWidth - 200,
  },
  rangeAutocomplete: {
    minWidth: 254,
    maxWidth: 254,
  },
  gridContainer: {
    maxHeight: "100%",
  },
  confirmDialogPaper: {
    minWidth: 364,
  },
}));

export default useStyles;
