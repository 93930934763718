import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Dialog } from "shared/components";
import { preventInputKeyCodes, validator } from "utils";
import Service from "../service";
import { noop, VALIDATIONS } from "shared/constants";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import useStyles from "./style";

let isFieldChange = false;
const NUMBER_TYPE_FIELDS = ["quantity", "bill_price", "pay_price"];
const defaultState = {
  id: null,
  bill_price: null,
  quantity: null,
  pay_price: null,
  priceMatrixName: null,
  isLoading: false,
  errors: {
    bill_price: " ",
    quantity: " ",
    pay_price: " ",
    priceMatrixName:" ",
  },
};

const Form = ({
  open = false,
  customerBranchId = null,
  selectedPriceMatrixName="",
  entries = [],
  isNew = false,
  entry = null,
  onClose = noop,
  handleDataLoad = noop,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);

  console.log(entry,"entry")

  useEffect(() => {
    if (entry && !isNew) {
      setState((prevState) => ({
        ...prevState,
        priceMatrixName: entry.name || defaultState.priceMatrixName,
        id: entry.id || defaultState.id,
        quantity: entry.quantity || defaultState.quantity,
        bill_price: entry.bill_price || defaultState.bill_price,
        pay_price: entry.pay_price || defaultState.pay_price,
      }));
    }
  }, [entry]);

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = {
      bill_price: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.LIMIT, value: 10 },
      ],
      pay_price: [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.LIMIT, value: 10 },
      ],
    };
    if (isNew) {
      fieldValidatorMap.quantity = [
        { type: VALIDATIONS.REQUIRED, value: true },
        { type: VALIDATIONS.LIMIT, value: 10 },
        {
          type: VALIDATIONS.MAX,
          value: 24,
        },
      ];
    }
    if (fieldValidatorMap[field]) {
      const isValueInEntries = entries?.some(
        (entry) => `${entry[field]}` === value
      );

      if (isValueInEntries) {
        const errorMessages = {
          quantity: "The hour value",
          bill_price: "The billing price",
          pay_price: "The settlement price",
        };
        errorMessage = `${errorMessages[field]} already exists in the records.`;
      } else if (parseFloat(value) === 0) {
        errorMessage = "The value cannot be 0.";
      } else {
        const validationError = fieldValidatorMap[field].map((validation) =>
          validator(
            validation.type,
            validation.value,
            value,
            validation.inputType || "string",
            validation.customMessage
          )
        );

        errorMessage = validationError
          .filter((error) => error?.message)
          .map((error) => error?.message)[0];
      }
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const handleFieldChange = (evt) => {
    isFieldChange = true;
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    const type = evt.currentTarget?.type || evt.target?.type;
    let errorMessage = validate(field, value) || " ";

    if (type === "number" && value.indexOf(".") !== -1) {
      let stringValue = value.toString().split(".");
      let length = stringValue[1]?.length > 2;
      if (length) {
        value = +value;
        value = Math.round((value + Number.EPSILON) * 100) / 100;
        value = value + "";
      }
    }
    if (type === "number" && value?.length > 1 && value.indexOf(".") === -1) {
      value = value?.replace(/^0+/, "");
    }

    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  const handleSubmit = async () => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    const { quantity, bill_price, pay_price } = state;

    Object.keys(state).map((key) => {
      if (NUMBER_TYPE_FIELDS.includes(key)) {
        state[key] = Number.isInteger(+state[key])
          ? (+state[key]).toFixed(0)
          : (+state[key]).toFixed(2);
      }
    });
    const serviceMethod = isNew ? "post" : "put";

    const dataToSend = {
      customer_branch_id: customerBranchId?.id,
      name:selectedPriceMatrixName,
      quantity: quantity,
      bill_price: bill_price,
      pay_price: pay_price,
    };

    if (!isNew ) {
      dataToSend.id = entry.id;
    }

    const { error } = await Service[serviceMethod](dataToSend);

    if (error) {
      setState((prevState) => ({ ...prevState, isLoading: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success(`Entry ${isNew ? "created" : "updated"} successfully.`);
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
    }));
    handleClose(true);
  };

  const handleClose = (isSubmitted = false) => {
    isFieldChange = false;
    onClose(isSubmitted);
    setState(defaultState);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <Dialog.Title hasClose>{`${
        (isNew) ? "New Entry" : `Update Entry (Hour - ${entry.quantity})`
      }`}</Dialog.Title>
      <Dialog.Content>
        {(isNew) && (
          <TextField
            fullWidth
            required
            type="number"
            label="Hour"
            variant="outlined"
            name="quantity"
            size="small"
            onWheel={(event) => {
              event.target.blur();
            }}
            onKeyDown={preventInputKeyCodes}
            value={state.quantity}
            className="mb-1 mt-2"
            onChange={handleFieldChange}
            error={!!state.errors.quantity.trim()}
            helperText={state.errors.quantity}
          />
        )}
        <TextField
          fullWidth
          required
          type="number"
          label="Billing"
          variant="outlined"
          name="bill_price"
          size="small"
          onWheel={(event) => {
            event.target.blur();
          }}
          onKeyDown={preventInputKeyCodes}
          value={state.bill_price}
          className="mb-1 mt-2"
          onChange={handleFieldChange}
          error={!!state.errors.bill_price.trim()}
          helperText={state.errors.bill_price}
          InputProps={{
            startAdornment: <InputAdornment>$</InputAdornment>,
          }}
        />
        <TextField
          fullWidth
          required
          type="number"
          label="Settlement"
          variant="outlined"
          name="pay_price"
          size="small"
          onKeyDown={preventInputKeyCodes}
          onWheel={(event) => {
            event.target.blur();
          }}
          value={state.pay_price}
          className="mb-1 mt-2"
          onChange={handleFieldChange}
          error={!!state.errors.pay_price.trim()}
          helperText={state.errors.pay_price}
          InputProps={{
            startAdornment: <InputAdornment>$</InputAdornment>,
          }}
        />
      </Dialog.Content>
      <Dialog.Actions>
        <div className="p-4">
          <Button
            variant="outlined"
            onClick={handleClose}
            className="ml-2 mr-2"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="ml-2 mr-2"
            disabled={state.isLoading || !isFieldChange || validate()}
            onClick={handleSubmit}
          >
            {isNew ? "Create" : "Update"}
            {state.isLoading && (
              <CircularProgress size={24} className="p-absolute progress-btn" />
            )}
          </Button>
        </div>
      </Dialog.Actions>
    </Dialog>
  );
};

export default Form;
