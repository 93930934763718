import { Route, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getToken, isJWTExpired } from 'utils';

/**
 * Route component that ensures authorization for protected routes.
 */
export default withRouter(
	({ history, component: Component, currentUser, ...rest }) => {
		const isAuthenticated = !isJWTExpired(getToken());
		return (
			<Route
				{...rest}
				render={props => {
					if (isAuthenticated) {
						return <Component {...props} />;
					} else {
						// toast.error('Redirecting to login screen..');
						history.push(`/sign-in?redirectUrl=${window.location.href}`);
					}
				}}
			/>
		);
	}
);
