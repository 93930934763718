/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Tooltip, Typography } from "@material-ui/core";
import useStyles from "./style";
import clsx from "clsx";
import { Grid } from "shared";

import { getUTCDateString, updateLayout } from "utils";
import { useEffect } from "react";
import { modeOfPaymentList } from "modules/shared/constants";

const PaymentSummaryGrid = ({ isFetchingData = false, entries = [] }) => {
  const classes = useStyles();

  const columnConfig = [
    {
      id: "payment_number",
      label: "Payment No.",
      field: "payment_number",
      render: (row) => {
        const title = row.payment_number;
        return (
          <Tooltip title={title} placement="top-start">
            <Typography variant="body2">{title}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "date",
      label: "Payment Date",
      field: "date",
      render: (row) => {
        const title = getUTCDateString(row.date);
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "invoice_number",
      label: "Invoice No.",
      field: "invoice_number",
      render: (row) => {
        const title = row.invoice_number;
        return (
          <Tooltip title={title} placement="top-start">
            <Typography variant="body2">{title}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "invoice_date",
      label: "Invoice Date",
      field: "invoice_date",
      render: (row) => {
        const title = getUTCDateString(row.invoice.invoice_date);
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "reference_number",
      label: "Reference Number",
      field: "reference_number",
      render: (row) => {
        const title = row.reference_number;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "amount",
      label: "Amount",
      field: "amount",
      render: (row) => {
        const title = `$${row?.amount || "0.00"}`;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      id: "mode",
      label: "Mode of Payment",
      field: "mode",
      render: (row) => {
        const title = modeOfPaymentList.find((ele) => ele.value === row?.mode);
        return (
          <Tooltip title={`${title.label || "-"}`} placement="top-start">
            <Typography variant="body2">{`${title.label || "-"}`}</Typography>
          </Tooltip>
        );
      },
    },
  ];

  useEffect(() => {
    setTimeout(updateLayout, 1000);
  }, [entries]);

  return (
    <Paper
      element={4}
      className={clsx("mb-4 mt-8", classes.invoice)}
      style={{ position: "relative" }}
    >
      <Typography variant="h6" className="m-2" color="primary">
        Other payments related to this invoice
      </Typography>
      <Grid
        columns={columnConfig}
        rows={entries}
        actionBarConfig={null}
        hasSelection={false}
        isLoading={isFetchingData}
        hasPagination={false}
      />
    </Paper>
  );
};

export default PaymentSummaryGrid;
