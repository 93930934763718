import React, { useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Divider,
  InputAdornment,
  Tooltip,
  IconButton,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import useStyles from "./style";
import { AppContext, noop } from "shared";
import NoRecords from "assets/images/norecord.svg";
import { preventInputKeyCodes } from "utils";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const transformRows = (rows) => {
  return rows.map((row) => {
    let description = `${row.route_name} - ${row.ic_name}`;
    let quantity = `${row.bill_quantity} (Days)`;
    let rate = `${row.bill_rate}`;
    let amount = `${row.bill_amount}`;

    if (row.bill_fuel_surcharge && row.bill_fuel_surcharge !== "0.00") {
      description += `\nFuel Surcharge`;
      quantity += `\n-`;
      rate += `\n${row.bill_fuel_surcharge}%`;
      amount += `\n${row.bill_fuel_amount}`;
    }

    if (row.bill_extra_miles && row.bill_extra_miles !== "0.00") {
      description += `\nExtra Miles`;
      quantity += `\n${row.bill_extra_miles} (Miles)`;
      rate += `\n${row.bill_rate_per_extra_mile}`;
      amount += `\n${row.bill_extra_mile_amount}`;
    }

    if (row.toll_amount && row.toll_amount !== "0.00") {
      description += `\nTolls`;
      quantity += `\n-`;
      rate += `\n${row.toll_amount}`;
      amount += `\n${row.toll_amount}`;
    }

    return {
      description,
      quantity,
      rate,
      amount,
      manual_entry: row.manual_entry,
      id: row.id,
    };
  });
};

const InvoiceTable = ({
  rows = [],
  discount = 0,
  discountAmount = 0,
  adjustment = 0,
  errors = {},
  finalize = false,
  isDiscountAmount = false,
  handleFieldChange = noop,
  handleDeleteDialog = noop,
  handleDiscount = noop,
  handleEditDialog = noop,
}) => {
  const { appData } = useContext(AppContext);
  const classes = useStyles({ isTabletView: appData.isTabletView, rows });

  const transformedRows = transformRows(rows);

  const initialTotalAmount = transformedRows
    .reduce((sum, row) => {
      const amounts = row.amount.split("\n").map(parseFloat);
      return sum + amounts.reduce((acc, curr) => acc + curr, 0);
    }, 0)
    .toFixed(2);

  const totalAmount = (
    parseFloat(initialTotalAmount) -
    parseFloat(discountAmount) +
    parseFloat(adjustment || 0)
  ).toFixed(2);

  return (
    <div className={classes.grid}>
      {/* {isDraft && <div className={classes.watermark}>PAID</div>} */}
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader className={classes.table} aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>ACTION</TableCell>
              <TableCell>DESCRIPTION</TableCell>
              <TableCell>QUANTITY</TableCell>
              <TableCell>RATE</TableCell>
              <TableCell>AMOUNT</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!rows || (rows && rows.length === 0) ? (
              <TableCell colSpan={4} align="center">
                <div className="d-flex f-justify-center">
                  <img
                    className={classes.noRecordsImg}
                    src={NoRecords}
                    alt="noRecord"
                  />
                </div>
              </TableCell>
            ) : (
              transformedRows.map((row, index) => (
                <TableRow
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#80808017" : "inherit",
                  }}
                >
                  <TableCell>
                    <Tooltip title="Edit Row" placement="top-start">
                      <IconButton
                        disabled={!row.manual_entry || finalize}
                        color="primary"
                        style={{ cursor: row.manual_entry && "pointer" }}
                        onClick={() => handleEditDialog(row.id)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Row" placement="top-start">
                      <IconButton
                        disabled={!row.manual_entry || finalize}
                        style={{
                          color:
                            row.manual_entry && !finalize ? "#e53935" : null,
                          cursor: row.manual_entry && "pointer",
                        }}
                        onClick={() => handleDeleteDialog(row.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  {["description", "quantity", "rate", "amount"].map(
                    (field) => (
                      <TableCell
                        key={field}
                        style={{
                          padding: "6px 16px",
                        }}
                      >
                        {row[field].split("\n").map((line, i) => (
                          <div key={i}>{line}</div>
                        ))}
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!!rows && rows.length > 0 && (
        <div className={classes.totalAmount}>
          <div
            className="d-flex f-align-center f-justify-between"
            style={{ marginBottom: "8px" }}
          >
            <Typography variant="h6">Sub Total</Typography>
            <Typography variant="h6" className="ml-2">
              {initialTotalAmount || 0}
            </Typography>
          </div>
          <div
            className="d-flex f-align-center f-justify-between"
            style={{ marginBottom: "8px" }}
          >
            <div className="d-flex f-align-center f-justify-between">
              <Typography variant="body1" className=" mb-6">
                Discount by:
              </Typography>
              <ButtonGroup
                size="small"
                aria-label="small outlined button group"
                className=" mb-6 ml-2"
              >
                <Tooltip
                  title={"Calculate discount with percentage"}
                  placement="top"
                >
                  <Button
                    className={
                      isDiscountAmount === false ? classes.selectedButton : ""
                    }
                    onClick={() => handleDiscount(false)}
                  >
                    %
                  </Button>
                </Tooltip>
                <Tooltip
                  title={"Calculate discount with amount"}
                  placement="top"
                >
                  <Button
                    className={
                      isDiscountAmount === true ? classes.selectedButton : ""
                    }
                    onClick={() => handleDiscount(true)}
                  >
                    $
                  </Button>
                </Tooltip>
              </ButtonGroup>
              {!isDiscountAmount && (
                <TextField
                  type="number"
                  size="small"
                  name="discount"
                  variant="outlined"
                  value={discount}
                  error={!!errors.discount?.trim()}
                  onKeyDown={preventInputKeyCodes}
                  onChange={handleFieldChange}
                  disabled={finalize}
                  helperText={errors.discount}
                  style={{ marginLeft: 44, width: 120 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              )}
              {isDiscountAmount && (
                <TextField
                  type="number"
                  size="small"
                  name="discountAmount"
                  variant="outlined"
                  value={discountAmount}
                  error={!!errors.discountAmount?.trim()}
                  onKeyDown={preventInputKeyCodes}
                  onChange={handleFieldChange}
                  disabled={
                    finalize ||
                    initialTotalAmount === "0.00" ||
                    initialTotalAmount === "0"
                  }
                  helperText={errors.discountAmount}
                  style={{ marginLeft: 44, width: 120 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              )}
            </div>
            <Typography variant="body1" className="ml-2 mb-6">
              {discountAmount || 0}
            </Typography>
          </div>
          {/* <div className="d-flex f-align-center f-justify-between">
            <div className="d-flex f-align-center f-justify-between">
              <Typography variant="body1" className=" mb-6">
                Adjustment
              </Typography>
              <TextField
                size="small"
                variant="outlined"
                type="number"
                disabled={isDraft}
                name="adjustment"
                error={!!errors.adjustment?.trim()}
                onKeyDown={(evt) => preventInputKeyCodes(evt, { HYPHEN: true })}
                value={adjustment}
                helperText={errors.adjustment}
                onChange={handleFieldChange}
                style={{ marginLeft: 25, width: 115 }}
              />
            </div>
            <Typography variant="body1" className=" mb-6">
              {adjustment || 0}
            </Typography>
          </div> */}
          <Divider className="mt-4 mb-2" />
          <div className="d-flex f-align-center f-justify-between">
            <Typography variant="h6">Total ( $ )</Typography>
            <Typography
              variant="h6"
              style={{ color: totalAmount < 0 ? "red" : "inherit" }}
            >
              {totalAmount || 0}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvoiceTable;
