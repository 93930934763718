import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  barChartHeadrer: { width: "100%", overflowX: "auto", overflowY: "hidden" },
  xAxisLabel: { wordWrap: "break-word", textAlign: "center" },
  isFetchingData: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 1,
    backdropFilter: "blur(3px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  customTooltip: {
    backgroundColor: "#ffffff",
    border: "1px solid #000000",
    padding: "10px",
    borderRadius: "5px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
}));

export default useStyles;
