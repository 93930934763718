import { useState } from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import { getFileExtension } from "utils";
import SharedService from "../../service";
import {
  FEEDBACK_TYPES,
  MAX_ALLOWED_FILE_SIZE,
  SUPPORTED_FILE_FORMATS,
} from "../../constants";
import isLength from "validator/es/lib/isLength";
import { Dialog } from "shared/components";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import AttachFileIcon from "@material-ui/icons/AttachFile";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckIcon from "@material-ui/icons/Check";
import DescriptionIcon from "@material-ui/icons/Description";
import FeedbackOutlinedIcon from "@material-ui/icons/FeedbackOutlined";
import newOrgLogo from "shared/assets/images/pdx-logo.png";
import OrgLogo from "shared/assets/images/org-logo.png";
import useStyles from "./style";

const defaultState = {
  isOpen: false,
  type: FEEDBACK_TYPES[0],
  title: "",
  description: "",
  isLoading: false,
  attachedFile: null,
  errors: {
    title: "",
    description: "",
  },
};

const Feedback = () => {
  const classes = useStyles();
  const [state, setState] = useState(defaultState);

  const handleFieldChange = ({ name, value }, errorMessage = "") => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
      errors: {
        ...prevState.errors,
        [name]: errorMessage,
      },
    }));
  };

  const showLoader = (show = true) => {
    setState((prevState) => ({
      ...prevState,
      isLoading: show,
    }));
  };

  const handleSubmit = async () => {
    showLoader();
    const formData = new FormData();
    formData.append("type", state.type);
    formData.append("title", state.title);
    formData.append("description", state.description);
    state.attachedFile &&
      formData.append("attachment_path", state.attachedFile.file);
    const { error } = await SharedService.submitFeedback(formData);

    if (error) {
      showLoader(false);
      return toast.error(Array.isArray(error) ? error[0]?.message : error);
    }
    setState((prevState) => ({
      ...prevState,
      type: defaultState.type,
      title: defaultState.title,
      description: defaultState.description,
      attachedFile: defaultState.attachedFile,
    }));
    showLoader(false);
    toast.success("Feedback submitted.");
  };

  const handleClose = () => setState(defaultState);

  const handleFileChange = (evt) => {
    const file = evt.target.files[0];
    const fileExtension = getFileExtension(file.name);
    if (
      !SUPPORTED_FILE_FORMATS.FEEDBACK.includes(fileExtension.toLowerCase())
    ) {
      return toast.error(`${fileExtension} extension not supported.`);
    } else if (file.size > MAX_ALLOWED_FILE_SIZE.FEEDBACK * 1048576) {
      return toast.error(
        `Size of ${file.name} file is more than ${MAX_ALLOWED_FILE_SIZE.FEEDBACK}mb.`
      );
    }

    setState((prevState) => ({
      ...prevState,
      attachedFile: {
        file,
        status: "uploading",
      },
    }));

    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        attachedFile: {
          ...prevState.attachedFile,
          status: "success",
        },
      }));
    }, 1000 * 2);
  };

  // Code for multiple file upload
  // const handleFileChange = evt => {
  // 	const files = evt.target.files;
  // 	const filesList = [];
  // 	for (let i = 0; i < files.length; i++) {
  // 		filesList.push(files.item(i));
  // 	}

  // 	const [
  // 		supportedFiles,
  // 		unSupportedFile,
  // 		duplicateFile,
  // 		sizeExtendedFiles,
  // 	] = filesList.reduce(
  // 		(acc, file) => {
  // 			const fileExtension = getFileExtension(file.name);
  // 			if (
  // 				!SUPPORTED_FILE_FORMATS.FEEDBACK.includes(fileExtension.toLowerCase())
  // 			) {
  // 				acc[1].push(fileExtension);
  // 			} else if (
  // 				state.attachedFiles.find(item => item.file.name === file.name)
  // 			) {
  // 				acc[2].push(file.name);
  // 			} else if (file.size > MAX_ALLOWED_FILE_SIZE.FEEDBACK * 1048576) {
  // 				acc[3].push(file.name);
  // 			} else {
  // 				acc[0].push({
  // 					file: file,
  // 					status: 'uploading',
  // 				});
  // 			}
  // 			return acc;
  // 		},
  // 		[[], [], [], []]
  // 	);

  // 	sizeExtendedFiles.length &&
  // 		toast.error(
  // 			`Size of ${sizeExtendedFiles.join(
  // 				', '
  // 			)} is more than ${MAX_ALLOWED_FILE_SIZE.FEEDBACK}mb.`
  // 		);

  // 	unSupportedFile.length &&
  // 		toast.error(`${unSupportedFile.join(', ')} extension not supported.`);

  // 	duplicateFile.length &&
  // 		toast.error(`${duplicateFile.join(', ')} already uploaded.`);

  // 	supportedFiles.length &&
  // 		setState(prevState => {
  // 			return {
  // 				...prevState,
  // 				attachedFiles: [...supportedFiles, ...prevState.attachedFiles],
  // 			};
  // 		});

  // 	setTimeout(() => {
  // 		setState(prevState => {
  // 			return {
  // 				...prevState,
  // 				isFileUploaded: true,
  // 				attachedFiles: prevState.attachedFiles.map(file => {
  // 					file.status = 'success';
  // 					return file;
  // 				}),
  // 			};
  // 		});
  // 	}, 1000 * 2);
  // };

  return (
    <>
      <MenuItem
        onClick={() =>
          setState((prevState) => ({ ...prevState, isOpen: true }))
        }
      >
        <ListItemIcon className={classes.listIcon}>
          <FeedbackOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Feedback" />
      </MenuItem>
      <Dialog
        classes={{
          paper: classes.dialog,
        }}
        open={state.isOpen}
        onClose={handleClose}
      >
        <Dialog.Title hasClose />
        <Dialog.Content>
          <div className="d-flex flex-column f-justify-between">
            <img
              alt="Organization logo"
              className="ml-6 mb-6"
              src={newOrgLogo}
              height={60}
              width={200}
            />
          </div>
          <Typography className="mb-4 medium-font" variant="h5">
            Give Us Feedback!
          </Typography>
          <Typography variant="body2" className="mb-4 medium-font">
            Your feedback is valuable and we need your insights! Please fill out
            the form below to give us your thoughts or experiences with this
            application!
          </Typography>
          <div>
            <TextField
              select
              label="Type"
              variant="outlined"
              size="small"
              className="w-25"
              name="type"
              value={state.type}
              onChange={(evt) => handleFieldChange(evt.target)}
            >
              {FEEDBACK_TYPES.map((type) => (
                <MenuItem
                  key={type}
                  value={type}
                  selected={type === state.type}
                >
                  {type}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              autoFocus
              required
              fullWidth
              className="mt-4"
              label="Title"
              variant="outlined"
              name="title"
              size="small"
              value={state.title}
              helperText={state.errors.title}
              error={!!state.errors.title.trim()}
              onChange={(evt) => {
                const maxLength = 200;
                const value = evt.currentTarget?.value;
                let errorMessage = value
                  ? isLength(value, { min: 1, max: maxLength })
                    ? ""
                    : `Max: ${maxLength} char(s).`
                  : "Required.";
                handleFieldChange(evt.currentTarget, errorMessage);
              }}
            />
            <TextField
              multiline
              required
              fullWidth
              rows={4}
              className="mt-4"
              label="Description"
              variant="outlined"
              name="description"
              size="small"
              value={state.description}
              helperText={state.errors.description}
              error={!!state.errors.description.trim()}
              onChange={(evt) => {
                const maxLength = 1000;
                const value = evt.currentTarget?.value;
                let errorMessage = value
                  ? isLength(value, { min: 1, max: maxLength })
                    ? ""
                    : `Max: ${maxLength} char(s).`
                  : "Required.";
                handleFieldChange(evt.currentTarget, errorMessage);
              }}
            />
            <input
              // multiple
              type="file"
              name="feedback-attachment"
              id="feedback-attachment"
              className="d-none"
              onChange={handleFileChange}
              onClick={(evt) => (evt.currentTarget.value = "")}
              accept={`.${SUPPORTED_FILE_FORMATS.FEEDBACK.join(",.")}`}
            />
            <Button
              className="mt-4"
              variant="outlined"
              color="primary"
              htmlFor="feedback-attachment"
              component="label"
              startIcon={<AttachFileIcon />}
              disabled={!!state.attachedFile}
            >
              Attach file(s)
            </Button>
            <div className={clsx("mt-4", classes.filesWrapper)}>
              {state.attachedFile && (
                <Paper
                  elevation={4}
                  className={clsx(
                    "d-flex f-align-center f-justify-between p-2 mw-75 mb-4 mt-4 p-relative",
                    classes.paper
                  )}
                >
                  <div className="d-flex f-align-center">
                    <DescriptionIcon color="primary" />
                    <div className={clsx("ml-2", classes.fileProgressWrapper)}>
                      <Tooltip
                        title={state.attachedFile?.file?.name}
                        placement="top-start"
                      >
                        <Typography variant="body2" noWrap>
                          {state.attachedFile?.file?.name}
                        </Typography>
                      </Tooltip>
                      {state.attachedFile?.status === "uploading" && (
                        <LinearProgress variant="indeterminate" />
                      )}
                    </div>
                  </div>
                  {state.attachedFile?.status === "success" && (
                    <CheckIcon color="primary" />
                  )}
                  {state.attachedFile?.status !== "uploading" && (
                    <IconButton
                      className={clsx("p-absolute", classes.removeFileBtn)}
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          attachedFile: null,
                        }))
                      }
                    >
                      <CancelIcon color="primary" />
                    </IconButton>
                  )}
                </Paper>
              )}
              {/* Code for multiple file upload */}
              {/* {state.attachedFiles.map((item, index) => (
								<Paper
									elevation={4}
									className={clsx(
										'd-flex f-align-center f-justify-between p-2 mw-75 mb-4 mt-4 p-relative',
										classes.paper
									)}
								>
									<div className='d-flex f-align-center'>
										<DescriptionIcon color='primary' />
										<div className={clsx('ml-2', classes.fileProgressWrapper)}>
											<Tooltip title={item.file.name} placement='top-start'>
												<Typography variant='body2' noWrap>
													{item.file.name}
												</Typography>
											</Tooltip>
											{item.status === 'uploading' && (
												<LinearProgress variant='indeterminate' />
											)}
										</div>
									</div>
									{item.status === 'success' && <CheckIcon color='primary' />}
									{item.status === 'failed' && (
										<ReportProblemIcon color='error' />
									)}
									{item.status !== 'uploading' && (
										<IconButton
											className={clsx('p-absolute', classes.removeFileBtn)}
											onClick={() => removeFile(index)}
										>
											<CancelIcon color='primary' />
										</IconButton>
									)}
								</Paper>
							))} */}
            </div>
          </div>
        </Dialog.Content>
        <Dialog.Actions>
          <div className="mb-3">
            <Button
              className="text-secondary cancel-btn mr-6"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              className="mr-3"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={
                state.isLoading ||
                Object.values(state.errors).some((err) => err) ||
                !state.title ||
                !state.description
              }
            >
              Submit
              {state.isLoading && (
                <CircularProgress size={24} className={classes.progressBtn} />
              )}
            </Button>
          </div>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default Feedback;
