import { useState } from "react";
import { toast } from "react-toastify";
import Service from "../service";
import { getFileExtension } from "utils";
import { Dialog } from "shared/components";
import { noop } from "shared/constants";
import {
  MAX_ALLOWED_FILE_SIZE,
  SUPPORTED_FILE_FORMATS,
} from "../../shared/constants";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import useStyles from "./style";

const defaulState = {
  file: null,
  isLoading: false,
  openBulkUploadDialog: false,
};

const BulkUpload = ({
  open = false,
  uploadKey = "",
  onClose = noop,
  onSheetError = noop,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(defaulState);

  const handleClose = (isUploaded = false) => {
    setState((prevState) => ({ ...prevState, ...defaulState }));
    onClose(isUploaded);
  };

  const handleFileChange = (evt) => {
    const file = evt.target.files[0];
    const { name, size } = file;
    const fileExtension = getFileExtension(name);

    if (size > MAX_ALLOWED_FILE_SIZE.COMMON * 1048576) {
      return toast.error(
        `Size of "${file.name}" is more than ${MAX_ALLOWED_FILE_SIZE.COMMON} mb.`
      );
    } else if (
      !SUPPORTED_FILE_FORMATS.COMMON.includes(fileExtension.toLowerCase())
    ) {
      return toast.error(
        `Only ${SUPPORTED_FILE_FORMATS.COMMON.join(
          ", "
        )} formats are supported.`
      );
    }
    setState((prevState) => ({ ...prevState, file }));
  };

  const handleBulkUploads = async (file, type, forceUpload = false) => {
    const formData = new FormData();
    formData.append("media", file);
    formData.append("type", type);

    setState((prevState) => ({ ...prevState, isLoading: true }));
    const { error } = await Service.bulkUploads(formData);
    setState((prevState) => ({ ...prevState, isLoading: false }));

    if (error) {
      if (Array.isArray(error)) {
        if (
          Object.values(error)?.every(
            (errors) => errors?.errors[0]?.code === "PDX-CFS-015"
          )
        ) {
          return setState((prevState) => ({
            ...prevState,
            openBulkUploadDialog: true,
            isLoading: false,
          }));
        }

        let errorKams = {};
        let errorMessage = error.reduce((acc, val) => {
          (val?.errors || [])
            .filter((errors) => errors?.code !== "PDX-CFS-015")
            .map((error) => {
              const kamNames = error.kamNameArray || [];
              if (Object.keys(acc).some((key) => key === error.message)) {
                (acc[error.message] || []).push(val.row);
                (errorKams[error.message] || []).push(kamNames);
              } else {
                acc = { ...acc, [error.message]: [val.row] };
                errorKams = { ...errorKams, [error.message]: [kamNames] };
              }
              return errorKams;
            });
          return acc;
        }, []);

        errorMessage = Object.keys(errorMessage).reduce((acc, key) => {
          const kamNames = (errorKams[key] || []).flat().join(", ");
          return {
            ...acc,
            [`${key}${kamNames}`]: errorMessage[key],
          };
        }, {});
        return onSheetError(errorMessage);
      } else {
        return toast.error(error.message);
      }
    } else {
      toast.success("Price Matrix uploaded successfully.");
    }
    handleClose(true);
  };

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.paper,
      }}
    >
      <Dialog.Title hasClose onClose={() => handleClose(false)}>
        {"Upload Data | Price Matrix"}
      </Dialog.Title>
      <Dialog.Content>
        <input
          id="file"
          type="file"
          className="d-none"
          accept={`.${SUPPORTED_FILE_FORMATS.COMMON.join(",.")}`}
          onChange={handleFileChange}
          onClick={(evt) => (evt.currentTarget.value = "")}
        />
        <Button
          disabled={state.isLoading}
          variant="outlined"
          component="label"
          color="primary"
          htmlFor="file"
        >
          Choose File
        </Button>
        {state.file && (
          <div className="d-flex f-align-center mt-4">
            <Tooltip title={state.file?.name} placement="top-start">
              <Typography variant="caption" className="mw-75" noWrap>
                {state.file?.name}
              </Typography>
            </Tooltip>
            {state.isLoading && <LinearProgress className="ml-2 w-25" />}
          </div>
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <div className="p-4">
          <Button
            variant="outlined"
            onClick={() => handleClose(false)}
            className="ml-2 mr-2"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="ml-2 mr-2"
            disabled={state.isLoading || !state.file}
            onClick={() => handleBulkUploads(state.file, uploadKey)}
          >
            Upload
          </Button>
        </div>
      </Dialog.Actions>
    </Dialog>
  );
};

export default BulkUpload;
