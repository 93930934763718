import { useEffect, useRef, useState } from "react";
import { GoogleApiWrapper, InfoWindow, Map, Marker } from "google-maps-react";
import {
  Button,
  CircularProgress,
  DialogContent,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Dialog } from "shared/components";
import { noop, PRIMARY_ADDRESS } from "shared/constants";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useStyles from "./style";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import { toast } from "react-toastify";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tabs from "@material-ui/core/Tabs";
import Paper from "@material-ui/core/Paper";
import { VALIDATIONS } from "shared/constants";
import { validator } from "utils";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Service from "./../service";
import clsx from "clsx";
import BusinessIcon from "@material-ui/icons/Business";
import kamLocation from "./../../../assets/images/kamLocation.png";
import kamLocationPinPoint from "./../../../assets/images/kamLocationPinPoint.png";
import { GpsFixed } from "@material-ui/icons";

const apiKey = "AIzaSyDqd-JnU7PMIEZWnZiXsBHJFVcTelOlAiA";

const defaultState = {
  address_type: null,
  manual_address_type: null,
  showingInfoWindow: false,
  activeMarker: {},
  selectedPlace: {},
  testResults: {},
  selectedTab: 0,
  searchPerformed: false,
  streetAddress: "",
  city: "",
  zipCode: "",
  state: "",
  country: "",
  phone: "",
  phoneNumber: "",
  primaryContact: "",
  contact: "",
  errors: {
    phone: " ",
    phoneNumber: " ",
    primaryContact: " ",
    contact: " ",
    streetAddress: " ",
    city: " ",
    zipCode: " ",
    state: " ",
    country: " ",
  },
};

const KAMLocation = ({
  google,
  allLocation = [],
  getAllLocations = noop,
  isTabletView = false,
  kamID = "",
  onClose = noop,
}) => {
  const searchInput = useRef(null);
  const classes = useStyles();
  const [mapCenter, setMapCenter] = useState(null);
  const [state, setState] = useState({
    ...defaultState,
    // address_type: allLocation.length > 0 ? allLocation[0].type.label : null,
    // manual_address_type:
    //   allLocation.length > 0 ? allLocation[0].type.label : null,
    streetAddress:
      allLocation.length > 0 ? allLocation[0].kamAddress?.street_address : "",
    city: allLocation.length > 0 ? allLocation[0].kamAddress?.city : "",
    state: allLocation.length > 0 ? allLocation[0].kamAddress?.state : "",

    zipCode: allLocation.length > 0 ? allLocation[0].kamAddress?.zip_code : "",
    country: allLocation.length > 0 ? allLocation[0].kamAddress?.country : "",
    phone: allLocation.length > 0 ? allLocation[0].kamAddress?.phone : "",
    phoneNumber: allLocation.length > 0 ? allLocation[0].kamAddress?.phone : "",
    primaryContact:
      allLocation.length > 0 ? allLocation[0].kamAddress?.primary_contact : "",
    contact:
      allLocation.length > 0 ? allLocation[0].kamAddress?.primary_contact : "",
  });
  const streetAddress =
    allLocation.length > 0 ? allLocation[0].kamAddress?.street_address : "";
  const updateState =
    allLocation.length > 0 ? allLocation[0].kamAddress?.state : "";
  const city = allLocation.length > 0 ? allLocation[0].kamAddress?.city : "";
  const zipCode =
    allLocation.length > 0 ? allLocation[0].kamAddress?.zip_code : "";
  const country =
    allLocation.length > 0 ? allLocation[0].kamAddress?.country : "";
  const phoneNumber =
    allLocation.length > 0 ? allLocation[0].kamAddress?.phone : "";
  const phone = allLocation.length > 0 ? allLocation[0].kamAddress?.phone : "";
  const primaryContact =
    allLocation.length > 0 ? allLocation[0].kamAddress?.primary_contact : "";
  const contact =
    allLocation.length > 0 ? allLocation[0].kamAddress?.primary_contact : "";
  // const type = allLocation.length > 0 ? allLocation[0].type.label : null;
  // const addressType = allLocation.length > 0 ? allLocation[0].type.label : null;
  const locations =
    allLocation.length > 0
      ? {
          lat: allLocation[0].kamAddress?.geo_location?.geometry.location.lat,
          lng: allLocation[0].kamAddress?.geo_location?.geometry.location.lng,
        }
      : undefined;

  const onMarkerClick = (props, marker, e) => {
    setState((prevState) => ({
      ...prevState,
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    }));
  };

  const onInfoClose = (props) => {
    if (state.showingInfoWindow) {
      setState((prevState) => ({
        ...prevState,
        showingInfoWindow: false,
        activeMarker: null,
      }));
    }
  };

  const loadAsyncScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = src;
      script.addEventListener("load", () => resolve(script));
      document.head.appendChild(script);
    });
  };
  const initMapScript = () => {
    if (window.google) {
      return Promise.resolve();
    }
    const src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  };

  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      { location: place.geometry.location },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            setMapCenter(
              place.geometry.location.toJSON(),
              (state.showingInfoWindow = false),
              (state.activeMarker = null)
            );
            setState((prevState) => ({
              ...prevState,
              searchPerformed: true,
              testResults: results,
            }));
          }
        }
      }
    );
  };

  const handleClick = (mapProps, map, clickEvent) => {
    if (clickEvent) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode(
        { location: clickEvent.latLng.toJSON() },
        (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              setMapCenter(
                results[0].geometry.location.toJSON(),
                (state.showingInfoWindow = false),
                (state.activeMarker = null)
              );
              setState((prevState) => ({
                ...prevState,
                searchPerformed: false,
                testResults: results,
              }));
              console.log(results[0].formatted_address);
            } else {
              console.log("No results found");
            }
          } else {
            console.log(`Geocoder failed due to: ${status}`);
          }
        }
      );
    }
  };

  const validate = (field, value) => {
    let errorMessage = "";
    const fieldValidatorMap = {
      phone: [
        { type: VALIDATIONS.MIN_LENGTH, value: 10 },
        { type: VALIDATIONS.MAX_LENGTH, value: 30 },
      ],
      phoneNumber: [
        { type: VALIDATIONS.MIN_LENGTH, value: 10 },
        { type: VALIDATIONS.MAX_LENGTH, value: 30 },
      ],
      primaryContact: [{ type: VALIDATIONS.MAX_LENGTH, value: 100 }],
      contact: [{ type: VALIDATIONS.MAX_LENGTH, value: 100 }],
      streetAddress: [{ type: VALIDATIONS.MAX_LENGTH, value: 100 }],
      city: [{ type: VALIDATIONS.MAX_LENGTH, value: 100 }],
      zipCode: [{ type: VALIDATIONS.MAX_LENGTH, value: 10 }],
      state: [{ type: VALIDATIONS.MAX_LENGTH, value: 100 }],
      country: [{ type: VALIDATIONS.MAX_LENGTH, value: 100 }],
    };

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const handleFieldChange = (evt) => {
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    let errorMessage = validate(field, value) || " ";

    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  const initAutocomplete = () => {
    if (!searchInput.current) return;

    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current
    );
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () => {
      onChangeAddress(autocomplete);
    });
  };

  useEffect(() => {
    initMapScript().then(() => initAutocomplete());
  }, []);

  const handleSearch = () => {
    const address = searchInput.current.value;
    if (!address) return;

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status === "OK" && results.length) {
        setMapCenter(
          results[0].geometry.location.toJSON(),
          (state.showingInfoWindow = false),
          (state.activeMarker = null)
        );
        setState((prevState) => ({
          ...prevState,
          searchPerformed: true,
          testResults: results,
        }));
      } else {
        alert("Address not found.");
      }
    });
  };

  const onMapLoad = (map) => {
    map.addListener("click", handleClick);
  };

  const onMapUnmount = (map) => {
    window.google.maps.event.clearInstanceListeners(map);
  };

  const handleUpdatelocation = async () => {
    setState((prevState) => ({
      ...prevState,
      saveUpdateLocation: true,
    }));
    const payload = {
      id: allLocation[0].address_id,
      entity_type: "kamUser",
      entity_id: kamID,
      ...(state.selectedTab === 0
        ? {
            geo_location:
              state.testResults?.length > 0 ||
              state.testResults[0] !== undefined
                ? state.testResults[0]
                : null,
            type:
              typeof state.address_type === "object"
                ? state.address_type
                : allLocation[0].type,
            phone: state.phoneNumber,
            primary_contact: state.primaryContact,
          }
        : {
            street_address: state.streetAddress,
            state: state.state,
            city: state.city,
            zip_code: state.zipCode,
            country: state.country,
            type:
              typeof state.manual_address_type === "object"
                ? state.manual_address_type
                : allLocation[0].type,
            phone: state.phone,
            primary_contact: state.contact,
          }),
    };

    const { error } = await Service.updateLocation(payload);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        saveUpdateLocation: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success(`Address updated successfully.`);
    setState((prevState) => ({
      ...prevState,
      saveUpdateLocation: false,
    }));
    getAllLocations();
    handleClose();
  };

  const handleSaveManually = async () => {
    setState((prevState) => ({
      ...prevState,
      saveLocationManually: true,
    }));
    const payload = {
      entity_type: "kamUser",
      street_address: state.streetAddress,
      state: state.state,
      city: state.city,
      zip_code: state.zipCode,
      primary_contact: state.contact,
      country: state.country,
      phone: state.phone,
      type: state.manual_address_type,
      entity_id: kamID,
    };
    const { error } = await Service.createLocation(payload);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        saveLocationManually: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }
    setState((prevState) => ({
      ...prevState,
      saveLocationManually: false,
    }));
    handleClose(true);
    return toast.success("Address Added Successfully.");
  };

  const handleSaveLocation = async () => {
    setState((prevState) => ({
      ...prevState,
      saveLocation: true,
    }));
    const payload = {
      entity_type: "kamUser",
      type: state.address_type,
      address: state.testResults[0].formatted_address,
      entity_id: kamID,
      geo_location: state.testResults[0],
      phone: state.phoneNumber,
      primary_contact: state.primaryContact,
    };

    const { error } = await Service.createLocation(payload);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        saveLocation: false,
      }));
      return toast.error(
        Array.isArray(error) ? error[0].message : error.message
      );
    }
    setState((prevState) => ({
      ...prevState,
      saveLocation: false,
    }));
    handleClose(true);
    return toast.success("Address Added Successfully.");
  };

  const handleClose = (isSubmitted = false) => {
    onClose(isSubmitted);
    setState((prevState) => ({
      ...prevState,
      streetAddress: "",
      city: "",
      zipCode: "",
      state: "",
      country: "",
      phone: "",
      phoneNumber: "",
      primaryContact: "",
      contact: "",
    }));
  };

  const handleResetAddress = () => {
    setState((prevState) => ({
      ...prevState,
      streetAddress: "",
      city: "",
      zipCode: "",
      state: "",
      country: "",
      phone: "",
      contact: "",
      errors: {
        phone: " ",
        contact: " ",
        streetAddress: " ",
        city: " ",
        zipCode: " ",
        state: " ",
        country: " ",
      },
      manual_address_type: null,
    }));
  };

  const handleTabChange = (evt, value) => {
    setState((prevState) => ({
      ...prevState,
      selectedTab: value,
    }));
  };

  const geocodeJson = `https://maps.googleapis.com/maps/api/geocode/json?key=${apiKey}`;

  const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
    const url = `${geocodeJson}&latlng=${lat},${lng}`;
    fetch(url)
      .then((response) => response.json())
      .then((location) => {
        if (location.status === "OK" && location.results.length) {
          setMapCenter({
            lat: location.results[0].geometry.location.lat,
            lng: location.results[0].geometry.location.lng,
          });

          setState((prevState) => ({
            ...prevState,
            searchPerformed: true,
            testResults: location.results,
          }));
        } else {
          alert("Address not found.");
        }
      })
      .catch((error) => console.error(error));
  };

  const findMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          reverseGeocode({ latitude, longitude });
        },
        (error) => console.error(error),
        { enableHighAccuracy: true }
      );
    }
  };

  return (
    <Dialog
      open={true}
      classes={{
        paper: classes.dialog,
        paperWidthSm:
          state.selectedTab === 0 ? classes.paperWidthSm : classes.paperWidth,
      }}
      onClose={handleClose}
    >
      <Dialog.Title hasClose>
        <Typography variant="h5" color="primary">
          {allLocation.length > 0
            ? "Update Address For KAM"
            : "Add Address For KAM"}
        </Typography>
        <Typography variant="body1">
          {allLocation.length > 0
            ? "Please choose one of the following options to update your address."
            : " Please choose one of the following options to enter your address."}
        </Typography>
      </Dialog.Title>
      <DialogContent>
        <div>
          <Paper className={classes.boxPaperStyle}>
            <Tabs
              value={state.tabIndex}
              onChange={handleTabChange}
              indicatorColor={"#eeeeee"}
              textColor={"primary"}
              className={classes.tabsStyle}
            >
              <Tab
                style={{
                  paddingBottom: 0,
                  borderTop:
                    state.selectedTab === 0 ? "4px solid #80808045" : "none",
                  borderLeft:
                    state.selectedTab === 0 ? "4px solid #80808045" : "none",
                  borderRight:
                    state.selectedTab === 0 ? "4px solid #80808045" : "none",
                }}
                label={
                  <Box
                    elevation={state.selectedTab === 0 ? 8 : 0}
                    className={classes.boxStyle}
                  >
                    <Typography
                      variant="subtitle2"
                      component={"span"}
                      color={state.selectedTab === 0 ? "primary" : "grey.300"}
                    >
                      Locate on Maps
                    </Typography>
                  </Box>
                }
              />
              <Tab
                style={{
                  paddingBottom: 0,
                  borderTop:
                    state.selectedTab !== 0 ? "4px solid #80808045" : "none",
                  borderLeft:
                    state.selectedTab !== 0 ? "4px solid #80808045" : "none",
                  borderRight:
                    state.selectedTab !== 0 ? "4px solid #80808045" : "none",
                }}
                label={
                  <Box
                    elevation={state.selectedTab !== 0 ? 8 : 0}
                    className={classes.boxStyle}
                  >
                    <Typography
                      variant="subtitle2"
                      component={"span"}
                      color={state.selectedTab !== 0 ? "primary" : "grey.300"}
                    >
                      {allLocation.length > 0
                        ? "Update Manually"
                        : "Add Manually"}
                    </Typography>
                  </Box>
                }
              />
            </Tabs>
          </Paper>
        </div>

        {state.selectedTab === 0 ? (
          <div>
            <Paper className={classes.paperStyle}>
              {!isTabletView ? (
                <div className="d-flex flex-wrap f-align-start f-justify-between">
                  <div className="d-flex flex-wrap">
                    <TextField
                      inputRef={searchInput}
                      label="Search Location..."
                      variant="outlined"
                      className={classes.searchField}
                      size="small"
                      classes={{
                        root: classes.autocompleteWrapper,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment className={classes.adornmentClass}>
                            <Button
                              className={clsx(
                                "mb-2 mt-2",
                                classes.searchButton
                              )}
                              variant="contained"
                              onClick={handleSearch}
                              style={{ backgroundColor: "#1976d2" }}
                            >
                              <Tooltip
                                title={"Search Location"}
                                placement="top-start"
                              >
                                <SearchIcon className={classes.searchIcon} />
                              </Tooltip>
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                    />

                    {/* <Autocomplete
                      size="small"
                      className={classes.field}
                      disableClearable
                      value={state.address_type}
                      options={PRIMARY_ADDRESS}
                      getOptionLabel={(option) => option.label || option}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Type Of Address"
                          variant="outlined"
                        />
                      )}
                      onChange={(evt, value) =>
                        setState((prevState) => ({
                          ...prevState,
                          address_type: value,
                        }))
                      }
                    /> */}
                    <TextField
                      className={classes.field}
                      name="phoneNumber"
                      label="Phone"
                      variant="outlined"
                      size="small"
                      value={state.phoneNumber}
                      error={!!state.errors.phoneNumber?.trim()}
                      helperText={state.errors.phoneNumber}
                      onChange={handleFieldChange}
                    />
                    <TextField
                      className={classes.field}
                      name="primaryContact"
                      label="Primary Contact"
                      variant="outlined"
                      size="small"
                      value={state.primaryContact}
                      error={!!state.errors.primaryContact?.trim()}
                      helperText={state.errors.primaryContact}
                      onChange={handleFieldChange}
                    />
                    <Button
                      className={classes.saveButton}
                      variant="contained"
                      color="primary"
                      onClick={
                        allLocation.length > 0
                          ? handleUpdatelocation
                          : handleSaveLocation
                      }
                      disabled={
                        (locations &&
                          state.phoneNumber === phoneNumber &&
                          state.primaryContact === primaryContact &&
                          !mapCenter) ||
                        (allLocation.length === 0 && !mapCenter) ||
                        state.saveLocation ||
                        state.saveUpdateLocation ||
                        state.errors.phoneNumber !== " " ||
                        state.errors.primaryContact !== " "
                      }
                    >
                      {allLocation.length > 0
                        ? "Update Address"
                        : "Save Address"}
                      {(state.saveLocation || state.saveUpdateLocation) && (
                        <CircularProgress
                          size={24}
                          className="p-absolute progress-btn"
                        />
                      )}
                    </Button>
                  </div>
                  <div>
                    <Tooltip
                      title={"Search Current Location"}
                      placement="top-end"
                    >
                      <IconButton
                        className={clsx("c-pointer", classes.GpsFixedIconColor)}
                        onClick={findMyLocation}
                        style={{ backgroundColor: "#1976d2" }}
                      >
                        <GpsFixed />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              ) : (
                <>
                  <div className="d-flex f-align-center mt-4">
                    <TextField
                      inputRef={searchInput}
                      label="Search Location..."
                      variant="outlined"
                      className="mr-4"
                      //className={classes.searchFields}
                      size="small"
                      classes={{
                        root: classes.autocompleteWrapper,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment className={classes.adornmentClass}>
                            <Button
                              className={clsx(
                                "mb-2 mt-2",
                                classes.searchButton
                              )}
                              variant="contained"
                              onClick={handleSearch}
                              style={{ backgroundColor: "#1976d2" }}
                            >
                              <Tooltip
                                title={"Search Location"}
                                placement="top-start"
                              >
                                <SearchIcon className={classes.searchIcon} />
                              </Tooltip>
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      // className={classes.fields}
                      name="phoneNumber"
                      label="Phone"
                      variant="outlined"
                      className="mt-5"
                      size="small"
                      value={state.phoneNumber}
                      error={!!state.errors.phoneNumber?.trim()}
                      helperText={state.errors.phoneNumber}
                      onChange={handleFieldChange}
                    />
                  </div>
                  <div className="d-flex f-align-center">
                    <TextField
                      className={clsx("mr-4", classes.fields)}
                      name="primaryContact"
                      label="Primary Contact"
                      variant="outlined"
                      size="small"
                      value={state.primaryContact}
                      error={!!state.errors.primaryContact?.trim()}
                      helperText={state.errors.primaryContact}
                      onChange={handleFieldChange}
                    />
                    <Button
                      //className={classes.saveButtons}
                      className="mb-6 mr-4"
                      variant="contained"
                      color="primary"
                      onClick={
                        allLocation.length > 0
                          ? handleUpdatelocation
                          : handleSaveLocation
                      }
                      disabled={
                        (locations &&
                          state.phoneNumber === phoneNumber &&
                          state.primaryContact === primaryContact &&
                          !mapCenter) ||
                        (allLocation.length === 0 && !mapCenter) ||
                        state.saveLocation ||
                        state.saveUpdateLocation ||
                        state.errors.phoneNumber !== " " ||
                        state.errors.primaryContact !== " "
                      }
                    >
                      {allLocation.length > 0
                        ? "Update Address"
                        : "Save Address"}
                      {(state.saveLocation || state.saveUpdateLocation) && (
                        <CircularProgress
                          size={24}
                          className="p-absolute progress-btn"
                        />
                      )}
                    </Button>
                    <Tooltip
                      title={"Search Current Location"}
                      placement="top-end"
                    >
                      <IconButton
                        className={clsx(
                          "c-pointer mb-6",
                          classes.GpsFixedIconColors
                        )}
                        //className={clsx("c-pointer", classes.GpsFixedIconColors)}
                        onClick={findMyLocation}
                        style={{ backgroundColor: "#1976d2" }}
                      >
                        <GpsFixed />
                      </IconButton>
                    </Tooltip>
                  </div>
                </>
              )}

              <div className={!isTabletView ? classes.map : classes.mapTablet}>
                <Map
                  className={classes.maps}
                  google={google}
                  zoom={allLocation.length > 0 ? 14 : 5}
                  center={state.searchPerformed ? mapCenter : undefined}
                  onReady={(mapProps, map) => onMapLoad(map)}
                  onClick={(mapProps, map, clickEvent) =>
                    handleClick(mapProps, map, clickEvent)
                  }
                  onUnmount={onMapUnmount}
                  initialCenter={
                    allLocation.length > 0
                      ? {
                          lat: allLocation[0].kamAddress?.geo_location?.geometry
                            .location.lat,
                          lng: allLocation[0].kamAddress?.geo_location?.geometry
                            .location.lng,
                        }
                      : { lat: 39.3455632, lng: -91.2947461 }
                  }
                >
                  {!mapCenter && allLocation.length > 0 && (
                    <Marker
                      title="Add This Address"
                      address={[
                        allLocation[0].kamAddress?.street_address,
                        allLocation[0].kamAddress?.city,
                        allLocation[0].kamAddress?.state,
                        allLocation[0].kamAddress?.zip_code,
                        allLocation[0].kamAddress?.country,
                      ].join(", ")}
                      location={allLocation[0].kamAddress?.address}
                      onClick={onMarkerClick}
                      position={{
                        lat: allLocation[0].kamAddress?.geo_location?.geometry
                          .location.lat,
                        lng: allLocation[0].kamAddress?.geo_location?.geometry
                          .location.lng,
                      }}
                      options={{
                        icon:
                          state.activeMarker &&
                          state.activeMarker?.location ===
                            allLocation[0].kamAddress?.address
                            ? {
                                url: kamLocationPinPoint,
                                scaledSize: new window.google.maps.Size(50, 50),
                              }
                            : {
                                url: kamLocation,
                                scaledSize: new window.google.maps.Size(50, 50),
                              },
                      }}
                    />
                  )}
                  {mapCenter && (
                    <Marker
                      title="Add This Address"
                      position={mapCenter}
                      address={state.testResults[0]?.formatted_address}
                      onClick={onMarkerClick}
                      options={{
                        icon: {
                          url: kamLocationPinPoint,
                          scaledSize: new window.google.maps.Size(50, 50),
                        },
                      }}
                    />
                  )}
                  <InfoWindow
                    marker={state.activeMarker}
                    visible={state.showingInfoWindow}
                    onClose={onInfoClose}
                  >
                    <div className="d-flex flex-wrap">
                      <BusinessIcon className="mt-3 mr-2" />
                      <h4>{state.selectedPlace?.address}</h4>
                    </div>
                  </InfoWindow>
                </Map>
              </div>
            </Paper>
          </div>
        ) : (
          <div>
            <div className={classes.root}>
              <div className="d-flex f-align-center mt-4">
                <TextField
                  required
                  className={classes.StreetAddress}
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="streetAddress"
                  label="Street Address"
                  value={state.streetAddress}
                  error={!!state.errors.streetAddress?.trim()}
                  helperText={state.errors.streetAddress}
                  onChange={handleFieldChange}
                />
                <TextField
                  required
                  fullWidth
                  className={classes.city}
                  size="small"
                  variant="outlined"
                  label="City"
                  name="city"
                  value={state.city}
                  error={!!state.errors.city?.trim()}
                  helperText={state.errors.city}
                  onChange={handleFieldChange}
                />
              </div>
              <div className="d-flex f-align-center">
                <TextField
                  required
                  fullWidth
                  className={classes.state}
                  size="small"
                  variant="outlined"
                  label="State"
                  name="state"
                  value={state.state}
                  error={!!state.errors.state?.trim()}
                  helperText={state.errors.state}
                  onChange={handleFieldChange}
                />
                <TextField
                  required
                  fullWidth
                  className={classes.zip}
                  size="small"
                  variant="outlined"
                  label="ZIP Code"
                  name="zipCode"
                  value={state.zipCode}
                  error={!!state.errors.zipCode?.trim()}
                  helperText={state.errors.zipCode}
                  onChange={handleFieldChange}
                />
              </div>
              <div className="d-flex f-align-center">
                <TextField
                  required
                  fullWidth
                  className={classes.country}
                  name="country"
                  size="small"
                  variant="outlined"
                  label="Country"
                  value={state.country}
                  error={!!state.errors.country?.trim()}
                  helperText={state.errors.country}
                  onChange={handleFieldChange}
                />
                <TextField
                  className={classes.phoneNumber}
                  name="phone"
                  label="Phone"
                  variant="outlined"
                  size="small"
                  value={state.phone}
                  error={!!state.errors.phone?.trim()}
                  helperText={state.errors.phone}
                  onChange={handleFieldChange}
                />
                {/* <Autocomplete
                  size="small"
                  className={classes.manualField}
                  disableClearable
                  options={PRIMARY_ADDRESS}
                  getOptionLabel={(option) => option.label || option}
                  value={state.manual_address_type}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.addressType}
                      required
                      label="Type Of Address"
                      variant="outlined"
                    />
                  )}
                  onChange={(evt, value) =>
                    setState((prevState) => ({
                      ...prevState,
                      manual_address_type: value,
                    }))
                  }
                /> */}
              </div>
              <div className="d-flex f-align-center">
                <TextField
                  className={classes.contact}
                  name="contact"
                  label="Primary Contact"
                  variant="outlined"
                  size="small"
                  value={state.contact}
                  error={!!state.errors.contact?.trim()}
                  helperText={state.errors.contact}
                  onChange={handleFieldChange}
                />
              </div>
              <div className="mb-3 d-flex flex-wrap" style={{ float: "right" }}>
                <Button
                  className="ml-4 mr-4 mt-4"
                  variant="contained"
                  color="primary"
                  onClick={handleResetAddress}
                  disabled={
                    !state.country &&
                    !state.state &&
                    !state.zipCode &&
                    !state.city &&
                    !state.streetAddress &&
                    !state.manual_address_type &&
                    !state.phone &&
                    !state.contact
                  }
                >
                  Reset
                </Button>
                <Button
                  className="mt-4"
                  variant="contained"
                  color="primary"
                  onClick={
                    allLocation.length > 0
                      ? handleUpdatelocation
                      : handleSaveManually
                  }
                  disabled={
                    (state.country === country &&
                      state.state === updateState &&
                      state.zipCode === zipCode &&
                      state.phone === phone &&
                      state.contact === contact &&
                      state.city === city &&
                      state.streetAddress === streetAddress) ||
                    !state.country ||
                    !state.state ||
                    !state.zipCode ||
                    !state.city ||
                    !state.streetAddress ||
                    state.saveLocationManually ||
                    state.saveUpdateLocation ||
                    state.errors.phone !== " " ||
                    state.errors.contact !== " " ||
                    state.errors.state !== " " ||
                    state.errors.city !== " " ||
                    state.errors.zipCode !== " " ||
                    state.errors.country !== " " ||
                    state.errors.streetAddress !== " "
                  }
                >
                  {allLocation.length > 0 ? "Update Address" : "Save Address"}
                  {(state.saveLocationManually || state.saveUpdateLocation) && (
                    <CircularProgress
                      size={24}
                      className="p-absolute progress-btn"
                    />
                  )}
                </Button>
              </div>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default GoogleApiWrapper({
  apiKey,
  libraries: ["places"],
})(KAMLocation);
