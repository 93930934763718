import { Switch, Route } from "react-router-dom";
import CustomFuelSurcharge from "./custom-fuel-surcharge";
import View from "./view";

const FuelSurchargeModule = ({ match }) => {
  return (
    <Switch>
      <Route exact path={match.path} component={View} />
      <Route
        exact
        path={`${match.path}/schedule/:schedule?`}
        component={CustomFuelSurcharge}
      />
    </Switch>
  );
};

export default FuelSurchargeModule;
