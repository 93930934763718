import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";

const NotificationLoader = () => {
  return [...new Array(10)].map((item, index) => (
    <Box key={index}>
      <Box className="d-flex f-justify-between pl-2 pr-4 pt-8 pb-8">
        <Box className="d-flex f-align-center">
          <Skeleton variant="rect" width={40} height={40} className="ml-4" />
          <Box className="ml-4">
            <Typography variant="body1">
              <Skeleton width={200} />
            </Typography>
            <Typography variant="body2">
              <Skeleton width={400} />
            </Typography>
          </Box>
        </Box>
        <Box className="d-flex f-align-center">
          <Typography variant="body2">
            <Skeleton width={24} />
          </Typography>
        </Box>
      </Box>
      <Divider />
    </Box>
  ));
};

export default NotificationLoader;
