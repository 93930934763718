import { http, responseFormatter } from "utils";

const get = (query) => {
  return responseFormatter(
    http.get(`/price-matrix/${query || ""}`, {
      setAuthHeader: true,
    })
  );
};

const getPriceMatrixNames = (customerBranchId) => {
  return responseFormatter(
    http.get(`/price-matrix/name/${customerBranchId}`,{
      setAuthHeader: true,
    })
  );
};

const post = (data) => {
  return responseFormatter(
    http.post("/price-matrix", data, {
      setAuthHeader: true,
    })
  );
};

const put = (data) => {
  return responseFormatter(
    http.put("/price-matrix", data, {
      setAuthHeader: true,
    })
  );
};

const deleteEntry = (entryIds) => {
  return responseFormatter(
    http.delete(`/price-matrix?recordIds=${entryIds}`, {
      setAuthHeader: true,
    })
  );
};

const download = (type, queryString = "") => {
  return responseFormatter(
    http.get(`/download/priceMatrix/${type}${queryString}`, {
      setAuthHeader: true,
    })
  );
};

const bulkUploads = (data) => {
  return responseFormatter(
    http.post("/bulk-upload", data, {
      setAuthHeader: true,
    })
  );
};

const FuelSurchargeService = {
  get,
  post,
  put,
  deleteEntry,
  download,
  bulkUploads,
  getPriceMatrixNames
};

export default FuelSurchargeService;
