/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
  Chip,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Accordion,
  AccordionSummary,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";

import useStyles from "./style";
import {
  ActionDialog,
  Datepicker,
  Dropdown,
  FilterComponent,
  Grid,
} from "shared/components";
import clsx from "clsx";
import { toast } from "react-toastify";
import Service from "../service";
import { parseISO, differenceInSeconds } from "date-fns";
import {
  getDateString,
  getPageConfig,
  getUTCDateString,
  queryStringBuilderNew,
  setPageConfig,
  updateLayout,
  updatePagination,
  validator,
} from "utils";
import { AppContext, PAGE_KEYS, VALIDATIONS, noop } from "shared";
import TuneIcon from "@material-ui/icons/Tune";
import { SharedService } from "modules/shared";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import InvoiceSummary from "./invoice-summary";
import GenerateInvoicePDF from "../generateInvoicePdf";
import { INVOICE_STATUS } from "modules/shared/constants";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import GetAppIcon from "@material-ui/icons/GetApp";
import { PDFDocument } from "pdf-lib";
import SendIcon from "@material-ui/icons/Send";
import MailIcon from "@material-ui/icons/Mail";
import AddIcon from "@material-ui/icons/Add";
import PostAddIcon from "@material-ui/icons/PostAdd";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CancelIcon from "@material-ui/icons/Cancel";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

let gridHelper = null,
  timeout = null;
let isConfirmDisabled = false;

const defaultState = {
  entries: [],
  scheduleDate: null,
  deletingEntryIds: null,
  entry: {},
  isInvoiceLoading: false,
  pdfUrl: "",
  pdxCompanyList: [],
  customerlocationList: [],
  customer: null,
  isOverdue: false,
  selectedInvoice: null,
  pdxCompany: null,
  route: null,
  viewInvoiceSummary: false,
  isMarkAsSentLoading: false,
  selectedLocation: null,
  invoiceDate: null,
  invoiceSentDate: null,
  search: "",
  pageFilters: [],
  isInvoiceDetailsLoading: false,
  isPdfPreviewLoading: false,
  isPdfDownloadLoading: false,
  isInvoiceSent: false,
  email: "",
  from: "",
  emailSubject: "Invoices from Parts Distribution Xpress (PDX)",
  emailBody:
    "Dear Customers,\n\nYour invoice is attached. Please remit payment at your earliest convenience. Thank you for your business - we appreciate it very much.\n\nSincerely,\nParts Distribution Xpress",
  temporaryEmail: [],
  permanentEmail: [],
  showAddPermanentEmailDialog: false,
  isPermEmailLoading: false,
  errors: {
    email: " ",
    from: " ",
    emailSubject: " ",
    emailBody: " ",
  },
  showSendInvoicePopup: false,
  isInvoiceSending: false,
  emailLoading: false,
  filters: {
    customer: [],
    isOverdue: [],
    invoiceStatus: [],
    pdxCompany: [],
    route: [],
    selectedLocation: [],
    invoiceFilter: [],
    invoiceDate: [],
    invoiceSentDate: [],
  },
  dynamicStatus: {
    all: { label: "All", value: "all", isSelected: true },
    draft: {
      label: "Draft",
      value: "draft",
      isSelected: true,
    },
    approved: {
      label: "Approved",
      value: "approved",
      isSelected: true,
    },
    pending_approval: {
      label: "Pending Approval",
      value: "pending-approval",
      isSelected: true,
    },
    paid: {
      label: "Paid",
      value: "paid",
      isSelected: true,
    },
    partially_paid: {
      label: "Partially Paid",
      value: "partially-paid",
      isSelected: true,
    },
  },
  totalEntries: 0,
  pageSize: 100,
  pageNumber: 1,
  order: null,
  orderBy: null,
  selectedRows: [],
  isLoading: false,
  isInvoiceDeleteLoading: false,
  isFetchingList: {
    customers: false,
    pdxCompany: false,
    locations: false,
    route: false,
  },
  dynamicColumns: {
    select_all: { label: "Select All", value: "select_all", isSelected: true },
    serial_no: { label: "Invoice No.", value: "serial_no", isSelected: true },
    status: { label: "Status", value: "status", isSelected: true },
    invoice_date: {
      label: "Invoice Date",
      value: "invoice_date",
      isSelected: true,
    },
    initial_sent_date: {
      label: "Invoice Sent Date",
      value: "initial_sent_date",
      isSelected: true,
    },
    pdxCompany: { label: "PDX Company", value: "pdxCompany", isSelected: true },
    customer: { label: "Customer", value: "customer", isSelected: true },
    location: { label: "Location", value: "location", isSelected: true },
    route: { label: "Route", value: "route", isSelected: true },
    terms: {
      label: "Net Terms Days",
      value: "terms",
      isSelected: true,
    },
    total_amount: {
      label: "Total Amount",
      value: "total_amount",
      isSelected: true,
    },
    discount_amt: {
      label: "Discount Amount",
      value: "discount_amt",
      isSelected: true,
    },
    total_amount_finalized: {
      label: "Finalized Amount",
      value: "total_amount_finalized",
      isSelected: true,
    },
    balance_amt: {
      label: "Balance Amount",
      value: "balance_amt",
      isSelected: true,
    },
  },
  isApprovalLoading: false,
  isApprovedDialog: false,
  approvalType: "",
  invoiceLoading: false,
  isPulsingEffect: false,
};

const ViewInvoice = ({ history, location }) => {
  const classes = useStyles();
  const { appData, updateContextData } = useContext(AppContext);
  const data = location.state || {};
  const pageConfig = getPageConfig(PAGE_KEYS.VIEW_INVOICE);
  const [state, setState] = useState({
    ...defaultState,
    dynamicColumns: pageConfig?.dynamicColumns || defaultState.dynamicColumns,
    viewInvoiceSummary:
      data?.viewInvoiceSummary || defaultState.viewInvoiceSummary,
    selectedInvoice: data?.selectedInvoice || defaultState.selectedInvoice,
    isPulsingEffect: data?.pulsingEffect || defaultState.isPulsingEffect,
  });

  const appDataList = useMemo(() => {
    const users = appData.users;
    const customers = appData.customers.filter(
      (customer) => customer.is_active
    );

    return {
      customers,
      users,
    };
  }, [appData]);

  const fetchList = async (
    listType = "customers",
    callback = noop,
    ...params
  ) => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: !appData[listType]?.length,
      },
    }));

    let serviceMethod, responseKey;

    // eslint-disable-next-line default-case
    switch (listType) {
      case "customers":
        serviceMethod = "getCustomerList";
        responseKey = "customers";
        break;
      case "users":
        serviceMethod = "getUsersList";
        responseKey = "users";
        break;
    }

    const { data, error } = await SharedService[serviceMethod](...params);

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          [listType]: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    const listData = data[responseKey] || [];
    if (listType === "customers") {
      listData.map((item = {}) => {
        (item.customer_branches || []).map((branch = {}) => {
          (branch.routes || []).sort((a, b) =>
            Intl.Collator().compare(a.name, b.name)
          );
        });
      });
    }
    updateContextData(listType, listData);

    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        [listType]: false,
      },
    }));
    callback(data[responseKey]);
  };

  const fetchCustomerLocation = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        locations: true,
      },
    }));
    const { data, error } = await Service.getCustomerLocation();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          locations: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      customerlocationList:
        data?.locations || defaultState.customerlocationList,
      isFetchingList: {
        ...prevState.isFetchingList,
        locations: false,
      },
    }));
  };

  const fetchPDXCompanyList = async () => {
    setState((prevState) => ({
      ...prevState,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: true,
      },
    }));

    const { data, error } = await SharedService.getPDXCompanyList();

    if (error) {
      setState((prevState) => ({
        ...prevState,
        isFetchingList: {
          ...prevState.isFetchingList,
          pdxCompany: false,
        },
      }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }

    setState((prevState) => ({
      ...prevState,
      pdxCompanyList: data?.rows || defaultState.pdxCompanyList,
      isFetchingList: {
        ...prevState.isFetchingList,
        pdxCompany: false,
      },
    }));
  };

  const getCustomerList = useMemo(() => {
    return appDataList.customers.filter((customer) =>
      customer.customer_branches.some(
        (branch) =>
          branch.pdx_company_id === state.pdxCompany?.id &&
          branch.is_active &&
          !branch.is_deleted
      )
    );
  }, [state.pdxCompany]);

  const getLocationList = Object.entries(state.customerlocationList)
    .map(([stateName, locations]) => {
      const filteredLocations = !state.customer
        ? locations
        : locations.filter((location) =>
            state.customer?.customer_branches?.some(
              (branch) => branch.location === location
            )
          );
      return filteredLocations.map((location) => ({
        label: `${location}-(${stateName})`,
        value: location,
      }));
    })
    .flat()
    .sort((a, b) => Intl.Collator().compare(a.label, b.label));

  const getRouteList = useMemo(() => {
    return (
      state.customer && state.selectedLocation
        ? state.customer?.customer_branches?.filter(
            (item) => state.selectedLocation?.value === item.location
          )
        : appDataList.customers
            ?.filter((customer) => customer.is_active)
            ?.map((item) => item.customer_branches)
            .flat()
    )
      ?.map((item) => item.routes)
      .flat()
      ?.map((route) => ({ id: route.id, name: route.name }))
      ?.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
      );
  }, [appDataList.customers, state.customer, state.selectedLocation]);

  const handleTitleClick = (row) => {
    history.push({
      pathname: "/invoice/draft_invoice",
      state: {
        invoiceId: row?.id,
        customerList: appDataList.customers,
      },
    });
  };

  useEffect(() => {
    if (!!data.selectedInvoice && Object.keys(data.selectedInvoice).length > 0)
      getInvoice(state.selectedInvoice, true);
  }, [data.selectedInvoice]);

  const getInvoice = async (row, preview) => {
    setState((prevState) => ({ ...prevState, isInvoiceLoading: true }));

    let queryString = `?invoiceIds=${row?.id}`;

    const { data, error } = await Service.getAllInvoiceDetails(queryString);

    const invoiceEntries = data?.invoiceDataArray?.[0];

    if (error) {
      setState((prevState) => ({ ...prevState, isInvoiceLoading: false }));
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      return null;
    }

    const blob = GenerateInvoicePDF({
      invoiceDetails: invoiceEntries?.lineItems,
      invoiceNo: invoiceEntries.invoiceDetails.serial_no,
      terms: invoiceEntries.invoiceDetails.terms,
      termsAndCondition: invoiceEntries.invoiceDetails.terms_and_condition,
      invoiceDate: invoiceEntries.invoiceDetails.invoice_date,
      customerNotes: invoiceEntries.invoiceDetails.customer_notes,
      invoiceStatus: invoiceEntries.invoiceDetails.status,
      discount: invoiceEntries.invoiceDetails.discount_amt,
      pdxCompany:
        invoiceEntries.invoiceDetails.customer_branch?.pdxCompany?.value,
      balanceAmount: invoiceEntries?.invoiceDetails?.balance_amt,
      paidAmount: invoiceEntries?.invoiceDetails?.paid_amt,
      isOverdue: invoiceEntries?.invoiceDetails?.is_overdue,
      completePaymentDoneOn:
        invoiceEntries?.invoiceDetails?.complete_payment_done_on,
      billTo: {
        customerName: invoiceEntries.invoiceDetails.bill_to?.customerName,
        branch: invoiceEntries.invoiceDetails.bill_to?.branch,
        streetAddress: invoiceEntries.invoiceDetails.bill_to?.street_address,
        state: invoiceEntries.invoiceDetails.bill_to?.state,
        city: invoiceEntries.invoiceDetails.bill_to?.city,
        country: invoiceEntries.invoiceDetails.bill_to?.country,
        zipCode: invoiceEntries.invoiceDetails.bill_to?.zip_code,
        phone: invoiceEntries.invoiceDetails.bill_to?.phone,
        contact: invoiceEntries.invoiceDetails.bill_to?.contact,
      },
      isPreview: preview,
    });

    setState((prevState) => ({
      ...prevState,
      isInvoiceLoading: false,
      pdfUrl: blob,
      entry: invoiceEntries || defaultState.entry,
    }));
    history.replace({ ...history.location, state: {} });
    return invoiceEntries;
  };

  const fetchInvoice = async (row, loading) => {
    setState((prevState) => ({ ...prevState, [loading]: true }));

    let queryString = `?invoiceIds=${row?.id}`;

    const { data, error } = await Service.getAllInvoiceDetails(queryString);

    const invoiceEntries = data?.invoiceDataArray?.[0];

    if (error) {
      setState((prevState) => ({ ...prevState, [loading]: false }));
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
      return null;
    }

    fetchEntries(
      state.search,
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    )
      .then(() => {
        setState((prevState) => ({
          ...prevState,
          [loading]: false,
          entry: invoiceEntries || defaultState.entry,
        }));
      })
      .catch(() => {
        setState((prevState) => ({
          ...prevState,
          [loading]: false,
        }));
      });
  };

  const handleMarkInvoiceSent = async (id, isSingleInvoice = false) => {
    setState((prevState) => ({ ...prevState, isMarkAsSentLoading: true }));

    const idArray = [];
    if (Array.isArray(id)) {
      idArray.push(...id);
    } else {
      idArray.push(id);
    }

    const { error } = await Service.markInvoiceSent({ invoiceIds: idArray });

    if (error) {
      setState((prevState) => ({ ...prevState, isMarkAsSentLoading: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success("Invoice(s) marked as sent successfully");
    setState((prevState) => ({
      ...prevState,
      isMarkAsSentLoading: false,
      isInvoiceSent: false,
    }));
    fetchEntries(
      state.search,
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    );
    if (isSingleInvoice) {
      getInvoice({ id: id || isSingleInvoice }, true);
    }
  };

  const handleMarkInvoiceSentDialog = () =>
    setState((prevState) => ({
      ...prevState,
      isInvoiceSent: true,
    }));

  const getSearchArray = (search) => {
    const columns = ["serial_no"];
    return columns.map((column) => ({
      field: column,
      value: search,
    }));
  };

  const fetchEntries = useCallback(
    async (
      search,
      filters,
      pageSize,
      pageNumber,
      order,
      orderBy,
      pageFilters = []
    ) => {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const searchArray = getSearchArray(search);
      const sortObj = { field: orderBy, order };

      let filtersArr = [
        ...pageFilters.map((filter) => ({
          ...filter,
          value: filter?.value?.value || filter?.value,
        })),
      ];

      if (filters.customer.length && filters.customer[0].value) {
        filtersArr = [...filtersArr, ...filters.customer];
      }

      if (filters.pdxCompany.length && filters.pdxCompany[0].value) {
        filtersArr = [...filtersArr, ...filters.pdxCompany];
      }
      if (filters.invoiceStatus.length && filters.invoiceStatus) {
        filtersArr = [...filtersArr, ...filters.invoiceStatus];
      }
      if (filters.isOverdue.length && filters.isOverdue) {
        filtersArr = [...filtersArr, ...filters.isOverdue];
      }
      if (
        filters.selectedLocation.length &&
        filters.selectedLocation[0].value
      ) {
        filtersArr = [...filtersArr, ...filters.selectedLocation];
      }
      if (filters.invoiceDate.length && filters.invoiceDate[0].value) {
        filtersArr = [...filtersArr, ...filters.invoiceDate];
      }
      if (filters.invoiceSentDate.length && filters.invoiceSentDate[0].value) {
        filtersArr = [...filtersArr, ...filters.invoiceSentDate];
      }
      if (filters.invoiceFilter.length && filters.invoiceFilter[0].value) {
        filtersArr = [...filtersArr, ...filters.invoiceFilter];
      }

      let queryString = queryStringBuilderNew(
        pageSize,
        pageSize * (pageNumber - 1),
        searchArray,
        filtersArr,
        sortObj,
        search && search.length > 0
      );
      if (filters.route.length && filters.route[0]) {
        let routeArray = [];
        routeArray.push(filters.route);
        queryString += `&filter[where][and][0][or][0][route_ids][contains]=${JSON.stringify(
          filters.route
        )}`;
      }
      const { data, error } = await Service.getAllInvoice(queryString);

      if (error) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          selectedRows: defaultState.selectedRows,
        }));
        gridHelper && gridHelper.resetSelection();
        return toast.error(
          Array.isArray(error) ? error[0]?.message : error.message
        );
      }

      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        entries: data.rows || defaultState.entries,
        selectedRows: defaultState.selectedRows,
        totalEntries: data.count,
      }));
      gridHelper && gridHelper.resetSelection();
      history.replace({ ...history.location, state: {} });
      return data;
    },
    []
  );

  const handleGetInvoice = useCallback(
    (search, filters, pageSize, pageNumber, order, orderBy, pageFilters) => {
      const pollDraftInvoice = async () => {
        try {
          const data = await fetchEntries(
            search,
            filters,
            pageSize,
            pageNumber,
            order,
            orderBy,
            pageFilters
          );

          if (
            data.rows?.invoice_generation_status === "COMPLETED" ||
            data.rows?.invoice_generation_status === "ERROR" ||
            data.rows?.invoice_generation_status === undefined ||
            data.rows?.invoice_generation_status === null
          ) {
            clearInterval(intervalId);
          }
        } catch (error) {
          clearInterval(intervalId);
        }
      };

      const intervalId = setInterval(pollDraftInvoice, 1000);

      pollDraftInvoice();

      return () => clearInterval(intervalId);
    },
    []
  );

  useEffect(() => {
    handleGetInvoice(
      state.search,
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    );
  }, []);

  const fetchAllInvoiceEntries = useCallback(
    async (selectedIds = [], loading) => {
      if (!selectedIds.length) {
        return;
      }

      setState((prevState) => ({
        ...prevState,
        [loading]: true,
      }));

      const idString = selectedIds.join(",");
      let queryString = selectedIds.length ? `?invoiceIds=${idString}` : "";
      const { data, error } = await Service.getAllInvoiceDetails(queryString);

      if (error) {
        setState((prevState) => ({
          ...prevState,
          [loading]: false,
        }));
        return toast.error(
          Array.isArray(error) ? error[0]?.message : error.message
        );
      }

      setState((prevState) => ({
        ...prevState,
        [loading]: false,
      }));
      return data?.invoiceDataArray;
    },
    [state.selectedRows]
  );

  const filterConfig = useMemo(
    () => [
      {
        field: "terms",
        fieldToDisplay: "Terms",
        operatorType: "number",
      },
      {
        field: "total_amount_calculated",
        fieldToDisplay: "Total Amount",
        operatorType: "number",
      },
      {
        field: "discount_amt",
        fieldToDisplay: "Discount Amount",
        operatorType: "number",
      },
      {
        field: "total_amount_finalized",
        fieldToDisplay: "Finalized Amount",
        operatorType: "number",
      },
      {
        field: "balance_amt",
        fieldToDisplay: "Balance Amount",
        operatorType: "number",
      },
    ],
    []
  );

  useEffect(() => {
    fetchEntries(
      state.search,
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    ).then((data) => {
      updatePagination(
        data?.count,
        { pageNumber: state.pageNumber, pageSize: state.pageSize },
        (pageNumber) => {
          setState((prevState) => ({ ...prevState, pageNumber }));
        }
      );
    });
  }, [
    fetchEntries,
    state.search,
    state.filters,
    state.pageSize,
    state.pageNumber,
    state.order,
    state.orderBy,
    state.viewInvoiceSummary,
    state.pageFilters,
  ]);

  useEffect(() => {
    fetchList("customers", noop);
    fetchList("users", noop);
    fetchPDXCompanyList();
    fetchCustomerLocation();
  }, [state.viewInvoiceSummary]);

  useEffect(() => {
    if (
      state.pdxCompany &&
      (state.customer || state.selectedLocation || state.route)
    ) {
      setState((prevState) => ({
        ...prevState,
        customer: null,
        selectedLocation: null,
        route: null,
        filters: {
          ...prevState.filters,
          customer: [],
          selectedLocation: [],
          route: [],
        },
      }));
    }
  }, [state.pdxCompany]);

  useEffect(() => {
    if (state.customer && (state.selectedLocation || state.route)) {
      setState((prevState) => ({
        ...prevState,
        selectedLocation: null,
        route: null,
        filters: {
          ...prevState.filters,
          selectedLocation: [],
          route: [],
        },
      }));
    }
  }, [state.customer]);

  useEffect(() => {
    setTimeout(updateLayout, 1000);
  }, [state.entries]);

  const handlePageNumberChange = useCallback((pageNumber) => {
    setState((prevState) => ({
      ...prevState,
      pageNumber,
      selectedRows: defaultState.selectedRows,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handlePageSizeChange = useCallback((pageSize) => {
    setState((prevState) => ({
      ...prevState,
      pageSize,
      pageNumber: defaultState.pageNumber,
      selectedRows: defaultState.selectedRows,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleSelectionChange = useCallback(async (selectedRows) => {
    setState((prevState) => ({
      ...prevState,
      selectedRows,
    }));
  }, []);

  const handleSortChange = useCallback((fieldObj, order) => {
    setState((prevState) => ({
      ...prevState,
      order: order,
      orderBy: fieldObj.field || fieldObj.fieldName,
      selectedRows: defaultState.selectedRows,
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleSearch = useCallback((search) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        search,
        pageNumber: defaultState.pageNumber,
        selectedRows: defaultState.selectedRows,
      }));
      gridHelper && gridHelper.resetSelection();
    }, 1000);
  }, []);

  const handleInvoiceFilter = (option) => {
    if (option.value === "overdue") {
      setState((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          invoiceFilter: [
            {
              field: "is_overdue",
              type: "=",
              value: true,
            },
          ],
        },
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          invoiceFilter: [
            {
              field: "status",
              type: "=",
              value: option.value,
            },
          ],
        },
      }));
    }
  };

  const handleApproval = async (id, type) => {
    setState((prevState) => ({
      ...prevState,
      isApprovalLoading: true,
    }));

    let idArray = [];
    idArray.push(id);

    let serviceMethod, status;

    switch (type) {
      case "approve":
        serviceMethod = "approveInvoice";
        status = "approved";

        break;
      case "reject":
        serviceMethod = "rejectInvoice";
        status = "rejected";
        break;
      default:
        serviceMethod = null;
    }

    const { error } = await Service[serviceMethod]({ invoiceIds: idArray });

    setState((prevState) => ({
      ...prevState,
      isApprovalLoading: false,
      isApprovedDialog: false,
    }));

    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      toast.success(`Invoice has been ${status} successfully.`);
      fetchEntries(
        state.search,
        state.filters,
        state.pageSize,
        state.pageNumber,
        state.order,
        state.orderBy,
        state.pageFilters
      );
      getInvoice({ id: id }, true);
    }
  };

  const handleFilterChange = useCallback((pageFilters = []) => {
    setState((prevState) => ({
      ...prevState,
      pageFilters,
      selectedRows: [],
    }));
    gridHelper && gridHelper.resetSelection();
  }, []);

  const handleApprovalDialog = (id, type, value) => {
    setState((prevState) => ({
      ...prevState,
      isApprovedDialog: value,
      invoiceId: id,
      approvalType: type,
    }));
  };

  const handleItemClick = (invoice) => {
    setState((prevState) => ({
      ...prevState,
      selectedInvoice: invoice,
    }));
  };

  const handleFetchEmail = (loading) => {
    setState((prevState) => ({
      ...prevState,
      [loading]: true,
    }));
    fetchEntries(
      state.search,
      state.filters,
      state.pageSize,
      state.pageNumber,
      state.order,
      state.orderBy,
      state.pageFilters
    )
      .then(() => {
        setState((prevState) => ({
          ...prevState,
          [loading]: false,
        }));
      })
      .catch(() => {
        setState((prevState) => ({
          ...prevState,
          [loading]: false,
        }));
      });
  };

  const handleMultiplePdf = async (
    selectedInvoiceIds = [],
    loading,
    isPreview = false
  ) => {
    const invoiceData = await fetchAllInvoiceEntries(
      selectedInvoiceIds,
      loading
    );

    const pdfArrayBuffers = await Promise.all(
      invoiceData?.map(async (invoice) => {
        const invoiceData = {
          invoiceDetails: invoice?.lineItems,
          invoiceNo: invoice.invoiceDetails.serial_no,
          terms: invoice.invoiceDetails.terms,
          termsAndCondition: invoice.invoiceDetails.terms_and_condition,
          invoiceDate: invoice.invoiceDetails.invoice_date,
          customerNotes: invoice.invoiceDetails.customer_notes,
          invoiceStatus: invoice.invoiceDetails.status,
          discount: invoice.invoiceDetails.discount_amt,
          pdxCompany: invoice.invoiceDetails.customer_branch?.pdxCompany?.value,
          balanceAmount: invoice?.invoiceDetails?.balance_amt,
          paidAmount: invoice?.invoiceDetails?.paid_amt,
          isOverdue: invoice?.invoiceDetails?.is_overdue,
          billTo: {
            customerName: invoice.invoiceDetails.bill_to?.customerName,
            branch: invoice.invoiceDetails.bill_to?.branch,
            streetAddress: invoice.invoiceDetails.bill_to?.street_address,
            state: invoice.invoiceDetails.bill_to?.state,
            city: invoice.invoiceDetails.bill_to?.city,
            country: invoice.invoiceDetails.bill_to?.country,
            zipCode: invoice.invoiceDetails.bill_to?.zip_code,
            phone: invoice.invoiceDetails.bill_to?.phone,
            contact: invoice.invoiceDetails.bill_to?.contact,
          },
          isArrayPrinting: true,
        };

        const pdfBytes = await GenerateInvoicePDF(invoiceData);
        return pdfBytes;
      })
    );

    const mergedPdf = await PDFDocument.create();

    for (const pdfArrayBuffer of pdfArrayBuffers) {
      const pdf = await PDFDocument.load(pdfArrayBuffer);
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }

    const mergedPdfBytes = await mergedPdf.save();

    const mergedPdfBlob = new Blob([mergedPdfBytes], {
      type: "application/pdf",
    });

    if (isPreview) {
      const pdfUrl = URL.createObjectURL(mergedPdfBlob);
      window.open(pdfUrl);
    } else {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(mergedPdfBlob);
      link.download = `multiple_invoices${
        state.pdxCompany
          ? `-${state.pdxCompany?.value.replace(/\s+/g, "_")}`
          : ""
      }-${
        state.invoiceDate
          ? getUTCDateString(state.invoiceDate)
          : getDateString(Date.now())
      }.pdf`;

      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    }
  };

  const columnConfig = [
    {
      isHidden: !state.dynamicColumns?.serial_no?.isSelected,
      id: "serial_no",
      label: "Invoice No.",
      field: "serial_no",
      canSort: true,
      render: (row) => {
        const title = row.serial_no;
        return (
          <div className="d-flex f-align-center">
            <Tooltip title={title} placement="top-start">
              <Typography
                variant="body2"
                onClick={() => {
                  getInvoice(row, true);
                  setState((prevState) => ({
                    ...prevState,
                    viewInvoiceSummary: true,
                    selectedInvoice: row,
                  }));
                }}
                style={{
                  color: "#775edc",
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                {title}
              </Typography>
            </Tooltip>
            {!!row?.is_sent_to_customer && (
              <Tooltip title={"Invoice Sent"} placement="top-start">
                <MailIcon color="primary" className="ml-2" />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.status?.isSelected,
      id: "status",
      label: "Status",
      field: "status",
      render: (row) => {
        const title = INVOICE_STATUS.find((item) => item.value === row?.status);
        return (
          <Tooltip
            title={
              !!row.is_overdue
                ? `${title?.label} / Overdue`
                : title?.label ?? ""
            }
            placement="top-start"
          >
            <Typography variant="body2">
              {!!row.is_overdue
                ? `${title?.label} / Overdue`
                : title?.label || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.invoice_date?.isSelected,
      id: "invoice_date",
      label: "Invoice Date",
      field: "invoice_date",
      canSort: true,
      render: (row) => {
        const title = getUTCDateString(row.invoice_date);
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.initial_sent_date?.isSelected,
      id: "initial_sent_date",
      label: "Invoice Sent Date",
      field: "initial_sent_date",
      canSort: true,
      render: (row) => {
        const title = getUTCDateString(row.initial_sent_date);
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.pdxCompany?.isSelected,
      id: "pdxCompany",
      label: "PDX Company",
      field: "pdxCompany",
      render: (row) => {
        const title = row?.customer_branch?.pdxCompany?.value;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.customer?.isSelected,
      id: "customer",
      label: "Customer",
      field: "customer",
      render: (row) => {
        const title = row?.customer_branch?.customer?.name;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.location?.isSelected,
      id: "location",
      label: "Location",
      field: "location",
      render: (row) => {
        const title = row?.customer_branch?.location;

        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.route?.isSelected,
      id: "route",
      label: "Route(s)",
      field: "route",
      render: (row) => {
        const routes = row?.RoutesDetails ?? [];
        const title = routes.map((ele) => ele?.name)?.join(", ");
        return (
          <Tooltip title={title || ""} placement="top-start">
            <Typography variant="body2" noWrap>
              {title || "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.terms?.isSelected,
      id: "terms",
      label: "Net Terms Days",
      field: "terms",
      canSort: true,
      render: (row) => {
        const title = row.terms;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.total_amount?.isSelected,
      id: "total_amount_calculated",
      label: "Total Amount",
      field: "total_amount_calculated",
      canSort: true,
      render: (row) => {
        const title = `$${row.total_amount_calculated || "0.00"}`;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.discount_amt?.isSelected,
      id: "discount_amt",
      label: "Discount Amount",
      field: "discount_amt",
      canSort: true,
      render: (row) => {
        const title = `$${row.discount_amt || "0.00"}`;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.total_amount_finalized?.isSelected,
      id: "total_amount_finalized",
      label: "Finalized Amount",
      field: "total_amount_finalized",
      canSort: true,
      render: (row) => {
        const title = `$${row.total_amount_finalized || "0.00"}`;
        return (
          <Tooltip title={title ?? ""} placement="top-start">
            <Typography variant="body2">{title || "-"}</Typography>
          </Tooltip>
        );
      },
    },
    {
      isHidden: !state.dynamicColumns?.balance_amt?.isSelected,
      id: "balance_amt",
      label: "Balance Amount",
      field: "balance_amt",
      canSort: true,
      render: (row) => {
        const title = row.balance_amt;
        return (
          <Tooltip title={`$${title || "0.00"}`} placement="top-start">
            <Typography variant="body2">{`$${title || "0.00"}`}</Typography>
          </Tooltip>
        );
      },
    },
  ];

  const handleClose = (isClose) => {
    setState((prevState) => ({
      ...prevState,
      viewInvoiceSummary: false,
    }));
  };

  const handleDelete = async (ids) => {
    setState((prevState) => ({
      ...prevState,
      isInvoiceDeleteLoading: true,
    }));

    const queryString = `?invoiceIds=${ids}`;

    const { error } = await Service.deleteInvoices(queryString);

    setState((prevState) => ({
      ...prevState,
      isInvoiceDeleteLoading: false,
      deletingEntryIds: null,
      selectedRows: defaultState.selectedRows,
    }));

    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error.message);
    } else {
      toast.success("Deleted successfully.");
      gridHelper && gridHelper.resetSelection();
      fetchEntries(
        state.search,
        state.filters,
        state.pageSize,
        state.pageNumber,
        state.order,
        state.orderBy,
        state.pageFilters
      );
    }
  };

  const statusKeys = Object.keys(defaultState.dynamicStatus);

  const getFieldValidatorMap = (state, field) => {
    const fieldValidatorMap = {
      from: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
      email: [{ type: VALIDATIONS.EMAIL, value: true }],
      emailSubject: [{ type: VALIDATIONS.MAX_LENGTH, value: 200 }],
      emailBody: [{ type: VALIDATIONS.MAX_LENGTH, value: 2000 }],
    };
    return fieldValidatorMap;
  };

  const validate = (field, value) => {
    let errorMessage = " ";
    const fieldValidatorMap = getFieldValidatorMap(state, field);

    if (fieldValidatorMap[field]) {
      const validationError = fieldValidatorMap[field].map((validation) =>
        validator(
          validation.type,
          validation.value,
          value,
          validation.inputType || "string"
        )
      );
      errorMessage = validationError
        .filter((error) => error?.message)
        .map((error) => error?.message)[0];
    } else {
      Object.keys(fieldValidatorMap).forEach((key) => {
        const message = validate(key, state[key]);
        if (!!message) {
          errorMessage = message;
        }
      });
    }

    return errorMessage;
  };

  const handleSubmit = async (
    ids = [],
    tempEmailArray,
    permEmailArray,
    subject,
    body
  ) => {
    setState((prevState) => ({ ...prevState, isInvoiceSending: true }));
    const emailArray =
      !state.selectedRows.length || state.selectedRows.length === 1
        ? [...tempEmailArray, ...permEmailArray]
        : [...tempEmailArray];

    const { error } = await Service.sendEmail({
      invoiceIds: ids,
      emailIds: emailArray,
      subject,
      email_body: body,
    });

    if (error) {
      setState((prevState) => ({ ...prevState, isInvoiceSending: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success("Email sent successfully.");
    setState((prevState) => ({
      ...prevState,
      isInvoiceSending: false,
      showSendInvoicePopup: false,
      temporaryEmail: defaultState.temporaryEmail,
      permanentEmail: defaultState.permanentEmail,
      from: defaultState.from,
      emailSubject: defaultState.emailSubject,
      emailBody: defaultState.emailBody,
    }));
    handleFetchEmail("invoiceLoading");
  };

  const handleFieldChange = (evt) => {
    const field = evt.currentTarget?.name || evt.target?.name;
    let value = evt.currentTarget?.value || evt.target?.value;
    let errorMessage = validate(field, value) || " ";

    if (state.temporaryEmail.some((obj) => obj === value.trim())) {
      errorMessage = "This email has already been entered.";
    }
    if (state.permanentEmail.some((email) => email === value.trim())) {
      errorMessage = "This email has already been entered.";
    }

    isConfirmDisabled = !!errorMessage && errorMessage !== " ";
    setState((prevState) => ({
      ...prevState,
      [field]: value,
      errors: {
        ...prevState.errors,
        [field]: errorMessage,
      },
    }));
  };

  const handleAddEmail = (type) => {
    if (state.email?.trim()) {
      setState((prevState) => ({
        ...prevState,
        [type]: [...(prevState[type] || []), state?.email?.trim()],

        ...(type === "temporaryEmail" && {
          showAddPermanentEmailDialog: false,
          email: defaultState.email,
        }),
      }));
    }
  };

  const handleDeleteEmail = (id, type) => {
    setState((prevState) => {
      const filteredEmails = prevState[type].filter((email) => email !== id);
      const emailExists = filteredEmails.some(
        (item) => item === prevState.email
      );

      return {
        ...prevState,
        [type]: filteredEmails,
        errors: {
          ...prevState.errors,
          email: emailExists ? prevState.errors.email : " ",
          from: prevState.errors.from,
          emailSubject: prevState.errors.emailSubject,
          emailBody: prevState.errors.emailBody,
        },
      };
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (validate() === " " && state.errors?.email === " ") {
        event.preventDefault();
        handleAddEmail("temporaryEmail");
      }
    }
  };

  const handleBlur = () => {
    if (validate() === " " && state.errors?.email === " ") {
      handleAddEmail("temporaryEmail");
    }
  };

  const handleAddPermEmailDialog = () => {
    if (validate() === " " && state.errors?.email === " ") {
      handleAddEmail("permanentEmail");
    }

    setState((prevState) => ({
      ...prevState,
      showAddPermanentEmailDialog: true,
    }));
  };

  const addPermananentEmaiil = async (data, email) => {
    setState((prevState) => ({ ...prevState, isPermEmailLoading: true }));

    const payload = {
      id: data.id || null,
      invoice_type: data.invoiceType?.id || null,
      pdx_company_id: data.pdx_company_id || null,
      customer_id: data.customer_id,
      state: data.state || null,
      location: data.location || null,
      aa_id: data.aa_id || null,
      kam_id: data.kam_id || null,
      mgr_id: data.mgr_id || null,
      email_addresses: {
        emailAddresses: email,
      },
    };

    const { error } = await Service.addEmailInBranch(payload);

    if (error) {
      handleAddEmail("temporaryEmail");
      setState((prevState) => ({ ...prevState, isPermEmailLoading: false }));
      return toast.error(
        Array.isArray(error) ? error[0]?.message : error.message
      );
    }
    toast.success(`Customer Email has been succesfully added.`);
    setState((prevState) => ({
      ...prevState,
      isPermEmailLoading: false,
      showAddPermanentEmailDialog: false,
      email: defaultState.email,
    }));
    handleFetchEmail("emailLoading");
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      emailSubject: `${
        state.selectedRows.length === 1
          ? `#${
              state.entries.filter((obj) =>
                state.selectedRows.includes(obj.id)
              )[0]?.serial_no
            }`
          : "Invoices"
      } from Parts Distribution Xpress (PDX)`,
      emailBody: `Dear ${
        state.selectedRows.length === 1
          ? state.entries.filter((obj) =>
              state.selectedRows.includes(obj.id)
            )[0]?.customer_branch?.customer?.name
          : "Customers"
      },\n\nYour ${
        state.selectedRows.length > 1 ? "invoices are" : "invoice is"
      } attached. Please remit payment at your earliest convenience. Thank you for your business - we appreciate it very much.\n\nSincerely,\nParts Distribution Xpress`,
    }));
  }, [state.selectedInvoice, state.selectedRows, state.entries]);

  const dataEntry =
    state.entries.filter((obj) => state.selectedRows.includes(obj.id))[0] || [];

  const emailAddresses =
    state.selectedRows.length === 1 &&
    (dataEntry?.customer_branch?.email_addresses?.emailAddresses || []);

  useEffect(() => {
    if (
      state.showSendInvoicePopup &&
      dataEntry?.status === "approved" &&
      emailAddresses.length
    ) {
      setState((prevState) => ({
        ...prevState,
        permanentEmail: emailAddresses,
      }));
    }
  }, [state.showSendInvoicePopup, dataEntry, emailAddresses]);

  return (
    <>
      <div className="mr-5">
        <div className={classes.fixedHeader}>
          <Typography variant="h4" color="primary" className=" ml-2">
            View Invoices
            <Button
              style={{ float: "right" }}
              className="mt-1 mr-2"
              variant="contained"
              color="primary"
              onClick={() => history.push("/home")}
              startIcon={<ArrowBackIcon />}
            >
              Back To Home
            </Button>
            <Button
              style={{ float: "right" }}
              className="mt-1 mr-2"
              variant="contained"
              color="primary"
              onClick={() => history.push("/invoice")}
              startIcon={<ArrowBackIcon />}
            >
              Back To Invoice
            </Button>
          </Typography>
          <Typography variant="body2" style={{ marginLeft: "10px" }}>
            View All Invoice.
          </Typography>
        </div>
        {!state.viewInvoiceSummary && (
          <>
            <div className="d-flex f-justify-between f-align-center">
              <div className="d-flex f-wrap">
                <div className="mt-4 mr-4">
                  <Datepicker
                    mask
                    label="Invoice Date"
                    selected={state.invoiceDate}
                    placement={"bottom-start"}
                    classes={{
                      datepickerWrapper: classes.scheduleDateWrapper,
                      input: {
                        root: classes.datepickerWrapper,
                      },
                    }}
                    isClearable
                    onChange={(value) => {
                      setState((prevState) => ({
                        ...prevState,
                        invoiceDate: value,
                        filters: {
                          ...prevState.filters,
                          invoiceDate: [
                            {
                              field: "invoice_date",
                              type: "=",
                              value: getDateString(value),
                            },
                          ],
                        },
                      }));
                    }}
                  />
                </div>
                <div className="mt-4 mr-4">
                  <Datepicker
                    mask
                    label="Invoice Sent Date"
                    selected={state.invoiceSentDate}
                    placement={"bottom-start"}
                    classes={{
                      datepickerWrapper: classes.scheduleDateWrapper,
                      input: {
                        root: classes.datepickerWrapper,
                      },
                    }}
                    isClearable
                    onChange={(value) => {
                      setState((prevState) => ({
                        ...prevState,
                        invoiceSentDate: value,
                        filters: {
                          ...prevState.filters,
                          invoiceSentDate: [
                            {
                              field: "initial_sent_date",
                              type: "=",
                              value: getDateString(value),
                            },
                          ],
                        },
                      }));
                    }}
                  />
                </div>
                <div className="mr-2">
                  <Autocomplete
                    fullWidth
                    className={clsx("mr-4 mt-4", classes.customerDropdown)}
                    size="small"
                    value={state.pdxCompany}
                    classes={{
                      root: classes.autocompleteFilters,
                    }}
                    options={state.pdxCompanyList}
                    getOptionLabel={(option) => option.value || ""}
                    renderInput={(params) =>
                      state.isFetchingList.pdxCompany ? (
                        <Skeleton variant="rect" width="100%" height="42px" />
                      ) : (
                        <TextField
                          {...params}
                          label="PDX Company"
                          variant="outlined"
                        />
                      )
                    }
                    onChange={(evt, value) => {
                      setState((prevState) => ({
                        ...prevState,
                        pdxCompany: value,
                        filters: {
                          ...prevState.filters,
                          pdxCompany: [
                            {
                              field: "pdx_company_name",
                              type: "=",
                              value: value?.value,
                            },
                          ],
                        },
                      }));
                    }}
                  />
                </div>
                <div className="mr-2">
                  <Autocomplete
                    fullWidth
                    className={clsx("mr-4 mt-4", classes.customerDropdown)}
                    size="small"
                    value={state.customer}
                    classes={{
                      root: classes.autocompleteFilters,
                    }}
                    options={
                      !state.pdxCompany ? appData.customers : getCustomerList
                    }
                    getOptionLabel={({ name }) => name}
                    renderInput={(params) =>
                      state.isFetchingList.customers ? (
                        <Skeleton variant="rect" width="100%" height="42px" />
                      ) : (
                        <TextField
                          {...params}
                          label="Customer"
                          variant="outlined"
                        />
                      )
                    }
                    onChange={(evt, value) => {
                      setState((prevState) => ({
                        ...prevState,
                        customer: value,
                        filters: {
                          ...prevState.filters,
                          customer: [
                            {
                              field: "customer_name",
                              type: "=",
                              value: value?.name,
                            },
                          ],
                        },
                      }));
                    }}
                  />
                </div>
                <div className={clsx("mr-2")}>
                  <Autocomplete
                    classes={{
                      root: classes.autocompleteFilters,
                    }}
                    size="small"
                    fullWidth
                    className={clsx("mr-4 mt-4", classes.customerDropdown)}
                    value={state.selectedLocation}
                    options={getLocationList}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) =>
                      state.isFetchingList.locations ? (
                        <Skeleton variant="rect" width="100%" height="42px" />
                      ) : (
                        <TextField
                          {...params}
                          label="Location"
                          variant="outlined"
                        />
                      )
                    }
                    getOptionSelected={(option, value) =>
                      option.label === value.label
                    }
                    onChange={(evt, value) => {
                      setState((prevState) => ({
                        ...prevState,
                        selectedLocation: value,
                        filters: {
                          ...prevState.filters,
                          selectedLocation: [
                            {
                              field: "location",
                              type: "=",
                              value: value?.value,
                            },
                          ],
                        },
                      }));
                    }}
                  />
                </div>
                <div className={clsx("mr-2")}>
                  <Autocomplete
                    fullWidth
                    className={clsx("mr-4 mt-4", classes.customerDropdown)}
                    size="small"
                    classes={{
                      root: classes.autocompleteFilters,
                    }}
                    value={state.route}
                    options={getRouteList}
                    getOptionLabel={(option) => option?.name || option || ""}
                    renderInput={(params) =>
                      state.isFetchingList.customers ? (
                        <Skeleton variant="rect" width="100%" height="42px" />
                      ) : (
                        <TextField
                          {...params}
                          label="Route"
                          variant="outlined"
                        />
                      )
                    }
                    onChange={(evt, value) => {
                      setState((prevState) => ({
                        ...prevState,
                        route: value,
                        filters: {
                          ...prevState.filters,
                          route: [value?.id],
                        },
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="d-flex f-align-center">
                <TextField
                  type="text"
                  variant="outlined"
                  className="ml-2 mt-3"
                  size="small"
                  defaultValue={state.search}
                  placeholder="search by: Invoice No."
                  InputProps={{ endAdornment: <SearchIcon /> }}
                  onChange={(evt) =>
                    handleSearch((evt.target.value || "").trim())
                  }
                />
              </div>
            </div>
            <Paper
              elevation={2}
              className={clsx("p-4 mt-10", classes.paperSpacing)}
            >
              <div
                className={clsx(
                  "d-flex f-align-center f-justify-between p-2 pl-4 pr-4",
                  classes.actionsWrapper
                )}
              >
                <div>
                  {!!state.selectedRows.length && (
                    <>
                      <Button
                        startIcon={<DeleteForeverOutlinedIcon color="error" />}
                        classes={{
                          root: "border-error ml-4",
                          label: "color-error",
                        }}
                        variant="outlined"
                        onClick={() => {
                          if (
                            state.entries.some(
                              (ele) =>
                                state.selectedRows.includes(ele.id) &&
                                ele.status !== "draft"
                            )
                          ) {
                            return toast.error(
                              "Deletion is not allowed for invoices unless they are in draft status."
                            );
                          }
                          setState((prevState) => ({
                            ...prevState,
                            deletingEntryIds: state.selectedRows.join(","),
                          }));
                        }}
                      >
                        Delete
                      </Button>
                      <Button
                        color="primary"
                        startIcon={<SendIcon color="primary" />}
                        className="ml-2"
                        variant="outlined"
                        onClick={() => {
                          if (
                            state.entries.some(
                              (ele) =>
                                state.selectedRows.includes(ele.id) &&
                                (ele.status === "draft" ||
                                  ele.status === "pending-approval")
                            )
                          ) {
                            return toast.error(
                              "Invoice(s) can only be sent to customers whose status is approved, paid, or partially paid."
                            );
                          }
                          setState((prevState) => ({
                            ...prevState,
                            showSendInvoicePopup: true,
                          }));
                        }}
                      >
                        Send Invoice
                      </Button>
                      <Button
                        color="primary"
                        className="ml-2"
                        variant="outlined"
                        onClick={() => {
                          if (
                            state.entries.some(
                              (ele) =>
                                state.selectedRows.includes(ele.id) &&
                                (ele.status === "draft" ||
                                  ele.status === "pending-approval")
                            )
                          ) {
                            return toast.error(
                              "Invoice(s) can only be marked as sent for customers whose status is approved, paid, or partially paid."
                            );
                          }
                          handleMarkInvoiceSentDialog();
                        }}
                      >
                        Mark as Sent
                      </Button>
                    </>
                  )}
                </div>

                <div className="d-flex f-align-center">
                  <div className="mr-4">
                    <Button
                      startIcon={<GetAppIcon />}
                      variant="contained"
                      color="primary"
                      disabled={
                        state.isInvoiceDetailsLoading ||
                        !state.selectedRows?.length
                      }
                      onClick={() =>
                        handleMultiplePdf(
                          state.selectedRows,
                          "isInvoiceDetailsLoading",
                          false
                        )
                      }
                    >
                      Download
                      {state.isInvoiceDetailsLoading && (
                        <CircularProgress
                          size={24}
                          className="p-absolute progress-btn"
                        />
                      )}
                    </Button>
                  </div>
                  <FormControlLabel
                    label="Is Overdue"
                    control={
                      <Checkbox
                        color="primary"
                        checked={state.isOverdue}
                        onChange={(evt) => {
                          const overdue = evt.target.checked;
                          setState((prevState) => ({
                            ...prevState,
                            isOverdue: overdue,
                            filters: {
                              ...prevState.filters,
                              isOverdue: overdue
                                ? [
                                    {
                                      field: "is_overdue",
                                      type: "=",
                                      value: overdue,
                                    },
                                  ]
                                : [],
                            },
                          }));
                        }}
                      />
                    }
                  />
                  <Dropdown
                    classes={{
                      paper: classes.dropdownPaper,
                      wrapper:
                        !!state.filters.invoiceStatus.length &&
                        classes.dropdownBackground,
                    }}
                    disablePortal={false}
                    isMultiSelect
                    remainOpen
                    placement="bottom-end"
                    options={statusKeys.map((key) => state.dynamicStatus[key])}
                    labelEllipses
                    hasEllipses
                    label={"Status :"}
                    onChange={(selectedOptions) => {
                      const isSelectAll = selectedOptions.some(
                        (item) => item.value === "all"
                      );
                      const isCurrentSelectAll =
                        state.dynamicStatus?.all?.isSelected;
                      const isClickedOnSelectAll =
                        isSelectAll !== isCurrentSelectAll;
                      const isAllOptionSelected =
                        statusKeys.length - 1 ===
                        selectedOptions.filter((item) => item.value !== "all")
                          .length;

                      const updatedDynamicStatus = statusKeys.reduce(
                        (acc, key) => {
                          const isSelected = isClickedOnSelectAll
                            ? isSelectAll
                            : key === "all"
                            ? isAllOptionSelected
                            : selectedOptions.some(
                                (item) =>
                                  item.label === state.dynamicStatus[key].label
                              );

                          return {
                            ...acc,
                            [key]: { ...state.dynamicStatus[key], isSelected },
                          };
                        },
                        {}
                      );

                      const tempFilters = [];

                      if (!updatedDynamicStatus["all"].isSelected) {
                        statusKeys.forEach((key) => {
                          if (key !== "all") {
                            if (updatedDynamicStatus[key].isSelected) {
                              tempFilters.push({
                                field: "status",
                                type: "=",
                                value: key.replace(/_/g, "-"),
                              });
                            }
                          }
                        });
                      }

                      setState((prevState) => ({
                        ...prevState,
                        dynamicStatus: {
                          ...prevState.dynamicStatus,
                          ...updatedDynamicStatus,
                        },
                        filters: {
                          ...prevState.filters,
                          invoiceStatus: tempFilters,
                        },
                        pageNumber: defaultState.pageNumber,
                      }));
                    }}
                  />
                  <Dropdown
                    classes={{
                      paper: classes.tunePaper,
                    }}
                    disablePortal={false}
                    remainOpen
                    isMultiSelect
                    placement="bottom-end"
                    options={Object.keys(state.dynamicColumns).map(
                      (key) => state.dynamicColumns[key]
                    )}
                    customToggle={({ anchorRef, onClick }) => (
                      <Tooltip
                        title="Show/Hide column(s)"
                        placement="top-start"
                      >
                        <IconButton
                          color="primary"
                          ref={anchorRef}
                          onClick={onClick}
                        >
                          <TuneIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    onChange={(options) => {
                      const isSelectAll = options.some(
                        (item) => item.value === "select_all"
                      );
                      const isCurrentSelectAll =
                        state.dynamicColumns?.select_all?.isSelected;

                      const isClickedOnSelectAll =
                        isSelectAll !== isCurrentSelectAll;

                      const isAllOptionSelected =
                        Object.keys(state.dynamicColumns).length - 1 ===
                        options.filter((item) => item.value !== "select_all")
                          .length;
                      const updatedDynamicColumns = Object.keys(
                        state.dynamicColumns
                      ).reduce((acc, key) => {
                        const isSelected = isClickedOnSelectAll
                          ? isSelectAll
                          : key === "select_all"
                          ? isAllOptionSelected
                          : !!options.some((item) => item.value === key);

                        return {
                          ...acc,
                          [key]: {
                            ...state.dynamicColumns[key],
                            isSelected,
                          },
                        };
                      }, {});
                      setPageConfig(PAGE_KEYS.VIEW_INVOICE, {
                        dynamicColumns: updatedDynamicColumns,
                      });
                      setState((prevState) => ({
                        ...prevState,
                        dynamicColumns: {
                          ...prevState.dynamicColumns,
                          ...updatedDynamicColumns,
                        },
                      }));
                    }}
                  />
                  <Tooltip placement="top-start" title="Filter">
                    <IconButton
                      color="primary"
                      className={clsx("ml-2", {
                        "bg-primary": !!state.pageFilters?.length,
                      })}
                      onClick={() => {
                        setState((prevState) => ({
                          ...prevState,
                          isFiltering: true,
                        }));
                      }}
                    >
                      <FilterListIcon
                        className={clsx({
                          "color-white": !!state.pageFilters?.length,
                        })}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              <Grid
                columns={columnConfig}
                rows={state.entries.map((entry, rowIndex) => {
                  const createdAt = parseISO(entry.created_at);
                  const now = new Date();
                  const isRecent = differenceInSeconds(now, createdAt) <= 20;

                  return {
                    ...entry,
                    className: clsx({
                      [classes.overdue]: !!entry.is_overdue,
                      [classes.paid]: entry.status === "paid",
                      [classes.pulseRow]: state.isPulsingEffect && isRecent,
                    }),
                  };
                })}
                actionBarConfig={null}
                hasSelectAll={false}
                isLoading={state.isLoading}
                totalRows={state.totalEntries}
                onReady={(instance) => (gridHelper = instance)}
                pageSize={state.pageSize}
                pageNumber={state.pageNumber}
                order={state.order}
                orderBy={state.orderBy}
                onPageNumberChange={handlePageNumberChange}
                onPageSizeChange={handlePageSizeChange}
                onSelectionChange={handleSelectionChange}
                onSortChange={handleSortChange}
                classes={{
                  container: classes.addressGridridPaper,
                }}
              />
            </Paper>
          </>
        )}
        {state.viewInvoiceSummary && (
          <InvoiceSummary
            rows={state.entries}
            selectedInvoice={state.selectedInvoice}
            customerList={appDataList.customers}
            usersList={appDataList.users}
            isLoading={state.isLoading}
            totalEntries={state.totalEntries}
            pageSize={state.pageSize}
            isInvoiceLoading={state.isInvoiceLoading}
            pageNumber={state.pageNumber}
            isMarkAsSentLoading={state.isMarkAsSentLoading}
            onPageNumberChange={handlePageNumberChange}
            getInvoice={getInvoice}
            handleMarkInvoiceSent={handleMarkInvoiceSent}
            onPageSizeChange={handlePageSizeChange}
            handleClose={handleClose}
            handleTitleClick={handleTitleClick}
            handleInvoiceFilter={handleInvoiceFilter}
            handleItemClick={handleItemClick}
            handleApprovalDialog={handleApprovalDialog}
            pdfUrl={state.pdfUrl}
            entry={state.entry}
            history={history}
            handleFetch={handleFetchEmail}
            invoiceLoading={state.invoiceLoading}
            handleMultiplePdf={handleMultiplePdf}
            isPdfPreviewLoading={state.isPdfPreviewLoading}
            isPdfDownloadLoading={state.isPdfDownloadLoading}
            invoiceFilterStatus={state.filters.invoiceStatus}
            fetchInvoice={fetchInvoice}
            emailLoading={state.emailLoading}
          />
        )}
        {state.isApprovedDialog && (
          <ActionDialog
            classes={{
              confirm: `${
                state.approvalType === "reject" ? "bg-danger" : "bg-primary"
              }`,
              paper: classes.paperSize,
            }}
            open={state.isApprovedDialog}
            contentText={`Are you sure you want to ${state.approvalType} ?`}
            onConfirm={() =>
              handleApproval(state.invoiceId, state.approvalType)
            }
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                isApprovedDialog: false,
              }))
            }
            isConfirmDisabled={state.isApprovalLoading}
            positiveActionLabel={
              <>
                {state.approvalType}
                {state.isApprovalLoading && (
                  <CircularProgress
                    size={24}
                    className="p-absolute progress-btn"
                  />
                )}
              </>
            }
          />
        )}
      </div>
      {state.deletingEntryIds && (
        <ActionDialog
          classes={{
            confirm: "bg-danger",
            paper: classes.paperSize,
          }}
          open={!!state.deletingEntryIds}
          contentText="Are you sure you want to delete?"
          onConfirm={() => handleDelete(state.deletingEntryIds)}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              deletingEntryIds: null,
            }))
          }
          isConfirmDisabled={state.isInvoiceDeleteLoading}
          positiveActionLabel={
            <>
              Delete
              {state.isInvoiceDeleteLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
        />
      )}
      {state.isInvoiceSent && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.paperSizeDialog,
          }}
          open={state.isInvoiceSent}
          contentText={`Are you sure you want to mark this invoice as sent?`}
          onConfirm={() => {
            handleMarkInvoiceSent(
              state.selectedRows.length && state.selectedRows,
              false
            );
          }}
          onCancel={() =>
            setState((prevState) => ({
              ...prevState,
              isInvoiceSent: false,
            }))
          }
          isConfirmDisabled={state.isMarkAsSentLoading}
          positiveActionLabel={
            <>
              SEND
              {state.isMarkAsSentLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn color-white"
                />
              )}
            </>
          }
          negativeActionLabel="Cancel"
        />
      )}
      {state.isFiltering && (
        <FilterComponent
          isFiltering={state.isFiltering}
          filterConfig={filterConfig}
          appliedFilters={state.pageFilters}
          onFilterApply={handleFilterChange}
          onClose={() => {
            setState((prevState) => ({
              ...prevState,
              isFiltering: false,
            }));
          }}
        />
      )}
      {state.showSendInvoicePopup && (
        <ActionDialog
          classes={{
            confirm:
              state.isInvoiceSending || isConfirmDisabled
                ? "black"
                : "bg-primary",
            paper: classes.invoiceSentPaperSize,
          }}
          open={!!state.showSendInvoicePopup}
          contentText={
            <div style={{ overflow: "hidden" }}>
              <Typography
                variant="h5"
                className="text-bold mb-4"
                color="primary"
              >
                {`Send ${
                  state.selectedRows.length === 1
                    ? `#${
                        state.entries.filter((obj) =>
                          state.selectedRows.includes(obj.id)
                        )[0]?.serial_no
                      }`
                    : "Invoices"
                } `}
              </Typography>
              <div className="d-flex">
                <Typography
                  variant="h6"
                  className="text-bold mr-8 mt-4"
                  style={{ width: "8rem" }}
                >
                  From
                </Typography>
                <TextField
                  label="From"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="from"
                  disabled
                  value={"noreply@pdxdelivers.com"}
                  className="mt-3"
                  helperText=" "
                />
              </div>
              {!state.selectedRows.length || state.selectedRows.length === 1 ? (
                <>
                  <div className="d-flex">
                    <Typography
                      variant="h6"
                      className="text-bold mr-8 mt-4"
                      style={{ width: "8rem" }}
                    >
                      To
                    </Typography>

                    <div
                      className="d-flex"
                      style={{ gap: "6px", width: "100%" }}
                    >
                      <TextField
                        label="Add Email ID(s)"
                        fullWidth
                        variant="outlined"
                        size="small"
                        name="email"
                        value={state.email}
                        disabled={state.isInvoiceSending}
                        required
                        className="mt-3"
                        onChange={handleFieldChange}
                        error={!!state.errors.email?.trim()}
                        helperText={state.errors.email}
                      />

                      <div>
                        <Tooltip
                          title="Add Email Temporarily"
                          placement="top-end"
                        >
                          <Button
                            type="submit"
                            onClick={() => {
                              if (
                                validate() === " " &&
                                state.errors?.email === " "
                              ) {
                                handleAddEmail("temporaryEmail");
                              }
                            }}
                            variant="contained"
                            color="primary"
                            disabled={
                              isConfirmDisabled ||
                              !state.email ||
                              state.isInvoiceSending
                            }
                            className={classes.buttonStyle1}
                          >
                            <AddIcon />
                          </Button>
                        </Tooltip>
                      </div>
                      <div>
                        <Tooltip
                          title="Add Email To Customer"
                          placement="top-end"
                        >
                          <Button
                            type="submit"
                            onClick={handleAddPermEmailDialog}
                            variant="contained"
                            color="primary"
                            disabled={
                              isConfirmDisabled ||
                              !state.email ||
                              state.isInvoiceSending
                            }
                            className={classes.buttonStyle1}
                          >
                            <PostAddIcon />
                          </Button>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  {(state.temporaryEmail.length > 0 ||
                    state.permanentEmail.length > 0) && (
                    <div className="d-flex mb-4">
                      <div
                        variant="h6"
                        className="mr-8 mt-4"
                        style={{ width: "8rem" }}
                      ></div>
                      <div
                        className="d-flex f-justify-between flex-wrap"
                        style={{ width: "100%", gap: 20 }}
                      >
                        {state.permanentEmail.length > 0 && (
                          <div
                            className="d-flex flex-column"
                            style={{ width: "100%" }}
                          >
                            <Accordion style={{ width: "100%" }}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1c-content"
                                id="panel1c-header"
                              >
                                <div>
                                  <Typography className={classes.heading}>
                                    Customer Email(s)
                                  </Typography>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div style={{ width: "100%" }}>
                                  <List dense disablePadding>
                                    {state.emailLoading ? (
                                      <ListItem>
                                        <Skeleton
                                          className="mr-2"
                                          variant="circle"
                                          width={15}
                                          height={15}
                                        />
                                        <ListItemText
                                          primary={
                                            <Skeleton
                                              variant="rect"
                                              width="50%"
                                              height="20px"
                                            />
                                          }
                                        />
                                      </ListItem>
                                    ) : (
                                      state.permanentEmail.map((item) => (
                                        <ListItem key={item}>
                                          <div className="mr-2">
                                            <FiberManualRecordIcon
                                              style={{ fontSize: 10 }}
                                            />
                                          </div>
                                          <ListItemText primary={item} />
                                          {!state.isInvoiceSending && (
                                            <ListItemSecondaryAction>
                                              <IconButton
                                                onClick={() =>
                                                  handleDeleteEmail(
                                                    item,
                                                    "permanentEmail"
                                                  )
                                                }
                                                edge="end"
                                                aria-label="delete"
                                              >
                                                <CancelIcon fontSize="small" />
                                              </IconButton>
                                            </ListItemSecondaryAction>
                                          )}
                                        </ListItem>
                                      ))
                                    )}
                                  </List>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        )}
                        {state.temporaryEmail.length > 0 && (
                          <div
                            className="d-flex flex-column"
                            style={{ width: "100%" }}
                          >
                            <Accordion style={{ width: "100%" }}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1c-content"
                                id="panel1c-header"
                              >
                                <div>
                                  <Typography className={classes.heading}>
                                    Temporary Email(s)
                                  </Typography>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div style={{ width: "100%" }}>
                                  <List dense disablePadding>
                                    {state.temporaryEmail.map((item) => (
                                      <ListItem key={item}>
                                        <ListItemText primary={item} />
                                        {!state.isInvoiceSending && (
                                          <ListItemSecondaryAction>
                                            <IconButton
                                              onClick={() =>
                                                handleDeleteEmail(
                                                  item,
                                                  "temporaryEmail"
                                                )
                                              }
                                              edge="end"
                                              aria-label="delete"
                                            >
                                              <CancelIcon fontSize="small" />
                                            </IconButton>
                                          </ListItemSecondaryAction>
                                        )}
                                      </ListItem>
                                    ))}
                                  </List>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="d-flex">
                  <Typography
                    variant="h6"
                    className="text-bold mr-8 mt-4"
                    style={{ width: "8rem" }}
                  >
                    To
                  </Typography>
                  <TextField
                    label="Add Email ID(s)"
                    fullWidth
                    variant="outlined"
                    size="small"
                    name="email"
                    value={state.email}
                    required
                    className="mt-3"
                    onChange={handleFieldChange}
                    error={!!state.errors.email?.trim()}
                    helperText={state.errors.email}
                    disabled={state.isInvoiceSending}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    InputProps={{
                      classes: { root: classes.inputRoot },
                      startAdornment: (
                        <div className={classes.chipsContainer}>
                          {state.temporaryEmail.map((email) => (
                            <Chip
                              key={email}
                              disabled={state.isInvoiceSending}
                              label={email}
                              onDelete={() =>
                                handleDeleteEmail(email, "temporaryEmail")
                              }
                              className={classes.emailChip}
                            />
                          ))}
                        </div>
                      ),
                    }}
                  />
                </div>
              )}
              <div className="d-flex">
                <Typography
                  variant="h6"
                  className="text-bold mr-8 mt-4"
                  style={{ width: "8rem" }}
                >
                  Subject
                </Typography>
                <TextField
                  label="Add Subject"
                  fullWidth
                  variant="outlined"
                  size="small"
                  name="emailSubject"
                  value={state.emailSubject}
                  disabled={state.isInvoiceSending}
                  className="mt-3"
                  onChange={handleFieldChange}
                  error={!!state.errors.emailSubject?.trim()}
                  helperText={state.errors.emailSubject}
                />
              </div>
              <div
                className="d-flex f-align-center mb-4"
                style={{ marginLeft: 130 }}
              >
                <AttachFileIcon fontSize="small" className="mr-1" />
                <Typography
                  variant="body2"
                  className="text-bold"
                  style={{
                    color: "black",
                  }}
                >
                  Invoice PDF
                </Typography>
              </div>
              <div className="d-flex">
                <Typography
                  variant="h6"
                  className="text-bold mr-8 mt-4"
                  style={{ width: "8rem" }}
                >
                  Email Body
                </Typography>
                <TextField
                  label="Email Body"
                  fullWidth
                  variant="outlined"
                  minRows={10}
                  maxRows={10}
                  multiline
                  disabled={state.isInvoiceSending}
                  size="small"
                  name="emailBody"
                  value={state.emailBody}
                  className="mt-3"
                  onChange={handleFieldChange}
                  error={!!state.errors.emailBody?.trim()}
                  helperText={state.errors.emailBody}
                />
              </div>
            </div>
          }
          onConfirm={() =>
            handleSubmit(
              state.selectedRows,
              state.temporaryEmail,
              state.permanentEmail,
              state.emailSubject,
              state.emailBody
            )
          }
          onCancel={() => {
            isConfirmDisabled = false;
            setState((prevState) => ({
              ...prevState,
              showSendInvoicePopup: false,
              email: defaultState.email,
              temporaryEmail: defaultState.temporaryEmail,
              permanentEmail: defaultState.permanentEmail,
              emailSubject: `${
                state.selectedRows.length === 1
                  ? `#${
                      state.entries.filter((obj) =>
                        state.selectedRows.includes(obj.id)
                      )[0]?.serial_no
                    }`
                  : "Invoices"
              } from Parts Distribution Xpress (PDX)`,
              emailBody: `Dear ${
                state.selectedRows.length === 1
                  ? state.entries.filter((obj) =>
                      state.selectedRows.includes(obj.id)
                    )[0]?.customer_branch?.customer?.name
                  : "Customers"
              },\n\nYour ${
                state.selectedRows.length > 1 ? "invoices are" : "invoice is"
              } attached. Please remit payment at your earliest convenience. Thank you for your business - we appreciate it very much.\n\nSincerely,\nParts Distribution Xpress`,
              errors: {
                email: " ",
                from: " ",
                emailSubject: " ",
                emailBody: " ",
              },
            }));
          }}
          isConfirmDisabled={
            state.isInvoiceSending ||
            isConfirmDisabled ||
            !!state.email ||
            (state.temporaryEmail.length === 0 &&
              state.permanentEmail.length === 0) ||
            (validate() && validate() !== " ") ||
            (state.selectedRows.length &&
              state.selectedRows.length !== 1 &&
              state.temporaryEmail.length === 0)
          }
          positiveActionLabel={
            <>
              SEND INVOICE
              {state.isInvoiceSending && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn color-white"
                />
              )}
            </>
          }
          negativeActionLabel="Cancel"
        />
      )}
      {state.showAddPermanentEmailDialog && (
        <ActionDialog
          classes={{
            confirm: "bg-primary",
            paper: classes.paperSizeDialog,
          }}
          open={!!state.showAddPermanentEmailDialog}
          contentText={
            <>
              <Typography variant="body2" className="mt-2 text-bold">
                {`Would you like to add this email address `}
                <span style={{ color: "Black" }}>{state.email}</span>
                {` to customer emails?`}
              </Typography>
            </>
          }
          onConfirm={() =>
            addPermananentEmaiil(
              state.entries.filter((obj) =>
                state.selectedRows.includes(obj.id)
              )[0]?.customer_branch,
              state.permanentEmail
            )
          }
          onCancel={() => {
            setState((prevState) => {
              return {
                ...prevState,
                showAddPermanentEmailDialog: false,
                permanentEmail: emailAddresses,
              };
            });
          }}
          isConfirmDisabled={state.isPermEmailLoading}
          positiveActionLabel={
            <>
              Add To Customer Email
              {state.isPermEmailLoading && (
                <CircularProgress
                  size={24}
                  className="p-absolute progress-btn"
                />
              )}
            </>
          }
          negativeActionLabel={<>CANCEL</>}
        />
      )}
    </>
  );
};

export default ViewInvoice;
