import { Switch, Route } from 'react-router-dom';
import View from './view';

const ExchangeModule = ({ match }) => {
	return (
		<Switch>
			<Route exact path={match.path} component={View} />
		</Switch>
	);
};

export default ExchangeModule;
