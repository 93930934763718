import makeStyles from "@material-ui/core/styles/makeStyles";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: "calc(100vh - 550px)",
  },
  griddddd: {
    [theme.breakpoints.down("sm")]: {
      height: (props) => (props.isRoleKAM ? 232 : 146),
    },
    height: 240,
  },
  pageBtnwrapper: {},
  iconButton: {
    borderColor: "#58585a",
    borderRadius: 8,
    width: 128,
    [theme.breakpoints.down("sm")]: {
      width: (props) => props.isRoleKAM && 198,
    },
  },
  icon: {
    [theme.breakpoints.down("sm")]: {
      // fontSize: (props) => props.isRoleKAM && 98,
      height: (props) => props.isRoleKAM && "148px",
    },
    height: "75px",
  },
  dcnIcon: {
    [theme.breakpoints.down("sm")]: {
      // fontSize: (props) => props.isRoleKAM && 98,
      height: (props) => props.isRoleKAM && "148px",
    },
    height: "80px",
    width: "130px",
  },
  dahsboardItemTitle: {
    color: grey[900],
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: (props) => props.isRoleKAM && 22,
    },
  },
}));

export default useStyles;
